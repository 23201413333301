import { smileRatingOptions } from "forms/fields/SmileRatingField"
import getIconOrError from "icons"
import View from "ui/View"

const SmileLegend = ({ className, $alignItems }) => (
  <View $flexDirection="column" $alignItems={$alignItems} className={className}>
    <h5 className="text-gray-8 mb-xs">LEGEND</h5>
    <View $flexDirection="row" $alignItems="center" $width="initial" className="space-x-xs">
      {smileRatingOptions
        .slice()
        .reverse()
        .map(({ icon, color }) => {
          const Icon = getIconOrError(icon)
          return <Icon className="fa-lg" key={color} color={color} />
        })}
    </View>
  </View>
)

export default SmileLegend
