import { useOrCreateExerciseInstance } from "domains/Exercise/resource"
import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import { useUser } from "resources/users"
import Loading from "ui/Loading"
import SelectedUserContext from "ui/SelectedUserContext"

const CurrentUserIndividualResultsBlocksContainer = ({ kitInstance, content }) => {
  const { data: exerciseInstance, isFetching } = useOrCreateExerciseInstance({
    teamId: kitInstance.team_id,
    slug: kitInstance.kit.exercise.slug,
    version: kitInstance.kit.exercise.version,
  })
  const { data: user, isFetching: userIsFetching } = useUser({ userId: "me" })

  if ((!exerciseInstance && isFetching) || (!user && userIsFetching)) {
    return <Loading />
  }

  if (!exerciseInstance) {
    return null
  }

  return (
    <SelectedUserContext.Provider value={{ selectedUser: user }}>
      <ResultsBlocks blocks={content} exerciseInstances={[exerciseInstance]} selectedUsers={[user]} />
    </SelectedUserContext.Provider>
  )
}

export default CurrentUserIndividualResultsBlocksContainer
