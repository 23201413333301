import DOMPurify from "dompurify"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

const RichTextFieldRichText = ({ identifier, exerciseInstance, className }) => {
  if (!exerciseInstance) {
    return null
  }

  const answer = getExerciseAnswer(exerciseInstance, identifier)

  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} className={className} />
}

export default styled(RichTextFieldRichText)`
  ol,
  ul {
    margin: 0;
    padding-left: 16px;

    li {
      color: inherit;
    }
  }
`
