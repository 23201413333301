import { Fragment } from "react"
import { Link } from "react-router-dom"

import Accordion from "components/Accordion"
import KalturaVideo from "components/KalturaVideo"
import VimeoVideo from "components/VimeoVideo"
import YouTubeVideo from "components/YouTubeVideo"
import * as FONT_AWESOME_ICONS from "icons/FontAwesomeIcons"
import blockUIComponents from "ui/blockUIComponents"
import Grid from "ui/Grid"
import LinkModal from "ui/LinkModal"
import PieSliceChart from "ui/PieSliceChart"
import PromoFreeKitLink from "ui/PromoFreeKitLink"
import ShareModal from "ui/ShareModal"
import Slide from "ui/Slide"
import Slideshow from "ui/Slideshow"
import SmallAudioPlayer from "ui/SmallAudioPlayer"
import SmileLegend from "ui/SmileLegend"

const blockComponents = {
  ...blockUIComponents,
  Accordion,
  KalturaVideo,
  SmallAudioPlayer,
  Link,
  LinkModal,
  PieSliceChart,
  PromoFreeKitLink,
  Slideshow,
  Slide,
  SmileLegend,
  VimeoVideo,
  YouTubeVideo,
  Fragment,
  Grid,
  ShareModal,
  ...FONT_AWESOME_ICONS,
}

export default blockComponents
