function SvgEmailColoredIcon({ size = "1em", ...props }) {
  return (
    <svg viewBox="0 0 60 44" fill="none" width={size} height={size} {...props}>
      <mask id="email" maskUnits="userSpaceOnUse" x={0} y={0} width={60} height={44} style={{ maskType: "alpha" }}>
        <rect width={60} height={43.8} rx={8} fill="#1025E3" />
      </mask>
      <g mask="url(#email)">
        <rect width={60} height={43.8} rx={8} fill="#1025E3" fillOpacity={0.8} />
        <path d="M35.933 18.786a8 8 0 0 0-11.266 0L-5.4 48.6H66L35.933 18.786z" fill="#FFE141" />
        <path
          d="M34.561 24.603a6.5 6.5 0 0 1-9.122 0L-5.939-6.3h71.878L34.561 24.603z"
          fill="#FF5369"
          stroke="#F8F8F8"
          strokeWidth={3}
        />
      </g>
    </svg>
  )
}

export default SvgEmailColoredIcon
