import { Navigate } from "react-router"

import MiniKitsTableOfContents from "./MiniKitsTableofContents/MiniKitsTableOfContents"

import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"
import { buildUrl } from "utils/string"

const MiniKitHome = () => {
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const { data: user } = useUser({ userId: "me" })
  const existingQueryParams = useQueryParams()

  if (redirectTeamId) {
    return (
      <Navigate
        to={buildUrl(["minis"], {
          urlQueryParams: { ...existingQueryParams, team_id: redirectTeamId },
          useTrailingSlash: false,
        })}
        replace
      />
    )
  }

  if (!user || !selectedTeam || needsRedirect) {
    return null
  }

  return (
    <main className="align-left full-width">
      <div className="main-container">
        <MiniKitsTableOfContents user={user} selectedTeam={selectedTeam} />
      </div>
    </main>
  )
}

export default MiniKitHome
