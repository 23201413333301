import { styled } from "styled-components"

import Accordion from "components/Accordion"
import { DownloadIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import ChatBubble from "ui/ChatBubble"

const VerticalTeammojis = styled(function VerticalTeammojis({ kit }) {
  return (
    <>
      {!!kit.bonus_page.teammojis_subheader && (
        <h2 className="text-gray-9 mb-medium">{kit.bonus_page.teammojis_subheader}</h2>
      )}
      <div className="description-wrapper">
        <div>
          {kit.bonus_page.bonus_desc.map((description, index) => (
            <div className="mb-xs" key={index}>
              {description.paragraph_text}
            </div>
          ))}
        </div>
        {!!kit.bonus_page.chat_bubble && <ChatBubble kit={kit} className="chat-bubble ml-large" />}
      </div>
      {!!kit.bonus_page.emoji_example && <EmojiExample kit={kit} className="mt-medium mb-xl ml-large emoji-example" />}
      <div className="pb-small">{kit.bonus_page.emoji_header_desc}</div>
      {kit.emoji_list.map(({ emoji, slug, title, content }) => (
        <div key={slug} className="emoji-row-container text-gray-9 py-small">
          <img src={emoji} className="text-large mt-xxs mr-medium ml-large" aria-label={title} alt={slug} />
          <div className="ml-large">
            <h3 className="text-bold mb-xs">
              {slug} {!!title && "(" + title + ")"}
            </h3>
            {content}
          </div>
        </div>
      ))}
      <div className="py-small">{kit.bonus_page.emoji_footer_desc}</div>
      <a
        href={`https://static.risingteam.com/kit_assets/${kit.bonus_page.emoji_download_slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="tertiary mb-xl mt-small">
          <DownloadIcon />
          Download TeamMojis
        </Button>
      </a>
      <Accordion className="text-bold text-gray-8" title="How to install" isOpen={false}>
        <div className="text-normal text-gray-9">
          <div className="py-xs pb-large">
            TeamMojis can be added as custom emojis to{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/chat/answer/12800149">
              Google Chat
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://slack.com/help/articles/206870177-Add-custom-emoji"
            >
              Slack
            </a>
            . If you use a different messaging platform, email us at{" "}
            <a target="_blank" rel="noopener noreferrer" href="mailto:hello@risingteam.com">
              hello@risingteam.com
            </a>{" "}
            to share what messaging tool you use so we can prioritize what we build next.
          </div>
        </div>
      </Accordion>
    </>
  )
})`
  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    .emoji-example {
      margin-left: var(--spacing-0);
    }
  }
`

const EmojiExample = styled(function ({ className, kit }) {
  return (
    <div className={className}>
      {kit.bonus_page.emoji_example.map((example, index) => (
        <div key={index}>
          <h4>{example.example_header}</h4>
          <ul className="ml-xl mt-small mb-medium">
            {example.examples.map((list, index) => (
              <li key={index}>
                {list.map((item, index) => {
                  if (item.example_type === "text") {
                    return item.example_content
                  }
                  if (item.example_type === "emoji") {
                    return (
                      <img
                        key={index}
                        src={item.example_content}
                        alt={item.example_content}
                        className={item.example_class}
                        width="24px"
                      />
                    )
                  }
                  return null
                })}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
})`
  width: 75%;

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    width: 100%;
    .emoji-example {
      margin-left: var(--spacing-0);
    }
  }
`

export default VerticalTeammojis
