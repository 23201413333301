import { createContext, useContext } from "react"

const ModalContext = createContext({
  modal: null,
  setModal: (_modalData: TODO) => {},
})

const useModal = () => useContext(ModalContext) || {}

export { ModalContext, useModal }
