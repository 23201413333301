import { styled } from "styled-components"

import DoubleStarIcon from "icons/DoubleStarIcon"
import SingleStarIcon from "icons/SingleStarIcon"
import TripleStarIcon from "icons/TripleStarIcon"

const ChatBubbleContent = ({ kit }) => (
  <>
    {kit.bonus_page.chat_bubble.map((content, index) => {
      if (content.chat_type === "text") {
        return content.chat_content
      }
      if (content.chat_type === "emoji") {
        return <img key={index} src={content.chat_content} alt={content.chat_content} className={content.chat_class} />
      }
      return null
    })}
  </>
)

const ChatBubble = styled(function ({ className, kit }) {
  return (
    <div className={className}>
      <div className="star-container">
        <SingleStarIcon />
        <DoubleStarIcon />
      </div>
      <p className="bubble speech px-large py-small text-align-left border-radius-medium">
        <ChatBubbleContent kit={kit} />
      </p>
      <TripleStarIcon className="triple-star" />
    </div>
  )
})`
  img {
    width: 40px;
    height: 40px;
  }

  p.bubble {
    position: relative;
    width: 90%;
    line-height: 1.4em;
    background-color: var(--gray-1);
    border: 1px solid #eaeaea;
    margin-left: 5%;
    margin-top: -13px;
    color: #7a7a7a;

    &::before,
    &::after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
    }
  }

  p.speech {
    &::before {
      left: 170px;
      bottom: -25px;
      border: 12px solid;
      border-color: #eaeaea #eaeaea transparent transparent;
    }

    &::after {
      left: 171px;
      bottom: -22px;
      border: 11px solid;
      border-color: #fafafa #fafafa transparent transparent;
    }
  }

  .star-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .triple-star {
    margin-top: 7px;
    margin-left: 160px;
  }
`
export default ChatBubble
