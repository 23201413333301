import { useQuery } from "@tanstack/react-query"
import { throttle } from "lodash-es"
import { useState, useMemo, useCallback } from "react"

import api from "api"
import { getFirebaseRealtimeDatabase } from "domains/Authentication/firebase"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { checkNamedArguments } from "utils/function"

async function fetcher({ url, params = {} }: { url: string; params?: Record<string, unknown> }) {
  // TODO(evnp) - we can remove this checkNamedArguments after resources refactoring is complete
  checkNamedArguments("fetcher", arguments, { required: ["url"], optional: ["params"] })
  const { data } = await api.get(url, { params })
  return data
}

const REALTIME_KEY_PATH_PREFIX = "path/"

function getRealtimeCacheKey({
  kitInstanceId,
  key,
}: {
  kitInstanceId: KitInstanceID | null
  key: string
}): string | null {
  return kitInstanceId ? `kit_instance/${kitInstanceId}/${key}` : null
}

function useRealtimeQuery({
  realtimeCacheKey,
  queryCacheKey,
  queryFn,
  enabled = true,
  onSuccess = null,
}: {
  realtimeCacheKey: string | null
  queryCacheKey: Parameters<typeof useQuery>[0]
  queryFn: Parameters<typeof useQuery>[1]
  enabled?: boolean
  onSuccess?: ((data: unknown) => void) | null
}) {
  const queryResults = useQuery(queryCacheKey, queryFn, { enabled, ...(onSuccess ? { onSuccess } : {}) })
  const [listenerEnabled, setListenerEnabled] = useState(false)
  // Setting react state to a function will set state to the return value of the function.
  // In order to save a function to state, we wrap it in another function.
  const noOpFunc = useCallback(() => {}, [])
  const [unsubscribeFunc, setUnsubscribeFunc] = useState(() => noOpFunc)
  const { refetch } = queryResults
  const throttledRefetch = useMemo(() => throttle(refetch, 500), [refetch])
  const shouldEnableListener = !!enabled && !!realtimeCacheKey && !listenerEnabled
  const shouldDisableListener = !enabled && !!listenerEnabled

  useEffectAfterChange(() => {
    if (!!shouldEnableListener) {
      ;(async () => {
        const { database: db, ref: firebaseRealtimeDatabaseRef, onValue } = await getFirebaseRealtimeDatabase()
        let unsubscribe: () => void
        if (db && firebaseRealtimeDatabaseRef && onValue) {
          const valueRef = firebaseRealtimeDatabaseRef(db, realtimeCacheKey)
          unsubscribe = onValue(valueRef, (snapshot: { val: Function }) => {
            if (snapshot.val() != null) {
              throttledRefetch()
            }
          })
        }
        setListenerEnabled(true)
        if (db && firebaseRealtimeDatabaseRef && onValue) {
          setUnsubscribeFunc(() => unsubscribe)
        }
      })()
      // TODO(evnp,PeterLD) Take a look at the code above together - if it's safe to swap ordering of setListenerEnabled and setUnsubscribeFunc we can simplify things.
    } else if (!!shouldDisableListener) {
      unsubscribeFunc()
      setListenerEnabled(false)
      setUnsubscribeFunc(() => noOpFunc)
      // TODO(evnp,PeterLD) Take a look at the code above together - if it's safe to swap ordering of setListenerEnabled and setUnsubscribeFunc we can simplify things.
    }

    return () => unsubscribeFunc()
  }, [
    realtimeCacheKey,
    throttledRefetch,
    unsubscribeFunc,
    shouldEnableListener,
    shouldDisableListener,
    enabled,
    listenerEnabled,
    noOpFunc,
  ])

  return queryResults
}

export { fetcher, REALTIME_KEY_PATH_PREFIX, getRealtimeCacheKey, useRealtimeQuery }
