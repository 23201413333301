import cn from "classnames"

import { getSortedTruncatedUserAnswers } from "../results_utils"
import AnswerDisplaySmall from "../ResultsComponents/AnswerDisplaySmall"

const TextFieldMultiUserList = ({ identifier, exerciseInstances, sortedUsers, className, maxNumResults = null }) => (
  <div className={cn(className, "mb-xl")}>
    {getSortedTruncatedUserAnswers(exerciseInstances, sortedUsers, identifier, maxNumResults).map(([user, answer]) => (
      <AnswerDisplaySmall key={user.id} className="mb-small" title={user.short_name} answer={answer} />
    ))}
  </div>
)

export default TextFieldMultiUserList
