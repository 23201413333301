import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { lazy, useState, Suspense } from "react"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"

import Contact from "components/Contact"
import Home from "components/Home"
import NotFound from "components/NotFound"
import RTKitPublicView from "components/RTKitPublicView"
import RTKitView from "components/RTKitView"
import SelectOrUpdatePayment from "components/SelectOrUpdatePayment"
import ServerError from "components/ServerError"
import Admin from "domains/Admin"
import Arti from "domains/Arti"
import Login from "domains/Authentication/Login"
import { checkForStaleJS, useAPIJSDateInfo } from "domains/Authentication/resource"
import Authentication, {
  AdminRedirectRoute,
  CheckSupportedBrowser,
  PrivateRoute,
  PublicOnlyRoute,
} from "domains/Authentication/Routes"
import ProviderSSOLogin from "domains/Authentication/sso/ProviderSSOLogin"
import TestSSOLogin from "domains/Authentication/sso/TestSSOLogin"
import { isSSORedirectLoginFlowEnabled } from "domains/Authentication/utils"
import Bonus from "domains/Bonus"
import PublicNewsGeneratorBonus from "domains/Bonus/PublicNewsGeneratorBonus"
import Demo from "domains/Demo"
import EngagementSurvey from "domains/EngagementSurvey/EngagementSurvey"
import EngagementSurveyComplete from "domains/EngagementSurvey/EngagementSurveyComplete"
import GetStarted from "domains/GetStarted"
import KitSession from "domains/KitSession"
import LeaderActions from "domains/LeaderActions"
import LeaderKit from "domains/LeaderKit"
import MiniKit from "domains/MiniKit"
import PromoIndex from "domains/Promo"
import ShrmVolunteer from "domains/Promo/ShrmVolunteer"
import Reports from "domains/Reports"
import Results from "domains/Results"
import ShareResultsPage from "domains/Results/Share/ShareResultsPage"
import RTIntegrations from "domains/RTIntegrations"
import ZapierAuth from "domains/RTIntegrations/ZapierAuth"
import RTOnly from "domains/RTOnly"
import Setup from "domains/Setup"
import Subscribe from "domains/Subscribe"
import TeamCode from "domains/TeamCode"
import StartSession from "domains/TeamCode/StartSession"
import UserAccount from "domains/UserAccount"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import { AppModal } from "ui/Modal"
import { ModalContext } from "ui/ModalContext"

// Production react-query devtools, implementation from:
// https://tanstack.com/query/v4/docs/framework/react/devtools#devtools-in-production
const ReactQueryDevtoolsProduction = lazy(() =>
  // eslint-disable-next-line import/extensions
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then((module) => ({
    default: module.ReactQueryDevtools,
  }))
)

const RTRoutes = () => {
  const [modal, setModal] = useState(null)
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  // Production react-query devtools, implementation from:
  // https://tanstack.com/query/v4/docs/framework/react/devtools#devtools-in-production
  const [showProdReactQueryDevtools, setShowReactQueryDevtools] = useState(false)
  useEffectAfterFirstRender(() => {
    window.toggleReactQueryDevtools = () => setShowReactQueryDevtools((showing) => !showing)
  })

  // Create a referentially stable instance of queryClient
  // https://tkdodo.eu/blog/react-query-fa-qs#2-the-queryclient-is-not-stable
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // TODO(react-query-v5) Remove network mode when we migrate to v5
            // Test behavior when browser navigator.onLine=false and ensure that
            // authentication requests still fire as expected and auth still works.
            // (/authentication/me and /authentication/api_js_date)
            // See https://stackoverflow.com/a/75566702 for details.
            networkMode: "offlineFirst",
            refetchOnWindowFocus: false,
            retry: false,
            onError: (error) => {
              if (error?.response?.status === 401 && !pathname.startsWith("/auth/")) {
                queryClient.clear()
                navigate(`/auth/login?next=${encodeURIComponent(`${pathname}${search}`)}`)
              }
            },
          },
          mutations: {
            // TODO(react-query-v5) Remove network mode when we migrate to v5
            // Test behavior when browser navigator.onLine=false and ensure that
            // authentication requests still fire as expected and auth still works.
            // (/authentication/me and /authentication/api_js_date)
            // See https://stackoverflow.com/a/75566702 for details.
            networkMode: "offlineFirst",
          },
        },
      })
  )

  const ssoRedirectLogin = isSSORedirectLoginFlowEnabled()
  return (
    <QueryClientProvider client={queryClient}>
      <ModalContext.Provider value={{ modal, setModal }}>
        <StaleJSChecker />
        <Routes>
          <Route element={<CheckSupportedBrowser />}>
            <Route element={<PublicOnlyRoute />}>
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/google" element={<Navigate to="/auth/sso/google.com" />} />
              <Route path="/auth/test-sso/redirect" element={<TestSSOLogin ssoRedirectLogin={true} />} />
              <Route path="/auth/test-sso" element={<TestSSOLogin ssoRedirectLogin={ssoRedirectLogin} />} />
              <Route path="/auth/sso/:provider/redirect" element={<ProviderSSOLogin ssoRedirectLogin={true} />} />
              <Route path="/auth/sso/:provider" element={<ProviderSSOLogin ssoRedirectLogin={ssoRedirectLogin} />} />
              <Route
                path="/auth/sso/:provider/redirect-loading"
                element={<ProviderSSOLogin ssoRedirectLogin={ssoRedirectLogin} showLoadingUI={true} />}
              />
            </Route>
            <Route element={<RTKitPublicView />}>
              <Route path="/auth/*" element={<Authentication />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/get-started/*" element={<GetStarted />} />
              <Route path="/promo/:promoCode" element={<PromoIndex />} />
              <Route path="/shrm-24" element={<PromoIndex promoCode="shrm-24" />} />
              <Route path="/shrm-volunteer" element={<ShrmVolunteer />} />
              <Route path="/codes/*" element={<Navigate to={pathname.replace("codes", "code") + search} replace />} />
              <Route path="/code/*" element={<TeamCode />} />
              <Route path="/share/:share_code" element={<ShareResultsPage />} />
              <Route path="/select-or-update-payment" element={<SelectOrUpdatePayment />} />
              <Route path="/engagement-survey" element={<EngagementSurvey />} />
              <Route path="/engagement-survey-complete" element={<EngagementSurveyComplete />} />
              <Route path="/integrations/*" element={<RTIntegrations />} />
              <Route path="/news-generator" element={<PublicNewsGeneratorBonus />} />
              <Route path="/leader-actions/:action/:status" element={<LeaderActions />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route element={<RTKitView sidebar="Kits" />}>
                <Route path="/" element={<Home />} />
                <Route path="/kit/*" element={<LeaderKit />} />
              </Route>
              <Route element={<RTKitView sidebar="Minis" />}>
                <Route path="/minis/*" element={<MiniKit />} />
              </Route>
              <Route element={<RTKitView sidebar="Team" />}>
                <Route path="/team/*" element={<Results />} />
              </Route>
              <Route element={<RTKitView sidebar="Account" />}>
                <Route path="/account/*" element={<UserAccount />} />
                <Route path="/contact" element={<Contact />} />
              </Route>
              <Route element={<RTKitView sidebar="Subscribe" />}>
                <Route path="/subscribe/*" element={<Subscribe />} />
              </Route>
              <Route element={<RTKitView sidebar="Bonus" />}>
                <Route path="/bonus/*" element={<Bonus />} />
              </Route>
              <Route element={<RTKitView sidebar="Admin" />}>
                <Route path="/admin/*" element={<Admin />} />
              </Route>
              <Route element={<RTKitView sidebar="Reports" />}>
                <Route path="/reports/*" element={<Reports />} />
              </Route>
              <Route element={<RTKitView hideSidebarIf={() => true} />}>
                <Route path="/session/:slug/:id/*" element={<KitSession />} />
                <Route path="/startsession/*" element={<StartSession />} />
              </Route>
              <Route element={<RTKitView sidebar="Setup" hideSidebarIf={(user) => !user.setup_exited_at} />}>
                <Route path="/setup/*" element={<Setup />} />
              </Route>
              <Route element={<RTKitView sidebar="Demo" />}>
                <Route path="/demo/*" element={<Demo />} />
              </Route>
              <Route element={<RTKitView sidebar="RT Only" />}>
                <Route path="/rtonly/*" element={<RTOnly />} />
              </Route>
              <Route element={<RTKitView sidebar="aRTi" />}>
                <Route path="/arti/*" element={<Arti />} />
              </Route>
              <Route path="/rtadmin/*" element={<AdminRedirectRoute />} />
              <Route path="/500" element={<ServerError />} />
              <Route path="/integrations/zapier-auth" element={<ZapierAuth />} />
              <Route element={<RTKitView hideSidebarIf={() => true} />}>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Route>
        </Routes>
        <AppModal />
        <ReactQueryDevtools />
        {!!showProdReactQueryDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
      </ModalContext.Provider>
    </QueryClientProvider>
  )
}

const StaleJSChecker = () => {
  const { data: apiJSDateInfo } = useAPIJSDateInfo()
  useEffectAfterChange(() => checkForStaleJS(apiJSDateInfo), [apiJSDateInfo])
  return null
}

export default RTRoutes
