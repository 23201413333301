import cn from "classnames"

import { PrintIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"

const PrintButton = ({ className, text = null }) => (
  <Button className={cn(className, "tertiary hide-in-print")} onClick={() => window.print()}>
    <PrintIcon className="hide-on-mobile" />
    {text ?? "Print"}
  </Button>
)

export default PrintButton
