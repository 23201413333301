import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { useShareExerciseResults } from "../Exercise/resource"

import PrintButton from "components/PrintButton"
import { ShareIcon, LinkIcon } from "icons/FontAwesomeIcons"
import { useUser } from "resources/users"
import Button from "ui/Button"
import CopyButton from "ui/CopyButton"
import Modal from "ui/Modal"
import View from "ui/View"

const ShareResultsButton = ({
  selectedUserId,
  selectedExercise,
  shareLink,
  setShareLink,
  shareAssessmentTitle,
  className,
}) => {
  const { data: user } = useUser({ userId: "me" })
  const { mutateAsync: shareExerciseResults } = useShareExerciseResults()
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
    onShareResultsClick()
  }
  const closeModal = () => {
    setShowModal(false)
  }

  const onShareResultsClick = async () => {
    const { data } = await shareExerciseResults(selectedExercise.id)
    const shareUrl = `${window.location.origin}/share/${data}`
    setShareLink(shareUrl)
  }

  if (!selectedUserId) {
    // "Team Overview" is selected, show print button instead of share button:
  }

  return (
    <>
      {!!user && selectedUserId === user.id && (
        <>
          <Button
            className={cn(className, "tertiary hide-in-print ml-medium-mobile-never my-medium", className)}
            onClick={openModal}
          >
            <ShareIcon className="hide-on-mobile" />
            Share
          </Button>
          <Modal
            title="Share this page"
            isOpen={showModal}
            onRequestClose={closeModal}
            size="small"
            content={<ShareAssessmentModal shareLink={shareLink} shareAssessmentTitle={shareAssessmentTitle} />}
          />
        </>
      )}
      {!!user && <PrintButton className={cn("my-medium", className)} />}
    </>
  )
}

const ShareAssessmentModal = styled(function ShareAssessmentModal({ className, shareLink, shareAssessmentTitle }) {
  if (!shareLink) {
    return null
  }

  return (
    <View className={className}>
      <div className="share-modal-container">
        <div className="text-gray-8 text-normal py-xs">
          To share your {shareAssessmentTitle}, copy the unique URL and send by your favorite messaging platform.
        </div>
        <div className="text-gray-9 text-normal text-semi-bold py-xs">Your unique URL</div>
        <div className="share-link pt-xs text-gray-8 text-normal p-small">{shareLink}</div>
        <CopyButton className="mt-large" buttonClassName="tertiary" clipboardText={shareLink}>
          <LinkIcon />
          Copy link
        </CopyButton>
      </div>
    </View>
  )
})`
  .share-modal-container {
    background-color: #ffffff;
  }

  .share-link {
    background-color: var(--gray-1);
    border: 1px solid var(--gray-4);
    border-radius: 4px;
  }

  & {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`

export default ShareResultsButton
