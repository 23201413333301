import { styled } from "styled-components"

export default styled.input`
  border-radius: var(--border-radius);
  width: 100%;
  padding: var(--spacing-2);
  box-shadow: var(--blur-4);
  border: none;
  outline: none;
  color: var(--subtitle);
  appearance: none;

  &:focus-visible,
  &:hover {
    box-shadow: var(--lift-6);
  }

  &.medium {
    min-width: 330px;
  }

  &.full-width {
    width: 100%;
  }
`
