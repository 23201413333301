import cn from "classnames"
import { useState } from "react"

import Button from "./Button"
import Modal from "./Modal"

import { colors } from "ui/theme"

const LinkModal = ({ modal_title, link_content, className, buttonClassName, children }) => {
  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }
  return (
    <div className={className}>
      <Button color={colors.risingBlue} className={cn("link-semi-bold", buttonClassName)} onClick={openModal}>
        {link_content}
      </Button>
      <Modal title={modal_title} isOpen={showModal} onRequestClose={closeModal} size="medium" content={children} />
    </div>
  )
}

export default LinkModal
