import cn from "classnames"
import { Link, Navigate } from "react-router-dom"

import Button from "ui/Button"
import Fireworks from "ui/Fireworks"
import View from "ui/View"

const SetupComplete = ({ className, steps = null }) => {
  if (steps?.filter(({ completed }) => !completed).length) {
    return <Navigate replace to="/setup/incomplete" />
  }
  return (
    <div className={cn(className, "full-width m-xxxl")}>
      <h1 className="text-large text-gray-9 mb-xl">Set up complete</h1>
      <p className="text-gray-8 mb-large">Nice work — you are all set to start using Rising Team. See you soon!</p>
      <View $flexDirection="column" $alignItems="center">
        <img src="https://static.risingteam.com/kit_assets/onboarding/partycone.gif" alt="party-cone" width="400px" />
      </View>
      <View $justifyContent="center" className="mt-xxxl mb-large">
        <Link to="/kit" className="link text-button">
          <Button>Finish set up</Button>
        </Link>
      </View>
      <Fireworks />
    </div>
  )
}

export default SetupComplete
