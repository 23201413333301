import { shuffle, first, last, uniq, keyBy } from "lodash-es"
import { useState } from "react"

import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getChartConfig } from "domains/Exercise/results_utils/talents"
import TalentCoverage from "domains/Exercise/ResultsComponents/TalentCoverage"
import TalentMashupCompareUsersForm from "domains/Exercise/ResultsComponents/TalentMashupCompareUsersForm"
import TalentOverlaps from "domains/Exercise/ResultsComponents/TalentOverlaps"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import {
  useRealtimeSelectedShareoutUser,
  useUpdateSelectedShareoutUser,
  useKitParticipants,
} from "resources/monthly_kit"
import { useUser, sortUsersByShortName } from "resources/users"
import BulletedList from "ui/BulletedList"
import Callout from "ui/Callout"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useSyncSessionEnabled from "ui/hooks/useSyncSessionEnabled"
import Loading from "ui/Loading"
import RadarChart from "ui/RadarChart"
import TextContainer from "ui/TextContainer"
import { REALTIME_KEY_PATH_PREFIX } from "utils/query"
import { useHasTeamFeature } from "utils/team"

const TalentsMashupStep = ({ className, sessionStep }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: allParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!allParticipants || (isFetching && !exerciseInstances)) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer maxWidth={860} className="mr-xl">
          <p className="text-gray-9 mb-large">
            Here is a fun way to explore natural talents among the members of our team. We can choose any two team
            members to look at how they are alike and how they complement each other, and what that might mean about how
            they can work together even better.
          </p>
          <Callout title="Discuss" className="mb-xl">
            <BulletedList>
              <li>Do any examples come to mind of seeing these talents in action?</li>
              <li>
                How can this pair make the most of their joint and complementary talents? Any ideal projects? Anything
                to watch out for?
              </li>
            </BulletedList>
          </Callout>
        </TextContainer>
        <FacilitatorTip
          className="mb-xl sidebar-right"
          tip="
            As a team, pick two people on the dropdown below, or have fun picking random pairs with the “Pick for me”
            button. Use the discussion questions below to inspire a meaningful discussion. Try to match each person on
            your team at least once."
        />
      </SidebarContainer>
      {Boolean(allParticipants.length) && (
        <MashupResults
          allParticipants={allParticipants}
          exerciseInstances={exerciseInstances}
          sessionStep={sessionStep}
        />
      )}
    </StepContent>
  )
}

const MashupResults = ({ allParticipants, exerciseInstances, sessionStep }) => {
  const { kitInstance, team } = useKitSession()
  const { data: user } = useUser({ userId: "me" })
  const isTeamLead = user.id === team.team_lead_id
  const participants = allParticipants.filter((user) =>
    exerciseInstances?.find((exercise) => exercise.user_id === user.id)
  )
  const sortedParticipants = sortUsersByShortName({ users: participants })
  const shuffledUsers = shuffle(sortedParticipants)
  const [selectedNonRealtimeUser1, setSelectedNonRealtimeUser1] = useState(first(shuffledUsers))
  const [selectedNonRealtimeUser2, setSelectedNonRealtimeUser2] = useState(last(shuffledUsers))

  const { enabled: realtimeShareoutUserActive } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SHAREOUT_USER)
  const realtimeShareoutUserKey = REALTIME_KEY_PATH_PREFIX + sessionStep.path
  const { data: selectedRealtimeShareoutUser1, isInitialLoading: isInitialLoadingUser1 } =
    useRealtimeSelectedShareoutUser({
      kitInstanceId: kitInstance?.id,
      key: realtimeShareoutUserKey + "-user1",
      enabled: !!(kitInstance && realtimeShareoutUserActive),
    })
  const { data: selectedRealtimeShareoutUser2, isInitialLoading: isInitialLoadingUser2 } =
    useRealtimeSelectedShareoutUser({
      kitInstanceId: kitInstance?.id,
      key: realtimeShareoutUserKey + "-user2",
      enabled: !!(kitInstance && realtimeShareoutUserActive),
    })
  const { mutateAsync: updateSelectedRealtimeShareoutUser1 } = useUpdateSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey + "-user1",
  })
  const { mutateAsync: updateSelectedRealtimeShareoutUser2 } = useUpdateSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey + "-user2",
  })
  const syncSessionEnabled = useSyncSessionEnabled()

  const setSelectedUser1 = (user) => {
    setSelectedNonRealtimeUser1(user)
    realtimeShareoutUserActive && syncSessionEnabled && isTeamLead && updateSelectedRealtimeShareoutUser1(user.id)
  }

  const setSelectedUser2 = (user) => {
    setSelectedNonRealtimeUser2(user)
    realtimeShareoutUserActive && syncSessionEnabled && isTeamLead && updateSelectedRealtimeShareoutUser2(user.id)
  }

  if (!exerciseInstances?.length) {
    return null
  }

  if (isInitialLoadingUser1 || isInitialLoadingUser2) {
    return <Loading />
  }

  const selectedRealtimeUser1 = !!selectedRealtimeShareoutUser1 ? selectedRealtimeShareoutUser1 : first(shuffledUsers)
  const selectedRealtimeUser2 = !!selectedRealtimeShareoutUser2 ? selectedRealtimeShareoutUser2 : last(shuffledUsers)
  const selectedUser1 =
    realtimeShareoutUserActive && syncSessionEnabled ? selectedRealtimeUser1 : selectedNonRealtimeUser1
  const selectedUser2 =
    realtimeShareoutUserActive && syncSessionEnabled ? selectedRealtimeUser2 : selectedNonRealtimeUser2

  const participantIdMap = keyBy(allParticipants, "id")
  const exerciseInstance1 = exerciseInstances.find((exercise) => exercise.user_id === selectedUser1.id)
  const exerciseInstance2 = exerciseInstances.find((exercise) => exercise.user_id === selectedUser2.id)
  const exercisesToCompare = uniq([exerciseInstance1, exerciseInstance2].filter((x) => !!x))

  const chartConfig = getChartConfig(exercisesToCompare, participantIdMap)

  return (
    <>
      <TalentMashupCompareUsersForm
        selectedUser1={selectedUser1}
        selectedUser2={selectedUser2}
        setSelectedUser1={setSelectedUser1}
        setSelectedUser2={setSelectedUser2}
        sortedUsers={sortedParticipants}
        realtimeShareoutUserActive={realtimeShareoutUserActive}
        syncSessionEnabled={syncSessionEnabled}
      />
      <RadarChart {...chartConfig} showLegend={true} className="mb-xl" />
      <TalentCoverage userIdMap={participantIdMap} exercisesToCompare={exercisesToCompare} />
      <TalentOverlaps userIdMap={participantIdMap} exercisesToCompare={exercisesToCompare} />
    </>
  )
}

export default TalentsMashupStep
