import { Link, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { getStandaloneExerciseUrl } from "resources/monthly_kit"

const TalentsTraining2 = ({ kitInstance, className }) => {
  const { pathname } = useLocation()
  const exerciseUrl = getStandaloneExerciseUrl({
    slug: kitInstance.slug,
    teamId: kitInstance.team_id,
    onFinishUrl: pathname,
  })
  return (
    <div className={className}>
      <div className="text-gray-9 pt-medium">
        <div>
          Now that you understand the value of a talents-based approach, how do you identify what those talents are?
          During this Rising Team session, your team will take a quick assessment that will help identify each team
          member's natural talents and how they relate to the talents across the team.
        </div>
        <h4 className="text-rising-orange py-small">The Talents exercise</h4>
        <div className="pb-xs">
          The exercise has six drag and drop questions that ask about talents and can be completed in about 10 minutes.
          Each person will then get a personalized Talent Profile with a spider chart of nine talent types, with
          stronger talents showing as the areas that are filled in further towards the edges of the chart. (We aren’t
          going to explain the individual talent types here, so that you can take your own Talents exercise without any
          bias. You’ll see the details after you <Link to={exerciseUrl}>try the exercise</Link> yourself.)
        </div>
        <div className="image-row">
          <div className="image-container pt-small pl-medium ml-medium mr-large">
            <img
              src="https://static.risingteam.com/kit_assets/talents/kit-training-talents-2.1.png"
              alt="kit-training-talents-strengths"
              width="253"
              height="189"
            />
          </div>
          <div className="row-item pb-xs mr-xxxxl">
            The placements on the chart are not meant to be “exact measurements” but rather estimates of general
            strength, which is why we label the talent based on the ring it’s in, between very high and low. Two talents
            that are in the same ring should generally be seen as similar in strength.
          </div>
        </div>
        <div className="pb-xs">
          There will be three parts to the session, which you can see in the{" "}
          <Link to={`${kitInstance.prep_url}video-walkthrough`}>video preview</Link>: a) understanding each individual
          person’s results, b) looking at “mashups of various pairs of people to see how they can best work together,
          and c) reflecting on the team as a whole to see where talents are strongest and where there may be gaps.
        </div>
        <div className="pb-large">A few key things to keep in mind:</div>
        <h4 className="text-rising-orange py-small">1. The Talents exercise is a self-reported reflection tool</h4>
        <div className="pb-xs">
          We built this exercise for it to be taken quickly and be a good representation of people’s own sense of their
          top talents. It is meant to be a conversation starter, not an exact “label” of a person. For this reason, we
          also include the ability for each person to rate their own results in terms of accuracy. Since we are only
          measuring talents here (the things people enjoy and that come easily to them), they should be able to say what
          feels right to them personally, rather than as measured by others. As part of the discussion, make sure to
          leave room for people to talk about anything that feels off or missing to them.
        </div>
        <h4 className="text-rising-orange py-small">2. It’s focused on workplace talents</h4>
        <div className="pb-xs">
          For this session, we limit the discussion only to the most commonly used talents in the workplace. People may
          have other talents, like a natural gift in working with animals, pure artistic creativity, excellent athletic
          abilities, etc. (and some people do find ways to use those talents in their work). The talents are
          intentionally “high level,” and within each type there may be specific sub talents (for example, public
          speaking is a sub talent of persuasion, as is the compelling presentation of data). If people find that there
          are other talents they have that are relevant to their work (either general or specific) that are not
          represented here, encourage them to bring those up in the discussion. By identifying these nine common
          workplace talents, you should be able to use the exercise results to easily determine what types of work each
          person is well suited to on the team.
        </div>
        <h4 className="text-rising-orange py-small">3. Look for people with complementary talents</h4>
        <div className="pb-xs">
          In the mashup section and the team overview section you’ll see a “coverage” score that shows what percentage
          of the talent types you cover together. People with higher coverage scores are more complementary, meaning
          they have natural talents in different areas, so they may make good partners. It’s not a competition, though.
          People with lower coverage scores are just more similar to each other, which is also fine!
        </div>
        <div className="image-row">
          <div className="image-container pt-small ml-xl mr-large">
            <img
              src="https://static.risingteam.com/kit_assets/talents/kit-training-talents-2.2.png"
              alt="kit-training-talents-strengths"
              width="327"
              height="230"
            />
          </div>
          <div className="row-item pb-xs mr-xxxxl">
            In this example, the person in green has a natural talent in envisioning new ideas, but clarifying those
            ideas to make them understandable to others is more difficult. The person in the blue has a harder time
            envisioning ideas, but loves to clarify them. So these two people would make great partners on getting new
            ideas imagined and outlined.
          </div>
        </div>
        <h4 className="text-rising-orange py-small">4. Use talents to bring out the highest potential in the team</h4>
        <div className="pb-xs">
          When you look at your team overview, you’ll be able to see areas of high overlap and any areas that are gaps.
          When you think about the team needs, are there people who have talent coverage in each of the areas you need,
          and are people mainly being asked to do work that aligns with their talents? When both of those things are
          true, that is the real sweet spot.
        </div>
        <div className="pb-xs">
          It’s most important to ensure you have coverage in the areas that your team works on most often. If that’s the
          case with your team, it’s something to celebrate! People on your team who have talents outside of your team’s
          primary needs are also an asset, and you can think about the times when you’ll be able to put those talents to
          work. If you have gaps, talk about whether it’s important to fill those gaps within your team or if it’s ok to
          have other teams do that work. If you believe it is important to fill the gaps, you can talk in the session
          about how you might do that, such as partnering with other teams or hiring new team members.
        </div>
        <div className="image-row">
          <div className="image-container pt-small mx-medium">
            <img
              src="https://static.risingteam.com/kit_assets/talents/kit-training-talents-2.3.png"
              alt="kit-training-talents-strengths"
              width="329"
              height="230"
            />
          </div>
          <div className="row-item pb-xs mr-xl">
            In this example, the team is nicely well rounded, and even has multiple people that spike in some areas. The
            one weak point is “Persuade,” since there is no one with high or very high talent there. This team has
            decided that Persuade is not a primary need for them and they can get help from other teams as needed.
          </div>
        </div>
        <h4 className="text-rising-orange py-small">5. How you react matters</h4>
        <div className="pb-xs">
          And finally, as with the session on psychological safety, remaining open, curious, and positive in this
          discussion is key to ensuring each of your team members feels understood and valued for what they bring to the
          table. Knowing that you see the importance of each of their talents will give your team members more
          confidence to really play to their strengths and contribute their best to the team. Overall, you want to make
          sure your team understands that the goal of this exercise is to support them and help maximize their success,
          not to judge them.
        </div>
        <div className="pb-xs">
          In the next step, you will see{" "}
          <Link to={kitInstance.session_preview_url} target="_blank" rel="noopener noreferrer">
            a quick preview
          </Link>{" "}
          of how the team session will run, and test out the <Link to={exerciseUrl}>Talents exercise</Link> for
          yourself. In our last training article we’ll cover how to amplify your team’s talents on an ongoing basis.
        </div>
      </div>
    </div>
  )
}

export default styled(TalentsTraining2)`
  .image-row {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    img {
      max-width: 100%;
      height: auto;
    }

    .image-row {
      display: flex;
      flex-direction: column;
    }

    .image-container,
    .row-item {
      margin: var(--spacing-0);
    }
  }
`
