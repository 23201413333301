import { Navigate } from "react-router-dom"

import AlreadyLoggedInPurchaseTeamMember from "./AlreadyLoggedInPurchaseTeamMember"
import AlreadyPaidSubscription from "./AlreadyPaidSubscription"

import { useOwnedAccount } from "resources/billing"
import { useUser } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import { buildUrl } from "utils/string"

const AlreadyLoggedInPurchase = ({ queryParamString, promoCode = null }) => {
  const { data: user } = useUser({ userId: "me" })
  const { data: ownedAccount, isFetching: billingIsFetching } = useOwnedAccount()
  const { gratitude_promo: gratitudePromo } = useQueryParams()

  if (!user || billingIsFetching) {
    return <Loading />
  }

  if (gratitudePromo && (ownedAccount || user.can_manage_billing_for_an_account)) {
    const gratitudeUrl = buildUrl(["kit", "gratitude"], {
      urlQueryParams: { gratitude_promo: 1 },
    })
    return <Navigate to={gratitudeUrl} replace />
  }

  if (user.can_view_subscribe_tab) {
    return <Navigate to="/subscribe" replace />
  }

  if (!ownedAccount) {
    return <AlreadyLoggedInPurchaseTeamMember user={user} promoCode={promoCode} />
  }

  if (ownedAccount.is_subscription_active) {
    return <AlreadyPaidSubscription />
  } else {
    return <Navigate to={`/get-started/create-account/onboarding/?${queryParamString}`} replace />
  }
}

export default AlreadyLoggedInPurchase
