import { Formik } from "formik"

import { useKitSession } from "../KitSessionContext"

import ExerciseRichTextField from "domains/Exercise/ExerciseComponents/ExerciseRichTextField"
import ExerciseForm from "domains/Exercise/ExerciseForm"
import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer, getTeamLead } from "domains/Exercise/results_utils"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import StepContent from "domains/KitSession/components/StepContent"
import StepTitle from "domains/KitSession/components/StepTitle"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import BulletedList from "ui/BulletedList"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const FeedbackFlexPrepare = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const exercise = kitInstance.kit?.exercise
  const { minutes, auto_start_timer, show_timer_controls } = sessionStep
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { initialValues, onSubmit, saveOnChange, isFetching } = useExerciseForm({
    teamId: team.id,
    slug: exercise?.slug,
    version: exercise?.version,
  })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 10000,
  })
  const { data: user } = useUser({ userId: "me" })

  if (!user || !participants || !exerciseInstances || isFetching) {
    return <Loading />
  }

  const teamLead = getTeamLead(participants, team)
  const teamLeadExercise = exerciseInstances.find(({ user_id }) => user_id === teamLead.id)
  const assignmentUserId = getExerciseAnswer(teamLeadExercise, `flex_feedback_assignment_${user.id}`)
  const assignmentUser = participants.find(({ id }) => id === assignmentUserId)
  const assignmentUserDisplayValue = assignmentUser ? assignmentUser.short_name : "[not selected]"

  return (
    <StepContent className={className}>
      <StepTitle
        sessionStep={sessionStep}
        minutes={minutes}
        startSeconds={minutes * 60}
        autoStartTimer={auto_start_timer}
        showTimerAll={true}
        showTimerControls={show_timer_controls}
        className="mb-medium"
      >
        <h2 className="text-gray-9">Prepare your feedback</h2>
      </StepTitle>
      <div className="text-gray-9">
        <p className="mb-medium text-semi-bold">
          You have been assigned to give positive feedback to {assignmentUserDisplayValue}.
        </p>
        <p className="mb-medium">
          Review everything you’ve observed {assignmentUserDisplayValue} do over the last few months. Find one action
          they took that you appreciate in some way. It can be as large or small as you like.
        </p>
        <p className="mb-medium">There are two things to keep in mind to be great at giving positive feedback:</p>
        <BulletedList className="mb-large">
          <li>
            Be as specific as possible - pick a specific action or behavior and share the details of what happened.
          </li>
          <li>
            Share the impact to you - explain why you appreciated those actions and how they mattered to you or to
            others.
          </li>
        </BulletedList>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <ExerciseForm>
            <div>
              <ExerciseRichTextField
                label="I noticed that:"
                name="flex_feedback_action"
                saveOnChange={saveOnChange}
                placeholder="Enter the action here, be as specific as you can and include exactly what happened and when."
                className="mb-medium"
              />
              <ExerciseRichTextField
                label="I appreciated it because:"
                name="flex_feedback_appreciated_because"
                saveOnChange={saveOnChange}
                placeholder="Enter the positive outcome that action made possible and/or how it made you feel."
                className="mb-medium"
              />
            </div>
          </ExerciseForm>
        </Formik>
      </div>
    </StepContent>
  )
}

export default FeedbackFlexPrepare
