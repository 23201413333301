import DOMPurify from "dompurify"

export default function AnswerDisplaySmall({ title, answer, ...props }) {
  return (
    <div {...props}>
      <span className="text-small text-semi-bold text-gray-9 bg border border-light border-radius px-xs py-xxs mr-small">
        {title}
      </span>
      {typeof answer === "string" ? (
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />
      ) : (
        answer ?? null
      )}
    </div>
  )
}
