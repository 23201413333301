import { styled } from "styled-components"

const TalentsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>Our theme is Natural Talents. In these three brief training articles, we’ll cover:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>the definition of talents (vs skills and strengths) and why they matter for your team</li>
          <li>how this session will help you identify and maximize each person’s talents</li>
          <li>tips to continue harnessing your team’s talents to drive success</li>
        </ul>
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">1. What is a talent?</h3>
      <div className="pb-xs">
        To get started, let’s talk about the difference between a talent, a skill, and a strength:
      </div>
      <h4 className="text-gray-9 py-xs">a) Talents are innate abilities and skills are things we learn to do</h4>
      <div className="py-xs">
        Each of us has natural talents—the activities that give us energy and generally come easily to us. Talents are
        innate; they are often present from the time we are very young.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/412/exactly-what-talent-anyway.aspx"
        >
          Gallup
        </a>
        , who has done global research in this area for decades explains, “Talent reflects how you're hard-wired [and]
        dictates your moment-by-moment reactions to your environment—there's an instinctiveness, an immediacy implied.”
      </div>
      <div className="py-xs">
        We can think of natural talents as the activities we have likely always been good at, whereas “skills” are
        things we learn to be good at over time, but don’t always come as naturally to us. Again, according to Gallup,
        “Knowledge and skills imply learned behavior, actions that require more active cognitive processing.”
      </div>
      <div className="image-row">
        <div className="image-container pt-small mr-medium">
          <img
            src="https://static.risingteam.com/kit_assets/talents/kit-training-talents-1.1.png"
            alt="kit-training-talents-strengths"
            width="320"
            height="323"
          />
        </div>
        <div className="py-xs">
          You may also hear references to “strengths'' at work, and according to Gallup, strengths are where we have
          both high talent and high skill. In this session, we’ll just be discussing talents—the activities that come
          most naturally to us—with the goal of helping our team identify and amplify their talents to keep them on the
          right side of this chart. While the upper right quadrant is ideal, the bottom right quadrant is okay too,
          especially for people earlier in their careers. When people are in roles that don’t leverage their natural
          talents (those on the left side of the chart), although they can be successful, especially with strong skills,
          they are often less happy and less productive.
        </div>
      </div>
      <div className="py-xs">
        A workplace example of a person on the lower right hand side of the chart is someone who has a natural talent
        for building/creating things and is well suited to be an engineer, but is new to a particular coding language.
        They are still in a zone of high talent and just need to come up to speed on a particular skill.
      </div>
      <h4 className="text-gray-9 py-xs">b) Working within our talents makes us happy and energized</h4>
      <div className="py-xs">
        It’s important to remember that identifying talents is not about highlighting whether we are good at something,
        but rather whether we enjoy it and it comes easily to us. It’s possible to be very good at things that are not
        our natural talents, but doing those activities tends to drain our energy. Those are the activities that are on
        the upper left of the chart. A key question to ask ourselves here is, “If I could choose any activity, would I
        want to spend hours doing this one?” If the answer is no, then those activities are low talent ones, even if you
        are great at them. People are happiest and most successful when they spend more time working on areas within
        their natural talents. While every job may require some activities outside our talents, it’s ideal to find roles
        where we can use our talents more often.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">2. Why do talents matter?</h3>
      <div className="pb-xs">So, the key question is...why does all of this matter? Here’s why:</div>
      <h4 className="text-gray-9 py-xs">a) Employees are more engaged when managers focus on talents</h4>
      <div className="py-xs">
        Gallup describes engaged employees as “those who are highly involved in, enthusiastic about, and committed to
        their work and workplace,” and{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gallup.com/workplace/313313/historic-drop-employee-engagement-follows-record-rise.aspx"
        >
          according to their research
        </a>{" "}
        engagement is low worldwide. Only about 33% of US employees and 15% of global employees are engaged at work.
        However, managers can make a big difference here. In fact Gallup says that 70% of the variance in employee
        engagement can be attributed to the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/182792/managers-account-variance-employee-engagement.aspx"
        >
          quality of managers
        </a>
        .
      </div>
      <div className="py-xs">
        The great news is that managers who focus on helping people use their talents and strengths have a huge impact.
        According to Gallup, employees who can use their strengths at work are 6x more likely to be engaged at work.
        They also perform better and are less likely to leave their organizations.
      </div>
      <div className="image-row">
        <div className="py-xs">
          And they found that when managers focus on a team member’s strengths, vs. their weaknesses or no feedback at
          all, the team member’s{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://news.gallup.com/businessjournal/26755/Strengths-Next-Generation.aspx"
            className="text-underline"
          >
            chances of being actively disengaged
          </a>{" "}
          go down to one in one hundred.
        </div>
        <div className="image-container pt-small ml-xxxxl">
          <img
            src="https://static.risingteam.com/kit_assets/talents/kit-training-talents-1.2.png"
            alt="kit-training-talents-focusing-on-strengths"
            width="400"
            height="204"
          />
        </div>
      </div>
      <h4 className="text-gray-9 py-xs">b) Wellness improves when people use their strengths</h4>
      <div className="py-xs">
        Research also tells us that the more people can use their strengths to do what they do best,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/167462/employees-strengths-company-stronger.aspx"
          className="text-underline"
        >
          the better they feel
        </a>
        . People who get to leverage their natural talents each day are less likely to report experiencing worry,
        stress, anger, sadness, or physical pain during the previous day, and more likely to report having ample energy,
        feeling well-rested, being happy, smiling or laughing a lot, learning something interesting, and being treated
        with respect. More than half (52%) of Americans who use their strengths for three hours a day or less are
        stressed, but this falls to 36% for those who use their strengths 10 hours per day or more.
      </div>
      <h4 className="text-gray-9 py-xs">c) Focusing on talents drives team success </h4>
      <div className="py-xs">
        The data shows that not only are individuals more engaged and successful when they get to focus on strengths,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/167462/employees-strengths-company-stronger.aspx"
          className="text-underline"
        >
          but <i>teams and companies</i> are also more successful
        </a>{" "}
        when this happens. Gallup research shows that teams that focus on strengths every day have 12.5% greater
        productivity than teams that don’t, and that investing in and focusing on employees' talents leads to higher
        levels of performance, profitability, productivity, and greater earnings per share for businesses.
      </div>
      <div className="py-xs">
        Marcus Buckingham, a leader in the strengths movement who used to work with Gallup on StrengthsFinder and then
        created the StandOut assessment, compares leveraging team member talents to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2005/03/what-great-managers-do"
          className="text-underline"
        >
          leaders who play chess instead of checkers
        </a>
        . He says, “I’ve found that while there are as many styles of management as there are managers, there is one
        quality that sets truly great managers apart from the rest: They discover what is unique about each person and
        then capitalize on it. Average managers play checkers, while great managers play chess. The difference? In
        checkers, all the pieces are uniform and move in the same way; they are interchangeable. You need to plan and
        coordinate their movements, certainly, but they all move at the same pace, on parallel paths. In chess, each
        type of piece moves in a different way, and you can’t play if you don’t know how each piece moves. More
        importantly, you won’t win if you don’t think carefully about how you move the pieces. Great managers know and
        value the unique abilities and even the eccentricities of their employees, and they learn how best to integrate
        them into a coordinated plan of attack.”
      </div>
      <div className="py-xs">There are a few reasons why utilizing each person’s talents helps the team:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">It saves time:</span> People are faster and more effective at their own work
            when they do things that come more easily to them.
          </li>
          <li>
            <span className="text-bold">It makes each person more accountable:</span> When people know they are being
            called on based on a specific talent, it makes them want to take ownership and show they can deliver in that
            area.
          </li>
          <li>
            <span className="text-bold">It builds a stronger sense of team:</span> When people understand each other’s
            talents and rely on each other to take action in those areas, it creates a sense of interdependence and
            connectedness because people realize they need each other to succeed.{" "}
          </li>
        </ul>
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">
        3. Does this mean I should ignore weaknesses or poor performance?{" "}
      </h3>
      <div className="py-xs">
        Of course not. Focusing on someone’s natural talent doesn’t mean we should lower our expectations as leaders.
        It’s important to still set clear goals and expectations, let people know if they are falling short of those
        goals, and help support people to meet the expectations that are set in our organizations. The concept here is
        that by helping people spend more of their time in areas that align with their natural talents, they are more
        likely to be successful in meeting the expectations we set, since those are areas that come easily to them and
        that they enjoy. Often when performance is an issue, it means that someone’s role may be demanding too much work
        outside of their natural talents.
      </div>
      <div className="py-xs">
        The{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/412/exactly-what-talent-anyway.aspx"
          className="text-underline"
        >
          research
        </a>{" "}
        suggests we should spend most of our time further developing the areas where we have natural talent rather than
        trying to shore up those where we don't. As an example, imagine you were a natural at sprinting and ok at
        long-distance running, but it was much harder for you. It would lead to a better outcome to spend your time
        honing your sprinting skills to become even stronger, rather than trying to become a marathoner or split your
        time to try to get a bit better at both.
      </div>
      <div className="py-xs">
        That’s a wrap on defining talents and why they matter. Now we’re ready to talk about how we’ll identify them and
        help each person start amplifying them in the team session.
      </div>
    </div>
  </div>
)

export default styled(TalentsTraining1)`
  .image-row {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    img {
      max-width: 100%;
      height: auto;
    }

    .image-row {
      display: flex;
      flex-direction: column;
    }

    .image-container {
      margin: var(--spacing-0);
    }
  }
`
