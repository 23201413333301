import SelectField from "./SelectField"

const JOB_FUNCTIONS = [
  { value: "Administrative", label: "Administrative" },
  { value: "Comms", label: "Comms" },
  { value: "Consultant", label: "Consultant" },
  { value: "Customer support", label: "Customer support" },
  { value: "Design", label: "Design" },
  { value: "Engineering", label: "Engineering" },
  { value: "Executive", label: "Executive" },
  { value: "Finance", label: "Finance" },
  { value: "Human resources", label: "Human resources" },
  { value: "IT", label: "IT" },
  { value: "Legal", label: "Legal" },
  { value: "Marketing", label: "Marketing" },
  { value: "Operations", label: "Operations" },
  { value: "Product management", label: "Product management" },
  { value: "Research", label: "Research" },
  { value: "Sales", label: "Sales" },
  { value: "Other", label: "Other" },
]

const SCHOOL_JOB_FUNCTIONS = [
  { value: "School leadership team", label: "School leadership team" },
  { value: "Assistant principal", label: "Assistant principal" },
  { value: "Principal", label: "Principal" },
  { value: "Teacher", label: "Teacher" },
  { value: "Educational support", label: "Educational support" },
]

const SHRM_JOB_FUNCTIONS = [
  { value: "Academician", label: "Academician" },
  { value: "Administrative Support Administrator", label: "Administrative Support Administrator" },
  { value: "Benefits", label: "Benefits" },
  { value: "Communications/CSR (Branding/Social Media)", label: "Communications/CSR (Branding/Social Media)" },
  { value: "Compensation", label: "Compensation" },
  { value: "Compliance", label: "Compliance" },
  { value: "Consultant", label: "Consultant" },
  { value: "Consultant - Independent", label: "Consultant - Independent" },
  {
    value: "Consultant - Multi-person Firm Diversity, Equity and Inclusion EEO/Affirmative Action",
    label: "Consultant - Multi-person Firm Diversity, Equity and Inclusion EEO/Affirmative Action",
  },
  { value: "Employee Relations/Programs", label: "Employee Relations/Programs" },
  { value: "Employment/Recruitment/Talent Acq", label: "Employment/Recruitment/Talent Acq" },
  { value: "General HR", label: "General HR" },
  { value: "Global Mobility/Immigration", label: "Global Mobility/Immigration" },
  { value: "Health, Safety, Security", label: "Health, Safety, Security" },
  { value: "HRIS/Analytics", label: "HRIS/Analytics" },
  { value: "International HRM", label: "International HRM" },
  { value: "Labor/Industrial Relations", label: "Labor/Industrial Relations" },
  { value: "Legal", label: "Legal" },
  { value: "Manage Outsourced HR", label: "Manage Outsourced HR" },
  { value: "Org. Development/L&D/Training", label: "Org. Development/L&D/Training" },
  { value: "Other HR Function", label: "Other HR Function" },
  { value: "Other non HR Function", label: "Other non HR Function" },
  { value: "Relocation", label: "Relocation" },
  { value: "Research", label: "Research" },
  { value: "Strategic Planning", label: "Strategic Planning" },
]

const JobFunctionsField = ({ jobFunctions = JOB_FUNCTIONS, ...props }) => (
  <SelectField {...props}>
    <option disabled value="" />
    {jobFunctions.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ))}
  </SelectField>
)

export default JobFunctionsField

export { JOB_FUNCTIONS, SCHOOL_JOB_FUNCTIONS, SHRM_JOB_FUNCTIONS }
