import type { PropertiesHyphen as CSSProperties } from "csstype"

type CSSPropertyName = keyof CSSProperties

function formatLengthValue(value: string | number | null): string {
  return Number.isInteger(value) ? `${value}px` : value?.toString() ?? "0"
}

// Construct a length style declaration for provided value.
// If value is an integer, automatically use px as unit.
// If value is a string, ensure it has px, rem, or % specified.
// Otherwise, log a warning and return nothing.
// Wrap in a @media block if option media param is provided.
function formatLength(
  lengthName: CSSPropertyName,
  value: string | number | null,
  {
    media = null,
    fallbackValueIfInvalidLength = null,
  }: { media?: string | null; fallbackValueIfInvalidLength?: string | number | null } = {}
) {
  const wrapper = (styles: string) => {
    if (media) {
      return `
        @media (${media}) {
          ${styles}
        }
      `
    } else {
      return styles
    }
  }
  if (Number.isInteger(value)) {
    return wrapper(`${lengthName}: ${value}px;`)
  } else if (typeof value === "string" && /^-?[0-9.]+(px|rem|%)$/.test(value)) {
    return wrapper(`${lengthName}: ${value};`)
  } else if (fallbackValueIfInvalidLength != null) {
    return wrapper(`${lengthName}: ${fallbackValueIfInvalidLength};`)
  } else {
    if (value != null) {
      console.warn(`Invalid ${lengthName} value passed (${value})`)
    }
    return ""
  }
}

export { formatLengthValue, formatLength, type CSSPropertyName }
