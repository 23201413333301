const MotivatorsDailyIntro = () => (
  <>
    <h2 className="mb-medium">Intro</h2>
    <p className="mb-medium">
      When we feel motivated at work, we’re happier, more energized, and more likely to be successful.
    </p>
    <p className="mb-xxxxl">
      This exercise will help you reflect and share some of the factors that motivate you on a day-to-day basis. We will
      be covering long-term career decisions later in the session, so{" "}
      <span className="text-bold">focus on your daily motivators in this step.</span>
    </p>
  </>
)

export default MotivatorsDailyIntro
