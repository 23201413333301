const MenziesCELeadershipTraining2 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-large">
        Now that we understand the importance of leadership that cultivates collective efficacy and what it looks like,
        a question is, how do we foster it in your school? The first thing to note is that cultivating collective
        efficacy happens in stages, not overnight. The training and the Rising Team for Schools sessions will help you
        get started.
      </p>
      <h3 className="text-rising-orange mb-medium">Seek perspectives</h3>
      <p className="mb-medium">
        Everyone brings different perspectives. It's helpful to encourage others to contribute their thoughts and ideas,
        so everyone's voice is heard. The power of the collective rests in exploring the contributions and insights of
        the group.
      </p>
      <p className="mb-large">
        The activity is structured to help explore different perspectives through a series of questions and reflection
        tasks.
      </p>
      <h3 className="text-rising-orange mb-medium">Start with what's going well</h3>
      <p className="mb-large">
        We often focus on areas for improvement, and sometimes forget to look at what's working well and celebrate that.
        Take the opportunity through the activity to reflect and acknowledge strengths and achievements, no matter how
        small.
      </p>
      <h3 className="text-rising-orange mb-medium">Explore possibilities for improvement together</h3>
      <p className="mb-medium">
        There is no single path to improvement. The activity encourages the team to consider steps that could be taken
        to develop and grow collective efficacy.
      </p>
      <p className="mb-medium">
        Encouraging the team to identify ways they can work on developing collective efficacy in the context of their
        role can promote agency and something tangible to commit to.
      </p>
    </div>
  </div>
)

export default MenziesCELeadershipTraining2
