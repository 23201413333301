import { debounce } from "lodash-es"

import { StorageKey } from "utils/storage"

function isMediaQueryMatching(query: string): boolean {
  const trimmedQuery = query.replace(/(^\s*\(+\s*|\s*\)+\s*$)/g, "")
  const formattedQuery = `(${trimmedQuery})`
  return !!window.matchMedia(formattedQuery).matches
}

function isReducedMotionPreferred(): boolean {
  return isMediaQueryMatching("prefers-reduced-motion")
}

async function scrollToTop({ behavior = "smooth" }: { behavior?: ScrollBehavior } = {}): Promise<void> {
  if (isReducedMotionPreferred()) {
    behavior = "auto"
    // Make scroll operations happen instantaneously instead of being animated.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
  }

  if (window.scrollY !== 0) {
    // Create a promise that will resolve after
    // - one scroll event fires, THEN
    // - 200ms passes with no scroll events firing
    // This will effectively tell us when the browser scroll operation has completed.
    // Note: 200ms chosen because generally browser scroll events fire every 100ms,
    //       though the exact duration between events is browser-dependent.
    const scrollPromise = new Promise<void>((resolve) => {
      const debouncedResolve = debounce(() => {
        window.removeEventListener("scroll", debouncedResolve)
        resolve()
      }, 200)
      window.addEventListener("scroll", debouncedResolve)
    })
    window.scroll({ top: 0, behavior })
    await scrollPromise
  }
}

function onKeyEvent<T>(
  key: string,
  handlerFn: (keyEvent: KeyboardEvent, ...otherArgs: unknown[]) => T,
  { ctrl = false, shift = false, alt = false, meta = false, preventDefault = false, stopPropagation = false } = {}
) {
  return (keyEvent: KeyboardEvent, ...otherArgs: unknown[]) => {
    if (typeof keyEvent?.key === "string" && keyEvent.key.toLowerCase() === key) {
      if (
        (!ctrl || keyEvent.ctrlKey) &&
        (!shift || keyEvent.shiftKey) &&
        (!alt || keyEvent.altKey) &&
        (!meta || keyEvent.metaKey)
      ) {
        if (preventDefault) {
          keyEvent.preventDefault()
        }
        if (stopPropagation) {
          keyEvent.stopPropagation()
        }
        return handlerFn(keyEvent, ...otherArgs)
      }
    }
  }
}

const DEFAULT_IMPORT_RELOAD_DELAY_MS = 200
const DEFAULT_IMPORT_RELOAD_BLOCK_MS = 60_000

// Force-refresh the page if an error occurs loading a dynamic imports.
// We will ever only reload page once in this way to prevent edge-case reload loops.
let isForcedReloadScheduled = false
function forceOnePageReload({
  delayReloadMs = DEFAULT_IMPORT_RELOAD_DELAY_MS,
  blockReloadMs = DEFAULT_IMPORT_RELOAD_BLOCK_MS,
}: {
  delayReloadMs?: number
  blockReloadMs?: number
} = {}): boolean {
  // Only trigger another reload if one isn't already pending from another
  // concurrent safeDynamicImport call. Still return true if another reload
  // is pending, to indicate correctly to callers that reload will occur soon.
  if (isForcedReloadScheduled) {
    return true
  }

  const nowMs = new Date().getTime()
  const lastReloadMs = getLastForcedReloadTimeMs()

  // Reload page if no forced reload happened in last minute (avoid reload loops):
  if (!lastReloadMs || nowMs - lastReloadMs > blockReloadMs) {
    isForcedReloadScheduled = true
    sessionStorage.setItem(StorageKey.LastForcedReloadTimeMs, new Date().getTime().toString())
    // Delay reload slightly to increase chances of resolving import issues:
    // (eg. if CDN was momentarily down, more likely to be back after small wait)
    setTimeout(() => window.location.reload(), delayReloadMs)
    return true
  }

  return false
}

function getLastForcedReloadTimeMs(): number | null {
  const time = parseInt(sessionStorage.getItem(StorageKey.LastForcedReloadTimeMs) ?? "")
  return Number.isInteger(time) ? time : null
}

// Clean up any prior force-reload record from session storage after 1 minute:
setTimeout(() => {
  sessionStorage.removeItem(StorageKey.LastForcedReloadTimeMs)
}, DEFAULT_IMPORT_RELOAD_BLOCK_MS)

export {
  scrollToTop,
  onKeyEvent,
  isMediaQueryMatching,
  isReducedMotionPreferred,
  forceOnePageReload,
  getLastForcedReloadTimeMs,
}
