import cn from "classnames"
import { Form, Formik } from "formik"
import { styled } from "styled-components"

import ArtiChoiceInput from "./ArtiChoiceInput"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import ChoicesField from "forms/fields/ChoicesField"
import { MagnifyingGlassIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"

const ArtiTeamSelectField = styled(function ArtiTeamSelectField({
  className,
  teams,
  teamsIsFetching,
  selectedTeam,
  discussionTeamId,
  onDiscussionTeamChange,
}) {
  if (!teams || teamsIsFetching) {
    return null
  }

  //   TODO: check whether we still want to exclude jumbo teams from arti
  const availableUserTeamsOptions = teams.filter(({ jumbo }) => jumbo === false)

  const currentUserTeamsOptions = selectedTeam
    ? [availableUserTeamsOptions.find(({ id }) => id === discussionTeamId)]
    : availableUserTeamsOptions

  return (
    <Formik initialValues={{ discussionTeamId }} enableReinitialize>
      <Form className={cn(className, "arti-team-select pl-small")}>
        <View $flexDirection="row" $flexWrap="wrap" $gap="var(--spacing-2)">
          <ChoicesField
            noContainer
            name="discussionTeamId"
            type="radio"
            saveOnChange={(_name, value) => onDiscussionTeamChange({ value })}
            options={currentUserTeamsOptions.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            component={ArtiChoiceInput}
            disabled={!!selectedTeam}
            childrenAfterChoices={
              <AdvancedSelectField
                className="arti-teams-search"
                name="discussionTeamId"
                width="100%"
                hideCaret
                disabled={!!selectedTeam}
                placeholder={<span className="text-gray-9">Search for a team</span>}
                icon={<MagnifyingGlassIcon className="mr-xxs text-gray-9" />}
                onChange={onDiscussionTeamChange}
                options={teams.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                formatOptionLabel={({ label }) => (
                  <View $justifyContent="space-between">
                    <span className="text-semi-bold" title={label}>
                      {label}
                    </span>
                  </View>
                )}
              />
            }
          />
        </View>
      </Form>
    </Formik>
  )
})`
  .arti-teams-search {
    display: inline-block;
    min-width: 184px;
    vertical-align: top;
    font-weight: 500;
    color: var(--gray-9); /* select options inherit their text color from this */
    &.advanced-select--is-disabled {
      display: none;
    }
    .advanced-select__control {
      padding-bottom: 45px; /* align select bottom with adjacent choice options */
      border: 1px solid var(--white);
      border-radius: 4px;
      background-color: #fdc774;
      box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
      .advanced-select__input-container,
      .advanced-select__single-value {
        color: var(--gray-9);
      }
      &:hover:not(.disabled) {
        border: 1px solid var(--white);
        background-color: #fbe8ca;
      }
    }
  }
  .advanced-select__control--menu-is-open {
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white) !important; // important is necessary here to override hover bg color
  }
  .arti-member-select-new-conversation {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0px 0px 4px 0px rgba(255, 211, 89, 0.44);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0px 2px 4px 0px rgba(255, 211, 89, 0.75);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .arti-member-search {
      /* place search input on its own line on mobile so menu doesn't go offscreen: */
      display: block;
    }
  }
`
export default ArtiTeamSelectField
