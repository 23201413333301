const CrisisResponseTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        After you complete the session with your team, here are some tips on how to continue fostering a culture of
        collaborative crisis response.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Leverage Responder roles</h2>
      <p className="mb-medium">
        No doubt the team will continue to experience crises small and large. Now, equipped with an understanding of
        each team member’s natural response to such events, you have an opportunity to tap into people’s unique
        strengths. Whether it’s Firefighters jumping in to eliminate a problem or Fire Inspectors getting to the root
        cause to prevent repeat events, when a difficult situation emerges, empower team members to do what they do
        best.
      </p>

      <h2 className="text-rising-orange mb-medium">2. Ensure coverage across response types</h2>
      <p className="mb-medium">
        Even though people have more common natural response tendencies, anyone can be assigned to any role, as long as
        they are clear on what is expected of them in that role. The most important thing is for every necessary role to
        have coverage. Also, pay attention to Responder roles the team may be missing. Armed with this knowledge, you
        can proactively identify gaps in your response and be more intentional about filling them.
      </p>

      <h2 className="text-rising-orange mb-medium">3. Lean into your own strengths</h2>
      <p className="mb-medium">
        Just as important as leveraging team member's strengths and preferences is leaning into your own.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2020/07/7-strategies-for-promoting-collaboration-in-a-crisis"
        >
          HBR recommends
        </a>{" "}
        “Rather than trying to change your natural tendencies — which is almost impossible during the stress of a crisis
        — focus instead on consciously using your style to improve collaboration.” This will enable you to look to team
        members to play roles that don’t align with your natural style.
      </p>
      <p className="mb-medium">
        For example, if you naturally gravitate toward jumping in and fighting fires, then you can look to other team
        members who naturally gravitate to roles like Protector and Communicator. This not only enables the team to
        better respond to a crisis, but also decreases the likelihood of burnout.
      </p>

      <h2 className="text-rising-orange mb-medium">4. Be visible</h2>
      <p className="mb-medium">
        Regardless of the Responder role you most prefer to play, as the team leader, people look to you for both
        modeling and guidance. What you say and what you do have a lot of influence on the team. “In times of crisis,
        leaders can inspire trust by providing transparent and frequent information about what they know and what they
        do not know,” says the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://trainingindustry.com/blog/leadership/7-things-the-best-leaders-do-in-times-of-crisis/"
        >
          Training Industry Magazine
        </a>
        .
      </p>
      <p className="mb-medium">
        Whether that be daily team huddles, weekly check-in meetings, regular emails or instant messages, keeping team
        members informed is critical to how quickly the team can jump to solutions. And if you need support in doing
        this, look for your natural Communicators.
      </p>

      <h2 className="text-rising-orange mb-medium">5. Encourage two-way communication </h2>
      <p className="mb-medium">
        In addition to frequent communication, great leaders provide a venue for others to ask questions and voice
        concerns. How comfortable team members feel in sharing their thoughts and challenges can make a big difference
        in how well they are able to collaborate and take needed action. HBR reminds us that, “Communications should be
        two-way: listen to what your team is saying—and not saying. The information and insights they have may surprise
        you.”
      </p>
      <p className="mb-medium">
        There’s no magic formula or one-size-fits-all approach to responding to crises. It’s about knowing the skills
        and preferences of team members and ensuring collaboration and coverage when a crisis emerges.
      </p>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="ml-xl mb-medium">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.harvardbusiness.org/leading-your-team-through-a-crisis/"
          >
            Leading Your Team Through a Crisis
          </a>{" "}
          (Harvard Business Review)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ccl.org/articles/leading-effectively-articles/how-to-lead-through-a-crisis/"
          >
            How to Lead Through a Crisis
          </a>{" "}
          (Center for Creative Leadership)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2020/07/7-strategies-for-promoting-collaboration-in-a-crisis"
          >
            7 Strategies for Promoting Collaboration in a Crisis
          </a>{" "}
          (Harvard Business Review)
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/learning/leading-in-crisis">
            Leading in Crisis
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/inclusive-female-leadership"
          >
            Inclusive Female Leadership: Leading through a crisis
          </a>{" "}
          (LinkedIn Learning)
        </li>
      </ul>
    </div>
  </div>
)

export default CrisisResponseTraining3
