import { styled } from "styled-components"

const ResilienceTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <h2 className="text-rising-orange pb-medium">1. What is resilience</h2>
      <div className="pb-large">To get started, let's define resilience and why we need it in the workplace.</div>
      <h3 className="pb-medium">Resilience helps us bounce back</h3>
      <div className="pb-xs">
        Resilience is the ability to adapt well to change and quickly recover from setbacks. As most of us have
        experienced, we're living in a world that's constantly changing, resulting in moments of ambiguity and
        unpredictable challenges&mdash;just think global pandemic.
      </div>
      <div className="pt-xs pb-large">
        Resilience is what gives people the strength and emotional fortitude to cope with such demanding situations. At
        the core of similar qualities like "grit" or "mental toughness," resilience is something we tap into to overcome
        challenges, big or small, in any given moment.
      </div>
      <h3 className="pb-medium">It moves us from surviving to thriving</h3>
      <div className="pb-xs">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gallup.com/workplace/349484/state-of-the-global-workplace.aspx"
        >
          Gallup reports
        </a>{" "}
        that workers' daily stress is at a record high, "increasing from 38% in 2019 to 43% in 2020," and even higher in
        the US and Canada, at 57%. Virtual work, changing priorities and reorganizations are just a few of the workplace
        stressors we might face. All of which can leave us feeling drained, frustrated, and unable to stay focused.
      </div>
      <div className="pt-xs pb-large">
        Developing resilience not only helps us get through tough times, but also improves our health, performance, and
        overall happiness.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.health.harvard.edu/mind-and-mood/ramp-up-your-resilience"
        >
          Harvard Health Publishing reports
        </a>{" "}
        that resilience is "associated with longevity, lower rates of depression, and greater satisfaction with life."
      </div>
      <h2 className="text-rising-orange pb-medium">2. Resilience is a muscle we can build</h2>
      <div className="pb-large">
        The good news is that resilience is something we can learn. Like building a muscle, resilience needs to be
        worked on in order to get stronger. And when we flex this muscle it helps us manage our energy, tackle stress
        and avoid burnout.
      </div>
      <h3 className="text-gray-9 pb-medium">Resilience does not mean avoiding failure</h3>
      <div className="pb-large">
        Having high resilience doesn't mean avoiding difficult and demanding situations all together. In fact, building
        resilience often comes from experiencing failures, setbacks and even stress. When we encounter difficult
        situations, we can use them as an opportunity to flex our resilience muscle and practice new strategies,
        ultimately improving our ability to address future challenges. Resilient people aren't necessarily any better at
        avoiding challenges, but they do respond to them differently than people with low resilience.
      </div>
      <h3 className="text-gray-9 pb-medium">We can use multiple strategies to build resilience</h3>
      <div className="pb-large">
        Although many models exist to help us build resilience, one of the most practical is the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://cclinnovation.org/wp-content/uploads/2020/12/researchinsights_1220_rev1.pdf?webSyncID=65d05252-50c0-40c8-9447-45bccd09e041&sessionGUID=04b5c4d9-52ca-2387-b896-9ec86b682326"
        >
          Center for Creative Leadership's (CCLs) Comprehensive Resilience (CORE) Framework
        </a>
        . They break resilience into four areas. Physical, mental, emotional and social.
      </div>
      <div className="pb-large">
        <img
          src="https://static.risingteam.com/kit_assets/resilience/kit-resilience-training-1.1.png"
          alt="kit-resilience-why-resilient-teams-stronger"
        />
      </div>
      <div className="pb-xs">CCL describes each area as such:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            "Physical resilience is the body's capacity to respond to stressors with strength and stamina, as well as
            recover from injury.
          </li>
          <li>
            Mental resilience is the ability to maintain or regain cognitive capacities that risk degradation and to
            allow creativity to emerge.
          </li>
          <li>
            Emotional resilience centers on understanding, appreciating, and regulating emotions, and consciously
            choosing feelings and responses, rather than simply automatically reacting to the environment.
          </li>
          <li>
            Social resilience reflects an individual's capacity to work with others to endure and recover from
            stressors."
          </li>
        </ul>
      </div>
      <div className="py-xs">
        In the CCL's model, they point out that the areas are not independent, but rather overlapping. This allows for a
        more holistic approach when practicing resilience actions and behaviors.
      </div>
      <div className="py-xs">Here are some examples of activities that build resilience:</div>
      <div className="pt-xs ml-xl pb-large">
        <ul>
          <li>Eating well</li>
          <li>Exercising</li>
          <li>Getting enough sleep</li>
          <li>Taking timeouts or meditating</li>
          <li>Reframing threats as challenges</li>
          <li>Paying attention to negative self-talk</li>
          <li>Asking reflective questions</li>
          <li>Expressing gratitude for yourself and others</li>
          <li>Connecting regularly with a social network (in person or virtually)</li>
        </ul>
      </div>
      <h3 className="text-gray-9 pb-medium">Building resilience is a personal journey</h3>
      <div className="pb-large">
        We all have different backgrounds and skills, so the strategies we each use to develop resilience will also be
        specific to our own needs and comfort levels. In Time Magazine's article,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://time.com/collection/guide-to-happiness/3892044/the-science-of-bouncing-back/"
        >
          "The Science of Bouncing Back"
        </a>{" "}
        Dr. Dennis Charney, author of the book <em>Resilience: The Science of Mastering Life's Greatest Challenges</em>,
        says, "For resilience, there's not one prescription that works. You have to find what works for you." This is
        especially important to consider for leaders looking to create more resilient teams.
      </div>
      <h2 className="text-rising-orange pb-medium">
        3. Resilient teams are just as important as resilient individuals
      </h2>
      <div className="pb-large">
        While individual resilience is built independently, team resiliency can be cultivated by leadership.
      </div>
      <h3 className="text-gray-9 pb-medium">Leaders set the tone</h3>
      <div className="pb-xs">
        Resilience is a celebrated and admired leadership trait&mdash;and one we see brought to life in many of our
        favorite fictional heroes. Whether it's T'Challa from Black Panther continuously bouncing back from even the
        most difficult setbacks or Katniss Everdeen from The Hunger Games learning from past challenges to conquer new
        ones, resilient leaders have a big impact on the performance and well-being of those around them.
      </div>
      <div className="pt-xs pb-large">
        This is especially true in the face of crises. Remote working and social distancing have pushed many to their
        limits and tested our resilience.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ccl.org/articles/leading-effectively-articles/8-steps-help-become-resilient/"
        >
          The Center for Creative Leadership reminds us
        </a>{" "}
        that "in difficult times, your people are looking to you for emotional strength and courage as you remain
        positive and look for new opportunities. They're looking for you to set the direction and light the path."
      </div>
      <h3 className="text-gray-9 pb-medium">Team resilience comes with perks</h3>
      <div className="pb-xs">
        When leaders exhibit high levels of resilience, it not only has tangible benefits to team performance, but also
        provides a model of behavior that creates new cultural norms. When a team has high resilience they are able to:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>Improvise</li>
          <li>Respond quickly to change</li>
          <li>Take greater risks</li>
          <li>Create new solutions</li>
          <li>Believe in their abilities</li>
          <li>Demonstrate greater resistance to stress</li>
        </ul>
      </div>
      <div className="pt-xs pb-large">
        Team's with high resilience, especially the ability to better manage stress, also experience higher engagement.
        As we've{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gallup.com/workplace/236366/right-culture-not-employee-satisfaction.aspx"
        >
          learned from Gallup
        </a>
        , teams who score in the top 20% in engagement see 59% less employee turnover. On the flipside,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hcamag.com/us/specialization/learning-development/how-to-build-resilient-teams/254899"
        >
          HRD reports
        </a>{" "}
        that "employees with poor resilience are 55% less engaged at work."
      </div>
      <h3 className="text-gray-9 pb-medium">We all have the capacity to be resilient</h3>
      <div className="pb-xs">
        While resilience can seem difficult to find in some situations, we all have natural instincts we call on when
        forced to respond to a challenge. Building resilience as a team starts with understanding how we each personally
        respond, and then learning how others respond so we can leverage everyone's combined abilities to adapt.
      </div>
      <div className="pt-xs pb-xxl">
        That's a wrap on resilience. Now we're ready to talk about how we'll involve the team in building resilience
        together.
      </div>
    </div>
  </div>
)

export default styled(ResilienceTraining1)`
  img {
    max-width: 100%;
    height: auto;
  }

  li {
    color: var(--gray9);
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    .image-container {
      margin: var(--spacing-0);
    }
  }
`
