import { useExerciseContext } from "domains/Exercise/ExerciseContext"
import { smileRatingOptions } from "forms/fields/SmileRatingField"
import getIconOrError from "icons"
import Loading from "ui/Loading"
import RTBar from "ui/RTBar"
import RTGridTable from "ui/RTGridTable"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import View from "ui/View"
import { plural } from "utils/string"

const PieSliceGroupRatingFieldAggregatedMultiUserBarChart = ({
  identifiers,
  useAggregatedResultsData,
  has_sensitive_data,
  additional_data,
  className,
}) => {
  const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
    component: "PieSliceGroupRatingFieldAggregatedMultiUserBarChart",
    identifiers,
    has_sensitive_data,
    additional_data,
  })
  const theme = useCurrentTheme()
  const { exerciseInstance = null } = useExerciseContext()

  if (!aggregatedResults && isFetching) {
    return <Loading />
  }

  if (!aggregatedResults) {
    return null
  }

  if (aggregatedResults.insufficient_data) {
    return (
      <p className="p-large bg-gray-1">
        To maintain anonymity, we only share results when there are at least three team members with complete results.
      </p>
    )
  }

  // number of results that fit on one jumbo screen, or all results for smaller screens
  const numResultsToShow = theme.isWidescreen ? 5 : aggregatedResults.length
  const topResults = Object.values(aggregatedResults.results)
    .filter(({ votes }) => votes > 0)
    .sort((a, b) => b.votes - a.votes)
    .slice(0, numResultsToShow)
  const maxVotes = Math.max(...topResults.map(({ votes }) => votes))

  const headers = ["", "Votes", "Breakdown", "Average"]

  const rowItems = topResults.map(({ label, votes, rating_percentages, rating_average }) => {
    const {
      icon = null,
      color = null,
      label: ratingLabel = null,
    } = smileRatingOptions[Math.round(rating_average) - 1] ?? {}
    const Icon = icon && getIconOrError(icon)
    const percentageScale = maxVotes > 0 ? votes / maxVotes : 0
    const termDescriptions = exerciseInstance?.definition?.term_descriptions ?? {}
    const description = termDescriptions[label.toLowerCase()] ?? null
    const tooltip = (
      <>
        <div>
          <strong>
            {label}
            {!!description && ":"}
          </strong>{" "}
          {description ?? ""}
        </div>
        <div className="text-nowrap">
          <strong>{ratingLabel}</strong>
          {" on average"}
        </div>
      </>
    )
    const ariaLabel = `${label}: ${plural(votes, "vote")} ${ratingLabel} on average`
    const averageIcon = !!Icon && <Icon color={color} className={theme.isWidescreen ? "fa-2x" : "fa-xl"} />
    const barChart = (
      <View aria-hidden className="full-width align-self-center">
        {smileRatingOptions.map((ratingOption, idx) => {
          const percentage = rating_percentages?.[idx + 1] ?? 0
          return (
            percentage > 0 && (
              <RTBar
                key={idx}
                color={ratingOption.color}
                height={theme.isWidescreen ? "48px" : "16px"}
                width={`${percentage * percentageScale}%`}
                tooltip={`${ratingOption.label}: ${Math.round(percentage)}%`}
              />
            )
          )
        })}
      </View>
    )

    return {
      ariaLabel,
      columns: [
        { component: label, tooltip },
        { component: votes, tooltip, className: "justify-content-right" },
        { component: barChart },
        { component: averageIcon, tooltip, className: "justify-content-center" },
      ],
    }
  })

  return (
    <RTGridTable
      headers={headers}
      rowItems={rowItems}
      numColumns={4}
      gridTemplateColumns="max-content max-content auto max-content"
      gridTemplateColumnsMobile="max-content auto max-content"
      className={className}
    />
  )
}

export default PieSliceGroupRatingFieldAggregatedMultiUserBarChart
