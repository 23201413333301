import { mapValues, map, isEmpty, reduce } from "lodash-es"

import { CHART_MAX_VALUE, getCategoryToPointsMap, getChartValues, pointsToBucketIndex } from "./radarchart"

// There are 6 responder types and 5 questions.
// One way to understand the distribution is to imagine the point distribution of 5 consecutive dice rolls.
// That is a bell curve that ranges from 5-30 (this ranges from 0-25 which is the same thing).
const MAX_POINTS_PER_BUCKET = [7, 13, 17, 25]
const BUCKETS = ["Low", "Medium", "High", "Very High"]

const RESPONDER_TYPES_DATA = {
  Communicator: {
    title: "Communicator",
    role: "“I keep everyone on the same page.”",
    description:
      "Communicators connect people to information. They identify how the crisis is impacting systems and people and ensure everyone knows what’s happening.",
  },
  Firefighter: {
    title: "Firefighter",
    role: "“I jump into action to tackle the challenge”",
    description:
      "Firefighters are the fixers, ready to leap into action. They are often energized by a crisis and willing to do whatever it takes to eliminate the immediate threat.",
  },
  Inspector: {
    title: "Inspector",
    role: "“I discover the root cause to prevent this in the future”",
    description:
      "Inspectors find out the root cause of the crisis. They unearth the processes and decision-making that led to a setback to prevent it from happening again.",
  },
  "Mission Control": {
    title: "Mission Control",
    role: "“I combine our efforts for maximum impact”",
    description:
      "Mission Control looks at the bigger picture during a crisis to make sure everyone’s work fits together efficiently and effectively.",
  },
  Paramedic: {
    title: "Paramedic",
    role: "“I take care of the people who are impacted”",
    description:
      "Paramedics help other people in a crisis. They know challenges are stressful and make sure that people have the care and resources available to get through it.",
  },
  Protector: {
    title: "Protector",
    role: "“I ensure we do things right and don’t make it worse”",
    description:
      "Protectors like to ensure potential solutions are sustainable and high quality so “quick fixes” don’t cause future problems down the road.",
  },
}

const processRespondersData = (exercise) => {
  const responderPoints = getCategoryToPointsMap(exercise)
  const sortedResponderTypes = Object.keys(responderPoints).sort((a, b) => responderPoints[b] - responderPoints[a])
  const chartValues = getChartValues(responderPoints, MAX_POINTS_PER_BUCKET)
  const responderBucketIndices = mapValues(responderPoints, (points) =>
    pointsToBucketIndex(points, MAX_POINTS_PER_BUCKET)
  )
  const responderBuckets = mapValues(responderBucketIndices, (bucketIndex) => BUCKETS[bucketIndex])

  return {
    exercise,
    responderPoints,
    sortedResponderTypes,
    chartValues,
    responderBucketIndices,
    responderBuckets,
  }
}

const getChartConfig = (exercises, userIdMap) => {
  if (isEmpty(exercises)) {
    return { data: [] }
  }

  const processedExercises = exercises.map(processRespondersData)
  const names = exercises.map((exercise) => userIdMap[exercise.user_id].short_name)
  // The radar chart has one ring per bucket plus an extra ring for aesthetic reasons
  const gridLevels = MAX_POINTS_PER_BUCKET.length + 1

  return {
    keys: names,
    indexBy: "responderType",
    maxValue: CHART_MAX_VALUE,
    gridLevels,
    valueFormat: (value) => Math.round(value),
    tooltipFormat: (value) => Math.round(value),
    data: map(RESPONDER_TYPES_DATA, ({ title }, responderType) =>
      reduce(
        processedExercises,
        (chartConfig, processedExercise) => {
          const { exercise, chartValues } = processedExercise
          const key = userIdMap[exercise.user_id].short_name
          chartConfig[key] = chartValues[responderType] ?? 0
          return chartConfig
        },
        { responderType: title }
      )
    ),
  }
}

const mashupExercises = (exercises, userIdMap) => {
  if (isEmpty(exercises)) {
    return {
      processedExercises: [],
      numHighResponderTypes: 0,
      peopleWithResponderType: [],
    }
  }

  const processedExercises = exercises.map(processRespondersData)
  const responderTypes = Object.keys(RESPONDER_TYPES_DATA)

  const maxBucketIndexPerResponderType = responderTypes.reduce((acc, responderType) => {
    const exerciseBucketIndicesForResponderType = processedExercises.map(
      ({ responderBucketIndices }) => responderBucketIndices[responderType]
    )
    const maxBucketIndex = Math.max(...exerciseBucketIndicesForResponderType)
    return { ...acc, [responderType]: maxBucketIndex }
  }, {})

  const numHighResponderTypes = responderTypes.filter(
    (responderType) => maxBucketIndexPerResponderType[responderType] >= 2
  ).length

  const peopleWithResponderType = responderTypes.reduce((acc, responderType) => {
    const processedExercisesWithResponderType = processedExercises.filter(
      ({ responderBucketIndices }) => responderBucketIndices[responderType] >= 2
    )
    const people = processedExercisesWithResponderType.map((x) => userIdMap[x.exercise.user_id])
    return { [responderType]: people, ...acc }
  }, {})

  return {
    processedExercises,
    numHighResponderTypes,
    peopleWithResponderType,
  }
}

export { MAX_POINTS_PER_BUCKET, BUCKETS, RESPONDER_TYPES_DATA, processRespondersData, getChartConfig, mashupExercises }
