import cn from "classnames"
import { useNavigate } from "react-router-dom"

import Button, { type ButtonProps } from "./Button"

import { ArrowLeftIcon } from "icons/FontAwesomeIcons"

interface BackButtonProps extends ButtonProps {
  className?: string
  navigateTo?: string
  onClick?: (event: MouseEvent) => void
  onNavigate?: () => Promise<boolean>
}

const BackButton = ({ className, navigateTo, onClick, onNavigate, ...props }: BackButtonProps) => {
  const navigate = useNavigate()
  const handleOnClick = async (event: MouseEvent) => {
    if (onClick) {
      onClick(event)
    } else {
      let cancelNavigation = false
      if (onNavigate) {
        cancelNavigation = await onNavigate()
      }
      if (!cancelNavigation) {
        if (navigateTo) {
          navigate(navigateTo)
        } else {
          navigate(-1)
        }
      }
    }
  }
  return (
    <Button className={cn(className, "nav-button")} onClick={handleOnClick} {...props}>
      <ArrowLeftIcon />
      Back
    </Button>
  )
}

export default BackButton
export type { BackButtonProps }
