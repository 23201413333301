import { useEffect } from "react"

const NOT_PASSED = Symbol() // guaranteed to not match any other value

export default function useEffectAfterEveryRender(effectFunc, dependencyArray = NOT_PASSED) {
  if (dependencyArray !== NOT_PASSED) {
    throw new Error(
      "useEffectAfterEveryRender: Never pass a dependency array as the 2nd argument." +
        "\nINCORRECT:  useEffectAfterEveryRender(() => { ... }, <anything> )" +
        "\n  CORRECT:  useEffectAfterEveryRender(() => { ... })"
    )
  }
  // eslint-disable-next-line no-restricted-syntax
  useEffect(effectFunc)
}
