import cn from "classnames"

import ArtiChatInputArea from "./ArtiChatInputArea"
import ArtiChatMessages from "./ArtiChatMessages"
import ArtiTeamMemberOptedOutMessage from "./ArtiTeamMemberOptedOutMessage"
import ArtiUserMemberSelectorMessage from "./ArtiUserMemberSelectorMessage"

const ArtiConversationTypeTeamMember = ({
  user,
  team,
  llm,
  selectedMember,
  teamMembers,
  memberId,
  onMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
  showTeamSummary,
  conversationTypeSelected,
  isFetchingHistorySession,
  messages,
  loading,
  historyExchangeId,
  chatInputRef,
  artiTourPopularTopicsMenuOpen,
  isComponentMounted,
  onSubmit,
}) => {
  const memberOptedOut = selectedMember?.arti_disabled

  return (
    <>
      <ArtiUserMemberSelectorMessage
        user={user}
        team={team}
        llm={llm}
        selectedMember={selectedMember}
        teamMembers={teamMembers}
        memberId={memberId}
        onMemberChange={onMemberChange}
        memberIdTeamNameMap={memberIdTeamNameMap}
        onStartNewConversation={onStartNewConversation}
        showTeamSummary={showTeamSummary}
        conversationTypeSelected={conversationTypeSelected}
      />
      {!memberOptedOut ? (
        <div
          className={cn("fadeable conversation-container", {
            hidden: !selectedMember || isFetchingHistorySession,
          })}
        >
          <ArtiChatMessages
            messages={messages}
            user={user}
            team={team}
            loading={loading}
            llm={llm}
            historyExchangeId={historyExchangeId}
            chatInputRef={chatInputRef}
          />
          <ArtiChatInputArea
            loading={loading}
            isComponentMounted={isComponentMounted}
            onSubmit={onSubmit}
            chatInputRef={chatInputRef}
            messages={messages}
            user={user}
            team={team}
            selectedMember={selectedMember}
            artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
            conversationTypeSelected={conversationTypeSelected}
            className="mb-medium"
          />
        </div>
      ) : (
        <ArtiTeamMemberOptedOutMessage selectedMember={selectedMember} />
      )}
    </>
  )
}

export default ArtiConversationTypeTeamMember
