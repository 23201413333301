import cn from "classnames"
import { useField } from "formik"
import { styled, css } from "styled-components"

import getIconOrError from "icons"
import { CircleInfoIcon } from "icons/FontAwesomeIcons"
import InputRadio from "ui/InputRadio"
import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const IconChoiceInput = styled(function IconChoiceInput({
  name,
  value,
  label,
  type,
  icon,
  className,
  onBlur,
  checked,
  disabled,
  onChange,
  isFullWidthMobile,
  initialValue: _, // omit from props to avoid "React does not recognize the `initialValue` prop on a DOM element" warning.
  onCustomChange: __, // omit from props to avoid "React does not recognize the `onCustomChange` prop on a DOM element" warning.
  maxWidth: ___, // omit from props to avoid "React does not recognize the `maxWidth` prop on a DOM element" warning.
  removeChoiceInputFontWeight: ____, // omit from props to avoid "React does not recognize the `removeChoiceInputFontWeight` prop on a DOM element" warning.
  use_custom_colors, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  ...props
}) {
  const Icon = icon ? getIconOrError(icon) : () => null
  return (
    <View as="label" className={cn(className, { "cursor-default": disabled })}>
      <input type={type} name={name} onChange={onChange} onBlur={onBlur} value={value} checked={checked} {...props} />
      <View
        $alignItems={isFullWidthMobile ? "flex-start" : "center"}
        $justifyContent="center"
        className={cn("box px-xs", isFullWidthMobile ? "py-xxs" : "py-medium")}
        $flexDirection="column"
        role={type}
        aria-checked={checked}
        aria-disabled={disabled}
      >
        <div>
          {!!Icon && <Icon className="icon" />}
          <div className="text-exercise-button">
            <PurifyInnerHTMLDiv>{label}</PurifyInnerHTMLDiv>
          </div>
        </div>
      </View>
    </View>
  )
})`
  text-align: center;
  user-select: none;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "176px")};

  & > div {
    height: 100%;
    color: var(--gray-8);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: #fff;
    box-shadow: var(--blur-4);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
  }
  &:hover > div,
  &:focus-visible > div {
    box-shadow: var(--lift-2);
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .text-exercise-button {
    font-size: 0.9375rem; // ~15px
    line-height: 1.313rem; // ~21px
    ${({ isFullWidthMobile }) => !!isFullWidthMobile && "align-content: center;"}
  }

  ${({ isFullWidthMobile }) =>
    isFullWidthMobile &&
    css`
      @media (max-width: ${({ theme }) => theme.mobileMax}) {
        .box > div {
          display: flex;
          gap: 8px;
        }
      }
    `}

  .icon {
    color: ${({ color, use_custom_colors }) => (!!color && !!use_custom_colors ? color : "var(--gray-7)")};
  }
  .text-exercise-button {
    color: var(--gray-7);
  }

  &:hover .icon,
  &:focus-visible .icon {
    color: ${({ color, use_custom_colors }) => (!!color && !!use_custom_colors ? color : "var(--gray-8)")};
  }
  &:hover .text-exercise-button,
  &:focus-visible .text-exercise-button {
    color: var(--gray-8);
    ${({ removeChoiceInputFontWeight }) => !removeChoiceInputFontWeight && "font-weight: 600;"}
  }

  input {
    display: none;
    appearance: none;
  }
  input[type="radio"]:checked + div .icon,
  input[type="checkbox"]:checked + div .icon {
    color: ${({ color, use_custom_colors }) => (!!color && !!use_custom_colors ? color : "#fff")};
  }
  input[type="radio"]:checked + div .text-exercise-button,
  input[type="checkbox"]:checked + div .text-exercise-button {
    color: ${({ text_color, use_custom_colors }) =>
      !!text_color && !!use_custom_colors ? `var(--${text_color})` : "#fff"};
    ${({ removeChoiceInputFontWeight }) => !removeChoiceInputFontWeight && "font-weight: 600;"}
  }
  &:hover input[type="radio"]:checked + div .icon,
  &:hover input[type="checkbox"]:checked + div .icon,
  &:focus-visible input[type="radio"]:checked + div .icon,
  &:focus-visible input[type="checkbox"]:checked + div .icon {
    color: ${({ color, use_custom_colors }) => (!!color && !!use_custom_colors ? color : "var(--white-2)")};
  }
  &:hover input[type="radio"]:checked + div .text-exercise-button,
  &:hover input[type="checkbox"]:checked + div .text-exercise-button,
  &:focus-visible input[type="radio"]:checked + div .text-exercise-button,
  &:focus-visible input[type="checkbox"]:checked + div .text-exercise-button {
    color: ${({ text_color, use_custom_colors }) =>
      !!text_color && !!use_custom_colors ? `var(--${text_color})` : "var(--white-2)"};
  }
  input[type="radio"]:checked + div,
  input[type="checkbox"]:checked + div {
    box-shadow: var(--lift-4);
    background-color: ${({ background_color, use_custom_colors }) =>
      !!background_color && !!use_custom_colors ? background_color : "var(--green-3)"};
    border-color: ${({ background_color, use_custom_colors }) =>
      !!background_color && !!use_custom_colors ? background_color : "var(--green-3)"};
  }
  &:hover input[type="radio"]:checked + div,
  &:hover input[type="checkbox"]:checked + div,
  &:focus-visible input[type="radio"]:checked + div,
  &:focus-visible input[type="checkbox"]:checked + div {
    box-shadow: var(--lift-2);
    background-color: ${({ background_color, use_custom_colors }) =>
      !!background_color && !!use_custom_colors ? background_color : "var(--green-4)"};
    border-color: ${({ background_color, use_custom_colors }) =>
      !!background_color && !!use_custom_colors ? background_color : "var(--green-4)"};
  }
  .box {
    min-height: 46px;
  }
`

const IconChoiceInputFullWidth = styled(function IconChoiceInputFullWidth({
  name,
  value,
  label,
  type,
  icon,
  className,
  onBlur,
  checked,
  disabled,
  onChange,
  initialValue: _, // omit from props to avoid "React does not recognize the `initialValue` prop on a DOM element" warning.
  onCustomChange: __, // omit from props to avoid "React does not recognize the `onCustomChange` prop on a DOM element" warning.
  ...props
}) {
  const Icon = icon ? getIconOrError(icon) : () => null
  return (
    <View as="label" className={cn(className, { "cursor-default": disabled })}>
      <input type={type} name={name} onChange={onChange} onBlur={onBlur} value={value} checked={checked} {...props} />
      <View
        $alignItems="center"
        $justifyContent="center"
        className="box p-medium"
        $flexDirection="column"
        role="checkbox"
        aria-checked={checked}
        aria-disabled={disabled}
      >
        <View $flexDirection="row" $alignItems="center">
          <div className="pr-large pl-xs">{!!Icon && <Icon className="icon" />}</div>
          <div className="text-exercise-button text-semi-bold">
            <PurifyInnerHTMLDiv>{label}</PurifyInnerHTMLDiv>
          </div>
        </View>
      </View>
    </View>
  )
})`
  text-align: left;
  user-select: none;

  & > div {
    height: 100%;
    background-color: #fff;
    box-shadow: var(--blur-1);
    border-radius: 8px;
  }
  &:hover > div,
  &:focus-visible > div {
    box-shadow: var(--lift-2);
    border-radius: 8px;
  }

  .icon {
    width: 32px;
    height: 32px;
    color: var(--gray-6);
  }

  input {
    display: none;
    appearance: none;
  }
  input[type="radio"]:checked + div .icon,
  input[type="checkbox"]:checked + div .icon {
    color: var(--rising-blue);
  }
  &:hover input[type="radio"]:checked + div .icon,
  &:hover input[type="checkbox"]:checked + div .icon,
  &:focus-visible input[type="radio"]:checked + div .icon,
  &:focus-visible input[type="checkbox"]:checked + div .icon {
    color: var(--rising-blue);
  }
  input[type="radio"]:checked + div,
  input[type="checkbox"]:checked + div {
    box-shadow: var(--blur-1);
    background-color: #ebf4fe;
    border-radius: 8px;
  }
  &:hover input[type="radio"]:checked + div,
  &:hover input[type="checkbox"]:checked + div,
  &:focus-visible input[type="radio"]:checked + div,
  &:focus-visible input[type="checkbox"]:checked + div {
    box-shadow: var(--blue-lift);
    background-color: #ebf4fe;
    border-radius: 8px;
  }
  .box {
    min-height: 90px;
  }
`

const Choice = ({
  name,
  value,
  label,
  onChange,
  onBlur,
  checked,
  type,
  icon,
  disabled,
  tooltip,
  className,
  option,
  exerciseComponentNameMap = {},
  onCustomChange,
}) => {
  const CustomFieldComponent = !!option?.custom_field && exerciseComponentNameMap[option?.custom_field?.component]
  return (
    <View className={cn(className, { "cursor-default": disabled })} $alignItems="center" as="label">
      <InputRadio
        type={type}
        className="ml-none mr-small"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        checked={checked}
        icon={icon}
        disabled={disabled}
      />
      <div className="text-gray-9" style={{ userSelect: "none" }}>
        <Tooltip top float key={value} title={tooltip} disabled={!tooltip}>
          <View $alignItems="center" $justifyContent="center">
            {!!option?.custom_field ? (
              <CustomFieldComponent
                name={option?.custom_field?.identifier}
                saveOnChange={onCustomChange}
                placeholder="Other"
                className="custom-field ml-xs"
              />
            ) : (
              <PurifyInnerHTMLDiv>{label}</PurifyInnerHTMLDiv>
            )}
            {!!tooltip && <CircleInfoIcon className="text-gray-8 ml-xxs" />}
          </View>
        </Tooltip>
      </div>
    </View>
  )
}

const Choices = ({
  options,
  name,
  onChange,
  onBlur,
  value,
  type,
  individualChoiceClassName,
  component: AsComponent = Choice,
  ...props
}) =>
  options.map((option) => (
    <AsComponent
      className={individualChoiceClassName}
      key={option.value}
      name={name}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      checked={type === "radio" ? value === option.value : value?.includes(option.value)}
      option={option}
      {...option}
      {...props}
    />
  ))

export default function ChoicesField({
  saveOnChange,
  className,
  style,
  individualChoiceClassName,
  childrenBeforeChoices = null,
  childrenAfterChoices = null,
  container = "div",
  noContainer = false,
  ...props
}) {
  const [field, _meta, { setValue }] = useField(props.name)

  function handleOnChange(evt) {
    const { value, type } = evt.target
    let newValue = value
    if (type === "checkbox") {
      const fieldValue = field?.value ?? []
      if (!!props.limit && fieldValue.length >= props.limit && !fieldValue.includes(value)) {
        return
      }
      newValue = fieldValue.includes(value) ? fieldValue.filter((v) => v !== value) : [...fieldValue, value]
    }
    setValue(newValue)
    saveOnChange?.(field.name, newValue)
  }

  function handleOnCustomChange(fieldName, value) {
    saveOnChange?.(fieldName, value)
  }

  const Container = container
  const content = (
    <>
      {childrenBeforeChoices}
      <Choices
        {...props}
        {...field}
        value={props.value || field.value}
        onChange={handleOnChange}
        onCustomChange={handleOnCustomChange}
        individualChoiceClassName={individualChoiceClassName}
      />
      {childrenAfterChoices}
    </>
  )

  return noContainer ? (
    content
  ) : (
    <Container style={style} className={className}>
      {content}
    </Container>
  )
}

export { IconChoiceInput, IconChoiceInputFullWidth, Choice, Choices }
