import { sum } from "lodash-es"
import { useRef } from "react"
import { styled } from "styled-components"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import PieChart from "ui/PieChart"
import { colors as themeColors, getRepeatingColorFunc } from "ui/theme"
import { adjustRadialChartLabelsToAvoidOverlap } from "utils/chart"
import { capitalize, plural } from "utils/string"

const PieSliceChart = ({
  data,
  colors = null,
  availableSlices = 0,
  separateSlices = false, // if true, show white gaps between slices of same color
  width = null,
  height = null,
  getAriaAdditionalInfo = null,
  className,
  ...props
}) => {
  const containerRef = useRef(null)
  const totalSlices = availableSlices + sum(data.map(({ value }) => value))

  // Apply vertical label adjustment to two topmost and bottommost labels
  // to avoid labels overlapping when greater than 20 slices are shown:
  useEffectAfterChange(() => {
    // No adjustment needed when chart uses 15 or fewer slices:
    if (containerRef.current && totalSlices > 15) {
      // Wait for chart render to finish; requestAnimationFrame not sufficient:
      setTimeout(() => {
        // Need to make these checks again in case state changed since wait started:
        if (containerRef.current && totalSlices > 15) {
          adjustRadialChartLabelsToAvoidOverlap(containerRef.current)
        }
      }, 100)
    }
  }, [data, totalSlices, width, height])

  if (!data) {
    return null
  }

  // Optionally, accept an array of colors instead of a color function:
  if (Array.isArray(colors)) {
    colors = getRepeatingColorFunc(
      data.map(({ id }) => id),
      colors,
      { sortKeys: false }
    )
  }

  let parsedSlices
  if (!separateSlices) {
    // If slices are not "separated" just add a key to each entry in data:
    parsedSlices = data.map(({ id, ...other }) => ({ id, key: id, ...other }))
  } else {
    // "Separated" slice style shows a white gap between each pie slice in chart.
    // This is done by splitting each entry with value=N in data into N separate
    // entries each with value=1:
    parsedSlices = data.flatMap(({ id, value, label, ...other }) =>
      Array(value)
        .fill()
        .map((_, idx) => ({
          id: `${id}_${idx}`,
          key: id,
          label: Math.ceil(value / 2) === idx + 1 && (label ?? capitalize(id)),
          value: 1,
          ...other,
        }))
    )
  }

  const unknownSlices = Array(availableSlices)
    .fill()
    .map((_, idx) => ({
      id: `unknown_${idx}`,
      key: "unknown",
      label: false,
      value: 1,
    }))

  const displaySlices = [...parsedSlices, ...unknownSlices]
  const sliceValuesForAriaLabel = displaySlices
    .map((dataItem) => {
      if (!dataItem.label) {
        return null
      }
      const additionalInfoStr = getAriaAdditionalInfo ? getAriaAdditionalInfo(dataItem) : ""
      return `${dataItem.label}: ${plural(dataItem.value, "slice")} ${additionalInfoStr}`
    })
    .filter(Boolean)
  const ariaLabel = `Chart of pie slices with the following values: ${sliceValuesForAriaLabel.join(", ")}`

  return (
    <div aria-label={ariaLabel} className={className} ref={containerRef}>
      <PieChart
        data={displaySlices}
        innerRadius={0.15}
        arcLinkLabel={(d) => d.label && <tspan>{d.label}</tspan>}
        arcLinkLabelsColor={(d) => (d.label ? themeColors.gray9 : "transparent")}
        minimumLabelPercentage={4 /* prevents single-slice labels from being hidden*/}
        padAngle={2}
        animate={false}
        colors={({ data }) => colors({ id: data.key })}
        arcLinkLabelsStraightLength={0}
        {...props}
      />
    </div>
  )
}

export default styled(PieSliceChart)`
  width: ${({ width }) => width ?? "100%"};
  height: ${({ height }) => height ?? "400px"};

  ${({ theme }) =>
    theme.isWidescreen
      ? `
        tspan {
          font-size: 1.5rem;
          line-height: 2rem;
        }
        `
      : `
        tspan {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
        `}

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    height: 225px;

    tspan {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
`
