import { Formik, Form } from "formik"
import { isEmpty } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import { RoleTooltip } from "./CrisisResponseScenarioResultStep"

import ExerciseRichTextField from "domains/Exercise/ExerciseComponents/ExerciseRichTextField"
import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance, getExerciseAnswer, getTeamLead } from "domains/Exercise/results_utils"
import { RESPONDER_TYPES_DATA } from "domains/Exercise/results_utils/crisis_response"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { InfoCircleIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const CrisisResponseScenarioIdentifyStep = ({ className, sessionStep }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const exercise = kitInstance?.kit?.exercise
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { initialValues, onSubmit, saveOnChange } = useExerciseForm({
    teamId: team.id,
    slug: exercise?.slug,
    version: exercise?.version,
  })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })
  const { data: user } = useUser({ userId: "me" })
  const { facilitator_tip, facilitator_tip_icon, facilitator_tip_full_width_step_body } = sessionStep

  if (!participants || !user || !exerciseInstances || !initialValues) {
    return <Loading />
  }

  const teamLead = getTeamLead(participants, team)

  const exerciseInstance = getUserExerciseInstance(exerciseInstances, teamLead)
  const team_scenario = getExerciseAnswer(exerciseInstance, "team_scenario")
  const team_responder_assign = getExerciseAnswer(exerciseInstance, "team_responder_assign")
  const user_responder_assign = (team_responder_assign && team_responder_assign[user.id]) ?? []

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <div className="text-gray-9 full-width">
          <h2 className="text-gray-9 mb-medium">Identify your responses</h2>
          <p className="mb-xl" style={{ maxWidth: 550 }}>
            You were assigned the following roles during this scenario. Describe what actions you would take to fulfill
            each role.
          </p>
          <p className="text-field-label text-gray-9 mb-xs">
            <span className="text-bold">Your team scenario</span> (for reference)
          </p>
          <AnswerDisplayContent className="mb-xl" answer={team_scenario} />
          {!isEmpty(user_responder_assign) ? (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              <Form className="text-gray-9">
                <div className="space-y-large">
                  {user_responder_assign.map((responder) => (
                    <div key={responder} className="space-y-xs">
                      <View className="mb-xs">
                        <h3 className="text-bold text-gray-9 mr-xs">{responder}</h3>
                        <RoleTooltip role={responder}>
                          <InfoCircleIcon className="text-rising-blue" />
                        </RoleTooltip>
                      </View>
                      <p className="text-gray-8">{RESPONDER_TYPES_DATA[responder].role}</p>
                      <p className="text-field-label">Your response:</p>
                      <ExerciseRichTextField
                        name={`own_${responder.toLowerCase().trim().split(/\s+/).join("_")}`}
                        saveOnChange={saveOnChange}
                      />
                    </div>
                  ))}
                </div>
              </Form>
            </Formik>
          ) : (
            <div>No assigned roles</div>
          )}
        </div>
        <FacilitatorTip
          className="sidebar-right"
          tip={facilitator_tip}
          icon={facilitator_tip_icon}
          positionAbsolute={facilitator_tip_full_width_step_body}
        />
      </SidebarContainer>
    </StepContent>
  )
}

export default CrisisResponseScenarioIdentifyStep
