import cn from "classnames"
import { styled } from "styled-components"

import ContactOptions from "./ContactOptions"

import { useUser } from "resources/users"

interface NotFoundProps {
  className?: string
}

const NotFound = ({ className }: NotFoundProps) => {
  const { data: user } = useUser({ userId: "me" })
  return (
    <div className={cn("main-container", className)}>
      <h2 className="mb-large">Oops, that page wasn't found.</h2>
      <p className="mt-large">
        Please try a different URL, or{" "}
        <a href="mailto:hello@risingteam.com" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{" "}
        and we'll assist you shortly.
      </p>
      <ContactOptions user={user} />
    </div>
  )
}

export default styled(NotFound)`
  width: 100%;
  text-align: center;
`

export type { NotFoundProps }
