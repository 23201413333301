function SvgSlackIcon({ size = "1em", ...props }) {
  return (
    <svg viewBox="0 0 48 48" width={size} height={size} {...props}>
      <path
        d="M18.133 2a4.4 4.4 0 1 0 0 8.8h4.4V6.4a4.4 4.4 0 0 0-4.4-4.4M18.133 13.733H6.4a4.4 4.4 0 1 0 0 8.8h11.734a4.4 4.4 0 0 0 0-8.8"
        fill="#36c5f0"
      />
      <path
        d="M46 18.134a4.4 4.4 0 1 0-8.8 0v4.4h4.4a4.4 4.4 0 0 0 4.4-4.4M34.267 18.134V6.4a4.4 4.4 0 1 0-8.8 0v11.733a4.4 4.4 0 1 0 8.8 0"
        fill="#2eb67d"
      />
      <path
        d="M29.866 46a4.4 4.4 0 1 0 0-8.8h-4.4v4.4a4.4 4.4 0 0 0 4.4 4.4M29.866 34.267H41.6a4.4 4.4 0 1 0 0-8.8H29.866a4.4 4.4 0 0 0 0 8.8"
        fill="#ecb22e"
      />
      <path
        d="M2 29.866a4.4 4.4 0 0 0 4.4 4.4 4.4 4.4 0 0 0 4.4-4.4v-4.4H6.4a4.4 4.4 0 0 0-4.4 4.4M13.733 29.866V41.6a4.4 4.4 0 1 0 8.8 0V29.866a4.4 4.4 0 0 0-4.4-4.4 4.4 4.4 0 0 0-4.4 4.4"
        fill="#e01e5a"
      />
    </svg>
  )
}

export default SvgSlackIcon
