import { Form, Formik } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import SaveButton from "domains/Admin/SaveButton"
import EmailField from "forms/fields/EmailField"
import ValidatedField from "forms/fields/ValidatedField"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { useInviteTeamMember } from "resources/teams"
import View from "ui/View"

const initialValues = { email: "" }
const schema = Yup.object().shape({
  email: Yup.string().required("Please enter an email."),
})

const TeamMemberField = styled(function TeamMemberField({ className, team }) {
  const { mutateAsync: inviteTeamMember } = useInviteTeamMember({ teamId: team.id, accountId: team.account_id })
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isError, setIsError] = useState(false)

  const onSubmit = handleErrors(
    async (values, formik) => {
      setIsSaved(false)
      setIsSaving(true)
      await inviteTeamMember(values.email)
      setIsSaving(false)
      setIsSaved(true)
      formik.setValues(initialValues)
    },
    (error) => {
      setIsError(true)
      return error
    }
  )

  function reset(setErrors) {
    setIsSaving(false)
    setIsSaved(false)
    setIsError(false)
    setErrors({})
  }

  return (
    <div className={className}>
      <div className="text-semi-bold mb-xs">Add a new team member</div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ values, setValues, setErrors }) => (
          <Form>
            <View $alignItems="top">
              <ValidatedField
                base={EmailField}
                name="email"
                size="medium"
                autoComplete="off"
                value={values.email}
                onClick={() => reset(setErrors)}
                onClear={() => setValues({ email: "" })}
              />
              <SaveButton className="ml-large" saving={!!isSaving} saved={!!isSaved && "Invite sent!"} error={isError}>
                Send invite
              </SaveButton>
            </View>
          </Form>
        )}
      </Formik>
    </div>
  )
})`
  label {
    width: 330px;
  }
`

export default TeamMemberField
