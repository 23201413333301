import { styled } from "styled-components"

import listStyles from "ui/styles/list"

const NumberedList = styled.ol`
  padding: 0;
  ${listStyles}
`

export default NumberedList
