import { styled } from "styled-components"

const VimeoVideo = ({ className, videoId }) => (
  <div className={className}>
    <iframe
      src={`https://player.vimeo.com/video/${videoId}`}
      title="Vimeo video player"
      frameBorder="0"
      allowFullScreen
      width="100%"
      className="video-iframe"
      // passes the origin to the embedded iframe so vimeo can verify the domain
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </div>
)

export default styled(VimeoVideo)`
  .video-iframe {
    aspect-ratio: 16 / 9;
  }
`
