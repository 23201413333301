import EvalExpression from "../expression_dsl"

const ExerciseAnswerDisplay = ({ answer_value, wrapper: Wrapper = "p", prefix = "", postfix = "", className }) => {
  const answerValue = answer_value && EvalExpression(answer_value)

  return (
    <Wrapper className={className}>
      {prefix}
      {answerValue}
      {postfix}
    </Wrapper>
  )
}

export default ExerciseAnswerDisplay
