import type { ComponentProps } from "react"
import { styled, keyframes } from "styled-components"
import type { IntRange } from "type-fest"

const SavingDots = (props: ComponentProps<"span">) => (
  <span {...props}>
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </span>
)

const dotAnimation = (percentage: IntRange<0, 100>) => keyframes`
  0% {
    opacity: 0;
  }

  ${percentage + "%"} {
    opacity: 1;
  }
`

export default styled(SavingDots)`
  color: currentcolor;

  span {
    font-size: 16px;
    animation: 1s ${dotAnimation(25)} infinite steps(1);
    animation-delay: 0.3s;

    &:first-child + span {
      animation-name: ${dotAnimation(50)};

      + span {
        animation-name: ${dotAnimation(75)};
      }
    }
  }
`
