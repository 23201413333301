const ListeningTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Conscious listening is an essential skill that drives a spirit of trust, collaboration, and productivity that 
        is at the heart of team effectiveness. When team members practice consciously listening together, they learn to
        show respect and create a safe space for the opinions and ideas of each team member. Building this practice as a
        team helps to foster innovation and encourages team members to experiment and take risks without feeling judged
        or criticized.
      </p>
      <h2 className="text-rising-orange mb-medium">Hearing isn't the same as listening</h2>
      <p className="mb-medium">
        "Hearing" and "listening" are often used interchangeably, but they can have very different meanings. Hearing on
        its own is a passive action of collecting sound. We hear things all day long. Whether or not we're paying
        attention is a different story! On the other hand, listening is a more active behavior where we pay attention to
        not only the words we hear but also the human and meaning behind them.
      </p>
      <p className="mb-medium">
        Conscious listening takes it one step further by addressing the active element of listening and also tuning in
        to the needs and feelings of the person you are listening to. To be a conscious listener means to go beyond just
        hearing and tap into a deeper level skill set.
      </p>
      <h2 className="text-rising-orange mb-medium">How well we listen can either build trust or erode it</h2>
      <p className="mb-medium">
        When we consciously listen, we have a better opportunity to understand what our teammates are saying and
        meaning, without jumping to conclusions or making assumptions. When we have this level of understanding, it
        helps us to gain insight into their thoughts, feelings, and motivations. This allows us to make better decisions
        and work in a more harmonious way. Over time, conscious listening builds trust and creates a safe space for open
        dialogue.
      </p>
      <p className="mb-medium">
        On the flip side, the absence of conscious listening can lead to a range of issues, including increased conflict
        among colleagues or decreased productivity due to confusion. When team members aren't listening to each other's
        ideas and concerns, it can be difficult for people to come together and solve problems. Additionally, most of us
        can relate to feeling demotivated when we feel like we aren't being heard.
      </p>
      <h2 className="text-rising-orange mb-medium">We all face barriers to listening well</h2>
      <p className="mb-medium">
        Deep listening can be challenging: if it was easy, everyone would do it. To start, we are distracted. According
        to some data, we touch our cell phones north of 340 times per day! As our mobile addiction grows, so does our
        inability to listen to those around us. Constant interruptions from our phones, social media, or day-to-day
        schedules can leave us distracted and preoccupied.
      </p>
      <p className="mb-medium">
        Additionally, we face a natural inclination to automatically make assumptions, judge, and interject. As Stephen
        Covey so famously stated in <em>The 7 Habits of Highly Effective People</em>, "Most people do not listen with
        the intent to understand; they listen with the intent to reply."
      </p>
      <p className="mb-medium">
        When we take these barriers into consideration, it's easy to see how we may think we're better listeners than we
        actually are.
      </p>
      <h3 className="mb-medium">We may have an inflated sense of our listening skills</h3>
      <p className="mb-medium">
        How good of a listener do you think you are? Most people would say pretty good. In its{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://newsroom.accenture.com/industries/global-media-industry-analyst-relations/accenture-research-finds-listening-more-difficult-in-todays-digital-workplace.htm"
        >
          #ListenLearnLead report
        </a>
        , Accenture found that nearly 96% of its 3,600 global professional survey respondents consider themselves to be
        good listeners. This study found that, although the majority of respondents considered themselves good
        listeners, nearly two-thirds confess that listening has become increasingly difficult as our lives have become
        more digital and prone to distraction. In fact, 8 in 10 say they multitask during meetings unless they actively
        need something from the person.
      </p>
      <p className="mb-medium">
        As{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.psychologytoday.com/us/blog/play-your-way-sane/202108/were-worse-listening-we-realize"
        >
          Psychology Today
        </a>{" "}
        summarizes: "there's a massive disconnect between the confidence in our listening and our actual abilities. In
        short, we're crummier listeners than we realize."
      </p>
      <h2 className="text-rising-orange mb-medium">Improving our ability to listen well</h2>
      <p className="mb-medium">
        Once we recognize what could be holding us back from being great listeners, the good news is that we can do
        something about it. Conscious listening involves three strategies, each of which you will practice as part of
        this Rising Team kit.
      </p>
      <h3 className="mb-medium">Check in with your intentions</h3>
      <p className="mb-medium">
        Are you truly open to hearing what the other party has to say? If the answer is no, then you're not likely to
        listen well and you have the ability to shift your approach. For example, if you find that you are in a hurry
        and are only listening to appease the other party, you can choose to reschedule the conversation to when you
        have more time to engage.
      </p>
      <h3 className="mb-medium">Stay in the present</h3>
      <p className="mb-medium">
        Take notice of where your attention is and address any potential distractions. For example, if you notice that
        your attention is being pulled away by texts on your phone, turn off notifications or put the phone away. The
        same is true for mental distractions. Perhaps while listening you are having fleeting thoughts about all of the
        tasks you need to complete. To help yourself listen more consciously, you could manage that distraction by
        writing a list for later if that makes you feel more free to listen.
      </p>
      <h3 className="mb-medium">Clarify and validate</h3>
      <p className="mb-medium">
        By asking clarifying questions and paraphrasing what you heard, you send a clear signal to the other person that
        you have truly heard them. Additionally, by showing respect for the opinions and ideas of each team member, you
        can validate each other's ideas and create a safe space for ongoing open dialogue.
      </p>
    </div>
  </div>
)

export default ListeningTraining1
