import { useMemo } from "react"

import Loading from "ui/Loading"
import WordCloud from "ui/WordCloud"

const TextFieldAggregatedMultiUserWordCloud = ({
  identifier,
  useAggregatedResultsData,
  additional_data,
  minFontSize,
  maxFontSize,
  refetch_interval,
  height = null,
  className,
}) => {
  const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
    component: "TextFieldAggregatedMultiUserWordCloud",
    identifiers: [identifier],
    additional_data,
    refetch_interval,
  })

  const words = useMemo(() => aggregatedResults?.data, [aggregatedResults?.data])

  if (!aggregatedResults && isFetching) {
    return <Loading />
  }

  if (!aggregatedResults) {
    return null
  }

  return (
    <WordCloud
      className={className}
      wcWords={words}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
      height={height}
    />
  )
}

export default TextFieldAggregatedMultiUserWordCloud
