function SvgPieSliceMultipleIcon(props) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_27419_47216)">
        <g filter="url(#filter0_di_27419_47216)">
          <path
            d="M28.4633 17.9787C29.2015 17.6026 29.4986 16.6969 29.0819 15.9809C26.1339 10.9165 21.5163 6.80073 15.6935 4.54541C14.921 4.2462 14.0726 4.68131 13.8166 5.46919L7.15872 25.96C6.74576 27.231 8.07559 28.3668 9.26629 27.7601L28.4633 17.9787Z"
            fill="currentColor"
            stroke="white"
            strokeOpacity="0.6"
          />
        </g>
        <g filter="url(#filter1_di_27419_47216)">
          <path
            d="M30.4556 22.4529C31.2124 22.1159 31.5565 21.227 31.1778 20.4902C28.4989 15.2784 24.103 10.9266 18.4062 8.36966C17.6505 8.03043 16.7805 8.42054 16.4836 9.19395L8.76244 29.3083C8.28353 30.5559 9.55209 31.7597 10.7729 31.2161L30.4556 22.4529Z"
            fill="currentColor"
            stroke="white"
            strokeOpacity="0.6"
          />
        </g>
        <g filter="url(#filter2_di_27419_47216)">
          <path
            d="M32.4242 27.9273C33.1976 27.6304 33.5878 26.7607 33.2481 26.0051C30.8457 20.6602 26.6836 16.0844 21.1284 13.2328C20.3914 12.8545 19.5022 13.1985 19.1653 13.9553L10.402 33.638C9.85845 34.8588 11.0623 36.1273 12.3099 35.6484L32.4242 27.9273Z"
            fill="currentColor"
            stroke="white"
            strokeOpacity="0.6"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_27419_47216"
          x="2.08423"
          y="-0.276611"
          width="32"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27419_47216" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27419_47216" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_27419_47216" />
        </filter>
        <filter
          id="filter1_di_27419_47216"
          x="3.4939"
          y="2.99353"
          width="33.3251"
          height="35.3251"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27419_47216" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27419_47216" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_27419_47216" />
        </filter>
        <filter
          id="filter2_di_27419_47216"
          x="4.94543"
          y="7.30627"
          width="34.5753"
          height="36.5753"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27419_47216" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27419_47216" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_27419_47216" />
        </filter>
        <clipPath id="clip0_27419_47216">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgPieSliceMultipleIcon
