import { Form, Formik } from "formik"
import { useState, useMemo } from "react"

import { getKitStatus } from "domains/LeaderKit/KitsTableofContents/utils"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import View from "ui/View"

const KitFiltersRevamp = ({ basicKits, kitInstanceMap, setVisibleKits }) => {
  const [teamSizeFilter, setTeamSizeFilter] = useState("standard")
  const [minLengthFilter, setMinLengthFilter] = useState(15)
  const [maxLengthFilter, setMaxLengthFilter] = useState(180)
  const [statusFilter, setStatusFilter] = useState("available")

  const teamSizeOptions = [
    { value: "standard", label: "2-14" },
    { value: "large", label: "15-25" },
    { value: "jumbo", label: "25+" },
  ]
  // array of numbers starting at 15 and incrementing by 15 up to 180
  const minLengthOptions = Array(12)
    .fill(0)
    .map((_, index) => (index + 1) * 15)
  const maxLengthOptions = Array(12)
    .fill(0)
    .map((_, index) => (index + 1) * 15)
  const statusOptions = [
    { value: "available", label: "Available" },
    { value: "in_progress", label: "In Progress" },
    { value: "scheduled", label: "Scheduled" },
    { value: "complete", label: "Completed" },
    { value: "unavailable", label: "Unavailable" },
  ]

  const initialValues = {
    team_size: "standard",
    min_length: 15,
    max_length: 180,
    status: "available",
  }

  const filteredKits = useMemo(
    () =>
      basicKits.filter((kit) => {
        const teamSizeMatches = teamSizeFilter ? kit.team_size === teamSizeFilter : true
        const minLengthMatches = minLengthFilter ? kit.rounded_session_time_minutes >= minLengthFilter : true
        const maxLengthMatches = maxLengthFilter ? kit.rounded_session_time_minutes <= maxLengthFilter : true
        const kitInstance = kitInstanceMap[kit.slug]
        const kitStatus = getKitStatus(kitInstance, kit)
        const matchesStatus = statusFilter ? kitStatus === statusFilter : true
        return teamSizeMatches && minLengthMatches && maxLengthMatches && matchesStatus
      }),
    [kitInstanceMap, teamSizeFilter, minLengthFilter, maxLengthFilter, basicKits, statusFilter]
  )

  useEffectAfterChange(() => {
    setVisibleKits(filteredKits)
  }, [filteredKits, setVisibleKits])

  return (
    <Formik initialValues={initialValues}>
      <Form>
        <View $gap="20px">
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Team size</div>
            <AdvancedSelectField
              name="team_size"
              onChange={({ value }) => setTeamSizeFilter(value)}
              options={teamSizeOptions.map((option) => ({ label: option.label, value: option.value }))}
              width={104}
            />
          </View>
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Min length</div>
            <AdvancedSelectField
              name="min_length"
              onChange={({ value }) => setMinLengthFilter(value)}
              options={minLengthOptions.map((option) => ({
                label: `${option} minutes`,
                value: option,
                isDisabled: option > maxLengthFilter,
              }))}
              width={134}
            />
          </View>
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Max length</div>
            <AdvancedSelectField
              name="max_length"
              onChange={({ value }) => setMaxLengthFilter(value)}
              options={maxLengthOptions.map((option) => ({
                label: `${option} minutes`,
                value: option,
                isDisabled: option < minLengthFilter,
              }))}
              width={134}
            />
          </View>
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Status</div>
            <AdvancedSelectField
              name="status"
              onChange={({ value }) => setStatusFilter(value)}
              options={statusOptions.map((option) => ({ label: option.label, value: option.value }))}
              width={166}
            />
          </View>
        </View>
      </Form>
    </Formik>
  )
}

export default KitFiltersRevamp
