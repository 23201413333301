import * as Sentry from "@sentry/browser"
import { isValidElement, Fragment } from "react"

import useWindowSize from "ui/hooks/useWindowSize"

// Supported HTML Element Types:
const SUPPORTED_TAGS = [
  "a",
  "b",
  "blockquote",
  "br",
  "button",
  "code",
  "div",
  "em",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "hr",
  "i",
  "img",
  "input",
  "iframe",
  "label",
  "li",
  "ol",
  "p",
  "pre",
  "s",
  "span",
  "strong",
  "svg",
  "table",
  "td",
  "textarea",
  "tr",
  "u",
  "ul",
]

const MobileOnly = ({ children }) => {
  const { isMobileOrSmaller } = useWindowSize()
  return isMobileOrSmaller ? children : null
}

const MobileNever = ({ children }) => {
  const { isMobileOrSmaller } = useWindowSize()
  return !isMobileOrSmaller ? children : null
}

const SUPPORTED_COMPONENTS = {
  MobileOnly,
  MobileNever,
}

function renderBlocks(content, components, propUpdateFn, { detectRawHtml = true } = {}) {
  // Accept null/undefined content, return null and let React render nothing:
  if (content == null) {
    return null
  }

  // Accept string content, return directly and let React render:
  if (typeof content === "string") {
    // TEMP: detect whether we try and pass raw HTML through content strings
    if (detectRawHtml && /<\/[a-z]+>|<br>/.test(content)) {
      Sentry.captureMessage(
        `Tried to render block content containing raw html string, this is probably a mistake ("${content}").`
      )
    }
    return content
  }

  // This indicates content is a JSX fragment, return directly and let React render:
  if (isValidElement(content)) {
    return content
  }

  // If content is not an array by this point, raise a validation error:
  if (!Array.isArray(content)) {
    throw new Error(`blocks rendering expected array content but received: ${JSON.stringify(content)}`)
  }

  propUpdateFn = propUpdateFn ?? (({ props }) => props) // default: don't modify props

  return content.map((element, idx) => {
    // If content entry is string, render directly in parent without any processing:
    if (typeof element === "string") {
      return element
    }

    // This indicates element is a JSX fragment, return directly and let React render:
    if (isValidElement(element)) {
      return element
    }

    /// INPUT PREPARATION ///
    let [tagName, props, subContent] = element

    // Allow shorthand of ["tag", "css-class", "content"] to be used,
    // instead of ["tag", { className: "css-class" }, "content"]:
    if (typeof props === "string") {
      props = props.trim().length ? { className: props } : {}
    } else {
      props = props ?? {}
    }

    /// ELEMENT TAG/COMPONENT SELECTION ///

    let Tag
    if (SUPPORTED_TAGS.includes(tagName)) {
      Tag = tagName
    } else {
      // If you wish to render a specific component, add it to 'components' arg.
      Tag = components?.[tagName] ?? SUPPORTED_COMPONENTS[tagName] ?? null
    }

    if (!Tag) {
      throw new Error(`Tag name does not match HTML element, component, or icon ("${tagName}")`)
    }

    /// ELEMENT PROP PROCESSING ///

    props = propUpdateFn({ Tag, props })

    // null return from propUpdateFn indicates we want to skip rendering of this block:
    if (props == null) {
      return null
    }

    if (typeof props !== "object") {
      throw new Error("You must return a props object from the propsUpdateFn passed to renderBlocks.")
    }

    props.$renderBlockProps?.forEach((prop) => {
      if (props[prop]) {
        const renderedProp = renderBlocks(props[prop], components, propUpdateFn)
        props[prop] = renderedProp
      }
    })

    /// RENDER ELEMENT(S) ///

    // If sub-content is an array, recursively render it:
    if (Array.isArray(subContent)) {
      subContent = renderBlocks(subContent, components, propUpdateFn, { detectRawHtml: false })
    } else if (typeof subContent !== "string") {
      // Otherwise it must be string content -- error if not:
      throw new Error(
        `blocks rendering expected array or string sub-content but received: ${JSON.stringify(subContent)}`
      )
    }

    return (
      <Fragment key={idx}>
        {/* this is needed since some tags fail if we have subContent, even if subContent is empty. ex: img */}
        {subContent ? <Tag {...props}>{subContent}</Tag> : <Tag {...props} />}
      </Fragment>
    )
  })
}

export default renderBlocks
