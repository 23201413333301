import Table from "components/Table"
import { useKitUsageSummaryReportData } from "resources/billing"
import Loading from "ui/Loading"
import View from "ui/View"

const KitUsageSummaryReport = ({
  className,
  accountId,
  accountTags,
  multiAccountIds,
  kitSlug,
  teamId,
  teamTags,
  includeArchivedTeams,
}) => {
  const { data: kitUsageData, isFetching } = useKitUsageSummaryReportData({
    accountId,
    accountTags,
    multiAccountIds,
    kitSlug,
    teamId,
    teamTags,
    includeArchivedTeams,
  })

  if (!kitUsageData && isFetching) {
    return <Loading />
  }

  return (
    <div>
      <h3 className="mb-medium">Summary</h3>
      <View $width="100%">
        <Table
          className={className}
          rows={[kitUsageData]}
          empty="No kit usage data available yet"
          notFound="No matching data"
          maxColumnWidth={50}
          columns={[
            {
              id: "total_teams",
              Header: "Total teams",
              accessor: "total_teams",
            },
            {
              id: "completed_sessions",
              Header: "Sessions completed",
              accessor: "completed_sessions",
            },
            {
              id: "total_development_kits",
              Header: "Total Development Kits",
              accessor: "total_development_kits",
            },
            {
              id: "total_connection_kits",
              Header: "Total Connection Kits",
              accessor: "total_connection_kits",
            },
            {
              id: "total_minis",
              Header: "Total Minis",
              accessor: "total_minis",
            },
          ]}
        />
      </View>
    </div>
  )
}

export default KitUsageSummaryReport
