import FeedbackPeerFramework from "domains/Exercise/ResultsComponents/FeedbackPeerFramework"
import { CopyIcon, CommentsIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"

const FeedbackResultsReview = ({ kit }) => (
  <>
    <View $alignItems="flex-start" className="mb-large">
      <CommentsIcon className="text-rising-green title-icon mr-medium" />
      <h1 className="text-gray-9">The PEER Framework </h1>
    </View>
    <p className="text-gray-9 mb-medium">
      The PEER framework is a helpful tool for remembering the elements that make constructive feedback useful. Want to
      use it outside of a Rising Team session? Download the PDF or make a copy of a Google doc below.
    </p>
    <View className="mb-large">
      <a href={kit.assets.peer_google_doc_link} target="_blank" rel="noopener noreferrer">
        <Button className="tertiary">
          <CopyIcon />
          Copy the Google doc
        </Button>
      </a>
    </View>
    <h2 className="text-gray-9 mb-large">The PEER Framework</h2>
    <FeedbackPeerFramework />
    <p className="text-gray-9">
      After delivering feedback, remember to pause and give the recipient an opportunity to respond. Ask for their
      experience and consider the situation from their point of view.
    </p>
    <h4 className="text-gray-9 my-small">When deciding to give feedback consider the following:</h4>
    <ul className="text-gray-9 mb-medium ml-xl">
      <li>Are your intentions positive?</li>
      <li>Will this feedback help the person grow or create positive change?</li>
      <li>Will speaking up help encourage positive future behaviors from this person and others?</li>
      <li>Is now a good time? Does the recipient have the emotional resources to hear and react to it at this time?</li>
    </ul>
    <p className="text-gray-9">
      If any of these are not true, research suggests you may want to proceed with caution or hold off. But these are
      not absolutes, so keep these in mind in addition to your personal values, company values, etc.
    </p>
  </>
)

export default FeedbackResultsReview
