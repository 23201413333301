import { styled } from "styled-components"

const TeamPhotoModalContent = styled(function TeamPhotoModalContent({ sessionPictureUrl, kitInfo, className }) {
  return (
    <img src={sessionPictureUrl} alt={`Your team during the ${kitInfo?.title + " "} session`} className={className} />
  )
})`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
`

export default TeamPhotoModalContent
