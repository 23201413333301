function SvgDoubleStarIcon(props) {
  return (
    <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.68503 4.83495L11.6649 0.467957L12.6609 6.98013L17.0279 11.96L10.5157 12.956L5.53582 17.2464L4.53985 10.7342L0.172852 5.83093L6.68503 4.83495Z"
        fill="#FAC126"
      />
      <path
        d="M22.0844 32.2627L20.2457 29.1982L23.7699 29.3514L26.8345 27.5126L26.6813 31.0369L28.52 34.1014L24.9957 33.9482L21.9312 35.7869L22.0844 32.2627Z"
        fill="#FAC126"
      />
    </svg>
  )
}

export default SvgDoubleStarIcon
