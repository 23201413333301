import DOMPurify from "dompurify"

import ExerciseContent from "./ExerciseContent"
import ExerciseForm from "./ExerciseForm"

import TextContainer from "ui/TextContainer"

export default function ExerciseStep({ kit, step, is_standalone, is_session, saveOnChange }) {
  const { components, question } = step
  return (
    <ExerciseForm>
      <TextContainer>
        {!!question && <p className="text-gray-9" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }} />}
      </TextContainer>
      <ExerciseContent
        kit={kit}
        is_standalone={is_standalone}
        is_session={is_session}
        components={components}
        saveOnChange={saveOnChange}
        is_full_exercise={true}
      />
    </ExerciseForm>
  )
}
