import { getExerciseAnswer } from "../results_utils"

const TextFieldText = ({ identifier, exerciseInstance }) => {
  if (!exerciseInstance) {
    return null
  }

  const answer = getExerciseAnswer(exerciseInstance, identifier)

  return answer
}

export default TextFieldText
