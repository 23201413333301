import { find, set, keyBy } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import { useOrCreateExerciseInstance, useMutateExerciseAnswer } from "domains/Exercise/resource"
import { getChartConfig } from "domains/Exercise/results_utils/learner-agility"
import AssessmentAccuracyForm from "domains/Exercise/ResultsComponents/AssessmentAccuracyForm"
import LearnerAgilityLearningPreferencesResults from "domains/Exercise/ResultsComponents/LearnerAgilityLearningPreferencesResults"
import StepContent from "domains/KitSession/components/StepContent"
import LineRatingField from "forms/fields/LineRatingField"
import TextareaField from "forms/fields/TextareaField"
import Yup from "forms/yup"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import RadarChart from "ui/RadarChart"
import { useHasTeamFeature } from "utils/team"

const LearnerAgilityLearningPreferencesReviewStep = ({ kitInstance, className }) => {
  const kit = kitInstance.kit
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { data: exercise, isFetching } = useOrCreateExerciseInstance({
    teamId: kitInstance.team_id,
    slug: kit.exercise.slug,
    version: kit.exercise.version,
  })

  if (!participants || isFetching) {
    return <Loading />
  }

  const participantIdMap = keyBy(participants, "id")
  const chartConfig = getChartConfig([exercise], participantIdMap)

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Review your results</h2>
      <p className="text-gray-9 mb-small">
        These results reflect how you prefer to learn new skills or information, not necessarily your abilities.
        Learning in our preferred styles can make it easier for us to learn new things.
      </p>
      <RadarChart className="my-xl" {...chartConfig} />
      <h3 className="text-gray-9 mb-large">Your top three</h3>
      <LearnerAgilityLearningPreferencesResults exercise={exercise} isCompact={false} className="mb-xl" />
      <LearnerAgilityAssessmentAccuracyReview exercise={exercise} />
    </StepContent>
  )
}

const LearnerAgilityAssessmentAccuracyReview = ({ exercise }) => {
  const { mutateAsync: mutateExerciseAnswer } = useMutateExerciseAnswer(exercise.id)

  const initialValues = {
    assessment_feel: find(exercise.answers, { identifier: "assessment_feel" })?.data,
    assessment_feel_reason: find(exercise.answers, { identifier: "assessment_feel_reason" })?.data,
  }
  const lineRatingLabels = ["Not at all", "Just a bit", "Somewhat", "Pretty much", "A lot"]

  const saveOnChange = (name, value) => {
    mutateExerciseAnswer(set({}, name, value))
  }

  return (
    <AssessmentAccuracyForm title="Reflect" initialValues={initialValues} validationSchema={reviewSchema}>
      <p className="text-normal text-gray-9 mb-large">
        The purpose of this exercise is to help uncover patterns and inspire conversation; not put you in a box.
      </p>
      <div className="text-field-label mb-xs">How much do these results feel like you?</div>
      <LineRatingField
        numOptions={5}
        name="assessment_feel"
        axisLabels={lineRatingLabels}
        ariaLabels={lineRatingLabels}
        className="mb-large"
        saveOnChange={saveOnChange}
      />
      <TextareaField
        name="assessment_feel_reason.value"
        label="Why or why not?"
        className="mb-large"
        saveOnChange={saveOnChange}
      />
    </AssessmentAccuracyForm>
  )
}

const reviewSchema = Yup.object().shape({
  assessment_feel: Yup.object().shape({
    value: Yup.string().required().label("How much does this assessment feel like you?"),
  }),
  assessment_feel_reason: Yup.object().shape({
    value: Yup.string().label("Why or why not?"),
  }),
})

export default LearnerAgilityLearningPreferencesReviewStep
