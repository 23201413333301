import { trim } from "lodash-es"
import { Children, useMemo } from "react"
import { Routes, useParams, useLocation } from "react-router-dom"

import StepsContext from "components/Steps/StepsContext"

// TODO is there a better react-router function to do that?
function formatPath(path, locationSearch) {
  if (!path) {
    return
  }
  // TODO regex to replace only when at the end
  path = path.replace("/*", "")
  if (path.startsWith("/")) {
    return `${path}${locationSearch}`
  }
  return `../${path}${locationSearch}`
}

const Steps = ({ children, paths, ...props }) => {
  const { search } = useLocation()
  const currentPath = useParams()["*"]
  // We trim leading and trailing slashes before comparing paths below, so that the
  // logic works whether or not there are slashes in the provided list of paths:
  const currentIndex = paths.findIndex((path) => trim(currentPath, "/") === trim(path, "/").replace("/*", ""))
  const previous = paths[currentIndex - 1] || props.previous
  const next = currentIndex >= 0 ? paths[currentIndex + 1] || props.next : null
  const isLast = currentIndex === paths.length - 1
  const count = paths.length
  const value = useMemo(
    () => ({
      isLast,
      previous: formatPath(previous, search),
      next: formatPath(next, isLast ? "" : search),
      currentIndex,
      count,
    }),
    [next, previous, isLast, currentIndex, count, search]
  )

  return <StepsContext.Provider {...props} value={value} children={children} />
}

const RouteSteps = ({ children, ...props }) => {
  const paths = Children.toArray(children)
    .filter((child) => child != null && !child.props.ignore)
    .map((child) => child.props.path)
  return (
    <Steps {...props} paths={paths}>
      <Routes>{children}</Routes>
    </Steps>
  )
}

export { Steps, RouteSteps }
