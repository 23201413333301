function SvgLogoIcon(props) {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M53.9566 66.8259C51.7228 64.7602 48.1238 64.7602 45.7659 66.8259L35.4656 76.3512C33.2318 78.4169 33.2318 81.745 35.4656 83.9255L45.7659 93.4508C47.9997 95.5165 51.5987 95.5165 53.9566 93.4508L64.2569 83.9255C66.4907 81.8598 66.4907 78.5317 64.2569 76.3512L53.9566 66.8259Z"
        fill="#F26322"
      />
      <path d="M81.5189 32.1222L65.7078 47.1489L75.4179 56.3773L91.229 41.3506L81.5189 32.1222Z" fill="#F99F1B" />
      <path d="M68.9015 20.0638L53.0903 35.0906L62.6286 44.1556L78.4398 29.1289L68.9015 20.0638Z" fill="#FE7A00" />
      <path
        d="M50.0075 32.1993L65.8057 17.1848L60.9447 12.565L54.99 6.90568C52.3165 4.36477 47.9416 4.36477 45.268 6.90568L39.3133 12.565L2.00516 47.7913C-0.668387 50.3322 -0.668387 54.4901 2.00516 57.031L7.95988 62.6903C10.6334 65.2312 15.0083 65.2312 17.6819 62.6903L50.0075 32.1993Z"
        fill="#F26322"
      />
      <path
        d="M98.0099 47.7906L94.3642 44.3257L78.5659 59.3402L82.2117 62.8051C84.8852 65.346 89.2601 65.346 91.9337 62.8051L97.8884 57.1457C100.683 54.4893 100.683 50.3315 98.0099 47.7906Z"
        fill="#FFD257"
      />
    </svg>
  )
}

export default SvgLogoIcon
