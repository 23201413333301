import cn from "classnames"
import { keyBy } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import RealtimeSlideshow from "domains/KitSession/components/RealtimeSlideshow"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { DashIcon, QuestionIcon } from "icons/FontAwesomeIcons"
import { useRealtimeSlideRevealStatus, useUpdateSlideRevealStatus } from "resources/monthly_kit"
import { useUser } from "resources/users"
import Callout from "ui/Callout"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useSyncSessionEnabled from "ui/hooks/useSyncSessionEnabled"
import useWindowSize from "ui/hooks/useWindowSize"
import Loading from "ui/Loading"
import Slide from "ui/Slide"
import Slideshow from "ui/Slideshow"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const TextFieldAggregatedMultiUserSlideshowWithRevealAccordion = styled(
  function TextFieldAggregatedMultiUserSlideshowWithRevealAccordion({
    identifiers,
    useAggregatedResultsData,
    sortedUsers,
    title,
    nextText,
    isRealtimeSlideshow = false,
    additional_data,
    className,
  }) {
    const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
      component: "TextFieldAggregatedMultiUserSlideshowWithRevealAccordion",
      identifiers,
      additional_data,
    })

    if (!aggregatedResults && isFetching) {
      return <Loading />
    }

    if (!aggregatedResults) {
      return null
    }

    const realtimeSlideShowRevealKey = identifiers?.join("-")

    const userIdMap = keyBy(sortedUsers, "id")
    const slides = aggregatedResults.slides
      .map((slideResult, idx) => createSlide({ key: idx, slideResult, userIdMap, realtimeSlideShowRevealKey }))
      .filter(Boolean)

    return (
      <div className={cn(className, "mb-medium")}>
        <Callout>
          {!!isRealtimeSlideshow ? (
            <RealtimeSlideshow
              title={title}
              slides={slides}
              nextText={nextText}
              identifiers={identifiers}
              realtimeSlideShowRevealKey={realtimeSlideShowRevealKey}
              isSlideRevealable={true}
            />
          ) : (
            <Slideshow title={title} slides={slides} nextText={nextText} />
          )}
        </Callout>
      </div>
    )
  }
)`
  .content-box {
    position: relative;

    &:hover {
      box-shadow: var(--lift-2);
    }
  }
`

const RevealAccordion = styled(function RevealAccordion({ user, answer, realtimeSlideShowRevealKey, className }) {
  const { kitInstance, team } = useKitSession()
  const { data: sessionUser } = useUser({ userId: "me" })
  const syncSessionEnabled = useSyncSessionEnabled()
  const { enabled: isRealtimeSlideshowFeatureActive } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SLIDESHOW)
  const isRealtimeSlideshowActive =
    isRealtimeSlideshowFeatureActive && !!kitInstance?.session_started_at && !kitInstance?.session_completed_at
  const isTeamLead = team && sessionUser.id === team.team_lead_id

  const { data: slideRevealStatus, isInitialLoading } = useRealtimeSlideRevealStatus({
    kitInstanceId: kitInstance?.id,
    key: realtimeSlideShowRevealKey,
    enabled: !!(kitInstance && isRealtimeSlideshowActive),
  })

  const { mutateAsync: updateSlideRevealStatus } = useUpdateSlideRevealStatus({
    kitInstanceId: kitInstance?.id,
    key: realtimeSlideShowRevealKey,
  })

  const [isActive, setIsActive] = useState(false)
  const theme = useCurrentTheme()
  const { isTabletOrSmaller } = useWindowSize()

  const currentSlideRevealStatus = !!slideRevealStatus ? slideRevealStatus["reveal_status"] : false
  const currentRevealStatus =
    !!isRealtimeSlideshowActive && !!syncSessionEnabled && !isTeamLead ? currentSlideRevealStatus : isActive

  if (isInitialLoading) {
    return <Loading />
  }

  const toggleReveal = async (currentRevealStatus) => {
    setIsActive(!currentRevealStatus)
    !!isTeamLead &&
      !!isRealtimeSlideshowActive &&
      !!syncSessionEnabled &&
      (await updateSlideRevealStatus(!currentRevealStatus))
  }

  return (
    <div className={cn(className, "mb-large")}>
      <View
        $alignItems="center"
        className={cn(
          "content-box mt-medium border border-radius-small",
          theme.isWidescreen ? "text-huge p-medium" : "text-medium px-medium py-large"
        )}
      >
        "{answer}"
      </View>
      <View
        $width={theme.isWidescreen || isTabletOrSmaller ? "90%" : "50%"}
        $alignItems="center"
        className="border border-gray-4 bg-gray-1 px-large py-medium border-bottom-radius-small text-gray-9 cursor-pointer"
        onClick={() => toggleReveal(currentRevealStatus)}
      >
        {!isRealtimeSlideshowActive ||
        !syncSessionEnabled ||
        (!!isRealtimeSlideshowActive && !!syncSessionEnabled && !!isTeamLead) ? (
          <View $alignItems="center">
            {!!currentRevealStatus ? (
              <>
                <DashIcon className="dash-icon mr-small text-orange-4" />
                <span className="short-name-text">{user.short_name}!</span>
              </>
            ) : (
              <>
                <QuestionIcon className="question-icon text-orange-4" />
                <QuestionIcon className="question-icon text-orange-4" />
                <QuestionIcon className="question-icon text-orange-4" />
                <span className="reveal-text text-semi-bold text-rising-blue ml-xs">Click to reveal</span>
              </>
            )}
          </View>
        ) : (
          !!currentRevealStatus && (
            <>
              <DashIcon className="dash-icon mr-small text-orange-4" />
              <span className="short-name-text">{user.short_name}!</span>
            </>
          )
        )}
      </View>
    </div>
  )
})`
  ${({ theme }) =>
    theme.isWidescreen
      ? `

  .reveal-text {
    cursor: pointer;
  }

  .short-name-text, .reveal-text {
    font-size: 2rem; // matches h2 widescreen styling
    line-height: 2.75rem;
  }

  .question-icon, .dash-icon {
    width: 48px;
    height: 48px;
  }
`
      : `
  .short-name-text, .reveal-text {
    font-size: 1.0625rem; // matches h4 styling
    line-height: 1.5rem;
  }

  .question-icon, .dash-icon {
    width: 24px;
    height: 24px;
  }
`}
`

const createSlide = ({ key, slideResult, userIdMap, realtimeSlideShowRevealKey }) => {
  const user = userIdMap[slideResult.user_id]
  if (!user || !slideResult.answer) {
    return null
  }
  return (
    <Slide>
      <RevealAccordion
        key={key}
        user={user}
        answer={slideResult.answer}
        realtimeSlideShowRevealKey={realtimeSlideShowRevealKey}
      />
    </Slide>
  )
}

export default TextFieldAggregatedMultiUserSlideshowWithRevealAccordion
