import TalentsChart from "domains/Exercise/ResultsComponents/TalentsChart"
import TalentsUserResults from "domains/Exercise/ResultsComponents/TalentsUserResults"
import { StarShootingIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"

const TalentsShareResults = ({ exerciseInstance }) => {
  const userProfileFirstName = exerciseInstance.user_share_first_name
  const userProfileLastInitial = exerciseInstance.user_share_last_initial + "."
  const userIdMap = {
    [exerciseInstance.user_id]: {
      id: exerciseInstance.user_id,
      short_name: `${exerciseInstance.user_share_first_name} ${exerciseInstance.user_share_last_initial}`,
    },
  }

  return (
    <div>
      <div className="mb-medium">
        <View $alignItems="center">
          <StarShootingIcon className="text-orange-3 title-icon mr-medium" />
          <h1 className="text-gray-9">Talent Profile</h1>
        </View>
      </div>
      <p className="text-gray-9 mb-small">
        This profile was created by <span className="text-capitalize">{userProfileFirstName}</span> during a Rising Team
        session.
      </p>
      <p>
        <a href="https://risingteam.com/" target="_blank" rel="noopener noreferrer">
          Join Rising Team today
        </a>{" "}
        to get access to this and more exercises and insights that will benefit your teamwork.
      </p>
      <h2 className="text-capitalize text-gray-9 pt-xl">
        {userProfileFirstName} {userProfileLastInitial}
      </h2>
      <h2 className="text-bold text-gray-9 mt-xl mb-medium">Your top talents</h2>
      <p className="text-gray-9 mb-large">
        These areas are more likely to come easily to you and bring you joy. Try to spend as much of your time here as
        you can.
      </p>
      <TalentsChart userIdMap={userIdMap} exerciseInstances={[exerciseInstance]} className="mb-xl" />
      <TalentsUserResults exercise={exerciseInstance} />
    </div>
  )
}

export default TalentsShareResults
