// TOOD: feedbackstep.js
import cn from "classnames"
import { Form, Formik, yupToFormErrors } from "formik"
import { isObject, mapValues, range } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import { StepNavigation } from "components/Steps/StepNavigation"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import IconRatingField from "forms/fields/IconRatingField"
import LineRatingField from "forms/fields/LineRatingField"
import SmileRatingField from "forms/fields/SmileRatingField"
import TextareaField from "forms/fields/TextareaField"
import Yup from "forms/yup"
import { usePostSessionSurvey, useUpdatePostSessionSurvey } from "resources/monthly_kit"
import Button from "ui/Button"
import { TEAM_FLAGS } from "ui/hooks/useFeatures"
import { useModal } from "ui/ModalContext"
import View from "ui/View"
import { KitGroupSize, KitType, getKitTypeInfo } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const FeedbackStep = styled(function FeedbackStep({
  sessionStep,
  navigateNextHandler,
  navigateBackHandler,
  nextButtonProps,
  backButtonProps,
  navRightContent,
  className,
}) {
  const { kitInstance, team } = useKitSession()
  const { data } = usePostSessionSurvey({ kitInstanceId: kitInstance.id })
  const { mutateAsync: updatePostSessionSurvey } = useUpdatePostSessionSurvey({
    surveyId: data?.id,
  })
  const { setModal } = useModal()
  const [warningShown, setWarningShown] = useState(false)
  const demo = !!team.demo
  const kit_type = kitInstance.kit.type
  const isJumbo = kitInstance.kit.group_size === KitGroupSize.JUMBO
  const { enabled: isJumboPromoSession } = useHasTeamFeature(team, TEAM_FLAGS.JUMBO_PROMO_SESSIONS)
  const isMini = kitInstance.kit.type === KitType.MINI
  const showLearnedSomethingValuableQuestion = getKitTypeInfo(kit_type).showLearnedSomethingValuableQuestion
  const showFeelMoreConnectedQuestion = getKitTypeInfo(kit_type).showFeelMoreConnectedQuestion
  const showWorkMoreEffectivelyQuestion = getKitTypeInfo(kit_type).showWorkMoreEffectivelyQuestion && !isJumbo
  const showLikelihoodOfRecommendingQuestion = getKitTypeInfo(kit_type).showLikelihoodOfRecommendingQuestion
  const showThinkAboutSessionQuestion = getKitTypeInfo(kit_type).showThinkAboutSessionQuestion
  const showImproveExperienceFeedbackQuestion = getKitTypeInfo(kit_type).showImproveExperienceFeedbackQuestion

  if (!data) {
    return null
  }

  const initialValues = {
    learned_something_valuable: { value: data.learned_something_valuable?.toString() },
    feel_more_connected: { value: data.feel_more_connected?.toString() },
    work_more_effectively: { value: data.work_more_effectively?.toString() },
    likelihood_of_recommending: { value: data.likelihood_of_recommending?.toString() },
    think_about_session: { value: data.think_about_session?.toString() },
    improve_experience_feedback: data.improve_experience_feedback || "",
  }
  const validationSchema = getSurveySchema({
    demo,
    isJumbo,
    isJumboPromoSession,
    isMini,
    showWorkMoreEffectivelyQuestion,
  })
  const validate = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false })
    } catch (e) {
      return yupToFormErrors(e)
    }

    const normalizedValues = mapValues(values, (val) => (isObject(val) ? val.value : val))
    const agreeDisagreeValues = ["learned_something_valuable", "feel_more_connected", "work_more_effectively"].map(
      (key) => parseInt(normalizedValues[key])
    )
    const hasStronglyDisagree = agreeDisagreeValues.some((value) => value === 1)
    const hasDisagree = agreeDisagreeValues.some((value) => value === 2)
    const highlyRecommend = parseInt(normalizedValues["likelihood_of_recommending"]) >= 8

    if ((hasStronglyDisagree || hasDisagree) && highlyRecommend && !warningShown) {
      const disagreeText = hasStronglyDisagree ? "strongly disagree" : "disagree"
      setModal({
        content: (
          <>
            <div className="space-y-medium mb-large">
              <p>Did you mean to say both "{disagreeText}" and "highly recommend"?</p>
              <p>If yes, please provide more details and confirm.</p>
              <p>If no, please update your answer.</p>
            </div>
            <View $justifyContent="flex-end">
              <Button onClick={() => setModal(null)}>Continue</Button>
            </View>
          </>
        ),
      })
      setWarningShown(true)
      return { form: `Did you mean to say both "${disagreeText}" and "highly recommend"?` }
    }

    return {}
  }
  const onSubmit = async (values) => {
    const normalizedValues = mapValues(values, (val) => (isObject(val) ? val.value : val))
    await updatePostSessionSurvey(normalizedValues)
  }

  return (
    <StepContent className={className}>
      <SidebarContainer className={cn({ "mb-large": !isMini })}>
        {!isMini && (
          <>
            <p>
              Thank you for sharing your experience. We value and appreciate every single response and actively make
              changes based on your feedback.
            </p>
            <FacilitatorTip className="sidebar-right" icon="turn-off-share-screen" />
          </>
        )}
      </SidebarContainer>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        <Form>
          {!!showLearnedSomethingValuableQuestion && (
            <>
              <div className="text-field-label mb-xs">During today’s session, I learned something valuable:</div>
              <LineRatingField
                numOptions={5}
                name="learned_something_valuable"
                axisLabels={["Strongly disagree", "Neutral", "Strongly agree"]}
                ariaLabels={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
                className="mb-large line-rating-field"
              />
            </>
          )}
          {!isJumboPromoSession && !!showFeelMoreConnectedQuestion && (
            <>
              <div className="text-field-label mb-xs">
                After today’s session, I feel more connected to {isJumbo ? "this group" : "my team"}:
              </div>
              <LineRatingField
                numOptions={5}
                name="feel_more_connected"
                axisLabels={["Strongly disagree", "Neutral", "Strongly agree"]}
                ariaLabels={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
                className="mb-large line-rating-field"
              />
            </>
          )}
          {!!showWorkMoreEffectivelyQuestion && (
            <>
              <div className="text-field-label mb-xs">
                Based on today’s session, I believe my team will work more effectively together:
              </div>
              <LineRatingField
                numOptions={5}
                name="work_more_effectively"
                axisLabels={["Strongly disagree", "Neutral", "Strongly agree"]}
                ariaLabels={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
                className="mb-large line-rating-field"
              />
            </>
          )}
          {!!showLikelihoodOfRecommendingQuestion && (
            <>
              <div className="text-field-label mb-xs">
                How likely are you to recommend this workshop to a friend or colleague?
              </div>
              <LineRatingField
                numOptions={10}
                name="likelihood_of_recommending"
                showNumbers={true}
                axisLabels={["Not at all likely", "Extremely likely"]}
                ariaLabels={range(10).map((idx) => {
                  const baseLabel = `${idx + 1} out of 10`
                  const suffix = idx === 0 ? ".  Not at all likely" : idx === 9 ? ". Extremely likely" : ""
                  return `${baseLabel}${suffix}`
                })}
                className="mb-large line-rating-field"
              />
            </>
          )}
          {!!showThinkAboutSessionQuestion && (
            <>
              <div className="text-field-label mb-xs">
                {sessionStep.think_about_session_label ?? "What do you think about this session?"}
              </div>
              {sessionStep.use_shortened_think_about_session ? (
                <IconRatingField
                  name="think_about_session.value"
                  showLabels={false}
                  useTooltip={false}
                  className="mb-large"
                  individualChoiceClassName="border border-radius-small blur-4 p-small"
                  options={[
                    {
                      value: "1",
                      icon: "ThumbsDownIcon",
                      color: "var(--orange-2)",
                      label: "Not helpful",
                    },
                    {
                      value: "3",
                      icon: "MehIcon",
                      color: "var(--rising-yellow)",
                      label: "Neutral",
                    },
                    {
                      value: "5",
                      icon: "ThumbsUpIcon",
                      color: "var(--rising-green)",
                      label: "Helpful",
                    },
                  ]}
                />
              ) : (
                <SmileRatingField
                  name="think_about_session.value"
                  showLabels={false}
                  useTooltip={false}
                  className="mb-large"
                  individualChoiceClassName="border border-radius-small blur-4 p-small"
                />
              )}
            </>
          )}
          {!!showImproveExperienceFeedbackQuestion && (
            <TextareaField
              name="improve_experience_feedback"
              label={
                sessionStep.improve_experience_feedback_label ??
                "Was there anything you especially liked about this session or thought could be improved?"
              }
              className="mb-mlarge"
            />
          )}
          <StepNavigation
            onNext={navigateNextHandler}
            onBack={navigateBackHandler}
            nextButtonProps={nextButtonProps}
            backButtonProps={backButtonProps}
            rightContent={navRightContent}
          />
        </Form>
      </Formik>
    </StepContent>
  )
})`
  .line-rating-field {
    max-width: 384px;
  }
`

function getSurveySchema({ demo, isJumbo, isJumboPromoSession, isMini, showWorkMoreEffectivelyQuestion }) {
  const isOptional = !!(isJumbo || demo || import.meta.env.VITE_APP_FEEDBACK_SURVEY_OPTIONAL)

  // For standard kit instances, all survey fields are required.
  // For demo kit instances, all survey fields are optional.
  // For jumbo kit instances, all survey fields are optional.
  // For connection kits, work_more_effectively is removed from the questions.
  const strVal = () => (isOptional ? Yup.string() : Yup.string().required())

  const learned_something_valuable = Yup.object().shape({ value: strVal().label("Learned something valuable") })
  const feel_more_connected = Yup.object().shape({ value: strVal().label("Feel more connected") })
  const work_more_effectively = Yup.object().shape({ value: strVal().label("Work more effectively") })
  const likelihood_of_recommending = Yup.object().shape({ value: strVal().label("Likelihood of recommending") })
  const think_about_session = Yup.object().shape({
    value: isOptional
      ? Yup.string()
      : Yup.string().required("Please rate this session, we value your feedback!").label("Opinion about session"),
  })
  const improve_experience_feedback = Yup.string().label("Feedback")

  if (isMini) {
    return Yup.object().shape({
      think_about_session,
      improve_experience_feedback,
    })
  }

  if (isJumboPromoSession) {
    return Yup.object().shape({
      learned_something_valuable,
      likelihood_of_recommending,
      improve_experience_feedback,
    })
  }
  if (!showWorkMoreEffectivelyQuestion) {
    return Yup.object().shape({
      learned_something_valuable,
      feel_more_connected,
      likelihood_of_recommending,
      improve_experience_feedback,
    })
  }
  return Yup.object().shape({
    learned_something_valuable,
    feel_more_connected,
    work_more_effectively,
    likelihood_of_recommending,
    improve_experience_feedback,
  })
}

export default FeedbackStep
