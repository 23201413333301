import CrisisResponseTeamSummary from "domains/Exercise/ResultsComponents/CrisisResponseTeamSummary"

const CrisisResponseExerciseReport = ({ exerciseInstances, userIdMap, team }) => (
  <CrisisResponseTeamSummary
    exerciseInstances={exerciseInstances}
    userIdMap={userIdMap}
    teamResults={true}
    practiceScenario={false}
    team={team}
  />
)

export default CrisisResponseExerciseReport
