import cn from "classnames"
import { styled } from "styled-components"

import getIconOrError from "icons"
import View from "ui/View"

const IconLabelGrid = ({ icons_data, className }) => (
  <div className={cn(className)}>
    <View className="agenda" $flexDirectionMobile="column">
      {icons_data.map((icon, idx) => {
        const Icon = getIconOrError(icon.name)
        return (
          <div className="icon-text-container" key={idx}>
            <Icon color={`var(--${icon.color})`} className="fa-2x fa-fw pb-medium icon" />
            <p>{icon.label}</p>
          </div>
        )
      })}
    </View>
  </div>
)

export default styled(IconLabelGrid)`
  .icon-text-container {
    display: flex;
    flex-direction: column;
    padding: ${({ icons_data }) => (icons_data.length > 6 ? " 0 var(--spacing-2)" : "0 var(--spacing-4)")};
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    flex-grow: 1;
    flex-basis: 0;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 54px;
      border-right: 2px dashed var(--gray-5);
    }
  }

  .agenda div:first-child {
    padding-left: var(--spacing-3);
  }

  .agenda div:last-child {
    border-right: none;
    padding-right: var(--spacing-3);
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    h3 {
      margin-bottom: var(--spacing-3);
    }

    .icon-text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-right: none;
      text-align: flex-start;
      padding: 0 0 var(--spacing-3) 0;

      &:not(:last-child)::after {
        border-right: none;
      }
    }

    .agenda {
      display: flex;
      flex-direction: column;
      padding: 0;

      div:first-child {
        padding-left: 0;
      }

      div:last-child {
        padding: 0 0 var(--spacing-7) 0;
        text-align: left;
      }
    }

    .icon {
      font-size: 1.5rem;
      padding: 0 var(--spacing-2) 0 0;
    }
  }
`
