const BOHLeadershipPrinciplesTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Prepare for the session by learning more about why Bank of Hawaiʻi developed our Leadership Principles, why they
        matter, and what they mean.
      </p>
      <h2 className="text-rising-orange mb-medium">Building Strength for the Future</h2>
      <p className="mb-medium">
        Bank of Hawaiʻi is committed to the next 125+ years as an independent financial institution in the markets that
        we serve. In order to meet that commitment we need to continuously invest, adapt and improve to meet current
        needs while building resilience for the future.
      </p>
      <p className="mb-medium">
        The key to long-term sustainability is to ever evolve, navigate fluidly and adapt in all areas. Leadership is
        the key to our success. We need to continue to evolve and focus on ongoing development for our leaders and team
        members so we can all work more effectively across the business and teams.
      </p>
      <p className="mb-medium">
        Becoming a great leader just doesn’t happen. It takes a lot of work and effort to become an effective and
        inspiring leader to your team and colleagues. Leadership is about fostering an environment of partnership and
        collaboration towards a shared purpose across the organization.
      </p>
      <p className="mb-medium">Let’s learn about our Leadership Principles at Bank of Hawaiʻi.</p>
      <h2 className="text-rising-orange mb-medium">What is a Leadership Principle</h2>
      <p className="mb-medium">
        A Leadership Principle is a guide for our behavior as leaders. The principles can inspire a leader's actions,
        decisions, and efforts that guide them and their team to successful outcomes.
      </p>

      <h2 className="text-rising-orange mb-medium">Our Leadership Principles</h2>
      <p className="mb-medium">
        At Bank of Hawaiʻi, we have created principles that are key to living the behaviors that lead to leadership
        excellence.
      </p>
      <p className="mb-medium">Here are the Leadership Principles:</p>
      <h3 className="mb-medium">1. Be One Bankoh</h3>
      <p className="mb-medium">
        Lead across departments to elevate partnerships and collaboration toward a shared purpose. Put our employees,
        customers and shareholders first.
      </p>
      <h3 className="mb-medium">2. Champion Forward Thinking</h3>
      <p className="mb-medium">
        Embrace new ways of working and promote diverse thinking. Inspire and nurture creativity, curiosity, and
        learning in the pursuit of continuous improvement.
      </p>
      <h3 className="mb-medium">3. Walk the Talk</h3>
      <p className="mb-medium">Lead by example and be accountable for our actions.</p>
      <h3 className="mb-medium">4. Invest in Our People</h3>
      <p className="mb-medium">
        Actively develop, coach, mentor, and share talent across the organization. Advocate for our team members and
        celebrate their successes throughout their career journey.
      </p>
      <h3 className="mb-medium">5. Empower Our People</h3>
      <p className="mb-medium">
        Create opportunities for team members to take initiative and grow decision-making ability.
      </p>
      <p className="mb-medium">
        Now that you understand what our Leadership Principles are, we hope to inspire leaders at all levels to be
        value-creators and positively impact our business by translating our principles into exceptional outcomes so we
        can all thrive.
      </p>
      <p className="mb-medium">Because when we THRIVE we SHINE!</p>
    </div>
  </div>
)

export default BOHLeadershipPrinciplesTraining1
