const ExpectationsTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        After you complete the Rising Team session on clear expectations, we want to give you some tips for how to
        continue building this practice discipline with your team.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Start with the WHAT and WHY—not the HOW</h2>
      <p className="mb-medium">
        Although delegation is an important part of managing a team, setting and clarifying expectations starts with
        defining the outcome you're looking for, and then empowering people to reach the goal as they choose.
      </p>
      <p className="mb-medium">
        For every goal or responsibility you choose to delegate, consider the following questions:
      </p>
      <ul className="ml-xl mb-large">
        <li>How does this connect to a larger purpose?</li>
        <li>Why am I asking this person/s to own this result?</li>
        <li>Am I fully empowering them to own it?</li>
        <li>How will I know they are successful?</li>
      </ul>
      <h2 className="text-rising-orange mb-medium">2. Make the ROAD a new habit</h2>
      <p className="mb-medium">
        Creating a culture of clarity and commitment requires building team habits until they become a core part of how
        the team operates. This can be done by utilizing the ROAD framework to transparently set expectations. When the
        ROAD framework is consistently used, the team will build a habit of proactively asking questions—and keep asking
        them until they're clear enough to commit. This can be done in 1:1 as well as team meetings.
      </p>
      <h2 className="text-rising-orange mb-medium">3. The ROAD as a two-way street</h2>
      <p className="mb-medium">
        Setting expectations goes both ways; involving both setting expectations with team members and clarifying what
        they can expect of you. Encourage your team to ask clarifying questions. Some people need the extra
        encouragement, especially if there is a power imbalance. The extra time required is usually worth it in the
        end—especially if questions arise that you don't yet have the answers for. And this process can help create more
        clarity around your goals as well.
      </p>
      <h2 className="text-rising-orange mb-medium">4. Communicate early and often</h2>
      <p className="mb-medium">
        The fast-moving nature of work can lead to miscommunication and lack of clarity. Aligning on expectations early
        on reduces confusion and ensures our commitments are clear. In a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2020/07/5-tips-for-communicating-with-employees-during-a-crisis"
        >
          recent HBR article
        </a>
        , authors Brooks Holtom, Amy C. Henderson, and David Niu state, "Most leaders need to communicate to staff far
        more often than they think is necessary. Frequent communication reduces fear and uncertainty and ensures that
        employees have heard the message." This is especially relevant during times of change and transition, especially
        when priorities shift.
      </p>
      <p className="mb-medium">
        Get ahead of the curve by using the ROAD model both at the onset of a new goal if and when things change. This
        framework can help you make your "early and often" communication even more clear.
      </p>
      <h2 className="text-rising-orange mb-medium">5. Write it down</h2>
      <p className="mb-medium">
        Whether mapping out measures of success or setting a plan to overcome obstacles, it's important to record
        decisions and next steps. This will help both parties stay accountable and avoid potential misalignment down the
        road. Inc. Magazine cites{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.inc.com/peter-economy/this-is-way-you-need-to-write-down-your-goals-for-faster-success.html"
        >
          a goal-setting study
        </a>{" "}
        led by psychology professor Dr. Gail Matthews that concluded that "you are 42 percent more likely to achieve
        your goals if you write them down." We provide a template for the ROAD framework that you can use to make sure
        you write this all down and give yourselves a higher chance of reaching your goals. The template will be
        available after completion of the team session.
      </p>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="ml-xl mb-xxxxl">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2021/05/what-your-future-employees-want-most"
          >
            What Your Future Employees Want Most
          </a>{" "}
          (Harvard Business Review)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/holding-your-team-accountable"
          >
            Holding Your Team Accountable
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/learning/managing-for-results-4">
            Managing for Results
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/resourcesandtools/hr-topics/people-managers/pages/setting-employee-expectations.aspx"
          >
            6 Tips on Setting Expectations for Employees
          </a>{" "}
          (Society for Human Resource Management)
        </li>
      </ul>
    </div>
  </div>
)

export default ExpectationsTraining3
