import RealtimeSlideshow from "./components/RealtimeSlideshow"
import { useKitSession } from "./KitSessionContext"

import blockComponents from "components/Blocks/blockComponents"
import renderBlocks from "components/Blocks/renderBlocks"
import { getTeamLead } from "domains/Exercise/results_utils"
import FeedbackPeerFramework from "domains/Exercise/ResultsComponents/FeedbackPeerFramework"
import AggregatedResultsBlocksContainer from "domains/KitSession/components/AggregatedResultsBlocksContainer"
import AppCodeURL from "domains/KitSession/components/AppCodeURL"
import Confetti from "domains/KitSession/components/Confetti"
import CurrentUserContextContainer from "domains/KitSession/components/CurrentUserContextContainer"
import CurrentUserIndividualResultsBlocksContainer from "domains/KitSession/components/CurrentUserIndividualResultsBlocksContainer"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import GroupTimer from "domains/KitSession/components/GroupTimer"
import IconLabelGrid from "domains/KitSession/components/IconLabelGrid"
import IfAnyUserExerciseAnswerEqualsContainer from "domains/KitSession/components/IfAnyUserExerciseAnswerEqualsContainer"
import IfTeamLevelExerciseAnswerEqualsContainer from "domains/KitSession/components/IfTeamLevelExerciseAnswerEqualsContainer"
import IfTeamLevelExerciseAnswerExistsOrNotContainer from "domains/KitSession/components/IfTeamLevelExerciseAnswerExistsOrNotContainer"
import MarkStepCompleted from "domains/KitSession/components/MarkStepCompleted"
import MiniKitSessionSelectedUsersShareView from "domains/KitSession/components/MiniKitSessionSelectedUsersShareView"
import NumMaxParticipants from "domains/KitSession/components/NumMaxParticipants"
import NumParticipantsWarning from "domains/KitSession/components/NumParticipantsWarning"
import NumPeopleCompleted from "domains/KitSession/components/NumPeopleCompleted"
import ParticipantCountGrid from "domains/KitSession/components/ParticipantCountGrid"
import ParticipantCountList from "domains/KitSession/components/ParticipantCountList"
import PreviewSessionStartButton from "domains/KitSession/components/PreviewSessionStartButton"
import RaisedHandPicker from "domains/KitSession/components/RaisedHandPicker"
import RaiseHandButton from "domains/KitSession/components/RaiseHandButton"
import ReflectionsForm from "domains/KitSession/components/ReflectionsForm"
import ResultsBlocksContainer from "domains/KitSession/components/ResultsBlocksContainer"
import ScheduleSession from "domains/KitSession/components/ScheduleSession"
import SessionCodeCopyLink from "domains/KitSession/components/SessionCodeCopyLink"
import SessionExerciseForm from "domains/KitSession/components/SessionExerciseForm"
import SessionImage from "domains/KitSession/components/SessionImage"
import SessionImagePrompt from "domains/KitSession/components/SessionImagePrompt"
import SessionImageUploaded from "domains/KitSession/components/SessionImageUploaded"
import SessionLink from "domains/KitSession/components/SessionLink"
import SessionQRCode from "domains/KitSession/components/SessionQRCode"
import SessionSelectedUsersShareView from "domains/KitSession/components/SessionSelectedUsersShareView"
import SessionTeamExerciseForm from "domains/KitSession/components/SessionTeamExerciseForm"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepTimer from "domains/KitSession/components/StepTimer"
import StepTitle from "domains/KitSession/components/StepTitle"
import TeamLevelExerciseResultsBlocksContainer from "domains/KitSession/components/TeamLevelExerciseResultsBlocksContainer"
import TeamMemberWelcomeStepInfo from "domains/KitSession/components/TeamMemberWelcomeStepInfo"
import { blockIcons } from "icons"
import { useKitParticipants, getAppCodeURL } from "resources/monthly_kit"
import blockUIComponents from "ui/blockUIComponents"
import Button from "ui/Button"
import Callout from "ui/Callout"
import DefaultTimer from "ui/DefaultTimer"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import PromptTip from "ui/PromptTip"
import { buildUrl } from "utils/string"
import { useHasTeamFeature } from "utils/team"

function getName(user, first = false, last = false) {
  const name = user?.name ?? null
  if (first) {
    return user?.first_name ?? name
  } else if (last) {
    return user?.last_name ?? name
  } else {
    return name
  }
}

const SessionCode = () => {
  const { kitInstance } = useKitSession()
  return kitInstance.session_code
}

const CodePageURL = () => getAppCodeURL()

const FullCodeURL = () => {
  const { kitInstance } = useKitSession()
  return buildUrl([getAppCodeURL(), kitInstance.session_code], { useTrailingSlash: false })
}

const UserName = ({ user, first = false, last = false }) => getName(user, first, last)

const TeamLeadName = ({ first = false, last = false }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })

  if (!participants) {
    return null
  }
  const teamLead = getTeamLead(participants, team)
  return getName(teamLead, first, last)
}

const TeamLeadOnly = ({ isTeamLead, children }) => (!isTeamLead ? null : children)

const TeamMemberOnly = ({ isTeamLead, children }) => (isTeamLead ? null : children)

const HideIfPreview = ({ children }) => {
  const { preview } = useKitSession()
  return !!preview ? null : children
}

const ShowIfPreview = ({ children }) => {
  const { preview } = useKitSession()
  return !!preview ? children : null
}

const ShowIfSharedFlagActive = ({ sharedFlag, children }) => {
  const { team } = useKitSession()
  const { enabled } = useHasTeamFeature(team, sharedFlag)
  return enabled ? children : null
}

const ShowIfSharedFlagInactive = ({ sharedFlag, children }) => {
  const { team } = useKitSession()
  const { enabled } = useHasTeamFeature(team, sharedFlag)
  return enabled ? null : children
}

// Session Components
const SESSION_COMPONENTS = {
  AggregatedResultsBlocksContainer,
  AppCodeURL,
  Button,
  Callout,
  Confetti,
  CurrentUserContextContainer,
  CurrentUserIndividualResultsBlocksContainer,
  DefaultTimer,
  FacilitatorTip,
  FeedbackPeerFramework,
  GroupTimer,
  IconLabelGrid,
  IfAnyUserExerciseAnswerEqualsContainer,
  IfTeamLevelExerciseAnswerEqualsContainer,
  IfTeamLevelExerciseAnswerExistsOrNotContainer,
  HideIfPreview,
  MiniKitSessionSelectedUsersShareView,
  NumMaxParticipants,
  NumParticipantsWarning,
  NumPeopleCompleted,
  MarkStepCompleted,
  ParticipantCountGrid,
  ParticipantCountList,
  PreviewSessionStartButton,
  PromptTip,
  RaiseHandButton,
  RaisedHandPicker,
  ReflectionsForm,
  ResultsBlocksContainer,
  SessionCode,
  SessionExerciseForm,
  SessionTeamExerciseForm,
  SessionLink,
  SessionImage,
  SessionImagePrompt,
  ScheduleSession,
  SessionImageUploaded,
  SessionQRCode,
  SessionSelectedUsersShareView,
  SessionCodeCopyLink,
  CodePageURL,
  FullCodeURL,
  ShowIfSharedFlagActive,
  ShowIfSharedFlagInactive,
  ShowIfPreview,
  SidebarContainer,
  StepTimer,
  StepTitle,
  TeamLevelExerciseResultsBlocksContainer,
  TeamLeadName,
  TeamLeadOnly,
  TeamMemberOnly,
  TeamMemberWelcomeStepInfo,
  UserName,
  RealtimeSlideshow,
}

const isSessionComponent = (Tag) => Object.values(SESSION_COMPONENTS).includes(Tag)

export default function renderKitBlocks(
  content,
  {
    kitInstance,
    sessionStep,
    user,
    isTeamLead,
    preview,
    navigateNextHandler,
    navigateBackHandler,
    nextButtonProps,
    backButtonProps,
    navRightContent,
  }
) {
  const components = { ...blockComponents, ...blockUIComponents, ...blockIcons, ...SESSION_COMPONENTS }
  return renderBlocks(content, components, ({ Tag, props }) => {
    if (isSessionComponent(Tag)) {
      return {
        ...props,
        kitInstance,
        sessionStep,
        user,
        isTeamLead,
        preview,
        navigateNextHandler,
        navigateBackHandler,
        nextButtonProps,
        backButtonProps,
        navRightContent,
      }
    } else {
      return props
    }
  })
}
