import cn from "classnames"
import { styled } from "styled-components"

import { useIsSchoolAccountType } from "components/AccountTypeContext"
import View from "ui/View"

const CeoImage = styled(function CeoImage({ className, text = null }) {
  const isSchool = useIsSchoolAccountType()
  const imageFilename = isSchool ? "school-jen.png" : "jen.png"

  return (
    <View $justifyContent="center" className={cn("show-on-desktop", className)}>
      <img src={`https://static.risingteam.com/kit_assets/onboarding/${imageFilename}`} alt="" width="382px" />
      {!!text && (
        <p className="ceo-text">
          <span>{text}</span>
        </p>
      )}
    </View>
  )
})`
  position: relative;

  .ceo-text {
    position: absolute;
    top: 150px;
    width: 325px;
    height: 150px;
    font-size: 14px;
    line-height: 23px;
    text-align: justify;

    // Add slant to left side of CEO text container:
    &::before {
      content: "";
      float: left;
      width: 40px;
      height: 100%;
      shape-outside: polygon(0 0, 100% 0, 0 100%);
    }

    // Add background color to cover standard CEO-quote to replace with new text:
    &::after {
      content: "";
      background: var(--gray-1);
      position: absolute;
      top: 0;
      left: 30px;
      width: 310px;
      height: 150px;
      z-index: var(--z-zero);
    }

    // Use inner span with z-index:1 so text appears above background color:
    span {
      z-index: var(--z-above-zero);
      position: relative; // needed for z-index to work
    }
  }
`

const NonCEOImage = styled(function NonCEOImage({ className, imageFilename = null, imgAlt = "" }) {
  return (
    <View $justifyContent="center" className={cn("show-on-desktop", className)}>
      <img src={`https://static.risingteam.com/kit_assets/onboarding/${imageFilename}`} alt={imgAlt} width="382px" />
    </View>
  )
})`
  position: relative;
`

export { CeoImage, NonCEOImage }
