import { forwardRef, useState, type Ref } from "react"
import { styled } from "styled-components"

import InputField, { StyledInput, type InputFieldProps } from "./InputField"

import { EyeIcon, EyeSlashIcon } from "icons/FontAwesomeIcons"

interface StyledPasswordInputProps extends Omit<InputFieldProps, "type"> {
  className?: string
  passwordHint?: boolean
}

interface PasswordFieldProps extends StyledPasswordInputProps {
  eye?: boolean
  inert?: boolean
  autoComplete?: string
}

const StyledPasswordInput = styled(
  forwardRef(function StyledPasswordInput(
    { className, passwordHint = false, ...props }: StyledPasswordInputProps,
    ref: Ref<HTMLInputElement>
  ) {
    const [hidden, setHidden] = useState(true)

    return (
      <div className={className}>
        {!!passwordHint && (
          <div className="text-thin text-small text-gray-8 pb-small show-on-desktop">
            Passwords must be at least eight characters long
          </div>
        )}
        {hidden ? (
          <>
            <StyledInput className={className} ref={ref} {...props} type="password" />
            <EyeIcon className="eye" onClick={() => setHidden(false)} />
          </>
        ) : (
          <>
            <StyledInput className={className} ref={ref} {...props} type="text" />
            <EyeSlashIcon className="eye" onClick={() => setHidden(true)} />
          </>
        )}
      </div>
    )
  })
)`
  position: relative;

  .eye {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
`

const PasswordField = ({
  className,
  eye = false,
  passwordHint = false,
  inert = false,
  ...props
}: PasswordFieldProps) => {
  if (inert) {
    props = { ...props, inert: "true" } as unknown as PasswordFieldProps
    // need to use "true" string for inert attribute until React supports it,
    // watch https://github.com/facebook/react/pull/24730
  }
  return !eye ? (
    <InputField className={className} {...props} type="password" passwordHint={passwordHint} />
  ) : (
    <InputField className={className} forwardedAs={StyledPasswordInput} {...props} passwordHint={passwordHint} />
  )
}

PasswordField.defaultProps = {
  eye: false,
}

export default PasswordField
