import { Popover } from "@headlessui/react"
import cn from "classnames"
import { format as formatDate, parseISO } from "date-fns"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import TeamPhotoModalContent from "domains/LeaderKit/TeamPhotoModalContent"
import TeamPhotoShareCopy from "domains/LeaderKit/TeamPhotoShareCopy"
import { CheckIcon, ShareIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants, useSessionPicture } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import BulletedList from "ui/BulletedList"
import Button from "ui/Button"
import FlairContainer from "ui/FlairContainer"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useModal } from "ui/ModalContext"
import PageBreadcrumbs from "ui/PageBreadcrumbs"
import SocialShareButtons from "ui/SocialShareButtons"
import TextContainer from "ui/TextContainer"
import View from "ui/View"
import { asArray } from "utils/array"
import { KitGroupSize, getKitTypeInfo } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const SessionCompleteHome = ({ kitInstance, user, className }) => {
  const { data: team } = useTeam({ teamId: kitInstance.team_id })
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { data: sessionPicture } = useSessionPicture({
    kitInstanceId: kitInstance.id,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })

  if (!participants || !kitInstance || !team) {
    return null
  }

  const sessionCompletedTime = formatDate(parseISO(kitInstance.session_completed_at), "PPP")
  const sessionCompleteText = asArray(kitInstance.kit.session_complete_text)[0] ?? null
  const hasPrepLinks = kitInstance.kit.prep_links?.length ? true : false
  const hasResultsPages = kitInstance.kit.results_pages?.length ? true : false
  const isTeamLead = team.team_lead_id === user.id

  return (
    <main className={cn("align-left full-width", className)}>
      <FlairContainer className="main-container">
        <TextContainer maxWidth={525} mobileMaxWidth={265}>
          <PageBreadcrumbs className="mb-medium">
            <Link to={`/kit?team_id=${kitInstance.team_id}`}>View all kits</Link>
            {kitInstance.kit.title}
          </PageBreadcrumbs>
          <h4 className="text-primary text-bold">Theme</h4>
          <h1 className="text-gray-9 text-bold py-small pr-medium">{kitInstance.kit.title}</h1>
          <div className="text-gray-9 text-thin">{kitInstance.kit.description}</div>
        </TextContainer>
        <TextContainer maxWidth={675}>
          <div className="session-border mt-xl pt-small pb-large px-large">
            <View className="text-gray-9 mb-medium" $alignItems="flex-end">
              <CheckIcon color="var(--rising-green)" className="mr-xs fa-2x" />
              <h4>Session complete!</h4>
            </View>
            <div className="text-small text-gray-9 mb-small">
              Your team of {participants.length} completed this session on {sessionCompletedTime}.
            </div>
            {!!hasResultsPages && (
              <div className="text-small text-gray-9">
                <Link to={kitInstance.results_url}>View the exercise results</Link> from the session. (You can also view
                this from the Team Results tab.)
              </div>
            )}
          </div>
          {!!sessionPicture && (
            <TeamPhotoSection kitInstance={kitInstance} sessionPicture={sessionPicture} className="mt-xl" />
          )}
          {!!(
            getKitTypeInfo(kitInstance.kit.type).hasReflections && kitInstance.kit.group_size !== KitGroupSize.JUMBO
          ) && (
            <div className="mt-xl">
              <h3 className="text-gray-9 text-bold">Reflections</h3>
              <div className="text-gray-9 pt-xxs">
                You captured some reflections and action items during the session.{" "}
                <Link to={`${kitInstance.review_url}reflections/`}>See them again.</Link>
              </div>
            </div>
          )}
          {!!sessionCompleteText && !!kitInstance.kit.bonus_page && (
            <div className="mt-xl">
              <h3 className="text-gray-9 text-bold">{sessionCompleteText["bonus_title"]}</h3>
              <div className="pt-xs">
                {sessionCompleteText["bonus_text"]}{" "}
                <Link to={kitInstance.bonus_url}>{sessionCompleteText["bonus_link_text"]}</Link>
              </div>
            </div>
          )}
          {isTeamLead && hasPrepLinks ? (
            <div className="mt-xl">
              <h3 className="text-gray-9 text-bold">Training Materials</h3>
              <div className="pt-xxs">Review what you learned about the theme</div>
              <BulletedList className="mt-medium">
                {kitInstance.kit.prep_links.map((prep_link) => (
                  <li key={prep_link.title} className="bullet-point">
                    <div className="text-normal">
                      {prep_link.slug ? (
                        <Link to={`${kitInstance.prep_url}articles/${prep_link.slug}`}>{prep_link.title}</Link>
                      ) : (
                        <a target="_blank" rel="noopener noreferrer" href={prep_link.external_url}>
                          {prep_link.title}
                        </a>
                      )}
                    </div>
                  </li>
                ))}
              </BulletedList>
            </div>
          ) : null}
          {!!isTeamLead && (
            <div className="mt-xl">
              <h3 className="text-gray-9 text-bold">The Huddle</h3>
              <div className="pt-xs text-small">
                Jump into our community of managers and coaches in{" "}
                <a href="https://app.risingteam.com/slack/invite" target="_blank" rel="noopener noreferrer">
                  the Huddle on Slack
                </a>{" "}
                to discuss.
              </div>
            </div>
          )}
        </TextContainer>
      </FlairContainer>
    </main>
  )
}

const TeamPhotoSection = styled(function TeamPhotoSection({ kitInstance, sessionPicture, className }) {
  const { setModal } = useModal()
  const openModal = () => {
    setModal({
      content: <TeamPhotoModalContent kitInfo={kitInstance?.kit} sessionPictureUrl={sessionPicture} />,
      size: "medium",
      showFrame: false,
    })
  }

  return (
    <div className={className}>
      <h3 className="text-gray-9 text-bold mb-small">Team Photo</h3>
      <View className="team-photo-view" $flexWrap="wrap">
        <img src={sessionPicture} alt="Team" className="mr-large mb-small" onClick={openModal} />
        <div className="team-photo-text">
          <TeamPhotoShareCopy className="mb-xxs" />
          <TeamPhotoShareButton kitInstance={kitInstance} sessionPicture={sessionPicture} />
        </div>
      </View>
    </div>
  )
})`
  .team-photo-text {
    flex: 1 1 0;
    min-width: 200px;
  }

  .team-photo-view img {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    cursor: pointer;
  }

  .photo-share-button {
    display: inline-flex;
    white-space: nowrap;
  }
`

const TeamPhotoShareButton = styled(function TeamPhotoShareButton({ sessionPicture, className }) {
  return (
    <Popover className={cn("inline-block", className)}>
      <Popover.Button as={Button} className="photo-share-button tertiary mt-small">
        <span>Share your photo</span>
        <ShareIcon />
      </Popover.Button>
      <Popover.Panel>
        <SocialShareButtons url={sessionPicture} className="mt-small" />
      </Popover.Panel>
    </Popover>
  )
})`
  min-width: 235px;
`

export default styled(SessionCompleteHome)`
  .session-border {
    border-width: 1px 1px 1px 8px;
    border-radius 8px 8px 8px 8px;
    border-style: solid;
    border-color: #EEEEEE #EEEEEE #EEEEEE var(--rising-green);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
`
