import { useState } from "react"
import { styled } from "styled-components"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"

const StyledProgressTimer = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #d4d4d4;
  overflow: hidden;

  div {
    position: relative;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div:first-child {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 50%;
      transform: translateX(-50%);
      font-size: 22px;
      font-weight: 700;
      z-index: var(--z-above-zero);
      background: rgb(212 212 212 / 50%);
      border-radius: 100%;
      height: 60px;
      width: 60px;
    }

    div:last-child {
      height: 40px;
      width: ${({ progressBarWidth }) => progressBarWidth || "100%"};
      background-color: ${({ progressBarColor }) => progressBarColor};
      transition: width 0.5s ease, background-color 0.5s ease;
    }
  }
`

const ProgressTimer = ({
  fromSeconds = 10,
  countFinished,
  progressColors = ["var(--rising-green)", "var(--rising-yellow)", "var(--rising-orange)"],
}) => {
  const [seconds, setSeconds] = useState(fromSeconds)
  const [progress, setProgress] = useState(0)

  useEffectAfterChange(() => {
    const triggerCountFinished = () => {
      setTimeout(() => {
        countFinished()
      }, 0)
    }

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newSeconds = prevSeconds - fromSeconds / 100

        if (newSeconds <= -1) {
          clearInterval(intervalId)
          countFinished && triggerCountFinished()
          return 0
        }

        return newSeconds
      })

      setProgress(() => ((fromSeconds - seconds) / fromSeconds) * 100)
    }, fromSeconds * 10)

    return () => clearInterval(intervalId)
  }, [seconds, countFinished, fromSeconds])

  const getColorFromProgress = () => {
    const colors = progressColors
    const index = Math.floor((progress / 100) * (colors.length - 1))
    return colors[index]
  }

  return (
    <StyledProgressTimer progressBarColor={getColorFromProgress()} progressBarWidth={`${progress}%`}>
      <div>
        <div>{Math.ceil(seconds)}</div>
        <div />
      </div>
    </StyledProgressTimer>
  )
}

export default ProgressTimer
