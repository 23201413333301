import { Route, Routes } from "react-router"

import { CSAOrgIntegration, Cornell2023Integration } from "./FreeKitSignUp"
import IntuitIntegration from "./IntuitIntegration"

const RTIntegrations = () => (
  <Routes>
    <Route path="/intuit/*" element={<IntuitIntegration />} />
    <Route path="/csa_org/*" element={<CSAOrgIntegration />} />
    <Route path="/cornell_2023/*" element={<Cornell2023Integration />} />
  </Routes>
)

export default RTIntegrations
