import { createContext, useContext } from "react"

const ExerciseContext = createContext({
  exerciseInstance: null,
})

const useExerciseContext = () => useContext(ExerciseContext) || {}

export default ExerciseContext

export { useExerciseContext }
