import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { ErrorMessage, Form, Formik } from "formik"
import { useState } from "react"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import TextField from "forms/fields/TextField"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { LightbulbIcon } from "icons/FontAwesomeIcons"
import { useCreateAccountTeam, useAddTeamAccounts } from "resources/billing"
import Button from "ui/Button"
import Loading from "ui/Loading"
import View from "ui/View"

const CreateNewTeamForm = styled(function CreateNewTeamForm({
  className,
  account,
  user,
  onSubmit = null,
  onTeamChange,
}) {
  const { data: accounts } = useAddTeamAccounts()
  const hasMultipleAccounts = accounts?.length > 1
  const [selectedAccountId, setSelectedAccountId] = useState(hasMultipleAccounts ? null : account.id)
  const { mutateAsync: createTeam } = useCreateAccountTeam(selectedAccountId)
  const [addTeamLoading, setAddTeamLoading] = useState(false)
  const [addTeamError, setAddTeamError] = useState(false)

  if (!accounts) {
    return null
  }

  const accountOptions = accounts.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const onFormSubmit = handleErrors(
    async (values) => {
      setAddTeamError(false)
      setAddTeamLoading(true)
      const { team_name, team_lead_id } = values
      const newTeam = await createTeam({
        team_name,
        team_lead_id,
      })
      onSubmit?.()
      onTeamChange({ value: newTeam.id })
    },
    (data, _values, formik, err) => {
      setAddTeamLoading(false)
      const format = (errMsg) => (Array.isArray(errMsg) ? errMsg.join(", ") : errMsg)
      if (data.team_name) {
        formik.setErrors({ team_name: format(data.team_name) })
      } else if (data.account_id) {
        formik.setErrors({ account_id: format(data.account_id) })
      } else {
        setAddTeamError(true)
      }
      Sentry.captureException(err)
      return null
    }
  )
  const initialValues = {
    account_id: hasMultipleAccounts ? null : account.id,
    team_name: "",
    team_lead_id: user.id,
  }

  const schema = Yup.object().shape({
    account_id: Yup.number().required("Please select an account."),
    team_name: Yup.string().required("Please enter a team name."),
  })

  return (
    <div className={cn("add-team-form", className, { disabled: addTeamLoading })}>
      {!!addTeamLoading && <Loading className="add-team-form-loading" />}
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onFormSubmit}
      >
        <Form>
          <p className="text-normal mb-large">
            Creating a new team allows you to run Rising Team sessions with a new group of team members.
          </p>
          {!!hasMultipleAccounts && (
            <>
              <div className="text-semi-bold mb-xs">Account</div>
              <AdvancedSelectField
                name="account_id"
                placeholder="Select an account"
                options={accountOptions}
                saveOnChange={(_name, value) => setSelectedAccountId(value)}
                width={330} // same as default input width
              />
              <ErrorMessage
                component="div"
                className="text-semi-bold text-orange-4 mt-xs mb-small"
                data-cy="field-message"
                name="account_id"
              />
            </>
          )}
          <div className="text-semi-bold mt-medium mb-xs">Team name</div>
          <TextField name="team_name" size="medium" autoComplete="off" placeholder="What is the team's name?" />
          {!!addTeamError && (
            <div className="team-form-error mt-large">
              <div className="text-danger text-semi-bold">Sorry, there was an error creating your team.</div>
              Please try again in a few minutes, or,{" "}
              <Link to="/contact" className="text-semi-bold">
                Contact Us
              </Link>{" "}
              for support.
            </div>
          )}
          <View className="text-normal p-large bg-yellow-3 mt-medium" $flexDirection="column">
            <View>
              <LightbulbIcon color="var(--yellow-2)" className="fa-xl mr-small" />
              <h5>Did you know?</h5>
            </View>
            <p className="pl-xl">
              You can invite new teammates to an existing team. When you schedule the next session, you can add the new
              participant(s) to session which will automatically add them to an existing team (up to 14 members total).
            </p>
          </View>
          <Button className="inline-block mt-large" type="submit">
            Create team
          </Button>
        </Form>
      </Formik>
    </div>
  )
})`
  opacity: 1;
  transition: opacity 0.2s ease;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .add-team-form-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default CreateNewTeamForm
