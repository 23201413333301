import { styled } from "styled-components"

const InputSeekSlider = (props) => <input type="range" {...props} />

export default styled(InputSeekSlider)`
  position: relative;
  appearance: none;
  width: 100%;

  &::-webkit-slider-runnable-track {
    width: 100%;
    border-radius: 2px;
    background: var(--gray-3);
    box-shadow: inset 0 0 2px rgb(0 0 0 / 20%);
    height: 8px;
    cursor: pointer;
  }

  &::-moz-range-track {
    width: 100%;
    border-radius: 2px;
    background: var(--gray-3);
    box-shadow: inset 0 0 2px rgb(0 0 0 / 20%);
    height: 8px;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    border-radius: 2px;
    background: var(--gray-3);
    box-shadow: inset 0 0 2px rgb(0 0 0 / 20%);
    height: 8px;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    margin: -7px 0 0;
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: var(--orange-4);
    box-shadow: 0 2px 4px rgb(35 35 35 / 20%);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    height: 20px;
    width: 20px;
    background-color: var(--orange-4);
    box-shadow: 0 2px 4px rgb(35 35 35 / 20%);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
  }

  &::-ms-thumb {
    height: 20px;
    width: 20px;
    background-color: var(--orange-4);
    box-shadow: 0 2px 4px rgb(35 35 35 / 20%);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
  }

  &:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  &:active::-moz-range-thumb {
    transform: scale(1.2);
  }

  &:active::-ms-thumb {
    transform: scale(1.2);
  }
`
