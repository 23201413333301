import { useState } from "react"
import { styled } from "styled-components"

import TalentBucket from "./TalentBucket"

import Accordion from "components/Accordion"
import { processTalentsData, getTalentsData, TEAMMOJI_BASE_URL, BUCKETS } from "domains/Exercise/results_utils/talents"
import Button from "ui/Button"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const TalentsUserResults = ({ exercise, accordion = false, className }) => {
  const [viewMore, setViewMore] = useState(false)

  if (!exercise) {
    return <div className="text-center">Exercise Not Completed</div>
  }

  const talentsData = getTalentsData(exercise)
  const { sortedTalents, talentBuckets } = processTalentsData(exercise)
  const TalentSectionComponent = accordion ? TalentSectionAccordion : TalentSectionBucket

  const veryHighTalents = sortedTalents.filter((talent) => talentBuckets[talent] === BUCKETS[3])
  const highTalents = sortedTalents.filter((talent) => talentBuckets[talent] === BUCKETS[2])
  const mediumTalents = sortedTalents.filter((talent) => talentBuckets[talent] === BUCKETS[1])
  const lowTalents = sortedTalents.filter((talent) => talentBuckets[talent] === BUCKETS[0])

  return (
    <div className={className}>
      <TextContainer maxWidth={860}>
        {[...veryHighTalents, ...highTalents].map((talent) => (
          <TalentSectionComponent key={talent} talentBucket={talentBuckets[talent]} metadata={talentsData[talent]} />
        ))}
        {!!viewMore && (
          <>
            {!!mediumTalents &&
              mediumTalents.map((talent) => <TalentSectionComponent key={talent} metadata={talentsData[talent]} />)}
            {!!lowTalents &&
              lowTalents.map((talent) => <TalentSectionComponent key={talent} metadata={talentsData[talent]} />)}
          </>
        )}
        <Button className="ml-xl link text-button" onClick={() => setViewMore(!viewMore)}>
          {!viewMore ? "View all >" : "View less >"}
        </Button>
      </TextContainer>
    </div>
  )
}

const TalentSectionBucket = ({ talentBucket, metadata }) => (
  <View className="mb-large">
    <div className="teammoji-container mr-large mb-medium">
      <img src={`${TEAMMOJI_BASE_URL}${metadata.teammoji}`} aria-label={metadata.title} alt="" />
    </div>
    <div>
      <View $alignItems="center" className="mb-xs">
        <h3 className="text-gray-9">{metadata.title}</h3>
        <TalentBucket bucket={talentBucket} className="ml-xs" />
      </View>
      <p className="short-description text-normal text-gray-8 mb-medium">{metadata.shortDescription}</p>
      <p className="text-normal text-gray-9">{metadata.description}</p>
    </div>
  </View>
)

const TalentSectionAccordion = ({ talentBucket, metadata }) => (
  <div>
    <View $alignItems="center" className="mb-xs">
      <h3 className="text-gray-9">{metadata.title}</h3>
      <TalentBucket bucket={talentBucket} className="ml-xs" />
    </View>
    <Accordion className="text-gray-9" title={metadata.shortDescription}>
      <View>
        <div className="teammoji-container mr-large">
          <img src={`${TEAMMOJI_BASE_URL}${metadata.teammoji}`} aria-label={metadata.title} alt="" />
        </div>
        <p className="text-normal text-gray-9">{metadata.description}</p>
      </View>
    </Accordion>
  </div>
)

export default styled(TalentsUserResults)`
  .teammoji-container,
  .teammoji-container img {
    height: 60px;
    width: 60px;
  }

  .short-description {
    font-size: 1.0625rem;
  }
`
