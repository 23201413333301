const LearnerAgilityTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        Imagine a team where everyone felt safe volunteering for new tasks, asking questions, and learning from each
        other. What problems could you solve? What could you create together?
      </p>
      <p className="mb-medium">
        In this kit, you'll discover the benefits of creating a learning culture and develop strategies to increase your
        learning agility. You'll also move on to stage 2 of psychological safety—learner safety—to help your team foster
        a growth mindset.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Why learning agility is essential to growth</h2>
      <p className="mb-medium">
        To get started, let's explore what learning agility is and why it's so important to your team's long-term
        success.
      </p>
      <h3 className="mb-medium">Growth requires learning agility</h3>
      <p className="mb-medium">
        Speed, flexibility, agility—these traits have become critical to the short and long-term success of today's
        organizations. As our workplaces continue to evolve, the need for teams to operate with curiosity and flex as
        needed has never been greater. Whether creating new ways of working, collaborating across cultures, or having to
        learn new skills to solve problems, our ability to be agile learners is not only an important part of the
        success of our team, but also to our careers.
      </p>
      <h3 className="mb-medium">Learning agility is learning how to learn</h3>
      <p className="mb-medium">
        According to the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ccl.org/articles/leading-effectively-articles/tips-for-improving-your-learning-agility/"
        >
          Center for Creative Leadership (CCL)
        </a>
        , "Learning agility is about knowing how to learn—knowing what to do when you don't know what to do. It's about
        learning from experience and applying it in new ways, adapting to new circumstances and opportunities."
      </p>
      <p className="mb-medium">
        An agile learner has a mindset that helps them to continually grow and adapt to unfamiliar situations. This
        growth mindset encourages specific learning behaviors like:
      </p>
      <ul className="ml-xl mb-medium">
        <li>Asking for feedback</li>
        <li>Seeking advice and support</li>
        <li>Volunteering to participate in unknown situations</li>
        <li>Embracing new challenges, even when scared</li>
        <li>Considering the situation from different points of view</li>
      </ul>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://focus.kornferry.com/leadership-and-talent/the-organisational-x-factor-learning-agility/"
        >
          Korn Ferry
        </a>
        , a world leader in leadership and organizational development, concluded that "learning agility is not
        necessarily an academic skill, rather it encapsulates an individual's ability and passion to quickly study a new
        problem and use their own learning process to gain deep understanding before making a decision."
      </p>
      <h3 className="mb-medium">Learning agility predicts success</h3>
      <p className="mb-medium">
        Being learning-able helps us navigate new situations and continually grow and adapt. "Those who demonstrate
        strong 'learning agility' often excel at being able to study, analyze, and understand new situations and new
        business problems,"{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://focus.kornferry.com/leadership-and-talent/the-organisational-x-factor-learning-agility/"
        >
          reports Korn Ferry
        </a>
        .
      </p>
      <p className="mb-medium">
        Their research found that individuals with high learning agility were "18 times more likely to be identified as
        high-potential employees than their low-learning agile colleagues. They are also twice as likely to be
        promoted."
      </p>
      <p className="mb-medium">
        Korn Ferry found that while individual success is important, the real impact of learning agility is best
        understood by looking at the larger company-wide impact.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://focus.kornferry.com/leadership-and-talent/the-organisational-x-factor-learning-agility/"
        >
          Their research
        </a>{" "}
        found that "companies with the greatest rates of high learning agile executives produced 25% higher profit
        margins compared with peer companies."
      </p>
      <p className="mb-medium">
        For teams to experience these kinds of benefits, they first need to feel safe exercising learning agility
        behaviors.
      </p>
      <h2 className="text-rising-orange mb-medium">2. Learning requires psychological safety</h2>
      <p className="mb-medium">
        Learner safety is the second stage of psychological safety, following inclusion safety. It satisfies our basic
        human need to learn and grow. In this section we'll cover the barriers to learner safety and how to create a
        growth mindset culture.
      </p>
      <h3 className="mb-medium">Learning is both intellectual and emotional</h3>
      <p className="mb-medium">
        While we can practice new skills that increase our learning agility, we need to feel safe in the workplace to
        exercise those behaviors. Whether asking a team member for support in rectifying a mistake, seeking feedback on
        a result we've produced, or volunteering to complete a task we've never done, exercising learning agility
        requires some degree of risk and personal vulnerability.
      </p>
      <h3 className="mb-medium">Becoming comfortable with being uncomfortable</h3>
      <p className="mb-medium">
        Timothy R. Clark, author of{" "}
        <i>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.leaderfactor.com/4-stages-of-psychological-safety"
          >
            The 4 Stages of Psychological Safety: Defining the Path to Inclusion and Innovation
          </a>
        </i>
        , found that "We all bring some level of inhibition and anxiety to the learning process. We all have
        insecurities." Clark goes on to explain that "When we sense learner safety, we're more willing to be vulnerable,
        take risks, and develop resilience in the learning process. Conversely, a lack of learner safety triggers the
        self-censoring instinct, causing us to shut down, retrench, and manage personal risk."
      </p>
      <p className="mb-medium">
        Essentially the safer we feel, the more comfortable we are being uncomfortable. And this is the foundation of
        our learning agility.
      </p>
      <h3 className="mb-medium">Cultivate a culture of learning</h3>
      <p className="mb-medium">
        As a leader, you have the power to cultivate a culture where team members feel safe being vulnerable in the
        learning process. How safe we feel is greatly influenced by our environment, including what we understand as
        team norms—which behaviors get rewarded and which get punished.
      </p>
      <p className="mb-medium">Some of the cultural factors that positively influence learner safety include:</p>
      <ul className="mb-medium ml-xl">
        <li>Seeing others ask for help with positive outcomes</li>
        <li>Having clear goals and purpose</li>
        <li>Working on a supportive team</li>
        <li>Understanding it's okay not to know how to do something</li>
        <li>Having time to learn</li>
      </ul>
      <p className="mb-medium">
        When a team feels safe to learn, they take more risks, which in turn drives more engagement, innovation, and
        results.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Learning is personal</h2>
      <p className="mb-medium">
        In a world where we have so many choices in how we receive and retain information, a great way to become more
        learning-agile is to increase our awareness of our personal learning preferences. At some point in our education
        it's likely we've come across a learning model that helps us identify how we learn. Starting with{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vark-learn.com/introduction-to-vark/the-vark-modalities/"
        >
          Neil Fleming's foundational VARK model
        </a>
        , we may have some knowledge of our preference to learn <b>V</b>isual, <b>A</b>ural,
        <b>R</b>ead/write, or <b>K</b>inesthetic sensory modalities. Fleming's research, supported by more recent
        research, suggests that we seldom operate in just one mode.
      </p>
      <p className="mb-medium">
        What seems to be more important when identifying our personal learning style or preferences is that we can
        identify how we learn and communicate that to others to accelerate our ability to learn. By honoring these
        preferences and sharing them with team members we can create a culture where we feel comfortable learning and
        adopt a growth mindset as a team.
      </p>
    </div>
  </div>
)

export default LearnerAgilityTraining1
