import { useCallback } from "react"

// https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
const alignCenterOptions = { block: "center" }
const useScrollElementIntoViewRef = (scrollIntoViewOptions = alignCenterOptions) =>
  useCallback(
    (element) => {
      element?.scrollIntoView(scrollIntoViewOptions)
    },
    [scrollIntoViewOptions]
  )

export default useScrollElementIntoViewRef
