import { getUserAnswer } from "../results_utils"

import Accordion from "components/Accordion"
import AnswerDisplayContent from "ui/AnswerDisplayContent"

const RichTextFieldMultiUserAccordionList = ({ identifier, hide_empty_answers, exerciseInstances, sortedUsers }) => (
  <div className="mb-xl">
    {sortedUsers.map((user) => {
      const answer = getUserAnswer(exerciseInstances, user, identifier)

      if (hide_empty_answers && !answer) {
        return null
      }

      return (
        <Accordion key={user.id} title={<h3 className="text-semi-bold">{user.short_name}</h3>}>
          <AnswerDisplayContent className="mb-small" answer={answer} />
        </Accordion>
      )
    })}
  </div>
)

export default RichTextFieldMultiUserAccordionList
