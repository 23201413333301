const RT4SPurposeGoalsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Before running the session with your team, here is some additional context about the theme.
      </p>
      <h2 className="text-rising-orange pb-xs">Purpose</h2>
      <ul className="ml-xl mb-medium">
        <li>Purpose is the overarching reason for the existence or action of something.</li>
        <li>It answers the question of why something is done or why it exists.</li>
        <li>It provides direction, motivation, and meaning.</li>
        <li>It tends to be enduring and fundamental.</li>
        <li>
          The purpose is often broad and more abstract, guiding the overall mission or vision of a school, team, or
          individual.
        </li>
      </ul>
      <p className="mb-medium">
        For example, the purpose of the school may be to provide quality education and foster the holistic development
        of students.
      </p>
      <h2 className="text-rising-orange pb-xs">Goals</h2>
      <ul className="ml-xl mb-medium">
        <li>Goals are specific, measurable objectives that help fulfill the purpose.</li>
        <li>They are concrete targets or milestones set to achieve a desired outcome.</li>
        <li>Goals are time-bound and actionable, providing a roadmap for progress.</li>
        <li>They can be short-term or long-term.</li>
        <li>Goals are the actionable steps taken to realise the broader purpose.</li>
      </ul>
      <p className="mb-medium">
        For example, a school might set goals to increase attendance or completion rates, improve literacy, numeracy,
        and well-being targets, enhance student engagement, or implement new teaching approaches. These goals are
        specific, measurable targets that contribute to fulfilling the broader purpose of providing quality education
        and holistic development.
      </p>
    </div>
  </div>
)

export default RT4SPurposeGoalsTraining1
