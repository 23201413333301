import { styled } from "styled-components"

const PsychologicalSafetyTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9">
      So, now you know why psychological safety is important and how you’ll work towards it in this Rising Team session.
      We also want to give you some tips for how to encourage a climate of psychological safety on your team over time.
      You can use these tips during any of the team sessions and also in your day-to-day routine:
    </div>
    <h3 className="pt-large pb-xs">
      <span className="list-number pr-xs">1. Set the stage</span>
    </h3>
    <div className="text-gray-9">
      Amy Edmondson, the Harvard Business School professor best known for her work on psychological safety, uses the
      term “
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.myhrfuture.com/digital-hr-leaders-podcast/2020/7/13/how-to-create-psychological-safety-at-work?format=amp"
      >
        setting the stage
      </a>
      ” to describe that you first need to help people understand the reason why you are asking people to share their
      views. It’s a combination of reminding people of the mission and purpose behind their work so they know why their
      voice matters, and reminding them of the uncertainty and complexity of the work you do, so they understand the
      risks of not speaking up. Emphasizing the complexity and realities of work also lets people know that you
      understand that not everything will be perfect, and that it’s ok and expected to make mistakes.
    </div>
    <h3 className="pt-large pb-xs">
      <span className="list-number pr-xs">2. Be vulnerable—first</span>
    </h3>
    <div className="text-gray-9">
      As we covered when describing the Team Dynamics exercise, if you want your team to be open and vulnerable with
      you, it’s key to show vulnerability first in order to demonstrate for your team that it’s ok to do it. The
      hallmark of a climate of psychological safety is when people are not afraid to speak up, including about their own
      mistakes or concerns they have about direction. If you want people to feel safe talking about those things, you
      need to model it for them, by sharing your own mistakes and concerns too.
    </div>
    <h3 className="pt-large pb-xs">
      <span className="list-number pr-xs">3. Actively invite {"&"} welcome all voices</span>
    </h3>
    <div className="text-gray-9">
      <div>
        Amy Edmondson notes that when aiming to create psychological safety,{" "}
        <span className="text-bold">we should be careful not to mistake silence for agreement</span>. In fact,
        especially when there is silence around something, it is a good time to invite participation, because it may
        mean that people are hesitating to speak up. Here are two tactics that can be helpful in encouraging
        participation:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Ask good questions </span> - Edmondson refers to these as questions that invite
            careful thought and give people room to respond, like “What are we missing?” or “Does anyone have a
            different perspective here?”
          </li>
          <li>
            <span className="text-bold">Invite people to write</span> - Not everyone is comfortable speaking about their
            ideas, especially on the spot and/or in front of others. One exercise that can help here is to have everyone
            start by writing their own thoughts in a shared doc (for example about one of the questions above) and then
            have a discussion about what people wrote. This tactic can be especially helpful for introverts and people
            who are operating in a 2nd language.
          </li>
        </ul>
      </div>
    </div>
    <h3 className="pt-large pb-xs">
      <span className="list-number pr-xs">4. Make it a judgment free zone</span>
    </h3>
    <div className="text-gray-9">
      <div>
        One absolutely essential aspect of building psychological safety for your team is ensuring that when people do
        speak up, they don’t face negative repercussions. The very definition of psychological safety is having
        confidence that you will not be embarrassed, rejected, or punished for speaking up with ideas, questions,
        concerns or mistakes. Two tips to make sure you are starting with a judgment-free approach:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Use reflective listening</span> - Start by just reflecting back to someone what
            they said to make sure they know you heard them and that you understand what they are voicing. You can try,
            “What I heard you say is…” and then reflect back what they said to you.
          </li>
          <li>
            <span className="text-bold">Start from a place of curiosity</span> - If you don’t understand and/or you
            don’t agree with what someone is saying, rather than jump in to disagree, try asking additional questions to
            ensure you fully understand. You can start by saying, “Tell me more about that.”
          </li>
        </ul>
      </div>
    </div>
    <h3 className="pt-large pb-xs">
      <span className="list-number pr-xs">5. Respond productively</span>
    </h3>
    <div className="text-gray-9">
      <div>
        Another last bit of advice from Amy Edmondson is to make sure you respond productively when people do step
        forward so they can see the proactive and positive response to their participation. Two examples of proactive
        actions you can take are:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Take collaborative action</span> - Once you indicate you do want to take action,
            think about ways to involve the team in the solution. Use language like, “How can we solve/improve this
            together?” to encourage their ideas further.
          </li>
          <li>
            <span className="text-bold">Celebrate the failures</span> - One good way to ensure people are comfortable
            talking about mistakes and failures is to celebrate them when they happen, so people know that it is ok to
            fail, especially when it’s around something that the team had good reasons to try. In fact, it’s helpful to
            share failures because that’s how we learn not to repeat mistakes. You can add a “Festival of Failure”
            section to your ongoing meetings or chats as a way to make this part of the norm. (More on failure in
            upcoming session themes.)
          </li>
        </ul>
      </div>
      <div>
        And that’s it! Congrats! You’ve finished your training on team dynamics and psychological safety and you’re
        ready to move on to the last brief step of preparation before your Rising Team session.
      </div>
    </div>
    <h3 className="text-gray-9 pt-large pb-xs">Additional resources</h3>
    <div className="text-gray-9">
      <div>If you want to keep learning more about this topic, here are some additional resources:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2017/08/high-performing-teams-need-psychological-safety-heres-how-to-create-it"
            >
              High Performing Teams Need Psychological Safety. Here’s How to Create It
            </a>{" "}
            (Harvard Business Review)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mckinsey.com/business-functions/organization/our-insights/psychological-safety-and-the-critical-role-of-leadership-development"
            >
              Psychological Safety and the Critical Role of Leadership Development
            </a>{" "}
            (McKinsey)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/learning/psychological-safety-clear-blocks-to-innovation-collaboration-and-risk-taking/psychological-safety-clear-blocks-to-problem-solving-and-innovation"
            >
              Amy Edmondson course on Psychological Safety
            </a>{" "}
            (LinkedIn Learning)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ccl.org/articles/leading-effectively-articles/what-is-psychological-safety-at-work/"
            >
              What is Psychological Safety at Work?
            </a>{" "}
            (Center for Creative Leadership)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2021/04/what-psychological-safety-looks-like-in-a-hybrid-workplace"
            >
              What Psychological Safety Looks Like in a Hybrid Workplace
            </a>{" "}
            (HBR)
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default styled(PsychologicalSafetyTraining3)`
  .list-number {
    color: var(--rising-orange);
  }
`
