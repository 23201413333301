function isLocalhostEnv(): boolean {
  return (
    import.meta.env.VITE_NODE_ENV === "development" ||
    ["localhost", "rtkit.localhost"].includes(window.location.hostname)
  )
}

function isDevelopmentEnv(): boolean {
  return isLocalhostEnv() && window.location.port === "3006"
}

function isCITestEnv(): boolean {
  return isLocalhostEnv() && window.location.port === "8000"
}

function isStagingEnv(): boolean {
  return window.location.hostname === "staging-kit.risingteam.com"
}

function isProductionEnv(): boolean {
  return window.location.hostname === "app.risingteam.com"
}

function isFirefoxBrowserEnv(): boolean {
  const userAgent = navigator.userAgent?.toLowerCase() ?? ""
  return userAgent.includes("firefox") || userAgent.includes("fxios")
}

export { isLocalhostEnv, isDevelopmentEnv, isCITestEnv, isStagingEnv, isProductionEnv, isFirefoxBrowserEnv }
