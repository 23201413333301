const MotivatorsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">To get started, let's talk about what motivation is and why it's important.</p>
      <h2 className="text-rising-orange mb-medium">1. Motivation is what moves us</h2>
      <p className="mb-medium">
        Swipe through any social feed or check out any movie or show and we'll find themes around motivation. How to be
        better, get stronger, and make our lives easier are just a few of the themes we come across in media every day.
        We're a society driven to strive—for better health, greater purpose, professional growth, status, belonging, and
        more.
      </p>
      <p className="mb-medium">
        Motivation speaks to what we value most and explains the reasons behind many of our actions. Which is why
        knowing what motivates us is like getting a secret key to help unlock our goals.
      </p>
      <h3 className="mb-medium">We share core motivators</h3>
      <p className="mb-medium">
        As humans, we all share some core needs. If you're familiar with{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.simplypsychology.org/maslow.html">
          Maslow's hierarchy of needs
        </a>
        , you know that we have to meet our lower needs before we can move up and pay attention to any needs above.
        Outside of the workplace, this means we need things like food and shelter before we can pay attention to things
        like friendships and self esteem. In the workplace, this can mean that we need core things like psychological
        safety, a livable wage, and reasonable hours before we're motivated by things like being part of a great team or
        getting exposure on an exciting project.
      </p>
      <img
        className="mb-medium"
        src="https://static.risingteam.com/kit_assets/motivators/kit-motivators-training-1.1.png"
        alt="maslows-hierarchy-of-needs"
        width="315px"
      />
      <h3 className="mb-medium">Motivation can be intrinsic or extrinsic</h3>
      <p className="mb-medium">
        Intrinsic motivation is when we want to do something simply because it feels good, such as the feeling of pride
        from self-improvement or feeling connected when helping others. As a leader, you may be motivated by the common
        intrinsic factor of how it feels to positively affect the lives of people you manage.
      </p>
      <p className="mb-medium">
        Extrinsic motivation, on the other hand, is based on external rewards like a job title, compensation, or other
        more tangible incentives. For example, you may be motivated to lead a team because it comes with a pay raise or
        respected title.
      </p>
      <p className="mb-medium">
        Most people are motivated by a combination of intrinsic and extrinsic factors, sometimes even on the same goal.
      </p>
      <h2 className="text-rising-orange mb-medium">2. Motivation drives success</h2>
      <p className="mb-medium">
        Now that we're clear on what motivation is, let's explore the role it plays in our ability to achieve our goals.
      </p>
      <h3 className="mb-medium">Motivation inspires mastery</h3>
      <p className="mb-medium">
        According to the popular research of cognitive psychologist Dr. Anders Ericsson, motivation is the most
        significant predictor of success. In his book, <em>Peak</em>, Ericsson and co-author Robert Pool reveal that
        motivation is what keeps us focused and able to deal with challenges when we're reaching for a goal.
      </p>
      <p className="mb-medium">
        Dr. Ericsson's{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=uoUHlZP094Q">
          theory of "deliberate practice"
        </a>{" "}
        says that when we're motivated, we're driven to learn new things and are more open to coaching and feedback.
        This, he found, is the difference between staying a novice or becoming an expert.
      </p>
      <h3 className="mb-medium">Motivation makes us more resilient</h3>
      <p className="mb-medium">
        The world is complex and constantly changing, and motivation is a powerful part of having the self-
        determination and resilience required to adapt and succeed.
      </p>
      <p className="mb-medium">
        Anyone who's ever focused on achieving a stretch goal (like participating in a triathlon, or getting an advanced
        degree) has probably realized that desire alone is not enough to cross the finish line. Achieving big goals also
        involves the ability to overcome obstacles and be resilient in the face of setbacks. When we know what motivates
        us, we can tap into it for an extra boost when things get challenging. This is especially helpful for long-term
        goals that require our sustained interest and effort.{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://positivepsychology.com/what-is-motivation/">
          Positive Psychology says
        </a>
        , "The essence of motivation is energized and persistent goal-directed behavior. When we are motivated, we move
        and take action."
      </p>
      <h2 className="text-rising-orange mb-medium">3. Motivation is important in the workplace</h2>
      <p className="mb-medium">
        Motivation applies to our personal as well as professional lives. Let's wrap up by exploring the role motivation
        plays when leading a team.
      </p>
      <h3 className="mb-medium">Motivation can be harnessed</h3>
      <p className="mb-medium">
        Whether it's for ourselves or our team members, motivation is an underlying force that can be harnessed for
        self-improvement.
      </p>
      <p className="mb-medium">
        When we're aware of each other's core needs, we can use them to help achieve our goals. Whether trying to cross
        the finish line with a long-term project or simply get volunteers for a new initiative, motivation is a strategy
        to keep the team energized and thriving.
      </p>
      <h3 className="mb-medium">Motivation shifts over time</h3>
      <p className="mb-medium">
        What motivates us can change throughout our lives. This is especially true when we experience life changes—like
        a new career or becoming a parent. Knowing that, it's important to regularly revisit and reconsider what
        motivates us as we make both near-term and long-term career decisions.
      </p>
      <p className="mb-xxl">
        We all want to be successful. And no matter how we define that success, the key to achieving it is knowing what
        sparks and increases our motivation.{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://positivepsychology.com/motivation-human-behavior/">
          Positive Psychology concludes
        </a>{" "}
        that "finding ways to increase motivation is crucial because it allows us to change behavior, develop
        competencies, be creative, set goals, grow interests, make plans, develop talents, and boost engagement."
      </p>
    </div>
  </div>
)

export default MotivatorsTraining1
