import { keyBy } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getTeamLead, getUserAnswer } from "domains/Exercise/results_utils"
import FeedbackDeliveringTeamLeadResults from "domains/Exercise/ResultsComponents/FeedbackDeliveringTeamLeadResults"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import BulletedList from "ui/BulletedList"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const FeedbackDeliveringDeliverStep = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: user } = useUser({ userId: "me" })
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
  })

  if (!user || !participants || (!exerciseInstances && isFetching)) {
    return <Loading />
  }

  const participantIdMap = keyBy(participants, "id")
  const teamLead = getTeamLead(participants, team)
  const delivering_feedback_receive = getUserAnswer(exerciseInstances, teamLead, "delivering_feedback_receive")
  const delivering_feedback_receive_user = participantIdMap[delivering_feedback_receive]
  const delivering_feedback_give = getUserAnswer(exerciseInstances, teamLead, "delivering_feedback_give")
  const delivering_feedback_give_user = participantIdMap[delivering_feedback_give]

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Role play!</h2>
      <SidebarContainer>
        <TextContainer maxWidth={660} className="text-gray-9">
          <h3 className="text-gray-9 mb-medium">Your cast</h3>
          <BulletedList className="mb-medium">
            <li>
              {delivering_feedback_give_user ? delivering_feedback_give_user.short_name : "[no one selected]"} is the
              Fairy Godperson
            </li>
            <li>
              {delivering_feedback_receive_user ? delivering_feedback_receive_user.short_name : "[no one selected]"} is
              Cinderella
            </li>
            <li>The rest of the team will observe</li>
          </BulletedList>
        </TextContainer>
        <FacilitatorTip
          className="sidebar-right"
          tip="The Fairy Godperson has different instructions on their screen."
          icon="turn-off-share-screen"
        />
      </SidebarContainer>
      {user.id === delivering_feedback_give_user?.id && (
        <FeedbackDeliveringTeamLeadResults
          kitInstance={kitInstance}
          exerciseInstances={exerciseInstances}
          user={teamLead}
        />
      )}
      {user.id === delivering_feedback_receive_user?.id && (
        <>
          <h3 className="text-gray-9 mb-medium">Cinderella, here is your role:</h3>
          <BulletedList>
            <li>Listen while Fairy Godperson delivers their message. Then thank them for the feedback.</li>
            <li>
              Was anything unclear? Be curious! Try to hold off on agreeing or disagreeing, and just try to understand
              what they have to say.
            </li>
          </BulletedList>
        </>
      )}
    </StepContent>
  )
}

export default FeedbackDeliveringDeliverStep
