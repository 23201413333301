import { Navigate, useParams } from "react-router"

import { useSSOProvider } from "../resource"

import SSOLogin from "./SSOLogin"

import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import { buildUrl, asStringsOrNulls } from "utils/string"

interface ProviderSSOLoginProps {
  ssoRedirectLogin?: boolean
  showLoadingUI?: boolean
}

const ProviderSSOLogin = ({ ssoRedirectLogin = false, showLoadingUI = false }: ProviderSSOLoginProps) => {
  const { provider } = useParams()
  const { next = null } = asStringsOrNulls(useQueryParams())
  const { data: ssoProvider, isFetching } = useSSOProvider(provider ?? "")

  if (!provider) {
    return <Navigate to="/" replace />
  }

  if (!ssoProvider && isFetching) {
    return <Loading />
  }

  if (ssoRedirectLogin && !showLoadingUI) {
    const redirectLoadingUrl = buildUrl(["auth", "sso", provider, "redirect-loading"], {
      urlQueryParams: { next },
    })
    return <Navigate to={redirectLoadingUrl} replace state={{ showLoginUI: true }} />
  }

  const providerName = ssoProvider?.sso_provider?.providerName ?? null

  if (!providerName) {
    return <Navigate to="/" replace />
  }

  return <SSOLogin provider={provider} providerName={providerName} ssoRedirectLogin={ssoRedirectLogin} />
}

export default ProviderSSOLogin
