import { useState } from "react"

// drag and drop tutorial https://www.smashingmagazine.com/2020/02/html-drag-drop-api-react/
const DragAndDrop = ({
  onDropHandler = () => {},
  onDragEnterHandler = () => {},
  onDragOverHandler = () => {},
  onDragLeaveHandler = () => {},
  children,
}) => {
  const [dragEnterCount, setDragEnterCount] = useState(0)

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragEnterCount((i) => {
      i++
      return i
    })
    onDragEnterHandler(e)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragEnterCount((i) => {
      i--
      return i
    })
    if (dragEnterCount - 1 <= 0) {
      onDragLeaveHandler(e)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDragOverHandler(e)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragEnterCount(0)
    if (e.dataTransfer.files.length) {
      !!onDropHandler && onDropHandler(e.dataTransfer.files)
    } else {
      !!onDropHandler && onDropHandler(e)
    }
  }

  return (
    <div onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
      {children}
    </div>
  )
}
export default DragAndDrop
