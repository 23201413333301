import { Link, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { getStandaloneExerciseUrl } from "resources/monthly_kit"

const PsychologicalSafetyTraining2 = ({ kitInstance, className }) => {
  const { pathname } = useLocation()
  const exerciseUrl = getStandaloneExerciseUrl({
    slug: kitInstance.slug,
    teamId: kitInstance.team_id,
    onFinishUrl: pathname,
  })
  return (
    <div className={className}>
      <div className="text-gray-9">
        Now that we understand the critical importance of creating environments of psychological safety for our teams,
        the question is, how do we do that? The first thing to remember is that this happens in stages, not overnight.
        This training and Rising Team session will help get you started.
      </div>
      <div className="text-small text-gray-9 pt-medium">The User Manual exercise:</div>
      <div className="pt-small pb-large">
        <img
          src="https://static.risingteam.com/kit_assets/psychological-safety/kit-training-building-trust.png"
          alt="kit-training-building-trust"
        />
      </div>
      <h3 className="text-gray-9 pt-medium pb-xs">Build trust with small steps</h3>
      <div className="text-gray-9">
        One of the most important precursors to psychological safety is creating trust within a team, and it’s easiest
        to do that by starting with small steps. In this team session, we’ll do a Team Dynamics exercise where everyone
        creates a “User Manual” about their own working style preferences. This exercise will help you learn how to work
        better together, and also serve as a stepping stone to building deeper trust.
      </div>
      <h3 className="text-gray-9 pt-large pb-xs">Start with people’s basic preferences</h3>
      <div className="text-gray-9">
        We start with lightweight questions, like what times of day people like to work and when it’s ok to interrupt
        them. Sharing these basic preferences as a team, and{" "}
        <span className="text-bold">
          learning from you—their leader—that any version of these preferences is acceptable within your team, is a good
          way to build up trust.
        </span>{" "}
        It’s important when discussing the full team results, that you welcome everyone’s unique working styles and
        share your own as well. These working styles questions will also help you work better together by understanding
        each person’s unique preferences.{" "}
        <span className="text-bold">
          Knowing that you want to understand and recognize their working style choices will make each person feel more
          safe and valued on your team.
        </span>
      </div>
      <h3 className="text-gray-9 pt-large pb-xs">Then go deeper</h3>
      <div className="text-gray-9">
        After these lighter weight questions, the exercise moves on to some deeper, more thoughtful questions to
        increase trust further, like “What are some things you struggle with at work?” and “How can other people on your
        team help you be your best?” These questions are harder for people to answer and it’s important that you model
        the behavior you’d like to see from the team. If you want people to be open with you and feel safe to share
        ideas, feedback and mistakes with the team, then it’s key that you demonstrate that openness with them as well.{" "}
        <span className="text-bold">
          So in this section we ask you to go first, sharing an example of something you personally struggle with at
          work.
        </span>{" "}
        Seeing you be vulnerable and open first will encourage your team to answer the questions more openly too.
      </div>
      <h3 className="text-gray-9 pt-large pb-xs">Ultimately creating a climate of psychology safety</h3>
      <div className="text-gray-9">
        <div>
          <span className="text-bold">
            Walking through these questions together as a team will help strengthen the muscle that’s needed to create
            climates of psychological safety—risk-taking without fear of repercussions.
          </span>
          When your team sees you sharing your honest answers to these deeper questions, and also sees that nothing bad
          will happen when they share honest answers too, it’s a great demonstration of creating a safe space. Make sure
          you listen closely and have a supportive response when your team members are walking through their answers to
          these questions in the Rising Team session.
        </div>
        {!kitInstance.session_completed_at && (
          <div className="pt-xs">
            In the next step of your prep, you’ll be able to see a{" "}
            <Link to={`${kitInstance.prep_url}video-walkthrough`}>quick video</Link> of how the team session will run,
            and test out the <Link to={exerciseUrl}>Team Dynamics {"&"} User Manual exercise</Link> for yourself if you
            want to try it in advance.
          </div>
        )}
      </div>
    </div>
  )
}

export default styled(PsychologicalSafetyTraining2)`
  img {
    max-width: 100%;
    height: auto;
  }
`
