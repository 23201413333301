import cn from "classnames"
import DOMPurify from "dompurify"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import getIconOrError from "icons"
import View from "ui/View"

const SelectFieldReadOnlyField = ({
  identifier,
  exerciseComponent,
  exerciseInstance,
  className,
  show_answer_with_icon = false,
}) => {
  const userAnswer = getExerciseAnswer(exerciseInstance, identifier)
  const choiceOptions = exerciseComponent.options
  const choiceAnswer = choiceOptions.find(({ value }) => value === userAnswer)
  const answer = choiceAnswer?.label
  const Icon = show_answer_with_icon && answer && getIconOrError(choiceAnswer?.icon)
  const color = Icon && choiceAnswer?.iconClassName

  return (
    <View
      className={cn(
        "bg-gray-1 p-small border border-gray-4 border-radius mb-xl mt-xs",
        { "text-gray-8": !!show_answer_with_icon },
        className
      )}
      $alignItems="center"
      $width="fit-content"
    >
      {!!Icon ? <Icon className={cn("mr-small", color)} /> : null}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />
    </View>
  )
}

export default styled(SelectFieldReadOnlyField)`
  min-width: 200px;
  min-height: 48px;
`
