import cn from "classnames"
import { useParams } from "react-router"

import { PromoKitSlug } from "utils/kit"

export default function KitUnavailable({ className }) {
  const { slug } = useParams()
  const isGratitudeKit = slug === PromoKitSlug.GRATITUDE
  return (
    <div className={cn("main-container", className)}>
      <h2 className="mb-large">
        Sorry, {isGratitudeKit ? "the Gratitude kit" : "that kit"} isn't available for your account yet.
      </h2>
      {isGratitudeKit ? (
        <p className="mt-large">
          We've been notified and will be helping your Rising Team account administrator add the Gratitude kit to your
          account. We'll send you an email as soon as it's ready. Thank you for your patience!
        </p>
      ) : (
        <p>
          Please contact your Rising Team account administrator and ask them to add the kit to your account, or{" "}
          <a target="_blank" rel="noopener noreferrer" href="mailto:hello@risingteam.com">
            contact us
          </a>{" "}
          and we'll be in touch with you soon.
        </p>
      )}
    </div>
  )
}
