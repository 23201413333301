import { styled } from "styled-components"

import TeamPhotoModalContent from "domains/LeaderKit/TeamPhotoModalContent"
import { useModal } from "ui/ModalContext"

const SessionSummaryPhoto = ({ kitInstance, selectedKit, className }) => {
  const { setModal } = useModal()

  const sessionPictureUrl = kitInstance?.session_picture_url
  if (!sessionPictureUrl) {
    return null
  }

  const openModal = () => {
    setModal({
      content: <TeamPhotoModalContent kitInfo={selectedKit} sessionPictureUrl={sessionPictureUrl} />,
      size: "medium",
      showFrame: false,
    })
  }

  return (
    <div className={className}>
      <h3 className="mb-medium mr-medium text-gray-9">Team Photo</h3>
      <img src={sessionPictureUrl} alt="Team" className="team-photo-img mr-large mb-small" onClick={openModal} />
    </div>
  )
}

export default styled(SessionSummaryPhoto)`
  .team-photo-img {
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
    cursor: pointer;
  }
`
