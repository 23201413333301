import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { Form, Formik } from "formik"
import { useState } from "react"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import TeamLeadField from "domains/Admin/TeamLeadField"
import { canUserManageAccount } from "domains/Admin/utils"
import TextField from "forms/fields/TextField"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { useCreateAccountTeam } from "resources/billing"
import Button from "ui/Button"
import useFeatures from "ui/hooks/useFeatures"
import Loading from "ui/Loading"

const AddTeamForm = styled(function AddTeamForm({ className, account, user, onSubmit = null }) {
  const features = useFeatures()
  const { mutateAsync: createTeam } = useCreateAccountTeam(account.id)
  const [addTeamLoading, setAddTeamLoading] = useState(false)
  const [addTeamError, setAddTeamError] = useState(false)
  const canSeeAllAccountLeads = canUserManageAccount(account, user, features)
  const [emailAccordionOpen, setEmailAccordionOpen] = useState(canSeeAllAccountLeads)
  const canUserAddAccountTeams = account?.can_user_add_account_teams

  if (!canUserAddAccountTeams) {
    return null
  }

  const onFormSubmit = handleErrors(
    async (values) => {
      setAddTeamError(false)
      setAddTeamLoading(true)
      const { team_name, team_lead_email, team_lead_id, shrm_24 = false } = values
      await createTeam({
        team_name,
        ...(emailAccordionOpen ? { team_lead_email } : { team_lead_id }),
        // don't send both team_lead_id and team_lead_email at once
        ...(shrm_24 ? { shrm_24 } : {}),
      })
      onSubmit?.()
    },
    (data, _values, formik, err) => {
      setAddTeamLoading(false)
      const format = (errMsg) => (Array.isArray(errMsg) ? errMsg.join(", ") : errMsg)
      if (data.team_name) {
        formik.setErrors({ team_name: format(data.team_name) })
      } else if (data.team_lead_id) {
        formik.setErrors({ team_lead_id: format(data.team_lead_id) })
      } else if (data.team_lead_email) {
        formik.setErrors({ team_lead_email: format(data.team_lead_email) })
      } else {
        setAddTeamError(true)
      }
      Sentry.captureException(err)
      return null
    }
  )
  const initialValues = {
    team_name: "",
    team_lead_id: canSeeAllAccountLeads ? null : user.id,
    team_lead_email: "",
  }
  const leadInvalidMessage = "Please specify a team lead."
  const schema = Yup.object().shape(
    {
      team_name: Yup.string().label("Team name"),
      team_lead_id: Yup.string()
        .nullable()
        .label("Team lead")
        .when("team_lead_email", {
          is: (team_lead_email) => !team_lead_email?.trim().length,
          then: (schema) => schema.required(leadInvalidMessage),
        }),
      team_lead_email: Yup.string()
        .label("Team lead email")
        .when("team_lead_id", {
          is: (team_lead_id) => !team_lead_id?.trim().length,
          then: (schema) => schema.required(leadInvalidMessage),
        }),
    },
    ["team_lead_id", "team_lead_email"]
  )
  return (
    <div className={cn("add-team-form", className, { disabled: addTeamLoading })}>
      {!!addTeamLoading && <Loading className="add-team-form-loading" />}
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onFormSubmit}
      >
        <Form>
          <div className="text-semi-bold mb-xs">Team leads</div>
          <p className="text-normal mb-large">
            To set up a new team, either assign the team to an existing lead or invite a new lead. Each person will
            receive an invitation to set up their account and schedule their first session with their team.
          </p>
          <TeamLeadField
            className="mb-medium"
            user={user}
            account={account}
            initialEmailAccordionOpen={canSeeAllAccountLeads}
            onEmailAccordionOpen={() => setEmailAccordionOpen(true)}
            onIdAccordionOpen={() => setEmailAccordionOpen(false)}
          />
          <div className="text-semi-bold mb-xs">Team name (optional)</div>
          <TextField
            className="mr-large"
            name="team_name"
            size="medium"
            autoComplete="off"
            placeholder="What is the team's name?"
          />
          {!!addTeamError && (
            <div className="team-form-error mt-large">
              <div className="text-danger text-semi-bold">Sorry, there was an error creating your team.</div>
              Please try again in a few minutes, or,{" "}
              <Link to="/contact" className="text-semi-bold">
                Contact Us
              </Link>{" "}
              for support.
            </div>
          )}
          <Button className="inline-block mt-large" type="submit">
            Add team
          </Button>
        </Form>
      </Formik>
    </div>
  )
})`
  opacity: 1;
  transition: opacity 0.2s ease;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .add-team-form-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default AddTeamForm
