import getIntroComponentOrError from "domains/Exercise/IntroComponents"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import TextContainer from "ui/TextContainer"

const ActivityIntroStep = ({ sessionStep }) => {
  const { intro_component, facilitator_tip, facilitator_tip_icon } = sessionStep

  const IntroComponent = intro_component && getIntroComponentOrError(intro_component)

  return (
    <StepContent>
      <SidebarContainer>
        <TextContainer className="text-gray-9">{!!IntroComponent && <IntroComponent />}</TextContainer>
        {!!facilitator_tip && (
          <FacilitatorTip className="sidebar-right" tip={facilitator_tip} icon={facilitator_tip_icon} />
        )}
      </SidebarContainer>
    </StepContent>
  )
}

export default ActivityIntroStep
