const FeedbackTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        Imagine a team where giving and receiving feedback was easy and comfortable, and happened on a regular basis.
        What would that make possible? What could you achieve together?
      </p>
      <p className="mb-large">
        In this Kit, you’ll discover the benefits of creating a feedback culture, and learn how to give and take
        feedback in ways that build trust and accelerate growth.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Why feedback culture is essential to team success</h2>
      <p className="mb-medium">
        To get started, let’s explore what a feedback culture is and why it’s so important to your team’s success.
      </p>
      <h3 className="mb-medium">Feedback can become a team norm</h3>
      <p className="mb-medium">
        In the context of work, feedback means telling people when you think they’re doing well and also when you see
        opportunities for improvement. When teams do this regularly, they create a culture of feedback that promotes
        honest dialogue, where everyone helps each other—and the team—grow.
      </p>
      <h3 className="mb-medium">Feedback fuels growth</h3>
      <p className="mb-medium">
        The right kind of feedback at the right time helps teams reduce errors, learn, and improve their communication.
        This can be especially helpful as teams continue to work in new and different ways. A{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/the-social-economy"
        >
          study from McKinsey
        </a>{" "}
        found that clear, transparent communication between team members can boost productivity by as much as 25%.
      </p>
      <h3 className="mb-medium">Feedback saves leaders time</h3>
      <p className="mb-medium">
        Team leaders also directly benefit from building a feedback culture.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2016/10/the-performance-management-revolution"
        >
          Harvard Business Review (HBR) cites
        </a>{" "}
        a study by the advisory service CEB, where the average manager reported spending about 210 hours—close to five
        weeks—doing performance appraisals each year. Much of that time is spent documenting and delivering feedback
        that spans months and potentially includes feedback the team member has never heard before. This isn’t just an
        inefficient way to spend time, it can also lead to frustrated employees who feel blindsided by what they’re
        hearing.
      </p>
      <p className="mb-medium">
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2012/01/creating-sustainable-performance">
          Research from HBR
        </a>{" "}
        shows that one of the best ways to help employees thrive is to give them frequent feedback and guidance. It’s
        one of the primary tools leaders have to increase a sense of learning and engagement. When teams are versed in
        giving and receiving feedback on a regular basis, they’re able to double down on productive behavior that is
        working well, or course correct sooner when things aren’t working.
      </p>
      <p className="mb-medium">
        For teams to experience these kinds of benefits, they need to learn and practice skills to deliver and receive
        feedback on a regular basis.
      </p>
      <h2 className="text-rising-orange mb-medium">2. Both positive and constructive feedback are important</h2>
      <h3 className="mb-medium">Positive feedback is critical to success</h3>
      <p className="mb-medium">
        When we think about giving and receiving feedback, we usually think of it being constructive—meant to point out
        opportunities for improvement. The truth is that positive feedback, meant to point out what’s going well, is
        even more important to a person’s growth and success. In fact, a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gallup.com/workplace/236570/employees-lot-managers.aspx"
        >
          Gallup study
        </a>{" "}
        found that 67% of employees who received positive feedback from their managers were “highly involved” in their
        work. Whereas, just 31% of employees who received feedback primarily focused on their weaknesses felt the same
        level of engagement. So not only does it feel good to receive (and give!) positive feedback, it’s important to
        do it regularly. Most of us are naturally more comfortable giving praise, but remember—without the same level of
        specificity and frequency as we might give for constructive feedback, our efforts can have limited impact.
      </p>
      <h3 className="mb-medium">Constructive feedback, given well, helps people thrive</h3>
      <p className="mb-medium">
        Because more critical feedback can trigger emotions, it’s often something we shy away from. Leadership experts{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/search?term=jack%20zenger">
          Jack Zenger
        </a>{" "}
        and{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/search?term=joseph%20folkman">
          Joseph Folkman cite a study in HBR
        </a>{" "}
        where they found that team members want to hear constructive feedback, but most of us are not comfortable giving
        it. 92% of the respondents in their global study agreed that “negative (redirecting)” feedback, if delivered
        appropriately, would be most helpful to their career.
      </p>
      <p className="mb-medium">
        They go on to report that, despite our desire to hear what we can improve, most of us are far more comfortable
        giving positive feedback than we are giving negative, or constructive, feedback.
      </p>
      <p className="mb-medium">
        The truth is, both positive and constructive feedback are both necessary for people to excel. A good approach is
        to aim for frequent positive feedback and occasional constructive feedback. And whichever you’re giving,
        remember that the key is delivering it in a way that builds trust and focuses on impact.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Practice increases confidence and competence</h2>
      <p className="mb-medium">
        Giving and receiving feedback well are skills that can be learned. And like all skills, it takes practice to do
        them well. As leaders, we have an important role in creating strong and healthy feedback skills in our team.
      </p>
      <h3 className="mb-medium">Focus on compassion and clarity</h3>
      <p className="mb-medium">
        Trust is at the core of building a culture where team members feel safe in both giving and receiving feedback,
        especially when it comes to changing behavior. (Rising Team’s Psychological Safety kit is a great tool to set
        the team up for success). The best feedback is delivered using a combination of clarity and compassion—being
        clear about the specific actions you are giving feedback about and showing care and understanding about where
        the person is coming from.
      </p>
      <h3 className="mb-medium">Make it focused on your own experience</h3>
      <p className="mb-medium">
        “The first problem with feedback is that humans are unreliable raters of other humans” say Marcus Buckingham and
        Ashley Goodall in their{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2019/03/the-feedback-fallacy">
          HBR article titled “The Feedback Fallacy”
        </a>
        . They go on to describe what they call the ‘idiosyncratic rater effect,’ where our evaluations of others are
        distorted by our own unconscious bias of what good looks like for any given behavior or action. When we decide
        to give feedback to a team member, it’s important to first check in with ourselves and separate facts from
        interpretation. To help us successfully prepare and deliver feedback through this lens, it helps to have a go-to
        framework.
      </p>
      <h3 className="mb-medium">A framework can help</h3>
      <p className="mb-medium">
        There are various frameworks available to both give and receive effective feedback. Rising Team’s model, which
        you can preview in the next training article, provides an effective approach with elements like compassion,
        empathy, and curiosity at its core.
      </p>
      <p className="mb-medium">
        Because feedback can trigger emotions, feedback culture doesn’t get created overnight. The idea of providing
        regular feedback to both colleagues and leaders can take some time to get used to. With some structure and
        practice though, teams can learn from each other and create new norms based on mutual trust that fuel growth and
        productivity.
      </p>
    </div>
  </div>
)

export default FeedbackTraining1
