const CareerHorizonsThinkingBigIntro = () => (
  <>
    <h2 className="mb-medium">Intro</h2>
    <p className="mb-medium">During this activity we’ll explore potential longer-term professional aspirations.</p>
    <p className="mb-medium">Can you think of any career paths you might want to explore in the future?</p>
    <p className="mb-medium">
      If you followed your passions, explored your interests, and utilized your talents, can you image potential
      pathways that might be interesting? What’s on your career ‘bucket list’?
    </p>
    <p className="mb-xxxxl">When you’re ready, go to the next step to enter 2 or 3 potential pathways to explore.</p>
  </>
)

export default CareerHorizonsThinkingBigIntro
