import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import Button from "./Button"
import View from "./View"

import { ArrowRightIcon, ArrowLeftIcon } from "icons/FontAwesomeIcons"

const Slideshow = styled(function Slideshow({ title, slides, nextText, className }) {
  const [slideIdx, setSlideIdx] = useState(0)
  const length = slides?.length
  const nextSlide = () => {
    setSlideIdx(slideIdx === length - 1 ? slideIdx : slideIdx + 1)
  }
  const backSlide = () => {
    setSlideIdx(slideIdx === 0 ? slideIdx : slideIdx - 1)
  }

  return (
    <div className={className}>
      <View $justifyContent="space-between" className="mb-small mb-xs-mobile-only">
        <div className="slideshow-title text-semi-bold text-gray-8">{!!title && title}</div>
        <span className="count-slide text-semi-bold text-gray-7 hide-on-mobile">
          {slideIdx + 1} of {length}
        </span>
      </View>
      {slides[slideIdx]}
      <View $justifyContent="flex-end">
        {slideIdx !== length - 1 ? (
          <View $justifyContent="space-between" className="next-text">
            <Button
              className={cn("link text-button text-medium-mobile-never", { hidden: slideIdx === 0 })}
              onClick={() => backSlide(slideIdx)}
            >
              <ArrowLeftIcon />
              <div className="text-semi-bold">Back</div>
            </Button>
            <Button className="link text-button text-medium-mobile-never" onClick={() => nextSlide(slideIdx)}>
              <div className="text-semi-bold">{nextText}</div>
              <ArrowRightIcon />
            </Button>
          </View>
        ) : (
          <p className="end-text text-semi-bold text-gray-7 py-xxs text-medium-mobile-never">The end</p>
        )}
      </View>
    </div>
  )
})`
  .slide-text {
    line-height: var(--spacing-7);
  }
`

export default Slideshow
