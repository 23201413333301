import blockComponents from "components/Blocks/blockComponents"
import renderBlocks from "components/Blocks/renderBlocks"
import { blockIcons } from "icons"
import blockUIComponents from "ui/blockUIComponents"
import DefaultTimer from "ui/DefaultTimer"

const ExerciseBlocks = ({ blocks }) => {
  const components = { ...blockComponents, ...blockUIComponents, ...blockIcons, DefaultTimer }
  return <>{renderBlocks(blocks, components)}</>
}

export default ExerciseBlocks
