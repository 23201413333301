import "intro.js/introjs.css"
import type IntroJS from "intro.js"
import { Steps, Hints, type StepsProps, type HintsProps } from "intro.js-react"
import { useState, useRef, useCallback, type Ref, type LegacyRef } from "react"
import { useLocation } from "react-router-dom"
import { createGlobalStyle } from "styled-components"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import theme from "ui/theme"
import { scrollToTop } from "utils/browser"

interface TourStep {
  element: string
  title?: string
  intro?: string
  scrollToTop?: boolean
  hideButtons?: boolean
  disableStepper?: boolean
  disableInteraction?: boolean
  mobileFullWidth?: boolean
  hints?: Array<string | Omit<HintsProps["hints"][0], "hint">>
  position?:
    | "floating"
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-right-aligned"
    | "top-left-aligned"
    | "top-middle-aligned"
    | "bottom-right-aligned"
    | "bottom-left-aligned"
    | "bottom-middle-aligned"
}

interface TourProps {
  steps: TourStep[]
  hash?: string
  introJsRef?: null | Ref<typeof IntroJS | null>
  autoStartTour?: boolean
  onTourStart?: null | (() => any)
  onStepChange?: null | ((stepIndex: number) => any)
  onTourExit?: null | (() => any)
}

enum TourCSSClasses {
  MobileFullWidth = "introjs-tooltip-mobile-full-width",
}

enum TourCSSVars {
  StepButtonsDisplay = "--introjs-step-buttons-display",
  StepBulletsPointerEvents = "--introjs-step-bullets-pointer-events",
}

const DEFAULT_TOUR_HASH = "tour"

const Tour = ({
  steps,
  hash = DEFAULT_TOUR_HASH,
  introJsRef = null,
  autoStartTour = false,
  onTourStart = null,
  onStepChange = null,
  onTourExit = null,
}: TourProps) => {
  const { hash: urlHash } = useLocation()
  const [tourStarted, setTourStarted] = useState(false)
  const [tourExited, setTourExited] = useState(false)
  const tourEnabled = !tourExited && (autoStartTour || !!urlHash?.slice(1).toLowerCase().startsWith(hash))
  const stepsRef = useRef<Steps>()
  const [stepHints, setStepHints] = useState<HintsProps["hints"]>([])

  useEffectAfterChange(() => setTourExited(false), [urlHash])
  // Updating tourExited to false every time the URL hash changes allows the tour
  // to be restarted manually afterward using a button on the calling page.
  // This works because calling pages will always activate the tour by using
  // `navigate` to add #tour to the URL hash.

  const onTourStartCallback = useCallback(() => onTourStart?.(), [onTourStart])
  const onTourExitCallback = useCallback(() => onTourExit?.(), [onTourExit])
  const prepareStep = useCallback(
    async (stepIndex: number) => {
      const step = steps.at(stepIndex)

      // Update URL hash to match step index:
      window.history.replaceState(null, "", `#${hash}-${stepIndex + 1}/${steps.length}`)
      // Note: We don't want to use react-router navigate for this because it will
      // cause page re-render / UI updates. From this point forward the tour is being
      // managed by IntroJS, not React, so these hash updates need to happen outside
      // of the React update->render cycle.

      if (step?.hideButtons) {
        document.body.style.setProperty(TourCSSVars.StepButtonsDisplay, "none")
      } else {
        document.body.style.removeProperty(TourCSSVars.StepButtonsDisplay)
      }

      if (step?.disableStepper) {
        document.body.style.setProperty(TourCSSVars.StepBulletsPointerEvents, "none")
      } else {
        document.body.style.removeProperty(TourCSSVars.StepBulletsPointerEvents)
      }

      if (step?.mobileFullWidth) {
        document.body.classList.add(TourCSSClasses.MobileFullWidth)
      } else {
        document.body.classList.remove(TourCSSClasses.MobileFullWidth)
      }

      onStepChange?.(stepIndex)

      if (step?.scrollToTop) {
        // Must wait for scrollToTop so next step's element will be rendered on page:
        await scrollToTop()
      } else {
        // Single frame wait helps in cases where step element must load/render first:
        await new Promise(requestAnimationFrame)
      }

      ;(stepsRef.current as unknown as Steps)?.updateStepElement(stepIndex)
      // necessary when steps point to elements that don't exist at tour initialization
      // (see https://www.npmjs.com/package/intro.js-react#dynamic-elements)

      // Activate step hints, if any:
      const defaultHintPosition = "middle-middle"
      setStepHints(
        (step?.hints ?? []).map((hint) => ({
          hint: "",
          ...(typeof hint === "object" ? hint : { element: hint }),
          hintPosition: (typeof hint === "object" && hint.hintPosition) || defaultHintPosition,
        }))
      )
    },
    [steps, hash, onStepChange]
  )

  // Change handler to run setup/cleanup at each moment the tour start & ends:
  useEffectAfterChange(() => {
    const isTourStarting = !!tourEnabled && !tourStarted
    const isTourEnding = !tourEnabled && !!tourStarted

    // Don't actually start the tour until IntroJS instances is available on stepsRef:
    if (isTourStarting && stepsRef.current?.introJs) {
      setTourStarted(true)

      const introJs = stepsRef.current.introJs
      if (introJsRef) {
        // Give callers access to IntroJS instance via introJsRef. This allows more
        // sophisticated integrations between page-specific UI and tour functionality.
        ;(introJsRef as { current: typeof IntroJS | null }).current = introJs
      }

      // Prepare first step:
      prepareStep(0)

      // Prepare subsequent step using IntroJS's "before step change" handler:
      introJs?.onbeforechange(async (_: any, stepIndex: number) => {
        await prepareStep(stepIndex)
        introJs.refresh()
        // need to refresh IntroJS here to ensure tooltips point at elements that may
        // not have been present on page before scroll
      })

      onTourStartCallback()
    }

    if (isTourEnding) {
      setTourStarted(false)

      // Tour finished, clean up any CSS-related styles/classes from document.body:
      document.body.style.removeProperty(TourCSSVars.StepButtonsDisplay)
      document.body.style.removeProperty(TourCSSVars.StepBulletsPointerEvents)
      document.body.classList.remove(TourCSSClasses.MobileFullWidth)

      // Also clean up any step hints that were set earlier:
      setStepHints([])

      // Also clear out introJs ref in case any callers are using it:
      if (introJsRef) {
        ;(introJsRef as { current: typeof IntroJS | null }).current = null
      }

      onTourExitCallback()
    }
  }, [tourEnabled, tourStarted, onTourStartCallback, onTourExitCallback, prepareStep, introJsRef])

  return (
    <>
      <TourGlobalStyle />
      <Steps
        ref={stepsRef as unknown as LegacyRef<Steps>}
        enabled={tourEnabled}
        initialStep={0}
        options={{
          autoPosition: false, // positioning of steps doesn't work without this
          keyboardNavigation: false,
          exitOnOverlayClick: false,
          scrollPadding: -180,
          // This scrollPadding value fixes tooltip alignment on mobile,
          // and doesn't seem to have any negative affects on desktop.
        }}
        onExit={() => {
          if (tourEnabled) {
            setTourExited(true)
            window.history.replaceState(null, "", " ")
            // clear URL hash; must pass string with space as 3rd argument
            // can't use navigate here because it will inadvertantly reset page UI
          }
        }}
        steps={steps as StepsProps["steps"]}
      />
      <Hints enabled={!!stepHints.length} hints={stepHints} />
    </>
  )
}

const TourGlobalStyle = createGlobalStyle`
  /* stylelint-disable-next-line selector-class-pattern */
  .introjs-helperLayer {
    box-shadow: rgb(33 33 33 / 50%) 0 0 0 5000px !important;

    // removes black border from around introjs "highlight" box
  }

  .introjs-hint {
    z-index: var(--z-max);
    pointer-events: none;
  }

  /* stylelint-disable-next-line selector-class-pattern */ // needed for intro-js css
  .introjs-tooltipReferenceLayer {
    .introjs-tooltipbuttons {
      display: var(${TourCSSVars.StepButtonsDisplay}, block);
      border-top: none;
      padding-top: 0;
    }

    .introjs-bullets {
      pointer-events: var(${TourCSSVars.StepBulletsPointerEvents}, initial);
      padding-bottom: var(--spacing-4);
    }

    .introjs-tooltip-title,
    .introjs-tooltiptext,
    .introjs-button {
      font-family: ${theme.normalFontFamily};
      font-size: ${theme.normalFontSize};
      line-height: ${theme.normalLineHeight};
      font-weight: normal;
      text-shadow: none;
    }

    .introjs-tooltip-title {
      font-size: 18px;
      line-height: 2.75rem;
      font-weight: 600;
      white-space: nowrap;
    }

    .introjs-tooltiptext {
      padding-top: var(--spacing-0);
      padding-bottom: var(--spacing-0);
    }

    .introjs-skipbutton {
      font-size: 1.5rem;
      padding-top: 6px;
      padding-right: 16px;
    }

    .introjs-button {
      font-weight: 600;
      line-height: 22px;
      border: 1px solid var(--rising-orange);
      border-radius: 8px;
      box-shadow: none;
      box-shadow: var(--blur-4);

      &:hover,
      &:focus-visible {
        box-shadow: var(--lift-4);
      }
    }

    .introjs-prevbutton {
      color: var(--rising-orange);
      background: var(--white);
    }

    .introjs-nextbutton {
      color: var(--white);
      background: var(--rising-orange);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    /* stylelint-disable-next-line selector-class-pattern */ // following is already kebab-case
    body.${TourCSSClasses.MobileFullWidth} {
      /* stylelint-disable-next-line selector-class-pattern */ // needed for intro-js css
      .introjs-tooltipReferenceLayer .introjs-tooltip {
        width: calc(100vw - (var(--spacing-4) * 2));
        margin-left: 5px;
        max-width: none;
        min-width: none;
      }
    }
  }

  // Since tour "hint" dot will be less noticable when user's prefers-reduced-motion
  // setting causes animation to be disabled, give it styles to make it noticable:
  @media (prefers-reduced-motion) {
    .introjs-hint-pulse {
      background-color: var(--white);
      box-shadow: var(--lift-4);
    }
  }
`

export default Tour
export { TourCSSVars, DEFAULT_TOUR_HASH }
export type { TourStep, TourProps }
