import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { styled } from "styled-components"

import ChoicesField from "./ChoicesField"

import FieldMessage from "forms/FieldMessage"
import getIconOrError from "icons"
import useWindowSize from "ui/hooks/useWindowSize"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const IconRatingField = styled(function IconRatingField({
  name,
  label,
  className,
  disabled,
  tooltip,
  labelClassNames,
  buttonIcons,
  largeButtonIcons,
  individualChoiceClassName,
  options: myOptions,
  useTooltip = true,
  showLabels = true,
  ...props
}) {
  const { isMobileOrSmaller } = useWindowSize()
  const options = myOptions.map((option, idx) => ({
    ...option,
    value: option.value || option.value === 0 ? option.value.toString() : (idx + 1).toString(),
    icon_label: option.icon_label || option.label,
  }))
  const displayOptions = showLabels && isMobileOrSmaller ? [...options].reverse() : options

  if (options.some((option) => !option.icon_label)) {
    const errorMessage = `IconRatingField.js: Must specify label or icon_label in options in order to be accessible. Field ${name}, ${label}`
    console.warn(errorMessage)
    Sentry.captureMessage(errorMessage)
  }
  const WrapperComponent = !!tooltip ? Tooltip : View

  const hasWideSpacing = buttonIcons && !largeButtonIcons
  const hasNoSpacing = showLabels && isMobileOrSmaller
  const spacing = hasNoSpacing ? null : hasWideSpacing ? "var(--spacing-3)" : "var(--spacing-1)"

  return (
    <div className={className}>
      <View $justifyContent="space-between" $alignItems="center">
        {!!useTooltip && (
          <WrapperComponent
            {...(!!tooltip ? { top: true, float: true, title: tooltip, disabled: !(label && tooltip) } : {})}
          >
            <div className={labelClassNames || "text-gray-9 text-bold"}>{label}</div>
          </WrapperComponent>
        )}
        <ChoicesField
          name={name}
          type="radio"
          options={displayOptions}
          component={showLabels ? IconRadioInputWithLabels : IconRadioInputWithoutLabels}
          disabled={disabled}
          className={cn("icon-rating", { "icon-rating-input": !!showLabels })}
          style={spacing ? { gap: spacing } : {}}
          buttonIcons={buttonIcons}
          largeButtonIcons={largeButtonIcons}
          individualChoiceClassName={individualChoiceClassName}
          {...props}
        />
      </View>
      <FieldMessage name={name} data-cy={`field-message-${name}`} />
    </div>
  )
})`
  .tooltip {
    width: fit-content;
  }

  .icon-rating {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .icon-rating-input {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      flex-direction: column;
      gap: var(--spacing-1);
      align-items: flex-start;
      width: 100%;
    }
  }

  ${({ buttonIcons, largeButtonIcons }) =>
    (buttonIcons || largeButtonIcons) &&
    `padding: ${largeButtonIcons ? "0 0 0 var(--spacing-1)" : "10px"};

    .icon-rating-input {
      height: ${largeButtonIcons ? "45px" : "30px"};
    }`}
`

const IconRadioInputWithLabels = styled(function IconRadioInputWithLabels({
  name,
  value,
  tooltip,
  type,
  className,
  onBlur,
  checked,
  onChange,
  disabled,
  icon,
  icon_label,
}) {
  const { isMobileOrSmaller } = useWindowSize()
  const Icon = getIconOrError(icon)
  const WrapperComponent = !!tooltip ? Tooltip : View
  return (
    <label className={cn("full-width-mobile-only", className, { "cursor-default": disabled })}>
      <WrapperComponent
        {...(!!tooltip ? { top: true, float: true, title: tooltip, disabled: !tooltip } : {})}
        className="inline-block"
      >
        <input
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          checked={checked}
          disabled={disabled}
        />
        <View
          className={cn("icon-input-container", { "icon-input-container-checked": checked })}
          $alignItems="center"
          $flexDirection={isMobileOrSmaller ? "row" : "column"}
          role="radio"
          aria-checked={checked}
          aria-disabled={disabled}
        >
          <Icon
            className={cn("rating-icon text-gray-6 fa-2x", {
              "rating-icon-checked": checked,
            })}
          />
          {!!icon_label && (
            <p className={cn("icon-label text-center", { "icon-label-checked": checked })}>{icon_label}</p>
          )}
        </View>
      </WrapperComponent>
    </label>
  )
})`
  & input {
    display: none;
  }

  .icon-input-container {
    justify-content: center;
  }

  .icon-rating-input {
    display: flex;
    flex-direction: row;
  }

  .rating-icon {
    color: var(--gray-6);
  }
  .rating-icon-checked {
    color: ${({ color }) => color || "var(--gray-9)"};
  }

  .icon-label {
    padding: var(--spacing-1) var(--spacing-2);
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .icon-input-container {
      padding: var(--spacing-2) var(--spacing-4);
      width: 100%;
      box-shadow: var(--blur-1);
      border-radius: 8px;
      justify-content: left;

      // TODO (shrm-resilience): on click, styling looks funny (squared edges, defaul background color)
      // TODO: these lines attempt to fix but does not work. We think it's a browser-based issue.
      &:hover > div,
      &:focus-visible > div {
        background-color: ${({ background_color }) => background_color};
        box-shadow: var(--blur-1);
      }
    }

    .icon-input-container-checked {
      background-color: ${({ background_color }) => background_color};
      border-radius: 8px;
    }

    .icon-label {
      color: var(--gray-9);
      font-size: 0.8125rem;
      line-height: 1.1875rem;
      text-align: center;
    }

    .icon-label-checked {
      font-weight: 500;
    }
  }

  ${({ buttonIcons, largeButtonIcons }) =>
    (buttonIcons || largeButtonIcons) &&
    `.icon-rating-input {
      height: ${largeButtonIcons ? "45px" : "30px"};
    }

    .icon-input-container {
      box-shadow: var(--blur-4);
      &:hover {
        box-shadow: var(--lift-6);
      }
      height: ${largeButtonIcons ? "45px" : "30px"};
      width: ${largeButtonIcons ? "45px" : "30px"};
      padding: ${largeButtonIcons ? "8px" : "5px"};

    }`}
`

const IconRadioInputWithoutLabels = styled(function IconRadioInputWithoutLabels({
  name,
  value,
  tooltip,
  type,
  className,
  onBlur,
  checked,
  onChange,
  disabled,
  icon,
  icon_label,
}) {
  const Icon = getIconOrError(icon)
  const WrapperComponent = !!tooltip ? Tooltip : View
  return (
    <label className={cn(className, "text-utility icon-label-input", { "cursor-default": disabled })}>
      <WrapperComponent
        {...(!!tooltip ? { top: true, float: true, title: tooltip, disabled: !tooltip } : {})}
        className="icon-input-container inline-block"
      >
        <input
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          checked={checked}
          disabled={disabled}
        />
        <View
          $alignItems="center"
          $justifyContent="center"
          $flexDirection="column"
          role="radio"
          aria-checked={checked}
          aria-disabled={disabled}
        >
          <Icon
            aria-label={icon_label}
            aria-hidden={false}
            className={cn("rating-icon text-gray-6 fa-2x", {
              "rating-icon-checked": checked,
            })}
          />
        </View>
      </WrapperComponent>
    </label>
  )
})`
  &.icon-label-input {
    display: flex;
    align-items: center;
  }

  & input {
    display: none;
  }

  .rating-icon {
    color: var(--gray-6);
  }

  .rating-icon-checked {
    color: ${({ color }) => color || "var(--gray-9)"};
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .icon-label {
      font-size: 0.8125rem;
      line-height: 1.1875rem;
      text-align: center;
      padding: var(--spacing-1) var(--spacing-0);
    }
  }

  ${({ buttonIcons, largeButtonIcons }) =>
    (buttonIcons || largeButtonIcons) &&
    `.icon-rating-input {
      height: ${largeButtonIcons ? "45px" : "30px"};
    }

    .icon-input-container {
      border-radius: 4px;
      box-shadow: var(--blur-2);
      &:hover {
        box-shadow: var(--lift-6);
      }
      height: ${largeButtonIcons ? "45px" : "30px"};
      width: ${largeButtonIcons ? "45px" : "30px"};
      padding: ${largeButtonIcons ? "8px" : "5px"};

    }`}
`

export default IconRatingField

export { IconRadioInputWithLabels, IconRadioInputWithoutLabels }
