import cn from "classnames"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { CircleIcon } from "icons/FontAwesomeIcons"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const ExpectationsUserResults = ({ exercise, is_full_exercise, className }) => {
  const goal = getExerciseAnswer(exercise, "goal")
  const clarify = getExerciseAnswer(exercise, "clarify")
  const clarifyRating = getExerciseAnswer(exercise, "clarify_rating")
  const obstacles = getExerciseAnswer(exercise, "obstacles")
  const obstaclesRating = getExerciseAnswer(exercise, "obstacles_rating")
  const align = getExerciseAnswer(exercise, "align")
  const alignRating = getExerciseAnswer(exercise, "align_rating")
  const deliver = getExerciseAnswer(exercise, "deliver")
  const action = getExerciseAnswer(exercise, "action")

  const RatingIcon = ({ rating }) => {
    const colors = {
      Green: "var(--rising-green)",
      Yellow: "var(--rising-yellow)",
      Red: "var(--error-red)",
    }

    return <CircleIcon color={colors[rating]} />
  }

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <p className="text-gray-9 text-semi-bold mb-xs">The goal</p>
        <AnswerDisplayContent answer={goal} />

        <View $justifyContent="space-between">
          <p className="text-gray-9 text-semi-bold mb-xs">1: Request and measurement</p>
          {!!clarifyRating && !!is_full_exercise && (
            <div className="text-gray-8">
              <span className="mr-xxs">{clarifyRating}</span> <RatingIcon rating={clarifyRating} />
            </div>
          )}
        </View>
        <AnswerDisplayContent answer={clarify} />

        <View $justifyContent="space-between">
          <p className="text-gray-9 text-semi-bold mb-xs">2: Obstacles and support</p>
          {!!obstaclesRating && !!is_full_exercise && (
            <div className="text-gray-8">
              <span className="mr-xxs">{obstaclesRating}</span> <RatingIcon rating={obstaclesRating} />
            </div>
          )}
        </View>
        <AnswerDisplayContent answer={obstacles} />

        <View $justifyContent="space-between">
          <p className="text-gray-9 text-semi-bold mb-xs">3: Align and commit</p>
          {!!alignRating && !!is_full_exercise && (
            <div className="text-gray-8">
              <span className="mr-xxs">{alignRating}</span> <RatingIcon rating={alignRating} />
            </div>
          )}
        </View>
        <AnswerDisplayContent answer={align} />

        <p className="text-gray-9 text-semi-bold mb-xs">4: Deliver and assess</p>
        <div className="mb-xl">
          {is_full_exercise ? (
            <p className="text-gray-8 text-italic">(To be completed later...)</p>
          ) : (
            <AnswerDisplayContent answer={deliver} />
          )}
        </div>

        {!!is_full_exercise && (
          <>
            <p className="text-gray-9 text-semi-bold mb-xs">Next steps</p>
            <AnswerDisplayContent answer={action} />
          </>
        )}
      </TextContainer>
    </div>
  )
}

export default ExpectationsUserResults
