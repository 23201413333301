const BOHLeadershipPrinciplesTraining3 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Our Leadership Principles are key to living the behaviors that lead to leadership excellence. Becoming a great
        leader doesn’t just happen, it takes work and effort to be an effective and inspiring leader.
      </p>
      <p className="mb-medium">
        Once you have completed the Rising Team session on the Leadership Principles, here are some ideas to help you
        continue to activate and strengthen your leadership capability and the capability of those around you.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Activate the Principles Now and Build Momentum</h2>
      <p className="mb-medium">
        As you walk through each of the Leadership Principles, you will share how you would activate the principles.
        Here are some ideas to focus your self-development efforts around the leadership principles:
      </p>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            Do a self-assessment. On a scale of 1 – 6 (low to high) rate your ability to exhibit the behaviors of each
            principle. Ask your leader, a colleague or trusted advisor to take a look at how you assessed yourself for
            additional input. Choose a principle to improve upon OR
          </li>
          <li>
            Identify one to two principles to focus on that will help move or strengthen your business or
            impact/increase your team commitment and capability. Focus on those principles OR
          </li>
          <li>
            Review your{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://engage.surveymonkey.com/login/?ut_source=engage_site&ut_source3=subnav"
              className="text-gray-9 text-underline"
            >
              BluePulse
            </a>{" "}
            results and identify the principle that will help move the needle for engagement, focus on that leadership
            principle and model the behaviors to help move your team forward
          </li>
        </ul>
      </div>
      <p className="mb-medium">
        Use these different ideas to build your Leadership Principle performance goal for the year and discuss it in a
        pop up with your leader for additional refinement so you can focus your efforts on the most important
        principles.
      </p>
      <h2 className="text-rising-orange mb-medium">2. Eliminate/Overcome the Blockers to the Principles</h2>
      <p className="mb-medium">
        In your Leadership Principles Activation Plan, you identified the blockers to activating on the principles and
        how you will overcome the blockers.
      </p>
      <div className="py-xs ml-xl">
        <ul>
          <li>Who can you enlist and partner with to overcome the blockers that you identified?</li>
          <li>
            Open up to being coached and build a Feedback Crew that can alert you if they see that a blocker continues
            to exist that doesn’t allow you to successfully execute on the leadership principles you are focused on
            improving.
          </li>
          <li>
            Blockers can continue to pop up at any time so be agile and ready to pivot as you need to so you can be an
            even more effective leader.
          </li>
        </ul>
      </div>
      <h2 className="text-rising-orange mb-medium">3. Build Greater Leadership Insight</h2>
      <p className="mb-medium">
        Journaling is a great way to build greater understanding of your leadership capability and becoming more
        self-aware of your impact to help inform your future decisions and behaviors.
      </p>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            Writing down ideas and plans can help organize your thoughts and clarify goals making it easier to develop
            and implement strategies
          </li>
          <li>
            Reflecting on your thoughts, actions, and behaviors will also help gain insight into your strengths and
            weaknesses
          </li>
          <li>
            Documenting your progress, successes, failures and insights can help you hold yourself accountable for your
            actions and track your growth over time. Great opportunity to Walk the Talk!
          </li>
        </ul>
      </div>
      <p className="mb-medium">
        Congratulations! You have finished the training for the Leadership Principles kit and now you can preview and
        prep for your Rising Team session
      </p>
    </div>
  </div>
)

export default BOHLeadershipPrinciplesTraining3
