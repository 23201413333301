import { Route, Routes } from "react-router-dom"

import MiniKitHome from "./MiniKitHome"

import { SimpleRouteLayout } from "domains/Authentication/Routes"

const MiniKit = () => (
  <Routes>
    <Route element={<SimpleRouteLayout title="Mini Kits" />}>
      <Route path="*" element={<MiniKitHome />} />
    </Route>
  </Routes>
)

export default MiniKit
