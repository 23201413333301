import useEventListener from "@use-it/event-listener"
import { useLocation } from "react-router"
import { styled } from "styled-components"

import Footer from "components/Footer"
import FirebaseUI, { getFirebaseUIContainerElement } from "domains/Authentication/FirebaseUI"
import LogoIcon from "icons/LogoIcon"
import Background from "ui/Background"
import PageTitle from "ui/PageTitle"
import View from "ui/View"
import { onKeyEvent } from "utils/browser"

interface SSOLoginProps {
  className?: string
  provider: string
  providerName: string
  ssoRedirectLogin?: boolean
}

const SSOLogin = ({ className, provider, providerName, ssoRedirectLogin = false }: SSOLoginProps) => {
  const { state } = useLocation()

  // On ENTER keypress, trigger SSO login flow:
  useEventListener(
    "keypress",
    onKeyEvent("enter", () => {
      getFirebaseUIContainerElement()?.querySelector("button")?.click()
    })
  )

  const isLoginRedirectLoading = !!(ssoRedirectLogin && !state?.showLoginUI)

  return (
    <>
      <PageTitle>Login</PageTitle>
      <Background className={className} $url="https://static.risingteam.com/kit_assets/login-bg.png">
        <View className="text-center main-container" $flex={1} $flexDirection="column" $alignItems="center">
          <View className="text-center" $flex={1} $flexDirection="column" $alignItems="center" $width="auto">
            <LogoIcon className="logo mb-large" />
            <h2 className="mb-xl text-bold">Welcome to Rising Team</h2>
            {!isLoginRedirectLoading && (
              <p className="mb-xl">
                Please click the button below to sign up
                <br /> or log in with your {providerName} account:
              </p>
            )}
            <FirebaseUI
              ssoRedirectLogin={ssoRedirectLogin}
              ssoProviders={[{ provider, providerName }]}
              isLoginRedirectLoading={isLoginRedirectLoading}
            />
          </View>
          <Footer />
        </View>
      </Background>
    </>
  )
}

export default styled(SSOLogin)`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    background: var(--fg);
  }

  .main-container {
    margin: 80px auto 215px;
    padding: 50px 0 30px;
    max-width: 540px;
    background: white;
    opacity: 0.93;

    @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
      border: 1px solid var(--gray-5);
      box-sizing: border-box;
      box-shadow: 0 0 33px rgba(0 0 0 / 5%);
      border-radius: 15px;
    }

    .logo {
      width: 72px;
      height: 67px;
    }
  }
`
