import { styled } from "styled-components"

const MenziesCollectiveEfficacyTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        This article will help you get up to speed on the topic and make the most of your team workshop. The theme of
        this kit is about cultivating collective efficacy in schools.
      </p>
      <h3 className="text-rising-orange mb-medium">
        Schools with high levels of collective efficacy are more effective.
      </h3>
      <p className="mb-medium">
        Collective efficacy stems from work by the psychologist, Professor Albert Bandura, who found that groups who
        believed in their collective ability to achieve desired outcomes were more successful. His work highlighted the
        significant influence of beliefs and expectations on behaviour.
      </p>
      <p className="mb-medium">
        In schools, researchers have found that where teachers work and learn together and achieve high levels of
        collective efficacy with shared beliefs, expectations and practice, student outcomes improve.
      </p>
      <h3 className="text-rising-orange mb-medium">Collective efficacy develops in phases.</h3>
      <p className="mb-medium">
        While it’s clear that collective efficacy is fundamental for aligning beliefs, expectations and practice in
        schools, it cannot be assumed it will develop on its own, or be built overnight.
      </p>
      <p className="mb-medium">
        Five core elements central to developing collective efficacy in schools are: trust, shared sense of purpose,
        structures and supports, evidence-informed priorities, and effective teaching and learning.
      </p>
      <h3 className="text-rising-orange mb-medium">The four phases of maturity</h3>
      <p className="mb-medium">One way to think about how these elements develop is across four phases of maturity:</p>
      <p className="mb-medium">
        <b>Awareness:</b> growing recognition that changes to the ways of working are needed to improve student
        learning.
      </p>
      <p className="mb-medium">
        <b>Emerging:</b> initial opportunities to build collective efficacy are being explored.
      </p>
      <p className="mb-medium">
        <b>Establishing:</b> collective efficacy is building and strengthening ways of working.
      </p>
      <p className="mb-medium">
        <b>Sustaining:</b> collective efficacy is well embedded and efforts are made to maintain it.
      </p>
      <p className="mb-medium">
        These collective efficacy elements and phases are explored in each of the three kits - school, leader and
        teacher.
      </p>
      <h3 className="text-rising-orange mb-medium">What collective efficacy looks like in action.</h3>
      <p className="mb-medium">
        When there are high levels of collective efficacy in a school, staff believe all students can learn and that
        their collective efforts will influence student outcomes. They set high expectations for teaching and learning.
        They persevere when faced with challenging circumstances or setbacks. They continuously work together to
        improve.
      </p>
      <h3 className="mb-medium">Additional resources</h3>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="mb-medium ml-medium">
        <li>
          Angela Duckworth on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.psychologytoday.com/au/blog/actionable-advice-help-kids-thrive/202110/the-power-collective-efficacy"
          >
            The Power of Collective Efficacy
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://visible-learning.org/2018/03/collective-teacher-efficacy-hattie/"
          >
            Collective Teacher Efficacy (CTE)
          </a>{" "}
          according to John Hattie
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ascd.org/el/articles/the-power-of-collective-efficacy"
          >
            The Power of Collective Efficacy
          </a>{" "}
          from ASCD
        </li>
      </ul>
    </div>
  </div>
)

export default styled(MenziesCollectiveEfficacyTraining1)`
  img {
    max-width: 100%;
    height: auto;
  }
`
