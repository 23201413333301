const LearnerAgilityLearningPreferencesIntro = () => (
  <>
    <h2 className="text-gray-9 mb-medium">Intro</h2>
    <p className="mb-medium">
      One benefit of the modern age is that there are many ways to learn just about any topic or skill. And people have
      different preferences around how they like to learn, process, and retain new information.
    </p>
    <p className="mb-xxxxl">
      This activity will help identify and explore your unique preferences. After you take the exercise, you will share
      your results with the team and explore ways to support each other using your preferred styles more often.
    </p>
  </>
)

export default LearnerAgilityLearningPreferencesIntro
