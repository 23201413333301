import { forwardRef, useImperativeHandle } from "react"

import { positiveIntOrZero } from "./Duration"

import useTimer from "ui/hooks/useTimer"

const TimerWithRef = forwardRef(({ TimerComponent, startSeconds, autoStartTimer, timerProps }, ref) => {
  const startMilliseconds = startSeconds * 1000
  const timerConfig = autoStartTimer ? { isRunning: true, timePassed: 0, playClickedTime: Date.now() } : {}
  const { timeLeft, play, pause, reset, isRunning } = useTimer(startMilliseconds, timerConfig)

  const durationSeconds = positiveIntOrZero(timeLeft / 1000)

  const resetAndPlay = () => {
    if (ref?.current) {
      ref.current?.reset?.()
      setTimeout(() => ref.current?.play?.(), 1) // Start after 1 second if not first render.
      // Observed slight delay in ref being loaded
    }
  }

  useImperativeHandle(ref, () => ({
    play,
    reset,
    resetAndPlay,
  }))

  return (
    <TimerComponent
      durationSeconds={durationSeconds}
      isRunning={isRunning}
      onPlay={play}
      onPause={pause}
      onReset={reset}
      {...timerProps}
    />
  )
})

export default TimerWithRef
