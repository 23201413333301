import cn from "classnames"
import { Fragment } from "react"
import { styled } from "styled-components"

import { getSortedTruncatedUserAnswers } from "../results_utils"
import AnswerDisplayIconBox from "../ResultsComponents/AnswerDisplayIconBox"

import getIconOrError from "icons"

const RadioGroupMultiUserIconGrid = styled(function RadioGroupMultiUserIconGrid({
  identifier,
  exerciseInstances,
  exerciseComponent,
  sortedUsers,
  maxNumResults = null,
  className,
}) {
  return (
    <div className={cn("mb-xl", className)}>
      {getSortedTruncatedUserAnswers(exerciseInstances, sortedUsers, identifier, maxNumResults).map(
        ([user, answer]) => (
          <Fragment key={user.id}>
            <div className="user-label text-gray-9 text-normal text-thin">{user.short_name}</div>
            <AnswerDisplayIconBox
              answers={exerciseComponent.options.map(({ short_label, value, icon }) => ({
                title: short_label,
                icon: getIconOrError(icon),
                value: answer.includes(value),
              }))}
            />
          </Fragment>
        )
      )}
    </div>
  )
})`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: var(--spacing-3);
  column-gap: var(--spacing-7);

  .user-label {
    text-align: right;
    align-self: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: 1fr;
    row-gap: var(--spacing-0);

    .user-label {
      text-align: left;
    }
  }
`

export default RadioGroupMultiUserIconGrid
