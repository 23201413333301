enum TeamsCacheKeyParts {
  Teams = "teams",
  TeamSelectorTeams = "team_selector_teams",
  GetDemoTeams = "get_demo_teams",
}

const { Teams, TeamSelectorTeams, GetDemoTeams } = TeamsCacheKeyParts

function getTeamsCacheKey(): TeamsCacheKeyParts[] {
  return [Teams]
}

function getTeamSelectorTeamsCacheKey(): TeamsCacheKeyParts[] {
  return [Teams, TeamSelectorTeams]
}

function getDemoTeamsCacheKey(): TeamsCacheKeyParts[] {
  return [Teams, GetDemoTeams]
}

export { TeamsCacheKeyParts, getTeamsCacheKey, getTeamSelectorTeamsCacheKey, getDemoTeamsCacheKey }
