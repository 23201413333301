import { Formik, Form } from "formik"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"

import { getAdminUrl } from "domains/Admin/utils"
import CheckboxGroupField from "forms/fields/CheckboxGroupField"
import TextField from "forms/fields/TextField"
import handleErrors from "forms/handleErrors"
import { useCancelSubscription } from "resources/billing"
import BackButton from "ui/BackButton"
import SubmitButton from "ui/SubmitButton"
import TextInput from "ui/TextInput"
import View from "ui/View"

const REASON_OPTIONS = [
  "I am not finding sufficient value in the product",
  "I no longer have time to invest in this product",
  "I no longer have budget to pay for this product",
  "I have learned a lot from the product already and no longer need to use it.",
  "I want more control over which topics I do in which order",
]

const CancelSubscription = () => {
  const { accountId } = useParams()
  const navigate = useNavigate()
  const { mutateAsync: cancelSubscription } = useCancelSubscription(accountId)

  const initialValues = {
    reasons: {
      value: [],
    },
    other: "",
  }

  const onSubmit = handleErrors(async (values) => {
    const feedback = [...values.reasons.value, values.other]
    await cancelSubscription(feedback)
    navigate(getAdminUrl({ accountId }))
  })

  return (
    <div>
      <h1 className="text-large my-large">Cancel your subscription</h1>
      <p className="text-gray-9 mb-medium">
        We’re sorry to see you go! Note that when you cancel, you will lose access to your team’s data including
        exercise results.
      </p>
      <p className="text-gray-9 mb-large">Please let us know why you are cancelling by choosing all that apply:</p>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="space-y-large">
          <CheckboxGroupField
            name="reasons"
            options={REASON_OPTIONS.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <div>
            <p className="text-field-label text-gray-9 mb-xs">Other</p>
            <TextField name="other" forwardedAs={TextInput} />
          </div>

          <View>
            <BackButton>Back</BackButton>
            <SubmitButton>Cancel Subscription</SubmitButton>
          </View>
        </Form>
      </Formik>
    </div>
  )
}

export default CancelSubscription
