import { capitalize, find, isObject } from "lodash-es"
import { styled } from "styled-components"

import { getExerciseAnswer, getUserExerciseInstance } from "../results_utils"

import Tooltip from "ui/Tooltip"
import View from "ui/View"

const CIRCLE_COLORS = [
  "var(--orange-3)",
  "var(--rising-yellow)",
  "var(--orange-4)",
  "var(--rising-green)",
  "var(--yellow-2)",
  "var(--orange-3)",
  "var(--rising-yellow)",
  "var(--orange-4)",
]

const DEFAULT_CIRCLE_COLOR = "var(--chart-orange)"

const PercentageGroupFieldMultiUserCircles = styled(function PercentageGroupFieldMultiUserCircles({
  identifier,
  exerciseComponent,
  exerciseInstances,
  sortedUsers,
  className,
}) {
  const answers = getExerciseAnswersByPercentage(exerciseInstances, sortedUsers, identifier)
  return (
    <div className={className}>
      {answers.map(({ identifier, users }, index) => (
        <div key={identifier}>
          <p className="text-semi-bold">{capitalize(identifier)}</p>
          <View $flexWrap="wrap" $alignItems="center">
            {users.map(({ id, initials, percentage, short_name }) => {
              if (!percentage || percentage < 1) {
                return null
              }
              // 0-50% circle diameter will grow linearly from 20px to 120px.
              // 50-100% circle diameter will grow linearly from 120px to 150px.
              const diameter = percentage <= 50 ? (100 * percentage) / 50 + 20 : (30 * (percentage - 50)) / 50 + 120
              const fontSize = getPctCircleFontSize(diameter)
              const exercise_item = exerciseComponent.items.find((item) => item.identifier === identifier)
              const tooltipTitle = exercise_item?.sentence ?? capitalize(identifier)
              const tooltipTitleWrapper = (title) => (
                <>
                  <b>{short_name}</b>: {percentage}%<br />
                  {title}
                </>
              )
              return (
                <Tooltip top float key={identifier + id} title={tooltipTitle} titleWrapper={tooltipTitleWrapper}>
                  <PercentageCircle
                    $width={diameter}
                    $height={diameter}
                    $color={CIRCLE_COLORS[index] || DEFAULT_CIRCLE_COLOR}
                    $fontSize={fontSize}
                    className="m-xxs"
                  >
                    {initials}
                  </PercentageCircle>
                </Tooltip>
              )
            })}
          </View>
        </div>
      ))}
    </div>
  )
})`
  .grid-answers {
    display: grid;
    grid-template-columns: repeat(${({ columns = 6 }) => columns}, minmax(0, 1fr));
    gap: 16px;
  }
  .answer-box {
    padding: 20px 15px;
    min-width: 120px;

    &.checked {
      background: rgba(255, 210, 87, 0.7);
      border: 1px solid var(--rising-yellow);
    }
    &.unchecked {
      background: var(--gray-1);
      border: 1px solid var(--gray-3);
    }
  }
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .grid-answers {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .tooltip {
    width: fit-content;
  }
  .tooltip-content {
    line-height: 1.25rem;
  }
`

const PercentageCircle = styled.div`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  background: ${({ $color }) => $color};
  font-size: ${({ $fontSize }) => $fontSize}px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const getPctCircleFontSize = (diameter) => {
  if (diameter < 24) {
    return 8
  } else if (diameter < 26) {
    return 10
  } else if (diameter < 28) {
    return 11
  } else if (diameter < 30) {
    return 12
  } else if (diameter < 34) {
    return 13
  } else if (diameter < 40) {
    return 14
  } else {
    return 15
  }
}

const getExerciseAnswersByPercentage = (exerciseInstances, participants, identifier) => {
  if (!participants || participants.length === 0) {
    return {}
  }

  const answerIdentifiers = Array.from(
    new Set(
      exerciseInstances.flatMap((instance) => {
        const exerciseAnswer = getExerciseAnswer(instance, identifier)
        return exerciseAnswer ? Object.keys(exerciseAnswer) : []
      })
    )
  )

  const answers = answerIdentifiers.map((identifier) => ({
    identifier,
    users: [],
  }))

  for (const user of participants) {
    const exerciseAnswer = getExerciseAnswer(getUserExerciseInstance(exerciseInstances, user), identifier)
    if (exerciseAnswer && isObject(exerciseAnswer)) {
      for (const [identifier, { percentage }] of Object.entries(exerciseAnswer)) {
        const answer = find(answers, { identifier })
        if (answer) {
          answer.users.push({
            id: user.id,
            initials: `${user.first_name[0]}${user.last_name[0]}`.toUpperCase(),
            short_name: user.short_name,
            percentage: percentage || 0,
          })
        }
      }
    }
  }
  for (const answer of answers) {
    answer.users.sort((u1, u2) => u2.percentage - u1.percentage)
  }
  return answers
}

export default PercentageGroupFieldMultiUserCircles
