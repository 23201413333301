import { kebabCase } from "lodash-es"
import { useRef, useState } from "react"

import { useTeamLevelExerciseInstance } from "domains/Exercise/resource"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { StyledSelect } from "forms/fields/SelectField"
import { useTeam } from "resources/teams"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import InputRadio from "ui/InputRadio"
import Loading from "ui/Loading"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const TeamNormsHeadlinesWithBackground = ({ kit, kitInstance }) => {
  const { data: selectedTeam } = useTeam({ teamId: kitInstance?.team_id })
  const { enabled: sessionRealtimeUpdatesFlagActive } = useHasTeamFeature(
    selectedTeam,
    SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE
  )
  const sessionRealtimeUpdates =
    sessionRealtimeUpdatesFlagActive &&
    !!kitInstance?.session_started_at &&
    !kitInstance?.session_completed_at &&
    selectedTeam
  const canvasRef = useRef()
  const [headline, setHeadline] = useState("")
  const [fileURL, setFileURL] = useState(kit.virtual_background_list[0].image)
  const [downloadURL, setDownloadURL] = useState()
  const { data: teamLevelExerciseInstance, isFetching } = useTeamLevelExerciseInstance({
    teamId: kitInstance.team_id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    sessionRealtimeUpdates,
  })

  const teamNorms = getExerciseAnswer(teamLevelExerciseInstance, "team_sticky_notes")
    ?.filter((stickyNote) => stickyNote?.isFavorite)
    ?.map((stickyNote) => stickyNote.value)

  useEffectAfterChange(() => {
    if (!isFetching && !!teamNorms) {
      const canvas = canvasRef.current

      const onFinish = () => setDownloadURL(canvas.toDataURL("image/jpeg"))
      drawCanvas({ canvas, headline, fileURL, onFinish })
    }
  }, [headline, fileURL, isFetching, teamNorms])

  if (isFetching) {
    return <Loading />
  }

  if (!teamNorms) {
    return <h3 className="text-semi-bold">No team norms were selected</h3>
  }

  const downloadFileName = `${kebabCase(headline)}.jpeg`

  const onChange = async (e) => {
    const result = kit.virtual_background_list.find((item) => item.image === e.target.value)
    setFileURL(result.image)
  }

  return (
    <div>
      <h2 className="text-semi-bold">Design your own Team background</h2>
      <div className="mt-medium mb-large">
        Share an inspirational message during your next meeting with a custom background. It only takes a few clicks!
      </div>
      <div style={{ height: 400, width: 700 }}>
        <canvas ref={canvasRef} width={700} height={400} />
      </div>
      <div> </div>
      <div className="space-y-medium">
        <div>
          <span></span>
        </div>
        <View $flexDirection="column" style={{ width: "100%" }}>
          <div className="text-field-label my-medium text-bold">Select your background image</div>
          <StyledSelect value={fileURL} onChange={onChange} className="small">
            {kit.virtual_background_list.map((image) => (
              <option key={image.image} value={image.image}>
                {image.slug}
              </option>
            ))}
          </StyledSelect>
        </View>

        <div>
          <span className="text-field-label mt-xl text-bold">Choose your team norm</span>
        </div>
        {teamNorms.map((value, index) => (
          <View $alignItems="center" as="label" key={index}>
            <InputRadio
              disabled
              type="radio"
              value={value}
              className="ml-none mr-small"
              checked={headline === value}
              onClick={(e) => setHeadline(e.target.value)}
            />
            <p>{value}</p>
          </View>
        ))}
        <div>
          <Button as="a" download={downloadFileName} href={downloadURL} disabled={!downloadURL}>
            Download Image
          </Button>
        </div>
      </div>
    </div>
  )
}

const drawCanvas = ({ canvas, headline, fileURL, onFinish }) => {
  const context = canvas.getContext("2d")
  //render image
  const background = new Image()
  background.crossOrigin = "anonymous"
  if (fileURL) {
    background.src = fileURL
  } else {
    background.src = "https://static.risingteam.com/kit_assets/career-horizons/bonus-images/default-image-icon.png"
  }

  background.onload = () => {
    const width = 700
    const height = 400
    const heightOfText = 35
    const heightOfOneStrip = 50
    context.drawImage(background, 0, 0, width, height)
    const text = headline ? headline : ""
    const textMeasurement = context.measureText(text)
    const numberOfLines = Math.ceil(textMeasurement.width / width)
    const startHeightOfBackground = height / 2 - (heightOfText * numberOfLines) / 2
    const heightOfStrip = heightOfOneStrip * numberOfLines
    const lines = wrapText(context, text, width)
    context.fillStyle = "#000000A6"
    context.beginPath()
    if (numberOfLines > 0) {
      context.rect(0, startHeightOfBackground, width, heightOfStrip)
      context.fill()
      context.stroke()
    }
    let startHeightOfText = startHeightOfBackground + heightOfText
    for (const line of lines) {
      context.fillStyle = "#ffffff"
      context.font = "bold 24px Arial"
      context.textAlign = "center"
      context.fillText(line, width / 2, startHeightOfText)
      startHeightOfText += heightOfText
    }
    onFinish()
  }
}

function wrapText(context, text, maxWidth) {
  const words = text.split(" ")
  let line = ""
  const lines = []

  words.forEach((word) => {
    const textLine = line + word + " "
    const textWidth = context.measureText(textLine).width
    if (textWidth > maxWidth && line.length > 0) {
      lines.push(line.trim())
      line = word + " "
    } else {
      line = textLine
    }
  })
  lines.push(line.trim())
  return lines
}

export default TeamNormsHeadlinesWithBackground
