import { styled } from "styled-components"

const Grid = styled.div`
  display: grid;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  gap: ${({ $gap }) => $gap};
  ${({ $columns }) => ($columns ? `grid-template-columns: repeat(${$columns}, 1fr);` : "")}
  ${({ $columnsTablet, theme }) =>
    $columnsTablet
      ? `@media (max-width: ${theme.tabletMax}) { grid-template-columns: repeat(${$columnsTablet}, 1fr); }`
      : ""}
  ${({ $columnsMobile, theme }) =>
    $columnsMobile
      ? `@media (max-width: ${theme.mobileMax}) { grid-template-columns: repeat(${$columnsMobile}, 1fr); }`
      : ""}
`

Grid.defaultProps = {
  $width: "100%",
  $height: "initial",
  $gap: "normal",
}

export default Grid
