import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import getIconOrError from "icons"
import SelectedUserContext from "ui/SelectedUserContext"
import View from "ui/View"

const BlockShareResults = ({ exerciseInstance }) => {
  const userProfileFirstName = exerciseInstance.user_share_first_name
  const userProfileLastInitial = exerciseInstance.user_share_last_initial + "."
  const user = {
    id: exerciseInstance.user_id,
    short_name: `${exerciseInstance.user_share_first_name} ${exerciseInstance.user_share_last_initial}`,
  }
  const shareDefinition = exerciseInstance.definition.share_results
  const Icon = shareDefinition.icon && getIconOrError(shareDefinition.icon)

  return (
    <div>
      <div className="mb-medium">
        <View $alignItems="center">
          <Icon className="title-icon mr-medium" color={shareDefinition.icon_color} />
          <h1 className="text-gray-9">{shareDefinition.title}</h1>
        </View>
      </div>
      <p className="text-gray-9 mb-small">
        This profile was created by <span className="text-capitalize">{userProfileFirstName}</span> during a Rising Team
        session.
      </p>
      <p>
        <a href="https://risingteam.com/" target="_blank" rel="noopener noreferrer">
          Join Rising Team today
        </a>{" "}
        to get access to this and more exercises and insights that will benefit your teamwork.
      </p>
      <h2 className="text-capitalize text-gray-9 py-xl">
        {userProfileFirstName} {userProfileLastInitial}
      </h2>
      <SelectedUserContext.Provider value={{ selectedUser: user }}>
        <ResultsBlocks blocks={shareDefinition.blocks} exerciseInstances={[exerciseInstance]} sortedUsers={[user]} />
      </SelectedUserContext.Provider>
    </div>
  )
}

export default BlockShareResults
