import { useState } from "react"
import { Outlet } from "react-router-dom"

import { AccountTypeContext } from "./AccountTypeContext"
import Header from "./Header"

import RTKitViewBase from "components/RTKitViewBase"

const RTKitPublicView = () => {
  const [accountType, setAccountType] = useState()

  return (
    <AccountTypeContext.Provider value={{ accountType, setAccountType }}>
      <Header sidebarVisible={false} />
      <RTKitViewBase>
        <Outlet />
      </RTKitViewBase>
    </AccountTypeContext.Provider>
  )
}

export default RTKitPublicView
