const TeamPhotoShareCopy = ({ className }) => (
  <p className={className}>
    Here is the photo your team captured during the session. Share it with your team as a fun memento. (AND for the
    chance to win a $250 dollars towards a team outing or gift, just share it online with #RiseTogether and tag us
    @risingteamhq or email it to us at{" "}
    <a target="_blank" rel="noopener noreferrer" href="mailto:hello@risingteam.com">
      hello@risingteam.com
    </a>
    .)
  </p>
)

export default TeamPhotoShareCopy
