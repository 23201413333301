import { useState, useCallback } from "react"
import { useInterval } from "use-interval"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"

export default function useTimer(
  milliseconds,
  { isRunning: _isRunning = false, timePassed: _timePassed = 0, playClickedTime: _playClickedTime = 0 } = {},
  { enableReinitialize = false } = {}
) {
  const [isRunning, setIsRunning] = useState(_isRunning)
  const [playClickedTime, setPlayClickedTime] = useState(_playClickedTime)
  const initialTimePassed = _isRunning ? Date.now() - _playClickedTime : _timePassed
  const [timePassed, setTimePassed] = useState(initialTimePassed)
  const timeLeft = milliseconds - timePassed

  useEffectAfterChange(() => {
    if (enableReinitialize) {
      setIsRunning(_isRunning)
    }
  }, [_isRunning, enableReinitialize])
  useEffectAfterChange(() => {
    if (enableReinitialize) {
      setTimePassed(_timePassed)
    }
  }, [_timePassed, enableReinitialize])
  useEffectAfterChange(() => {
    if (enableReinitialize) {
      setPlayClickedTime(_playClickedTime)
    }
  }, [_playClickedTime, enableReinitialize])

  const play = useCallback(() => {
    // Most of our timers stop before reaching 0 for aesthetic reasons
    // Reset if play is pressed after time is up
    if (timeLeft < 1000) {
      setTimePassed(0)
      setPlayClickedTime(Date.now())
    } else {
      setPlayClickedTime(Date.now() - timePassed)
    }
    setIsRunning(true)
  }, [timePassed, timeLeft, setPlayClickedTime, setIsRunning])

  const pause = useCallback(() => setIsRunning(false), [setIsRunning])

  const reset = useCallback(() => {
    setIsRunning(false)
    setTimePassed(0)
  }, [setIsRunning, setTimePassed])

  useInterval(
    () => {
      setTimePassed(Date.now() - playClickedTime)
    },
    isRunning ? 100 : null
  )

  return {
    play,
    pause,
    reset,
    isRunning,
    timeLeft,
    timePassed,
  }
}
