import type { ReactNode } from "react"

import Tooltip from "ui/Tooltip"
import { plural } from "utils/string"

type Match = Record<string, any> & { name: string }

function getTagSelectedLabel(tags: string[], matches: Match[]): ReactNode {
  return !(tags?.length && matches?.length) ? null : (
    <>
      {matches[0]?.name ?? ""}
      <span className="text-gray-6">
        {matches.length <= 1
          ? ` (matches ${plural(tags.length, "tag", { showCount: false })})`
          : ` and ${matches.length - 1} more`}
      </span>
    </>
  )
}

function getTaggedItemsDescription(itemType: string, tags: string[], matches: Match[]): string {
  return `${plural(matches.length, itemType)} with ${plural(tags.length, "tag", {
    showCount: false,
  })}`
}

interface TaggedItemTooltipProps {
  children: ReactNode
  itemType: string
  tags: string[]
  matches: Match[]
}

function TaggedItemsTooltip({ children, itemType, tags, matches }: TaggedItemTooltipProps): ReactNode {
  return (
    <Tooltip
      top
      title={
        <>
          {plural(matches.length, itemType)} with {plural(tags.length, "tag", { showCount: false })}
          :<br />
          {matches.map(({ name }: { name: string }) => name).join(", ")}
        </>
      }
      disabled={!matches.length}
      className="width-auto"
    >
      {children}
    </Tooltip>
  )
}

export { getTagSelectedLabel, getTaggedItemsDescription, TaggedItemsTooltip }
export type { TaggedItemTooltipProps }
