import { createContext, useContext } from "react"

const KitSessionContext = createContext({
  kitInstance: null,
  visibleSteps: null,
  preview: null,
  team: null,
})

const useKitSession = () => useContext(KitSessionContext) || {}

export { KitSessionContext, useKitSession }
