import cn from "classnames"
import { Formik } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import { useHubSpotModal } from "components/HubSpotModal"
import SubscribeModal from "domains/Subscribe/SubscribeModal"
import ToggleSwitchField from "forms/fields/ToggleSwitchField"
import { CircleCheckIcon, SquareCheckIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import useWindowSize from "ui/hooks/useWindowSize"
import HorizontalRule from "ui/HorizontalRule"
import { useModal } from "ui/ModalContext"
import View from "ui/View"
import { formatDollarAmount } from "utils/number"

const OFFER_TIER_IDS = ["product_tier_1", "product_tier_2", "product_tier_3"]

const PEPMSubscribeOffersForm = styled(function PEPMSubscribeOffersForm({ accountOffers, onOfferSelected, className }) {
  const [productTierOneOfferId, productTierTwoOfferId, productTierThreeOfferId] = OFFER_TIER_IDS
  const { isTabletOrLarger } = useWindowSize()
  const [monthly, setMonthly] = useState(false)
  const annualSavingsText = accountOffers.pricing_toggle.annual
  const monthlySavingsText = accountOffers.pricing_toggle.monthly

  return (
    <div className={className}>
      <View $justifyContent="center" $alignItems="center" $flexWrap="nowrap" className="mt-xl mb-xl">
        <label htmlFor="monthly" onClick={() => setMonthly(true)}>
          <View $justifyContent="center" $flexWrap="wrap" className={cn({ "text-gray-7": monthly })}>
            <span className="text-normal">Annual</span>
            <span className="text-bold mx-xs">{!!annualSavingsText && <>•&nbsp;&nbsp;{annualSavingsText}</>}</span>
          </View>
        </label>
        <Formik>
          <ToggleSwitchField name="monthly" evergreen checked={monthly} saveOnChange={() => setMonthly(!monthly)} />
        </Formik>
        <label htmlFor="monthly" className="mr-small" onClick={() => setMonthly(false)}>
          <View $justifyContent="center" $flexWrap="wrap" className={cn({ "text-gray-7": !monthly })}>
            <span className="text-normal">Monthly</span>
            <span className="text-bold mx-xs">{!!monthlySavingsText && <>•&nbsp;&nbsp;{monthlySavingsText}</>}</span>
          </View>
        </label>
      </View>
      <View
        $flexDirection={isTabletOrLarger ? "row" : "column"}
        $height="100%"
        className="mb-large flex-grow-1 flex-basis-0"
        $gap="24px"
      >
        {[productTierOneOfferId, productTierTwoOfferId].map((offerId) => (
          <Offer
            key={offerId}
            offerId={offerId}
            accountOffers={accountOffers}
            monthly={monthly}
            onOfferSelected={onOfferSelected}
            isTabletOrLarger={isTabletOrLarger}
          />
        ))}
      </View>
      <View>
        {[productTierThreeOfferId].map((offerId) => (
          <Offer key={offerId} offerId={offerId} accountOffers={accountOffers} />
        ))}
      </View>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
`

const Offer = styled(function Offer({ offerId, accountOffers, monthly, onOfferSelected, isTabletOrLarger, className }) {
  const { setModal: setSubscribeModal } = useModal()
  const { setModal: setHubSpotModal } = useHubSpotModal()

  const accountOffer = accountOffers[offerId]
  if (!accountOffer) {
    return null
  }

  const { monthly_dollar_amount, yearly_dollar_amount, original_dollar_amount } = accountOffer
  const dollarAmount = monthly ? monthly_dollar_amount : yearly_dollar_amount
  const tierName = accountOffer.name
  const text = accountOffer.description
  const featuresText = accountOffer.features_text
  const featuresList = accountOffer.feature_list
  const customerLogo = accountOffer.customer_logo
  const isEnterprise = Boolean(offerId === "product_tier_3")

  const onModalOfferSelected = async (props) => {
    setSubscribeModal(null)
    await onOfferSelected(props)
  }

  const onClickSelect = () => {
    setSubscribeModal({
      title: (
        <div className="text-white">
          <SquareCheckIcon className="mr-xxs" /> Get Rising Team
        </div>
      ),
      content: (
        <SubscribeModal
          accountOffer={accountOffer}
          tierName={tierName}
          originalDollarAmount={original_dollar_amount}
          selectedMonthly={monthly}
          onOfferSelected={onModalOfferSelected}
          isTabletOrLarger={isTabletOrLarger}
        />
      ),
      size: "medium",
      modalWithColorTitleBar: true,
      modalWithColorBorder: true,
    })
  }

  const onClickContact = () => {
    setHubSpotModal()
  }

  const showOfferText = !isEnterprise && original_dollar_amount !== dollarAmount

  return (
    <View
      $flexDirection="column"
      $justifyContent="center"
      $alignItems="center"
      className={cn(className, { "offer-container": showOfferText })}
    >
      <View className="flex-grow-1 mb-xxl">
        <View $flexDirection="column" className="mt-large ml-large mr-large">
          <View $alignItems="center" className="mb-xs">
            {!!customerLogo && <img src={customerLogo} alt="customer-logo" height="30px" className="mb-xxs" />}
            <h2 className="text-gray-9">{isEnterprise ? "Enterprise" : tierName}</h2>
          </View>
          <HorizontalRule />
          {!!featuresText && <h5 className="text-gray-8">{featuresText}</h5>}
          <div className="mb-xs text-small text-gray-8 text-left">
            {isEnterprise ? "All core offerings plus the extras you need to thrive" : text}
          </div>
          {!isEnterprise && (
            <View className="mt-small mb-large">
              {original_dollar_amount !== dollarAmount && (
                <h3 className="text-semi-bold mr-xxs text-strikethrough text-gray-6">
                  ${formatDollarAmount(original_dollar_amount)}
                </h3>
              )}
              <p className="price text-rising-green">${formatDollarAmount(dollarAmount)}</p>
              <span className="ml-xs text-field-label text-rising-green">per employee / month</span>
            </View>
          )}
          <ul className="text-gray-8 text-left full-width">
            {featuresList.map((feature) => (
              <li key={feature}>
                <View $alignItems="center">
                  <CircleCheckIcon className="mr-xs text-rising-green" />
                  {feature}
                </View>
              </li>
            ))}
          </ul>
        </View>
        {!!showOfferText && (
          <span className="special-offer-text text-semi-bold">
            Special <br /> offer
          </span>
        )}
      </View>
      <View $flexDirection="column" className="px-large mb-large">
        <Button
          onClick={isEnterprise ? onClickContact : onClickSelect}
          className={cn("text-wrap", isEnterprise ? "contact-button" : "select-button")}
        >
          {isEnterprise ? "Book a call" : "Select"}
        </Button>
      </View>
    </View>
  )
})`
  height: 100%;
  width: 0;
  flex: 1 1 0px;
  box-shadow: var(--blur-4);
  border-radius: 8px;

  &.offer-container {
    background: linear-gradient(-135deg, #fac126 60px, #0000 0);
    position: relative;
  }

  .cirlce-icon {
    width: 7px;
    height: 7px;
  }

  hr {
    border: 2px solid var(--rising-orange);
    border-radius: 4px;
    width: 100%;
    background: var(--rising-orange);
  }

  ul {
    list-style-type: none;
    font-size: 0.938rem; // ~15px
    line-height: 1.75rem; // ~28px
    columns: ${({ accountOffers, offerId }) => `${accountOffers[offerId]?.feature_list.length / 3}`};
  }

  button {
    width: 100%;
    padding: 23px;
    border: 1px solid var(--rising-orange);

    &.select-button {
      color: #fff;
      background-color: var(--rising-orange);
    }

    &.contact-button {
      color: var(--rising-orange);
      background-color: #fff;
    }
  }

  .special-offer-text {
    position: absolute;
    right: 0;
    text-align: right;
    margin-top: 10px;
    margin-right: 5px;
    color: white;
    font-size: 13px;
    line-height: 15.85px;
  }

  .price {
    font-size: 2.5rem;
    line-height: 2rem;
    font-weight: 800;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    width: 100%;
  }
`

export default PEPMSubscribeOffersForm
