import cn from "classnames"
import DOMPurify from "dompurify"
import { Fragment } from "react"
import { styled } from "styled-components"

import Loading from "ui/Loading"
import View from "ui/View"

const RadioGroupFieldAggregatedMultiUserBarChart = styled(function RadioGroupFieldAggregatedMultiUserBarChart({
  identifier,
  identifiers,
  useAggregatedResultsData,
  has_sensitive_data,
  additional_data,
  colors,
  className,
}) {
  const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
    component: "RadioGroupFieldAggregatedMultiUserBarChart",
    identifier,
    identifiers,
    has_sensitive_data,
    additional_data,
  })

  if (!aggregatedResults && isFetching) {
    return <Loading />
  }

  if (!aggregatedResults) {
    return null
  }

  if (aggregatedResults.insufficient_data) {
    return (
      <p className="p-large bg-gray-1">
        To maintain anonymity, we only share results when there are at least three team members with complete results.
      </p>
    )
  }
  const numResultsToShow = Object.values(aggregatedResults.results).length
  const topResults = Object.values(aggregatedResults.results)
    .sort((a, b) => b.votes - a.votes)
    .slice(0, numResultsToShow)
  const maxVotes = Math.max(...topResults.map(({ votes }) => votes))

  return (
    <div className={cn(className, "results-grid")}>
      <div className="label-column" />
      <div className="text-gray-7 neg-ml-large-mobile-never">Votes</div>
      <div className="text-gray-7 ">Breakdown</div>
      {topResults.map(({ identifier, label, votes }, idx) => (
        <Fragment key={identifier}>
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
            className="label-column text-right text-bold"
          />
          <div className="text-right ">{votes}</div>
          <View className="full-width">
            <div
              style={{
                backgroundColor: `var(--${colors[idx % colors.length]})`,
                height: "100%",
                width: `${(100 * votes) / maxVotes}%`,
              }}
              className={cn("rating-bar-segment")}
            ></div>
          </View>
        </Fragment>
      ))}
    </div>
  )
})`
  display: grid;
  grid-template-columns: max-content max-content auto;
  gap: var(--spacing-2) var(--spacing-2);

  .rating-bar-segment {
    &:not(:last-child) {
      // add space between bars without moving the right edge of aggregate bars:
      border-right: 6px solid white;
    }

    &:first-child {
      border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
    }

    &:last-child {
      border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
    }

    &:only-child {
      border-radius: var(--border-radius-small);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: max-content auto max-content;

    .label-column {
      grid-column-start: span 3;
      justify-self: start;
    }
  }
`

export default RadioGroupFieldAggregatedMultiUserBarChart
