import { useKitSession } from "../KitSessionContext"

import { useTeamLevelExerciseInstance } from "domains/Exercise/resource"
import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import { useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const TeamLevelExerciseResultsBlocksContainer = ({ kitInstance, content }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesFlagActive } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE
  )
  const sessionRealtimeUpdates = sessionRealtimeUpdatesFlagActive && !!kitInstance && !!team
  const { data: participants } = useKitParticipants({ kitInstance })
  const { data: exerciseInstance, isFetching } = useTeamLevelExerciseInstance({
    teamId: kitInstance.team_id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    sessionRealtimeUpdates,
  })

  if (!participants || (!exerciseInstance && isFetching)) {
    return <Loading />
  }

  if (!exerciseInstance) {
    return null
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })

  return <ResultsBlocks blocks={content} sortedUsers={sortedParticipants} teamExerciseInstance={exerciseInstance} />
}

export default TeamLevelExerciseResultsBlocksContainer
