import { kebabCase } from "lodash-es"
import { useRef, useState } from "react"

import { StyledSelect } from "forms/fields/SelectField"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import TextInput from "ui/TextInput"
import View from "ui/View"

const ZoomBackground = ({ kit }) => {
  const canvasRef = useRef()
  const [headline, setHeadline] = useState("")
  const [fileURL, setFileURL] = useState(kit.virtual_background_list[0].image)
  const [downloadURL, setDownloadURL] = useState()

  useEffectAfterChange(() => {
    const canvas = canvasRef.current

    const onFinish = () => setDownloadURL(canvas.toDataURL("image/png"))
    drawCanvas({ canvas, headline, fileURL, onFinish })
  }, [headline, fileURL])

  const downloadFileName = `${kebabCase(headline)}.png`

  const onChange = async (e) => {
    const result = kit.virtual_background_list.find((item) => item.image === e.target.value)
    setFileURL(result.image)
  }

  return (
    <div>
      <h2 className="text-semi-bold">Design your own Team background</h2>
      <div className="mt-medium mb-large">
        Share an inspirational message during your next meeting with a custom background. It only takes a few clicks!
      </div>
      <div style={{ height: 400, width: 700 }}>
        <canvas ref={canvasRef} width={700} height={400} />
      </div>
      <div> </div>
      <div className="space-y-medium">
        <div>
          <span></span>
        </div>
        <View $flexDirection="column" style={{ width: "100%" }}>
          <div className="text-field-label my-medium text-bold">Select your background image</div>
          <StyledSelect value={fileURL} onChange={onChange} className="small">
            {kit.virtual_background_list.map((image) => (
              <option key={image.image} value={image.image}>
                {image.slug}
              </option>
            ))}
          </StyledSelect>
        </View>

        <div>
          <span className="text-field-label mt-xl text-bold">Customize your headline</span>
        </div>
        <TextInput
          type="text"
          maxLength={24}
          multiline={true}
          value={headline}
          placeholder="Enter the text you want to place on the image. Max 24 characters"
          onChange={(e) => setHeadline(e.target.value)}
        />
        <div>
          <Button
            as="a"
            className="inline-block mt-medium"
            download={downloadFileName}
            href={downloadURL}
            disabled={!downloadURL}
          >
            Download Image
          </Button>
        </div>
      </div>
    </div>
  )
}

const drawCanvas = ({ canvas, headline, fileURL, onFinish }) => {
  const context = canvas.getContext("2d")
  //render image
  const background = new Image()
  background.crossOrigin = "anonymous"
  if (fileURL) {
    background.src = fileURL
  } else {
    background.src = "https://static.risingteam.com/kit_assets/career-horizons/bonus-images/default-image-icon.png"
  }

  background.onload = () => {
    context.drawImage(background, 0, 0, 700, 400)
    context.fillStyle = "#4a4a4a"
    context.beginPath()
    context.rect(0, 0, 700, 60)
    context.fill()
    context.stroke()
    context.textAlign = "center"
    context.fillStyle = "#ffffff"
    context.font = "bold 24px Arial"
    context.fillText(headline ? headline : "You are LIMITLESS!", 350, 40)
    onFinish()
  }
}
export default ZoomBackground
