const DEISafeSpacesTraining2 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Our theme for this kit is working toward developing an Inclusive Mindset. To do this, remember our goals for
        this session:
      </p>
      <ol>
        <li>Deepen our understanding of how social identity shapes our perspectives as individuals and a team</li>
        <li>
          Explore our personal learning edge opportunities and learn how to recognize the difference between being
          uncomfortable {"&"} unsafe
        </li>
        <li>Identify principles and practices that foster an inclusive environment on our team</li>
      </ol>
      <p className="mb-medium">
        This toolkit taps into our personal experiences, some of which may be emotional. The opening video underscores
        how to help people navigate moments should they feel uncomfortable or unsafe during the discussions. Feel free
        to reference the video, where appropriate, to remind people that they can take ownership of what they share.
      </p>
      <h2 className="text-rising-orange mb-medium">The warmup</h2>
      <p className="mb-medium">
        Begin with the warmup activity to identify and discuss your general experiences of feeling different (or not)
        from others. When reviewing the prompt, you can let folks know that the stories they decide to share can be
        negative, positive or even neutral. The goal when sharing is to listen and build empathy for other people’s
        experiences. It can also help you become aware of how rarely or often you have felt different and to what
        degree.
      </p>
      <h2 className="text-rising-orange mb-medium">Activity 1: Reflect on social identity</h2>
      <p className="mb-medium">
        The first activity will further your reflection of what a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pediaa.com/what-is-the-difference-between-personal-and-social-identity/"
        >
          social identity
        </a>{" "}
        is as well as the identities specific to you. Be mindful of the definition of social identities and that they
        are not the same as personality traits. “The main difference between personal and social identity is that
        personal identity relates to how you see yourself as different from others, while social identity relates to how
        you identify yourself in relation to others according to what you share in common with others.” -{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pediaa.com/what-is-the-difference-between-personal-and-social-identity/"
        >
          Pediaa
        </a>{" "}
        It is also important to note that some may make assumptions about our social identity groups (e.g. that you are
        white when you are actually mixed race or that you are a man when you are non-binary). These assumptions can
        impact our lived experiences as well.
      </p>
      <p className="mb-medium">
        The activity consists of four basic questions that also align with a list of several different social
        identities. If you’re unsure or unfamiliar with any of them be sure to click on the little “i” to gather more
        information. This list is not exhaustive, so if people have one to add, there is a "something else" box. When
        it's time to share, the additional identity can be discussed. We encourage you to think deeply about each
        question before giving your answer. It is okay if participants are unable to land on a concrete answer for one
        or more of the questions. You’ll then share your thoughts and insights with the team and highlight which social
        identities aligned with each question.
      </p>
      <h2 className="text-rising-orange mb-medium">Activity 2: Understand comfort zones</h2>
      <p className="mb-medium">
        The second activity is about comfort zones. Comfort zones are also explained in the introductory video with
        Dereca. During this activity, you’ll be provided with five different statements to which you’ll answer if the
        experience would place you within your comfort zone, learning edge or into the danger zone.
      </p>
      <p className="mb-medium">
        Invite people to take note of the feelings that they experience. It can also be helpful to imagine how the
        experience may be different for someone with a different social identity. You may have noticed that whether
        something was a comfort zone, learning edge or danger zone experience for you depended on the level of trust you
        have with the people in the scenario. Your insights will be shared and discussed with your team - always
        remember to take care of yourself and only disclose what feels safe for you to do so.
      </p>
      <h2 className="text-rising-orange mb-medium">Activity 3 : Inclusive communication</h2>
      <p className="mb-medium">
        The final activity focuses on inclusive communication. For communication to be at its most inclusive, we provide
        you with three principles. Familiarize yourself with the definitions of each in case questions come up during
        the discussion.
      </p>
      <h3 className="mb-medium text-semi-bold">Invitation not expectation</h3>
      <p className="mb-medium">
        We want people from marginalized groups to feel their identity is valued and supported. It makes sense to want
        to consult them on company decisions that may impact people with their identity. And, it can be tempting to
        assume people want to do the work of educating others. However, we can invite their input without placing the
        burden of representation on them.
      </p>
      <h3 className="mb-medium text-semi-bold">Appreciation not appropriation</h3>
      <p className="mb-medium">
        Culture is something to be celebrated but it can be easy to misrepresent or be reductive about identities we
        know little about. We can demonstrate appreciation for cultures by taking the time to learn about, credit and be
        accountable to the communities we want to acknowledge.
      </p>
      <h3 className="mb-medium text-semi-bold">Expression of care not expression of shame</h3>
      <p className="mb-medium">
        Making a mistake regarding social identity can be embarrassing. Our natural reaction is to either become
        defensive or effusively apologetic. The focus should be on the person who was impacted, not on your need to be
        validated or comforted regarding your mistake.
      </p>
      <p className="mb-medium">
        We’ll then provide you with statements that represent the principles well, or not at all. If you aren’t sure, go
        ahead and flip the card. We’ll give you more info on the “why” behind any statement that doesn’t quite follow
        the principles of inclusive communication.
      </p>
      <p className="mb-medium">
        At the end of this session, as always, you and your team will have time to reflect, close out the discussion and
        create actions to supplement your learning.
      </p>
    </div>
  </div>
)

export default DEISafeSpacesTraining2
