import { groupBy } from "lodash-es"

import { smileRatingOptions } from "forms/fields/SmileRatingField"
import { getRepeatingColorFunc } from "ui/theme"

const getRatingColorFunc = (keys, ratingValues, defaultColors) => {
  const getDefaultColor = getRepeatingColorFunc(keys, defaultColors)
  const ratingColors = groupBy(smileRatingOptions, "value")
  return (item) => {
    if (!ratingValues || !ratingValues[item.id]) {
      return getDefaultColor(item)
    }
    const ratingValue = ratingValues[item.id]
    const { color } = ratingColors[ratingValue][0]
    return color
  }
}

const getAriaAdditionalInfoFunc = (ratingValues) => (item) => {
  if (!ratingValues || !ratingValues[item.id]) {
    return null
  }
  const ratingValue = ratingValues[item.id]
  const rating = smileRatingOptions.find(({ value }) => value === ratingValue)
  return rating?.label
}

export { getRatingColorFunc, getAriaAdditionalInfoFunc }
