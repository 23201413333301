import IconRatingField from "./IconRatingField"

const SmileRatingField = (props) => <IconRatingField {...props} options={smileRatingOptions} />

const smileRatingOptions = [
  {
    icon: "FrownIcon",
    color: "var(--orange-2)",
    label: "Very unsatisfied",
  },
  {
    icon: "FrownSlightIcon",
    color: "var(--orange-4)",
    label: "Unsatisfied",
  },
  {
    icon: "MehIcon",
    color: "var(--rising-yellow)",
    label: "Neutral",
  },
  {
    icon: "SmileIcon",
    color: "var(--chart-green)",
    label: "Satisfied",
  },
  {
    icon: "GrinStarsIcon",
    color: "var(--rising-green)",
    label: "Very satisfied",
  },
].map((option, idx) => ({ value: (idx + 1).toString(), ...option }))

export default SmileRatingField

export { smileRatingOptions }
