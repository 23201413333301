import { useField } from "formik"
import { groupBy, mapValues } from "lodash-es"

import ExercisePercentageGroupFieldCenteredMetric from "./ExercisePercentageGroupFieldCenteredMetric"

import SmileRatingField, { smileRatingOptions } from "forms/fields/SmileRatingField"
import PercentagesChart from "ui/PercentagesChart"
import { getRepeatingColorFunc } from "ui/theme"
import View from "ui/View"

const ExercisePercentageGroupRatingField = ({
  kit,
  name,
  percentage_id,
  percentage_step_path,
  saveOnChange,
  className,
}) => {
  const [{ value: ratingValues }, _meta, { setValue }] = useField(name)
  const [{ value: pctValues }] = useField(percentage_id)
  const { items, percentage_colors } = kit.session.steps
    .find(({ path }) => path === percentage_step_path)
    .components.find(({ identifier }) => identifier === percentage_id)
  const itemsMap = groupBy(items, "identifier")
  const pctValuesLabels = mapValues(pctValues, (value, key) => ({
    ...itemsMap[key][0],
    ...value,
  }))
  const answerIdentifiers = Object.keys(pctValuesLabels)
    .filter((key) => pctValuesLabels[key].percentage > 0)
    .sort((key1, key2) => {
      const value1 = pctValuesLabels[key1]
      const value2 = pctValuesLabels[key2]

      // Sort custom fields to the end
      if (value1.custom_field && !value2.custom_field) {
        return 1
      }
      if (!value1.custom_field && value2.custom_field) {
        return -1
      }

      return pctValuesLabels[key1].label.localeCompare(pctValuesLabels[key2].label)
    })

  const onChangeItem = (fieldName, value) => {
    const itemName = fieldName.split(".")[1]
    const newRatingValues = {
      ...ratingValues,
      [itemName]: value,
    }

    setValue(newRatingValues)
    saveOnChange(name, newRatingValues)
  }

  return (
    <View className={className} $flexDirectionTablet="column" $alignItems="center">
      <View $flexDirection="column" $flex={1} className="space-y-small">
        {answerIdentifiers.map((id) => {
          const ratingName = `${name}.${id}`
          const item = itemsMap[id][0]
          return (
            <SmileRatingField
              key={ratingName}
              name={ratingName}
              label={item.label}
              tooltip={!item.custom_field && item.sentence}
              saveOnChange={onChangeItem}
              className="border border-gray-2 border-radius full-width px-medium py-xs"
            />
          )
        })}
      </View>
      <View $flexDirection="column" $flex={1}>
        <PercentagesChart
          data={pctValuesLabels}
          colors={getRatingColorFunc(answerIdentifiers, ratingValues, percentage_colors)}
          layers={["arcs", "arcLabels", "arcLinkLabels", "legends", ExercisePercentageGroupFieldCenteredMetric]}
          enableArcLabels={false}
        />
      </View>
    </View>
  )
}

const getRatingColorFunc = (answerIdentifiers, ratingValues, defaultColors) => {
  const getDefaultColor = getRepeatingColorFunc(answerIdentifiers, defaultColors)
  const ratingColors = groupBy(smileRatingOptions, "value")
  return (item) => {
    if (!ratingValues || !ratingValues[item.id]) {
      return getDefaultColor(item)
    }
    const ratingValue = ratingValues[item.id]
    const { color } = ratingColors[ratingValue][0]
    return color
  }
}

export default ExercisePercentageGroupRatingField

export { getRatingColorFunc }
