import { useMemo } from "react"

import { useHasAccountFeature } from "domains/Admin/utils"
import { useUserAccount } from "resources/billing"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"

// TODO(typescript): Figure out better way of typing feature flags and convert useFeatures.js to TypeScript.
type SharedFlags = typeof SHARED_FLAGS
type SharedFlag = SharedFlags[keyof SharedFlags]

function useHasTeamFeature(team: TeamData, flag: SharedFlag): { enabled: boolean; isInitialLoading: boolean } {
  const isSharedFlag = Object.values(SHARED_FLAGS).includes(flag)
  const { data: account } = useUserAccount(!!isSharedFlag && team?.account_id)
  const { enabled: hasAccountFeature, isInitialLoading: accountFeatureIsLoading } = useHasAccountFeature(account, flag)

  if (!team?.features || (isSharedFlag && accountFeatureIsLoading)) {
    return {
      enabled: false,
      isInitialLoading: true,
    }
  }

  const hasTeamFeature = team.features.includes(flag) ?? false
  if (!isSharedFlag) {
    return {
      enabled: !!hasTeamFeature,
      isInitialLoading: false,
    }
  }

  return {
    enabled: !!(hasTeamFeature || hasAccountFeature),
    isInitialLoading: false,
  }
}

function getNonLeadMembers(team: TeamData): UserData[] {
  return team.members.filter((user: UserData) => user.id !== team.team_lead_id)
}

function getUserNameOrEmail(user: UserData): string | null {
  return (user?.name?.length ? user?.name : user?.email) ?? null
}

function isMemberInvitePending(user: UserData): boolean {
  return !!user?.invited_at && !user?.is_registered
}

interface CacheParam {
  cache?: Map<UserID, UserData> | null
}

function getLead(team: TeamData, { cache = null }: CacheParam = {}): UserData | null {
  let lead = cache?.get(team.team_lead_id)
  if (!lead) {
    lead = team.members.find((m) => m.id === team.team_lead_id)
    if (lead) {
      cache?.set(team.team_lead_id, lead)
    }
  }
  return lead ?? null
}

function getLeadName(team: TeamData, { cache = null }: CacheParam = {}): string | null {
  const tl = getLead(team, { cache })
  return tl?.name ?? null
}

function getLeadEmail(team: TeamData, { cache = null }: CacheParam = {}): string | null {
  const tl = getLead(team, { cache })
  return tl?.email ?? null
}

function getLeadNameOrEmail(team: TeamData, { cache = null }: CacheParam = {}): string | null {
  const lead = getLead(team, { cache })
  return lead ? getUserNameOrEmail(lead) : null
}

function isLeadInvitePending(team: TeamData, { cache = null }: CacheParam = {}): boolean {
  const lead = getLead(team, { cache })
  return !!(lead && isMemberInvitePending(lead))
}

function useTeamInfo(): {
  getNonLeadMembers: typeof getNonLeadMembers
  isMemberInvitePending: typeof isMemberInvitePending
  getUserNameOrEmail: typeof getUserNameOrEmail
  getLead: (team: TeamData) => UserData | null
  getLeadName: (team: TeamData) => string | null
  getLeadEmail: (team: TeamData) => string | null
  getLeadNameOrEmail: (team: TeamData) => string | null
  isLeadInvitePending: (team: TeamData) => boolean
} {
  // Provide getters for team lead information from a team object which caches
  // team lead results so that team members arrays are not repeatedly searched.
  const cache = useMemo(() => new Map(), [])
  return {
    getNonLeadMembers,
    isMemberInvitePending,
    getUserNameOrEmail,
    getLead: (team: TeamData) => getLead(team, { cache }),
    getLeadName: (team: TeamData) => getLeadName(team, { cache }),
    getLeadEmail: (team: TeamData) => getLeadEmail(team, { cache }),
    getLeadNameOrEmail: (team: TeamData) => getLeadNameOrEmail(team, { cache }),
    isLeadInvitePending: (team: TeamData) => isLeadInvitePending(team, { cache }),
  }
}

export {
  useHasTeamFeature,
  getNonLeadMembers,
  getUserNameOrEmail,
  isMemberInvitePending,
  getLead,
  getLeadNameOrEmail,
  isLeadInvitePending,
  useTeamInfo,
}
