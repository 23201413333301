const CareerHorizonsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-large">
      <p className="mb-large">
        To get started, let's talk about what long-term career aspirations are and why they're important.
      </p>
      <h2 className="text-rising-orange mb-medium">1. How career aspirations drive team results</h2>
      <p className="mb-medium">
        To support your team with their career plans, start with their larger career aspirations. Once aspirations are
        clear, you can help map out a path forward, and align opportunities with the skills needed to get there.
      </p>
      <h3 className="mb-medium">Goals create purpose</h3>
      <p className="mb-medium">
        What are your long-term career goals? What does future success look like for you? These are big questions for
        most people, but defining big goals can inspire us to make more purposeful career decisions along the way.
      </p>
      <p className="mb-medium">
        Long-term aspirations push us to strive for more. Whether we imagine ourselves inventing something, starting a
        business, writing a book, or climbing the proverbial career ladder, what we dream about in the future has an
        impact on how we view the present. Because if we know where we want to go, we can focus on the skills,
        experiences, and relationships needed to get us there.
      </p>
      <h3 className="mb-medium">Growth motivates people</h3>
      <p className="mb-medium">
        Helping employees grow is an important part of every manager's job. Most of us understand this, so we regularly
        work to connect our employees' development to performance goals. What we sometimes overlook, though, is how
        important it is to also connect their learning and progression to their own personal and professional goals.
      </p>
      <p className="mb-medium">
        In fact, "growth" is one of the most common motivators chosen in the Rising Team Motivators exercise. People
        want to know that their current efforts are connected to not only driving business results, but also to their
        longer-term personal and professional growth. And when we lead with growth in mind, we can expect to have a more
        effective and motivated team.
      </p>
      <h3 className="mb-medium">The big picture has big benefits</h3>
      <p className="mb-medium">
        How would your team—and your own success—be impacted if you could not only continue attracting top talent but
        also hold on to them longer?{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www2.deloitte.com/content/dam/Deloitte/us/Documents/human-capital/us-hc-high-impact-workforce-research-in-brief.pdf"
        >
          Deloitte's High-Impact Workforce research
        </a>{" "}
        found an organization's investment in helping employees achieve their long-term career goals is correlated with
        significantly higher business results. In fact, their study reports that "high-performing organizations are 37
        times more likely to help individuals achieve their long-term career goals."
      </p>
      <p className="mb-medium">
        Helping employees connect their long-term aspirations to work they perform on a regular basis not only helps
        elevate performance, it also improves retention.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://learning.linkedin.com/resources/workplace-learning-report"
        >
          LinkedIn's Workforce Learning Report
        </a>{" "}
        reveals that "94% of employees would stay longer with a company if it invested in helping them learn."
      </p>
      <h2 className="text-rising-orange mb-medium">2. Start with the end in mind</h2>
      <p className="mb-medium">
        Now that we understand why long-term goals are important, let's explore why it's helpful to start with them.
      </p>
      <h3 className="mb-medium">Work backwards</h3>
      <p className="mb-medium">
        During times of change and transition—and we're no strangers to those—it's normal to question why we're on the
        path we are. In fact, LinkedIn Learning's career expert{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/learning/taking-charge-of-your-career"
        >
          Christine DiDonato shares that
        </a>
        , "In times of uncertainty it's not uncommon to focus on shorter-term career decisions as they may seem easier
        to address." DiDonato concludes, "As much as we may think we're helping our careers, we're better off taking a
        step back and thinking about our longer-term aspirations. This will help us align our decisions to what's
        ultimately more meaningful in our lives."
      </p>
      <h3 className="mb-medium">"Long" is relative</h3>
      <p className="mb-medium">
        How someone defines "long-term" can depend on where they are in their career. For some, it may mean 15 or 20
        years down the road. For others, it could be about where they see themselves in 5 years. What's most important
        is that we have some vision of future success so we can work backwards from it and identify shorter-term steps
        that aim us in the right direction.
      </p>
      <h3 className="mb-medium">Focus on gaps</h3>
      <p className="mb-xxxxl">
        When working with your team to develop and articulate their career goals, encourage them to focus on the skills
        and experiences that can help fill the gaps they need to achieve longer term goals. This is where you come in.
        Your role is to help employees reach their long-term aspirations through smaller, shorter-term steps. You may
        not have the power to make all of their career dreams come true right now, but you do have the ability to curate
        development opportunities that get them closer a step at a time.
      </p>
    </div>
  </div>
)

export default CareerHorizonsTraining1
