import cn from "classnames"

import { useKitSession } from "../KitSessionContext"

import { useRaisedHands, useUpdateRaisedHand } from "resources/monthly_kit"
import Button from "ui/Button"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useHasTeamFeature } from "utils/team"

const RaiseHandButton = ({
  kitInstance,
  user,
  promptKey,
  loweredContent,
  raisedContent,
  loweredClassName,
  raisedClassName,
  className,
}) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: raisedHandUsers, isFetching } = useRaisedHands({
    kitInstance,
    key: promptKey,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { mutateAsync: updateRaisedHand } = useUpdateRaisedHand({ kitInstance, key: promptKey })

  if (isFetching || !raisedHandUsers) {
    return null
  }
  const hasRaisedHand = raisedHandUsers.map((u) => u.id).includes(user.id)

  return (
    <Button
      className={cn(className, hasRaisedHand ? raisedClassName : loweredClassName)}
      onClick={() => updateRaisedHand(!hasRaisedHand)}
    >
      {hasRaisedHand ? raisedContent : loweredContent}
    </Button>
  )
}

export default RaiseHandButton
