const BOHEmployeeExperienceTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Prepare for the session by learning more about the Employee Experience (EX), the EX Principles of Success, and
        our Employee Experience Principles.
      </p>
      <h2 className="text-rising-orange mb-medium">What is Employee Experience (EX)?</h2>

      <p className="mb-medium">
        From the moment a prospective employee looks at a company’s job opening, to the moment employees leave the
        company, everything that they learn, do, see, and feel contributes to their employee experience. The employee
        experience (EX) is foundational to building greater employee engagement which leads to greater business
        performance.
      </p>
      <p className="mb-medium">
        Our focus at Bank of Hawaiʻi is about creating a culture and environment where we can all do our best work and
        thrive. We care about delivering the best client experience to our customers and the best employee experience to
        our valued teammates. Forward together is the only way truly great things happen!
      </p>
      <p className="mb-medium">
        Our goal is to deliver on our True North and drive transformation in our community, enrich lives, and create a
        more vibrant and sustainable island home. One way we as leaders deliver on True North is our role in creating a
        place where we can all do our best work and thrive as captured under our EX Principles of Success.
      </p>
      <h2 className="text-rising-orange mb-medium">The EX Principles of Success</h2>
      <h3 className="mb-medium">Foster A World Class Workplace</h3>
      <p className="mb-medium">
        We embrace the future of work and all its possibilities—investing in our employees by providing tools, removing
        barriers and evolving processes and programs to help them be successful and fulfilled.
      </p>
      <h3 className="mb-medium">Build Remarkable Careers</h3>
      <p className="mb-medium">
        We empower employees to create their futures by exploring aspirations together, creating paths for growth, and
        providing learning opportunities for career development and advancement.
      </p>
      <h3 className="mb-medium">Make Leadership Excellence a Priority</h3>
      <p className="mb-medium">
        We inspire leaders at all levels to be value-creators and positively impact our business by translating our True
        North into exceptional outcomes by elevating the communication, collaboration and capabilities of our teams.
      </p>
      <p className="mb-medium">
        We know that employees want to make daily progress in work that matters and feel like they made a difference
        when they leave work every day. Servicing our customers and community, delivering exceptional customer
        experiences (CX), improving products, and building a strong and reputable brand all require their help.
      </p>
      <h2 className="text-rising-orange mb-medium">Because Great CX comes from Great EX!</h2>
      <p className="mb-medium">
        We are focused on EX because we care and we want to enrich employees' lives by creating experiences where they
        can Thrive and Shine. How we deliver great employee experiences are based on these core EX Principles of Value
        Me, Know Me, Empower Me and Inspire Me. Why do we do it? Because we care!
      </p>
      <h3 className="mb-medium">Our Employee Experience Principles </h3>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Value Me</span> – If you value me, you get to know me
          </li>
          <li>
            <span className="text-bold">Know Me</span> – When you know me, you can empower me
          </li>
          <li>
            <span className="text-bold">Empower Me</span> – When you empower me, I feel inspired
          </li>
          <li>
            <span className="text-bold">Inspire Me</span> – When I am inspired, I THRIVE
          </li>
        </ul>
      </div>
      <p className="mb-medium">And When I THRIVE, I SHINE!</p>
    </div>
  </div>
)

export default BOHEmployeeExperienceTraining1
