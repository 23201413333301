const MenziesExpectationsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        To get started, let's dive into what it looks like when goals and expectations are clear in the workplace versus
        when they aren't.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Clarity eliminates confusion</h2>
      <p className="mb-medium">
        Do you know what's expected from you at work? What about your team members? You may be surprised to learn, as{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/186164/employees-don-know-expected-work.aspx"
        >
          Gallup reports
        </a>
        , that less than half of employees know what leaders and managers expect of them. So why is this?
      </p>
      <p className="mb-medium">
        Let's start with the fast-paced nature of work. Every time the organisation or team makes a change, desired
        outcomes shift. Add that to matrixed structures and agile ways of working and it's no surprise that expectations
        can often be unclear.
      </p>
      <p className="mb-large">
        Let's play this out. When we don't know what's expected of us, we are left to play a guessing game, as we try to
        deliver results our manager wants. This creates confusion—resulting in wasted time, strained relationships, and
        may even leave team members questioning their impact and value.
      </p>
      <img
        className="mb-medium"
        src="https://static.risingteam.com/kit_assets/expectations/kit-expectations-training-1.1.png"
        alt="goals-comic"
        width="760px"
      />
      <p className="mb-medium">
        Conversely, operating with a clear set of expectations means we know what's important and can make better
        decisions about our effort, time, and resources. When we feel confident about what success looks like, we can
        make a conscious commitment to the task or goal at hand.
      </p>
      <h3 className="mb-medium">Clear direction starts with leadership</h3>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://news.gallup.com/businessjournal/190478/germany-employee-engagement-problem-begins-managers.aspx"
        >
          Gallup's research
        </a>{" "}
        also shows that some managers are unclear about their own expectations. When our organisation makes a strategic
        shift, it can result in moving targets or competing priorities. When larger goals change, we may not immediately
        know how it impacts what's expected of our teams. If you are unclear as the team leader, then it's only natural
        that you would have difficulty articulating expectations to your team members.
      </p>
      <p className="mb-medium">
        When we find ourselves in this situation, it's important to pause and revisit our goals and expectations and if
        needed, realign on how success will be measured before delegating to team members.
      </p>
      <h3 className="mb-medium">The future is about outcome over output</h3>
      <p className="mb-medium">
        Cintrix's{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.citrix.com/content/dam/citrix/en_us/documents/analyst-report/work-2035.pdf"
        >
          Work 2035
        </a>{" "}
        project found that 86% of employees surveyed said they would prefer to work for a company that prioritised
        outcomes over output. In a recent{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2021/05/what-your-future-employees-want-most"
        >
          HBR article
        </a>
        , Citrix executive, Tim Minahan explained that "new employees want to work for a company that cares less about
        the qualified work output they are able to produce, and more about the impact they can deliver to the business
        in a holistic sense."
      </p>
      <p className="mb-medium">
        Virtual work has only intensified this point. As more teams move away from traditional ways of working,
        employees have to rely on self-management more than ever before. As they juggle competing priorities and face
        ongoing change, having a clear understanding of their goals and what's expected of them is key to maintaining
        productivity as well as morale.
      </p>
      <h2 className="text-rising-orange mb-medium">2. Clarity is essential to both goals and commitments</h2>
      <p className="mb-medium">
        Having clear goals is only half of the equation. Let's explore the role clear commitments play in achieving
        results.
      </p>
      <h3 className="mb-medium">A lack of clarity can come from either side</h3>
      <p className="mb-medium">
        Achieving results is a combination of a clear request as well as a clear commitment to deliver those results. By
        considering both sides of the equation, we can approach setting goals and expectations through a powerful lens.
      </p>
      <p className="mb-medium">
        A great way to understand this point is to think of a time when a goal wasn't achieved. Our default response may
        be to attribute the failure to a performance issue. Although in some cases, this may be true, it may also be due
        to a lack of clarity in the request—or a failure to gain full commitment from the person or people on the other
        end of that request.
      </p>
      <h3 className="mb-medium">Gaining commitment requires psychological safety</h3>
      <p className="mb-medium">
        Even with the best goal setting frameworks, if the person on the other end of the request doesn't commit, there
        is a risk of failure. We may assume that team members should automatically commit to their goals. Yet in
        reality, commitment comes when people not only know WHAT is expected of them, but also have a safe space to ask
        questions, raise obstacles, and identify support they may need.
      </p>
      <p className="mb-medium">
        As managers, our job is to create an opportunity for team members to do just that. And it starts with
        psychological safety. The safer employees feel, the more likely they will be to share their concerns. When they
        feel comfortable to demonstrate this kind of vulnerability, you can work together to align on solutions before
        unraised concerns derail success.
      </p>
      <p className="mb-medium">
        Committed employees are engaged, and it is visible through their actions. In{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2008/01/how-to-increase-employee-commi">
          an article in HBR
        </a>{" "}
        Marshall Goldsmith concludes, "Gaining employee commitment results in greater profits because enthusiastic
        employees stay, contribute discretionary effort, and engage customers."
      </p>
      <h2 className="text-rising-orange mb-medium">3. A culture of commitment helps the whole team</h2>
      <p className="mb-medium">
        When everyone on a team sees the value of setting and committing to clear expectations with each other, we are
        able to collectively focus on the most important priorities—and better yet, eliminate wasted work.
      </p>
      <p className="mb-medium">
        Team members who are on the same page about what's expected can act more confidently, knowing their efforts are
        focused on what their manager or stakeholders actually want. This increases trust and avoids employees having to
        defend, finger point, or play the blame game if they miss the mark.
      </p>
      <p className="mb-xxl">
        That's a wrap on setting and clarifying expectations. Now we're ready to talk about how we'll involve the team
        in building this discipline together!
      </p>
    </div>
  </div>
)

export default MenziesExpectationsTraining1
