import { forwardRef } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { styled } from "styled-components"

import InputField from "./InputField"

const StyledTextarea = styled(
  forwardRef(({ className, children, editBox: _editBox, borderRadius: _borderRadius, ...props }, ref) => (
    <div className={className}>
      <TextareaAutosize {...props} ref={ref} />
      {children}
    </div>
  ))
)`
  position: relative;
  width: 100%;
  border: none;
  padding: 12px;
  background-color: var(--fg);
  border-radius: ${({ borderRadius }) => borderRadius ?? "var(--border-radius)"};
  outline: none;
  box-shadow: var(--blur-4);

  &:focus-visible,
  &:hover {
    box-shadow: var(--lift-6);
  }

  &:focus-within {
    border-color: ${({ disabled }) => (disabled ? "transparent" : "var(--border-color)")};
  }

  textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
  }
`

const TextareaField = (props) => <InputField forwardedAs={StyledTextarea} minRows={props.rows ?? 4} {...props} />

export default TextareaField

export { StyledTextarea }
