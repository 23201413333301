import cn from "classnames"
import type { Property } from "csstype"
import type { ReactNode } from "react"
import { styled } from "styled-components"

import View from "./View"

import { SpinnerThirdDuotoneIcon } from "icons/FontAwesomeIcons"

interface LoadingProps {
  className?: string
  children?: ReactNode
  inline?: boolean
  primaryColor?: string | null
  secondaryColor?: string | null
  size?: Property.Width | null // prop used only in styles
}

const Loading = styled(function Loading({
  className,
  children,
  inline,
  primaryColor,
  secondaryColor,
}: LoadingProps = {}) {
  if (!!inline && !!children) {
    throw new Error("Loading component cannot have both inline and children props")
  }

  const loading = (
    <SpinnerThirdDuotoneIcon
      className="loading-icon fa-spin"
      style={{ "--fa-primary-color": primaryColor, "--fa-secondary-color": secondaryColor }}
    />
  )
  return !!inline ? (
    <span className={cn("inline-block", className)} data-testid="loading-spinner">
      {loading}
    </span>
  ) : (
    <View
      $alignItems="center"
      $justifyContent="center"
      className={cn("mt-small", className)}
      data-testid="loading-spinner"
    >
      {loading}
      {!!children && <div className="loading-content">{children}</div>}
    </View>
  )
})`
  .loading-icon {
    animation-duration: 0.75s;
  }

  &:not(.inline-block) {
    position: relative;

    .loading-icon {
      width: ${({ size }) => size};
      height: ${({ size }) => size};
    }

    .loading-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

Loading.defaultProps = {
  size: "70px",
  primaryColor: "var(--rising-orange)",
  secondaryColor: "var(--gray-6)",
}

export default Loading
export type { LoadingProps }
