import { ShareIcon, CopyIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"

const RoadTemplate = ({ kit }) => (
  <>
    <h2 className="text-gray-9 mt-xl mb-medium">Take the ROAD with you</h2>
    <p className="mb-medium">
      Whether setting a goal for someone on your team or clarifying expectations for your own goals, the ROAD framework
      outlines specific criteria needed to set everyone up for success. Want to use the framework outside of a Rising
      Team session? Download the PDF or make a copy of a Google doc below.
    </p>
    <View>
      <a
        href={`https://static.risingteam.com/kit_assets/${kit.assets.road_pdf_slug}`}
        target="_blank"
        rel="noopener noreferrer"
        className="mr-large"
      >
        <Button className="tertiary">
          <ShareIcon />
          Download the PDF
        </Button>
      </a>
      <a href={kit.assets.road_google_doc_link} target="_blank" rel="noopener noreferrer">
        <Button className="tertiary">
          <CopyIcon />
          Copy the Google doc
        </Button>
      </a>
    </View>
  </>
)

export default RoadTemplate
