import cn from "classnames"
import { Fragment } from "react"
import { styled } from "styled-components"

import Loading from "ui/Loading"
import View from "ui/View"

const PercentageGroupFieldAggregatedMultiUserBarChart = styled(
  function PercentageGroupFieldAggregatedMultiUserBarChart({
    identifier,
    useAggregatedResultsData,
    has_sensitive_data,
    additional_data,
    className,
  }) {
    const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
      component: "PercentageGroupFieldAggregatedMultiUserBarChart",
      identifiers: [identifier],
      has_sensitive_data,
      additional_data,
    })

    if (!aggregatedResults && isFetching) {
      return <Loading />
    }

    if (!aggregatedResults) {
      return null
    }

    if (aggregatedResults.insufficient_data) {
      return (
        <p className="p-large bg-gray-1">
          To maintain anonymity, we only share results when there are at least three team members with complete results.
        </p>
      )
    }

    const numResultsToShow = 5 // number of results that fit on one jumbo screen
    const topResults = Object.values(aggregatedResults.results)
      .sort((a, b) => b.percentages - a.percentages)
      .slice(0, numResultsToShow)
    const maxPercentages = Math.max(...topResults.map(({ percentages }) => percentages))

    return (
      <div className={cn(className, "results-grid")}>
        <div />
        <div className="text-gray-7 text-medium neg-ml-large">Total</div>
        <div className="text-gray-7 text-medium">Breakdown</div>
        {topResults.map(({ identifier, label, percentages }, idx) => (
          <Fragment key={identifier}>
            <div className="text-right text-medium">{label}</div>
            <div className="text-right text-medium">{percentages}</div>
            <View className="full-width">
              <div
                style={{
                  backgroundColor: aggregatedResults.colors[idx % aggregatedResults.colors.length],
                  height: "100%",
                  width: `${(100 * percentages) / maxPercentages}%`,
                }}
                className="rating-bar-segment"
              ></div>
            </View>
          </Fragment>
        ))}
      </div>
    )
  }
)`
  display: grid;
  grid-template-columns: max-content max-content auto;
  gap: var(--spacing-2) var(--spacing-2);

  .rating-bar-segment {
    &:not(:last-child) {
      // add space between bars without moving the right edge of aggregate bars:
      border-right: 6px solid white;
    }

    &:first-child {
      border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
    }

    &:last-child {
      border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
    }

    &:only-child {
      border-radius: var(--border-radius-small);
    }
  }
`

export default PercentageGroupFieldAggregatedMultiUserBarChart
