import { Link, useParams, Route, Routes, Navigate } from "react-router-dom"

import BlockResultsReview from "./BlockResultsReview"
import CareerHorizonsResultsReview from "./CareerHorizonsResultsReview"
import CrisisResponseResultsReview from "./CrisisResponseResultsReview"
import ExpectationsResultsReview from "./ExpectationsResultsReview"
import FeedbackResultsReview from "./FeedbackResultsReview"
import FiveSensesResultsReview from "./FiveSensesResultsReview"
import JumboBlockResultsReview from "./JumboBlockResultsReview"
import KeepLetGoResultsReview from "./KeepLetGoResultsReview"
import LearnerAgilityResultsReview from "./LearnerAgilityResultsReview"
import LifeLinesResultsReview from "./LifeLinesResultsReview"
import TalentsResultsReview from "./TalentsResultsReview"
import TalentsResultsReviewMashup from "./TalentsResultsReviewMashup"

import NotFound from "components/NotFound"
import { useKitDefinition } from "resources/monthly_kit"
import Loading from "ui/Loading"
import PageBreadcrumbs from "ui/PageBreadcrumbs"
import { useSelectedTeam } from "ui/SelectedTeamContext"
import { KitType } from "utils/kit"

const SLUG_TO_REVIEW_COMPONENT = {
  "talent-profile": TalentsResultsReview,
  "talent-mashup": TalentsResultsReviewMashup,
  "expectations-road-map": ExpectationsResultsReview,
  "career-horizons": CareerHorizonsResultsReview,
  feedback: FeedbackResultsReview,
  "learner-agility": LearnerAgilityResultsReview,
  "crisis-response": CrisisResponseResultsReview,
  lifelines: LifeLinesResultsReview,
  "five-senses": FiveSensesResultsReview,
  "keep-let-go": KeepLetGoResultsReview,
}

const ResultsReview = () => {
  const { kitSlug } = useParams()
  const { selectedTeam } = useSelectedTeam()
  const { data: kit, isFetching } = useKitDefinition({ teamId: selectedTeam?.id, slug: kitSlug })
  const firstResultsPage = kit?.results_pages?.[0]

  if (!selectedTeam) {
    return null
  }

  if (!kit || !firstResultsPage) {
    return isFetching ? <Loading /> : <NotFound />
  }

  return (
    <div className="main-container full-width">
      <Routes>
        {kit.results_pages.map((r) => (
          <Route
            key={r.slug}
            path={r.slug}
            ignore
            element={<ResultsPageWithBreadcrumb kit={kit} resultsPage={r} selectedTeam={selectedTeam} />}
          />
        ))}
        <Route
          path="*"
          ignore
          element={<Navigate to={`${firstResultsPage.slug}?team_id=${selectedTeam.id}`} replace />}
        />
      </Routes>
    </div>
  )
}

const ResultsPageWithBreadcrumb = ({ kit, resultsPage, selectedTeam }) => {
  const ResultsPage =
    selectedTeam.jumbo && kit.type !== KitType.MINI
      ? JumboBlockResultsReview
      : resultsPage.use_blocks
      ? BlockResultsReview
      : SLUG_TO_REVIEW_COMPONENT[resultsPage.slug]
  return (
    <>
      <PageBreadcrumbs className="mb-medium">
        <Link to={`/team?team_id=${selectedTeam.id}`}>View team</Link>
        {resultsPage.title}
      </PageBreadcrumbs>
      <ResultsPage kit={kit} resultsPage={resultsPage} selectedTeam={selectedTeam} />
    </>
  )
}

export default ResultsReview
