import cn from "classnames"
import { styled } from "styled-components"

import Button from "./Button"
import CopyButton from "./CopyButton"
import View from "./View"

import { LinkIcon } from "icons/FontAwesomeIcons"
import { useModal } from "ui/ModalContext"
import { colors } from "ui/theme"

const ShareModal = ({
  shareLinkTitle,
  shareLinkModalTitle,
  shareLinkModalContent,
  shareLink,
  size,
  className,
  buttonClassName,
}) => {
  const { setModal } = useModal()
  const openModal = () =>
    setModal({
      title: shareLinkModalTitle,
      content: <ShareLinkModal shareLinkModalContent={shareLinkModalContent} shareLink={shareLink} />,
      size,
    })
  return (
    <div className={className}>
      <Button color={colors.risingBlue} className={cn("link-semi-bold", buttonClassName)} onClick={openModal}>
        {shareLinkTitle}
      </Button>
    </div>
  )
}

const ShareLinkModal = styled(function ShareLinkModal({ className, shareLinkModalContent, shareLink }) {
  if (!shareLink) {
    return null
  }

  return (
    <View className={className}>
      <div className="share-modal-container">
        <div className="text-gray-8 text-normal py-xs">{shareLinkModalContent}</div>
        <div className="share-link pt-xs text-gray-8 text-normal p-small">{shareLink}</div>
        <CopyButton className="mt-large" buttonClassName="tertiary" clipboardText={shareLink}>
          <LinkIcon />
          Copy link
        </CopyButton>
      </div>
    </View>
  )
})`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  .share-modal-container {
    background-color: var(--white);
  }

  .share-link {
    background-color: var(--gray-1);
    border: 1px solid var(--gray-4);
    border-radius: 4px;
  }
`

export default ShareModal
