import cn from "classnames"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { Choice } from "forms/fields/ChoicesField"

const CheckboxGroupReadOnlyField = ({ identifier, exerciseComponent, exerciseInstance, className }) => {
  const { options } = exerciseComponent
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  return (
    <div className={cn("space-y-small", className)}>
      {options.map(({ value, label }) => (
        <Choice
          disabled
          type="checkbox"
          key={value}
          label={label}
          checked={userSelections.includes(value)}
          className="cursor-default"
        ></Choice>
      ))}
    </div>
  )
}

export default CheckboxGroupReadOnlyField
