import cn from "classnames"
import { styled } from "styled-components"

import View from "./View"

import getIconOrError from "icons"

const PromptTip = styled(function PromptTip({ tip, icon, icon_color, className }) {
  const Icon = icon && getIconOrError(icon)

  if (!tip && !icon) {
    return null
  }

  return (
    <View className={cn("bg-gray-1 p-small border-radius-small", className)} $alignItems="center">
      <Icon className={cn("fa-xl pr-small", icon_color)} color={icon_color} />
      <p>{tip}</p>
    </View>
  )
})`
  max-width: 578px;
`

export default PromptTip
