const LearnerAgilityBehaviorsIntro = () => (
  <>
    <h2 className="text-gray-9 mb-medium">Intro</h2>
    <p className="mb-medium">
      Learning agility is accelerated when we feel safe being vulnerable. One test of this is how comfortable we feel
      asking for help.
    </p>
    <p className="mb-xxxxl">
      During this activity, we're going to look back and reflect on a time we felt comfortable asking for help to learn
      something new and the factors that helped us feel comfortable. Then we'll choose which learning agility behaviors
      we'd like to focus on moving forward. <b>We will all share our responses with each other.</b>
    </p>
  </>
)

export default LearnerAgilityBehaviorsIntro
