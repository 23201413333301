const RT4SPerspectiveSeekingTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Before running the session with your team, here is some additional context about the theme.
      </p>
      <p className="mb-medium">
        Schools are faced with many challenges on a daily basis. With rapid changes in technology, evolving curriculum
        demands, and diverse student needs, educators must constantly adapt and find creative solutions to new problems.
        Effective problem-solving helps educators navigate uncertainty and maintain a supportive learning environment.
        And modeling this skill with students better prepares them for a world where they will need to think critically,
        adapt quickly, and tackle unpredictable situations in both their personal and professional lives.
      </p>
      <h2 className="text-rising-orange pb-xs">What perspective seeking makes possible</h2>
      <p className="mb-medium">
        Perspective seeking allows us to step back and understand the situation, define the problem, and see it from
        different angles to develop a better understanding and arrive at a better solution together. This involves
        thoroughly defining the problem and identifying its root causes. By stepping back and examining the situation
        from various perspectives, we gain a deeper understanding, enabling us to craft solutions that address the
        underlying issues rather than just the visible ones. This allows us to challenge our initial assumptions and
        gain a more holistic view.
      </p>
      <h2 className="text-rising-orange pb-xs">Perspective seeking is a practice</h2>
      <p className="mb-medium">
        Perspective seeking is the practice of actively trying to understand and appreciate different viewpoints and
        experiences, which strengthens problem solving and decision making. It involves stepping outside one’s own
        assumptions and biases to consider how others perceive a situation. This can include seeking input from diverse
        stakeholders, reflecting on their experiences, and exploring issues from multiple angles. The process encourages
        open-mindedness and empathy, allowing individuals or teams to uncover insights that might be overlooked.
      </p>
      <h2 className="text-rising-orange pb-xs">Brainstorming and iterative thinking</h2>
      <p className="mb-medium">
        Instead of settling for the first idea that comes to mind, the act of stepping back to develop a more nuanced
        understanding of a challenge encourages expansive brainstorming and iterative thinking. Expansive brainstorming
        is a creative process that encourages the generation of as many ideas as possible without immediately judging or
        narrowing them down. The focus is on thinking broadly and considering all potential solutions, even if they seem
        unconventional or impractical at first. Iterative thinking is a problem-solving approach where—instead of aiming
        for a perfect solution right away—ideas evolve through through multiple rounds of feedback and adjustment. These
        approaches not only cultivate a richer idea generation but also empower teams to explore unconventional
        solutions that may not have been initially apparent. Combining different ideas together can also generate
        solutions that are better than any one individual can do on their own.
      </p>
    </div>
  </div>
)

export default RT4SPerspectiveSeekingTraining1
