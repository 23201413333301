import SelectField from "./SelectField"

const SHRM_JOB_POSITIONS = [
  { value: "Academician Administrator", label: "Academician Administrator" },
  { value: "Asst/Assoc Director", label: "Asst/Assoc Director" },
  { value: "Asst. or Assoc. Vice Pres", label: "Asst. or Assoc. Vice Pres" },
  { value: "Business Partner / HRBP", label: "Business Partner / HRBP" },
  { value: "CHRO, Chief of HR/People Function", label: "CHRO, Chief of HR/People Function" },
  { value: "Consultant", label: "Consultant" },
  { value: "Coordinator", label: "Coordinator" },
  { value: "Director", label: "Director" },
  { value: "EVP, SVP", label: "EVP, SVP" },
  { value: "Generalist", label: "Generalist" },
  { value: "Legal Counsel / Attorney", label: "Legal Counsel / Attorney" },
  { value: "Manager, Supervisor", label: "Manager, Supervisor" },
  { value: "Non CHRO Executive, Other C-Suite", label: "Non CHRO Executive, Other C-Suite" },
  { value: "Other", label: "Other" },
  { value: "Partner, Principal", label: "Partner, Principal" },
  { value: "President, CEO, Chairman", label: "President, CEO, Chairman" },
  { value: "Representative, Associate", label: "Representative, Associate" },
  { value: "Specialist", label: "Specialist" },
  { value: "Sr. Director", label: "Sr. Director" },
  { value: "Student", label: "Student" },
  { value: "VP", label: "VP" },
]

const JobPositionsField = ({ jobPositions = SHRM_JOB_POSITIONS, ...props }) => (
  <SelectField {...props}>
    <option disabled value="" />
    {jobPositions.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ))}
  </SelectField>
)

export default JobPositionsField

export { SHRM_JOB_POSITIONS }
