import * as Sentry from "@sentry/browser"
import type { FirebaseApp } from "firebase/app"
import type { Auth as FirebaseAuthInstance, User as FirebaseUser } from "firebase/auth"
import type {
  Database as FirebaseDatabaseInstance,
  ref as firebaseDatabaseRef,
  onValue as firebaseDatabaseOnValue,
} from "firebase/database"
import type { auth as FirebaseUI } from "firebaseui"
import "firebaseui/dist/firebaseui.css"

import safeImportFirebaseModules from "domains/Authentication/safe_import_firebase_modules"
import { getNumSecondsSinceEpoch } from "utils/date"
import { isDevelopmentEnv } from "utils/env"
import { StorageKey } from "utils/storage"

type FirebaseDatabaseRef = typeof firebaseDatabaseRef
type FirebaseDatabaseOnValue = typeof firebaseDatabaseOnValue
type FirebaseUIInstance = FirebaseUI.AuthUI

// Firebase Auth Token Management Strategy
// ---------------------------------------
// For Firebase Auth'd users we need to add user ID tokens to every request in the
// form of an "Authorization: 'Bearer <token>'" header, but since we only want
// _some_ users to authenticate with Firebase Auth, this poses a problem:
//
// If a user isn't using Firebase Auth at authentication, we don't want
// their requests to rely on the Firebase Auth SDK at all, and we definitely don't
// want their requests to await for Firebase Auth to initialize and get the latest
// refreshed user ID token.
//
// To deal with this, whenever a user is signed in via Firebase Auth, we persist
// the latest Firebase Auth user ID token to localStorage, with an expiry date
// attached matching the time it will expire on the Firebase side (1 hour from
// refresh). If the a token does not exist in localStorage, our request code knows
// that a user is not authed via Firebase and will never try to refresh a token.
// Upon sign-out, the token is removed entirely from localStorage.
//
// On page reload, we can immediately send the persisted tokens with requests so
// authentication will work without waiting for the Firebase Auth SDK to initialize
// (and will continue to work after the SDK is fully initialized).

const FIREBASE_AUTH_TOKEN_EXPIRY_SECONDS = 55 * 60 // 1 hour minus 5 minute buffer
// 1 hour is the default token expiry period, see:
// https://firebase.google.com/docs/auth/admin/manage-sessions

// Private util functions:
function _isTokenExpired(expiry: number): boolean {
  return !Number.isInteger(expiry) || expiry < getNumSecondsSinceEpoch()
}
function _persistFirebaseUserIdTokenWithNewExpiry(token: string): void {
  window.localStorage.setItem(
    StorageKey.FirebaseAuthToken,
    JSON.stringify({
      token,
      expiry: getNumSecondsSinceEpoch() + FIREBASE_AUTH_TOKEN_EXPIRY_SECONDS,
    })
  )
}
function _persistFirebaseUserName(user: FirebaseUser): void {
  const name = user?.displayName?.trim() ?? ""
  if (name) {
    window.sessionStorage.setItem(StorageKey.FirebaseAuthUserName, name)
    // Use sessionStorage so this will be cleaned up naturally when tab closes.
    // We don't need to persist it any longer than that since it's just used
    // for pre-populating input fields during user account creation.
  }
}

// Set up a promise which allows various functions to delay operations
// until Firebase App initialization is complete.
// (getFirebaseAuthInstance, getFirebaseRealtimeDatabase)
type FirebaseAppInstancePromiseResolve = (firebaseApp: FirebaseApp) => void
let _firebaseAppInstancePromiseResolve: FirebaseAppInstancePromiseResolve | null = null
const _firebaseAppInstancePromise = new Promise<FirebaseApp>((resolve: FirebaseAppInstancePromiseResolve) => {
  _firebaseAppInstancePromiseResolve = resolve
})
function untilFirebaseAppInitialization(): Promise<FirebaseApp> {
  return _firebaseAppInstancePromise
}
function finishFirebaseAppInitialization(firebaseAppInstance: FirebaseApp): void {
  _firebaseAppInstancePromiseResolve?.(firebaseAppInstance)
}

async function initializeFirebaseApp(): Promise<void> {
  const authDomain = isDevelopmentEnv() ? "rising-team-staging.firebaseapp.com" : window.location.hostname

  // Tests may not have access to VITE_APP_FIREBASE_API_KEY, in which case we'll
  // skip Firebase Auth initialization entirely. If we do want to test flows that
  // involve Firebase auth, we'll set up the auth emulator to do so.
  // https://firebase.google.com/docs/emulator-suite/connect_auth
  if (import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    const { firebaseApp } = await safeImportFirebaseModules("firebaseApp")

    if (!firebaseApp?.initializeApp) {
      return // Dynamic import failed; page will reload imminently.
    }

    const firebaseAppInstance = firebaseApp.initializeApp(
      {
        apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
        authDomain,
        projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
        databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
      },
      import.meta.env.VITE_APP_FIREBASE_APP_NAME
      // Explicitly specify Firebase app name to avoid possible issues with Safari eg.
      // https://github.com/firebase/firebase-js-sdk/issues/7888#issuecomment-1906587853
    )

    if (["development", "test"].includes(import.meta.env.VITE_NODE_ENV)) {
      const { firebaseDB } = await safeImportFirebaseModules("firebaseDB")
      if (firebaseDB?.connectDatabaseEmulator && firebaseDB?.getDatabase) {
        firebaseDB.connectDatabaseEmulator(firebaseDB.getDatabase(firebaseAppInstance), "localhost", 9000)
      }
    }

    // Notify other functions that Firebase App initialization is complete:
    // (getFirebaseAuthInstance, getFirebaseRealtimeDatabase)
    finishFirebaseAppInitialization(firebaseAppInstance)
  }
}

async function getFirebaseAuthInstance(
  firebaseAuth?: typeof import("firebase/auth") | null
): Promise<FirebaseAuthInstance | null> {
  if (!import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    return null // in test environment, skip Firebase integration entirely; see comment above
  }

  if (!firebaseAuth?.getAuth) {
    const result = await safeImportFirebaseModules("firebaseAuth")
    firebaseAuth = result.firebaseAuth
  }

  if (!firebaseAuth?.getAuth) {
    return null // Dynamic import failed; page will reload imminently.
  }

  const firebaseAppInstance = await untilFirebaseAppInitialization()

  return firebaseAuth.getAuth(firebaseAppInstance)
}

// Get the latest Firebase user ID token from localStorage, to be used in the
// "Authorization" request header for API requests.
// This function will _not_ refresh the token automatically if it is expired;
// instead it provides a { token, expired } return value and the caller must
// check `expired` and refresh the token separately if necessary.
interface FirebaseAuthInfo {
  token: string | null
  expired: boolean
  isAuthenticatedWithFirebase: boolean
  firebaseUserDisplayName: string
}
function getFirebaseAuthInfo(): FirebaseAuthInfo {
  const strValue = window.localStorage.getItem(StorageKey.FirebaseAuthToken)
  let parsedValue = null
  try {
    parsedValue = JSON.parse(strValue ?? "")
  } catch (e) {
    // Pass, handle invalid persisted token value below.
  }

  const token = parsedValue?.token ?? null
  const expiry = parsedValue?.expiry ?? null

  if (!token || !Number.isInteger(expiry)) {
    // Token was invalid or missing - clear value from persistence:
    window.localStorage.removeItem(StorageKey.FirebaseAuthToken)
  }

  return {
    token,
    expired: token ? _isTokenExpired(expiry) : false,
    isAuthenticatedWithFirebase: !!token,
    // presence of token in localStorage indicates user is authed via Firebase
    firebaseUserDisplayName: window.sessionStorage.getItem(StorageKey.FirebaseAuthUserName) ?? "",
  }
}

// Run a function on the _next_ auth-state-change event only,
// and don't run it for any subsequent auth-state-change events after that:
async function onNextAuthStateChangedOnly(handler: (user: FirebaseUser | null) => void): Promise<void> {
  const { firebaseAuth } = await safeImportFirebaseModules("firebaseAuth")
  const firebaseAuthInstance = await getFirebaseAuthInstance(firebaseAuth)

  if (!firebaseAuth?.onAuthStateChanged || !firebaseAuthInstance) {
    return // Dynamic import failed; page will reload imminently.
  }

  const unsubscribeOnAuthStateChanged = firebaseAuth.onAuthStateChanged(firebaseAuthInstance, (user) => {
    // Remove handler immediately to ensure that it's only ever executed once per call:
    unsubscribeOnAuthStateChanged()
    return handler(user)
  })
}

// Force a refresh the Firebase user ID token, and return a promise which
// resolves to the refreshed token - which will expire in 1 hour.
// Also sets the latest token and new expiry in localStorage.
function refreshFirebaseUserIdToken(token: string | null): Promise<string | null> {
  const previousToken = token ?? getFirebaseAuthInfo().token
  return new Promise((resolve: (token: string | null) => void) => {
    onNextAuthStateChangedOnly(async (user: FirebaseUser | null): Promise<void> => {
      if (!user) {
        return resolve(null)
      }

      const forceTokenRefresh = true // force refresh so we know next expiry time
      let refreshedToken

      const { firebaseAuth } = await safeImportFirebaseModules("firebaseAuth")

      if (!firebaseAuth?.getIdToken) {
        return resolve(null) // Dynamic import failed; page will reload imminently.
      }

      try {
        refreshedToken = await firebaseAuth?.getIdToken(user, forceTokenRefresh)
      } catch (exception: unknown) {
        const error = exception as Error | null
        if (/firebase.*\(auth\/network-request-failed\)/i.test(error?.message ?? "")) {
          // Gracefully handle "network request failed" errors since these can
          // happen arbitrarily when the network connection is interrupted.
          // This will generally result in a 401 (unauthenticated) request response
          // at which point our system will retry the token refresh and request.
          console.warn(error?.message)
          return resolve(null)
        } else {
          throw error
        }
      }

      if (!refreshedToken) {
        throw new Error("firebase.js: getIdToken unexpectedly did not produce a token")
      }

      const currentToken = getFirebaseAuthInfo().token

      // Ensure authed state hasn't changed since we started awaiting latest token:
      if (!previousToken || previousToken === currentToken) {
        _persistFirebaseUserIdTokenWithNewExpiry(refreshedToken)
        return resolve(refreshedToken)
      } else {
        return resolve(currentToken)
      }
    })
  })
}

// Get the `Authorization: "Bearer <token>"` value needed to
// authenticate request against our backend via Firebase Auth.
// Returns null if the current user doesn't use Firebase Auth.
// Otherwise, returns a promise which resolves to the header key/value with
// correct token, after awaiting refresh of the token if necessary.
function getFirebaseAuthRequestHeaderPromise({ forceTokenRefresh = false } = {}): Promise<{
  Authorization?: string
}> | null {
  let { isAuthenticatedWithFirebase, token, expired } = getFirebaseAuthInfo()
  if (!isAuthenticatedWithFirebase) {
    return null
  } else {
    return new Promise(async (resolve) => {
      if (isAuthenticatedWithFirebase) {
        if (expired || forceTokenRefresh) {
          // Attempt to refresh the Firebase user ID token if it was expired,
          // or if this is a retry due to request auth failure:
          try {
            token = await refreshFirebaseUserIdToken(token)
          } catch (e) {
            Sentry.captureException(e)
            token = null
          }
        }
        if (token) {
          return resolve({ Authorization: `Bearer ${token}` })
        } else {
          return resolve({})
        }
      }
    })
  }
}

// Sign out the current user from Firebase Auth, and clear ID token from localStorage.
async function signOutFirebaseUser(): Promise<void> {
  window.localStorage.removeItem(StorageKey.FirebaseAuthToken)
  const { firebaseAuth } = await safeImportFirebaseModules("firebaseAuth")
  const firebaseAuthInstance = await getFirebaseAuthInstance(firebaseAuth)

  if (!firebaseAuth?.signOut || !firebaseAuthInstance) {
    return // Dynamic import failed; page will reload imminently.
  }

  await firebaseAuth.signOut(firebaseAuthInstance)
}

// Initialize FirebaseUI using the provided HTML id as container element.
// `onSignIn` may be provided to execute code after user successfully auths.
async function initializeFirebaseUI(
  authContainerId: string,
  {
    ssoProviders = [],
    ssoRedirectLogin = false,
    onSignIn = null,
  }: {
    ssoProviders?: SSOProviderData[]
    ssoRedirectLogin?: boolean
    onSignIn?: (() => void) | null
  }
): Promise<FirebaseUIInstance | null> {
  console.info("[Firebase 0ms] Starting Firebase UI.")

  const startMs = Date.now()
  const time = () => Date.now() - startMs
  let isSignInComplete = false
  let currentUser: FirebaseUser | null = null
  let currentToken: string | null = null

  function completeSignInWithToken(token: string | null): void {
    console.info(`[Firebase ${time()}ms] Recieved user ID token, completing sign in...`)
    if (!isSignInComplete && token && !currentToken) {
      currentToken = token
      isSignInComplete = true
      _persistFirebaseUserIdTokenWithNewExpiry(token)
      onSignIn?.()
      console.info(`[Firebase ${time()}ms] Sign in completed.`)
    }
  }

  function completeSignInWithUser(user: FirebaseUser): void {
    if (!isSignInComplete && user) {
      _persistFirebaseUserName(user)
      console.info(`[Firebase ${time()}ms] Getting user ID token...`)
      if (!currentToken) {
        user.getIdToken().then(completeSignInWithToken)
      }
    }
  }

  const uiConfig = {
    signInFlow: ssoRedirectLogin ? "redirect" : "popup",
    signInOptions: ssoProviders,
    tosUrl: "https://risingteam.com/terms",
    privacyPolicyUrl: "https://risingteam.com/privacy",
    callbacks: {
      signInSuccessWithAuthResult: ({ user }: { user: FirebaseUser }) => {
        if (!currentUser && user) {
          console.info(`[Firebase ${time()}ms] signInSuccessWithAuthResult triggered for user:`, user)
          currentUser = user
          completeSignInWithUser(user)
        }
        return false
        // Return false to prevent redirect here; our component code
        // will redirect to "next" query param if it was specified.
      },
    },
  }

  const { firebaseAuth, firebaseUI } = await safeImportFirebaseModules("firebaseAuth", "firebaseUI")
  const firebaseAuthInstance = await getFirebaseAuthInstance(firebaseAuth)

  if (!firebaseAuth?.onAuthStateChanged || !firebaseUI?.auth || !firebaseAuthInstance) {
    return null // Dynamic import failed; page will reload imminently.
  }

  const ui = firebaseUI.auth.AuthUI.getInstance() ?? new firebaseUI.auth.AuthUI(firebaseAuthInstance)

  let isFirebaseUIStarted = false
  const firebaseUIStartTimeoutIds: Array<ReturnType<typeof setTimeout>> = []

  function delayedStartFirebaseUI({ delayMs = 0 } = {}): void {
    if (!isFirebaseUIStarted && !isSignInComplete) {
      firebaseUIStartTimeoutIds.push(
        setTimeout(() => {
          firebaseUIStartTimeoutIds.forEach(clearTimeout)
          // Avoid starting FirebaseUI multiple times:
          if (!isFirebaseUIStarted && !isSignInComplete) {
            console.info(`[Firebase ${time()}ms] starting Firebase UI... (after ${delayMs}ms delay)`)
            isFirebaseUIStarted = true
            ui.start(`#${authContainerId}`, uiConfig)
            console.info(`[Firebase ${time()}ms] Firebase UI successfully started (after ${delayMs}ms delay)`)
          }
        }, delayMs)
      )
    }
  }

  // FirebaseUI is started within onAuthStateChanged callback below, to ensure it
  // starts after Firebase Auth instance is initialized. Sometimes this callback
  // isn't called as expected, so also initialize after 500ms if we haven't already.
  delayedStartFirebaseUI({ delayMs: 500 })

  // Register "backup" completeSignInWithUser handler to help avoid long delays before
  // FirebaseUI calls signInSuccessWithAuthResult on some mobile devices:
  const unsubscribeOnAuthStateChanged = firebaseAuth.onAuthStateChanged(
    firebaseAuthInstance,
    (user: FirebaseUser | null): void => {
      // If no user is authed, start firebase UI:
      if (!user) {
        console.info(`[Firebase ${time()}ms] onAuthStateChanged triggered with null user`)
        delayedStartFirebaseUI({ delayMs: 0 })
      }

      // If user is authed, finish sign-in process:
      if (user && !currentUser) {
        console.info(`[Firebase ${time()}ms] onAuthStateChanged triggered for user:`, user)
        currentUser = user
        completeSignInWithUser(user)

        // After sign-in successfully completed, remove handler so we don't run it again:
        unsubscribeOnAuthStateChanged()
      }
    }
  )

  return ui
}

interface FirebaseRealtimeDatabaseResult {
  database: FirebaseDatabaseInstance | null
  ref: FirebaseDatabaseRef | null
  onValue: FirebaseDatabaseOnValue | null
}
async function getFirebaseRealtimeDatabase(): Promise<FirebaseRealtimeDatabaseResult> {
  // in test environment, skip Firebase integration entirely; see comment above
  if (!import.meta.env.VITE_APP_FIREBASE_API_KEY) {
    return { database: null, ref: null, onValue: null }
  }

  const { firebaseDB } = await safeImportFirebaseModules("firebaseDB")

  const firebaseAppInstance = await untilFirebaseAppInitialization()

  return {
    database: firebaseDB?.getDatabase?.(firebaseAppInstance) ?? null,
    ref: firebaseDB?.ref ?? null,
    onValue: firebaseDB?.onValue ?? null,
  }
}

export {
  initializeFirebaseApp,
  untilFirebaseAppInitialization,
  initializeFirebaseUI,
  getFirebaseAuthInfo,
  refreshFirebaseUserIdToken,
  getFirebaseAuthRequestHeaderPromise,
  signOutFirebaseUser,
  getFirebaseRealtimeDatabase,
}
