import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import Button from "./Button"
import Tooltip from "./Tooltip"
import View from "./View"

import { CopyIcon, CheckIcon } from "icons/FontAwesomeIcons"

const CopyButton = ({
  className,
  buttonClassName,
  clipboardText,
  children,
  title = null,
  hoverColor = "rising-orange", // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  onClickColor = "rising-orange",
  icon = false,
  iconCopied = false,
  tooltip = null,
  tooltipCopied = null,
}) => {
  const [copied, setCopied] = useState()

  const onClick = async () => {
    if (copied) {
      return // already copied to clipboard recently
      // return early to avoid weird timing issues between multiple copy operations
    }

    let copySuccessful

    if (!navigator.clipboard) {
      copySuccessful = false
    } else {
      try {
        await navigator.clipboard.writeText(clipboardText)
        copySuccessful = true
      } catch {
        copySuccessful = false
      }
    }

    // if copy was not successful using navigator.clipboard api, try fallback method:
    if (!copySuccessful) {
      var textArea = document.createElement("textarea")
      textArea.value = clipboardText
      textArea.style.top = "0" // avoid scrolling to bottom
      textArea.style.left = "0"
      textArea.style.position = "fixed"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand("copy")
        copySuccessful = true
      } catch (err) {
        alert("your browser may not support automatic copying, please select the code and copy manually")
      }
      document.body.removeChild(textArea)
    }

    setCopied(copySuccessful)
    setTimeout(() => setCopied(false), 3000)
  }

  const content = (
    <View className={cn(className, { icon })} $alignItems="center">
      <Button
        onClick={onClick}
        className={cn(buttonClassName, {
          "text-gray-7-important": !copied,
          [`text-${onClickColor}-important`]: !!copied,
        })}
        title={title ?? "Copy text"}
      >
        {!icon ? children : iconCopied && copied ? <CheckIcon /> : <CopyIcon />}
      </Button>
      {!!copied && !iconCopied && <span className="text-semi-bold">Copied!</span>}
    </View>
  )

  if (tooltipCopied && copied) {
    if (typeof tooltipCopied === "string") {
      return <Tooltip title={tooltipCopied}>{content}</Tooltip>
    } else {
      const TooltipCopiedComponent = tooltipCopied
      return <TooltipCopiedComponent>{content}</TooltipCopiedComponent>
    }
  } else if (tooltip) {
    if (typeof tooltip === "string") {
      return <Tooltip title={tooltip}>{content}</Tooltip>
    } else {
      const TooltipComponent = tooltip
      return <TooltipComponent>{content}</TooltipComponent>
    }
  } else {
    return content
  }
}

export default styled(CopyButton)`
  &.icon button {
    background: none;
    box-shadow: none;
    border: none;
    padding: 6px;
    height: fit-content;
    width: fit-content;
    transition: color 150ms ease;
    &:hover {
      color: ${({ hoverColor }) => `var(--${hoverColor})`} !important;
    }
  }
`
