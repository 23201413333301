import cn from "classnames"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import { styled } from "styled-components"

import View from "ui/View"

const SocialShareButtons = styled(function SocialShareButtons({ url, className }) {
  return (
    <View $justifyContent="center" className={cn("space-x-small", className)}>
      <LinkedinShareButton url={url} className="social-share-button">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <TwitterShareButton url={url} className="social-share-button">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} hashtag="#RiseTogether" className="social-share-button">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
    </View>
  )
})`
  .social-share-button {
    height: 32px;
    border-radius: 50%;
    box-shadow: var(--blur-2);

    &:hover {
      box-shadow: var(--lift-4);
    }
  }
`

export default SocialShareButtons
