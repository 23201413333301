import { Link } from "react-router-dom"

const AppreciationsTraining2 = ({ kitInstance, className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>
        Once you finish the Rising Team session on appreciation, we want to provide some ideas for how you can keep the
        appreciation flowing on a regular basis. Here are a few ideas you can try:
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">1. Keep people’s unique profiles top of mind</h3>
      <div className="py-xs">
        Remind yourself about each person’s preferences. As you saw in the Appreciations exercise results, each person
        on your team has very unique preferences about what feels best to them. By looking at each person’s combined
        answers, you can discover and apply appreciation in the way that best suits each person. For instance two people
        who both have visibility as their top preference may differ in whether they want that recognition in public or
        private. For one, a private note from an executive would be better, and for the other, a public mention by an
        executive in a company meeting would be preferred. Similarly, if two people both prefer handmade items, but one
        wants to be recognized for their results and the other for their effort, what you write to each of them in a
        personal note might change. You can always go back to your{" "}
        <Link to={`/team?team_id=${kitInstance.team_id}`}>Team Results tab</Link> to find each person’s unique profile.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">2. Be timely and specific</h3>
      <div className="py-xs">
        When people describe meaningful appreciation they refer to it as specific, relevant and sincere. If you want to
        recognize someone for good work, or living by your values, it’s better to let them know in detail what you
        appreciate, than to say something generic, like “great job.” Being specific lets them know you are paying close
        attention, and also helps them understand which behaviors they should try to replicate in the future. When it’s
        possible, try to “catch people in the act” of doing things well and show appreciation in real-time, so people
        know that you notice what they are doing. Sometimes you’ll want to take time to put together larger
        demonstrations of appreciation, but intermingling those with smaller, more timely and specific mentions of
        appreciation can be really helpful.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">3. Appreciate more frequently than you think you need to</h3>
      <div className="py-xs">
        Even when managers believe they are appreciating people frequently, employees often feel like they don’t get
        enough appreciation from their managers. In a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2020/01/the-little-things-that-make-employees-feel-appreciated"
        >
          recent piece
        </a>{" "}
        in Harvard Business Review, researchers outline how this may be due to “the illusion of transparency, or
        people’s tendency to overestimate how visible their emotions are to others.” They say that as managers, we often
        incorrectly assume our team members know how we feel about them, when actually they don’t. This would suggest
        that we should try to show appreciation more frequently and more directly than we might believe is necessary. In
        the Appreciations exercise, you saw that people have different preferences around frequency, but as long as you
        are specific and authentic, the research generally suggests that appreciation is an area where more is better
        than less.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">4. Use “other-praising” language</h3>
      <div className="py-xs">
        Researchers at the University of North Carolina have identified{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2016/06/stop-making-gratitude-all-about-you">
          two types of gratitude
        </a>
        : “Other-praising, which acknowledges and validates the actions of the giver, and self-benefit, which describes
        how the receiver is better off for having been helped.” They found that when people used this “other-praising”
        language—appreciation for what the other person did, rather than for the way it benefitted you—that it
        strengthened relationships more. “Other-praising gratitude was strongly related to perceptions of
        responsiveness, positive emotion, and loving— but self-benefit gratitude was not.”
      </div>
      <div className="py-xs">For example, try saying:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            “The creativity you brought to organizing our event was amazing; it made the event so fun and highlighted
            your unique ability to engage the team.”
          </li>
          <li>
            Instead of… “Thanks for organizing the team event; I had such a great time and could not have done it
            without you.”
          </li>
        </ul>
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">5. Gather thanks from customers</h3>
      <div className="py-xs">
        Another way to help your team feel deeply appreciated is to connect them directly with the customers who benefit
        from their work, whether those people are users of an app, patients in a practice, clients of an agency, etc.
        Hearing directly from beneficiaries that they value the work your team is doing can be really meaningful. In
        fact, a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.sciencedirect.com/science/article/abs/pii/S0749597806000641"
        >
          study
        </a>{" "}
        by Adam Grant and colleagues found that when callers who solicited alumni donations met with a student who
        benefited from the donations they secured, the callers raised 171% more money than those who didn’t meet the
        student. One important thing to note about that study is that a control group where people just read a letter
        from the beneficiary did not have the same effect, so the power seems to lie in the direct connection. That
        said, any connection to customers is better than none. You can experiment here with everything from in-person
        meetings to video calls.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">6. Have a gratitude party</h3>
      <div className="py-xs">
        During the COVID pandemic, there were many cities where people would gather at the same time each night to thank
        the essential workers for the hard work they were doing. People would clap, cheer, bang pots, all at the same
        time. There were several parts to this that made it so meaningful—the ritual, the community, and the
        celebration. These are all things that you can recreate in your own team.
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Set aside a time</span> - Plan it so people know what to expect and when. You
            can create a moment for appreciation at the end of certain regular meetings, or at a regular time each week,
            etc.
          </li>
          <li>
            <span className="text-bold">Gather the team</span> - You can go high-tech or low-tech when you do this,
            depending on your team’s preferences and location. Use the tools already in your routine like Slack or other
            Messaging apps, or try tools made just for this, like a Kudoboard or Padlet. Or if you want to go
            old-school, you can try envelopes for each person that people can drop notes into. The main idea here is to
            get the team all together to appreciate each other.
          </li>
          <li>
            <span className="text-bold">Use TeamMojis and Soundboard to celebrate!</span> - Spoiler alert that the bonus
            items for this kit include not only some great new TeamMojis to celebrate and thank people, but also a fun
            soundboard you can use to cheer on the team in any meetings (virtual or in person!).
          </li>
        </ul>
      </div>
      <div className="py-xs">
        That’s it! You’ve finished the training on cultivating appreciation and now you can move on to the preview and
        prep for your Rising Team session.
      </div>
      <div className="py-xs">
        If you want to keep learning more about this topic, here are some additional resources:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2020/01/the-little-things-that-make-employees-feel-appreciated"
            >
              The Little Things that Make Employees Feel Appreciated
            </a>{" "}
            (Harvard Business Review)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2019/11/why-employees-need-both-recognition-and-appreciation"
            >
              Why Employees Need Both Recognition and Appreciation
            </a>{" "}
            (Harvard Business Review)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier"
            >
              Giving thanks can make you happier
            </a>{" "}
            (Harvard Health Publishing)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.psychologytoday.com/us/blog/prefrontal-nudity/201211/the-grateful-brain"
            >
              The Grateful Brain
            </a>{" "}
            (Psychology Today)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2020/11/building-a-better-workplace-starts-with-saying-thanks"
            >
              Building a Better Workplace Starts with Saying “Thanks”
            </a>{" "}
            (Harvard Business Review)
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default AppreciationsTraining2
