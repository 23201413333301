import FeedbackPeerFramework from "domains/Exercise/ResultsComponents/FeedbackPeerFramework"

const FeedbackTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        Now that you’re clear on the value of effectively giving and receiving feedback, how do you help your team
        practice the skills they need to make it work? This Rising Team session includes 4 activities that will help
        your team become more comfortable and confident giving both positive and constructive feedback.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Flex your feedback muscle</h2>
      <p className="mb-medium">
        In this first activity, team members will practice framing and delivering positive feedback to each other. Each
        team member will be randomly assigned another team member to give feedback to. During the session you will get a
        chance to review the assignments and make changes if needed. Then each team member will be prompted to identify
        one action that team member took that had a positive impact and why they appreciate it. And finally each team
        member will take turns delivering their feedback to their assigned partner in front of the larger team.
      </p>
      <h2 className="text-rising-orange mb-medium">2. What would you do?</h2>
      <p className="mb-medium">
        This activity is about what to consider when deciding whether or not to give another team member feedback. Team
        members will each be presented with a unique scenario and questions about factors like timing, their intentions,
        and desired outcomes. After completing their assigned scenario, each person will get a chance to share their
        scenario, what they would do, and how they arrived at that decision.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Delivering constructive feedback</h2>
      <p className="mb-medium">
        This activity is designed to teach the team a repeatable framework for delivering constructive feedback called
        the PEER Framework. During this exercise, the team will be presented with a sample scenario and asked to prepare
        feedback using Rising Team’s PEER feedback framework. Then two volunteers will roleplay as the giver and
        receiver so the whole team can see the framework in action.
      </p>
      <p className="mb-medium">
        This framework is a helpful tool for remembering the elements that make feedback useful, and includes the
        following steps:
      </p>
      <FeedbackPeerFramework className="mb-medium" />
      <h2 className="text-rising-orange mb-medium">4. A real world example</h2>
      <p className="mb-medium">
        The final step in this activity is a real-world application. Team members will be asked to think of a real
        person they’re considering giving constructive feedback to. They’ll think of a scenario in their own life (at
        work or outside of work) where they want to give feedback. Then they’ll use the PEER framework to prepare
        feedback that can be delivered after the session.
      </p>
      <p className="mb-medium">Note that this will be a personal exercise that will not be shared with the team.</p>
      <p className="mb-medium">A few things to keep in mind:</p>
      <div className="mb-medium">
        <ul>
          <li className="ml-xl">
            <h3 className="mb-medium">Giving feedback triggers unique responses</h3>
          </li>
          <p className="mb-medium">
            Everyone has their own experiences with giving and receiving feedback. Some will demonstrate more skill or
            comfort while participating in the activities. Your role is to create a safe environment where everyone,
            regardless of their comfort level or starting place, can openly share and practice.
          </p>
          <li className="ml-xl">
            <h3 className="mb-medium">Be a role model</h3>
          </li>
          <p className="mb-medium">
            This month’s activities are designed to spark a team conversation and bring new awareness of personal
            triggers that might prevent the team from giving and receiving feedback in the future. As the team leader,
            you have an opportunity to model by openly sharing your own experiences and reactions and even opportunities
            to improve. This will encourage others to do the same. The more vulnerability you’re able to demonstrate,
            the more trust will be built, and the safer team members will feel sharing their own comfort level and
            preferences around feedback.
          </p>
          <li className="ml-xl">
            <h3 className="mb-medium">Strive for incremental improvements</h3>
          </li>
          <p className="mb-medium">
            Creating a feedback culture takes time and practice. Since everyone will be coming to the meeting with
            different experiences, skill sets, and comfort levels with feedback, it’s important to strive for
            improvement over time. Some team members will progress faster than others, but what’s most important is that
            the team gets aligned on the importance of building a feedback culture and that they each understand they
            have a part to play.
          </p>
          <p className="mb-medium">
            You’ll be able to see a quick preview of how the team session will run, and try out the feedback exercises
            for yourself. In our last training article we’ll cover how you can accelerate building a feedback culture
            and sustain it over the long term.
          </p>
        </ul>
      </div>
    </div>
  </div>
)

export default FeedbackTraining2
