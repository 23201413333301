import cn from "classnames"
import { Form, Formik } from "formik"
import { styled } from "styled-components"

import { isValidMessageText } from "../utils"

import ArtiPopularTopicsSelect from "./ArtiPopularTopicsSelect"
import { ArtiTourStepNames } from "./ArtiTour"

import TextareaField from "forms/fields/TextareaField"
import FormMessage from "forms/FormMessage"
import { PaperPlaneTopIcon } from "icons/FontAwesomeIcons"
import useWindowSize from "ui/hooks/useWindowSize"
import SubmitButton from "ui/SubmitButton"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { onKeyEvent } from "utils/browser"

const ArtiChatInputArea = styled(function ArtiChatInputArea({
  loading,
  isComponentMounted,
  onSubmit,
  chatInputRef,
  messages,
  user,
  team,
  selectedMember,
  artiTourPopularTopicsMenuOpen,
  conversationTypeSelected,
  className,
}) {
  const { isMobileLandscapeOrSmaller } = useWindowSize()

  function onPopularTopicSelect({ value, formik }) {
    if (
      !isValidMessageText(formik.values?.message) ||
      window.confirm("Are you sure? This will clear your current prompt text.")
    ) {
      requestAnimationFrame(() => {
        if (isComponentMounted) {
          formik.setValues({ message: value, popularTopic: null })
          chatInputRef.current?.focus()
        }
      })
    }
  }

  return (
    <div className={className}>
      <Formik initialValues={{ message: "", popularTopic: null }} onSubmit={onSubmit}>
        {(formik) => (
          <Form className={cn("space-y-medium fadeable", { "non-interactive-and-faded": loading })}>
            <View $alignItems="center" $flexDirectionMobile="column">
              <div
                className="query-editor full-width mb-medium-mobile-only"
                data-arti-tour-step={ArtiTourStepNames.QueryEditor}
              >
                <View $alignItems="stretch">
                  <TextareaField
                    inputRef={chatInputRef}
                    name="message"
                    className="query-editor-textarea p-medium pr-none cursor-text"
                    data-testid="arti-query-editor"
                    placeholder="What would you like to discuss?"
                    size="full-width"
                    onKeyPress={onKeyEvent(
                      "enter",
                      () => onSubmit(formik.values, formik),
                      { shift: true, preventDefault: true }
                      // preventDefault avoids extra newline in textarea
                    )}
                  />
                  <div
                    className="query-editor-submit-container m-medium ml-none cursor-text"
                    onClick={() => chatInputRef.current?.focus()}
                  >
                    <View $alignItems="center" className="full-height">
                      <Tooltip title="Enter a message" disabled={isValidMessageText(formik.values?.message)}>
                        <SubmitButton
                          className="full-width-mobile-only link submit-button"
                          disabled={!isValidMessageText(formik.values?.message)}
                          labelSubmitting={false}
                          data-testid="arti-query-submit"
                        >
                          <PaperPlaneTopIcon className="fa-2x" />
                        </SubmitButton>
                      </Tooltip>
                    </View>
                  </div>
                </View>
                <div
                  className={cn(
                    "query-help-text text-gray-6 text-italic non-interactive pr-large pb-medium hide-on-mobile",
                    { hidden: !isValidMessageText(formik.values?.message) }
                  )}
                  style={messages.length > 1 ? { bottom: 0 } : {}}
                >
                  shift + enter to send
                </div>
                {messages.length === 1 && (
                  <ArtiPopularTopicsSelect
                    name="popularTopic"
                    user={user}
                    team={team}
                    selectedMember={selectedMember}
                    conversationTypeSelected={conversationTypeSelected}
                    width="100%"
                    alignCenter
                    formik={formik}
                    placeholder={isMobileLandscapeOrSmaller ? "Popular topics" : "Popular topics · type to search"}
                    onSelect={onPopularTopicSelect}
                    borderRadius="0 0 var(--border-radius) var(--border-radius)"
                    borderRadiusOpen="0"
                    {...(artiTourPopularTopicsMenuOpen
                      ? {
                          menuIsOpen: true,
                          disabled: true,
                          fadeWhenDisabled: false,
                          menuHeight: isMobileLandscapeOrSmaller ? 140 : 180,
                        }
                      : {})}
                    key={
                      artiTourPopularTopicsMenuOpen
                      // fixes an issue where popular-topics menu doesn't re-render
                      // when exiting arti tour, leaving it open unintentionally
                    }
                  />
                )}
              </div>
            </View>
            <FormMessage />
          </Form>
        )}
      </Formik>
    </div>
  )
})`
  .query-editor {
    position: relative;
    background: var(--orange-4);
    border-radius: 8px;
  }

  .query-editor-textarea {
    margin-right: calc(-1 * var(--spacing-6));
  }

  .query-editor-textarea > * {
    padding-bottom: var(--spacing-3);
    padding-right: calc(var(--spacing-6) + var(--spacing-2));
    // above puts --spacing-2 space between text and submit button
  }

  .query-editor-submit-container {
    z-index: 1; // without this, textarea will cover submit button, hiding it
  }

  .query-help-text {
    position: absolute;
    bottom: var(--spacing-7);
    right: var(--spacing-1);
  }

  .submit-button {
    color: var(--gray-7);
    &:hover {
      color: var(--rising-orange);
    }
    opacity: 1;
    &:disabled {
      opacity: 0.2;
    }
  }

  .query-help-text,
  .submit-button {
    transition: opacity 0.2s ease, color 0.1s ease;
  }
`

export default ArtiChatInputArea
