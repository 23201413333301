import * as Sentry from "@sentry/browser"
import { Formik } from "formik"
import { round, sumBy } from "lodash-es"
import { useRef, useState } from "react"
import { styled } from "styled-components"

import ToggleSwitchField from "forms/fields/ToggleSwitchField"
import { TriangleIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"
import { isProductionEnv } from "utils/env"
import { formatDollarAmount } from "utils/number"
import { plural } from "utils/string"

const SubscribeModal = styled(function SubscribeModal({
  className,
  accountOffer,
  selectedMonthly,
  originalDollarAmount,
  onOfferSelected,
}) {
  const { isTabletOrLarger } = useWindowSize()
  const [numMembers, setNumMembers] = useState(1)
  const [annual, setAnnual] = useState(!selectedMonthly)
  const monthlySubtotal = originalDollarAmount * numMembers
  const yearlySubtotal = monthlySubtotal * 12
  const subtotal = !annual ? monthlySubtotal : yearlySubtotal

  const annualPerPersonMonthlyDiscount = round(originalDollarAmount / 4, 2)
  const annualDiscount = annualPerPersonMonthlyDiscount * numMembers * 12

  const discounts = accountOffer.discounts ?? []
  discounts.forEach((discount) => {
    const perPersonDiscount = !annual ? discount.monthly_discount : discount.annual_per_month_discount * 12
    discount.total_dollar_discount = perPersonDiscount * numMembers
  })
  const totalPerPersonMonthlyDiscount = !annual
    ? sumBy(discounts, "monthly_discount")
    : annualPerPersonMonthlyDiscount + sumBy(discounts, "annual_per_month_discount")
  const totalMonthlyDollarDiscount = totalPerPersonMonthlyDiscount * numMembers
  const totalDollarDiscount = !annual ? totalMonthlyDollarDiscount : totalMonthlyDollarDiscount * 12

  const todaysTotal = subtotal - totalDollarDiscount
  const perMonthTotalAmount = !annual ? todaysTotal : round(todaysTotal / 12, 2)

  const calculatedPerUserAmount = round(perMonthTotalAmount / numMembers, 2)
  const accountOfferPerUserAmount = !annual ? accountOffer.monthly_dollar_amount : accountOffer.yearly_dollar_amount
  if (calculatedPerUserAmount !== accountOfferPerUserAmount) {
    const errorMessage =
      `Discrepancy between calculated amount ${calculatedPerUserAmount} and specified amount ${accountOfferPerUserAmount} ` +
      `for accountOffer ${accountOffer.name} with discounts ${JSON.stringify(accountOffer.discounts)}`
    if (isProductionEnv()) {
      Sentry.captureMessage(errorMessage)
    } else {
      throw errorMessage
    }
  }

  const onClick = async () => {
    // TODO: need to work on where this is directing
    await onOfferSelected({ yearly: annual, numMembers, productTier: accountOffer.product_tier })
  }

  return (
    <View className={className} $flexDirection="column">
      <View className="mb-small mt-large" $flexDirection={isTabletOrLarger ? "row" : "column"}>
        <View $flexDirection="column">
          <div className="question-text text-semi-bold">Two quick questions:</div>
          <NumMembersDropdown
            className="my-xl"
            numMembers={numMembers}
            setNumMembers={setNumMembers}
            isTabletOrLarger={isTabletOrLarger}
          />
          <p className="offer-text text-thin">Do you want to save 25% with annual billing?</p>
          <View $flexWrap="nowrap" className="mt-medium mb-xl">
            <label htmlFor="annual" className="mr-small" onClick={() => setAnnual(false)}>
              <View $justifyContent="center" $flexWrap="wrap">
                <span>Monthly</span>
              </View>
            </label>
            <Formik>
              <ToggleSwitchField name="annual" evergreen checked={annual} saveOnChange={() => setAnnual(!annual)} />
            </Formik>
            <label htmlFor="annual" onClick={() => setAnnual(true)}>
              <View $justifyContent="center" $flexWrap="wrap">
                <span className="mr-small">Annual</span>
              </View>
            </label>
          </View>
        </View>
        <View $flexDirection="column">
          <div className="bg-gray-1 full-width p-large">
            <div className="summary-text text-semi-bold">You’re all set!</div>
            <div className="summary-text">
              {!annual ? "Monthly" : "Annual"} subscription for {plural(numMembers, "employee")}
            </div>
            <View $justifyContent="space-between" $alignItems="center">
              <div className="summary-text">Price / employee</div>
              <div>
                ${formatDollarAmount(originalDollarAmount)} x {numMembers}
              </div>
            </View>
            <View $justifyContent="space-between" $alignItems="center">
              <div className="summary-text">Subtotal</div>
              <div>${formatDollarAmount(subtotal)}</div>
            </View>
            <View $justifyContent="space-between" $alignItems="center">
              <div className="summary-text text-bold">{!annual ? <>&nbsp;</> : "Annual 25% discount"}</div>
              <div className="text-green-2 text-semi-bold">
                {!annual ? <>&nbsp;</> : `-$${formatDollarAmount(annualDiscount)}`}
              </div>
            </View>
            {discounts.map((discount) => (
              <View key={discount.name} $justifyContent="space-between" $alignItems="center">
                <div className="summary-text text-bold">{discount.name}</div>
                <div className="text-green-2 text-semi-bold">
                  -${formatDollarAmount(discount.total_dollar_discount)}
                </div>
              </View>
            ))}
          </div>
          <View className="px-large pt-small" $justifyContent="space-between" $alignItems="center">
            <div className="summary-text text-bold">Today’s total:</div>
            <div className="summary-text text-bold">${formatDollarAmount(todaysTotal)}</div>
          </View>
          <View $justifyContent="flex-end">
            <div className="summary-text text-bold text-gray-8 px-large">
              {!annual ? <>&nbsp;</> : `$${formatDollarAmount(perMonthTotalAmount)} per month`}
            </div>
          </View>
        </View>
      </View>
      <Button onClick={onClick} className="full-width">
        Continue
      </Button>
    </View>
  )
})`
  .question-text {
    font-size: 1.5rem; // ~24px
    line-height: 2rem;
  }

  .offer-text {
    font-size: 1rem; // ~16px
    line-height: 1.5rem;
  }

  .summary-text {
    font-size: 1rem; // ~16px
    line-height: 1.875rem;
  }
`

const NumMembersDropdown = styled(function NumMembersDropdown({ numMembers, setNumMembers, className }) {
  const inputRef = useRef()

  const onQuantityChange = (e) => {
    const quantity = parseInt(e.target.value)
    if (!quantity) {
      setNumMembers(1)
    } else {
      setNumMembers(quantity)
    }
  }

  const inputUpdate = (isIncrement) => {
    if (isIncrement) {
      inputRef.current.stepUp()
    } else {
      inputRef.current.stepDown()
    }
    setNumMembers(Number(inputRef.current.value))
  }

  return (
    <View className={className} $flexDirection="column">
      <span className="label-text mb-xs">How many employees?</span>
      <View className="num-teams-select border border-radius">
        <input ref={inputRef} value={numMembers} onChange={onQuantityChange} type="number" min={1} />
        <View $flexDirection="column" $alignItems="center" $justifyContent="center" $width="none" className="mr-medium">
          <button className="triangle-button" onClick={() => inputUpdate(true)}>
            <TriangleIcon color="var(--gray-6)" className="triangle-icon" />
          </button>
          <button className="triangle-button" onClick={() => inputUpdate(false)}>
            <TriangleIcon color="var(--gray-6)" className="triangle-icon fa-rotate-180" />
          </button>
        </View>
      </View>
    </View>
  )
})`
  .label-text {
    font-size: 1rem; // ~16px
    line-height: 1.5rem;
  }

  .num-teams-select {
    width: 65%;
  }

  input[type="number"] {
    color: var(--gray-8);
    border-radius: 3px;
    padding: 0;
    padding-left: 15px;
    border: none;
    width: 100%;
    height: 36px;
    background: none;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;

    /* hide up/down arrows - Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    /* hide up/down arrows - Firefox */
    appearance: textfield;
  }

  .triangle-button {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    min-height: 12px;
    height: 12px;
    margin: 1px 0;
  }

  .triangle-icon {
    width: 12px;
    height: 12px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .num-teams-select {
      width: 100%;
    }
  }
`

export default SubscribeModal
