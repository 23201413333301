import cn from "classnames"

import { CheckIcon } from "icons/FontAwesomeIcons"
import Fireworks from "ui/Fireworks"
import PageTitle from "ui/PageTitle"
import View from "ui/View"

const EngagementSurveyComplete = ({ className }) => (
  <div className={cn("main-container full-width text-gray-9", className)}>
    <PageTitle>Team engagement survey</PageTitle>
    <h1 className="mb-large">Team engagement survey</h1>
    <View className="mb-small">
      <CheckIcon color="var(--rising-green)" className="fa-2x mr-medium" />
      <p>Survey complete!</p>
    </View>
    <p>
      Thank you for your time. Your participation is important to help our team understand what is and isn’t working and
      to learn and grow together.
    </p>
    <Fireworks />
  </div>
)
export default EngagementSurveyComplete
