import cn from "classnames"

const SetupStep = ({ className, steps, step, subStepIndex = null }) => {
  const { components, ...stepProps } = step
  const StepComponent = components[subStepIndex ?? 0]
  const stepMenuButtonHeight = 50 * steps.length
  const stepMenuMarginHeight = 16 * (steps.length - 1)
  const minHeight = stepMenuButtonHeight + stepMenuMarginHeight
  // minHeight of step container should match height of adjacent step button menu
  // each button is 50px tall, with 16px margin below
  return (
    <div className={cn(className, "lift-4 border-radius p-large mb-large")} style={{ minHeight }}>
      <StepComponent steps={steps} {...stepProps} index={subStepIndex} />
    </div>
  )
}

export default SetupStep
