import { styled } from "styled-components"

const ProgressBar = ({ className, max, value }) => <progress className={className} max={max} value={value}></progress>

export default styled(ProgressBar)`
  border: 0;
  background: var(--gray-1);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 30px;
  box-shadow: inset 0 0 2px rgb(0 0 0 / 20%);
  height: 10px;

  &::-moz-progress-bar {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 30px;
    background: linear-gradient(258.26deg, #fb6211 28%, #f97830 62.68%, #f8d313 94.29%);
  }

  &::-webkit-progress-bar {
    border: 0;
    background: var(--gray-1);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 30px;
    box-shadow: inset 0 0 2px rgb(0 0 0 / 20%);
  }

  &::-webkit-progress-value {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 30px;
    background: linear-gradient(258.26deg, #fb6211 28%, #f97830 62.68%, #f8d313 94.29%);
  }
`
