import * as FONT_AWESOME_ICONS from "./FontAwesomeIcons"
import PieSliceNoneIcon from "./PieSliceNoneIcon"
import PieSliceOneIcon from "./PieSliceOneIcon"

import { getOrError } from "utils/object"

// We only allow usage of certain icons via blocks, because we want to
// gradually move towards a setup where most icons in use are FontAwesome icons.
// Add exceptions here for certain icons that are absolutely necessary:
const blockIcons = {
  ...FONT_AWESOME_ICONS,
  PieSliceNoneIcon,
  PieSliceOneIcon,
}

export { blockIcons }

export default function getIconOrError(name) {
  const icons = {
    PieSliceNoneIcon,
    PieSliceOneIcon,
    ...FONT_AWESOME_ICONS,
  }
  return getOrError(icons, name, "Icon not found")
}
