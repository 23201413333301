import { Route, Routes, Navigate, Outlet } from "react-router-dom"

import TeamCodeHome from "./TeamCodeHome"
import TeamCodeProfile from "./TeamCodeProfile"
import TeamCodeRegistration from "./TeamCodeRegistration"

import { PrivateRoute } from "domains/Authentication/Routes"
import View from "ui/View"

const TeamCodeLayout = () => (
  <View $flex={1} $flexDirection="column" className="main-container">
    <Outlet />
  </View>
)

const TeamCode = () => (
  <Routes>
    <Route element={<TeamCodeLayout />}>
      <Route path="/" element={<TeamCodeHome />} />
      <Route path="/:sessionCode/registration" element={<TeamCodeRegistration />} />
      <Route path="/:sessionCode" element={<TeamCodeHome />} />
      <Route element={<PrivateRoute />}>
        <Route path="/profile" element={<TeamCodeProfile />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  </Routes>
)

export default TeamCode
