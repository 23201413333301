function SvgLogoTextIcon(props) {
  return (
    <svg width="145" height="35" viewBox="0 0 145 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.3182 20.068H10.4854C10.2925 20.068 10.196 20.2136 10.196 20.3592V23.7087C10.196 23.9029 10.0513 24 9.90666 24H6.28938C6.09646 24 6 23.8544 6 23.7087V10.6019C6 9.72816 6.72346 9 7.5916 9H12.8487C14.1991 9 15.3567 9.24272 16.3695 9.67961C17.3824 10.1165 18.1058 10.7476 18.6846 11.6214C19.2151 12.4466 19.5045 13.4175 19.5045 14.5825C19.5045 15.6505 19.2633 16.6214 18.7328 17.3981C18.2987 18.1262 17.6717 18.7087 16.8518 19.1456C16.7071 19.2427 16.6589 19.4369 16.7554 19.5825L19.4563 23.5146C19.6009 23.7087 19.4563 24 19.2151 24H15.4531C15.3567 24 15.2602 23.9515 15.212 23.8544L12.704 20.165C12.5111 20.1165 12.4146 20.068 12.3182 20.068ZM14.4885 12.9806C14.0545 12.5922 13.3792 12.3981 12.5111 12.3981H10.4854C10.2925 12.3981 10.196 12.5437 10.196 12.6893V16.4757C10.196 16.6699 10.3407 16.767 10.4854 16.767H12.5111C13.3792 16.767 14.0062 16.5728 14.4885 16.1845C14.9226 15.7961 15.1638 15.2621 15.1638 14.5825C15.1638 13.9029 14.9226 13.3689 14.4885 12.9806Z"
        fill="#484848"
      />
      <path
        d="M29.5346 23.6627C28.7147 23.4684 28.0395 23.2255 27.509 22.934C27.3643 22.8368 27.316 22.6911 27.3643 22.5453L28.3289 20.3105C28.4253 20.1648 28.6183 20.0676 28.763 20.1648C29.2453 20.4077 29.7276 20.602 30.3546 20.7963C31.078 20.9907 31.8015 21.0878 32.5249 21.0878C33.2001 21.0878 33.6342 21.0392 33.9236 20.8935C34.213 20.7477 34.3577 20.5534 34.3577 20.3105C34.3577 20.0676 34.213 19.8733 33.8754 19.7761C33.5378 19.6789 33.0072 19.5818 32.2838 19.4846C31.3674 19.3874 30.5957 19.2417 29.9205 19.0473C29.2935 18.853 28.7147 18.5129 28.2324 18.0271C27.7501 17.5413 27.509 16.8125 27.509 15.938C27.509 15.2093 27.7501 14.5291 28.1842 13.9461C28.6183 13.3631 29.2453 12.9259 30.1134 12.5858C30.9333 12.2457 31.9462 12.1 33.1519 12.1C33.9718 12.1 34.84 12.1971 35.6599 12.3429C36.3833 12.4886 36.9621 12.683 37.4444 12.9259C37.5891 12.9745 37.6373 13.1688 37.5891 13.3146L36.6245 15.5008C36.5763 15.6465 36.3833 15.7437 36.1904 15.6465C35.274 15.1607 34.2612 14.9664 33.1519 14.9664C32.5249 14.9664 32.0426 15.0635 31.7532 15.2093C31.4639 15.355 31.3192 15.5494 31.3192 15.7923C31.3192 16.0838 31.4638 16.2295 31.8015 16.3753C32.1391 16.4724 32.6696 16.5696 33.4413 16.7154C34.4059 16.8611 35.1776 17.0069 35.8046 17.2012C36.4316 17.3955 36.9621 17.7356 37.4444 18.2214C37.9267 18.7073 38.1196 19.3874 38.1196 20.3105C38.1196 21.0392 37.8785 21.6708 37.4444 22.2538C37.0103 22.8368 36.3351 23.2741 35.5152 23.6141C34.647 23.9542 33.6342 24.1 32.4285 24.1C31.4156 24.0028 30.451 23.8571 29.5346 23.6627Z"
        fill="#484848"
      />
      <path
        d="M57.4618 13.4992C58.3299 14.3918 58.764 15.681 58.764 17.4662V23.9124C58.764 24.1108 58.6193 24.21 58.4746 24.21H55.0503C54.8574 24.21 54.7609 24.0612 54.7609 23.9124V18.1604C54.7609 16.524 54.1339 15.7306 52.8799 15.7306C52.2047 15.7306 51.6259 15.9786 51.1919 16.4248C50.7578 16.8711 50.5649 17.6149 50.5649 18.5075V23.9124C50.5649 24.1108 50.4202 24.21 50.2755 24.21H46.8511C46.6582 24.21 46.5618 24.0612 46.5618 23.9124V12.7058C46.5618 12.5075 46.7065 12.4083 46.8511 12.4083H50.0826C50.2755 12.4083 50.372 12.5571 50.372 12.7058V13.6976C50.806 13.2017 51.3848 12.8546 52.0118 12.6067C52.6388 12.3587 53.314 12.21 54.0375 12.21C55.4361 12.21 56.5936 12.6562 57.4618 13.4992Z"
        fill="#484848"
      />
      <path
        d="M73.5691 12.4414C73.762 12.4414 73.8585 12.5863 73.8585 12.7312V21.8592C73.8585 23.9842 73.2797 25.6263 72.1222 26.6888C70.9647 27.7513 69.2766 28.2826 67.1063 28.2826C65.9487 28.2826 64.8877 28.1377 63.9231 27.8962C63.0549 27.6547 62.2832 27.365 61.608 26.9303C61.4633 26.8337 61.4151 26.6888 61.5116 26.5439L62.6691 24.274C62.7655 24.1291 62.9585 24.0808 63.1032 24.1774C63.5372 24.4672 64.0195 24.7087 64.5983 24.8536C65.3217 25.095 65.997 25.1916 66.7204 25.1916C67.7815 25.1916 68.5532 24.9502 69.0837 24.4672C69.566 23.9842 69.8072 23.3081 69.8072 22.3905V22.0041C69.0355 22.9217 67.8779 23.3564 66.3828 23.3564C65.37 23.3564 64.4054 23.1149 63.5372 22.6802C62.6691 22.1973 61.9939 21.5694 61.4633 20.7001C60.9328 19.8791 60.6917 18.8648 60.6917 17.754C60.6917 16.6432 60.9328 15.6773 61.4633 14.8562C61.9939 14.0352 62.6691 13.3591 63.5372 12.8761C64.4054 12.3931 65.37 12.2 66.3828 12.2C68.0226 12.2 69.2284 12.7312 70.0001 13.7937V12.6829C70.0001 12.4897 70.1448 12.3931 70.2895 12.3931H73.5691V12.4414ZM69.1319 19.4927C69.6142 19.058 69.8554 18.4785 69.8554 17.8023C69.8554 17.1262 69.6142 16.5466 69.1319 16.1119C68.6496 15.6773 68.0709 15.4841 67.3474 15.4841C66.624 15.4841 65.997 15.6773 65.5147 16.1119C65.0324 16.5466 64.7912 17.1262 64.7912 17.8023C64.7912 18.4785 65.0324 19.058 65.5147 19.4927C65.997 19.9273 66.5757 20.1688 67.2992 20.1688C68.0709 20.1688 68.6496 19.9273 69.1319 19.4927Z"
        fill="#484848"
      />
      <path
        d="M40.1452 12.4529H43.5695C43.7624 12.4529 43.8589 12.5997 43.8589 12.7465V23.8063C43.8589 24.0021 43.7142 24.0999 43.5695 24.0999H40.1452C39.9522 24.0999 39.8558 23.9531 39.8558 23.8063V12.7465C39.8076 12.5997 39.9522 12.4529 40.1452 12.4529Z"
        fill="#484848"
      />
      <path
        d="M40.3405 10.6469C39.9546 10.3188 39.7617 9.85001 39.7617 9.38125C39.7617 8.9125 39.9546 8.44374 40.3405 8.11561C40.7263 7.78748 41.2086 7.59998 41.7874 7.59998C42.3662 7.59998 42.8967 7.7406 43.2343 8.06873C43.6202 8.39686 43.8131 8.81874 43.8131 9.33438C43.8131 9.85001 43.6202 10.3188 43.2343 10.6469C42.8485 10.975 42.3662 11.1625 41.7874 11.1625C41.2086 11.1625 40.7263 10.975 40.3405 10.6469Z"
        fill="#484848"
      />
      <path
        d="M22.1452 12.1H25.5695C25.7624 12.1 25.8589 12.2512 25.8589 12.4025V23.7975C25.8589 23.9991 25.7142 24.1 25.5695 24.1H22.1452C21.9522 24.1 21.8558 23.9487 21.8558 23.7975V12.4025C21.8076 12.2512 21.9522 12.1 22.1452 12.1Z"
        fill="#484848"
      />
      <path
        d="M22.3405 10.7392C21.9546 10.4012 21.7617 9.91819 21.7617 9.43523C21.7617 8.95227 21.9546 8.46931 22.3405 8.13123C22.7263 7.79316 23.2086 7.59998 23.7874 7.59998C24.3662 7.59998 24.8967 7.74486 25.2343 8.08294C25.6202 8.42101 25.8131 8.85568 25.8131 9.38694C25.8131 9.91819 25.6202 10.4012 25.2343 10.7392C24.8485 11.0773 24.3662 11.2705 23.7874 11.2705C23.2086 11.2705 22.7263 11.0773 22.3405 10.7392Z"
        fill="#484848"
      />
      <path
        d="M104.224 19.0931H95.8322C96.0251 19.6761 96.3145 20.1619 96.845 20.4534C97.3273 20.7935 97.9543 20.9393 98.6778 20.9393C99.2083 20.9393 99.6906 20.8421 100.125 20.6964C100.462 20.5506 100.8 20.3563 101.138 20.1134C101.282 20.0162 101.427 20.0162 101.572 20.1134L103.308 21.9109C103.404 22.0567 103.404 22.251 103.308 22.3482C102.199 23.4656 100.607 24 98.5813 24C97.2309 24 96.0251 23.7571 95.0123 23.2227C93.9995 22.6883 93.1795 22.0081 92.649 21.085C92.0703 20.1619 91.8291 19.1417 91.8291 17.9757C91.8291 16.8097 92.1185 15.7895 92.649 14.915C93.1795 13.9919 93.9512 13.3117 94.9158 12.7773C95.8804 12.2429 96.9897 12 98.1955 12C99.353 12 100.366 12.2429 101.33 12.7287C102.247 13.2146 103.019 13.8947 103.549 14.8178C104.08 15.7409 104.369 16.8097 104.369 18.0243C104.321 18.0729 104.273 18.413 104.224 19.0931ZM96.6039 15.4494C96.1698 15.7895 95.9287 16.2753 95.784 16.9069H100.511C100.414 16.2753 100.125 15.7895 99.6906 15.4494C99.2566 15.1093 98.726 14.915 98.1473 14.915C97.5685 14.915 96.9897 15.1093 96.6039 15.4494Z"
        fill="#484848"
      />
      <path
        d="M115.173 13.2716C116.186 14.1493 116.668 15.5148 116.668 17.3191V23.5123C116.668 23.7074 116.523 23.8049 116.379 23.8049H113.244C113.051 23.8049 112.954 23.6586 112.954 23.5123V22.2932C112.376 23.4148 111.218 24 109.53 24C108.614 24 107.842 23.8537 107.215 23.5123C106.54 23.2198 106.057 22.7809 105.72 22.2444C105.382 21.708 105.238 21.0741 105.238 20.3913C105.238 19.2697 105.672 18.4407 106.492 17.8068C107.311 17.1728 108.614 16.8802 110.35 16.8802H112.279C112.472 16.8802 112.617 16.7339 112.569 16.5389C112.376 15.5148 111.556 15.0271 110.157 15.0271C109.627 15.0271 109.048 15.1247 108.469 15.3197C108.035 15.466 107.649 15.6123 107.311 15.8561C107.167 15.9537 106.926 15.9049 106.877 15.7586L105.816 13.5642C105.768 13.4179 105.768 13.2228 105.913 13.174C106.492 12.8327 107.215 12.5401 107.987 12.345C108.903 12.1012 109.819 12.0037 110.736 12.0037C112.713 11.9549 114.16 12.3938 115.173 13.2716ZM111.893 21.0741C112.231 20.8302 112.52 20.4889 112.665 20.05V19.0259H110.929C109.771 19.0259 109.144 19.416 109.144 20.1963C109.144 20.5376 109.289 20.8302 109.53 21.0741C109.819 21.2691 110.157 21.4154 110.639 21.4154C111.122 21.4154 111.507 21.3179 111.893 21.0741Z"
        fill="#484848"
      />
      <path
        d="M92.0808 9H79.2465C79.0521 9 78.9548 9.14611 78.9548 9.29221V12.0682C78.9548 12.263 79.1007 12.3604 79.2465 12.3604H83.2329C83.4274 12.3604 83.5246 12.5065 83.5246 12.6526V23.7078C83.5246 23.9026 83.6705 24 83.8163 24H87.4624C87.6569 24 87.7541 23.8539 87.7541 23.7078V12.7013C87.7541 12.5065 87.8999 12.4091 88.0458 12.4091H92.0322C92.2267 12.4091 92.3239 12.263 92.3239 12.1169V9.34091C92.4211 9.1461 92.2753 9 92.0808 9Z"
        fill="#484848"
      />
      <path d="M138.952 19.3H134.9V21.4251H138.952V19.3Z" fill="#F79025" />
      <path
        d="M134.901 18.595H138.952V17.2562C138.952 15.4711 138.518 14.1818 137.698 13.2893C136.83 12.3967 135.721 12 134.322 12C133.502 12 132.73 12.1488 132.055 12.4959C131.476 12.7438 130.994 13.0909 130.608 13.5372C130.464 13.6859 130.222 13.6859 130.078 13.5372C129.74 13.0909 129.306 12.7438 128.776 12.4959C128.149 12.1983 127.425 12.0496 126.605 12.0496C125.93 12.0496 125.255 12.1488 124.676 12.3967C124.097 12.6446 123.567 12.9917 123.133 13.4876V12.4959C123.133 12.2975 122.988 12.1488 122.795 12.1488H119.612C119.419 12.1488 119.274 12.2975 119.274 12.4959V23.6529C119.274 23.8512 119.419 24 119.612 24H122.988C123.181 24 123.326 23.8512 123.326 23.6529V18.0992C123.326 17.2066 123.518 16.562 123.856 16.1157C124.242 15.6694 124.724 15.4711 125.351 15.4711C126.509 15.4711 127.087 16.2645 127.087 17.9008V23.6529C127.087 23.8512 127.232 24 127.425 24H130.801C130.994 24 131.139 23.8512 131.139 23.6529V18.0992C131.139 17.2066 131.332 16.562 131.669 16.1157C132.055 15.6694 132.537 15.4711 133.116 15.4711C133.647 15.4711 134.081 15.6694 134.418 16.0661C134.708 16.4628 134.853 17.0579 134.853 17.9008V18.595H134.901Z"
        fill="#484848"
      />
      <path
        d="M134.9 22.1V22.7761C134.9 23.4523 135.431 23.9835 136.106 23.9835H137.794C138.469 23.9835 139 23.4523 139 22.7761V22.1H134.9Z"
        fill="#FB6211"
      />
    </svg>
  )
}

export default SvgLogoTextIcon
