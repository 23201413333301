import { Formik } from "formik"

import { ExerciseComponent } from "./ExerciseComponents"

import ErrorPage from "components/ErrorPage"
import ExerciseForm from "domains/Exercise/ExerciseForm"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import Loading from "ui/Loading"

export default function TeamExerciseEditableResults({
  kit,
  selectedTeam,
  teamExerciseInstance,
  components,
  className,
}) {
  const exercise = kit?.exercise

  const { initialValues, onSubmit, saveOnChange, isInvalid, isFetching } = useExerciseForm({
    teamId: selectedTeam?.id,
    slug: exercise?.slug,
    version: exercise?.version,
    teamLevelExercise: true,
  })

  if (!initialValues && isFetching) {
    return <Loading />
  }

  if (isInvalid) {
    return <ErrorPage />
  }

  // Allows us to use components not defined in the exercise
  const componentInitialValues = Object.fromEntries(
    (components ?? []).map(({ identifier, initialValue }) => [identifier, initialValue])
  )

  return (
    <Formik initialValues={{ ...componentInitialValues, ...initialValues }} onSubmit={onSubmit}>
      <ExerciseForm className={className}>
        {components.map(({ component, identifier, ...props }) => (
          <ExerciseComponent
            key={identifier}
            component={component}
            identifier={identifier}
            kit={kit}
            saveOnChange={saveOnChange}
            teamExerciseInstance={teamExerciseInstance}
            selectedTeam={selectedTeam}
            {...props}
          />
        ))}
      </ExerciseForm>
    </Formik>
  )
}
