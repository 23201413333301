import { mapValues, groupBy } from "lodash-es"
import { createContext, useContext } from "react"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useRealtimeTimer, useSetTimer } from "resources/monthly_kit"

const GroupTimersContext = createContext({
  groupTimers: {},
  setGroupTimers: () => {},
})

const useGroupTimer = (name, { realtimeGetEnabled = false, realtimeSetEnabled = false } = {}) => {
  const { kitInstance } = useKitSession()
  const { groupTimers, setGroupTimers } = useContext(GroupTimersContext) || {}
  const { [name]: localGroupTimer = null, ...otherGroupTimers } = groupTimers
  const { data: realtimeTimer, isInitialLoading } = useRealtimeTimer({
    kitInstanceId: kitInstance?.id,
    key: `group/${name}`,
    enabled: !!realtimeGetEnabled && !!name,
  })
  const { mutateAsync: updateTimer } = useSetTimer({ kitInstanceId: kitInstance?.id, key: `group/${name}` })
  const groupTimer = realtimeGetEnabled && realtimeTimer ? { ...localGroupTimer, ...realtimeTimer } : localGroupTimer
  const updateGroupTimer = groupTimer
    ? (timerOptions = {}) => {
        if (realtimeSetEnabled) {
          updateTimer({ ...groupTimer, ...timerOptions })
        } else {
          setGroupTimers({ ...otherGroupTimers, [name]: { ...groupTimer, ...timerOptions } })
        }
      }
    : () => {}

  return {
    groupTimer,
    isInitialLoading,
    updateGroupTimer,
  }
}

const getInitialGroupTimers = (steps) => {
  const groups = groupBy(
    steps.filter(({ timer_group }) => timer_group),
    "timer_group"
  )
  const groupMap = mapValues(groups, (group) =>
    group.reduce(
      (previous, current) => {
        previous.minutes += current.minutes
        return previous
      },
      {
        minutes: 0,
        isRunning: false,
        timePassed: 0,
        playClickedTime: 0,
      }
    )
  )

  return groupMap
}

export { GroupTimersContext, useGroupTimer, getInitialGroupTimers }
