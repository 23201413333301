import EngagementSurveyReport from "./EngagementSurveyReport/EngagementSurveyReport"
import KitUsageReport from "./KitUsageReport/KitUsageReport"
import SessionSummaryReport from "./SessionSummaryReport/SessionSummaryReport"

const ReportTypes = {
  SESSIONS: {
    value: "session_summary",
    name: "Session Summary",
    component: SessionSummaryReport,
  },
  KIT_USAGE: {
    value: "kit_usage",
    name: "Kit Usage",
    component: KitUsageReport,
  },
  ENGAGEMENT_SURVEY: {
    value: "engagement_survey",
    name: "Engagement Survey",
    component: EngagementSurveyReport,
  },
}

export default ReportTypes
