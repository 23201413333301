import cn from "classnames"
import { Form, Formik } from "formik"
import { sortBy } from "lodash-es"
import { Navigate, useNavigate } from "react-router"
import { styled } from "styled-components"

import NotFound from "components/NotFound"
import { createIncludeAllAccountsQueryParam, parseIncludeAllAccountsQueryParam } from "domains/Admin/utils"
import ReportTypes from "domains/Reports/ReportTypes"
import { getReportUrl, getReportTypes } from "domains/Reports/utils"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { Choice } from "forms/fields/ChoicesField"
import MultiSelectField from "forms/fields/MultiSelectField"
import TaggedSelectField, { TagOptionLabel } from "forms/fields/TaggedSelectField"
import ToggleSwitchField from "forms/fields/ToggleSwitchField"
import { useReportAccounts, useAddAccountTag, useRemoveAccountTag, useRemoveAccountTagSuffix } from "resources/billing"
import { useUser } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import HorizontalRule from "ui/HorizontalRule"
import Loading from "ui/Loading"
import { useSelectedTeam, useSelectedTeamId } from "ui/SelectedTeamContext"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { parseArrayQueryParam } from "utils/string"
import { getTagSelectedLabel, getTaggedItemsDescription, TaggedItemsTooltip } from "utils/tags"

const ReportsHome = styled(function ReportsHome({ className }) {
  const features = useFeatures()
  const {
    accountId,
    accountTags: accountTagsQueryParam,
    multiAccountIds: multiAccountIdsQueryParam,
    multiAccountActive: multiAccountActiveQueryParam,
    includeArchivedTeams: includeArchivedTeamsQueryParam,
    teamId,
    teamTags,
    reportType,
    includeAllAccounts,
    ...queryParams
  } = useQueryParams()
  const accountTags = parseArrayQueryParam(accountTagsQueryParam)
  const multiAccountIds = parseArrayQueryParam(multiAccountIdsQueryParam)
  const parsedIncludeAllAccounts = parseIncludeAllAccountsQueryParam(includeAllAccounts)
  const multiAccountActive = multiAccountActiveQueryParam === "true"
  const includeArchivedTeams = includeArchivedTeamsQueryParam === "true"
  const { data: user } = useUser({ userId: "me" })
  const { data: basicAccounts, isFetching: isFetchingAccounts } = useReportAccounts(parsedIncludeAllAccounts)
  const navigate = useNavigate()
  const { selectedTeam: selectedTeamOrNull } = useSelectedTeam()
  const isEngagementSurveyReport = reportType === ReportTypes.ENGAGEMENT_SURVEY.value
  const { mutateAsync: addAccountTag } = useAddAccountTag()
  const { mutateAsync: removeAccountTag } = useRemoveAccountTag()
  const { mutateAsync: removeAccountTagSuffix } = useRemoveAccountTagSuffix()
  const { setSelectedTeamId } = useSelectedTeamId()

  // Keep teamId context in sync with query param:
  useEffectAfterChange(() => {
    // Don't pass non-integer value to setSelectedTeamId:
    // (if we set teamId="ALL_TEAMS" as selected team we'll trigger a 404 request)
    if (Number.isInteger(parseInt(teamId))) {
      setSelectedTeamId(teamId)
    }
  }, [teamId, setSelectedTeamId])

  if (!user) {
    return <Loading />
  }

  if (!user.can_view_reports) {
    return null
  }

  if (!accountId && !basicAccounts) {
    return <Loading />
  }

  const urlQueryParams = {
    accountId,
    accountTags,
    multiAccountIds,
    ...(!isEngagementSurveyReport && multiAccountActive ? { multiAccountActive } : {}),
    teamId,
    teamTags,
    reportType,
    includeAllAccounts,
    includeArchivedTeams,
  }

  const sortedBasicAccounts = sortBy(basicAccounts, "name")
  const defaultBasicAccount =
    sortedBasicAccounts.filter((a) => a.id === selectedTeamOrNull?.account_id)[0] ?? sortedBasicAccounts[0]
  const basicAccountsMatchingTags = sortedBasicAccounts.filter((account) =>
    accountTags.some((tag) => account.tags.includes(tag))
  )

  if (!sortedBasicAccounts.length && !isFetchingAccounts) {
    return <NotFound />
  }

  const showIncludeAllAccountsControls =
    !!features[FLAGS.SUPERUSER_REPORTS] && !!user.is_staff && !user.is_demo_mode_active

  // If the user would normally see "Include all accounts" and they don't have any
  // accounts otherwise, default to includeAllAccounts=true to avoid blank state:
  if (
    !parsedIncludeAllAccounts.activeAccounts &&
    !parsedIncludeAllAccounts.activeAndInactiveAccounts &&
    showIncludeAllAccountsControls &&
    !isFetchingAccounts && // don't redirect if accounts is still fetching
    !sortedBasicAccounts.length
  ) {
    return (
      <Navigate
        to={getReportUrl({
          ...urlQueryParams,
          includeAllAccounts: createIncludeAllAccountsQueryParam({
            activeAccounts: true,
            activeAndInactiveAccounts: parsedIncludeAllAccounts.activeAndInactiveAccounts,
          }),
        })}
        replace
      />
    )
  }

  if (!accountId || !reportType) {
    const newAccountId = accountId || defaultBasicAccount.id
    const newTeamId = newAccountId && selectedTeamOrNull?.account_id === newAccountId ? selectedTeamOrNull?.id : null
    return (
      <Navigate
        to={getReportUrl({
          accountId: newAccountId,
          reportType: reportType?.length ? reportType : ReportTypes.SESSIONS.value,
          ...(!isEngagementSurveyReport && multiAccountActive ? { multiAccountActive } : {}),
          includeAllAccounts,
          teamId: newTeamId,
          ...queryParams,
        })}
        replace
      />
    )
  }

  const selectedBasicAccount = sortedBasicAccounts.find(({ id }) => id === Number(accountId))
  const accountIdFormValue = selectedBasicAccount?.id ?? null

  const multiAccountIdsSet = new Set(multiAccountIds.map(Number).filter(Number.isInteger))
  const multiAccountIdsFormValue = sortedBasicAccounts
    .filter(({ id }) => multiAccountIdsSet.has(id))
    .map(({ id, name }) => ({ value: id, label: name }))

  const onAccountChange = ({ value }) => {
    // when the account changes start from scratch since the existing values may not apply
    navigate(getReportUrl({ accountId: value, includeAllAccounts, reportType }))
  }

  const onMultiAccountChange = ({ value }) => {
    navigate(getReportUrl({ ...urlQueryParams, multiAccountIds: value }))
  }

  const onAccountTagsChange = ({ value }) => {
    // When selecting tags switch to making queries under first account ID.
    // This ensures react-select text always updates when it should, and helps ensure
    // tag query consistency (no inadvertent querying under different account IDs).
    const firstAccountId = sortedBasicAccounts[0]?.id
    const accountIdParam = firstAccountId ? { accountId: firstAccountId } : {}
    navigate(getReportUrl({ ...urlQueryParams, ...accountIdParam, accountTags: value }))
  }

  const onIncludeAllAccountsChange = (e) => {
    const checked = !!e.target.checked
    const includeAllAccounts = createIncludeAllAccountsQueryParam({ activeAccounts: checked })
    const newQueryParams = checked ? { ...urlQueryParams, includeAllAccounts } : { accountId: null, includeAllAccounts }
    navigate(getReportUrl(newQueryParams))
  }

  const onIncludeInactiveAccountsChange = (e) => {
    const checked = !!e.target.checked && parsedIncludeAllAccounts.activeAccounts
    const includeAllAccounts = createIncludeAllAccountsQueryParam({
      activeAccounts: parsedIncludeAllAccounts.activeAccounts,
      activeAndInactiveAccounts: checked,
    })
    const newQueryParams = checked ? { ...urlQueryParams, includeAllAccounts } : { accountId: null, includeAllAccounts }
    navigate(getReportUrl(newQueryParams))
  }

  const onIncludeArchivedTeamsChange = (e) => {
    const checked = !!e.target.checked
    // Remove teamId if unchecking, in case they have an archived team selected
    const { teamId: _, ...urlQueryParamsWithoutTeamId } = urlQueryParams
    const newQueryParams = checked
      ? { ...urlQueryParams, includeArchivedTeams: checked }
      : { ...urlQueryParamsWithoutTeamId, includeArchivedTeams: checked }
    navigate(getReportUrl(newQueryParams))
  }

  const hasMultipleAccounts = basicAccounts?.length > 1
  const multiAccountDisabled = isEngagementSurveyReport || !multiAccountActive
  const accountTagsDisabled = isEngagementSurveyReport

  const allAccountTags = Array.from(new Set((basicAccounts ?? []).flatMap((account) => account.tags ?? []))).sort()
  const accountTagsFormValue = accountTags.map((tag) => ({ value: tag, label: tag }))

  return (
    <div className={cn("main-container text-gray-9 full-width", className)}>
      <h1>Reports</h1>
      <HorizontalRule />
      <p className="mb-medium">
        These summary reports combine the end-of-session survey results, exercise results, and reflections and action
        items by team for each kit. Because admins can view these reports, we keep them anonymized, so that things
        people share with their team stay private within the team. Anyone who wants to share the details of their own
        personal results can do that from their own exercise pages on the Team Results tab.
      </p>
      {!!showIncludeAllAccountsControls && (
        <div className="space-y-medium mb-medium">
          <View>
            <Choice
              type="checkbox"
              label="Include All Accounts (internal use only)"
              onChange={onIncludeAllAccountsChange}
              checked={parsedIncludeAllAccounts.activeAccounts}
            />
            {!!parsedIncludeAllAccounts.activeAccounts && (
              <Choice
                type="checkbox"
                label="Include Inactive Accounts"
                onChange={onIncludeInactiveAccountsChange}
                checked={parsedIncludeAllAccounts.activeAndInactiveAccounts}
              />
            )}
          </View>
          <Choice
            type="checkbox"
            label="Include Archived Teams (internal use only)"
            onChange={onIncludeArchivedTeamsChange}
            checked={!!includeArchivedTeams}
          />
        </div>
      )}
      <Formik
        initialValues={{
          accountId: accountIdFormValue,
          accountTags: accountTagsFormValue,
          multiAccountIds: multiAccountIdsFormValue,
        }}
        enableReinitialize
      >
        <Form>
          {!!hasMultipleAccounts && (
            <View $alignItems="flex-start" className="mb-medium">
              <b className="kit-report-info-label mt-xs">Billing Account:</b>
              <div>
                {isEngagementSurveyReport || !multiAccountActive ? (
                  <TaggedItemsTooltip type="account" tags={accountTags} matches={basicAccountsMatchingTags}>
                    <TaggedSelectField
                      className="medium"
                      key={[reportType, ...accountTags].join(",")}
                      name="accountId"
                      width={330}
                      placeholder="Select account"
                      onChange={onAccountChange}
                      onTagAdd={(accountId, tag) =>
                        addAccountTag({
                          accountId,
                          tag,
                          includeAllAccounts:
                            parsedIncludeAllAccounts.activeAccounts ||
                            parsedIncludeAllAccounts.activeAndInactiveAccounts,
                        })
                      }
                      onTagRemove={(accountId, tag) => removeAccountTag({ accountId, tag })}
                      onTagRemoveSuffix={(accountId, tag, suffix) => removeAccountTagSuffix({ accountId, tag, suffix })}
                      onTagClick={(tag) =>
                        onAccountTagsChange({
                          value: accountTags.includes(tag)
                            ? accountTags.filter((t) => t !== tag)
                            : [...accountTags, tag],
                        })
                      }
                      options={sortedBasicAccounts.map(({ id, name, tags }) => ({
                        value: id,
                        tags: accountTagsDisabled ? null : tags,
                        label: name,
                        formattedSelectedLabel:
                          id === accountIdFormValue && !isEngagementSurveyReport
                            ? getTagSelectedLabel(accountTags, basicAccountsMatchingTags)
                            : null,
                      }))}
                    />
                  </TaggedItemsTooltip>
                ) : (
                  <MultiSelectField
                    className="medium"
                    name="multiAccountIds"
                    showSelectAll
                    selectedOutlined
                    width={330}
                    value={multiAccountIdsFormValue}
                    onChange={onMultiAccountChange}
                    placeholder="Select multiple accounts"
                    disabled={reportType === ReportTypes.ENGAGEMENT_SURVEY.value}
                    disabledHideOptionText
                    disabledAltPlaceholderText="Not available for this report type"
                    options={sortedBasicAccounts.map((account) => ({
                      value: account.id,
                      label: account.name,
                    }))}
                    noOptionsMessage={() => "No other accounts"}
                  />
                )}
                {!!features[FLAGS.MULTI_ACCOUNT_REPORTING] && (
                  <Tooltip
                    left
                    title="Not available for this report type."
                    disabled={!isEngagementSurveyReport}
                    className="width-auto"
                  >
                    <ToggleSwitchField
                      disabled={isEngagementSurveyReport}
                      className="py-medium"
                      checked={!isEngagementSurveyReport && multiAccountActive}
                      saveOnChange={(_fieldName, on) => {
                        // clear teamId and teamTags out of query params:
                        const {
                          multiAccountActive: _multiAccountActive,
                          teamId: _teamId,
                          teamTags: _teamTags,
                          ...otherQueryParams
                        } = urlQueryParams
                        navigate(
                          getReportUrl({
                            ...otherQueryParams,
                            ...(on ? { multiAccountActive: true } : {}),
                          })
                        )
                      }}
                    >
                      Select multiple accounts
                    </ToggleSwitchField>
                  </Tooltip>
                )}
              </div>
              {!!allAccountTags.length && !multiAccountActive && (
                <Tooltip
                  bottom
                  title="Not available for this report type."
                  disabled={!isEngagementSurveyReport}
                  className="width-auto"
                >
                  <MultiSelectField
                    className="medium ml-medium"
                    showSelectAll
                    optionFilled
                    selectedFilled
                    name="accountTags"
                    width={300}
                    value={isEngagementSurveyReport ? [] : accountTagsFormValue}
                    onChange={onAccountTagsChange}
                    placeholder="Or select account tags (optional)"
                    disabled={isEngagementSurveyReport}
                    options={allAccountTags.map((tag) => ({
                      value: tag,
                      label: tag,
                    }))}
                    formatOptionLabel={(option) => <TagOptionLabel tag={option.label} />}
                    noOptionsMessage={() => "No other tags"}
                    selectedDescription={(selectedTags) =>
                      getTaggedItemsDescription("account", selectedTags, basicAccountsMatchingTags)
                    }
                  />
                </Tooltip>
              )}
            </View>
          )}
        </Form>
      </Formik>
      <ReportTypeAndReportComponent
        user={user}
        selectedBasicAccount={multiAccountDisabled ? selectedBasicAccount : null}
        accountId={multiAccountDisabled ? accountId : multiAccountIds?.[0] ?? null}
        accountTags={multiAccountDisabled ? accountTags : null}
        multiAccountIds={multiAccountDisabled ? [] : multiAccountIds}
        multiAccountActive={multiAccountActive}
        teamId={teamId}
        teamTags={teamTags}
        includeAllAccounts={includeAllAccounts}
        includeArchivedTeams={includeArchivedTeams}
        reportType={reportType}
      />
    </div>
  )
})`
  .kit-report-info-label {
    width: 150px;
  }
`

const ReportTypeAndReportComponent = ({
  user,
  selectedBasicAccount,
  accountId,
  accountTags,
  multiAccountIds,
  multiAccountActive,
  teamId,
  teamTags,
  includeAllAccounts,
  includeArchivedTeams,
  reportType,
}) => {
  const reportTypes = getReportTypes(selectedBasicAccount, ReportTypes)
  const navigate = useNavigate()

  const urlQueryParams = {
    accountId,
    accountTags,
    multiAccountIds,
    ...(multiAccountActive ? { multiAccountActive } : {}),
    teamId,
    teamTags,
    reportType,
    includeAllAccounts,
    includeArchivedTeams,
  }

  const onReportTypeChange = ({ value }) => {
    const isKitUsageReport = value === ReportTypes.KIT_USAGE.value
    const resetUrlParams = isKitUsageReport ? { teamId: null, teamTags: null } : {}
    navigate(
      getReportUrl({
        ...urlQueryParams,
        ...resetUrlParams,
        reportType: value,
      })
    )
  }

  if (!reportTypes) {
    return null
  }

  const reportTypeData = Object.values(reportTypes).find(({ value }) => reportType === value)
  if (!reportTypeData) {
    return (
      <Navigate
        to={getReportUrl({
          accountId,
          reportType: ReportTypes.SESSIONS.value,
          includeAllAccounts,
          includeArchivedTeams,
        })}
        replace
      />
    )
  }

  const hasMultipleReportTypes = reportTypes.length > 1

  const ReportComponent = reportTypeData.component

  return (
    <>
      <Formik initialValues={{ reportType }}>
        <Form>
          {!!hasMultipleReportTypes && (
            <View $alignItems="center" className="mb-medium">
              <b className="kit-report-info-label">Report Type:</b>
              <AdvancedSelectField
                className="medium"
                name="reportType"
                width={330}
                onChange={onReportTypeChange}
                options={sortBy(reportTypes, "name").map((reportTypeInfo) => ({
                  value: reportTypeInfo.value,
                  label: reportTypeInfo.name,
                }))}
              />
            </View>
          )}
        </Form>
      </Formik>
      <ReportComponent
        user={user}
        accountId={accountId}
        accountTags={accountTags}
        multiAccountIds={multiAccountIds}
        includeArchivedTeams={includeArchivedTeams}
      />
    </>
  )
}

export default ReportsHome
