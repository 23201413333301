import qs from "qs"

import { isSchoolAccountType } from "components/AccountTypeContext"

function getReportTypes(selectedBasicAccount, ReportTypes) {
  if (isSchoolAccountType(selectedBasicAccount?.account_type)) {
    return [ReportTypes.SESSIONS, ReportTypes.KIT_USAGE]
  }
  return [ReportTypes.SESSIONS, ReportTypes.ENGAGEMENT_SURVEY, ReportTypes.KIT_USAGE]
}

function getReportUrl({
  accountId,
  accountTags,
  multiAccountIds,
  multiAccountActive,
  reportType,
  teamId,
  teamTags,
  kitSlug,
  includeAllAccounts,
  includeArchivedTeams,
}) {
  const queryParams = qs.stringify(
    {
      accountId,
      accountTags,
      multiAccountIds,
      multiAccountActive,
      reportType,
      teamId,
      teamTags,
      kitSlug,
      includeAllAccounts,
      includeArchivedTeams,
    },
    { arrayFormat: "comma" }
    // Use arrayFormat:comma so we can fit more items within the URL character limit
    // (it's much more compact). See https://www.npmjs.com/package/qs for details.
  )
  return `/reports/?${queryParams}`
}

export { getReportUrl, getReportTypes }
