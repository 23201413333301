import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import getIconOrError from "icons"
import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import View from "ui/View"

const RadioGroupIconList = ({ identifier, exerciseComponent, exerciseInstance }) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []

  return <IconListDisplay choiceOptions={choiceOptions} userSelections={userSelections} className="mb-medium" />
}

const IconListDisplay = styled(function IconListDisplay({ choiceOptions, userSelections, className }) {
  return (
    <View as="label" className={className}>
      {choiceOptions.map(({ label, icon, value }, idx) => {
        const Icon = getIconOrError(icon)
        return (
          <View
            key={idx}
            $alignItems="center"
            $justifyContent="center"
            className={cn("box p-medium", { checked: userSelections.includes(value) })}
            $flexDirection="column"
          >
            <View $flexDirection="row" $alignItems="center">
              <div className="pr-large pl-xs">{!!Icon && <Icon className="icon" />}</div>
              <div className="text-exercise-button text-semi-bold">
                <PurifyInnerHTMLDiv>{label}</PurifyInnerHTMLDiv>
              </div>
            </View>
          </View>
        )
      })}
    </View>
  )
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;

  > div {
    height: 100%;
    background-color: #fff;
    border: 1px solid var(--gray-4);
    border-radius: 8px;
  }

  .icon {
    width: 32px;
    height: 32px;
    color: var(--gray-6);
  }

  .checked {
    background-color: #ebf4fe;
    border: 1px solid #0a84ff1a;
    border-radius: 8px;

    .icon {
      color: var(--rising-blue);
    }
  }
`

export default RadioGroupIconList
