import cn from "classnames"
import { styled } from "styled-components"

import { answerAsArray, getExerciseAnswer } from "../results_utils"

import getIconOrError from "icons"
import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const RadioGroupTextButtons = ({
  identifier,
  exerciseComponent,
  exerciseInstance,
  useFullLabel,
  selectedStateBackgroundColor = null,
  selectedStateColor = null,
  unselectedStateBackgroundColor = null,
  unselectedStateIconColor = null,
  selectedStateIconColor = null,
  textWeightClass = "text-semi-bold",
}) => {
  const choiceOptions = exerciseComponent.options
  const columns = exerciseComponent.columns
  const userSelections = answerAsArray(getExerciseAnswer(exerciseInstance, identifier), [])

  return (
    <span>
      {!!useFullLabel && columns === 1 ? (
        <TextBoxList
          choiceOptions={choiceOptions}
          userSelections={userSelections}
          columns={columns}
          selectedStateBackgroundColor={selectedStateBackgroundColor}
          selectedStateColor={selectedStateColor}
          selectedStateIconColor={selectedStateIconColor}
          unselectedStateBackgroundColor={unselectedStateBackgroundColor}
          unselectedStateIconColor={unselectedStateIconColor}
          textWeightClass={textWeightClass}
        />
      ) : (
        <TextBoxGrid
          choiceOptions={choiceOptions}
          userSelections={userSelections}
          columns={columns}
          selectedStateBackgroundColor={selectedStateBackgroundColor}
          selectedStateColor={selectedStateColor}
          selectedStateIconColor={selectedStateIconColor}
          unselectedStateBackgroundColor={unselectedStateBackgroundColor}
          unselectedStateIconColor={unselectedStateIconColor}
          textWeightClass={textWeightClass}
        />
      )}
    </span>
  )
}

const TextBoxGrid = styled(function TextBoxGrid({ choiceOptions, userSelections, textWeightClass, className }) {
  return (
    <View className={cn(className, "mb-xl")} $flexWrap="wrap">
      {choiceOptions.map(({ value, short_label, tooltip, ...props }) => {
        const Icon = props.icon ? getIconOrError(props.icon) : null
        const selected = userSelections.includes(value)
        return (
          <Tooltip bottom float title={tooltip} disabled={!tooltip} key={value}>
            <div
              key={value}
              role="checkbox"
              aria-checked={selected}
              className={cn("answer-box text-gray-9 text-center", textWeightClass, { selected })}
            >
              <View $flexDirection="column" $alignItems="center">
                {!!Icon && (
                  <div className="px-large">
                    <Icon className="icon" />
                  </div>
                )}
                <div>{short_label}</div>
              </View>
            </div>
          </Tooltip>
        )
      })}
    </View>
  )
})`
  display: grid;
  grid-template-columns: repeat(${({ columns = 6 }) => columns}, minmax(0, 176px));
  grid-auto-rows: 1fr;
  gap: 16px;

  .answer-box {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 20px 12px;
    height: 100%;
    min-width: 120px;
    min-height: 60px;
    max-width: 176px;

    box-sizing: border-box;
    border-radius: 8px;

    background: ${({ unselectedStateBackgroundColor }) =>
      !!unselectedStateBackgroundColor ? `var(--${unselectedStateBackgroundColor})` : "var(--gray-1)"};
    border: 1px solid var(--gray-3);

    &.selected {
      background: ${({ selectedStateBackgroundColor }) =>
        !!selectedStateBackgroundColor ? `var(--${selectedStateBackgroundColor})` : "var(--green-3)"};
      color: ${({ selectedStateColor }) => (!!selectedStateColor ? `var(--${selectedStateColor})` : "var(--fg)")};
      ${({ selectedStateIconColor }) =>
        !!selectedStateIconColor && `border: 1px solid var(--${selectedStateIconColor});`}
      font-weight: 600;

      .icon {
        ${({ selectedStateIconColor }) => !!selectedStateIconColor && `color: var(--${selectedStateIconColor});`}
      }
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    ${({ unselectedStateIconColor }) => !!unselectedStateIconColor && `color: var(--${unselectedStateIconColor});`}
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 4)}, minmax(0, 1fr));
  }
  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 3)}, minmax(0, 1fr));
  }
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 2)}, minmax(0, 1fr));
  }
`

const TextBoxList = styled(function TextBoxList({ choiceOptions, userSelections, className }) {
  return (
    <View className={cn(className, "mb-xl")} $flexWrap="wrap">
      {choiceOptions.map(({ value, label, tooltip, ...props }) => {
        const Icon = props.icon ? getIconOrError(props.icon) : null
        return (
          <Tooltip bottom float title={tooltip} disabled={!tooltip} key={value}>
            <div
              key={value}
              className={cn("answer-box text-gray-9 text-center text-field-label full-width px-large py-medium", {
                selected: userSelections.includes(value),
              })}
            >
              <View $flexDirection="row" $alignItems="center">
                {!!Icon && (
                  <div className="pr-large">
                    <Icon className="icon" />
                  </div>
                )}
                <PurifyInnerHTMLDiv>{label}</PurifyInnerHTMLDiv>
              </View>
            </div>
          </Tooltip>
        )
      })}
    </View>
  )
})`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .answer-box {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    height: 100%;
    min-width: 120px;
    min-height: 60px;
    max-width: 545px;
    text-align: left;

    box-sizing: border-box;
    border-radius: 8px;

    background: ${({ unselectedStateBackgroundColor }) =>
      !!unselectedStateBackgroundColor ? `var(--${unselectedStateBackgroundColor})` : "var(--gray-1)"};
    border: 1px solid var(--gray-3);

    &.selected {
      box-shadow: var(--blur-1);
      background-color: ${({ selectedStateBackgroundColor }) =>
        !!selectedStateBackgroundColor ? `var(--${selectedStateBackgroundColor})` : " #ebf4fe"};

      .icon {
        color: ${({ selectedStateIconColor }) =>
          !!selectedStateIconColor ? `var(--${selectedStateIconColor})` : "var(--rising-blue)"};
      }
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    color: ${({ unselectedStateIconColor }) =>
      !!unselectedStateIconColor ? `var(--${unselectedStateIconColor})` : "var(--gray-6)"};
  }
`

export default RadioGroupTextButtons
