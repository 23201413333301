import SelectField from "./SelectField"

const COMPANY_SIZES = [
  { value: "1-24", label: "1-24" },
  { value: "25-49", label: "25-49" },
  { value: "50-99", label: "50-99" },
  { value: "100-249", label: "100-249" },
  { value: "250-499", label: "250-499" },
  { value: "500-999", label: "500-999" },
  { value: "1000-2499", label: "1000-2499" },
  { value: "2500-4999", label: "2500-4999" },
  { value: "5000-9999", label: "5000-9999" },
  { value: "10000-24999", label: "10000-24999" },
  { value: "25000 and over", label: "25000 and over" },
]

const CompanySizeField = ({ options = COMPANY_SIZES, ...props }) => (
  <SelectField {...props}>
    <option disabled value="" />
    {options.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ))}
  </SelectField>
)

export default CompanySizeField
