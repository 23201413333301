export default function listStyles({ wrapped = false, wrapColWidth = 200 }) {
  const when = (cond, ...styles) => (!cond ? "" : styles.join("\n"))
  return `
    ${when(wrapped, "display: flex;", "flex-wrap: wrap;")}

    li {
      ${when(wrapped, `width: ${wrapColWidth}px;`)}

      color: inherit;
      margin-left: var(--spacing-4);

      p {
        display: inline-block;
        vertical-align: top;
      }
    }
  `
}
