import { styled } from "styled-components"

import View from "ui/View"

const TalentsTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>
        After you complete the Rising Team session on talents, we want to give you some tips for how to amplify your
        team’s talents on a regular basis. Here are a few ideas you can try:
      </div>
      <h4 className="text-rising-orange py-small">1. Check in 1:1 with each person to get more detail</h4>
      <div className="pb-xs">
        While the Rising Team session is a good way to get started with these discussions, there may be more that each
        team member wants to cover with you that they didn’t want to share in the meeting with the full team. Some good
        questions to ask in your 1:1 meeting to go deeper on this topic are:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>What activities were you doing on your best day at work in the last few months?</li>
          <li>What activities were you doing on your worst day at work in the last few months?</li>
          <li>What do you wish you could do more of?</li>
          <li>What do you think you might be good at but haven’t been able to try?</li>
        </ul>
      </div>
      <div className="pb-xs">
        Follow up their answers to these questions by asking for more specifics about what they enjoy or don’t about
        each activity to learn more about what really drives them.
      </div>
      <div className="pb-xs">
        Once you feel like you have a good sense of their talents, ask them about ways they believe they can use their
        talents more. Often people will be able to guide themselves here and come up with great ideas that can help the
        team. To serve as a strong coach for your team members, you want to help them find their own solutions, and this
        can be part of that process.
      </div>
      <h4 className="text-rising-orange py-small">2. Map people’s talent zones</h4>
      <div className="pb-xs">
        Ideally each person should be in a role that requires no more than 20% of their time to be spent on activities
        outside of their natural talents. If that ratio gets too far off, people will be unhappy and stressed in their
        jobs, which may cause them to leave or cause their performance to suffer.
      </div>
      <div className="pb-xs">
        You can estimate this ratio by working with each person to{" "}
        <span className="text-bold">create a “talent map”</span> where they list out the primary activities they do in
        an average week and roughly how much time they spend on each one, as well as which talent type each activity
        corresponds to. Then map that back to which talent areas were high and very high for them. If significantly more
        than 20% of their time is being spent in areas of low or medium talent, you should discuss ways to adjust that.
      </div>
      <div className="pb-xs">
        Sometimes that might mean partnering them with another person on the team to help with some activities, and
        other times it may mean helping the person take on new projects or even find a new role that is a better fit for
        what they naturally love to do. If you need advice about how to think through approaches on this, hop into{" "}
        <a href="https://app.risingteam.com/slack/invite" target="_blank" rel="noopener noreferrer">
          the Huddle
        </a>{" "}
        on Slack
      </div>
      <h4 className="text-rising-orange py-small">3. Find the right fit for the person and the team</h4>
      <div className="pb-xs">
        To take this a step further, you can think of the Talents exercise as helping you coach your team to find the
        overlap of what they love to do (talents), what they have learned to be good at (skills) and what your
        organization needs. This is similar to the Japanese concept of “Ikigai” or “a reason for being.” (That concept
        has 4 elements - things I love, things I’m good at, things the world needs, and things I can get paid to do.)
      </div>
      <View $flexDirectionTablet="column">
        <div className="image-container pt-small ml-large mr-medium">
          <img
            src="https://static.risingteam.com/kit_assets/talents/kit-training-talents-3.1.png"
            alt="kit-training-talents-strengths"
            width="300"
            height="247"
          />
        </div>
        <div className="row-item mr-large pb-xs">
          The top two circles here are the same as the axes on the 2x2 chart in our first training article. Our Talents
          exercise should highlight the things they love to do, and you should be able to assess each team member on
          skills. For “things the organization needs” you will need to think through which of the 9 talents in our
          assessment are most important to each role on your team.
        </div>
      </View>
      <div className="pb-xs">
        Helping people understand where they fit here and how they work towards the middle on this chart is a helpful
        way to coach people towards their highest potential.
      </div>
      <h4 className="text-rising-orange py-small">4. Use individual talents to achieve team goals</h4>
      <div className="pb-xs">
        The next time you set goals as a team, identify which of the talent types will be required to reach each goal
        and then ask people on the team to sign up for areas that are needed and that align with their talents. It may
        lead to a slightly different set of assignments than you would typically get when just assigning people by their
        roles/functions, but it will likely lead to a better outcome since people will be doing the work they most enjoy
        and where their performance is likely to be highest. In her LinkedIn learning course, Jessica Mund, a certified
        strengths trainer, calls these{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/learning/a-strengths-based-approach-to-managing-your-team/improving-work-performance-through-strengths-centered-groups"
          className="text-underline"
        >
          strengths-centered groups.
        </a>
      </div>
      <div className="pb-xs">
        If there are areas that are needed where no one volunteers or no one's talents are a match, then talk through
        that as a team. It’s better to identify those issues early on and brainstorm ways to address them, than to find
        out later you don’t have the right mix of talents to achieve your goals. These are times when you may want to
        pull in other people from your organization or look for freelancers or other ways to fill key gaps.
      </div>
      <h4 className="text-rising-orange py-small">5. Integrate talents in your day-to-day language {"&"} routine</h4>
      <div className="pb-xs">
        There are many ways to make the use of talents part of your ongoing routine, including using our Rising Team
        tools after the session. Here are a few ideas:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Practice active listening</span> - Pay attention to when people sound energized
            (or drained) by something. This may be a signal about talents. Let them know you notice by saying, “I’m
            noticing you sound energized by this. What about it seems interesting to you?”
          </li>
          <li>
            <span className="text-bold">Use TeamMojis as a shared language</span> - Use our Rising TeamMojis of each
            talent type to give kudos to your team when you see them demonstrating their natural talents. It’s great to
            recognize what you want to reinforce.
          </li>
          <li>
            <span className="text-bold">Go back to the Mashups</span> - Pair complementary team members on key projects
          </li>
        </ul>
      </div>
      <div className="pb-xs">
        Awesome job!! You’ve finished your training on natural talents and you’re ready to move on to the preview and
        prep for your Rising Team session.
      </div>
      <div className="pb-xs">
        If you want to keep learning more about this topic, here are some additional resources:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://news.gallup.com/businessjournal/412/exactly-what-talent-anyway.aspx"
            >
              Exactly What Is Talent, Anyway?
            </a>{" "}
            (Gallup)
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2005/03/what-great-managers-do">
              What Great Managers Do
            </a>{" "}
            (Harvard Business Review)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://news.gallup.com/businessjournal/167462/employees-strengths-company-stronger.aspx"
            >
              How Employees’ Strengths Make Your Company Stronger
            </a>{" "}
            (Gallup)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/learning/a-strengths-based-approach-to-managing-your-team/"
            >
              A Strengths-Based Approach to Managing Your Team
            </a>{" "}
            (LinkedIn Learning)
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default styled(TalentsTraining3)`
  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    img {
      max-width: 100%;
      height: auto;
    }

    .image-container,
    .row-item {
      margin: var(--spacing-0);
    }
  }
`
