import KitUsageKitsReport from "domains/Reports/KitUsageReport/KitUsageKitsReport"
import KitUsageSummaryReport from "domains/Reports/KitUsageReport/KitUsageSummaryReport"
import KitUsageTeamReport from "domains/Reports/KitUsageReport/KitUsageTeamReport"
import ReportWithTeamAndKitControls from "domains/Reports/ReportWithTeamAndKitControls"

const KitUsageReport = ({ className, user, accountId, accountTags, multiAccountIds, includeArchivedTeams }) => (
  <div className={className}>
    <ReportWithTeamAndKitControls
      user={user}
      accountId={accountId}
      accountTags={accountTags}
      multiAccountIds={multiAccountIds}
      includeArchivedTeams={includeArchivedTeams}
      isTeamSelectionOptional={true}
      isKitSelectionOptional={true}
      ReportComponent={(props) => (
        <>
          <KitUsageSummaryReport {...props} />
          <KitUsageKitsReport {...props} />
          <KitUsageTeamReport {...props} />
        </>
      )}
    />
  </div>
)

export default KitUsageReport
