import { useExerciseContext } from "../ExerciseContext"
import ResultsBlocks from "../ResultsBlocks/ResultsBlocks"

import { useUser } from "resources/users"
import SelectedUserContext from "ui/SelectedUserContext"

const ExerciseResultsBlocksContainer = ({ blocks }) => {
  const { data: user } = useUser({ userId: "me" })
  const { exerciseInstance } = useExerciseContext()

  if (!exerciseInstance) {
    return null
  }

  const exerciseInstances = [exerciseInstance]
  const users = [user]

  return (
    <SelectedUserContext.Provider value={{ selectedUser: user }}>
      <ResultsBlocks blocks={blocks} exerciseInstances={exerciseInstances} selectedUsers={users} />
    </SelectedUserContext.Provider>
  )
}

export default ExerciseResultsBlocksContainer
