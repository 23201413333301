/* eslint-disable import/first */
// We use shims below that should run before other imports, so disable import/first.

import * as Sentry from "@sentry/browser"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import ReactModal from "react-modal"
import replaceAll from "string.prototype.replaceall"

// We've encountered browsers in the wild that don't yet support string.replaceAll,
// so we shim/polyfill it here to add that support. This will do nothing if
// string.replaceAll already exists in the current environment.
replaceAll.shim()
import "utils/readable-stream-async-iterator-polyfill"

import App from "./App"
import ErrorBoundary from "./components/ErrorBoundary"
import * as serviceWorker from "./serviceWorker"

import { initializeFirebaseApp } from "domains/Authentication/firebase"
import { isDevelopmentEnv, isCITestEnv, isStagingEnv } from "utils/env"

initializeFirebaseApp() // Don't await here; Firebase will initialize asynchronously.

Sentry.init({
  release: import.meta.env.VITE_APP_RELEASE_HASH,
  dsn: isDevelopmentEnv() || isCITestEnv() ? null : import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_SENTRY_ENV,
  attachStacktrace: true,
  // Attach stack traces to captureMessage events (in addition to captureException)
  // https://docs.sentry.io/platforms/javascript/configuration/options/#attach-stacktrace
  ignoreErrors: [
    "SyntaxError: No identifiers allowed directly after numeric literal",
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    // The error below looks like they are caused by Microsoft SafeLink previewing links in emails
    // and the reason there is an error is that Safelink doesn't fully support JS.
    // This was verified that the IP address is owned by MS.
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
    // Error type below could happen due to any network hiccups, and not necessarily on our end
    "AxiosError: Network Error",
    "AxiosError: Request aborted",
    "Error: Failed to load Stripe.js",
    "Blocked 'media' from 'data:'", // CSP media-src errors that represent blocked content we want to continue blocking
    /^Blocked 'font' from '.*'$/i, // CSP font-src errors that represent blocked fonts we want to continue blocking
  ],
  beforeSend(event, hint) {
    const { originalException, syntheticException } = hint

    // When running app locally, log all Sentry.captureException and
    // Sentry.captureMessage calls to console since they won't be visible otherwise:
    // Also do this console-logging in staging for added error visibility.
    if (isDevelopmentEnv() || isStagingEnv()) {
      for (const method of ["captureException", "captureMessage"]) {
        if (syntheticException?.stack?.includes(method)) {
          console.warn(`[Sentry.${method}]`, originalException)
        }
      }
    }

    if (isDevelopmentEnv() || isCITestEnv()) {
      // Never send events to Sentry from local/development mode:
      return null
    } else {
      // Send the event to Sentry:
      return event
    }
  },
})

ReactModal.setAppElement("#root")

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
