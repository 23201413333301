import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import View from "ui/View"

const Image = styled(function Image({ exerciseInstance, identifier, label, className }) {
  const imageAnswer = getExerciseAnswer(exerciseInstance, identifier)

  return (
    <View $width="fit-content">
      {!!imageAnswer && (
        <View $flexDirection="column" className={cn(className, "mb-xl")}>
          <div>{label}</div>
          <div className="answer-image">
            <img src={imageAnswer} alt={identifier} />
          </div>
        </View>
      )}
    </View>
  )
})`
  .answer-image {
    max-width: ${({ maxWidth }) => `${maxWidth}px`};

    @media (max-width: ${({ theme }) => theme.mobileMax}) {
      max-width: 377px;
    }

    img {
      display: block;
      object-fit: contain;
      border-radius: 8px;
      max-width: ${({ maxWidth }) => `${maxWidth}px`};
      max-height: ${({ maxWidth }) => `${maxWidth}px`};

      @media (max-width: ${({ theme }) => theme.mobileMax}) {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`

Image.defaultProps = {
  maxWidth: 377,
}

export default Image
