const LearnerAgilityTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        Now that you're clear on the role that benefits learning ability, how do you help your team embody a growth
        mindset? This Rising Team session will help people identify their learning preferences and identify team norms
        that foster learner safety and agility.
      </p>
      <h3 className="mb-medium">Learning stories</h3>
      <p className="mb-medium">
        The first activity is designed to help team members reflect on moments in their past when they felt both
        comfortable and uncomfortable learning something new or asking for help. In this activity, team members will
        share their experiences and identify specific factors that contributed to their hesitation or comfort. Then the
        team will be prompted to identify factors they would like to practice as a team to create an environment that
        fosters learning.
      </p>
      <h3 className="mb-medium">Learning agility</h3>
      <p className="mb-medium">
        The next exercise will ask team members to review a list of learning agility behaviors and choose a few they'd
        like to focus on in the future. They will share their selections with the larger team and then discuss how the
        team can support them as they try new things and take new learning risks.
      </p>
      <h3 className="mb-medium">Learning Styles Inventory</h3>
      <p className="mb-medium">
        The Learning Styles Inventory is designed to identify the ways team members prefer to learn, process, and retain
        new information and skills. Team members will be presented with five scenarios where they will be asked to rank
        options according to how they would prefer to learn in each scenario. Then they will receive a personal report
        identifying their top learning styles.
      </p>
      <p className="mb-medium">
        Each team member will then share their preferences with the larger team and discuss any support they would like
        in order to operate in their preferred learning modes moving forward.
      </p>
      <p className="mb-medium">A few things to keep in mind:</p>
      <h3 className="mb-medium">1. Demonstrate learning agility</h3>
      <p className="mb-medium">
        This kit's activities are designed to spark a team conversation and encourage future discussions about how to
        take learning risks. By demonstrating that you are comfortable being vulnerable in the process, you will
        encourage others to do the same. The more vulnerability you're able to demonstrate, the more trust will be
        built, and the safer the team members will feel sharing their own comfort level and preferences around learning.
      </p>
      <h3 className="mb-medium">2. Leverage the Psychological Safety kit</h3>
      <p className="mb-medium">
        This kit is designed to bring new awareness of personal and environmental triggers that might prevent the team
        from taking learning risks. As the team lead, you have an opportunity to model by not only openly sharing your
        own experiences, but also ensuring the team feels safe participating in the activities. Review the Psychological
        Safety kit to familiarize yourself with foundational concepts.
      </p>
      <h3 className="mb-medium">3. Consider that everyone will have a different level of comfort</h3>
      <p className="mb-medium">
        Creating a safe learning culture takes time and practice. Since everyone will be coming to the meeting with
        different experiences, skill sets, and comfort levels when taking learning risks, it's important to strive for
        improvement over time. Some team members will progress faster than others, but what's most important is that the
        team gets aligned on the importance of learning safety and practicing learning agility as a team.
      </p>
    </div>
  </div>
)

export default LearnerAgilityTraining2
