import { Outlet, Route, Routes, Navigate } from "react-router-dom"

import CreateAccount from "./CreateAccount"
import CompleteCheckoutSession from "./CreateAccount/CompleteCheckoutSession"
import Register from "./Register"
import RegisterInvited from "./Register/RegisterInvited"
import RegisterName from "./Register/RegisterName"

import { PrivateRoute } from "domains/Authentication/Routes"

const GetStartedLayout = () => (
  <div className="main-container full-width">
    <Outlet />
  </div>
)

const GetStarted = () => (
  <Routes>
    <Route element={<GetStartedLayout />}>
      <Route path="/sub/:subscription/:quantity" element={<Register />} />
      <Route path="/invite" element={<RegisterInvited />} />
      <Route path="/register-team-member" element={<RegisterInvited teamMemberInvite />} />
      <Route path="/register-name" element={<RegisterName />} />
      <Route element={<PrivateRoute />}>
        <Route path="/complete-checkout" element={<CompleteCheckoutSession />} />
        <Route path="/create-account/*" element={<CreateAccount />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  </Routes>
)

export default GetStarted
