import cn from "classnames"
import { styled } from "styled-components"

import ContactOptions from "./ContactOptions"

import { useUser } from "resources/users"
import BackButton from "ui/BackButton"
import PageTitle from "ui/PageTitle"
import View from "ui/View"

const Contact = ({ className }) => {
  const { data: user } = useUser({ userId: "me" })
  if (!user) {
    return null
  }

  return (
    <main className={cn("main-container", className)}>
      <PageTitle>Contact</PageTitle>
      <BackButton className="mt-small link text-button" />
      <View $flexDirection="column" className="box-shadow px-xl py-xl mt-xs">
        <h1 className="text-bold">Contact Us</h1>
        <h4 className="mt-xs">We're here to help!</h4>
        <ContactOptions user={user} />
      </View>
    </main>
  )
}

export default styled(Contact)`
  width: 100%;
`
