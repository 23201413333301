import { useState } from "react"

import useEffectAfterFirstRender from "./useEffectAfterFirstRender"

import { useCurrentTheme } from "ui/ThemeUpdateContext"

// https://usehooks.com/useWindowSize/

export default function useWindowSize() {
  const theme = useCurrentTheme()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffectAfterFirstRender(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  return {
    width: windowSize.width,
    height: windowSize.height,
    isMobileOrSmaller: windowSize.width <= parseInt(theme.mobileMax),
    isMobileLandscapeOrLarger: windowSize.width >= parseInt(theme.mobileLandscapeMin),
    isMobileLandscapeOrSmaller: windowSize.width <= parseInt(theme.mobileLandscapeMax),
    isTabletOrLarger: windowSize.width >= parseInt(theme.tabletMin),
    isTabletOrSmaller: windowSize.width <= parseInt(theme.tabletMax),
    isDesktopOrLarger: windowSize.width >= parseInt(theme.desktopMin),
  }
}
