import { QRCode } from "react-qrcode-logo"

import { getAppCodeURL } from "resources/monthly_kit"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import { buildUrl } from "utils/string"

const SessionQRCode = ({ className, kitInstance, size }) => {
  const theme = useCurrentTheme()
  const baseUrl = getAppCodeURL()
  const codeUrl = buildUrl([baseUrl, kitInstance.session_code])
  const qrCodePaddingQuietZone = 0
  // 10px white space around a QR code is recommended (default for react-qrcode-logo)
  // but we'll always be putting our own spacing around it anyway; leave that to the
  // calling component otherwise alignment with other elements will be difficult
  return (
    <div className={className} aria-label="QR code for session">
      {!!kitInstance && (
        <QRCode
          value={codeUrl}
          size={size}
          fgColor={theme.gray9}
          quietZone={qrCodePaddingQuietZone}
          ecLevel="M"
          qrStyle="dots"
          logoImage={`${import.meta.env.VITE_PUBLIC_URL}/logo192.png`}
          removeQrCodeBehindLogo={true}
        />
      )}
    </div>
  )
}

export default SessionQRCode
