import cn from "classnames"
import { FieldArray, useField } from "formik"
import { isEqual, pick, set } from "lodash-es"

import ExerciseComponentBody from "./ExerciseComponentBody"

import Button from "ui/Button"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"

const ExerciseRepeatingGroupFieldBody = ({
  name,
  components,
  exerciseComponentNameMap,
  button_text,
  initialValue,
  kit,
  saveOnChange: _saveOnChange,
  max_groups,
  as_rows,
  className,
}) => {
  const [{ value: answers }] = useField(name)
  const saveOnChange = (fieldName, fieldValue) => {
    const newAnswers = [...answers]
    const subFieldName = fieldName.slice(fieldName.indexOf("["))
    set(newAnswers, subFieldName, fieldValue)
    const filledAnswers = newAnswers.filter((answer) => !isEqual(answer, pick(initialValue[0], Object.keys(answer))))
    _saveOnChange(name, filledAnswers.length ? filledAnswers : initialValue)
  }
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  const asRowsDefault = as_rows && isMobileLandscapeOrLarger
  const asRowsMobile = as_rows && isMobileOrSmaller
  const singleComponent = components.length <= 1
  const FieldContainer = asRowsDefault ? View : "div"
  const fieldContainerProps = asRowsDefault ? { $alignItems: "center" } : {}

  return (
    <div className={className}>
      <FieldArray
        name={name}
        render={({ push }) => (
          <>
            <div
              className={cn("component-group-container", {
                "mb-large": !as_rows && !singleComponent,
                "space-y-xs mb-xs": asRowsDefault || singleComponent,
                "space-y-medium mb-medium": asRowsMobile && !singleComponent,
              })}
            >
              {answers.map((answer, idx) => (
                <FieldContainer
                  key={`${name}.${idx}`}
                  className={cn({
                    "border-top border-gray-5 pt-small mt-large": !as_rows && !singleComponent && idx > 0,
                    "space-y-large": !as_rows,
                    "space-y-xs": asRowsMobile,
                  })}
                  {...fieldContainerProps}
                >
                  {components.map(({ identifier, component, sentence, ...props }) => {
                    const id = `${name}[${idx}].${identifier}`
                    const AsComponent = exerciseComponentNameMap[component]
                    return (
                      <ExerciseComponentBody
                        AsComponent={AsComponent}
                        key={id}
                        name={id}
                        sentence={sentence}
                        saveOnChange={saveOnChange}
                        kit={kit}
                        {...props}
                      />
                    )
                  })}
                </FieldContainer>
              ))}
            </div>
            {(!max_groups || answers.length < max_groups) && (
              <Button onClick={() => push(initialValue[0])} className="tertiary">
                {button_text}
              </Button>
            )}
          </>
        )}
      />
    </div>
  )
}

export default ExerciseRepeatingGroupFieldBody
