import cn from "classnames"
import { groupBy, mapValues } from "lodash-es"
import { useState, useMemo } from "react"
import { styled } from "styled-components"

import { KIT_STATUS, getKitStatus, sortKits, sortDemoKits } from "domains/LeaderKit/KitsTableofContents/utils"
import { StyledSelect } from "forms/fields/SelectField"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useWindowSize from "ui/hooks/useWindowSize"
import { intersperse } from "utils/array"
import { KitType } from "utils/kit"

const FILTER_KEYS = {
  ALL_KITS: "all_kits",
  SCHEDULED: "scheduled",
  COMPLETED: "completed",
  DEVELOPMENT_KITS: "development_kits",
  CONNECTION_BOOSTS: "connection_boosts",
}

const KitFilters = styled(function KitFilters({
  basicKits,
  unavailableKits,
  kitInstanceMap,
  setVisibleKits,
  user,
  className,
}) {
  const [activeFilter, setActiveFilter] = useState(FILTER_KEYS.ALL_KITS)
  const { isMobileOrSmaller } = useWindowSize()
  const visibleFilters = useMemo(
    () =>
      [
        {
          text: "All kits",
          key: FILTER_KEYS.ALL_KITS,
          kits: [
            ...basicKits.filter((kit) => kit.type !== KitType.MINI),
            ...unavailableKits.filter((kit) => kit.type !== KitType.MINI).map((kit) => ({ ...kit, unavailable: true })),
          ],
        },
        {
          text: "Scheduled",
          key: FILTER_KEYS.SCHEDULED,
          kits: basicKits.filter(
            (kit) => kit.type !== KitType.MINI && getKitStatus(kitInstanceMap[kit.slug], kit) === KIT_STATUS.SCHEDULED
          ),
        },
        {
          text: "Completed",
          key: FILTER_KEYS.COMPLETED,
          kits: basicKits.filter(
            (kit) => kit.type !== KitType.MINI && getKitStatus(kitInstanceMap[kit.slug], kit) === KIT_STATUS.COMPLETE
          ),
        },
        {
          text: "Development Kits",
          key: FILTER_KEYS.DEVELOPMENT_KITS,
          kits: basicKits.filter((kit) => kit.type === KitType.KIT),
        },
        {
          text: "Connection Kits",
          key: FILTER_KEYS.CONNECTION_BOOSTS,
          kits: basicKits.filter((kit) => kit.type === KitType.BOOST),
        },
      ].filter(({ kits }) => kits.length > 0),
    [basicKits, unavailableKits, kitInstanceMap]
  )
  const visibleFiltersMap = useMemo(
    () => mapValues(groupBy(visibleFilters, "key"), (filters) => filters[0]),
    [visibleFilters]
  )

  useEffectAfterChange(() => {
    const { kits = [] } = visibleFiltersMap[activeFilter] ?? {}
    if (user.is_demo_mode_active) {
      setVisibleKits(sortDemoKits(kits, kitInstanceMap))
    } else {
      setVisibleKits(sortKits(kits, kitInstanceMap))
    }
  }, [activeFilter, setVisibleKits, visibleFiltersMap, kitInstanceMap, user.is_demo_mode_active])

  return (
    <div className={cn(className, "text-gray-7", { "full-width": isMobileOrSmaller })}>
      {isMobileOrSmaller ? (
        <MobileFilterSelect
          activeFilter={activeFilter}
          visibleFilters={visibleFilters}
          setActiveFilter={setActiveFilter}
          setVisibleKits={setVisibleKits}
        />
      ) : (
        intersperse(
          visibleFilters.map(({ text, key }) => {
            const onClick = () => {
              setActiveFilter(key)
            }
            return (
              <span
                key={key}
                onClick={onClick}
                className={cn("filter-option text-semi-bold cursor-pointer", {
                  "active-filter": key === activeFilter,
                })}
              >
                {text}
              </span>
            )
          }),
          <span className="px-xs">|</span>
        )
      )}
    </div>
  )
})`
  .filter-option:hover {
    color: var(--gray-9);
  }

  .active-filter {
    color: var(--gray-9);
    padding-bottom: 3px;
    border-bottom: 2px solid var(--orange-2);
  }
`

const MobileFilterSelect = ({ activeFilter, visibleFilters, setActiveFilter, setVisibleKits, className }) => {
  if (visibleFilters.length <= 0) {
    return null
  }

  const visibleFiltersMap = mapValues(groupBy(visibleFilters, "key"), (filters) => filters[0])
  const onChange = (evt) => {
    const key = evt.target.value
    const { kits } = visibleFiltersMap[key]
    setActiveFilter(key)
    setVisibleKits(kits)
  }

  return (
    <StyledSelect value={activeFilter} onChange={onChange} className={className}>
      {visibleFilters.map(({ text, key }) => (
        <option key={key} value={key}>
          {text}
        </option>
      ))}
    </StyledSelect>
  )
}

export default KitFilters
