function SvgSingleStarIcon(props) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.12509 4.52022L12.7179 0L13.484 7.12509L18.0043 12.7179L10.8792 13.484L5.28636 17.9276L4.52022 10.8026L0 5.20974L7.12509 4.52022Z"
        fill="#FAC126"
      />
    </svg>
  )
}

export default SvgSingleStarIcon
