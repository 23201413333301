import { useState, useRef, useCallback } from "react"

import useEffectAfterChange from "./useEffectAfterChange"

export default function useAudio({ volume } = {}) {
  const audio = useRef()
  const raf = useRef()

  const [duration, setDuration] = useState(0)
  const [currentTime, _setCurrentTime] = useState(0)
  const [muted, setMuted] = useState(false)
  const [paused, setPaused] = useState(true)
  const [playbackRate, _setPlaybackRate] = useState(1)

  const whilePlaying = useCallback(() => {
    if (audio.current) {
      const time = Math.floor(audio.current.currentTime)
      _setCurrentTime(time)
      raf.current = requestAnimationFrame(whilePlaying)
    }
  }, [audio, _setCurrentTime])

  useEffectAfterChange(() => {
    if (audio.current) {
      if (audio.current.readyState > 0) {
        setDuration(audio.current.duration)
      }

      audio.current.addEventListener("loadedmetadata", function () {
        if (audio.current) {
          setDuration(audio.current.duration)
        }
      })

      audio.current.addEventListener("pause", function () {
        if (audio.current) {
          setPaused(true)
          cancelAnimationFrame(raf)
        }
      })

      audio.current.addEventListener("play", function () {
        if (audio.current) {
          setPaused(false)
          requestAnimationFrame(whilePlaying)
        }
      })

      audio.current.addEventListener("volumechange", function () {
        if (audio.current) {
          setMuted(audio.current.muted)
        }
      })
    }
  }, [audio, setDuration, setPaused, setMuted, whilePlaying, raf])

  useEffectAfterChange(() => {
    if ("mediaSession" in navigator && audio.current) {
      navigator.mediaSession.setActionHandler("play", () => {
        if (audio.current) {
          audio.current.play()
        }
      })
      navigator.mediaSession.setActionHandler("pause", () => {
        if (audio.current) {
          audio.current.pause()
        }
      })
    }
  }, [audio])

  useEffectAfterChange(() => {
    if (audio.current && volume) {
      audio.current.volume = volume
    }
  }, [audio, volume])

  const togglePlay = () => {
    if (audio.current) {
      if (audio.current.paused) {
        audio.current.play()
      } else {
        audio.current.pause()
      }
    }
  }

  const toggleMute = () => {
    if (audio.current) {
      if (audio.current.muted) {
        audio.current.muted = false
      } else {
        audio.current.muted = true
      }
    }
  }

  const allPlaybackRates = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

  const setPlaybackRate = (speed) => {
    _setPlaybackRate(speed)
    if (audio.current) {
      audio.current.playbackRate = speed
    }
  }

  const setCurrentTime = (time, pauseAnimation = false) => {
    const t = parseInt(time)
    _setCurrentTime(t)
    if (audio.current) {
      audio.current.currentTime = t
      if (!audio.current.paused) {
        if (pauseAnimation) {
          cancelAnimationFrame(raf.current)
        } else {
          requestAnimationFrame(whilePlaying)
        }
      }
    }
  }

  return {
    audio,
    duration,
    currentTime,
    setCurrentTime,
    paused,
    muted,
    togglePlay,
    toggleMute,
    playbackRate,
    setPlaybackRate,
    allPlaybackRates,
  }
}
