import * as Sentry from "@sentry/browser"
import cn from "classnames"

import { getExerciseAnswer, getExerciseComponent } from "domains/Exercise/results_utils"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import TextContainer from "ui/TextContainer"

const CareerHorizonsThinkingBigExcitement = ({ exercise, path_field_id, className }) => {
  if (!path_field_id) {
    return null
  }

  const identifier = `${path_field_id}_excitement`
  const path_excitement = getExerciseAnswer(exercise, identifier)
  const exerciseComponent = getExerciseComponent(exercise, identifier)

  if (!exerciseComponent) {
    Sentry.captureMessage(`Unable to get exercise component from instance ${exercise.id}, identifier: ${identifier}`)
    return null
  }

  const { results_title: path_excitement_label } = exerciseComponent

  return (
    <>
      {!!path_excitement && (
        <div className={cn("text-gray-9", className)}>
          <TextContainer key={path_field_id}>
            <p className="text-field-label">{path_excitement_label}</p>
            <AnswerDisplayContent answer={path_excitement} />
          </TextContainer>
        </div>
      )}
    </>
  )
}

export default CareerHorizonsThinkingBigExcitement
