import { useState } from "react"

import { createFocusUserFunc, createGetColorFunc } from "../results_utils/radarchart"
import { getChartConfig } from "../results_utils/talents"

import RadarChart from "ui/RadarChart"

const TalentsChart = ({ userIdMap, exerciseInstances, ...props }) => {
  const [focusedUsername, setFocusedUsername] = useState(null)
  const chartConfig = getChartConfig(exerciseInstances, userIdMap)
  const getColorFunc = createGetColorFunc(focusedUsername)
  const focusUser = createFocusUserFunc({ focusedUsername, setFocusedUsername })

  return <RadarChart {...props} {...chartConfig} onKeyClick={focusUser} getColorFunc={getColorFunc} />
}

export default TalentsChart
