import { keyBy, mapValues } from "lodash-es"

import ExerciseAdvancedSelectField from "./ExerciseAdvancedSelectField"
import ExerciseAnswerDisplay from "./ExerciseAnswerDisplay"
import ExerciseAnswerDisplayContent from "./ExerciseAnswerDisplayContent"
import ExerciseBlocks from "./ExerciseBlocks"
import ExerciseCheckboxGroupFieldBody from "./ExerciseCheckboxGroupFieldBody"
import ExerciseCheckboxTableGroupField from "./ExerciseCheckboxTableGroupField"
import ExerciseComponentBody from "./ExerciseComponentBody"
import ExerciseDateField from "./ExerciseDateField"
import ExerciseGridCheckboxGroupField from "./ExerciseGridCheckboxGroupField"
import ExerciseGridRadioGroupField from "./ExerciseGridRadioGroupField"
import ExerciseGuessingGame from "./ExerciseGuessingGame"
import ExerciseHiddenField from "./ExerciseHiddenField"
import ExerciseIconRatingField from "./ExerciseIconRatingField"
import ExerciseImageField from "./ExerciseImageField"
import ExerciseLineRatingField from "./ExerciseLineRatingField"
import ExerciseListCheckboxGroupField from "./ExerciseListCheckboxGroupField"
import ExerciseParticipantSelectField from "./ExerciseParticipantSelectField"
import ExercisePercentageFieldBody from "./ExercisePercentageFieldBody"
import ExercisePercentageGroupFieldBody from "./ExercisePercentageGroupFieldBody"
import ExercisePercentageGroupRatingField from "./ExercisePercentageGroupRatingField"
import ExercisePieSliceFieldBody from "./ExercisePieSliceFieldBody"
import ExercisePieSliceGroupFieldBody from "./ExercisePieSliceGroupFieldBody"
import ExercisePieSliceGroupRatingField from "./ExercisePieSliceGroupRatingField"
import ExerciseRadioAccordionField from "./ExerciseRadioAccordionField"
import ExerciseRadioGroupField from "./ExerciseRadioGroupField"
import ExerciseRankOrderField from "./ExerciseRankOrderField"
import ExerciseRepeatingGroupFieldBody from "./ExerciseRepeatingGroupFieldBody"
import ExerciseResultsBlocksContainer from "./ExerciseResultsBlocksContainer"
import ExerciseRichTextField from "./ExerciseRichTextField"
import ExerciseStickyNoteOverallTeamFavorites from "./ExerciseStickyNoteOverallTeamFavorites"
import ExerciseStickyNotesBrainstormIdeas from "./ExerciseStickyNotesBrainstormIdeas"
import ExerciseStickyNotesUserSelectTeamFavorites from "./ExerciseStickyNotesUserSelectTeamFavorites"
import ExerciseStickyNoteUserSelectFavorite from "./ExerciseStickyNoteUserSelectFavorite"
import ExerciseTeamLevelNormsEditableResults from "./ExerciseTeamLevelNormsEditableResults"
import ExerciseTextareaField from "./ExerciseTextareaField"
import ExerciseTextField from "./ExerciseTextField"
import ExerciseTextReferenceCheckboxGroupField from "./ExerciseTextReferenceCheckboxGroupField"
import ExerciseTimeZoneSelectField from "./ExerciseTimeZoneSelectField"

import { getOrError } from "utils/object"

const ExerciseComponent = ({ component, ...props }) => {
  const AsComponent = component && getExerciseComponentOrError(component)
  return <ExerciseComponentBody AsComponent={AsComponent} {...props} />
}

const ExerciseRepeatingGroupField = ({ components, ...props }) => {
  const componentNames = components.map((component) => component.component).filter(Boolean)
  const exerciseComponentNameMap = getExerciseComponentNameMap(componentNames)
  return (
    <ExerciseRepeatingGroupFieldBody
      components={components}
      exerciseComponentNameMap={exerciseComponentNameMap}
      {...props}
    />
  )
}

const ExercisePercentageField = ({ custom_field, ...props }) => {
  const CustomFieldComponent = custom_field && getExerciseComponentOrError(custom_field.component)
  return (
    <ExercisePercentageFieldBody custom_field={custom_field} CustomFieldComponent={CustomFieldComponent} {...props} />
  )
}

const ExercisePercentageGroupField = ({ items, ...props }) => {
  const componentNames = items.map((item) => item.custom_field?.component).filter(Boolean)
  const exerciseComponentNameMap = getExerciseComponentNameMap(componentNames)
  return (
    <ExercisePercentageGroupFieldBody items={items} exerciseComponentNameMap={exerciseComponentNameMap} {...props} />
  )
}

const ExercisePieSliceField = ({ custom_field, ...props }) => {
  const CustomFieldComponent = custom_field && getExerciseComponentOrError(custom_field.component)
  return (
    <ExercisePieSliceFieldBody custom_field={custom_field} CustomFieldComponent={CustomFieldComponent} {...props} />
  )
}

const ExercisePieSliceGroupField = ({ items, ...props }) => {
  const componentNames = items.map((item) => item.custom_field?.component).filter(Boolean)
  const exerciseComponentNameMap = getExerciseComponentNameMap(componentNames)
  return <ExercisePieSliceGroupFieldBody items={items} exerciseComponentNameMap={exerciseComponentNameMap} {...props} />
}

const ExerciseCheckboxGroupField = ({ options, ...props }) => {
  const componentNames = options.map((option) => option?.custom_field?.component).filter(Boolean)
  const exerciseComponentNameMap = getExerciseComponentNameMap(componentNames)
  return (
    <ExerciseCheckboxGroupFieldBody options={options} exerciseComponentNameMap={exerciseComponentNameMap} {...props} />
  )
}

function getExerciseComponentNameMap(componentNames) {
  return mapValues(
    keyBy(componentNames, (x) => x),
    getExerciseComponentOrError
  )
}

export default function getExerciseComponentOrError(name) {
  const components = {
    ExerciseBlocks,
    ExerciseComponent,
    ExerciseAnswerDisplay,
    ExerciseAnswerDisplayContent,
    ExerciseCheckboxGroupField,
    ExerciseCheckboxTableGroupField,
    ExerciseDateField,
    ExerciseGridCheckboxGroupField,
    ExerciseGridRadioGroupField,
    ExerciseHiddenField,
    ExerciseIconRatingField,
    ExerciseImageField,
    ExerciseLineRatingField,
    ExerciseListCheckboxGroupField,
    ExercisePercentageField,
    ExercisePercentageGroupField,
    ExercisePercentageGroupRatingField,
    ExercisePieSliceField,
    ExercisePieSliceGroupField,
    ExercisePieSliceGroupRatingField,
    ExerciseRadioAccordionField,
    ExerciseRadioGroupField,
    ExerciseRankOrderField,
    ExerciseRepeatingGroupField,
    ExerciseResultsBlocksContainer,
    ExerciseAdvancedSelectField,
    ExerciseRichTextField,
    ExerciseTextareaField,
    ExerciseTextField,
    ExerciseParticipantSelectField,
    ExerciseTextReferenceCheckboxGroupField,
    ExerciseGuessingGame,
    ExerciseTimeZoneSelectField,
    ExerciseStickyNotesBrainstormIdeas,
    ExerciseStickyNoteUserSelectFavorite,
    ExerciseStickyNotesUserSelectTeamFavorites,
    ExerciseStickyNoteOverallTeamFavorites,
    ExerciseTeamLevelNormsEditableResults,
  }

  return getOrError(components, name, "Exercise component not found")
}

export { ExerciseComponent }
