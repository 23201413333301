import { getExerciseAnswer } from "../results_utils"

import getIconOrError from "icons"
import View from "ui/View"

const RadioGroupIcons = ({ identifier, exerciseComponent, exerciseInstance, className }) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const selectedChoiceOptions = choiceOptions.filter((option) => userSelections.includes(option.value))

  return (
    <View className={className}>
      {selectedChoiceOptions.map(({ value, icon, color }) => {
        const Icon = getIconOrError(icon)
        return <Icon key={value} color={color} />
      })}
    </View>
  )
}

export default RadioGroupIcons
