import { useRef } from "react"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"

export default function usePreviousValue(value) {
  const previousValue = useRef(null)
  useEffectAfterChange(() => {
    previousValue.current = value
  }, [value])
  return previousValue.current
}
