import { mashupExercises, getTalentsData } from "../results_utils/talents"

const TalentCoverage = ({ userIdMap, exercisesToCompare }) => {
  const talentsData = getTalentsData(exercisesToCompare[0])
  const talents = Object.keys(talentsData)
  const numTalents = talents.length
  const { numHighTalents } = mashupExercises(exercisesToCompare, userIdMap)

  return (
    <div className="text-gray-9 mb-xl">
      <h2>Coverage:</h2>
      <p>
        Together as a team, you have very high or high talent coverage across {numHighTalents} of the {numTalents}{" "}
        types.
      </p>
    </div>
  )
}

export default TalentCoverage
