import cn from "classnames"

import { getAppCodeURL } from "resources/monthly_kit"

const AppCodeURL = ({ className, short = true, protocol = false }) => {
  const url = getAppCodeURL({ short, protocol })
  return <div className={cn("line-break-anywhere", className)}>{url}</div>
}

export default AppCodeURL
