import cn from "classnames"
import DOMPurify from "dompurify"

import blockComponents from "components/Blocks/blockComponents"
import renderBlocks from "components/Blocks/renderBlocks"
import EvalExpression from "domains/Exercise/expression_dsl"

const ExerciseComponentBody = ({
  AsComponent,
  identifier,
  sentence,
  kit,
  saveOnChange,
  display_if,
  containerClassName,
  ...props
}) => {
  if (!AsComponent && !sentence) {
    return null
  }
  if (display_if && !EvalExpression(display_if)) {
    return null
  }

  return (
    <div key={identifier} className={containerClassName}>
      {!!sentence &&
        (Array.isArray(sentence) ? (
          <div className={cn("text-field-label", { "pb-xs": !!AsComponent })}>
            {renderBlocks(sentence, blockComponents)}
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sentence) }}
            className={cn("text-field-label", { "pb-xs": !!AsComponent })}
          />
        ))}
      {!!AsComponent && <AsComponent kit={kit} name={identifier} saveOnChange={saveOnChange} {...props} />}
    </div>
  )
}

export default ExerciseComponentBody
