import cn from "classnames"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import TextContainer from "ui/TextContainer"

const CareerHorizonsThinkingBigPathName = ({ exercise, path_field_id, className }) => {
  const path = getExerciseAnswer(exercise, path_field_id)

  return (
    <div className={cn("text-gray-9", className)}>
      <TextContainer>
        <h4 className="text-bold mb-xs">{path}</h4>
      </TextContainer>
    </div>
  )
}

export default CareerHorizonsThinkingBigPathName
