const CivilConversationsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Fostering a culture of civility in the workplace is not just a matter of politeness—it’s a strategic imperative.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.shrm.org/executive-network/insights/impact-of-civility-on-organizational-success"
          className="text-underline text-gray-9"
        >
          New SHRM research
        </a>{" "}
        highlights the connection between civil workplaces and positive business outcomes. The data underscores the
        critical role of civility in driving bottom-line results, from employee satisfaction to organizational turnover.
      </p>
      <h2 className="text-rising-orange pb-xs">Workplace incivility has become a regular occurrence</h2>
      <p className="mb-medium">
        A recent SHRM survey of over 1,000 US employees found that two-thirds of workers (66%) say they have{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.shrm.org/content/dam/en/shrm/topics-tools/topics/civility/2024-shrm-civility-research-infographic.pdf"
          className="text-underline text-gray-9"
        >
          experienced or witnessed incivility
        </a>{" "}
        in their workplace within the past month, and over half (57%) have experienced or witnessed incivility at work
        within the past week.
      </p>
      <p className="mb-medium">
        The most common forms of incivility witnessed or experienced included addressing others disrespectfully (36%
        observed this behavior), interrupting or silencing others while they were speaking (34%), and excessive
        monitoring or micromanaging (32%).
      </p>
      <h2 className="text-rising-orange pb-xs">Incivility is linked to job dissatisfaction, turnover, and more</h2>
      <p className="mb-medium">
        Employees who rate their workplace as uncivil{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.shrm.org/topics-tools/news/inclusion-equity-diversity/workplace-incivility-shrm-research-2024"
          className="text-underline text-gray-9"
        >
          are more than three times
        </a>{" "}
        as likely to express job dissatisfaction (28%) and more than twice as likely to consider leaving their job in
        the next year (38%).
      </p>
      <p className="mb-medium">
        Compared with employees who had not witnessed or experienced incivility in the past month, workers who had are
        less likely to be their authentic selves at work, less likely to believe they can share their honest thoughts
        without fear at work, and more likely to believe they must filter much of what they say at work, the research
        found.
      </p>
      <h2 className="text-rising-orange pb-xs">What can be done to cultivate civility at work</h2>
      <p className="mb-medium">
        A civil conversation is a dialogue between two or more people that intends to build a better understanding.
        Participants don't have to agree—what matters is active listening and learning about other people’s
        perspectives. A civil conversation focuses on process rather than results.
      </p>
      <p className="mb-medium">
        The following simple, yet powerful, core skills can go a long way to keeping conversations civil.
      </p>
      <h3>1. Listening: seek to understand</h3>
      <p className="mb-medium">
        Civil conversations often involve listening to someone who holds a different viewpoint. Participants don't have
        to agree—what matters is the act of listening to each other and learning about other people’s perspectives. The
        key here is to listen to understand rather than listen to respond, to be right, or to try to persuade the other
        person to agree with you.
      </p>
      <h3>2. Respect: value every voice</h3>
      <p className="mb-medium">
        Another aspect of civil conversation is sharing your authentic point of view while remaining respectful of
        others. Watch for anything that could be interpreted as insulting, rude, or judgmental to a specific individual
        or group of people. Avoid making sweeping generalizations, and use “I statements” to express your thoughts.
      </p>
      <h3>3. Empathy: Feel their viewpoint</h3>
      <p className="mb-medium">
        Empathy is our ability to understand what others are thinking and feeling. In conversations at work, try to set
        aside your own assumptions, imagine yourself in the other person's shoes, and try to see things from their point
        of view. Consider their background, experiences, and emotions that may influence their perspective.
      </p>
      <h3>4. Awareness: know when to take a break</h3>
      <p className="mb-medium">
        We all have topics that may bring up strong emotions and make it challenging to keep our cool. If you notice
        yourself or the group getting heated in any way, take a deep breath and remind yourselves of the key skills of
        listening, empathy, and respect. If the conversation gets to a place involving insults, shaming, or judgment,
        then it's no longer civil, and it's often better to take a break.
      </p>
      <p className="my-medium text-semi-bold">More resources:</p>
      <ul className="ml-xl">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/topics-tools/topics/civility"
            className="text-underline text-gray-9"
          >
            SHRM 1 Million Civil Conversations
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/topics-tools/news/inclusion-equity-diversity/workplace-incivility-shrm-research-2024"
            className="text-underline text-gray-9"
          >
            Workplace Incivility Is More Common Than You Think
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/topics-tools/news/bringing-political-civility-workplace"
            className="text-underline text-gray-9"
          >
            Bringing Political Civility into the Workplace
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/executive-network/insights/impact-of-civility-on-organizational-success"
            className="text-underline text-gray-9"
          >
            The Impact of Civility on Organizational Success
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/topics-tools/news/employee-relations/political-discussions-workplace"
            className="text-underline text-gray-9"
          >
            How to Manage Political Discussions in the Workplace
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shrm.org/topics-tools/news/employee-relations/email-communication-conflict"
            className="text-underline text-gray-9"
          >
            8 Tips for Avoiding Conflict in Email
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default CivilConversationsTraining1
