function getOrError<T>(obj: Record<string, T>, key: string, message: string): T {
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    const quoted = typeof key === "string" ? `"${key}"` : key
    throw new Error(`${message} (${quoted})`)
  }
  return obj[key] as T
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { getOrError }
