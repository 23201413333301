import { useEffect } from "react"

export default function useEffectAfterChange(effectFunc, dependencyArray) {
  if (!Array.isArray(dependencyArray)) {
    throw new Error(
      "useEffectAfterChange: You MUST pass a dependency array as the 2nd argument." +
        "\nINCORRECT:  useEffectAfterChange(() => { ... })" +
        "\n  CORRECT:  useEffectAfterChange(() => { ... }, [ <array of dependencies> ])"
    )
  }
  // eslint-disable-next-line no-restricted-syntax, react-hooks/exhaustive-deps
  useEffect(effectFunc, dependencyArray)
}
