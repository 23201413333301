import StandaloneNavigation from "./StandaloneNavigation"

const StandaloneIntro = ({ kit, exerciseInstance }) => (
  <div className="text-gray-9">
    <h1 className="mb-large">{kit.title}</h1>
    <p className="mb-large">{kit.intro_text}</p>
    <p className="mb-large">
      <b>Note:</b> The Stand-alone Exercise is similar to the group version of the exercise used during the team
      session, but without the group discussions and share-outs.
    </p>
    <StandaloneNavigation kit={kit} exerciseInstance={exerciseInstance} />
  </div>
)

export default StandaloneIntro
