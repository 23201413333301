import DOMPurify from "dompurify"
import { Formik } from "formik"
import { Navigate, Route, useLocation, useParams } from "react-router"

import ExerciseContext from "../ExerciseContext"
import { triggerStandaloneExerciseCompleted } from "../resource"

import StandaloneIntro from "./StandaloneIntro"
import StandaloneNavigation from "./StandaloneNavigation"
import StandaloneOutro from "./StandaloneOutro"
import StandaloneResults, { hasResultsPage } from "./StandaloneResults"

import blockComponents from "components/Blocks/blockComponents"
import renderBlocks from "components/Blocks/renderBlocks"
import ErrorPage from "components/ErrorPage"
import { RouteSteps } from "components/Steps/Steps"
import ExerciseStep from "domains/Exercise/ExerciseStep"
import getIntroComponentOrError from "domains/Exercise/IntroComponents"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import { getStandaloneExerciseUrl, useKitDefinition } from "resources/monthly_kit"
import useQueryParams from "ui/hooks/useQueryParams"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"
import StepProgressBar from "ui/StepProgressBar"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const StandaloneExerciseStep = ({
  exerciseInstance,
  kit,
  definition,
  step,
  saveOnChange,
  onFinishUrl,
  triggerExerciseCompleteOnNext,
}) => {
  const { description, standalone_description, intro_component } = step

  const getStepUrl = (step, idx) =>
    getStandaloneExerciseUrl({ slug: kit.slug, teamId: exerciseInstance.team_id, stepNumber: idx, onFinishUrl })
  const standaloneSteps = definition.steps.filter((step) => !step.session_only)
  const breadcrumbs = <StepProgressBar currentStep={step} allSteps={standaloneSteps} getStepUrl={getStepUrl} />
  const IntroComponent = intro_component && getIntroComponentOrError(intro_component)
  const stepDescription = standalone_description || description

  const onNextClick = () => {
    if (triggerExerciseCompleteOnNext) {
      triggerStandaloneExerciseCompleted(exerciseInstance.id)
    }
  }

  return (
    <>
      <div className="text-rising-orange text-small text-bold">STANDALONE EXERCISE</div>
      {!!step.title && (
        <>
          <View $alignItems="center" $justifyContent="space-between" className="mb-medium">
            <h1 className="text-gray-9">{step.title}</h1>
          </View>
          {!!breadcrumbs && <div className="mb-medium">{breadcrumbs}</div>}
        </>
      )}
      {IntroComponent ? (
        <>
          <IntroComponent />
        </>
      ) : (
        <>
          <TextContainer>
            {!!stepDescription && (
              <p
                className="text-gray-9 mb-medium"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(stepDescription) }}
              />
            )}
          </TextContainer>
          <ExerciseStep kit={kit} is_standalone={true} step={step} saveOnChange={saveOnChange} />
        </>
      )}
      <StandaloneNavigation kit={kit} onNextClick={onNextClick} exerciseInstance={exerciseInstance} />
      <FooterBlocks footerBlocks={kit.footer_blocks} step={step} />
    </>
  )
}

const FooterBlocks = ({ footerBlocks, step }) => {
  if (!footerBlocks || step.path === "exercise-intro") {
    return null
  }

  return <View $justifyContent="center">{renderBlocks(footerBlocks, blockComponents)}</View>
}

export default function StandaloneExercise() {
  const { selectedTeamId } = useSelectedTeamIdWithTeamIdQueryParam()
  const { kitSlug } = useParams()
  const { search } = useLocation()
  const { onFinishUrl } = useQueryParams()
  const { data: kit } = useKitDefinition({ teamId: selectedTeamId, slug: kitSlug })

  const exercise = kit?.exercise ?? {}
  const { slug, version } = exercise
  const { initialValues, onSubmit, saveOnChange, definition, exerciseInstance, isInvalid, isFetching } =
    useExerciseForm({
      teamId: selectedTeamId,
      slug,
      version,
    })

  if (!kit || (!exerciseInstance && isFetching)) {
    return null
  }

  if (isInvalid) {
    return <ErrorPage />
  }

  const standaloneSteps = definition.steps.filter((step) => !step.session_only)

  return (
    <div className="main-container full-width">
      <ExerciseContext.Provider value={{ exerciseInstance }}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <RouteSteps>
            {!!kit.intro_text && (
              <Route path="intro" element={<StandaloneIntro kit={kit} exerciseInstance={exerciseInstance} />} />
            )}
            {standaloneSteps.map((step, index) => {
              const isLastExerciseStep = index + 1 === standaloneSteps.length

              return (
                <Route
                  path={`${index}`}
                  key={index}
                  element={
                    <StandaloneExerciseStep
                      exerciseInstance={exerciseInstance}
                      kit={kit}
                      definition={definition}
                      step={step}
                      saveOnChange={saveOnChange}
                      onFinishUrl={onFinishUrl}
                      triggerExerciseCompleteOnNext={isLastExerciseStep}
                    />
                  }
                />
              )
            })}
            {hasResultsPage(exerciseInstance) && (
              <Route path="results" element={<StandaloneResults kit={kit} exerciseInstance={exerciseInstance} />} />
            )}
            {!!kit.outro_paragraphs && (
              <Route path="outro" element={<StandaloneOutro kit={kit} exerciseInstance={exerciseInstance} />} />
            )}
            <Route
              path="*"
              ignore
              element={<Navigate to={kit.intro_text ? `intro${search}` : `0${search}`} replace />}
            />
          </RouteSteps>
        </Formik>
      </ExerciseContext.Provider>
    </div>
  )
}
