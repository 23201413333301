// Note: Keep in sync with SurveyQuestionType in backend/risingteam/engagement_survey_constants.py
enum SurveyQuestionType {
  AGREE_SCALE_7_POINTS = "AGREE_SCALE_7_POINTS",
  LIKELY_SCALE_10_POINTS = "LIKELY_SCALE_10_POINTS",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  RICH_TEXT = "RICH_TEXT",
}

// disabled since we'll add more constant exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { SurveyQuestionType }
