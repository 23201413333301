import { useLocation } from "react-router-dom"

import useEffectAfterEveryRender from "ui/hooks/useEffectAfterEveryRender"
import usePreviousValue from "ui/hooks/usePreviousValue"

export default function useEffectAfterLocationChange(
  onLocationChangeFunc,
  { runOnPathChanges = true, runOnSearchChanges = true, runOnHashChanges = true, detectChangesFunc = null } = {}
) {
  const loc = useLocation()
  const prevLoc = usePreviousValue(loc)

  const path = loc?.pathname?.length ? loc.pathname : null
  const search = loc?.search?.length ? loc.search : null
  const hash = loc?.hash?.length ? loc.hash : null

  const prevPath = prevLoc?.pathname?.length ? prevLoc.pathname : null
  const prevSearch = prevLoc?.search?.length ? prevLoc.search : null
  const prevHash = prevLoc?.hash?.length ? prevLoc.hash : null

  const hasLocChanged = detectChangesFunc
    ? detectChangesFunc()
    : (runOnPathChanges && !!(path || prevPath) && path !== prevPath) ||
      (runOnSearchChanges && !!(search || prevSearch) && search !== prevSearch) ||
      (runOnHashChanges && !!(hash || prevHash) && hash !== prevHash)

  useEffectAfterEveryRender(() => {
    if (hasLocChanged) {
      const cleanup = onLocationChangeFunc(loc, prevLoc)
      return cleanup
    }
  })
}
