import { TableTooltip, Chip } from "@nivo/tooltip"
import { mapValues, isArray } from "lodash-es"
import { useMemo } from "react"

import { getChartColorMap } from "ui/theme"

const CHART_MAX_VALUE = 100
const MAX_ROWS_TO_TRUNCATE_SLICE_TOOLTIP = 15
const TRUNCATE_SLICE_TOOLTIP_ROWS_TO_DISPLAY = 5

const getCategoryToPointsMap = (exercise) => {
  const exerciseSteps = exercise.definition.steps
  const exerciseComponents = exerciseSteps.flatMap((step) => step.components)
  const rankOrderComponents = exerciseComponents.filter((component) => component.component === "ExerciseRankOrderField")
  const rankOrderAnswers = rankOrderComponents
    .map((component) => exercise.answers.find((answer) => answer.identifier === component.identifier))
    .filter((a) => a)

  const categoryToPointsMap = {}
  rankOrderAnswers.forEach((answer) => {
    if (!isArray(answer.data)) {
      return
    }
    answer.data.forEach((category, index) => {
      const points = answer.data.length - index - 1
      const currentPoints = categoryToPointsMap[category] ?? 0
      categoryToPointsMap[category] = currentPoints + points
    })
  })

  return categoryToPointsMap
}

const pointsToBucketIndex = (points, maxPointsPerBucket) => {
  const bucketIndex = maxPointsPerBucket.findIndex((maxBucketPoints) => points <= maxBucketPoints)
  return bucketIndex < 0 ? maxPointsPerBucket.length - 1 : bucketIndex
}

// Buckets are not evenly distributed, but the chart needs to have evenly distributed buckets.
// Therefore we need to normalize the points to be evenly distributed
const getChartValues = (categoryToPointsMap, maxPointsPerBucket) =>
  mapValues(categoryToPointsMap, (points) => {
    // The radar chart has one ring per bucket plus an extra ring for aesthetic reasons
    const chartBucketSize = CHART_MAX_VALUE / (maxPointsPerBucket.length + 1)

    const bucketIndex = pointsToBucketIndex(points, maxPointsPerBucket)
    const maxPoints = maxPointsPerBucket[bucketIndex]
    const minPoints = bucketIndex > 0 ? maxPointsPerBucket[bucketIndex - 1] + 1 : 0
    const numPossiblePointsInBucket = maxPoints - minPoints + 1
    const valuePerPoint = chartBucketSize / (numPossiblePointsInBucket + 1)

    const categoryPointsValue = (points - minPoints + 1) * valuePerPoint
    const bucketMinValue = chartBucketSize * (bucketIndex + 1)

    return (categoryPointsValue + bucketMinValue).toFixed(0)
  })

const createGetColorFunc = (focusedUsername) => (keys) => {
  const colorMap = getChartColorMap(keys)

  return ({ key }) => {
    if (focusedUsername && focusedUsername !== key) {
      return "#EEEEEE"
    } else {
      return colorMap[key]
    }
  }
}

const createFocusUserFunc =
  ({ focusedUsername, setFocusedUsername }) =>
  (username) => {
    if (focusedUsername !== username) {
      setFocusedUsername(username)
    } else {
      setFocusedUsername(null)
    }
  }

const createTruncatedSliceTooltipFunc =
  (focusedUsername) =>
  ({ index, data }) => {
    const rows = useMemo(() => getTruncatedSliceTooltipRows(data, focusedUsername), [data])
    return <TableTooltip title={<strong>{index}</strong>} rows={rows} />
  }

const getTruncatedSliceTooltipRows = (data, focusedUsername) => {
  if (focusedUsername) {
    const focusedUserDataRow = data.find((d) => d.id === focusedUsername)
    return [getSliceTooltipRow(focusedUserDataRow)]
  }

  if (data.length <= MAX_ROWS_TO_TRUNCATE_SLICE_TOOLTIP) {
    return data.map(getSliceTooltipRow)
  }

  const topRows = data.slice(0, TRUNCATE_SLICE_TOOLTIP_ROWS_TO_DISPLAY)
  const threeDots = <div className="text-medium">. . .</div>
  return [...topRows.map(getSliceTooltipRow), [null, threeDots, null]]
}

const getSliceTooltipRow = (datum) => [<Chip key={datum.id} color={datum.color} />, datum.id, datum.formattedValue]

export {
  CHART_MAX_VALUE,
  getCategoryToPointsMap,
  pointsToBucketIndex,
  getChartValues,
  createGetColorFunc,
  createFocusUserFunc,
  createTruncatedSliceTooltipFunc,
}
