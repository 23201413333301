import { Navigate } from "react-router"

import { useSideBarMenuItems } from "components/Sidebar"

const Home = () => {
  const { sideBarMenuItems, isFetching: sideBarIsFetching } = useSideBarMenuItems()

  if (sideBarIsFetching) {
    return null
  }

  const to = sideBarMenuItems[0].path
  return <Navigate to={to} replace />
}

export default Home
