function SvgReorderIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.333 4c0-.368.299-.667.667-.667h12a.667.667 0 010 1.334H2A.667.667 0 011.333 4zm0 4c0-.368.299-.667.667-.667h12a.667.667 0 010 1.334H2A.667.667 0 011.333 8zM2 11.333a.667.667 0 000 1.334h12a.667.667 0 100-1.334H2z"
        stroke="#777777"
        fill="#777777"
      />
    </svg>
  )
}

export default SvgReorderIcon
