export default function SearchResults({ query, items, str, result, empty = null }) {
  str = str ?? ((x) => x.toString())
  const rank = (item) => {
    const value = str(item).toLowerCase()
    return query.reduce((acc, term) => {
      const matchIdx = value.indexOf(term)
      return acc + (matchIdx === -1 ? value.length : matchIdx)
    }, 0)
  }
  const sort = (itemA, itemB) => rank(itemA) - rank(itemB)
  const filter = (item) => query.every((w) => str(item).toLowerCase().includes(w))
  items = !query.length ? items : items.filter(filter).slice(0, 100).sort(sort)
  // slice here for sort performance purposes; if desired match isn't in first 100
  // results it'll show up once search is refined (query longer than a few chars)
  return empty && !items.length ? empty : items.map(result)
}
