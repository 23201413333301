const BOHEmployeeExperienceTraining2 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Now that we understand what employee experience is and how great EX leads to great CX, we will dive into each
        Employee Experience (EX) Principle and discuss what the principles look like in action.
      </p>
      <p className="mb-medium">
        In this Rising Team kit, the group will share their own experiences for each principle which will lead to
        greater understanding for each of the teammate experiences and provide understanding on how to deliver the EX
        Principles for each other.
      </p>
      <h2 className="text-rising-orange mb-medium">Preparation for inspiration</h2>
      <p className="mb-medium">
        Before we start this kit, it's important to have all team members in a relaxed, open mindset. The ground rules
        give members guidelines to follow during the kit while the warmup activity allows them to start thinking of what
        "inspiration" means to them.
      </p>
      <p className="mb-medium">
        Inspiration can be at work or home and come in all forms including family, sports, religion/beliefs, etc. As a
        team lead, show support for your team when they share how their experiences inspired them to continue to build
        comfort in sharing personal stories as you move through the kit.
      </p>
      <h2 className="text-rising-orange mb-medium">The EX Principles</h2>
      <p className="mb-medium">
        As you begin the concept of the Employee Experience Principles, share with the team that at Bank of Hawaiʻi the
        employee experience is about creating a culture and environment where we can all do our best work and thrive. 
        And when you Thrive you Shine! We want all of our team members to have the opportunity to shine and
        understanding the EX Principles will help us to activate on it.
      </p>
      <h2 className="text-rising-orange mb-medium">Practicing the Principles</h2>
      <p className="mb-medium">
        The main exercise has all four principles for the group to talk through. The first section is "Value Me", which
        explains the different forms & actions of valuing someone. Team members will then share a time they felt valued
        and what people could do to show their work is valued.
      </p>
      <p className="mb-medium">
        The second section is "Know Me" and talks about how to show someone their voice is being acknowledged. Team
        members will then share a time they felt understood and one fact that everyone would be surprised to know about
        them.
      </p>
      <p className="mb-medium">
        The third section is "Empower Me" and what empowerment could look like. Team members will then share a time they
        felt empowered and one thing they would like to try when empowered.
      </p>
      <p className="mb-medium">
        The fourth section is "Inspire Me" and different methods for inspiration. Team members will then share a time
        they felt inspired at work and what could inspire them to do their best. They may also attach an image of
        something that brings them inspiration.
      </p>
      <h2 className="text-rising-orange mb-medium">Lasting Impressions</h2>
      <p className="mb-medium">
        When the group has completed answering all the questions, everyone will share their experiences with each
        principle and what would replicate that same feeling. Again, as team lead please continue to create a supportive
        atmosphere as topics may be personal. Overall you want to make sure your team knows this is a safe environment
        and that their thoughts/needs are being heard.
      </p>
      <p className="mb-medium">
        In the next step of your prep, you will learn how to take the groups needs and incorporate them into your daily
        tasks.
      </p>
    </div>
  </div>
)

export default BOHEmployeeExperienceTraining2
