import cn from "classnames"

import ArtiChatInputArea from "./ArtiChatInputArea"
import ArtiChatMessages from "./ArtiChatMessages"
import ArtiTeamSelectorMessage from "./ArtiTeamSelectorMessage"

const ArtiConversationTypeTeam = ({
  user,
  team,
  teams,
  llm,
  selectedDiscussionTeam,
  onDiscussionTeamChange,
  showTeamSummary,
  conversationTypeSelected,
  discussionTeamId,
  isFetchingHistorySession,
  messages,
  loading,
  historyExchangeId,
  chatInputRef,
  artiTourPopularTopicsMenuOpen,
  isComponentMounted,
  onSubmit,
}) => (
  <>
    <ArtiTeamSelectorMessage
      user={user}
      teams={teams}
      llm={llm}
      selectedTeam={selectedDiscussionTeam}
      discussionTeamId={discussionTeamId}
      onDiscussionTeamChange={onDiscussionTeamChange}
      showTeamSummary={showTeamSummary}
      conversationTypeSelected={conversationTypeSelected}
    />
    <div
      className={cn("fadeable conversation-container", {
        hidden: !discussionTeamId || isFetchingHistorySession,
      })}
    >
      <ArtiChatMessages
        messages={messages}
        user={user}
        team={team}
        loading={loading}
        llm={llm}
        historyExchangeId={historyExchangeId}
        chatInputRef={chatInputRef}
      />
      <ArtiChatInputArea
        loading={loading}
        isComponentMounted={isComponentMounted}
        onSubmit={onSubmit}
        chatInputRef={chatInputRef}
        messages={messages}
        user={user}
        team={team}
        artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
        conversationTypeSelected={conversationTypeSelected}
        className="mb-medium"
      />
    </div>
  </>
)

export default ArtiConversationTypeTeam
