import { merge } from "lodash-es"
import { Helmet } from "react-helmet-async"
import { styled } from "styled-components"

import { SquareCheckIcon } from "icons/FontAwesomeIcons"
import { useModal } from "ui/ModalContext"

const HubSpotModal = styled(function HubSpotModal({ className, isGratitudePromo = false }) {
  const embedSrc = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
  const meetingLink = isGratitudePromo
    ? "https://meetings.hubspot.com/serena51/gratitude-kit?embed=true"
    : "https://meetings.hubspot.com/serena51/risingteam?embed=true"
  return (
    <div className={className}>
      <div className="meetings-iframe-container" data-src={meetingLink}></div>
      <Helmet>
        <script type="text/javascript" src={embedSrc}></script>
      </Helmet>
    </div>
  )
})`
  .meetings-iframe-container {
    padding-top: var(--spacing-2);
    padding-left: 2px;
    overflow: hidden;
    height: 625px;
  }
`

function useHubSpotModal({ isGratitudePromo = false } = {}) {
  const { setModal, ...rest } = useModal()
  const defaultModalStyles = { content: { width: "auto" } }
  return {
    ...rest,
    setModal: (modalConfig) =>
      setModal(
        merge(
          {
            title: (
              <div className="text-white">
                <SquareCheckIcon className="mr-xxs" /> Book a call
              </div>
            ),
            content: <HubSpotModal isGratitudePromo={isGratitudePromo} />,
            maxHeight: "710px",
            styles: defaultModalStyles,
            modalWithColorTitleBar: true,
            modalWithColorBorder: true,
            positionOffsetY: "calc(-50vh + 360px)",
            // positionOffsetY calculated to keep modal at top of screen on all devices.
            // The HubSpot embed is quite tall and we want to avoid any chance of bottom
            // being cut off.
          },
          modalConfig
        )
      ),
  }
}

export default HubSpotModal
export { useHubSpotModal }
