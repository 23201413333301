import { useFieldValue } from "./ExerciseComponents/exercise_component_utils"

const EvalExpression = (expr) => {
  const { operation, args } = expr
  switch (operation) {
    case "equals":
      return Equals(args)
    case "field_value":
      return FieldValue(args)
    default:
      return expr
  }
}

const Equals = ([expr1, expr2]) => EvalExpression(expr1) === EvalExpression(expr2)

const FieldValue = ([expr]) => useFieldValue(EvalExpression(expr))

export default EvalExpression
