const MultiUserIfAnyAnswerEquals = ({ identifier, exerciseInstances, answerValue, noResultsBlocks, children }) => {
  const exerciseAnswers = exerciseInstances
    .flatMap((instance) => instance.answers)
    .filter((answer) => answer.identifier === identifier)

  const hasAnswerWithValue = exerciseAnswers.some((exerciseAnswer) => exerciseAnswer.data.value === answerValue)

  return <>{hasAnswerWithValue ? children : noResultsBlocks}</>
}

export default MultiUserIfAnyAnswerEquals
