import DOMPurify from "dompurify"
import { isValidElement } from "react"
import { styled } from "styled-components"

import Tooltip from "./Tooltip"
import View from "./View"

import getIconOrError from "icons"

const AnswerDisplayContentWithIcon = styled(function AnswerDisplayContentWithIcon({
  title,
  answer,
  iconConfig = {},
  ...props
}) {
  const Icon = iconConfig?.icon && getIconOrError(iconConfig.icon)

  return (
    <div {...props}>
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
        className="mb-xs text-semi-bold text-gray-9"
      />
      <View
        className="answer-container text-gray-9 bg-gray-1 border border-gray-4 border-radius py-small px-medium"
        $justifyContent="space-between"
      >
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />
        {!!Icon && (
          <View $alignItems="center" className="answer-icon-container">
            <Tooltip top float title={iconConfig.tooltip} disabled={!iconConfig.tooltip}>
              <Icon color={iconConfig.color} className="fa-lg ml-medium" />
            </Tooltip>
          </View>
        )}
      </View>
    </div>
  )
})`
  .answer-container {
    min-height: 52px;

    .answer-icon-container {
      height: 28px;
      width: initial;
    }

    ol,
    ul {
      margin: 0;
      padding-left: 16px;

      li {
        color: inherit;
      }
    }
  }
`

const AnswerDisplayContent = ({ title, answer, ...props }) => {
  const titleElement = isValidElement(title) ? (
    title
  ) : (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} className="mb-xs text-bold text-gray-9" />
  )
  const containerClassName = [
    "answer",
    "text-gray-9",
    "bg-gray-1",
    "border",
    "border-gray-4",
    "border-radius",
    "py-small",
    "px-medium",
    "mb-small",
  ].join(" ")
  const answerElement = isValidElement(answer) ? (
    <div className={containerClassName}>{answer}</div>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} className={containerClassName} />
  )

  return (
    <div {...props}>
      {titleElement}
      {answerElement}
    </div>
  )
}

export default styled(AnswerDisplayContent)`
  .answer {
    min-height: 52px;

    ol,
    ul {
      margin: 0;
      padding-left: 16px;

      li {
        color: inherit;
      }
    }
  }
`

export { AnswerDisplayContentWithIcon }
