import { useFormikContext } from "formik"
import { useNavigate } from "react-router"

import { useSteps } from "components/Steps/StepsContext"
import { getResultsUrl } from "resources/monthly_kit"
import BackButton from "ui/BackButton"
import useQueryParams from "ui/hooks/useQueryParams"
import NextButton from "ui/NextButton"
import View from "ui/View"

const StandaloneNavigation = ({ kit, exerciseInstance, onNextClick }) => {
  const navigate = useNavigate()
  const { onFinishUrl } = useQueryParams()
  const formikContext = useFormikContext()
  const { next, previous } = useSteps()
  const navigateToOnFinish = onFinishUrl ?? getResultsUrl({ slug: kit.slug, teamId: exerciseInstance.team_id })

  return (
    <View className="pb-medium mt-xxxxl" $justifyContent={previous ? "center" : "flex-start"}>
      {previous ? (
        <BackButton
          className="secondary"
          onClick={() => {
            if (formikContext) {
              // Submit can happen in background during nav back, we're
              // not worried about rendering stale date on previous step.
              formikContext.submitForm()
            }
            navigate(previous)
          }}
        />
      ) : (
        <div />
      )}
      {next ? (
        <NextButton
          onClick={async () => {
            if (formikContext) {
              await formikContext.submitForm()
            }
            if (onNextClick) {
              await onNextClick()
            }
            navigate(next)
          }}
        />
      ) : (
        <NextButton
          text="Finish"
          onClick={async () => {
            if (formikContext) {
              await formikContext.submitForm()
            }
            navigate(navigateToOnFinish)
          }}
        />
      )}
    </View>
  )
}

export default StandaloneNavigation
