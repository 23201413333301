import cn from "classnames"

import { useBillingData } from "./BillingDataContext"

import { StyledSelect } from "forms/fields/SelectField"

const NumTeamsDropdown = ({ className }) => {
  const { billingData, setBillingData } = useBillingData()

  const onQuantityChange = (e) => {
    const quantity = parseInt(e.target.value)
    setBillingData({ ...billingData, ...{ quantity } })
  }

  return (
    <div className={cn(className, "mb-large")}>
      <label>
        <div className="text-field-label label mb-xs">Number of teams</div>
        <StyledSelect value={billingData.quantity} onChange={onQuantityChange}>
          {[...Array(10)].map((_, idx) => {
            const num = idx + 1
            return (
              <option key={num} value={num}>
                {num} {num === 1 ? "team" : "teams"}
              </option>
            )
          })}
        </StyledSelect>
      </label>
    </div>
  )
}

export default NumTeamsDropdown
