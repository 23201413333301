import { useState } from "react"
import { useNavigate } from "react-router"

import TeamNameField from "domains/Setup/utils/TeamNameField"
import { CheckIcon, DoNotEnterIcon } from "icons/FontAwesomeIcons"
import { useAdminAccounts, useCreateAccountTeam } from "resources/billing"
import { sortUserTeams, useTeams } from "resources/teams"
import { useUpdateCurrentUser, useUser } from "resources/users"
import Button from "ui/Button"
import Loading from "ui/Loading"
import View from "ui/View"

const SetUpTeamStep = () => {
  // Update the number of participants using userAccount?.max_session_participants
  // when Onboarding flow if finalized

  const navigate = useNavigate()
  const { data: user } = useUser({ userId: "me" })
  const { data: teams } = useTeams()
  const { data: adminAccounts } = useAdminAccounts()
  const [createTeamNow, setCreateTeamNow] = useState(false)
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const freeTrial = !!adminAccounts?.[0]?.is_trial

  // Team leads will always have their first team created by the time they get to
  // this step, so get account ID from that team.
  // Admins may _not_ have their first team created, so we need to take the ID
  // from their first admin account (this is also the case for account owner).
  const teamAccountId = teams?.[0]?.account_id ?? adminAccounts?.[0]?.id ?? null
  const { mutateAsync: createTeam } = useCreateAccountTeam(teamAccountId)

  const stepTitle = <h3 className="mb-medium">Set up your team</h3>

  if (!user || !teamAccountId) {
    return (
      <>
        {stepTitle}
        <Loading className="mt-xxxxl" />
      </>
    )
  }

  const sortedTeams = sortUserTeams({ user, teams })

  const skipCreateTeam = async () => {
    await updateCurrentUser({ setup_will_not_lead_teams: true })
    const redirect_next = user.setup_steps[3] // Specify step this might change
    setCreateTeamNow(false)
    navigate(`../${redirect_next.path}`)
  }

  const createFirstTeam = async () => {
    await updateCurrentUser({ setup_will_not_lead_teams: false })
    const team_lead_id = user.id
    const team_name = !!teams?.length ? `${user.first_name}'s team ${teams?.length + 1}` : `${user.first_name}'s team 1`
    await createTeam({
      team_name,
      team_lead_id,
    })
    setCreateTeamNow(true)
  }

  const createNewTeam = async () => {
    const team_lead_id = user.id
    const team_name = !!teams?.length ? `${user.first_name}'s team ${teams?.length + 1}` : `${user.first_name}'s team 1`
    await createTeam({
      team_name,
      team_lead_id,
    })
  }

  return (
    <div>
      {stepTitle}
      {!!sortedTeams?.length || createTeamNow ? (
        <>
          <h4 className="mb-medium">Your teams</h4>
          {sortedTeams.map((team) => (
            <TeamNameField key={team.id} team={team} currentUser={user} />
          ))}
          <p className="py-small">
            Sessions can accommodate up to <b>14 participants</b>. If want to run sessions with more than 14 people, we
            recommend splitting them into two teams so that everyone has a chance to share and participate.
            {!!freeTrial && (
              <>
                {" "}
                Your subscription comes with unlimited teams, so you can run Rising Team sessions with as many teams as
                you like.
              </>
            )}
          </p>
          {!!freeTrial ? (
            <div className="text-center link-semi-bold mt-small">
              <a
                href="mailto:hello@risingteam.com"
                target="_blank"
                rel="noopener noreferrer"
                className="self-align-center"
              >
                Contact us
              </a>
            </div>
          ) : (
            <Button className="link-semi-bold mt-small full-width" onClick={() => createNewTeam()}>
              + Add another
            </Button>
          )}
        </>
      ) : (
        <>
          <p className="mb-medium">
            If you are planning to run Rising Team sessions with your own team (or teams), you can set them up here.
          </p>
          <p className="mb-small text-field-label">Do you want to run Rising Team sessions with your team(s)?</p>
          <View className="mb-small">
            <Button className="tertiary large" onClick={() => createFirstTeam()}>
              <CheckIcon color="var(--gray-7)" className="mr-xs fa-xl" />
              Yes
            </Button>
            <Button className="tertiary large" onClick={() => skipCreateTeam()}>
              <DoNotEnterIcon color="var(--gray-7)" className="mr-xs fa-xl" />
              No
            </Button>
          </View>
        </>
      )}
    </div>
  )
}

export default SetUpTeamStep
