import { Formik, Form } from "formik"
import { Link, useNavigate } from "react-router-dom"

import { resetPassword } from "../resource"

import HiddenField from "forms/fields/HiddenField"
import PasswordField from "forms/fields/PasswordField"
import FormMessage from "forms/FormMessage"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import useQueryParams from "ui/hooks/useQueryParams"
import MailToLink from "ui/MailToLink"
import SubmitButton from "ui/SubmitButton"

const InputForm = () => (
  <>
    <h2 className="mb-xxxxl text-bold">Reset password</h2>
    <Form style={{ display: "flex", flexDirection: "column" }} className="space-y-large">
      <HiddenField name="uid" />
      <HiddenField name="token" />
      <PasswordField label="Enter new password" name="new_password1" autoComplete="new-password" autoFocus />
      <PasswordField label="Confirm new password" name="new_password2" autoComplete="new-password" />
      <FormMessage />
      <div>
        <SubmitButton>Done</SubmitButton>
      </div>
    </Form>
  </>
)

const InviteSchema = Yup.object().shape({
  new_password1: Yup.string().required("This field is required."),
  new_password2: Yup.string().oneOf([Yup.ref("new_password1"), ""], "Passwords must match"),
})

interface ResetPasswordFormValues {
  uid: string
  token: string
  new_password1: string
  new_password2: string
}

const ResetPasswordForm = () => {
  const navigate = useNavigate()
  const { uid, token } = useQueryParams()

  const invalidPasswordResetLinkMessage = (
    <>
      Invalid password reset link. The link may have already been used or it may have expired. Please request a new{" "}
      <Link to="/auth/forgot">password reset link</Link> or contact <MailToLink email="support@risingteam.com" />.
    </>
  )

  const handleErrorDataFn = (
    data: Record<string, unknown>,
    _values: ResetPasswordFormValues,
    _formik: unknown,
    _exception: Error,
    responseStatus: number
  ) => {
    if (responseStatus === 403) {
      return { message: invalidPasswordResetLinkMessage }
    }
    return data
  }

  const onSubmit = handleErrors(async (values: ResetPasswordFormValues) => {
    await resetPassword(values)
    navigate("/")
  }, handleErrorDataFn)

  const initialValues = {
    uid: uid || "",
    token: token || "",
    new_password1: "",
    new_password2: "",
  }

  return (
    <Formik initialValues={initialValues} validationSchema={InviteSchema} onSubmit={onSubmit} component={InputForm} />
  )
}

export default ResetPasswordForm
