import { Helmet } from "react-helmet-async"

import { useRTBrandName } from "components/AccountTypeContext"

const PageTitle = ({ children }) => {
  const rtBrandName = useRTBrandName()
  const parts = [rtBrandName]
  if (children) {
    parts.push(children)
  }
  return (
    <Helmet>
      <title>{parts.join(" | ")}</title>
    </Helmet>
  )
}

export default PageTitle
