import { useCallback } from "react"

import useEffectAfterChange from "./useEffectAfterChange"

function keyEventOriginatesFromTextField(ev) {
  const targetTag = ev.target?.tagName?.toLowerCase()
  return ["input", "textarea"].includes(targetTag) || ev.target?.isContentEditable
}

export default function useKeyDown(
  {
    key = null,
    keys = [],
    meta = false, // Whether to require ev.metaKey to be pressed for handler to fire
    control = false, // Whether to require ev.ctrlKey to be pressed for handler to fire
    shift = false, // Whether to require ev.shiftKey to be pressed for handler to fire
    alt = false, // Whether to require ev.altKey to be pressed for handler to fire
    preventDefault = true, // Whether to call ev.preventDefault() on the key event
    stopPropagation = true, // Whether to call ev.stopPropagation() on the key event
    // Note: the above are only called for key events that match provided keys
    skipIfTextFieldFocused = false,
    // If true, won't fire event handler if a text element is currently focused
  },
  onKeyDown
) {
  if (key && keys?.length) {
    throw new Error("useKeyDown: `key` and `keys` cannot both be provided at once.")
  } else if (!key && !keys?.length) {
    throw new Error("useKeyDown: `key` or `keys` must be provided, but none were found.")
  } else if (key) {
    keys = [key]
  }

  const handler = useCallback(
    (ev) => {
      if (
        keys.includes(ev.key) &&
        (!meta || ev.metaKey) &&
        (!control || ev.ctrlKey) &&
        (!shift || ev.shiftKey) &&
        (!alt || ev.altKey) &&
        (!skipIfTextFieldFocused || !keyEventOriginatesFromTextField(ev))
      ) {
        if (preventDefault) {
          ev.preventDefault()
        }
        if (stopPropagation) {
          ev.stopPropagation()
        }
        return onKeyDown(ev)
      }
    },
    [keys, onKeyDown, meta, control, shift, alt, skipIfTextFieldFocused, preventDefault, stopPropagation]
  )

  useEffectAfterChange(() => {
    window.addEventListener("keydown", handler)
    return () => window.removeEventListener("keydown", handler)
  }, [handler])
}
