import cn from "classnames"

import { getExerciseAnswer } from "../results_utils"

import { useSessionOrTeamExerciseInstances } from "domains/Exercise/resource"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useHasTeamFeature } from "utils/team"

const RichTextFieldValueReferenceText = ({ className, identifier, fallbackText, exerciseInstance }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: exerciseInstances = [], isInitialLoading } = useSessionOrTeamExerciseInstances(exerciseInstance.slug, {
    refetchInterval: 30000,
    sessionRealtimeUpdates,
  })

  if (!exerciseInstance || isInitialLoading) {
    return null
  }

  const referenceAnswerId = parseInt(getExerciseAnswer(exerciseInstance, identifier))
  let textFieldAnswer = null
  exerciseInstances.find(({ answers }) => {
    textFieldAnswer = answers.find(({ id }) => id === referenceAnswerId)?.data ?? null
    return textFieldAnswer
  })

  const answer = textFieldAnswer?.["value"] ?? textFieldAnswer

  if (!answer) {
    return <p className="mb-large text-gray-8 text-italic">{fallbackText}</p>
  }

  return <AnswerDisplayContent answer={answer} className={cn(className, "mb-large")} />
}

export default RichTextFieldValueReferenceText
