function selectedText(): string {
  return window.getSelection?.()?.toString() ?? ""
  // gracefully degrade if browser selection APIs are not available
}

function isTextSelected(text: string): boolean {
  if (text && text.length) {
    return selectedText().trim() === text.trim()
  } else {
    return !!selectedText().length
  }
}

function moveCursorToEnd(inputOrTextarea: HTMLInputElement | HTMLTextAreaElement): void {
  if (inputOrTextarea) {
    const len = inputOrTextarea.value.length
    inputOrTextarea.selectionStart = len
    inputOrTextarea.selectionEnd = len
  }
}

export { selectedText, isTextSelected, moveCursorToEnd }
