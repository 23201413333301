import { useFormikContext } from "formik"
import { get } from "lodash-es"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"

const ExerciseAdvancedSelectField = ({ name, options, option_field_ids, ...props }) => {
  const fieldName = `${name}.value`
  const { values } = useFormikContext()

  let fieldOptions
  if (!!option_field_ids.length) {
    fieldOptions = option_field_ids
      .map((id) => {
        const label = get(values, id)
        return {
          value: id,
          label,
        }
      })
      .filter(({ label }) => label)
  } else {
    fieldOptions = options
  }

  return <AdvancedSelectField name={fieldName} options={fieldOptions} {...props} />
}

export default ExerciseAdvancedSelectField
