const MotivatorsTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        After your Rising Team session on Motivators, here's how to continue to harness the power of motivation with
        your team.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Ask what's working (and what's not)</h2>
      <p className="mb-medium">
        The fastest way to understand what drives someone is to simply ask. Now that the team has told you what's most
        important to them, they can share how it's going with each of their motivators in a 1:1 meeting.
      </p>
      <p className="mb-medium">
        If someone shares an area where their needs are not being met, you have an opportunity to address it. Ask
        follow-up questions to get at the root of their concerns. Then you can look for ways to address their needs with
        opportunities and solutions within your influence.
      </p>
      <h2 className="text-rising-orange mb-medium">2. Focus on what you can influence</h2>
      <p className="mb-medium">
        Sometimes team members may have a need that you don't have the power to meet. Maybe they're driven by
        accelerated growth and promotion and there are no higher-level positions available at the moment. Instead, focus
        on how you can support these needs. Think about ways they can gain more visibility, build their personal brand
        with higher-ups, or even learn a new skill to help prepare them for when a higher role becomes available.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Customize your approach</h2>
      <p className="mb-medium">
        A{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.forbes.com/sites/forbesbusinesscouncil/2021/06/28/12-ways-to-find-what-motivates-your-employees/"
        >
          recent Forbes article
        </a>{" "}
        explained that "Leaders need to understand they can't create motivation but they can instead provide
        inspiration. They need to help employees find their 'why' and define a purpose they feel motivated about. There
        is no one-size-fits-all model for employee motivation."
      </p>
      <p className="mb-medium">
        Each person is unique and your approach should be as well. If someone is motivated by personal growth, make sure
        to emphasize what they can learn by taking on a new assignment. If someone is striving for a promotion, help
        them see how a new project is an opportunity for more exposure and increased potential.
      </p>
      <h2 className="text-rising-orange mb-medium">4. Motivation can address behavior</h2>
      <p className="mb-medium">
        Knowing how to motivate your team will help ensure they're happily bringing their best efforts each week and
        helping the team meet its goals. This is especially true if you need someone to do something differently.
      </p>
      <p className="mb-medium">
        Instead of pointing out failures, you can tap into what motivates someone to change a behavior that isn't
        helping them or the team. For example, if someone values personal relationships, help them understand how a
        certain behavior is negatively impacting those relationships. When we understand how a behavior can hold us back
        from having a core need met, we're much more likely to course correct.
      </p>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="ml-xl mb-xxxxl">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/daniel-pink-on-motivation"
          >
            Daniel Pink on Motivation
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/learning/motivate-remote-teams">
            Motivate Remote Teams
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.forbes.com/sites/forbesbusinesscouncil/2021/06/28/12-ways-to-find-what-motivates-your-employees"
          >
            12 Ways To Find What Motivates Your Employees
          </a>{" "}
          (Forbes)
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=uoUHlZP094Q">
            How to Master Anything: PEAK by Anders Ericsson
          </a>{" "}
          (YouTube)
        </li>
      </ul>
    </div>
  </div>
)

export default MotivatorsTraining3
