import { HeartIcon, RocketLaunchIcon } from "icons/FontAwesomeIcons"

enum KitType {
  KIT = "kit",
  BOOST = "boost",
  MINI = "mini",
}

// Keep in sync with backend/monthly_kit/definitions/constants.py
enum CustomKitOrganization {
  BOH = "boh",
}

enum KitGroupSize {
  STANDARD = "standard",
  JUMBO = "jumbo",
}

enum KitLinkKey {
  SESSION_URL = "session_url",
  SESSION_PREVIEW_URL = "session_preview_url",
  SESSION_START_URL = "session_start_url",
  HOME_URL = "home_url",
  PREP_URL = "prep_url",
  REVIEW_URL = "review_url",
  RESULTS_URL = "results_url",
  BONUS_URL = "bonus_url",
  TEAM_HOME_URL = "team_home_url",
}

enum PromoKitSlug {
  GRATITUDE = "gratitude",
  CIVIL_CONVERSATIONS = "civil-conversations",
}

const CUSTOM_KIT_LOGO_URL = "https://static.risingteam.com/kit_assets/custom-kit-organization-logo/"

function getKitTypeInfo(kitType: KitType): {
  header: string
  showLearnedSomethingValuableQuestion: boolean
  showFeelMoreConnectedQuestion: boolean
  showWorkMoreEffectivelyQuestion: boolean
  showLikelihoodOfRecommendingQuestion: boolean
  showThinkAboutSessionQuestion: boolean
  showImproveExperienceFeedbackQuestion: boolean
  showTeamPageTakeExerciseLink: boolean
  hasReflections: boolean
  hasReportExerciseResponses: boolean
  pillIcon: typeof HeartIcon | typeof RocketLaunchIcon
  pillText: string
  pillColorCss: string
} {
  switch (kitType) {
    case KitType.BOOST:
      return {
        header: "Connection Kit",
        showLearnedSomethingValuableQuestion: true,
        showFeelMoreConnectedQuestion: true,
        showWorkMoreEffectivelyQuestion: false,
        showLikelihoodOfRecommendingQuestion: true,
        showThinkAboutSessionQuestion: false,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: false,
        hasReflections: false,
        hasReportExerciseResponses: false,
        pillIcon: HeartIcon,
        pillText: "Connection Kit",
        pillColorCss: "text-orange-3 border-orange-3",
      }
    case KitType.MINI:
      return {
        header: "Mini",
        showLearnedSomethingValuableQuestion: false,
        showFeelMoreConnectedQuestion: false,
        showWorkMoreEffectivelyQuestion: false,
        showLikelihoodOfRecommendingQuestion: false,
        showThinkAboutSessionQuestion: true,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: false,
        hasReflections: false,
        hasReportExerciseResponses: false,
        pillIcon: HeartIcon,
        pillText: "Mini Kit",
        pillColorCss: "text-orange-3 border-orange-3",
      }
    default:
      return {
        header: "Theme",
        showLearnedSomethingValuableQuestion: true,
        showFeelMoreConnectedQuestion: true,
        showWorkMoreEffectivelyQuestion: true,
        showLikelihoodOfRecommendingQuestion: true,
        showThinkAboutSessionQuestion: false,
        showImproveExperienceFeedbackQuestion: true,
        showTeamPageTakeExerciseLink: true,
        hasReflections: true,
        hasReportExerciseResponses: true,
        pillIcon: RocketLaunchIcon,
        pillText: "Development Kit",
        pillColorCss: "text-rising-green border-rising-green",
      }
  }
}

function getCustomKitTypeInfo(customKitOrganization: CustomKitOrganization): {
  customPillLogo: string | null
  pillText: string
  pillColorCss: string
} {
  switch (customKitOrganization) {
    case CustomKitOrganization.BOH:
      return {
        customPillLogo: CUSTOM_KIT_LOGO_URL + "boh-logo.svg",
        pillText: "Bank of Hawaiʻi",
        pillColorCss: "text-blue-2 border-blue-2 bg-blue-tint",
      }
    default:
      return {
        customPillLogo: null,
        pillText: "Development Kit",
        pillColorCss: "text-rising-green border-rising-green",
      }
  }
}

function roundKitSessionTime(sessionMinutes: number): number {
  // Note: Rounding logic must match rounded_session_time_minutes in monthly_kit/definitions/base.py
  // Rounding Scheme:
  // Round down to nearest 15-min mark if less than 5 min above that 15-min mark.
  // Otherwise round up to next 15-min mark. Eg. 63->60 64->60 65->75 67->75 etc.
  const remainder = sessionMinutes % 15
  return remainder < 5 ? sessionMinutes - remainder : sessionMinutes + 15 - remainder
}

function formatKitSessionTime(sessionMinutes: number, { unit = "minutes" } = {}): string {
  return `${roundKitSessionTime(sessionMinutes)} ${unit}`
}

export {
  KitType,
  KitGroupSize,
  KitLinkKey,
  PromoKitSlug,
  getKitTypeInfo,
  getCustomKitTypeInfo,
  formatKitSessionTime,
  roundKitSessionTime,
}
