import * as Sentry from "@sentry/browser"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { addHours, isAfter, isValid, parseISO } from "date-fns"

import { auth } from "../../api"

import { signOutFirebaseUser } from "domains/Authentication/firebase"
import { featuresCacheKey } from "ui/hooks/useFeatures"

function getHeap(): { identify: (id: string | number) => void; resetIdentity: () => void } {
  // TODO Change Heap import strategy so we can use correct typings with it.
  return (window as unknown as { heap: any }).heap
}

async function login(values: Record<string, any>): Promise<Record<string, unknown>> {
  const { data } = await auth.post("/login/", values)
  return data
}

async function logout(): Promise<Record<string, unknown>> {
  const { data } = await auth.post("/logout/")
  return data
}

async function forgotPassword(values: Record<string, any>): Promise<Record<string, unknown>> {
  const { data } = await auth.post("/password/reset/", values)
  return data
}

async function resetPassword(values: Record<string, any>): Promise<Record<string, unknown>> {
  const { data } = await auth.post("/password/reset/confirm/", values)
  return data
}

async function me(): Promise<Record<string, unknown> | null> {
  try {
    const { data } = await auth.get("/me/")
    try {
      getHeap().identify(data.id)
    } catch (exception) {}
    try {
      Sentry.getCurrentScope().setUser({ id: data.id })
    } catch {}
    return data
  } catch (exception: unknown) {
    const error = exception as (Error & { response: Response }) | null
    if (error?.response.status === 401) {
      return null
    }
    throw error
  }
}

function useLogin() {
  const queryClient = useQueryClient()
  return useMutation(login, {
    onSuccess: (data: Record<string, unknown>): void => {
      queryClient.setQueryData(authCacheKey, data)
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      getHeap().identify(data.id as string)
    },
  })
}

function useLogout() {
  const queryClient = useQueryClient()
  return useMutation(logout, {
    onSuccess: async (): Promise<void> => {
      try {
        await signOutFirebaseUser()
      } catch (e) {
        Sentry.captureException(e)
      }
      queryClient.removeQueries({ queryKey: authCacheKey, exact: true })
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      queryClient.clear()
      getHeap().resetIdentity()
    },
  })
}

function useAuth() {
  return useQuery(authCacheKey, me, {
    retry: 0,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  })
}

const authCacheKey = ["auth"]

async function getSSOProvider(provider: string): Promise<{ sso_provider: SSOProviderData | null }> {
  const { data } = await auth.get("/sso_provider/", { params: { provider } })
  return { sso_provider: data?.sso_provider ?? null }
}

function useSSOProvider(provider: string) {
  return useQuery(["sso_provider", provider], () => getSSOProvider(provider), { enabled: !!provider })
}

// useAPIJSDate and checkForStaleJS are used in Routes.js
//
// useAPIJSDate
// 1. This represents the date that the JS was deployed.
// 2. On our first page load, we store the date in the react query cache for 30 minutes.
// 3. Every 30 minutes we mark the cached value as stale.
// 4. If the window/tab is in focus, we refetch the value, if not, we do the refetch the next time it is in focus.
//
// checkForStaleJS
// 1. checkForStaleJS is called whenever the value of useAPIJSDate changes (this happens whenever we do a release)
// 2. If the date is null, this function does nothing.
// 3. The first time there is a date, clientJSDate is set to that date.
// 4. If the date ever changes from clientJSDate we check if we should reload the page.
// 5. If forceRefresh is true, reload immediately
// 6. Otherwise, wait until it's been 12 hours since we loaded the app to reload.
//
// The reason we wait 12 hours is because we don't want to force a refresh right after you load the app
// since the initial time period is the most likely time for you to be in the middle of something.
//
// [WARNING] If making changes to this code, please review the scheme
// described above and ensure changes cannot result in a refresh loop.
const initializedAt = new Date()
let clientJSDate: Date | null = null
function checkForStaleJS(apiJSDateInfo: { apiJSDate: string; forceRefresh: boolean }): void {
  const parsedDate = apiJSDateInfo?.apiJSDate ? parseISO(apiJSDateInfo.apiJSDate) : null
  const apiJSDate = isValid(parsedDate) ? parsedDate : null

  if (!apiJSDate) {
    return
  }

  if (!clientJSDate) {
    clientJSDate = apiJSDate
    return
  }

  const isOldClientJS = isAfter(apiJSDate, clientJSDate)
  const initializedOver12HoursAgo = isAfter(new Date(), addHours(initializedAt, 12))

  if (isOldClientJS && (apiJSDateInfo.forceRefresh || initializedOver12HoursAgo)) {
    window.location.reload()
  }
}

async function getAPIJSDateInfo(): Promise<Record<string, unknown>> {
  const { data } = await auth.get("/api_js_date/")
  return data
}

// [WARNING] If making changes to this code, please review the scheme
// described above and ensure changes cannot result in a refresh loop.
function useAPIJSDateInfo() {
  return useQuery(["api_js_date"], getAPIJSDateInfo, {
    retry: 0,
    staleTime: 29 * 60 * 1000, // needs to be slightly before refetchInterval
    cacheTime: 29 * 60 * 1000, // needs to be slightly before refetchInterval
    refetchInterval: 30 * 60 * 1000, // check every 30 minutes
    refetchOnWindowFocus: true,
  })
}

export {
  checkForStaleJS,
  forgotPassword,
  resetPassword,
  useAPIJSDateInfo,
  useLogin,
  useLogout,
  useAuth,
  useSSOProvider,
  authCacheKey,
}
