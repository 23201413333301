const MenziesCELeadershipTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-large">
        The theme of this kit is about leadership that cultivates collective efficacy in schools. This article will help
        you get up to speed on the topic and make the most of your team workshop.
      </p>
      <h3 className="text-rising-orange mb-medium">
        Leaders help create the conditions for collective efficacy to develop
      </h3>
      <p className="mb-medium">
        A growing body of research on collective efficacy suggests that to improve student outcomes in schools, we need
        leaders and staff who believe in their collective ability to promote student learning and who understand that
        through working together they can have a positive impact on student outcomes.
      </p>
      <p className="mb-large">
        Leaders are critical in this endeavor. To cultivate collective efficacy in schools, leaders model practices,
        promote mindsets, create supportive conditions, and foster opportunities to work together on improving teaching
        and learning.
      </p>
      <h3 className="text-rising-orange mb-medium">
        What leadership that cultivates collective efficacy looks like in action
      </h3>
      <p className="mb-large">
        School leaders who cultivate collective efficacy are focused on deepening the collaborative capacity of staff.
        They deliberately work to develop a shared sense of purpose, they draw on one another's expertise to enhance
        teaching and learning, and support colleagues' professional growth.
      </p>
      <h3 className="text-rising-orange mb-medium">Collective efficacy develops in phases</h3>
      <p className="mb-medium">
        While it's clear that collective efficacy is fundamental for aligning beliefs, expectations, and practice in
        schools, it cannot be assumed it will develop on its own, or be built overnight.
      </p>
      <p className="mb-large">
        Five core elements central to developing collective efficacy in schools are: trust, shared sense of purpose,
        structures and supports, evidence-informed priorities, and effective teaching and learning.
      </p>
      <h3 className="text-rising-orange mb-medium">The four phases of maturity:</h3>
      <p className="mb-medium">One way to think about how these elements develop is across four phases of maturity:</p>
      <p className="mb-medium">
        <strong>Awareness: </strong> growing recognition that changes to the ways of working are needed to improve
        student learning.
      </p>
      <p className="mb-medium">
        <strong>Emerging: </strong> initial opportunities to build collective efficacy are being explored.
      </p>
      <p className="mb-medium">
        <strong>Establishing: </strong> collective efficacy is building and strengthening ways of working.
      </p>
      <p className="mb-medium">
        <strong>Sustaining: </strong> collective efficacy is well embedded and efforts are made to maintain it.
      </p>
      <p className="mb-large">
        These collective efficacy elements and phases are explored in each of the three kits—school, leader, and
        teacher.
      </p>
      <h3 className="mb-medium">Additional resources</h3>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="ml-xl mb-xxxxl">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://research.acer.edu.au/cgi/viewcontent.cgi?article=1010&context=educational_leadership"
          >
            School leadership that cultivates collective efficacy
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.edweek.org/education/opinion-what-is-the-school-leaders-role-in-building-collective-efficacy/2019/12"
          >
            What is the school leader's role in building collective efficacy?
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default MenziesCELeadershipTraining1
