import { Link } from "react-router-dom"

import View from "ui/View"

// TODO: send an email that this happened so we can follow up with them.
const AlreadyPaidSubscription = () => (
  <View $flex={1} $flexDirection="column">
    <p>It looks like you’re already registered with Rising Team as an account owner.</p>
    <p className="pt-medium">
      To continue with your existing account <Link to="/">click here</Link>.
    </p>
    <p className="pt-medium">
      If you’re interested in using Rising Team with a second account, email us at{" "}
      <a href="mailto:hello@risingteam.com">hello@risingteam.com</a> for help setting that up.
    </p>
  </View>
)

export default AlreadyPaidSubscription
