import cn from "classnames"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { styled } from "styled-components"

import { LinkIcon, LinkSlashIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import useSyncSessionEnabled from "ui/hooks/useSyncSessionEnabled"

const SyncSessionToggle = ({ onEnable: onEnableParam = () => {}, className }) => {
  const [_, setSearchParams] = useSearchParams()
  const syncSessionEnabled = useSyncSessionEnabled()
  const onEnable = () => {
    setSearchParams({})
    onEnableParam()
  }
  const onDisable = () => setSearchParams({ "page-sync": "false" })

  const PageSyncEnabledMessage = () => (
    <>
      <span className="text-semi-bold">Page Sync enabled.</span> Your browser will automatically follow the session
      leader.
    </>
  )
  const PageSyncDisabledMessage = () => (
    <>
      <span className="text-semi-bold">Page Sync disabled.</span> Your browser will not automatically follow the session
      leader until you enable it again.
    </>
  )

  const pageSyncToast = (content) => {
    const toastId = "page-sync-toast"

    if (toast.isActive(toastId)) {
      toast.update(toastId, { render: content })
    } else {
      toast(content, { toastId })
    }
  }

  const toggleSessionSync = () => {
    if (syncSessionEnabled) {
      onDisable()
      pageSyncToast(<PageSyncDisabledMessage />)
    } else {
      onEnable()
      pageSyncToast(<PageSyncEnabledMessage />)
    }
  }

  return (
    <Button className={cn("tertiary", { active: syncSessionEnabled }, className)} onClick={toggleSessionSync}>
      {syncSessionEnabled ? (
        <LinkIcon className="page-sync-icon text-blue-2" />
      ) : (
        <LinkSlashIcon className="page-sync-icon" />
      )}
    </Button>
  )
}

export default styled(SyncSessionToggle)`
  height: 40px;
  width: 40px;
  padding: var(--spacing-1) !important;
  border: none !important;
  border-radius: 50% !important;

  &.active {
    background-color: var(--blue-tint);

    &:hover {
      .page-sync-icon {
        color: var(--rising-blue);
      }
    }
  }
`
