import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { Navigate, useNavigate } from "react-router"
import { styled } from "styled-components"

import { useBillingData } from "./BillingDataContext"
import { CeoImage } from "./CeoImage"
import NumTeamsDropdown from "./NumTeamsDropdown"

import { usePromo } from "domains/GetStarted/resource"
import { ArrowRightIcon } from "icons/FontAwesomeIcons"
import StripesBandDesktopIcon from "icons/StripesBandDesktopIcon"
import { createCheckoutSessionAndRedirect, createBillingAccount, convertAccountToPromo } from "resources/billing"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectOnComponentUnmount from "ui/hooks/useEffectOnComponentUnmount"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"
import View from "ui/View"
import { buildUrl } from "utils/string"

const AlreadyLoggedInPurchaseTeamMember = styled(function AlreadyLoggedInPurchaseTeamMember({
  className,
  user,
  promoCode = null,
}) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { billingData } = useBillingData()
  const { freeTrial } = billingData
  const { data: promo, isInitialLoading: promoIsInitialLoading } = usePromo(promoCode)

  const [hasSetupPromo, setHasSetupPromo] = useState(false)
  const [hasUnmounted, setHasUnmounted] = useState(false)
  useEffectOnComponentUnmount(() => setHasUnmounted(true))
  useEffectAfterChange(() => {
    async function setupPromo() {
      const billingAccount = await createBillingAccount()
      await convertAccountToPromo(billingAccount.id, promo.code, queryClient)
      if (!hasUnmounted) {
        navigate(buildUrl(promo.redirect_url_parts))
      }
    }
    if (promo && !hasSetupPromo && !promoIsInitialLoading) {
      setHasSetupPromo(true)
      setupPromo()
    }
  }, [hasSetupPromo, navigate, queryClient, hasUnmounted, promo, promoIsInitialLoading])

  if (promoIsInitialLoading) {
    return null
  }

  if (!promo && user.can_view_subscribe_tab) {
    return <Navigate to="/subscribe" replace />
  }

  if (promo) {
    return <Loading />
  }

  const onContinueSubscribe = async () => {
    const billingAccount = await createBillingAccount()
    await createCheckoutSessionAndRedirect({
      billingData,
      billingAccountId: billingAccount.id,
    })
  }

  return (
    <>
      <PageTitle>Let's get started</PageTitle>
      <View $flex={1} $flexDirection="column" className={className}>
        <h2 className="mb-medium text-bold">Hi {user.first_name},</h2>
        {!!billingData.selectNumTeams && <NumTeamsDropdown className="hide-on-desktop num-teams-dropdown" />}
        <View className="mb-medium">
          <div className="right-column show-on-desktop">
            <StripesBandDesktopIcon className="stripes-band-icon-desktop" />
          </div>
        </View>
        <View className="mt-xl">
          <div className="mb-xl">
            {!!billingData.selectNumTeams && <NumTeamsDropdown className="show-on-desktop num-teams-dropdown" />}
            {!!freeTrial && <h3 className="text-bold mb-small">Try Rising Team for free!</h3>}
            <div>
              <p className="mb-xs">It looks like you’re already registered with Rising Team as an account member.</p>
              <p className="mb-xxxxl">
                Would you like to sign up {!!freeTrial && "for the free trial"} so that you can use Rising Team with a
                team or teams of your own?
              </p>
            </div>
            <Button onClick={onContinueSubscribe}>
              Let’s do this
              <ArrowRightIcon />
            </Button>
          </div>
          <CeoImage className="my-xxxxl" />
        </View>
      </View>
    </>
  )
})`
  .right-column {
    width: 200px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .stripes-band-icon-desktop {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`

export default AlreadyLoggedInPurchaseTeamMember
