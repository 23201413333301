import { styled } from "styled-components"

import PieChart from "./PieChart"
import { getRepeatingColorFunc } from "./theme"

import { capitalize } from "utils/string"

const PercentagesChart = ({ data, colors, className, ...props }) => {
  if (!data) {
    return null
  }

  const parsedData = Object.entries(data)
    .filter(([, { percentage }]) => percentage)
    .map(([identifier, { percentage, label, tooltip }]) => ({
      id: identifier,
      label: label || capitalize(identifier),
      tooltip: tooltip || `${capitalize(identifier)}: <b>${percentage}%</b>`,
      value: percentage,
    }))
    .sort((d1, d2) => d1.id.localeCompare(d2.id))

  const colorFunc = Array.isArray(colors)
    ? getRepeatingColorFunc(
        parsedData.map(({ id }) => id),
        colors
      )
    : colors

  return (
    <div className={className}>
      <PieChart
        data={parsedData}
        innerRadius={0.6}
        arcLabel={(d) => <tspan>{d.value}%</tspan>}
        arcLinkLabel={(d) => <tspan>{d.label}</tspan>}
        arcLinkLabelsStraightLength={0}
        animate={false}
        colors={colorFunc}
        {...props}
      />
    </div>
  )
}

export default styled(PercentagesChart)`
  width: 100%;
  height: 400px;

  tspan {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  // increase weight of text within chart sections
  text[text-anchor="middle"] {
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    tspan {
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }
  }
`
