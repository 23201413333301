import { styled } from "styled-components"

const YouTubeVideo = ({ className, videoId }) => (
  <div className={className}>
    <iframe
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="video"
    ></iframe>
  </div>
)

// Responsive YouTube embed inspired by here:
// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
export default styled(YouTubeVideo)`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
