import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import api from "api"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useSelectedTeamId } from "ui/SelectedTeamContext"
import { getRealtimeCacheKey, useRealtimeQuery } from "utils/query"
import { buildUrl } from "utils/string"

function getLatestTeamExerciseInstances({ teamId, exerciseSlug }) {
  return () =>
    api
      .get(`/teams/${teamId}/latest_team_exercise_instances_for_slug/?exercise_slug=${exerciseSlug}`)
      .then(({ data }) => data)
}
function useLatestTeamExerciseInstances({ teamId, exerciseSlug, enabled = true, refetchInterval = false }) {
  return useQuery(
    ["exercises", "team_instances", teamId, exerciseSlug, "latest"],
    getLatestTeamExerciseInstances({ teamId, exerciseSlug }),
    {
      enabled: !!enabled && !!teamId && !!exerciseSlug,
      refetchInterval,
    }
  )
}

function getLatestUserExerciseInstances({ teamId, userId }) {
  return () => api.get(`/teams/${teamId}/latest_user_exercise_instances/?user_id=${userId}`).then(({ data }) => data)
}

const useLatestUserExerciseInstances = ({ teamId, userId, enabled = true }) =>
  useQuery(
    ["exercises", "user_instances", teamId, userId, "latest"],
    getLatestUserExerciseInstances({ teamId, userId }),
    { enabled: !!enabled && !!teamId && !!userId }
  )

function getSessionExerciseInstances(kitInstanceId) {
  return () => api.get(`/monthly_kit/kit_instances/${kitInstanceId}/exercise_instances/`).then(({ data }) => data)
}
function useSessionExerciseInstances(
  kitInstanceId,
  { enabled = true, refetchInterval = false, sessionRealtimeUpdates = false } = {}
) {
  const sessionExerciseInstancesDataWithPolling = useQuery(
    getSessionExerciseInstancesCacheKey(kitInstanceId),
    getSessionExerciseInstances(kitInstanceId),
    {
      enabled: !!enabled && !!kitInstanceId && !sessionRealtimeUpdates,
      refetchInterval,
      refetchIntervalInBackground: true,
    }
  )

  const sessionExerciseInstancesDataWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "exercise_answers" }),
    queryCacheKey: getSessionExerciseInstancesCacheKey(kitInstanceId),
    queryFn: getSessionExerciseInstances(kitInstanceId),
    enabled: !!enabled && !!kitInstanceId && !!sessionRealtimeUpdates,
  })

  if (!!sessionRealtimeUpdates) {
    return sessionExerciseInstancesDataWithoutPolling
  }
  return sessionExerciseInstancesDataWithPolling
}

// Use exercise instances tied to a session when in the session context,
// otherwise use exercise instances for the whole team
function useSessionOrTeamExerciseInstances(
  slug,
  { refetchInterval = false, enabled = true, sessionRealtimeUpdates = false }
) {
  const { kitInstance } = useKitSession()
  const { selectedTeamId } = useSelectedTeamId()
  const sessionExerciseInstancesData = useSessionExerciseInstances(kitInstance?.id, {
    enabled: !!enabled && !!kitInstance,
    refetchInterval,
    sessionRealtimeUpdates,
  })
  const teamExerciseInstancesData = useLatestTeamExerciseInstances({
    teamId: selectedTeamId,
    exerciseSlug: slug,
    enabled: !!enabled && !kitInstance,
    refetchInterval,
  })

  if (kitInstance) {
    return sessionExerciseInstancesData
  } else {
    return teamExerciseInstancesData
  }
}

const getSessionExerciseInstancesCacheKey = (kitInstanceId) =>
  // For some reason using the full ['monthly_kit', ...] cache key briefly creates a conflict,
  // returning the kit instance rather than the exercises
  ["kit_instances", kitInstanceId, "exercise_instances"]

const getExerciseInstanceCacheKey = ({ teamId, slug, teamLevelExercise }) => [
  "exercises",
  "instance",
  teamId,
  slug,
  !!teamLevelExercise,
]
const getExerciseInstanceCacheKeyFromExerciseInstance = (exerciseInstance) =>
  getExerciseInstanceCacheKey({
    teamId: exerciseInstance.team_id,
    slug: exerciseInstance.slug,
    teamLevelExercise: exerciseInstance.is_team_level_exercise,
  })

function getExerciseInstance({ teamId, slug }) {
  return () =>
    api.get(`/teams/${teamId}/latest_user_exercise_instance_for_slug/`, { params: { slug } }).then(({ data }) => data)
}
function useExerciseInstance({ teamId, slug }) {
  return useQuery(
    getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise: false }),
    getExerciseInstance({ teamId, slug }),
    {
      enabled: !!(teamId && slug),
    }
  )
}

function getTeamLevelExerciseInstance({ teamId, slug }) {
  return () =>
    api
      .get(`/teams/${teamId}/latest_team_level_exercise_instance_for_slug/`, { params: { slug } })
      .then(({ data }) => data)
}
function useTeamLevelExerciseInstance({
  teamId,
  kitInstanceId,
  slug,
  refetchInterval = null,
  enabled = true,
  sessionRealtimeUpdates = false,
}) {
  const teamExerciseInstanceDataWithPolling = useQuery(
    getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise: true }),
    getTeamLevelExerciseInstance({ teamId, slug }),
    {
      refetchInterval,
      enabled: !!enabled && !!teamId && !!slug && !sessionRealtimeUpdates,
    }
  )

  const teamExerciseInstanceDataWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "team_exercise_answers" }),
    queryCacheKey: getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise: true }),
    queryFn: getTeamLevelExerciseInstance({ teamId, slug }),
    enabled: !!enabled && !!teamId && !!slug && !!sessionRealtimeUpdates,
  })
  if (!!sessionRealtimeUpdates) {
    return teamExerciseInstanceDataWithoutPolling
  }
  return teamExerciseInstanceDataWithPolling
}

function getOrCreateExerciseInstance({ teamId, slug, version, teamLevelExercise }) {
  return () =>
    api
      .post(`/teams/${teamId}/get_or_create_latest_exercise_instance/`, { slug, version, teamLevelExercise })
      .then(({ data }) => data)
}
function useOrCreateExerciseInstance({ teamId, slug, version, teamLevelExercise }) {
  return useQuery(
    getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise }),
    getOrCreateExerciseInstance({ teamId, slug, version, teamLevelExercise }),
    { enabled: !!teamId && !!slug && !!version }
  )
}

function getExerciseDefinition({ teamId, slug, version }) {
  return () =>
    api.get(`/teams/${teamId}/get_exercise_definition/`, { params: { slug, version } }).then(({ data }) => data)
}
function useExerciseDefinition({ teamId, slug, version }) {
  return useQuery(
    ["exercises", "definition", teamId, slug, version],
    getExerciseDefinition({ teamId, slug, version }),
    { enabled: !!teamId && !!slug && !!version }
  )
}

function updateExerciseInstance(id) {
  return (values) => api.patch(`/exercises/instances/${id}/`, values).then(({ data }) => data)
}
function useUpdateExerciseInstance(id) {
  const queryClient = useQueryClient()
  return useMutation(updateExerciseInstance(id), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

function postExerciseAnswer(id) {
  return (values) => api.post(`/exercises/instances/${id}/answers/`, values).then(({ data }) => data)
}
function useMutateExerciseAnswer(id) {
  const queryClient = useQueryClient()
  return useMutation(postExerciseAnswer(id), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

const shareExerciseResults = async (id) => {
  const data = await api.post(`/exercises/instances/${id}/share_exercise_results/`)
  return data
}
const useShareExerciseResults = () => useMutation(shareExerciseResults)

function getSharedExerciseInstance(params) {
  return () => api.get("/exercises/instances/get_shared_exercise_instance/", { params }).then(({ data }) => data)
}
function useSharedExerciseInstance(params) {
  return useQuery(["share_code", params], getSharedExerciseInstance(params))
}

function updateExerciseAnswerImage(exerciseAnswerId) {
  return async function ({ identifier, file }) {
    const formData = new FormData()
    formData.append("exercise_answer_image", file)
    formData.append("identifier", identifier)
    const headers = {
      "Content-Type": "multipart/form-data",
    }

    const { data } = await api.post(`/exercises/instances/${exerciseAnswerId}/update_answer_image/`, formData, {
      headers,
    })
    return data
  }
}

function useUpdateExerciseAnswerImage(exerciseAnswerId) {
  const queryClient = useQueryClient()
  return useMutation(updateExerciseAnswerImage(exerciseAnswerId), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

function updateExerciseAnswerImageURL(exerciseAnswerId) {
  return async function ({ identifier, object_key }) {
    const { data } = await api.post(`/exercises/instances/${exerciseAnswerId}/update_answer_image_url/`, {
      object_key,
      identifier,
    })
    return data
  }
}

function useUpdateExerciseAnswerImageURL(exerciseAnswerId) {
  const queryClient = useQueryClient()
  return useMutation(updateExerciseAnswerImageURL(exerciseAnswerId), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

function deleteExerciseAnswer(exerciseInstanceId) {
  return async function ({ identifier }) {
    const { data } = await api.delete(
      buildUrl(["exercises", "instances", exerciseInstanceId, "delete_user_exercise_instance_for_slug"]),
      {
        data: { identifier },
      }
    )
    return data
  }
}

function useDeleteExerciseAnswer({ exerciseInstanceId }) {
  const queryClient = useQueryClient()
  return useMutation(deleteExerciseAnswer(exerciseInstanceId), {
    onSuccess: () => {
      queryClient.invalidateQueries(getExerciseInstanceCacheKeyFromExerciseInstance)
    },
  })
}

async function triggerStandaloneExerciseCompleted(exerciseInstanceId) {
  await api.post(`/exercises/instances/${exerciseInstanceId}/trigger_standalone_exercise_completed/`)
}

export {
  useDeleteExerciseAnswer,
  useLatestTeamExerciseInstances,
  useLatestUserExerciseInstances,
  useSessionExerciseInstances,
  useSessionOrTeamExerciseInstances,
  useExerciseInstance,
  useTeamLevelExerciseInstance,
  useOrCreateExerciseInstance,
  useUpdateExerciseInstance,
  useMutateExerciseAnswer,
  useShareExerciseResults,
  useSharedExerciseInstance,
  useUpdateExerciseAnswerImage,
  triggerStandaloneExerciseCompleted,
  useExerciseDefinition,
  useUpdateExerciseAnswerImageURL,
}
