import { createContext, useContext } from "react"

const PreviewOverlayContext = createContext({
  setPreviewOverlay: () => {},
  setPreviewOverlayText: () => {},
  setOnPreviewExitClick: () => {},
})

const usePreviewOverlayContext = () => useContext(PreviewOverlayContext)

export { PreviewOverlayContext, usePreviewOverlayContext }
