import BulletedList from "ui/BulletedList"

const KeepLetGoIntro = () => (
  <>
    <h2 className="text-gray-9 mb-medium">Intro</h2>
    <p className="mb-medium">
      Now that we’ve explored objects that are meaningful to us, let’s move on to habits and behaviors.
    </p>
    <p className="mb-medium">
      During this activity, we will reflect on the habits and behaviors that contribute to, or block us from, potential
      success in life. Try to get just a little bit out of your comfort zone and choose something meaningful from work
      or your personal life.
    </p>
    <p className="mb-medium">Potential examples:</p>
    <BulletedList className="mb-medium">
      <li>Asking for help or support when you need it</li>
      <li>Overscheduling (or under scheduling) your social life</li>
      <li>Your morning or evening routine</li>
      <li>Presenting or sharing your work with others</li>
      <li>Speaking up when you disagree</li>
    </BulletedList>
    <p className="mb-xxxxl">
      Everyone will complete the next steps on their own. Try to finish before the timer runs out, then wait for the
      rest of your team to proceed.
    </p>
  </>
)

export default KeepLetGoIntro
