import { useKitSession } from "../KitSessionContext"

import { useSessionPicture } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useHasTeamFeature } from "utils/team"

const SessionImageUploaded = ({ kitInstance, children }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data } = useSessionPicture({
    kitInstanceId: kitInstance.id,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  return data ? children : null
}

export default SessionImageUploaded
