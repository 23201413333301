import { last, sortBy } from "lodash-es"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import WordCloud from "ui/WordCloud"

const RadioGroupMultiUserWordCloud = ({
  exerciseInstances,
  identifier,
  minFontSize,
  maxFontSize,
  textFieldIdentifiers,
  height,
  className,
}) => {
  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at"))
  const exerciseComponent = getExerciseComponent(latestExerciseInstance, identifier)
  const choiceOptions = exerciseComponent.options
  const answers = exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)).filter(Boolean)

  const words = choiceOptions
    .map((option) => {
      const value = answers.filter((answer) => answer.includes(option.value)).length
      if (value === 0) {
        return null
      }
      return {
        text: option.short_label,
        value,
      }
    })
    .filter(Boolean)

  const accumulatedAdditionalAnswers = (textFieldIdentifiers ?? [])
    .flatMap((identifier) => exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)))
    .filter(Boolean)

  const additionalwords = accumulatedAdditionalAnswers.map((item) => ({
    text: item,
    value: 1,
  }))

  const finalListofWords = words.concat(additionalwords)

  return (
    <WordCloud
      className={className}
      wcWords={finalListofWords}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
      height={height}
    />
  )
}

export default RadioGroupMultiUserWordCloud
