import { Link } from "react-router-dom"

import { ArtiSender } from "../constants"

import ArtiChatMessage from "./ArtiChatMessage"

const ArtiTeamMemberOptedOutMessage = ({ selectedMember }) => (
  <ArtiChatMessage sender={ArtiSender.BOT}>
    Hi! {selectedMember.first_name} has opted out of having their information shared in aRTi. To see the insights
    they've shared in the Rising Team sessions, go to the <Link to="/team">Team tab</Link> or to get general leadership
    advice about how to work with them, choose "N/A" in the conversation with aRTi.
  </ArtiChatMessage>
)

export default ArtiTeamMemberOptedOutMessage
