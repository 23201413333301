import cn from "classnames"
import { useNavigate } from "react-router"

import Button from "./Button"
import SubmitButton from "./SubmitButton"

import { ArrowRightIcon } from "icons/FontAwesomeIcons"

const NextButton = ({ text, className, navigateTo, onClick, onNavigate, type = "button", secondary, submit }) => {
  const navigate = useNavigate()
  const buttonClassName = cn(className, "nav-button", {
    secondary: !!secondary,
  })
  const handleOnClick = async (evt) => {
    if (onClick) {
      onClick(evt)
    } else if (navigateTo) {
      let cancelNavigation = false
      if (onNavigate) {
        cancelNavigation = await onNavigate()
      }
      if (!cancelNavigation) {
        navigate(navigateTo)
      }
    }
  }
  return submit ? (
    <SubmitButton label={<ButtonContents text={text} />} labelSubmitting="Saving" className={buttonClassName} />
  ) : (
    <Button className={buttonClassName} type={type} onClick={handleOnClick}>
      <ButtonContents text={text} />
    </Button>
  )
}

const ButtonContents = ({ text }) => (
  <>
    {text || "Next"}
    <ArrowRightIcon />
  </>
)

export default NextButton
