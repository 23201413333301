import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import renderBlocks from "components/Blocks/renderBlocks"
import { blockIcons } from "icons"
import PercentagesChart from "ui/PercentagesChart"

const PercentageGroupFieldDonutChart = styled(function PercentageGroupFieldDonutChart({
  identifier,
  exerciseInstance,
  exerciseComponent,
}) {
  const percentages = getExerciseAnswer(exerciseInstance, identifier)
  const identifiers = percentages ? Object.keys(percentages) : []
  const percentage_colors = exerciseComponent.percentage_colors
  for (const identifier of identifiers) {
    const exercise_item = exerciseComponent.items.find((item) => item.identifier === identifier)
    if (exercise_item) {
      percentages[identifier].tooltip = (
        <>
          {renderBlocks(exercise_item.sentence, blockIcons)} <b>({percentages[identifier].percentage}%)</b>
        </>
      )
    }
  }

  return (
    <PercentagesChart
      className="percentages-chart mb-xl"
      data={percentages}
      colors={percentage_colors}
      arcLabelsTextColor="white"
    />
  )
})`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .percentages-chart {
      svg > g > g:nth-child(3) {
        display: none;
      }
    }
  }
`

export default PercentageGroupFieldDonutChart
