import DOMPurify from "dompurify"

import { getExerciseAnswer } from "../results_utils"

const RadioGroupSpanText = ({
  identifier,
  exerciseComponent,
  exerciseInstance,
  use_short_label = false,
  delimiter = ", ",
  className,
}) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const selectedChoiceOptions = choiceOptions.filter((option) => userSelections.includes(option.value))
  const selectedChoiceDisplayValues = selectedChoiceOptions.map((option) =>
    use_short_label ? option.short_label : option.label
  )
  const joinedDisplayText = selectedChoiceDisplayValues.join(delimiter)

  return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(joinedDisplayText) }} className={className} />
}

export default RadioGroupSpanText
