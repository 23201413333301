import { Form, Formik } from "formik"

import { getExerciseAnswer } from "../results_utils"

import IconRatingField from "forms/fields/IconRatingField"

const IconRatingFieldReadOnly = ({ identifier, exerciseInstance, options }) => {
  const answer = getExerciseAnswer(exerciseInstance, identifier)
  const initialValues = {
    [identifier]: { value: answer },
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form>
        <IconRatingField
          disabled
          className="mb-small"
          options={options}
          name={`${identifier}.value`}
          useTooltip={false}
        />
      </Form>
    </Formik>
  )
}

export default IconRatingFieldReadOnly
