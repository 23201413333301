import { Field } from "formik"

import FieldMessage from "forms/FieldMessage"
import View from "ui/View"

const ToggleField = ({ className, children, name, ...props }) => (
  <div className={className}>
    <View as="label" className="text-small">
      <Field type="checkbox" className="less-box-shadow border-gray-5 border-radius mr-small" name={name} {...props} />
      <div className="text-field-label">{children}</div>
    </View>
    <FieldMessage name={name} data-cy={`field-message-${name}`} />
  </div>
)

export default ToggleField
