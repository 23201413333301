import { ResponsivePie } from "@nivo/pie"
import { quantize, interpolateHcl } from "d3-interpolate"

import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import theme, { colors as themeColors } from "ui/theme"

const chartTheme = {
  fontSize: "16px",
  grid: {
    line: {
      strokeWidth: "2px",
      stroke: "var(--border-color)",
    },
  },
  axis: {
    ticks: {
      text: {
        fontWeight: 500,
        color: "#000",
      },
    },
  },
  label: {
    text: {
      fill: "#494949",
    },
  },
  fontFamily: theme.normalFontFamily,
  tooltip: {
    maxWidth: "230px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    color: "white",
    background: "var(--gray-9)",
    borderRadius: "4px",
    boxShadow: "0px 0px 33px rgba(0, 0, 0, 0.05)",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.375rem",
  },
}

const PieChart = ({
  data,
  arcLabel: _arcLabel,
  arcLinkLabel: _arcLinkLabel,
  theme = {},
  showMargins = true,
  minimumLabelPercentage = 5,
  ...props
}) => {
  const colors = quantize(interpolateHcl("#71D2C7", "#1B2758"), data.length)
  const labelFunc = (func, nullValue = null) => {
    if (!func) {
      return null
    }
    return (datum) => {
      const total = data.reduce((total, datum) => total + datum.value, 0)
      const percentage = (datum.value * 100) / total
      if (datum.value == null || datum.id === "placeholder" || percentage < minimumLabelPercentage) {
        return nullValue
      }
      return func(datum)
    }
  }

  // Add chart tooltips if data descriptions are available:
  if (data.some(({ description }) => description)) {
    data = data.map((data) => ({
      ...data,
      ...(!data.tooltip && data.id !== "other" && data.description
        ? { tooltip: `<b>${data.label}</b><br>${data.description}` }
        : {}),
      ...(!data.tooltip && data.id === "other"
        ? { tooltip: `<b>Other</b><br>${data.label === "Other" ? "" : data.label}` }
        : {}),
    }))
  }

  // Hide tooltip background if there are no tooltips
  const tooltipTheme =
    props?.tooltip || data.some(({ tooltip }) => !!tooltip)
      ? {}
      : { tooltip: { container: { background: "transparent" } } }

  return (
    <ResponsivePie
      data={data}
      margin={showMargins ? { top: 40, right: 120, bottom: 40, left: 120 } : 0}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      theme={{ ...chartTheme, ...tooltipTheme, ...theme }}
      colors={(datum) => {
        const index = data.findIndex(({ id }) => id === datum.id)
        return datum.data.color || colors[index]
      }}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLabel={labelFunc(_arcLabel)}
      arcLinkLabel={labelFunc(_arcLinkLabel)}
      arcLinkLabelsColor={labelFunc(() => themeColors.gray9, "transparent")}
      radialLabelsSkipAngle={0}
      radialLabel={labelFunc((d) => `${d.label} (${d.value}%)`)}
      radialLabelsLinkColor={labelFunc((d) => d.color, "transparent")}
      enableSliceLabels={false}
      tooltip={({ datum: { data } }) => (!data ? null : <PurifyInnerHTMLDiv>{data.tooltip}</PurifyInnerHTMLDiv>)}
      {...props}
    />
  )
}

export default PieChart

export { chartTheme }
