import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const LifelinesUserResults = ({ exercise, className }) => {
  const event_1_caption = getExerciseAnswer(exercise, "event_1_caption")
  const event_1_image = getExerciseAnswer(exercise, "event_1_image")
  const event_2_caption = getExerciseAnswer(exercise, "event_2_caption")
  const event_2_image = getExerciseAnswer(exercise, "event_2_image")
  const event_3_caption = getExerciseAnswer(exercise, "event_3_caption")
  const event_3_image = getExerciseAnswer(exercise, "event_3_image")

  return (
    <View className={cn("text-gray-9", className)}>
      <TextContainer>
        <AnswerDisplay number="1" caption={event_1_caption} image={event_1_image} />
        <AnswerDisplay number="2" caption={event_2_caption} image={event_2_image} />
        <AnswerDisplay number="3" caption={event_3_caption} image={event_3_image} />
      </TextContainer>
    </View>
  )
}

const AnswerDisplay = styled(function AnswerDisplay({ number, caption, image, className }) {
  return (
    <View className={className} $flexDirection="column">
      {!!(caption || image) && (
        <>
          <h3 className="text-bold mb-xs">Event #{number}</h3>
          {!!caption && <div className={`text-small text-semi-bold + ${image ? "mb-xs" : "mb-large"}`}>{caption}</div>}
          {!!image && (
            <div className="exercise-picture mb-large">
              <img src={image} alt={caption} />
            </div>
          )}
        </>
      )}
    </View>
  )
})`
  .exercise-picture {
    max-width: 377px;

    img {
      object-fit: contain;
      border-radius: 8px;
      max-width: 377px;
      max-height: 377px;
    }
  }
`

export default LifelinesUserResults
