import { useField } from "formik"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useSelectedTeam } from "ui/SelectedTeamContext"
import { useHasTeamFeature } from "utils/team"

const DUMMY_FIELD_NAME = "dummy-field-name-do-not-use-in-real-form-field"

const useFieldValue = (field_id) => {
  const [field] = useField(field_id || DUMMY_FIELD_NAME)
  return field_id ? field.value : null
}

const useExerciseParticipants = () => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: kitParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { selectedTeam } = useSelectedTeam()

  if (kitInstance) {
    return kitParticipants
  }

  return selectedTeam?.members
}

export { useFieldValue, useExerciseParticipants }
