const BOHLeadershipPrinciplesTraining2 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Leadership Principles are a guide for our behaviors as leaders. The principles can inspire your actions,
        decisions and efforts towards successful outcomes which are key to delivering leadership excellence at Bank of
        Hawaii.
      </p>
      <p className="mb-medium">
        Now that we understand that Leadership Principle are behaviors, we will take a deep dive into each principle. In
        this Rising Team kit, the group will share their experiences on each principle resulting in greater
        understanding of each other and our beliefs.
      </p>
      <h2 className="text-rising-orange mb-medium">Preparation for inspiration</h2>
      <p className="mb-medium">
        Before we start this kit, it is important to have all team members in a relaxed and open mindset. The ground
        rules give guidelines to follow which may ease any concerns but also there is a question about whether anything
        will keep someone from being 100% present. Because of the nature of the discussion and the vulnerability it may
        take to share, this will be key to take note of and rescheduling the session should not be a concern.
      </p>
      <h2 className="text-rising-orange mb-medium">The Leadership Principles</h2>
      <p className="mb-medium">
        At Bank of Hawaiʻi, we want to inspire leaders at all levels to be value-creators and to positively impact our
        business by delivering exceptional outcomes and making leadership excellence a priority.
      </p>
      <p className="mb-medium">
        We have five principles that are key to living the behaviors that lead to leadership excellence; Be One Bankoh,
        Champion Forward Thinking, Walk the Talk, Invest in Our People and Empower our People.
      </p>
      <p className="mb-medium">
        You’ll start with a warmup that will be about reflecting on a person who shaped you into the leader you are
        today. That will then lead into the definition Leadership Principles are which are guides to the behaviors we
        should exhibit.
      </p>
      <h2 className="text-rising-orange mb-medium">Principles in Action</h2>
      <p className="mb-medium">
        The principles in action activity will focus on each Leadership Principle including the definition, what it
        looks like and how people put the principle into practice today as well as a fun question about each principle.
      </p>
      <p className="mb-medium">
        Take note of the time allotted for the discussion and manage the time as necessary by choosing a few to speak
        and share vs the entire team.
      </p>
      <h2 className="text-rising-orange mb-medium">Activity: Make an Action Plan</h2>
      <p className="mb-medium">
        In this activity, each person will choose one principle to focus on and will create an action plan to activate
        their principle. They’ll identify the actions to take to activate the principle in daily work as well as
        identify how they will measure success. A key component here is identifying the blockers to success and planning
        on how to overcome those blockers.
      </p>
      <p className="mb-medium">
        Each person will work on this action plan on their own but you can debrief by asking members of the team to
        share what their principle and the components of their action plan. Most importantly, discuss who their
        accountability partner will be and when they will complete their plan.
      </p>
      <p className="mb-medium">Finish with a reminder to our leaders to “Make Leadership Excellence A Priority!”</p>
    </div>
  </div>
)

export default BOHLeadershipPrinciplesTraining2
