const MenziesCETeamsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-large">
        This article will help you get up to speed on the topic and make the most of your team workshop. The theme of
        this kit is about collective efficacy amongst teaching teams.
      </p>
      <h3 className="text-rising-orange mb-medium">How collective efficacy is built</h3>
      <p className="mb-medium">
        Collective efficacy can be cultivated through teams experiencing success together in various ways. These might
        include:
      </p>
      <ul className="ml-xl mb-large">
        <li>Mastery experiences where the team achieves success towards a collective goal;</li>
        <li>Vicarious experiences where team members observe and learn from one another;</li>
        <li>Social experiences where team members encourage one another and provide feedback;</li>
        <li>
          Affective experiences where team members are comfortable to express ideas, and feel good about their
          contributions.
        </li>
      </ul>
      <h3 className="text-rising-orange mb-medium">Collective efficacy develops in phases</h3>
      <p className="mb-medium">
        While it's clear that collective efficacy is fundamental for aligning beliefs, expectations, and practice in
        schools, it cannot be assumed it will develop on its own, or be built overnight.
      </p>
      <p className="mb-large">
        Five core elements central to developing collective efficacy in schools are: trust, shared sense of purpose,
        structures and supports, evidence-informed priorities, and effective teaching and learning.
      </p>
      <h3 className="text-rising-orange mb-medium">The four phases of maturity</h3>
      <p className="mb-medium">One way to think about how these elements develop is across four phases of maturity:</p>
      <p className="mb-medium">
        <strong>Awareness: </strong> growing recognition that changes to the ways of working are needed to improve
        student learning.
      </p>
      <p className="mb-medium">
        <strong>Emerging: </strong> initial opportunities to build collective efficacy are being explored.
      </p>
      <p className="mb-medium">
        <strong>Establishing: </strong> collective efficacy is building and strengthening ways of working.
      </p>
      <p className="mb-medium">
        <strong>Sustaining: </strong> collective efficacy is well embedded and efforts are made to maintain it.
      </p>
      <p className="mb-large">
        These collective efficacy elements and phases are explored in each of the three kits—school, leader, and
        teacher.
      </p>
      <h3 className="text-rising-orange mb-medium">What collective efficacy in teaching teams looks like</h3>
      <p className="mb-large">
        When there are high levels of collective efficacy in teams, staff are more likely to set high expectations for
        teaching and learning. They persevere when faced with challenging circumstances or setbacks and continuously
        work together to improve.
      </p>
      <h3 className="mb-medium">Additional resources</h3>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="ml-xl mb-xxxxl">
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://leadership.acsa.org/building-teacher-efficacy">
            The importance of building collective teacher efficacy
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default MenziesCETeamsTraining1
