import LearnerAgilityChart from "domains/Exercise/ResultsComponents/LearnerAgilityChart"
import { LearningStyles } from "domains/Results/LearnerAgilityResultsReview"

const LearnerAgilityExerciseReport = ({ exerciseInstances, userIdMap }) => (
  <div>
    <LearnerAgilityChart userIdMap={userIdMap} exerciseInstances={exerciseInstances} />
    <LearningStyles />
  </div>
)

export default LearnerAgilityExerciseReport
