import cn from "classnames"

import { getUserAnswer } from "../results_utils"

import AnswerDisplayContent from "ui/AnswerDisplayContent"

const RichTextFieldMultiUserList = ({ identifier, exerciseInstances, sortedUsers, className }) => (
  <div className={cn("mb-xl", className)}>
    {sortedUsers.map((user) => {
      const answer = getUserAnswer(exerciseInstances, user, identifier)
      return <AnswerDisplayContent key={user.id} className="mb-small" title={user.short_name} answer={answer} />
    })}
  </div>
)

export default RichTextFieldMultiUserList
