import useQueryParams from "ui/hooks/useQueryParams"

function useSyncSessionEnabled(): boolean {
  const queryParams = useQueryParams()
  const syncSession = queryParams["page-sync"] ?? "true"

  return syncSession === "true"
}

export default useSyncSessionEnabled
