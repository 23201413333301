import renderKitBlocks from "domains/KitSession/blocks"
import StepContent from "domains/KitSession/components/StepContent"
import { useUser } from "resources/users"

const KitBlockStep = ({
  kitInstance,
  sessionStep,
  isTeamLead,
  preview,
  navigateNextHandler,
  navigateBackHandler,
  nextButtonProps,
  backButtonProps,
  navRightContent,
}) => {
  const { data: user } = useUser({ userId: "me" })
  return (
    <StepContent>
      {renderKitBlocks(sessionStep.content, {
        kitInstance,
        sessionStep,
        user,
        isTeamLead,
        preview,
        navigateNextHandler,
        navigateBackHandler,
        nextButtonProps,
        backButtonProps,
        navRightContent,
      })}
    </StepContent>
  )
}

export default KitBlockStep
