import cn from "classnames"
import { useField } from "formik"
import shuffle from "knuth-shuffle-seeded"
import { Fragment, useState } from "react"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import ExerciseGridRadioGroupField from "./ExerciseGridRadioGroupField"

import { useSessionExerciseInstances, useDeleteExerciseAnswer, useExerciseInstance } from "domains/Exercise/resource"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { UserSecretIcon, CircleExclamationIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { useUser } from "resources/users"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useFeatures, { SHARED_FLAGS, FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import ProgressTimer from "ui/ProgressTimer"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const ExerciseGuessingGame = ({ saveOnChange, name, questionIdentifier, imageIdentifier }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants, isInitialLoading: isInitialLoadingParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { [FLAGS.RTDEV_ALLOW_DELETE_USER_ANSWERS]: allowDeleteExerciseAnswers } = useFeatures()

  const { data: exerciseInstances, isInitialLoading: isInitialLoadingExerciseInstances } = useSessionExerciseInstances(
    kitInstance?.id,
    {
      sessionRealtimeUpdates,
    }
  )
  const { data: exerciseInstance } = useExerciseInstance({ teamId: team.id, slug: kitInstance.kit.exercise.slug })
  const { mutateAsync: deleteExerciseAnswer } = useDeleteExerciseAnswer({
    exerciseInstanceId: exerciseInstance?.id,
  })
  const { data: user, isInitialLoading: isInitialLoadingUser } = useUser({ userId: "me" })
  const [slides, setSlides] = useState([])

  const [{ value: guessedAnswers }, _, { setValue }] = useField(name)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(guessedAnswers?.length ?? 0)
  const isQuizCompleted = guessedAnswers?.length === slides.length

  useEffectAfterChange(() => {
    if (
      !isInitialLoadingExerciseInstances &&
      !isInitialLoadingParticipants &&
      !isInitialLoadingUser &&
      slides.length === 0
    ) {
      const slides = createGuessingSlideDeck({
        exerciseInstances,
        participants,
        user,
        questionIdentifier,
        imageIdentifier,
      })
      setSlides(slides)
    }
  }, [
    isInitialLoadingExerciseInstances,
    isInitialLoadingParticipants,
    isInitialLoadingUser,
    user,
    slides,
    exerciseInstances,
    participants,
    setSlides,
    questionIdentifier,
    imageIdentifier,
  ])

  if (isInitialLoadingExerciseInstances || isInitialLoadingParticipants || isInitialLoadingUser) {
    return <Loading />
  }

  if (slides.length === 0) {
    return (
      <View $alignItems="center" $gap="8px" className="p-xs bg-yellow-tint">
        <CircleExclamationIcon color="var(--rising-yellow)" className="small-logo-icon" />
        <div>Oops! Answers from a minimum of 2 players are needed to play. </div>
      </View>
    )
  }

  const onDeleteExerciseButton = async () => {
    if (!!user.is_staff && !!allowDeleteExerciseAnswers) {
      await deleteExerciseAnswer({ identifier: name })
      setValue([])
    }
  }

  const handleOnSelection = (_, guessedUserId) => {
    const isLastStep = currentSlideIndex === slides.length - 1
    const { correctAnswer } = slides[currentSlideIndex]

    const newAnswers =
      !!guessedAnswers > 0
        ? [...guessedAnswers, { correctAnswer, userAnswer: guessedUserId }]
        : [{ correctAnswer, userAnswer: guessedUserId }]

    setValue(newAnswers)
    saveOnChange(name, newAnswers)

    if (!isLastStep) {
      setCurrentSlideIndex((prevSlideIndex) => prevSlideIndex + 1)
    }
  }

  return isQuizCompleted || currentSlideIndex >= slides?.length ? (
    <QuizCompleted
      onDeleteExerciseButton={onDeleteExerciseButton}
      allowDeleteExerciseAnswers={allowDeleteExerciseAnswers}
      user={user}
    />
  ) : (
    <QuizSlideDisplay
      name={name}
      slides={slides}
      handleOnSelection={handleOnSelection}
      currentSlideIndex={currentSlideIndex}
    />
  )
}

function ParticipantsList({ name, participants, handleOnSelection }) {
  return (
    <View $flexDirection="column" $alignItems="center" $width="100%" $justifyContent="center">
      <ExerciseGridRadioGroupField
        name={name}
        options={participants}
        saveOnChange={handleOnSelection}
        maxWidth="100%"
        columns={2}
        removeChoiceInputFontWeight={true}
        className="full-width justify-content-center"
      />
    </View>
  )
}

const QuizImage = styled(function QuizImage({ image, className }) {
  return (
    <View className={cn("bg-blue-tint", className)} $maxWidth="400px" $maxHeight="400px">
      <img src={image} alt="guessing-game" />
    </View>
  )
})`
  border-radius: 16px;
  overflow: hidden;

  img {
    width: 100%;
  }

  span {
    height: 220px;
  }
`

function QuizCompleted({ onDeleteExerciseButton, allowDeleteExerciseAnswers, user, className }) {
  return (
    <View
      className={cn(className, "border border-radius-medium pb-large mx-auto px-medium")}
      $flexDirection="column"
      $justifyContent="center"
      $alignItems="center"
      $width="100%"
      $height="100%"
      $minHeight="300px"
    >
      <div className="text-center mx-medium my-medium">
        <UserSecretIcon className="text-orange-4 large-logo-icon" />
        <p>Nice detective work.</p>
      </div>
      {!!user.is_staff && !!allowDeleteExerciseAnswers && (
        <Fragment>
          <Button onClick={onDeleteExerciseButton} className="mt-medium tertiary">
            Delete Guesses
          </Button>
          <p>(Internal use only)</p>
        </Fragment>
      )}
    </View>
  )
}

function QuizSlideDisplay({ name, slides, handleOnSelection, currentSlideIndex }) {
  if (currentSlideIndex >= slides?.length) {
    return null
  }
  const { text, image, participants } = slides[currentSlideIndex]
  return (
    <View
      className="border border-radius-medium pb-large mx-auto"
      $flexDirection="column"
      $alignItems="center"
      $width="100%"
      $height="100%"
    >
      <ProgressTimer
        key={`slide.${currentSlideIndex}`}
        fromSeconds={20}
        countFinished={() => handleOnSelection(null, -1)}
      />
      <View className="p-medium mx-auto" $flexDirection="column" $alignItems="center">
        {!!image && <QuizImage image={image} className="justify-content-center" />}
        <p className="text-large my-large">{text}</p>
        <ParticipantsList name={name} participants={participants} handleOnSelection={handleOnSelection} />
      </View>
    </View>
  )
}

function createGuessingUserOption({ id, short_name }) {
  return {
    label: short_name,
    value: id,
  }
}

function createGuessingList({ correctAnswerUserId, currentUser, allUsers }) {
  const randomSeed = correctAnswerUserId
  const correctUser = allUsers.find((participant) => participant.id === correctAnswerUserId)
  const incorrectUsers = allUsers.filter(
    (participant) => participant.id !== correctAnswerUserId && participant.id !== currentUser.id
  )
  const incorrectUserOptions =
    incorrectUsers?.length > 3 ? shuffle(incorrectUsers, randomSeed).slice(0, 3) : shuffle(incorrectUsers)

  return shuffle([
    createGuessingUserOption({ id: correctUser.id, short_name: correctUser.short_name }),
    ...incorrectUserOptions.map(createGuessingUserOption),
  ])
}

function createGuessingSlideDeck({ exerciseInstances, participants, user, questionIdentifier, imageIdentifier }) {
  const slides = []

  exerciseInstances.forEach((exerciseInstance) => {
    if (exerciseInstance.user_id !== user.id) {
      const exerciseQuestionAnswer = getExerciseAnswer(exerciseInstance, questionIdentifier)
      const exerciseImageAnswer = getExerciseAnswer(exerciseInstance, imageIdentifier)
      const guessAnswers = createGuessingList({
        correctAnswerUserId: exerciseInstance.user_id,
        currentUser: user,
        allUsers: participants,
      })

      const slide = {
        correctAnswer: exerciseInstance.user_id,
        text: exerciseQuestionAnswer,
        image: exerciseImageAnswer,
        participants: guessAnswers,
      }

      if (slide.text?.length || !!slide.image) {
        slides.push(slide)
      }
    }
  })
  return slides
}

export default ExerciseGuessingGame
