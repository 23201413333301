import TalentsAccuracyForm from "domains/Exercise/ResultsComponents/TalentsAccuracyForm"
import TalentsChart from "domains/Exercise/ResultsComponents/TalentsChart"
import TalentsUserResults from "domains/Exercise/ResultsComponents/TalentsUserResults"
import { StarShootingIcon } from "icons/FontAwesomeIcons"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const TalentsResults = ({ user, exerciseInstance }) => {
  // We manually append 'short_name' since the chart expects it, and it normally only exists
  // in relation to a session or team
  const userIdMap = {
    [user.id]: { ...user, short_name: user.first_name },
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-medium">
        <StarShootingIcon className="text-orange-3 title-icon mr-medium" />
        <h1 className="text-gray-9">Talent Profile</h1>
      </View>
      <TextContainer>
        <p className="text-gray-9 mb-small">
          Your natural talents are the places your chart expands towards the outer edges of the circle. Higher ratings
          indicate activities more likely to come easily to you and bring you joy. Remember that you may also be skilled
          in areas that are lower on this chart; it just means you enjoy those activities less and they tend to drain
          your energy.
        </p>

        <TalentsChart userIdMap={userIdMap} exerciseInstances={[exerciseInstance]} className="mb-xl" />
        <TalentsUserResults exercise={exerciseInstance} className="mb-xl" />
        <TalentsAccuracyForm exercise={exerciseInstance} />
      </TextContainer>
    </>
  )
}

export default TalentsResults
