import { find } from "lodash-es"
import { styled } from "styled-components"

import AnswerDisplayContent from "ui/AnswerDisplayContent"
import { useSelectedUser } from "ui/SelectedUserContext"

const ParticipantReflections = ({ reflections, kitInstance, teamMember, className }) => {
  const { selectedUser } = useSelectedUser()

  const reflectionLabel = (
    <div className="mb-xs text-semi-bold text-gray-9">
      <span className="text-bold">Reflection</span>
      {!!kitInstance && `: ${kitInstance.kit.reflection_prompt}`}
    </div>
  )
  const actionItemsLabel = (
    <div className="mb-xs text-semi-bold text-gray-9">
      <span className="text-bold">Action Item</span>
      {!!kitInstance && `: ${kitInstance.kit.action_items_prompt}`}
    </div>
  )

  const reflection = find(reflections, (reflection) => reflection.user_id === (teamMember?.id || selectedUser.id))

  return (
    <div className={className}>
      <AnswerDisplayContent title={reflectionLabel} answer={reflection?.reflection_text} />
      <AnswerDisplayContent title={actionItemsLabel} answer={reflection?.action_items} />
    </div>
  )
}

export default styled(ParticipantReflections)`
  .title-view {
    color: var(--gray-9);
    font-size: 1.25rem;
    font-weight: bold;
  }
`
