import cn from "classnames"
import { styled } from "styled-components"

import { useScheduleNextKits } from "resources/monthly_kit"
import { buildUrl } from "utils/string"

const ArtiKitRecommendation = styled(function ArtiKitRecommendation({ team, className }) {
  const { data: scheduleNextKits } = useScheduleNextKits({
    teamId: team.id,
    skipIfHasScheduled: true,
    limit: 1,
  })
  const nextKit = scheduleNextKits?.[0]

  if (!nextKit) {
    return null
  }

  const nextKitURL = buildUrl(["kit", nextKit.slug], { urlQueryParams: { team_id: team.id } })

  return (
    <div className={cn(className, "bg-orange-tint py-medium px-large mt-medium border-radius-small")}>
      For a more personalized answer, do the{" "}
      <a href={nextKitURL} target="_blank" rel="noopener noreferrer" className="text-semi-bold">
        {nextKit?.title}
      </a>{" "}
      kit with your team.
    </div>
  )
})`
  box-shadow: 0px 0px 2px 0px var(--orange-4);
`

export default ArtiKitRecommendation
