import { createContext, useContext } from "react"

const SelectedUserContext = createContext({
  selectedUser: null,
})

const useSelectedUser = () => useContext(SelectedUserContext) || {}

export default SelectedUserContext

export { useSelectedUser }
