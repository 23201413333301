const FeedbackTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <p className="mb-large">
        After your Rising Team session on giving and receiving feedback, here's how to accelerate change and continue
        supporting your team.
      </p>
    </div>
    <h2 className="text-rising-orange mb-medium">1. Lead by example</h2>
    <p className="mb-medium">
      One of the main barriers leaders face in building a feedback culture is that most team members have never
      experienced one before, and for that reason they lack the necessary skills. To create a culture where everyone
      feels confident and competent giving and receiving feedback, you can lead the way with your own behavior.
    </p>
    <p className="mb-medium">
      Start by asking for feedback and practice receiving it with curiosity and gratitude. When team members observe you
      in action, they will feel more safe in giving it a try themselves.
    </p>
    <h2 className="text-rising-orange mb-medium">2. Avoid the “feedback sandwich”</h2>
    <p className="mb-medium">
      Many of us have been taught to ease any discomfort from constructive criticism by positioning it in between
      positive statements. But if you’ve ever been on the receiving end of that delivery, you know that it’s not hard to
      read between the lines.
    </p>
    <p className="mb-medium">
      For feedback to be impactful, it also needs to be authentic and feel sincere. Instead of the “feedback sandwich,”
      practice using the Rising Team models of giving and receiving feedback. By focusing on compassion and clarity,
      there’s no need to pad your feedback in ways that could ultimately erode trust.
    </p>
    <h2 className="text-rising-orange mb-medium">3. Deliver feedback in the flow</h2>
    <p className="mb-medium">
      Just as important as preparing feedback with compassion and clarity is delivering it at the right moment. Regular
      feedback helps team members focus their energy on behaviors that will make them successful. By delivering feedback
      in a timely manner, team members can avoid dead ends, quickly address mistakes, and put themselves on a path that
      will yield more positive results for them personally as well as for the team. As author Marcus Buckingham so
      eloquently concludes in his{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://hbr.org/2019/03/the-feedback-fallacy?autocomplete=true"
      >
        HBR article, The Feedback Fallacy
      </a>
      , “That’s where feedback must meet us—in our moments of flow.”
    </p>
    <h2 className="text-rising-orange mb-medium">4. Feedforward: keep doing what you’re doing!</h2>
    <p className="mb-medium">
      When we give a team member feedback, we’re usually talking about what they did in the past. “Feedforward,” on the
      other hand, is about highlighting something positive that you want to continue to see in the future. This can be
      done by helping team members understand when their strengths are adding value and speaking to the future value
      they can have. As{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.gallup.com/workplace/236570/employees-lot-managers.aspx"
      >
        Gallup concludes
      </a>
      , “When managers help employees grow and develop through their strengths, they are more than twice as likely to
      engage their team members.”
    </p>
    <p className="mb-medium">
      The key is applying the same model to prepare praise as you would constructive feedback. This will enable you to
      be specific and communicate impact, which in turn helps team members internalize what they can continue to do in
      the future.
    </p>
    <h2 className="text-rising-orange mb-medium">5. Balance praise and constructive feedback</h2>
    <p className="mb-medium">
      Most of us naturally prefer to give and receive positive feedback. It feels really good to know that someone sees
      and appreciates what we’re doing, and it also helps us know where to lean in and expand on our strengths. The good
      news is that we suggest aiming for a mix that’s heavier on the positive feedback. With frequent positive feedback
      and occasional constructive feedback, your team will become more confident, more bonded, and more open to working
      on their growth opportunities.
    </p>
  </div>
)

export default FeedbackTraining3
