import { styled } from "styled-components"

import InputField from "./InputField"

const StyledSelect = styled(function StyledSelect({ className, ...props }) {
  return (
    <div className={className}>
      <select {...props} />
    </div>
  )
})`
  position: relative;
  margin-bottom: 0 !important;
  background-color: var(--fg);
  box-shadow: var(--blur-4);

  &.xsmall {
    min-width: 200px;
  }

  &.small {
    min-width: 240px;
  }

  &.medium {
    min-width: 330px;
  }

  &.large {
    min-width: 366px;
  }

  // Default full-width of container if size was not specified:
  &:not(.small):not(.medium):not(.large):not(.xsmall) {
    width: 100%;
  }

  border-radius: var(--border-radius);
  color: var(--subtitle);

  select:focus-visible,
  &:hover {
    box-shadow: var(--lift-6);
  }

  &::after {
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 12px;
    display: block;
    width: 16px;
    height: 9px;
    display: inline-block;
    content: "";
    mask: url("data:image/svg+xml; utf-8, <svg width='16' height='9' viewBox='0 0 16 9' xmlns='http://www.w3.org/2000/svg'><path d='M8.00003 9C7.76803 9 7.53703 8.92 7.34903 8.759L0.71585 2.20708C0.321212 1.81728 0.319251 1.18075 0.711478 0.788522L0.783011 0.716989C1.17737 0.322627 1.81812 0.327073 2.20697 0.72687L8.00003 6.683L13.793 0.726876C14.1819 0.327077 14.8226 0.322628 15.217 0.716992L15.2885 0.788524C15.6807 1.18075 15.6788 1.81728 15.2842 2.20708L8.65103 8.759C8.46303 8.92 8.23203 9 8.00003 9Z' fill='red'/></svg>")
      no-repeat 50% 50%;
    mask-size: cover;
    background-color: #bdbdbd;
    pointer-events: none;
  }

  select {
    width: 100%;
    appearance: none;
    background: transparent;
    padding: var(--spacing-2);
    padding-right: 38px; // for the arrow
    color: var(--subtitle);
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    &.medium {
      min-width: 260px;
    }

    &.large {
      min-width: 330px;
    }
  }
`

export { StyledSelect }

export default function SelectField(props) {
  return <InputField forwardedAs={StyledSelect} {...props} />
}
