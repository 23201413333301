function SvgTripleStarIcon(props) {
  return (
    <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.99481 28.2327L2.30126 25.0915L4.82951 27.0069L8.0473 27.2367L6.05534 29.8416L5.82551 32.9827L3.29723 31.0674L0.0794678 30.8375L1.99481 28.2327Z"
        fill="#FAC126"
      />
      <path
        d="M34.7089 22.3334L34.096 20.4947L35.9348 20.9544L37.7735 20.4181L37.3138 22.2568L37.9267 24.0955L36.0114 23.6358L34.1727 24.1721L34.7089 22.3334Z"
        fill="#FAC126"
      />
      <path
        d="M13.1038 5.01868L15.0192 0.575058L17.7006 4.63561L22.1442 6.55096L18.1603 9.23244L16.245 13.676L13.4869 9.69213L9.04327 7.77678L13.1038 5.01868Z"
        fill="#FAC126"
      />
    </svg>
  )
}

export default SvgTripleStarIcon
