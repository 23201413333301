const CrisisResponseTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        This article will give you additional context and background around the topics covered during the session.
      </p>
      <h2 className="text-rising-orange mb-medium">1. What is crisis response?</h2>
      <p className="mb-medium">
        To get started, let’s define what rises to the level of a workplace crisis and why we need to hone our response
        skills.
      </p>
      <h3 className="mb-medium">a. All teams experience crisis</h3>
      <p className="mb-medium">
        Even the most high performing teams experience unexpected situations that can derail them if not addressed
        quickly and competently. At some point in every leader’s career, they’ll be faced with leading a team through a
        crisis. It may be a large-scale event that has global impact—think global pandemic—or a problem that affects
        just their organization or team.
      </p>
      <p className="mb-medium">
        Maybe the company changes strategy overnight or a project the team has been working on for months gets suddenly
        canceled. Whatever the circumstances, when a problem rises to the level of a crisis it is a time of heightened
        challenge and stress.
      </p>
      <h3 className="mb-medium">b. How we respond matters</h3>
      <p className="mb-medium">
        Crisis can bring out the best and worst of us. We can rise to the occasion, freeze in our tracks, retreat, or
        even arise a hero.
      </p>
      <p className="mb-medium">
        Crises can trigger negative emotions and behaviors. But they can also trigger positive responses, like altruism,
        leadership, and teamwork. Consider natural disasters. While they trigger fear and alarm for those affected, they
        also prompt acts of heroism and helping those in need.
      </p>
      <p className="mb-medium">
        How we handle a crisis determines the outcome of the scenario which will have both immediate as well as long
        lasting effects.
      </p>
      <h2 className="text-rising-orange mb-medium">2. We all respond differently</h2>
      <p className="mb-medium">
        The first step in leading others through a crisis is acknowledging your own response and then understanding how
        to leverage that of others.
      </p>
      <h3 className="mb-medium">a. Our first response is instinctual</h3>
      <p className="mb-medium">
        Imagine there is an alien invasion and mass chaos ensues. How would you respond? Do you remain calm and assess
        the situation before taking action? Do you jump in and eliminate immediate dangers? Perhaps you tend to the
        needs of those around you? Or begin your search for what triggered the invasion?
      </p>
      <p className="mb-medium">
        Regardless of your response, what’s most important is that you understand you have one and it’s unique to you.
      </p>
      <p className="mb-medium">
        In an{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.fastcompany.com/90390969/human-behavior-in-crisis-situations"
        >
          interview with Russell Shilling
        </a>
        , chief scientific officer for the American Psychology Association, Fast Company reported that “According to
        Shilling, people’s reactions to danger depend a lot on how they’re wired, what they’ve learned in the past,
        their exposure to stress, and their preconceived notions of what constitutes danger.”
      </p>
      <h3 className="mb-medium">b. Leaders can’t rely on instincts alone</h3>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.harvardbusiness.org/leading-your-team-through-a-crisis/"
        >
          HBR
        </a>{" "}
        wants leaders to understand that “when a crisis strikes, we tend to respond instinctively, but those initial
        impulses may not be especially productive. They may even be counterproductive.”
      </p>
      <p className="mb-medium">
        As a team leader, it can also feel like the weight of a workplace crisis is on our shoulders alone. Heidi
        Gardner and Ivan Matviak{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2020/07/7-strategies-for-promoting-collaboration-in-a-crisis"
        >
          point out in HBR
        </a>{" "}
        that, “The desire to try to bring things under control can also lead to a go-it-alone mentality.” While it may
        feel like we should be solving any problems, it’s less effective to try to do this on our own.
      </p>
      <p className="mb-medium">
        Instead of trying to tackle a crisis alone, leaders can focus on how to facilitate others through chaos. Leaders
        can elevate their thinking about their role during a crisis, using techniques from{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://shop.ccl.org/usa/crisis-leadership.html">
          Crisis Leadership
        </a>
        , by Gene Klann. As covered in{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ccl.org/articles/leading-effectively-articles/how-to-lead-through-a-crisis/"
        >
          CCL
        </a>
        , “Effective leaders are able to remain calm and maintain a sense of perspective...during a crisis, your goal is
        to reduce loss and keep things operating as normal as possible.’’
      </p>
      <h2 className="text-rising-orange mb-medium">3. Crisis requires collaboration</h2>
      <p className="mb-medium">
        Great leaders cultivate a collaborative team response and greatly improve short and long-term outcomes.
      </p>
      <h3 className="mb-medium">a. Approach crisis response like a coach would</h3>
      <p className="mb-medium">
        One way to approach crisis response is like coaching a team sport. And like any good coach, you need to know
        your players. What are their strengths and weaknesses? What unique response skills do each bring to the team?
      </p>
      <p className="mb-medium">
        When a crisis hits, leaders should have an understanding of the roles each prefers to play.
      </p>
      <p className="mb-medium">Consider who prefers to:</p>
      <ul className="ml-xl mb-medium">
        <li>Jump in and tackle immediate problems?</li>
        <li>See the bigger picture and coordinate resources?</li>
        <li>Challenge the long-term impacts of solutions?</li>
        <li>Tend to the needs of other team members?</li>
        <li>Identify the root cause of the crisis to avoid it happening again?</li>
        <li>Keep others in the loop to ensure alignment and continuity?</li>
      </ul>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.harvardbusiness.org/leading-your-team-through-a-crisis/"
        >
          HBR
        </a>{" "}
        writes that “bringing together experts with diverse experience allows the response group to see risks and
        opportunities from different angles to generate new solutions and adapt dynamically to changing situations.”
      </p>
      <h3 className="mb-medium">b. Collaborative teams respond better</h3>
      <p className="mb-medium">
        While we can’t predict crises, we can create an environment where team members can more readily collaborate when
        the time comes. HBR collected over a decade worth of data across multiple industries to understand how teams
        handled work during a crisis.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2020/07/7-strategies-for-promoting-collaboration-in-a-crisis"
        >
          Their research
        </a>{" "}
        found that “As uncertainty and stress increased during the crisis, the highly collaborative people evolved their
        approach to developing business and executing work.”
      </p>
      <p className="mb-medium">
        Whereas they found that the “self-focused, uncollaborative people took a wholly different approach. They erected
        walls around their projects, pushed colleagues away, held their business and clients closely, and hoarded work.”
      </p>
      <p className="mb-medium">
        Cultivating a culture of crisis collaboration will enable the team to act quickly when a crisis hits and ensure
        a potentially manageable crisis doesn’t turn into an even bigger disaster.
      </p>
      <p className="mb-medium">
        That’s a wrap on honing crisis response. Now we’re ready to talk about how we’ll involve the team in responding
        to crises together.
      </p>
    </div>
  </div>
)

export default CrisisResponseTraining1
