import DOMPurify from "dompurify"

import { getExerciseAnswer } from "../results_utils"

const RadioGroupWithCustomSpanText = ({
  identifier,
  exerciseComponent,
  exerciseInstance,
  use_short_label = false,
  delimiter = ", ",
  customFieldIdentifiers,
  className,
}) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const selectedChoiceOptions = choiceOptions.filter(
    (option) => userSelections.includes(option.value) && !Object.keys(customFieldIdentifiers).includes(option.value)
  )
  const selectedChoiceDisplayValues = selectedChoiceOptions.map((option) =>
    use_short_label ? option.short_label : option.label
  )
  for (const [customField, customFieldIdentifier] of Object.entries(customFieldIdentifiers)) {
    const customFieldAnswer = getExerciseAnswer(exerciseInstance, customFieldIdentifier)
    if (!!customFieldAnswer?.length && userSelections.includes(customField)) {
      selectedChoiceDisplayValues.push(customFieldAnswer)
    }
  }
  const joinedDisplayText = selectedChoiceDisplayValues.join(delimiter)
  return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(joinedDisplayText) }} className={className} />
}

export default RadioGroupWithCustomSpanText
