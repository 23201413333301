import { ordinal } from "utils/string"

function checkNamedArguments(
  callerName: string,
  args: IArguments,
  {
    required = [],
    optional = [],
    position = 0,
  }: {
    required?: string[]
    optional?: string[]
    position?: number
  }
): void {
  const namedArgs = args[position] as Record<string, unknown>
  const namedArgsObjOptional = !required.length
  const namedArgsObjOmitted = args.length <= position

  if (typeof namedArgs !== "object" && !namedArgsObjOptional && !namedArgsObjOmitted) {
    throw new Error(
      `${callerName} call should have named-arguments object as ${ordinal(
        position + 1
      )} argument (received ${typeof namedArgs} instead).`
    )
  }

  if (!namedArgsObjOmitted) {
    if (required.length) {
      const missingArgs = required.filter((arg) => !Object.prototype.hasOwnProperty.call(namedArgs, arg))
      if (missingArgs.length) {
        throw new Error(`${callerName} call is missing required arguments (${missingArgs}).`)
      }
    }

    const expectedArgs = new Set([...required, ...optional])
    const unexpectedArgs = new Set(Object.keys(namedArgs).filter((arg) => !expectedArgs.has(arg)))

    if (unexpectedArgs.size) {
      throw new Error(`${callerName} call passes unexpected arguments (${[...unexpectedArgs]}).`)
    }
  }
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { checkNamedArguments }
