import { Link } from "react-router-dom"
import { styled } from "styled-components"

const LinkGrid = styled(function LinkGrid({ links, className }) {
  return (
    <div className={className}>
      {links.map(({ text, url, onClick, Icon }) => {
        const Wrapper = url ? Link : "div"
        const props = {}
        if (url) {
          props.to = url
        }
        if (onClick) {
          props.onClick = onClick
        }

        return (
          <Wrapper {...props} key={text} className="kit-link text-gray-9">
            <Icon className="kit-link-icon mr-xxs" /> {text}
          </Wrapper>
        )
      })}
    </div>
  )
})`
  --link-columns: ${({ hasSessionPicture }) => (hasSessionPicture ? 2 : 3)};
  --link-rows: ${({ links, hasSessionPicture }) => Math.ceil(links.length / (hasSessionPicture ? 2 : 3))};

  display: grid;
  grid-template-columns: repeat(var(--link-columns), 1fr);
  grid-template-rows: repeat(var(--link-rows), 1fr);
  grid-auto-flow: column;
  row-gap: var(--spacing-3);

  .kit-link {
    cursor: pointer;

    &:hover {
      color: var(--rising-blue);
    }
  }

  .kit-link-icon {
    width: 20px;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: initial;
  }
`

LinkGrid.defaultProps = {
  hasSessionPicture: false,
}

export default LinkGrid
