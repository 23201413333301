import { Form } from "formik"

import HearUsThroughField from "forms/fields/HearUsThroughField"
import JobFunctionsField from "forms/fields/JobFunctionsField"
import PhoneNumberField from "forms/fields/PhoneNumberField"
import PreferredPronounsField from "forms/fields/PreferredPronounsField"
import SelectField from "forms/fields/SelectField"
import TextField from "forms/fields/TextField"
import FormMessage from "forms/FormMessage"
import NextButton from "ui/NextButton"

const team_location_types = [
  { value: "remote", label: "Remote" },
  { value: "in-person", label: "In-person" },
  { value: "hybrid", label: "Hybrid" },
]

const AdminProfileForm = ({ nextButtonText }) => (
  <Form name="account-free-trial-details-form" autoComplete="off">
    <div className="space-y-large">
      <TextField label="Your company/organization name" name="org_name" size="large" />
      <SelectField label="Are your teams remote or in-person?" name="team_location_type" size="large">
        <option disabled value="" />
        {team_location_types.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </SelectField>
      <JobFunctionsField label="What is your job function" name="job_function" size="large" />
      <PreferredPronounsField label="How would you like us to refer to you?" name="pronouns" size="large" />
      <HearUsThroughField label="How did you hear about us?" name="hear_us_through" size="large" />
      <PhoneNumberField
        label="Would you like to get a text message when new Kits are available?"
        name="phone_number"
        size="large"
        placeholder="Enter your phone number"
      />

      <FormMessage />
    </div>

    <NextButton submit={true} text={nextButtonText} className="mt-xl mb-xs" />
  </Form>
)

export default AdminProfileForm

export { team_location_types }
