function SvgPieSliceNoneIcon(props) {
  return (
    <svg height="40" width="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.2803 21.2995C32.0184 20.9234 32.3153 20.0179 31.9014 19.3002C28.7114 13.7691 23.6773 9.27795 17.3174 6.84369C16.5437 6.54756 15.6957 6.98245 15.4397 7.77033L8.14798 30.2121C7.73502 31.483 9.06485 32.6188 10.2555 32.0121L31.2803 21.2995Z"
        fill="#FAFAFA"
        stroke="#BDBDBD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="4 3"
      />
    </svg>
  )
}

export default SvgPieSliceNoneIcon
