import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import TextContainer from "ui/TextContainer"

const ResilienceScenarioIntroStep = () => (
  <StepContent>
    <SidebarContainer>
      <TextContainer className="text-gray-9 full-width">
        <h2 className=" mb-medium">Intro</h2>
        <p className="mb-medium">
          During this exercise, we will work together as a team to create a response plan to a hypothetical crisis
          scenario.
        </p>
        <p className="mb-xxxxl">
          First we identify a scenario to use for this exercises. Next you will assign team members to roles using the
          “6 Hats” concept. This means that sometimes we each need to wear different hats (or roles) to address a new
          challenge. Sometimes the role we’re assigned aligns with our preferences and sometimes it doesn’t. This
          exercise will allow each of us to practice filling different roles, as we might need to do in a crisis.
        </p>
      </TextContainer>
      <FacilitatorTip className="sidebar-right" tip="Read this screen out loud." icon="share-screen" />
    </SidebarContainer>
  </StepContent>
)

export default ResilienceScenarioIntroStep
