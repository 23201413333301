import { useFormikContext } from "formik"
import { useState, type ReactNode } from "react"

import Button, { type ButtonProps } from "ui/Button"
import SavingDots from "ui/SavingDots"

interface SubmittingProps {
  labelSubmitting: ReactNode
}

interface CommonSubmitButtonProps extends ButtonProps {
  className?: string
  children?: ReactNode
  action?: () => void
  label?: ReactNode
  labelSubmitting?: ReactNode
  isSubmitting?: boolean
}

interface FormikSubmitButtonProps extends CommonSubmitButtonProps {}

interface CustomSubmitButtonProps extends CommonSubmitButtonProps {
  onClick: () => Promise<void>
  onSuccess?: () => Promise<void>
}

interface SubmitButtonProps extends CommonSubmitButtonProps {
  onClick?: () => Promise<void>
  onSuccess?: () => Promise<void>
}

const Submitting = ({ labelSubmitting }: SubmittingProps) => (
  <>
    {labelSubmitting}
    <SavingDots />
  </>
)

const CommonSubmitButton = ({
  className,
  children,
  action,
  label,
  labelSubmitting,
  isSubmitting = false,
  ...props
}: CommonSubmitButtonProps) => {
  label = label || children || "Submit"
  if (labelSubmitting !== false) {
    labelSubmitting = labelSubmitting || children || "Submitting"
  }
  return (
    <Button type="submit" className={className} onClick={action} disabled={isSubmitting} {...props}>
      {isSubmitting && labelSubmitting ? <Submitting labelSubmitting={labelSubmitting} /> : label}
    </Button>
  )
}

const FormikSubmitButton = (props: FormikSubmitButtonProps) => {
  const { isSubmitting, handleSubmit } = useFormikContext()
  return <CommonSubmitButton action={handleSubmit} isSubmitting={isSubmitting} {...props} />
}

const CustomSubmitButton = ({ onClick, onSuccess, ...props }: CustomSubmitButtonProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const action = () => {
    setIsSubmitting(true)
    onClick()
      .then(() => {
        onSuccess && onSuccess()
      })
      // Note: setIsSubmitting here can cause a warning in React: Can't perform a React state update on an unmounted component.
      // This is a false positive and in fact this warning was removed in React 18
      // https://github.com/facebook/react/pull/22114
      .finally(() => setIsSubmitting(false))
  }
  return <CommonSubmitButton action={action} isSubmitting={isSubmitting} {...props} />
}

const SubmitButton = ({ onClick, ...props }: SubmitButtonProps) => {
  const isFormik = !onClick
  return isFormik ? <FormikSubmitButton {...props} /> : <CustomSubmitButton onClick={onClick} {...props} />
}

export default SubmitButton
export type { SubmitButtonProps }
