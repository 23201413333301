import { styled } from "styled-components"

interface BackgroundProps {
  $url?: string | null
  $overlay?: string | null
}

const Background = styled.div<BackgroundProps>`
  background: url("${({ $url }) => $url}") ${({ $overlay }) => $overlay};
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export default Background
