import { useLocation, Routes, Route, Navigate, Outlet } from "react-router-dom"

import NotFound from "components/NotFound"
import ServerError from "components/ServerError"
import ForgotPasswordForm from "domains/Authentication/forms/ForgotPasswordForm"
import ResetPasswordForm from "domains/Authentication/forms/ResetPasswordForm"
import Logout from "domains/Authentication/Logout"
import { useAuth } from "domains/Authentication/resource"
import { useUser } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"
import { isDevelopmentEnv } from "utils/env"
import { asStringsOrNulls } from "utils/string"

const CheckSupportedBrowser = () => {
  const isUnsupportedBrowser = /MSIE/.test(window.navigator.userAgent)
  return isUnsupportedBrowser ? <BrowserNotSupported /> : <Outlet />
}

const BrowserNotSupported = () => (
  <div className="main-container p-medium">
    <h1 className="mb-medium">Browser not supported</h1>
    <p className="text-gray-9">
      Please download the latest version of{" "}
      <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
        Chrome
      </a>
      ,{" "}
      <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer">
        Firefox
      </a>
      , or{" "}
      <a href="https://www.microsoft.com/edge" target="_blank" rel="noopener noreferrer">
        Edge
      </a>{" "}
      to continue.
    </p>
  </div>
)

const PrivateRoute = () => {
  const { pathname, search } = useLocation()
  const { data: auth, isFetching, status } = useAuth()

  if (isFetching) {
    return <Loading />
  }
  if (status === "error") {
    return <ServerError />
  }
  if (!auth) {
    if (pathname.startsWith("/session")) {
      return <Navigate to="/code" replace />
    }
    const next = `${pathname}${encodeURIComponent(search)}`
    const to = `/auth/login?next=${next}`
    return <Navigate to={to} replace />
  }

  return <Outlet />
}

const PublicOnlyRoute = () => {
  const { next = null } = asStringsOrNulls(useQueryParams())
  const { data: auth, isFetching, status } = useAuth()

  if (isFetching) {
    return <Loading />
  }
  if (status === "error") {
    return <ServerError />
  }
  if (!!auth) {
    return <Navigate to={next || "/"} replace />
  }

  return <Outlet />
}

const ForgotPasswordSuccess = () => {
  const { state } = useLocation()
  if (!state) {
    return <NotFound />
  }

  const email = state.email
  return (
    <p>
      If a matching account was found, an email was sent to <span className="text-bold text-gray-9">{email}</span> to
      allow you to reset your password.
    </p>
  )
}

const AuthRoutesLayout = () => (
  <div className="main-container">
    <Outlet />
  </div>
)
const AuthRoutes = () => (
  <Routes>
    <Route element={<AuthRoutesLayout />}>
      <Route element={<PublicOnlyRoute />}>
        <Route path="forgot" element={<ForgotPasswordForm />} />
        <Route path="forgot/success" element={<ForgotPasswordSuccess />} />
        <Route path="reset" element={<ResetPasswordForm />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  </Routes>
)

const AdminRedirectRoute = () => {
  const { data: user } = useUser({ userId: "me" })
  if (!user) {
    return <Loading />
  }
  if (!user.is_staff) {
    return <Navigate to="/" replace />
    // Only perform redirect below if user is staff; otherwise nav to root url.
    // This prevents a redirect loop occuring if a non-staff user tries to login
    // with ?next=/rtadmin in the url.
  }
  // Need to change the port from 3006 to 8003 when in development env:
  const baseUrl = isDevelopmentEnv() ? "http://rtkit.localhost:8003" : window.location.origin
  const path = window.location.pathname || "/rtadmin"
  const queryParams = window.location.search ?? ""
  // Redirect to admin site:
  window.location.replace(baseUrl + path + queryParams)
  return null // return null here so we don't render anything prior to redirect
}

interface SimpleRouteLayoutProps {
  title?: string
}

const SimpleRouteLayout = ({ title }: SimpleRouteLayoutProps) => (
  <>
    {!!title && <PageTitle>{title}</PageTitle>}
    <Outlet />
  </>
)

export default AuthRoutes

export { CheckSupportedBrowser, PrivateRoute, PublicOnlyRoute, AdminRedirectRoute, SimpleRouteLayout }
export type { SimpleRouteLayoutProps }
