import { Link } from "react-router-dom"

import CareerHorizonsSelectedUserView from "domains/Exercise/ResultsComponents/CareerHorizonsSelectedUserView"
import TextContainer from "ui/TextContainer"

const CareerHorizonsResults = ({ exerciseInstance }) => (
  <TextContainer className="text-gray-9">
    <p className="mb-xl">
      Once your team finishes the kit, this summary of your results will be available from{" "}
      <Link to={`/team?team_id=${exerciseInstance.team_id}`}>the Team Results Tab</Link>.
    </p>
    <CareerHorizonsSelectedUserView exercise={exerciseInstance} />
  </TextContainer>
)

export default CareerHorizonsResults
