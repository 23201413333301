import { styled } from "styled-components"

import Button from "ui/Button"
import View from "ui/View"

const VirtualBackgrounds = styled(function VirtualBackgrounds({ className, kit }) {
  return (
    <div className={className}>
      <div className="text-gray-9 mt-xl mb-medium">
        <h2>{kit.bonus_page.virtual_background_header}</h2>
      </div>
      <div className="description-wrapper mb-large">
        <div>{kit.bonus_page.virtual_background_desc}</div>
      </div>
      <View className="backgrounds-grid" $flexDirection="row">
        {kit.virtual_background_list.map(({ image, slug }) => (
          <div key={slug}>
            <img src={image} className="my-small" alt={slug} />
          </div>
        ))}
      </View>
      <a
        href={`https://static.risingteam.com/kit_assets/${kit.bonus_page.virtual_background_download_slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="tertiary mb-xl mt-large">Download them all</Button>
      </a>
    </div>
  )
})`
  img {
    width: 220px;
    border-radius: 6px;
  }

  .backgrounds-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: var(--spacing-medium);
  }
`

export default VirtualBackgrounds
