import cn from "classnames"
import { merge } from "lodash-es"
import ReactModal from "react-modal"

import { useModal } from "./ModalContext"
import View from "./View"

import CloseIcon from "icons/CloseIcon"
import { ZIndex } from "ui/z-index"

const DEFAULT_STYLES = {
  header: {
    borderRadius: "var(--border-radius)",
  },
  overlay: {
    background: "var(--overlay)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "var(--spacing-5)",
    zIndex: ZIndex.Modal,
  },
  content: {
    position: "absolute",
    top: "0 !important",
    left: "0 !important",
    right: "0 !important",
    bottom: "0 !important",
    borderRadius: "var(--border-radius)",
    inset: "initial",
    padding: 0,
    maxWidth: "880px",
    width: "100%",
    lineHeight: "1.5",
    background: "var(--fg)",
    border: "none",
    outline: "none",
  },
}
const MAX_WIDTHS = {
  small: "480px",
  medium: "880px",
  large: "1200px",
  default: "880px",
}

const ModalHeader = ({ className, onRequestClose, children, modalWithColorTitleBar = false }) => (
  <View
    className={cn("modal-header", className, {
      "py-xs px-large bg-orange-4": modalWithColorTitleBar,
      "p-large": !modalWithColorTitleBar,
    })}
    $justifyContent="space-between"
    $alignItems={modalWithColorTitleBar ? "center" : "top"}
  >
    <h2 className="text-gray-9 mb-none">{children}</h2>
    {!!onRequestClose && (
      <CloseIcon
        className={cn("cursor-pointer", { "text-white text-bold": modalWithColorTitleBar })}
        onClick={onRequestClose}
        data-testid="modal-close"
      />
    )}
  </View>
)

const ModalFooter = ({ className, ...props }) => (
  <View
    className={cn("modal-footer p-medium", className)}
    $justifyContent="flex-end"
    $alignItems="center"
    $flexWrap="wrap"
    {...props}
  />
)

const ModalContent = ({ className, ...props }) => (
  <View $flexDirection="column" className={cn("modal-content mb-large px-large", className)} {...props} />
)

const AppModal = () => {
  const { modal, setModal } = useModal()

  return (
    <Modal
      isOpen={!!modal}
      title={modal?.title}
      onRequestClose={() => setModal(null)}
      size={modal?.size ?? "small"}
      maxWidth={modal?.maxWidth ?? null}
      maxHeight={modal?.maxHeight ?? "80%"}
      content={modal?.content}
      headerImage={modal?.headerImage}
      showFrame={modal?.showFrame}
      modalWithColorTitleBar={modal?.modalWithColorTitleBar}
      modalWithColorBorder={modal?.modalWithColorBorder}
      styles={modal?.styles}
      positionOffsetX={modal?.positionOffsetX ?? 0}
      positionOffsetY={modal?.positionOffsetY ?? 0}
    />
  )
}

function Modal({
  className,
  title,
  content,
  isOpen,
  onRequestClose,
  headerImage = null,
  headerImageAlt = null,
  showFrame = true,
  modalWithColorTitleBar = false,
  modalWithColorBorder = false,
  children,
  size = "medium",
  maxWidth = null,
  maxHeight = "80%",
  styles = {},
  zIndex = null,
  positionOffsetX = 0,
  positionOffsetY = 0,
}) {
  maxWidth = maxWidth || MAX_WIDTHS[size] || MAX_WIDTHS.default
  const style = merge(
    {},
    DEFAULT_STYLES,
    styles,
    zIndex ? { overlay: { zIndex } } : {},
    {
      content: {
        maxWidth,
        maxHeight,
        transform: `translate(${positionOffsetX},${positionOffsetY})`,
      },
    },
    !modalWithColorBorder
      ? {}
      : {
          content: { borderRadius: "8px", border: "3px solid var(--orange-4)" },
        }
  )
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} className={className} style={style}>
      {!!headerImage && (
        <img src={headerImage} alt={headerImageAlt || title} className="full-width" style={{ objectFit: "cover" }} />
      )}
      {!!title && (
        <ModalHeader onRequestClose={onRequestClose} modalWithColorTitleBar={modalWithColorTitleBar}>
          {title}
        </ModalHeader>
      )}
      {!!content && (showFrame ? <ModalContent className={title ? "" : "pt-large"}>{content}</ModalContent> : content)}
      {children}
    </ReactModal>
  )
}

export default Modal

export { ModalHeader, ModalFooter, ModalContent, AppModal }
