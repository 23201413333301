import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { AngleDownIcon, AngleRightIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"

const EXTERNAL_OPEN_STATE_DEFAULT = Symbol()
// We use a unique symbol here so it's easy for the accordion to detect
// whether or not the externalOpenState prop was passed. This way, that
// prop can be null/undefined and the accordion will still behave properly.
// If the default were 'null' instead, it would be impossible to tell whether
// the prop was not passed, or it WAS passed but with a null value.

const Accordion = ({
  className,
  title,
  openTitle = title,
  shortAccordion = false,
  accordionTag = "div",
  children,
  isOpen = false,
  externalOpenState = EXTERNAL_OPEN_STATE_DEFAULT,
  // if you need to control the accordion via external state management,
  // use externalOpenState instead of isOpen
  closedIcon = AngleRightIcon,
  openIcon = AngleDownIcon,
  noIndent = false,
  fullWidthTitle = false,
  beforeOpen = null,
  beforeClose = null,
  beforeToggle = null,
  afterOpen = null,
  afterClose = null,
  afterToggle = null,
}) => {
  const useExternalOpenState = externalOpenState !== EXTERNAL_OPEN_STATE_DEFAULT
  let isAccordionOpen = !!(useExternalOpenState ? externalOpenState : isOpen)
  let setAccordionOpen = null
  const [isOpenInternalState, setOpenInternalState] = useState(isAccordionOpen)

  // If externalOpenState was NOT passed, accordion will control its own state:
  if (!useExternalOpenState) {
    isAccordionOpen = isOpenInternalState
    setAccordionOpen = setOpenInternalState
  }

  const OpenIcon = openIcon
  const ClosedIcon = closedIcon

  const openAccordion = () => {
    beforeOpen?.()
    beforeToggle?.()
    setAccordionOpen?.(true)
    afterOpen?.()
    afterToggle?.()
  }
  const closeAccordion = () => {
    beforeClose?.()
    beforeToggle?.()
    setAccordionOpen?.(false)
    afterClose?.()
    afterToggle?.()
  }
  const AccordionTag = !!accordionTag ? accordionTag : "div"

  title = isAccordionOpen ? openTitle : title

  // Give sub-elements the ability to open/close the accordion if they wish:
  const titleContent =
    typeof title === "function" ? title({ isOpen: isAccordionOpen, openAccordion, closeAccordion }) : title
  const bodyContent =
    typeof children === "function" ? children({ isOpen: isAccordionOpen, openAccordion, closeAccordion }) : children

  return (
    <div className={cn(className, { "is-open": isAccordionOpen, "no-indent": noIndent })}>
      <View
        $alignItems="center"
        className={cn("title-view", !shortAccordion && "pb-medium")}
        role="button"
        aria-expanded={isAccordionOpen}
        onClick={() => (isAccordionOpen ? closeAccordion() : openAccordion())}
      >
        <OpenIcon className="open-icon" />
        <ClosedIcon className="closed-icon" />
        <AccordionTag className={cn("ml-xs", { "full-width": fullWidthTitle })}>{titleContent}</AccordionTag>
      </View>
      <div className="child-container">{bodyContent}</div>
    </div>
  )
}

export default styled(Accordion)`
  .title-view:hover {
    cursor: pointer;
  }

  .open-icon,
  .closed-icon {
    color: ${({ iconColor = "var(--gray-6)" }) => iconColor};
    width: ${({ iconSize = "24px" }) => iconSize};
    height: ${({ iconSize = "24px" }) => iconSize};
  }

  &:not(.no-indent) .child-container {
    margin-left: 32px;
  }

  &:not(.is-open) {
    > .child-container,
    > .title-view > .open-icon {
      display: none;
    }
  }

  &.is-open {
    > .title-view > .closed-icon {
      display: none;
    }
  }

  // When printing, override all closed/open styles -- accordions are always open:
  @media print {
    &.is-open,
    &:not(.is-open) {
      > .child-container,
      > .title-view > .open-icon {
        display: initial;
      }

      > .title-view > .closed-icon {
        display: none;
      }
    }
  }
`
