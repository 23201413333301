import cn from "classnames"
import { format as formatDate, parseISO } from "date-fns"
import DOMPurify from "dompurify"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import View from "ui/View"

const DateFieldReadOnlyField = ({ identifier, exerciseInstance, className }) => {
  const userDateAnswer = getExerciseAnswer(exerciseInstance, identifier)
  const formattedUserDateAnswer = userDateAnswer && formatDate(parseISO(userDateAnswer), "MM/dd/yyyy' - 'h:mm' 'a")

  return (
    <View
      className={cn("p-small border border-gray-4 border-radius mb-xl mt-xs text-gray-8", className)}
      $alignItems="center"
      $width="246px"
      $height="48px"
    >
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formattedUserDateAnswer) }} />
    </View>
  )
}

export default styled(DateFieldReadOnlyField)`
  box-shadow: inset 0 1px 4px 0 rgb(0 0 0 / 14%);
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 8%);
`
