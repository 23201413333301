import { Navigate, Route, Routes } from "react-router"

import ResultsHome from "./ResultsHome"
import ResultsReview from "./ResultsReview"

import { SimpleRouteLayout } from "domains/Authentication/Routes"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"

const ResultsLayout = () => {
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam()

  if (redirectTeamId) {
    return <Navigate to={`/team?team_id=${redirectTeamId}`} replace />
  }

  if (!selectedTeamId || needsRedirect) {
    return null
  }

  return <SimpleRouteLayout title="Results" />
}

const Results = () => (
  <Routes>
    <Route element={<ResultsLayout />}>
      <Route path="results/kit/:kitSlug/page/*" element={<ResultsReview />} />
      <Route path="*" element={<ResultsHome />} />
    </Route>
  </Routes>
)

export default Results
