import { Form } from "formik"

import CompanySizeField from "forms/fields/CompanySizeField"
import EmailField from "forms/fields/EmailField"
import HiddenField from "forms/fields/HiddenField"
import JobFunctionsField, { JOB_FUNCTIONS, SHRM_JOB_FUNCTIONS } from "forms/fields/JobFunctionsField"
import JobPositionsField, { SHRM_JOB_POSITIONS } from "forms/fields/JobPositionsField"
import PasswordField from "forms/fields/PasswordField"
import PhoneNumberField from "forms/fields/PhoneNumberField"
import PreferredPronounsField from "forms/fields/PreferredPronounsField"
import TextField from "forms/fields/TextField"
import ToggleField from "forms/fields/ToggleField"
import FormMessage from "forms/FormMessage"
import { ArrowRightIcon } from "icons/FontAwesomeIcons"
import SubmitButton from "ui/SubmitButton"
import View from "ui/View"

const TeamLeadRegisterForm = ({
  invited = false,
  inputFieldSize = "medium",
  showMinimalRegisterForm = true,
  jobFunctions = null,
  jobPositions = null,
  submitButtonText = null,
  isSHRMInvite = false,
  sideImage = null,
}) => (
  <Form name="register" autoComplete="off">
    <View $width="100%" $gap="var(--spacing-4)">
      <div className="space-y-large">
        {!!invited && (
          <EmailField disabled label="Email address" name="email" size={inputFieldSize} autoComplete="off" />
        )}
        <TextField label="First name" name="first_name" autoFocus size={inputFieldSize} />
        <TextField label="Last name" name="last_name" size={inputFieldSize} autoComplete="off" />
        {!!isSHRMInvite && (
          <>
            <TextField label="Company name" name="profile.org_name" size={inputFieldSize} autoComplete="off" />
            <CompanySizeField label="Company size" name="profile.org_size" size={inputFieldSize} autoComplete="off" />
            <JobPositionsField
              name="profile.job_position"
              label="Job position"
              size={inputFieldSize}
              jobPositions={jobPositions ?? SHRM_JOB_POSITIONS}
            />
            <JobFunctionsField
              name="profile.job_function"
              label="Job function"
              size={inputFieldSize}
              jobFunctions={jobFunctions ?? SHRM_JOB_FUNCTIONS}
            />
          </>
        )}
        {!invited && <EmailField label="Email address" name="email" size={inputFieldSize} autoComplete="off" />}
        {!!isSHRMInvite && (
          <PhoneNumberField name="profile.phone_number" label="Phone number (optional)" size={inputFieldSize} />
        )}
        <PasswordField
          label="Create a password"
          name="password"
          placeholder="Password"
          size={inputFieldSize}
          autoComplete="new-password"
          eye
          passwordHint
        />
        {!showMinimalRegisterForm && (
          <>
            <PreferredPronounsField
              name="profile.pronouns"
              label="How should we refer to you? (optional)"
              size="large"
            />
            <JobFunctionsField
              name="profile.job_function"
              label="Your job function (optional)"
              size="large"
              jobFunctions={jobFunctions ?? JOB_FUNCTIONS}
            />
          </>
        )}
      </div>
      {!!sideImage && <div>{sideImage}</div>}
    </View>
    <TermsAndConditionsCheckbox className="mt-large" />
    <FormMessage />

    <View className="pt-xxl">
      <SubmitButton>
        <span>{submitButtonText ?? "Let’s do this"}</span>
        <ArrowRightIcon />
      </SubmitButton>
    </View>
  </Form>
)

const TeamMemberRegisterForm = ({
  jobFunctions = null,
  invited = false,
  showMinimalRegisterForm = false,
  additionalContent = null,
}) => (
  <Form name="register" autoComplete="off" className="space-y-large mt-large">
    {invited ? (
      <EmailField disabled label="Email address" name="email" size="medium" autoComplete="off" />
    ) : (
      <HiddenField name="email" />
    )}
    <TextField
      label="First name *"
      name="first_name"
      placeholder="Enter your first name here"
      autoFocus
      size="medium"
    />
    <TextField
      label="Last name *"
      name="last_name"
      placeholder="Enter your last name here"
      size="medium"
      autoComplete="off"
    />
    {!showMinimalRegisterForm && (
      <>
        <PreferredPronounsField name="profile.pronouns" label="How would you like us to refer to you?" size="medium" />
        <JobFunctionsField
          name="profile.job_function"
          label="Your job function *"
          size="medium"
          jobFunctions={jobFunctions ?? JOB_FUNCTIONS}
        />
      </>
    )}
    <PasswordField
      label="Create a password *"
      name="password"
      placeholder="Enter your password here"
      size="medium"
      autoComplete="new-password"
      eye
    />
    <TermsAndConditionsCheckbox />
    <FormMessage />
    {!!additionalContent && <div>{additionalContent}</div>}
    <View className="pt-large pt-xxxxl-mobile-only">
      <SubmitButton>
        <span>Continue</span>
        <ArrowRightIcon />
      </SubmitButton>
    </View>
  </Form>
)

const TermsAndConditionsCheckbox = ({ className }) => (
  <ToggleField name="accept_policies" className={className}>
    <div>
      I have read and agree to the&nbsp;
      <a href="https://risingteam.com/terms" target="_blank" rel="noopener noreferrer">
        Terms and Conditions
      </a>
      &nbsp;and&nbsp;
      <a href="https://risingteam.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      . *
    </div>
  </ToggleField>
)

export { TeamLeadRegisterForm, TeamMemberRegisterForm }
