import { styled } from "styled-components"

const DEISafeSpacesTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Imagine a world where you feel safe and protected all the time. It’s perfect - always the right temperature,
        time of day, season - everything is exactly to your liking. Consider, how did you learn what temperature you
        liked? What season was perfect for you? What time of day made you feel at your best? Learning these things
        without a doubt took some trial and error - you would have had to experience a temperature too cold as well as
        too warm to know what was perfect.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Safe {"&"} brave spaces</h2>
      <p className="mb-medium">
        Learning how to hold Safe and Brave spaces can be similar. In finding what your personal “perfect” is, you’ll
        likely first want to feel safe enough to try something new, but will need to be brave enough to be willing to
        come in contact with something unknown and different. In this session you’ll learn more about being safe and
        brave, constructive discomfort, the danger zone and the learning edge.
      </p>
      <img
        src="https://static.risingteam.com/kit_assets/dei-safe-spaces/constructive-discomfort-model.png"
        alt="kit-training-why-appreciation-matters"
        className="my-medium"
      />
      <p className="mb-medium">
        Your team will become{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.onblick.com/blogs/motivating-your-teams-to-step-out-of-their-comfort-zone"
        >
          stronger
        </a>{" "}
        when each one of you learns how to move outside of your comfort zones and lean into the opportunities presented
        while in the learning edge. The ability as individuals, who are part of a team, to speak up (brave) and be
        vulnerable (safe) are essential in building a more inclusive environment.
      </p>
      <p className="mb-medium">
        This kit may feel a little different from previous ones, as the experiences you choose to share during this
        session may bring up a range of feelings for you. We want to encourage all of you to remember to take care of
        yourself, share only what feels safe, and to bravely lean into your learning edge whenever possible.
        Confidentiality is also an important part of creating a safe and brave space. We like to say, “What is said here
        stays here, what is learned here, leaves here.”
      </p>
      <h2 className="text-rising-orange mb-medium">2. Social identity</h2>
      <p className="mb-medium">
        We dig deep in this session on what{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://lo.library.wisc.edu/DEI_foundations/lesson_1.html">
          social identity
        </a>{" "}
        is and its effects on how each person sees themselves and experiences the world. Your social identity can impact
        how you feel about interactions with others of both similar and different backgrounds than you. Remaining humble
        and curious about those around you - even those who hold a similar identity - can help raise our awareness and
        understanding of others.
      </p>
      <p className="mb-medium">
        Many of us have been led to believe that diversity is solely defined by background, culture and gender. However,
        there are many other forms of diversity that often go overlooked. Disability, language, neurodivergence,
        parenting status, and citizenship status are just a few of many identities that can deeply impact our everyday
        lives. Expanding our concept of diversity helps us appreciate the richness of our backgrounds and differing
        perspectives. The research shows that{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.psychologytoday.com/us/blog/social-empathy/201907/why-we-need-diversity#:~:text=Diversity%20brings%20in%20new%20ideas,true%20for%20our%20culture%2C%20too."
        >
          diversity is an asset
        </a>
        , making for a better team and overall a more effective organization.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Inclusive communication</h2>
      <p className="mb-medium">
        Everything communicates something - our body language, our words, and yes, even our silence. When individuals
        feel included, teams are{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2019/12/the-value-of-belonging-at-work">
          healthier and more productive
        </a>
        . As we consider what it means to create more inclusive teams, learning how to be better, more thoughtful
        communicators can play a big role - especially as it relates to communicating through something as personal as
        the identities we align ourselves with.
      </p>
      <p className="mb-medium">
        Most of us have felt the sting of exclusion - whether as a child on the playground or perhaps as a result of
        religious clothing, political affiliation, or socioeconomic status. Striving to be inclusive in our
        communication isn’t easy. Mistakes and{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.npr.org/2020/06/08/872371063/microaggressions-are-a-big-deal-how-to-talk-them-out-and-when-to-walk-away"
        >
          microaggressions
        </a>{" "}
        will happen, but knowing what to be conscious of when communicating with our peers, both in writing and
        speaking, will help us avoid exclusionary behaviors.
      </p>
      <h2 className="text-rising-orange mb-medium">4. Inclusive mindset</h2>
      <p className="mb-medium">
        Brave - Humble - Dedicated are the three tenets that make up an Inclusive Mindset. In our workshops, we define
        this concept by saying, be brave enough to take risks outside of your comfort zone and lean into the learning
        opportunity. Be humble enough to listen and respect perspectives that are different from your own. Be dedicated
        to a lifelong commitment of accountability, raising your awareness on where bias shows up, and advocating on
        behalf of others.
      </p>
      <p className="mb-medium">
        Learning to have an Inclusive Mindset is just like excellence or innovation, it takes work on a continual basis.
        Training our minds to hold this mindset as a baseline of how we interact with those around us can lead to true
        positive changes within ourselves as well as in our interactions with others. As you work through this session,
        we hope you will begin to form ideas regarding what{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/business/talent/blog/talent-acquisition/ways-to-make-real-progress-on-diversity-equity-and-inclusion"
        >
          actions
        </a>{" "}
        you can take to build a more inclusive team.
      </p>
      <h5 className="mb-medium">Additional resources</h5>
      <ul className="mb-medium ml-medium">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://businessleadershiptoday.com/why-a-learning-culture-is-so-important-for-success/"
          >
            Why A Learning Culture Is So Important For Success
          </a>{" "}
          (Business Leadership Today)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.onblick.com/blogs/motivating-your-teams-to-step-out-of-their-comfort-zone"
          >
            Motivating Your Teams to Step Out of Their Comfort Zone
          </a>{" "}
          (OnBlick Inc)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.psychologytoday.com/us/blog/social-empathy/201907/why-we-need-diversity#:~:text=Diversity%20brings%20in%20new%20ideas,true%20for%20our%20culture%2C%20too"
          >
            Why We Need Diversity
          </a>{" "}
          (Psychology Today)
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2019/12/the-value-of-belonging-at-work">
            The Value of Belonging at Work
          </a>{" "}
          (HBR)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/business/talent/blog/talent-acquisition/ways-to-make-real-progress-on-diversity-equity-and-inclusion"
          >
            38 Ways to Make Real Progress on Diversity, Equity, and Inclusion
          </a>{" "}
          (LinkedIn)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://pediaa.com/what-is-the-difference-between-personal-and-social-identity/"
          >
            What is the Difference Between Personal and Social Identity
          </a>{" "}
          (Pediaa)
        </li>
      </ul>
    </div>
  </div>
)

export default styled(DEISafeSpacesTraining1)`
  img {
    max-width: 421px;
    height: auto;

    @media (max-width: ${({ theme }) => theme.mobileMax}) {
      max-width: 100%;
      height: auto;
    }
  }
`
