import { parseISO, toDate, format as formatDate } from "date-fns"

function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getNumMinutesPassed(startTime: Date | number): number {
  return Math.floor(getNumSecondsPassed(startTime) / 60)
}

function getNumSecondsPassed(startTime: Date | number): number {
  // Accept number (seconds) or Date as startTime:
  const startSeconds: number = typeof startTime === "number" ? startTime : startTime.getTime()
  return Math.floor((Date.now() - startSeconds) / 1000)
}

function getNumSecondsSinceEpoch(): number {
  return getNumSecondsPassed(0)
}

// Convert a timestamp (ISO or integer) to a date string.
// - timestamp: timestamp to format; may be ISO-formatted datetime string
//              or positive integer milliseconds since epoch
// - format: format string to use; should be compatible with
//                                 https://date-fns.org/docs/format
// - omitYearIfCurrent: if true, only include year if timestamp is outside current year
function formatTimestampAsDate(
  timestamp: string | number,
  format = "M/dd/yyyy",
  { omitYearIfCurrent = false } = {}
): string {
  let date = null
  if (Number.isInteger(timestamp)) {
    date = toDate(timestamp)
  }
  if (typeof timestamp === "string") {
    date = parseISO(timestamp)
  }
  if (omitYearIfCurrent && date?.getFullYear() === new Date().getFullYear()) {
    format = format.replace(/\/?y+\/?/i, "")
  }
  return date ? formatDate(date, format) : ""
}

export { getTimeZone, getNumMinutesPassed, getNumSecondsPassed, getNumSecondsSinceEpoch, formatTimestampAsDate }
