import cn from "classnames"
import { useState } from "react"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import AvailableKitExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/AvailableKitExpandedContent"
import CompletedKitExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/CompletedKitExpandedContent"
import KitScheduleModeExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/KitScheduleModeExpandedContent"
import RescheduleKitScheduleModeExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/RescheduleKitScheduleModeExpandedContent"
import TMAvailableExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/TMAvailableExpandedContent"
import TMScheduledExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/TMScheduledExpandedContent"
import UnavailableKitExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/UnavailableKitExpandedContent"
import KitTypePill from "domains/LeaderKit/KitsTableofContents/components/KitTypePill"
import { KIT_STATUS, getKitStatus } from "domains/LeaderKit/KitsTableofContents/utils"
import {
  AngleDownIcon,
  AngleUpIcon,
  BoltIcon,
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  SpinnerIcon,
} from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"
import { formatTimestampAsDate } from "utils/date"
import { KitType, formatKitSessionTime } from "utils/kit"

const KitTile = ({
  kitInfo,
  kitInstance,
  exerciseInstance,
  user,
  selectedTeam,
  initiallyExpanded,
  isConnectAccount,
  className,
  isScheduleModeActive,
  isSetupStep = false,
  isCurrentTeamLead,
}) => {
  const windowSize = useWindowSize()
  const kitStatusConfig = getKitStatusConfig(
    kitInfo,
    kitInstance,
    exerciseInstance,
    user,
    selectedTeam,
    isConnectAccount,
    windowSize,
    isScheduleModeActive,
    isSetupStep
  )

  return (
    <KitTileContents
      kitInfo={kitInfo}
      kitInstance={kitInstance}
      user={user}
      kitStatusConfig={kitStatusConfig}
      selectedTeam={selectedTeam}
      initiallyExpanded={initiallyExpanded}
      className={className}
      isScheduleModeActive={isScheduleModeActive}
      isSetupStep={isSetupStep}
      isCurrentTeamLead={isCurrentTeamLead}
    />
  )
}

// KitTileContents is split into its own component so we can pass kitStatusConfig
// as a prop to access it in styled-components css
const KitTileContents = styled(function KitTileContents({
  kitInfo,
  kitInstance,
  user,
  kitStatusConfig,
  selectedTeam,
  initiallyExpanded,
  className,
  isScheduleModeActive,
  isSetupStep,
  isCurrentTeamLead,
}) {
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded)
  const homeUrl = kitInstance?.home_url ?? `/kit/${kitInfo.slug}?team_id=${selectedTeam.id}`

  const { linkUrl, linkText, ExpandedContent } = kitStatusConfig
  const Wrapper = linkUrl && !isScheduleModeActive ? Link : "div"
  const wrapperProps = {}
  if (linkUrl) {
    wrapperProps.to = linkUrl
  }
  const openExpanded = () => {
    if (ExpandedContent && !isExpanded) {
      setIsExpanded(true)
    }
  }
  const closeExpanded = () => {
    if (ExpandedContent && isExpanded) {
      setIsExpanded(false)
    }
  }

  const minutesText = formatKitSessionTime(kitInfo.session_time_minutes)

  return (
    <div className={className}>
      <Wrapper {...wrapperProps} onClick={openExpanded} className={cn({ "cursor-pointer": !isExpanded })}>
        <div className="kit p-medium border-radius-medium border-left-thick">
          <View
            $alignItems="center"
            $justifyContent="space-between"
            onClick={closeExpanded}
            className={cn({ "cursor-pointer": isExpanded })}
          >
            <div className={cn({ "full-width": isMobileOrSmaller && !ExpandedContent })}>
              <View
                $alignItems="center"
                $alignItemsMobile="flex-start"
                $flexDirectionMobile="column-reverse"
                className={cn("mb-xxs", { "space-x-xs": isMobileLandscapeOrLarger })}
              >
                {isSetupStep ? (
                  <View $flexDirection="column">
                    <h3 className="text-gray-9">{kitInfo.title}</h3>
                    <View $flexDirection="row" className="mt-xs">
                      <KitTypePill className="mr-xs" selectedKitInfo={kitInfo} />
                      <div className="text-small text-gray-8">
                        <ClockIcon className="mr-xxs" />
                        {minutesText}
                      </div>
                    </View>
                  </View>
                ) : (
                  <>
                    {!!isMobileOrSmaller && <KitTypePill className="mt-xxs" selectedKitInfo={kitInfo} />}
                    {ExpandedContent && !kitInfo.unavailable && !isScheduleModeActive && isCurrentTeamLead ? (
                      <Link to={homeUrl} className="text-gray-9" data-testid={`kit-link:${kitInfo.slug}`}>
                        <h3>{kitInfo.title}</h3>
                      </Link>
                    ) : (
                      <h3 className="text-gray-9">{kitInfo.title}</h3>
                    )}
                    {!!isMobileLandscapeOrLarger && <KitTypePill selectedKitInfo={kitInfo} />}
                    <div className="text-small text-gray-8">
                      <ClockIcon className="mr-xxs" />
                      {minutesText}
                    </div>
                  </>
                )}
              </View>
              {!isSetupStep && <KitStatus kitStatusConfig={kitStatusConfig} />}
            </div>
            {!!isMobileLandscapeOrLarger && !!linkText && <div className="text-semi-bold">{linkText}</div>}
            {!!ExpandedContent && !isSetupStep && !isExpanded && <AngleDownIcon className="text-medium text-gray-7" />}
            {!!ExpandedContent && !isSetupStep && !!isExpanded && <AngleUpIcon className="text-medium text-gray-7" />}
          </View>
          {!!ExpandedContent && !!isExpanded && (
            <ExpandedContent
              kitInstance={kitInstance}
              kitInfo={kitInfo}
              user={user}
              selectedTeam={selectedTeam}
              className="mt-xs"
              isScheduleModeActive={isScheduleModeActive}
              isSetupStep={isSetupStep}
            />
          )}
        </div>
      </Wrapper>
    </div>
  )
})`
  .kit {
    box-shadow: var(--blur-2);
    border-color: ${({ kitStatusConfig }) => kitStatusConfig.color};

    &:hover {
      box-shadow: var(--lift-4);
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
    h3 {
      max-width: 300px;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    h3 {
      max-width: 360px;
    }
  }
`

const KitStatus = ({ kitStatusConfig }) => {
  const { color, text, linkText, Icon } = kitStatusConfig
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  return (
    <>
      <View $alignItems="center" className={cn("text-gray-8 fit-content", { "mb-xxs": isMobileOrSmaller })}>
        {!!Icon && <Icon color={color} className={cn("mr-xs", { "ml-xs": isMobileLandscapeOrLarger })} />}
        <div>{text}</div>
      </View>
      {!!isMobileOrSmaller && !!linkText && <div className="text-semi-bold text-align-right">{linkText}</div>}
    </>
  )
}

const getKitStatusConfig = (
  kitInfo,
  kitInstance,
  exerciseInstance,
  user,
  selectedTeam,
  isConnectAccount,
  windowSize,
  isScheduleModeActive,
  isSetupStep = false
) => {
  const { isMobileOrSmaller } = windowSize
  const scheduledDateFormat = isMobileOrSmaller ? "M/dd/yyyy" : "'for' M/dd/yyyy 'at' h:mm aaa"
  const kitStatus = getKitStatus(kitInstance, kitInfo)

  const isSelectedTeamLead = selectedTeam.team_lead_id === user.id
  const isDemo = selectedTeam.demo

  if (!isSelectedTeamLead && isDemo) {
    return getDemoKitStatusConfig({ kitStatus, kitInstance })
  }

  const isConnectAccountBonusKit = isConnectAccount && kitInfo.type === KitType.KIT

  const statusOptions = {
    [KIT_STATUS.COMPLETE]: {
      color: "var(--orange-4)",
      text: (
        <span className="text-nowrap">
          Completed {!!kitInstance?.session_completed_at && formatTimestampAsDate(kitInstance.session_completed_at)}
        </span>
      ),
      Icon: CheckIcon,
      ...(!isScheduleModeActive
        ? isSelectedTeamLead || !kitInfo.has_standalone_exercise || exerciseInstance
          ? {
              ExpandedContent: CompletedKitExpandedContent,
            }
          : {
              linkUrl: getStandaloneExerciseUrl({ slug: kitInstance?.basic_kit?.slug, teamId: selectedTeam.id }),
              linkText: "Take exercise",
            }
        : {
            ExpandedContent: KitScheduleModeExpandedContent,
          }),
    },
    [KIT_STATUS.SCHEDULED]: {
      color: "var(--rising-yellow)",
      text: (
        <span className="text-nowrap">
          Scheduled{" "}
          {!!kitInstance?.scheduled_time && formatTimestampAsDate(kitInstance.scheduled_time, scheduledDateFormat)}
        </span>
      ),
      Icon: CalendarIcon,
      ...(!isScheduleModeActive
        ? isSelectedTeamLead
          ? {
              linkUrl: kitInstance?.home_url,
              linkText: "Get ready",
              // For the kit tile in setup flow, continue to show kit description
              // and scheduled date even after the kit is scheduled:
              ...(isSetupStep ? { ExpandedContent: AvailableKitExpandedContent } : {}),
            }
          : {
              ExpandedContent: TMScheduledExpandedContent,
            }
        : { ExpandedContent: RescheduleKitScheduleModeExpandedContent }),
    },
    [KIT_STATUS.AVAILABLE]:
      isSelectedTeamLead || isScheduleModeActive
        ? {
            color: "var(--rising-green)",
            text: selectedTeam.preview ? (
              <span className="text-nowrap">Preview session</span>
            ) : isConnectAccountBonusKit ? (
              // allow wrapping since this can go offscreen on mobile:
              "1 Development Kit included with your subscription!"
            ) : (
              <span className="text-nowrap">Available now</span>
            ),
            Icon: BoltIcon,
            ExpandedContent: AvailableKitExpandedContent,
          }
        : {
            color: "var(--gray-5)",
            text: <span className="text-nowrap">Available to team leads</span>,
            Icon: BoltIcon,
            ExpandedContent: TMAvailableExpandedContent,
          },
    [KIT_STATUS.IN_PROGRESS]: {
      color: "var(--blue-2)",
      text: <span className="text-nowrap">In progress</span>,
      Icon: SpinnerIcon,
      ...(!isScheduleModeActive
        ? {
            linkUrl: kitInstance?.session_url,
            linkText: <span className="text-nowrap">Rejoin the session</span>,
          }
        : {
            ExpandedContent: KitScheduleModeExpandedContent,
          }),
    },
    [KIT_STATUS.UNAVAILABLE]: {
      color: "var(--gray-5)",
      text: <span className="text-nowrap">Available with {isConnectAccount ? "upgrade" : "subscription"}</span>,
      ExpandedContent: UnavailableKitExpandedContent,
    },
  }

  return statusOptions[kitStatus]
}

const getDemoKitStatusConfig = ({ kitStatus, kitInstance }) => {
  if (kitStatus === KIT_STATUS.COMPLETE) {
    return {
      color: "var(--orange-4)",
      text: `Completed ${kitInstance?.session_completed_at && formatTimestampAsDate(kitInstance.session_completed_at)}`,
      Icon: CheckIcon,
      ExpandedContent: CompletedKitExpandedContent,
    }
  }

  return {
    color: "var(--rising-green)",
    text: "Preview session",
    Icon: BoltIcon,
    ExpandedContent: AvailableKitExpandedContent,
  }
}

export default KitTile
