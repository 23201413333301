import { styled } from "styled-components"

import { formatLength } from "utils/ui"

const TextContainer = styled.div`
  ${({ theme, maxWidth = null }) => formatLength("max-width", maxWidth ?? theme.textContainerDefaultMaxWidth)};
  ${({ theme, mobileMaxWidth = null }) =>
    formatLength("max-width", mobileMaxWidth, { media: `max-width: ${theme.mobileMax}` })};
  ${({ minWidth = null }) => formatLength("min-width", minWidth)};
  ${({ theme, mobileMinWidth = null }) =>
    formatLength("min-width", mobileMinWidth, { media: `max-width: ${theme.mobileMax}` })};
`
export default TextContainer
