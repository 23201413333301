import { useField } from "formik"
import { useState } from "react"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { SelectFavoriteStickyNotes } from "domains/KitSession/components/StickyNotes"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useUser } from "resources/users"
import Grid from "ui/Grid"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { plural } from "utils/string"
import { useHasTeamFeature } from "utils/team"

const ExerciseStickyNotesUserSelectTeamFavorites = ({
  className,
  name,
  exerciseIdentifier,
  filterIsFavorite,
  saveOnChange,
  userOverallTeamFavoriteLimit = 5,
}) => {
  const USER_OVERALL_TEAM_FAVORITE_LIMIT = userOverallTeamFavoriteLimit
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const {
    data: exerciseInstances,
    isFetching,
    isInitialLoading,
  } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })
  const { data: user } = useUser({ userId: "me" })

  const [{ value: userStickyNotes }, _, { setValue }] = useField(name)
  const initialVotesRemaining =
    USER_OVERALL_TEAM_FAVORITE_LIMIT -
    (userStickyNotes?.filter((stickyNote) => stickyNote?.isUserOverallTeamFavorite)?.length ?? 0)
  const [votesRemaining, setVotesRemaining] = useState(initialVotesRemaining)

  useEffectAfterChange(() => {
    if (!isFetching) {
      const allUsersFavoriteNotes = getUserStickyNotes({
        exerciseInstances,
        exerciseIdentifier,
        filterIsFavorite,
        name,
        user,
      })
      setValue(Object.values(allUsersFavoriteNotes))
    }
  }, [exerciseIdentifier, exerciseInstances, filterIsFavorite, isFetching, name, user, saveOnChange, setValue])

  if (isInitialLoading) {
    return <Loading />
  }

  const handleOnFavoriteClick = (userStickyNoteIndex) => {
    const isCurrentlyOverallFavorited = userStickyNotes[userStickyNoteIndex]?.isUserOverallTeamFavorite === true
    const userOverallTeamFavorite = userStickyNotes.filter((stickyNote) => stickyNote?.isUserOverallTeamFavorite)

    if (userOverallTeamFavorite.length === USER_OVERALL_TEAM_FAVORITE_LIMIT && !isCurrentlyOverallFavorited) {
      return
    }

    const updatedUserStickyNotes = userStickyNotes.map((stickyNote, index) =>
      index === userStickyNoteIndex
        ? { ...userStickyNotes[userStickyNoteIndex], isUserOverallTeamFavorite: !isCurrentlyOverallFavorited }
        : stickyNote
    )
    setVotesRemaining((prevVotesRemaining) =>
      isCurrentlyOverallFavorited ? prevVotesRemaining + 1 : prevVotesRemaining - 1
    )
    setValue(updatedUserStickyNotes)
    saveOnChange(name, updatedUserStickyNotes)
  }

  return (
    <>
      <div className="text-semi-bold mb-medium">You have {plural(votesRemaining, "vote")} remaining.</div>
      <Grid className={className} $columns="3" $columnsMobile="1" $columnsTablet="2" $gap="12px">
        <SelectFavoriteStickyNotes
          name={name}
          stickyNotes={userStickyNotes?.map((stickyNote) => ({
            value: stickyNote.value,
            isFavorite: !!stickyNote?.isUserOverallTeamFavorite,
          }))}
          handleOnFavoriteClick={handleOnFavoriteClick}
        />
      </Grid>
    </>
  )
}

const getUserStickyNotes = ({ exerciseInstances, exerciseIdentifier, filterIsFavorite, name, user }) => {
  const allUsersFavoriteNotes = {}
  exerciseInstances.forEach((exerciseInstance) => {
    if (exerciseInstance.user_id === user.id) {
      const userTeamFavorites = getExerciseAnswer(exerciseInstance, name)
      userTeamFavorites?.forEach((stickyNote) => {
        allUsersFavoriteNotes[stickyNote.value] = { ...stickyNote }
      })
    }
    const exerciseAnswer = getExerciseAnswer(exerciseInstance, exerciseIdentifier)
    const userNotes =
      (filterIsFavorite ? exerciseAnswer?.filter((stickyNote) => stickyNote?.isFavorite) : exerciseAnswer) ?? []
    const currentUserNotes = userNotes
      .map((stickyNote) => ({
        value: stickyNote.value.trim(),
        isUserOverallTeamFavorite: false,
      }))
      .filter((stickyNote) => stickyNote.value.length > 0)

    !!currentUserNotes &&
      currentUserNotes.forEach((stickyNote) => {
        if (!allUsersFavoriteNotes[stickyNote.value]) {
          allUsersFavoriteNotes[stickyNote.value] = { ...stickyNote }
        }
      })
  })
  return allUsersFavoriteNotes
}

export default ExerciseStickyNotesUserSelectTeamFavorites
