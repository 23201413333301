const BOHEmployeeExperienceTraining3 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Our focus at Bank of Hawaiʻi is about creating a culture and environment where we can all do our best work and
        thrive. We care about delivering a great client experience (CX) to our customers and in order to do so, we need
        to deliver the best employee experience (EX) to our valued teammates because we know that great EX leads to
        great CX.
      </p>
      <p className="mb-medium">
        Now that you understand the EX Principles and how it can help our teammates Thrive and Shine, what should you do
        after you complete the EX Principles Rising Team kit to continue the momentum?
      </p>
      <h2 className="text-rising-orange mb-medium">Understanding the EX principles</h2>
      <p className="mb-medium">
        As we have learned, how we deliver great employee experiences are based on our EX principles of Value Me, Know
        Me, Empower Me and Inspire Me.
      </p>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Value Me</span> – If you value me, you get to know me
          </li>
          <li>
            <span className="text-bold">Know Me</span> – When you know me, you can empower me
          </li>
          <li>
            <span className="text-bold">Empower Me</span> – When you empower me, I feel inspired
          </li>
          <li>
            <span className="text-bold">Inspire Me</span> – When I am inspired, I THRIVE
          </li>
        </ul>
      </div>
      <p className="mb-medium">And When I THRIVE, I SHINE!</p>
      <p className="mb-medium">
        As a leader, how do you ensure that you are exhibiting behaviors that are in alignment with the EX Principles?
        Do your employees feel valued? Unsure if you are delivering the principles? Create a feedback squad.
      </p>
      <p className="mb-medium">
        A feedback squad is a trusted group of colleagues who can observe and share what you are doing well and identify
        opportunities for you to grow your behaviors so they are in alignment with the EX Principles. Who’s on your
        squad?
      </p>
      <h2 className="text-rising-orange mb-medium">Keep Their Profiles Top of Mind</h2>
      <p className="mb-medium">
        Keeping the profile of your teammate top of mind can help guide how you on how to meet their needs. Do you know
        them and their aspirations? What can you do to empower them so they can continue to grow and thrive? Here are
        some ideas on how you can exhibit each of the principles.
      </p>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Value Me</span> – People feel valued when you get to know them. This can take
            the form of appreciation, investment, acknowledgement, recognition, care or celebration
          </li>
          <li>
            <span className="text-bold">Know Me</span> – Knowing someone leads to empowerment which can take the form of
            understanding, encouragement, communication and helping to belong
          </li>
          <li>
            <span className="text-bold">Empower Me</span> – Empowerment can feel like trust, support or enrichment so
            your teammate can feel inspired
          </li>
          <li>
            <span className="text-bold">Inspire Me</span> – To inspire you may motivate, encourage, or uplift your
            teammate so they can thrive
          </li>
        </ul>
      </div>
      <h3 className="my-medium">Hone In and Refine Your Skills</h3>
      <p className="mb-medium">
        Continue to leverage your feedback squad to identify what you need to adjust or do differently to show your team
        how much you care about their growth and finding opportunities for them to Thrive.
      </p>
      <p className="mb-medium">
        Review your{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://engage.surveymonkey.com/login"
          className="text-gray-9 text-underline"
        >
          BluePulse
        </a>{" "}
        survey results. What are the opportunities to create a greater employee experience for your team? How can you
        leverage the EX Principles to deliver that experience? Use the data to help inform your understanding of your
        impact and what your team is looking for to create greater EX.
      </p>
      <p className="mb-medium">
        In your regular pop-up meeting with your employee talk about what you are doing to hone your skills and gather
        input from your employee so you can continue to refine your ability to create great EX for them.
      </p>
    </div>
  </div>
)

export default BOHEmployeeExperienceTraining3
