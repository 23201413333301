import LearnerAgilityBehaviorsUserResults from "domains/Exercise/ResultsComponents/LearnerAgilityBehaviorsUserResults"
import LearnerAgilityChart from "domains/Exercise/ResultsComponents/LearnerAgilityChart"
import LearnerAgilityLearningPreferencesResults from "domains/Exercise/ResultsComponents/LearnerAgilityLearningPreferencesResults"
import { BrainIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"

const LearnerAgilityShareResults = ({ exerciseInstance }) => {
  const userIdMap = {
    [exerciseInstance.user_id]: {
      id: exerciseInstance.user_id,
      short_name: `${exerciseInstance.user_share_first_name} ${exerciseInstance.user_share_last_initial}`,
    },
  }

  return (
    <div>
      <div className="mb-medium">
        <View $alignItems="center">
          <BrainIcon className="text-orange-2 title-icon mr-medium" />
          <h1 className="text-gray-9">Learning profile</h1>
        </View>
      </div>
      <h3 className="mb-medium">Learning behaviors</h3>
      <LearnerAgilityBehaviorsUserResults exercise={exerciseInstance} />
      <h3 className="text-bold text-gray-9 mb-medium">Learning preferences</h3>
      <p className="mb-medium text-gray-9">
        One benefit of the modern age is that there are many ways to learn just about any topic or skill. And people
        have different preferences around how they like to learn, process, and retain new information. Levering our
        preferences can make it easier to learn.
      </p>
      <LearnerAgilityChart userIdMap={userIdMap} exerciseInstances={[exerciseInstance]} showLegend={false} />
      <h3 className="text-gray-9 mb-medium">Your top three</h3>
      <LearnerAgilityLearningPreferencesResults exercise={exerciseInstance} isCompact={false} />
    </div>
  )
}

export default LearnerAgilityShareResults
