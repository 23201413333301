import CareerHorizonsSelectedUserView from "domains/Exercise/ResultsComponents/CareerHorizonsSelectedUserView"
import { MountainsIcon } from "icons/FontAwesomeIcons"
import Loading from "ui/Loading"
import View from "ui/View"

const CareerHorizonsShareResults = ({ exerciseInstance }) => {
  const userProfileFirstName = exerciseInstance.user_share_first_name
  const userProfileLastInitial = exerciseInstance.user_share_last_initial + "."

  if (!exerciseInstance) {
    return <Loading />
  }

  return (
    <div className="text-gray-9">
      <div className="mb-medium">
        <View $alignItems="center">
          <MountainsIcon className="text-orange-2 title-icon mr-medium" />
          <h1>Career Horizons</h1>
        </View>
      </div>
      <p className="mb-small">
        This profile was created by <span className="text-capitalize">{userProfileFirstName}</span> during a Rising Team
        session.
      </p>
      <p className="mb-xl">
        <a target="_blank" rel="noopener noreferrer" href="https://risingteam.com">
          Join Rising Team today
        </a>{" "}
        to get access to this and more exercises and insights that will benefit your teamwork.
      </p>
      <h2 className="text-capitalize mb-xl">
        {userProfileFirstName} {userProfileLastInitial}
      </h2>
      <div>
        <CareerHorizonsSelectedUserView exercise={exerciseInstance} />
      </div>
    </div>
  )
}

export default CareerHorizonsShareResults
