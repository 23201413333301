import { styled } from "styled-components"

import ChoicesField from "./ChoicesField"

const CheckboxGroupField = styled(function CheckboxGroupField({
  name,
  options,
  columns = 3, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  ...props
}) {
  return <ChoicesField name={`${name}.value`} className="space-y-small" type="checkbox" options={options} {...props} />
})`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => (columns >= 2 ? 2 : 1)}, minmax(0, 1fr));
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.desktopMin}) {
    grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  }
`

export default CheckboxGroupField
