import { isCITestEnv, isDevelopmentEnv } from "utils/env"

function isSSORedirectLoginFlowEnabled(): boolean {
  if (isDevelopmentEnv() || isCITestEnv()) {
    // Redirect disabled for the time being in dev environment due to issues preventing login.
    return false
  }
  return true
}

// disabled since we'll add more util exports in here; remove when there's more than one
// eslint-disable-next-line import/prefer-default-export
export { isSSORedirectLoginFlowEnabled }
