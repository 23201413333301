function SvgPieSliceOneIcon(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_di_27419_47213)">
        <path
          d="M31.2803 21.2995C32.0184 20.9234 32.3153 20.0179 31.9014 19.3002C28.7114 13.7691 23.6773 9.27795 17.3174 6.84369C16.5437 6.54756 15.6957 6.98245 15.4397 7.77033L8.14798 30.2121C7.73502 31.483 9.06485 32.6188 10.2555 32.0121L31.2803 21.2995Z"
          fill="currentColor"
          stroke="white"
          strokeOpacity="0.6"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_27419_47213"
          x="3"
          y="2"
          width="34"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_27419_47213" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_27419_47213" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_27419_47213" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgPieSliceOneIcon
