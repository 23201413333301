// Use String instead of Numeric Enum here for three reasons:
// - to prevent any number from being accepted in places where ZIndex type is expected
// - to restrict/avoid UI code from doing custom math with z-indices;
//   instead, if custom functionality is needed we should build it into this system
// - to avoid "reverse mappings" which we don't want,
//   eg. {"Zero": 0, "Sticky": 100, ... "0": "Zero", "100": "Sticky" }
enum ZIndex {
  Zero = "0",
  Sticky = "100",
  Modal = "200",
  Menu = "300",
  Tooltip = "400",

  Above = "10",
  Below = "-10",

  Max = "2147483647", // (2^32 / 2 - 1) maximum CSS z-index value in modern browsers
}

// Construct a ZIndexLayer type and const object, which is a subset of ZIndex
// containing only entries which represent our "stack" of UI layers.
// The resulting type and object will behave exactly as if this were defined as enum.
type ZIndexLayer = Exclude<ZIndex, ZIndex.Above | ZIndex.Below | ZIndex.Max>
const { Above: _, Below: __, Max: ___, ...zIndexLayers } = ZIndex
// eslint-disable-next-line @typescript-eslint/no-redeclare
const ZIndexLayer = zIndexLayers

type ZIndexAbove = Brand<string, "ZIndexAbove">
type ZIndexBelow = Brand<string, "ZIndexBelow">

function aboveZIndex(zIndex: ZIndex | ZIndexAbove): ZIndexAbove {
  return (parseInt(zIndex) + parseInt(ZIndex.Above)).toString() as ZIndexAbove
}

function belowZIndex(zIndex: ZIndex | ZIndexBelow): ZIndexBelow {
  return (parseInt(zIndex) + parseInt(ZIndex.Below)).toString() as ZIndexBelow
}

export { ZIndex, ZIndexLayer, aboveZIndex, belowZIndex }
