function SvgClose(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <g transform="rotate(45 12 12)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13 0h-2v11H0v2h11v11h2V13h11v-2H13V0z" fill="currentColor" />
      </g>
    </svg>
  )
}

export default SvgClose
