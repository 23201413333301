import cn from "classnames"
import { differenceBy, sample } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { ArrowRightIcon } from "icons/FontAwesomeIcons"
import { useRaisedHands } from "resources/monthly_kit"
import Button from "ui/Button"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import View from "ui/View"
import { StorageKey, storageKeyWithId } from "utils/storage"
import { plural } from "utils/string"
import { useHasTeamFeature } from "utils/team"

const RaisedHandPicker = styled(function RaisedHandPicker({
  kitInstance,
  promptKey,
  exampleContent,
  actionText,
  buttonClassName,
  initialVolunteerText,
  nextVolunteerText,
  useNewDesign,
  className,
}) {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: raisedHandUsers } = useRaisedHands({
    kitInstance,
    key: promptKey,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)
  const [alreadySelected, setAlreadySelected] = useState([])
  const [prevSelectedIds, setPrevSelectedIds] = useState([])
  const availableVolunteers = raisedHandUsers ? differenceBy(raisedHandUsers, alreadySelected, "id") : []
  initialVolunteerText = initialVolunteerText || "Pick a volunteer"
  nextVolunteerText = nextVolunteerText || "Pick another"

  // Try to pull previously-selected user IDs from sessionStorage,
  // so those users can be place last in picking-order for this hand-picking round.
  // If no data in sessionstorage, availableVolunteers list will just be used directly.
  // Use sessionStorage instead of localStorage so that data is cleared when user closes tab.
  useEffectAfterFirstRender(() => {
    try {
      const key = storageKeyWithId(StorageKey.RaisedHandPickerUserIds, kitInstance.id)
      setPrevSelectedIds(JSON.parse(window.sessionStorage.getItem(key)))
    } catch {
      setPrevSelectedIds([])
    }
  })

  const pickVolunteer = () => {
    if (availableVolunteers.length <= 0) {
      return
    }
    const prevSelectedIdSet = new Set(prevSelectedIds)
    const notPrevSelectedVolunteers = availableVolunteers.filter(({ id }) => !prevSelectedIdSet.has(id))
    const selected = sample(notPrevSelectedVolunteers.length ? notPrevSelectedVolunteers : availableVolunteers)
    setSelectedVolunteer(selected)
    setAlreadySelected([...alreadySelected, selected])

    // Record selected user ID in sessionStorage, so they can be excluded
    // from future hand-picking rounds:
    prevSelectedIdSet.add(selected.id)
    setPrevSelectedIds(Array.from(prevSelectedIdSet))
    const key = storageKeyWithId(StorageKey.RaisedHandPickerUserIds, kitInstance.id)
    window.sessionStorage.setItem(key, JSON.stringify(Array.from(prevSelectedIdSet)))
  }

  return (
    <div className={className}>
      <h2 className={cn({ "mb-small": !alreadySelected.length && raisedHandUsers?.length && !actionText })}>
        {selectedVolunteer ? "Now sharing" : "Want to share?"}
      </h2>
      {!!actionText && <p className="mb-xs mt-xs text-gray-8">{actionText}</p>}
      {!!selectedVolunteer && <h2 className="mt-xs mb-small text-gray-8">{selectedVolunteer.short_name}</h2>}
      {raisedHandUsers?.length ? (
        <View
          $alignItems={useNewDesign ? "flex-start" : "center"}
          $flexDirection={useNewDesign ? "column" : "row"}
          $gap="8px"
        >
          {!!availableVolunteers.length && (
            <Button
              className={cn(!useNewDesign && buttonClassName, "mr-xs py-small text-small")}
              onClick={pickVolunteer}
            >
              {alreadySelected.length > 0 ? nextVolunteerText : initialVolunteerText}
              {!!useNewDesign && <ArrowRightIcon className="fa-xl" />}
            </Button>
          )}
          <p className="volunteers-text text-gray-8 text-nowrap">
            {!!availableVolunteers.length
              ? ((!!useNewDesign && !selectedVolunteer) || !useNewDesign) &&
                plural(availableVolunteers.length, "Volunteer")
              : "(Last volunteer)"}
          </p>
        </View>
      ) : (
        <>
          {!actionText && (
            <p className="mb-xs mt-xs text-gray-8">
              {!useNewDesign ? "Press this button on your screen." : "Waiting for volunteers."}
            </p>
          )}
          <Button className={!useNewDesign && buttonClassName} onClick={() => {}} disabled={!!useNewDesign}>
            {exampleContent}
          </Button>
        </>
      )}
    </div>
  )
})`
  .volunteers-text {
    font-size: 20px;
  }
`

export default RaisedHandPicker
