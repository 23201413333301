const DEISafeSpacesTraining3 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Nurturing an inclusive environment at work takes continuous effort and reflection. Maintaining a safe and brave
        space helps to make that inclusive environment possible. We want to help you do that with these tips to
        integrate the learnings from this session into your day to day practices.
      </p>
      <h2 className="text-rising-orange mb-medium">
        1. Calibrate the difference between being uncomfortable and unsafe.
      </h2>
      <p className="mb-medium">
        Being brave often requires that we are willing to be uncomfortable. As a leader, it can be important to get out
        of your comfort zone in ways that make a safer environment for members of your team.
      </p>
      <p className="mb-medium">
        Maybe{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://online.maryville.edu/blog/addressing-implicit-bias/">
          confronting your own biases
        </a>
        , talking about mistakes you’ve made, or{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://diversity.tufts.edu/resources/interrupting-bias-calling-out-vs-calling-in/"
        >
          interrupting prejudice
        </a>{" "}
        makes you uncomfortable. The good news is that the more time you spend in your learning edge on something, the
        more likely it is that it will eventually become part of your comfort zone. Far too often we shut down when we
        feel discomfort instead of staying present for the experience and seeking the learning opportunity. Being
        uncomfortable is not the same as being unsafe.
      </p>
      <p className="mb-medium">
        Should you feel like you are unsafe, we recommend you take care of yourself. Take a break. Seek support. Decide
        whether you need to be the one to respond. Your safety is important. However, learning how to tell the
        difference between being unsafe and uncomfortable will not only prevent you from getting into the danger zone
        but it can also support you in not staying in the comfort zone longer than necessary.
      </p>
      <p className="mb-medium">
        Remember, whether your team feels like a safe and brave space for your colleagues can depend a lot on whether
        they can trust you to speak up, to be present and to be accountable when it matters most. We encourage you to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.verywellmind.com/how-to-get-comfortable-being-uncomfortable-5204440"
        >
          embrace constructive discomfort
        </a>{" "}
        as a part of your learning journey and as a means to creating an inclusive culture on your team.
      </p>
      <h2 className="text-rising-orange mb-medium">
        2. Support a safe space for your colleagues by staying humble and curious.
      </h2>
      <p className="mb-medium">
        Humility requires an acknowledgement that you don’t know everything. While that may seem like a short jump for
        many of us, in practice it can be a bit more complicated. For example, if we are both in a car and I remark that
        I am feeling cold, you could turn to me and say that you are not cold, and therefore, it is not cold. Or, you
        can validate my experience and seek to find a temperature that is comfortable to both of us. What is real for
        you or how you think you would respond if in the other’s position may not resonate or be real for someone else.
      </p>
      <p className="mb-medium">
        Particularly when the distinction stems from a difference in social identity, it is important to stay humble.
        When confronted with a perspective that challenges your own, consider responding with curiosity rather than with
        defensiveness. Rather than diminishing someone’s experience by saying they are overreacting, consider that you
        can’t know what it is like to be in another’s shoes. This attitude will ultimately create a safer space for the
        people around you to share more of their authentic selves. As you remain humble, you may find yourself led more
        into the learning edge, an ideal place for a leader to be.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Be proactive in communicating inclusively.</h2>
      <p className="mb-medium">
        Practicing inclusive communication means continually educating ourselves about the power of our words and the
        unintended impacts our words and actions may have on people from marginalized social identities. For example, in{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://textio.com/blog/job-performance-feedback-is-heavily-biased-new-textio-report/75983880330"
        >
          performance reviews
        </a>{" "}
        some are called assertive while others are called aggressive. Words can signal unconscious stereotypes that can
        be perceived as negative.
      </p>
      <p className="mb-medium">
        We covered a few principles in the session but, as you might imagine, communication is an area of endless
        learning. It is not possible to know everything that may offend anyone. No two people from any group can be
        relied on to have the exact same reaction to a word or phrase.
      </p>
      <p className="mb-medium">
        In addition, the context in which we use words often impacts the way they are received. Stay humble and curious
        about how you can communicate more effectively with this person the next time.
      </p>
      <p className="mb-medium">
        Trust plays a huge role in how people respond when we get it wrong. You can build trust by proactively
        demonstrating that inclusive communication is important to you.
      </p>
      <p className="mb-medium">
        Let’s get specific. Here are some (but not all) of the ways that you can be thinking about communicating
        inclusively right now. Take a moment to notice how many of these you use already and how many you can adopt in
        the near future.
      </p>
      <ul className="ml-xl mb-medium">
        <li>
          <span className="text-bold">Share your gender pronouns</span> Sharing your gender pronouns, even though you
          may think they are obvious, can invite others, for whom it may not be so obvious, to{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://pronouns.org/">
            share their pronouns
          </a>{" "}
          without feeling singled out. We don’t recommend insisting on this practice, but rather inviting it as an act
          of allyship with the non-binary and transgender communities.
        </li>
        <li>
          <span className="text-bold">
            Determine whether to use{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://odr.dc.gov/page/people-first-language">
              people first language
            </a>{" "}
            or{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://accessate.net/features/2519/person-first-vs-identity-first-language"
            >
              identity first language
            </a>{" "}
          </span>
          Rather than labeling someone by their disability first, some in the disability community prefer language that
          focuses on the person first. For example, calling someone a person with epilepsy instead of an epileptic
          person focuses on the person instead of on their disability. Others feel their disability is a fundamental
          part of their identity and prefer that the identity comes first. Not everyone in the disability community
          wants the same thing. Not sure what to do? Ask. No one community is a monolith.
        </li>
        <li>
          <span className="text-bold">Use the word "partner" or "significant other"</span> when you don't know a
          person's relationship situation. It can be awkward or even offensive to assign the wrong gender or status
          (spouse, girlfriend, boyfriend, etc.) to the person that plays such an important role in the life of your
          colleague. Using more neutral language avoids assumptions about a person's sexual orientation or family
          structure.
        </li>
        <li>
          <span className="text-bold">Avoid language that assumes physical ability</span> For example, saying “If you
          are comfortable standing...” may work better than “If you are able, please stand.” This kind of language
          avoids assumptions we can make about people’s physical ability status.
        </li>
        <li>
          <span className="text-bold">Acknowledge holidays and cultural practices</span> This kind of proactive
          communication helps people from within marginalized communities feel seen as well as educates others about the
          diversity around us.
        </li>
        <li>
          <span className="text-bold">Learn the proper pronunciation of names</span> People’s names matter. Taking the
          time to learn how they are pronounced is an important sign of respect.
        </li>
        <li>
          <span className="text-bold">Use a microphone</span> People who struggle with hearing may not feel comfortable
          asking you to speak up just for them. And even if they do, you may not consistently remember to do it. Using a
          microphone helps make it comfortable for everyone.
        </li>
        <li>
          <span className="text-bold">Leverage closed-captioning</span> Platforms like Microsoft Powerpoint, Zoom, and
          Google Meet can provide automatic, live closed-captioning, a service that supports the hearing impaired as
          well as non-native speakers of the language used. Informing people of their options is a fantastic way to be
          proactively inclusive without waiting for people to ask you for accommodation.{" "}
        </li>
        <li>
          <span className="text-bold">Be cognizant of font size</span> Using a reasonable font size can support people
          with visual impairment. And it usually makes whatever is being read more visually appealing to everyone else
          too!
        </li>
        <li>
          <span className="text-bold">Send agendas ahead of time</span> This can be useful to introverts, people with
          neurodivergence, and others who benefit from the ability to process ideas prior to being called on to share
          them. This ultimately creates greater equity and helps teams be more innovative and productive by garnering
          the power of our ideas.
        </li>
      </ul>
      <p className="mb-medium">
        Keep your learning going. Follow thought leaders on social media, do your own research and bring in professional
        educators. It’s up to you to continue your education on inclusive communication on an ongoing basis.
      </p>
      <h2 className="text-rising-orange mb-medium">4. Make your commitment to DEI visible.</h2>
      <p className="mb-medium">
        Just like creativity or excellence, being inclusive is not an end goal - it takes time to get there and work to
        stay there. During the safe and brave spaces activity, you may have noticed that whether something was a comfort
        zone, learning edge or danger zone experience for you depended on the level of trust you have with the people in
        the scenario. As a leader, you want to support the creation of a culture that makes your team a safe place to
        be, regardless of their social identity. You also want to be the kind of leader that allows for the people on
        your team to feel like they can be brave in addressing DEI related issues. A big part of creating that culture
        is making sure that each person on your team knows that you are committed. Consider the following ideas for how
        you can make that commitment visible:
      </p>
      <ul className="ml-xl mb-medium">
        <li>Ask interview candidates about their interest in DEI as a team priority.</li>
        <li>
          If your organization has affinity or employee resource groups based on social identities, be vocal about your
          support for participation in them and consider participating in at least one yourself. Even if you don’t share
          the identity of the group, they often welcome people who want to practice allyship.
        </li>
        <li>
          Make sure your team knows that you are supportive of the accommodations some may need due to disability or
          neurodivergence.
        </li>
        <li>
          Use 1:1s and team meetings to ask about each person’s sense of inclusion and belonging as a part of the team.
        </li>
      </ul>

      <p className="mb-medium">
        Know that your commitment to DEI can show up in a myriad of authentic ways. Your DEI journey is a marathon and
        not a sprint. Keep learning and growing. While being open about your journey may feel vulnerable, remember that
        it can be a helpful reminder to your team that you are someone they can trust.
      </p>
      <h5 className="mb-medium">Additional resources:</h5>
      <ul className="mb-medium ml-medium">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://online.maryville.edu/blog/addressing-implicit-bias/"
          >
            How to Identify and Overcome Your Implicit Bias
          </a>{" "}
          (Maryville University)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://builtin.com/diversity-inclusion/unconscious-bias-examples"
          >
            16 Unconscious Bias Examples and How to Avoid Them in the Workplace
          </a>{" "}
          (Built In)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://diversity.tufts.edu/resources/interrupting-bias-calling-out-vs-calling-in/"
          >
            Interrupting Bias: Calling Out vs. Calling In
          </a>{" "}
          (Tufts)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://textio.com/blog/job-performance-feedback-is-heavily-biased-new-textio-report/75983880330"
          >
            Job performance feedback is heavily biased
          </a>{" "}
          (Textio)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.gsb.stanford.edu/insights/language-gender-bias-performance-reviews"
          >
            The Language of Gender Bias in Performance Reviews
          </a>{" "}
          (Stanford Graduate School of Business)
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://pronouns.org/">
            Pronouns.org
          </a>{" "}
          (Pronouns.org)
        </li>
      </ul>
    </div>
  </div>
)

export default DEISafeSpacesTraining3
