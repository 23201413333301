import DefaultTimer from "ui/DefaultTimer"

const StepTimer = ({ sessionStep, showTimerControls = true, className }) => {
  const { auto_start_timer, minutes } = sessionStep

  return (
    <DefaultTimer
      minutes={minutes}
      autoStart={auto_start_timer}
      showTimerControls={showTimerControls}
      className={className}
    />
  )
}

export default StepTimer
