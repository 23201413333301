import { Link } from "react-router-dom"

import SetupMenu from "domains/Setup/SetupMenu"
import SetupNavigation from "domains/Setup/SetupNavigation"
import SetupStep from "domains/Setup/SetupStep"
import CloseIcon from "icons/CloseIcon"
import useWindowSize from "ui/hooks/useWindowSize"
import Loading from "ui/Loading"
import View from "ui/View"

const SetupHome = ({ className, user, steps, step, subStepIndex = null }) => {
  const { isMobileLandscapeOrSmaller } = useWindowSize()

  if (!user?.setup_steps) {
    return <Loading />
  }

  const userName = user.first_name?.trim()
  const userDesc = userName?.length ? ` ${userName}` : ""

  return (
    <View className={className}>
      <div className="main-container full-width">
        <Link style={{ float: "right" }} to="/kit">
          <CloseIcon className="cursor-pointer" color="var(--gray-7)" />
        </Link>
        <h1 className="text-gray-9 mb-large">Let’s get you set up</h1>
        <p className="mb-xxl">Hi{userDesc}! Complete these steps to get your team set up with Rising Team.</p>
        <View>
          <SetupMenu steps={steps} step={step} className="mr-large-mobile-never" />
          {!isMobileLandscapeOrSmaller && (
            <div className="full-width">
              <SetupStep steps={steps} step={step} subStepIndex={subStepIndex} />
              <SetupNavigation steps={steps} />
            </div>
          )}
        </View>
      </div>
    </View>
  )
}

export default SetupHome
