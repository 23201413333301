import { Link } from "react-router-dom"
import { styled } from "styled-components"

import View from "ui/View"

const UnavailableKitExpandedContent = styled(function UnavailableKitExpandedContent({ kitInfo, user, className }) {
  const isAdmin = user.can_manage_billing_for_an_account

  return (
    <div className={className}>
      <div className="text-gray-9">{kitInfo.description}</div>
      <View
        $alignItems="center"
        $alignItemsMobile="flex-start"
        $justifyContent="flex-end"
        $flexDirectionMobile="column-reverse"
        className="mt-medium"
      >
        {!!isAdmin && (
          <Link to="/subscribe" className="text-semi-bold">
            See pricing
          </Link>
        )}
      </View>
    </div>
  )
})`
  .upgrade-confirmation-box {
    background: rgba(255 210 87 / 10%);
  }
`

export default UnavailableKitExpandedContent
