import { uniqBy } from "lodash-es"
import type { ReactNode } from "react"
import { isValidElement, cloneElement } from "react"

function asArray<T>(value: T[]): T[]
function asArray<T>(value: T): T[] {
  return Array.isArray(value) ? value : [value]
}

function uniqueById<T extends { id: string | number }>(items: T[]): T[] {
  return uniqBy(items, (item: T) => item.id)
}

function intersperse(arr: ReactNode[], delimiter: ReactNode): ReactNode[] {
  const isReactElement = isValidElement(delimiter)
  return arr
    .flatMap((item, idx) => [item, isReactElement ? cloneElement(delimiter, { key: `delimeter-${idx}` }) : delimiter])
    .slice(0, -1)
}

export { asArray, uniqueById, intersperse }
