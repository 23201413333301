import { getExerciseAnswer } from "../results_utils"

const answerExists = (answer) => answer != null && answer !== ""

const IfAnswersExist = ({ identifiers, exerciseInstance, all = true, children }) => {
  const answerExistences = identifiers.map((identifier) =>
    answerExists(getExerciseAnswer(exerciseInstance, identifier))
  )
  const showChildren = all ? answerExistences.every((x) => x) : answerExistences.some((x) => x)
  return <>{!!showChildren && children}</>
}

const IfAnswersDoNotExist = ({ identifiers, exerciseInstance, all = true, children }) => {
  const answerExistences = identifiers.map((identifier) =>
    answerExists(getExerciseAnswer(exerciseInstance, identifier))
  )
  const showChildren = all ? answerExistences.every((x) => !x) : answerExistences.some((x) => !x)
  return <>{!!showChildren && children}</>
}

export { IfAnswersExist, IfAnswersDoNotExist }
