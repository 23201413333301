const ExercisePercentageGroupFieldCenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  const value = dataWithArc.reduce((sum, item) => sum + item.value, 0)
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "1.5rem",
        fontWeight: 600,
        fill: "var(--subtitle)",
        textTransform: "uppercase",
      }}
    >
      {value}%
    </text>
  )
}

export default ExercisePercentageGroupFieldCenteredMetric
