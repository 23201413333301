/* stylelint-disable selector-class-pattern */
// this file uses react-select CSS classes with underscores which trip up styleint

import cn from "classnames"
import { useRef } from "react"
import {
  type Props as ReactSelectProps,
  type ClearIndicatorProps,
  type SelectInstance,
  type ActionMeta,
} from "react-select"
import { styled } from "styled-components"

import AdvancedSelectField, { type SelectOption, type AdvancedSelectFieldProps } from "forms/fields/AdvancedSelectField"
import { XmarkIcon, TrashIcon } from "icons/FontAwesomeIcons"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import Tooltip from "ui/Tooltip"

const SELECT_ALL = "SELECT_ALL"

const ClearIndicator = (props: ClearIndicatorProps) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div
      className="advanced-select__indicator mr-xxs mt-small"
      style={getStyles("clearIndicator", props) as object}
      {...restInnerProps}
      ref={ref}
    >
      <Tooltip top wrapInView={false} title="Deselect all">
        <TrashIcon className="deselect-all-icon" />
      </Tooltip>
    </div>
  )
}

interface MultiSelectFieldProps extends AdvancedSelectFieldProps<true> {
  value: SelectOption[]
  name: string
  placeholder: string
  options: SelectOption[]
  className?: null | string
  selectedDescription?: null | ((selectedValues: string[]) => string)
  disabledAltPlaceholderText?: null | string
  disabledHideOptionText?: boolean
  disabled?: boolean
  showSelectAll?: boolean
  optionOutlined?: boolean
  optionFilled?: boolean
  selectedOutlined?: boolean
  selectedFilled?: boolean
}

const MultiSelectField = styled(function MultiSelectField({
  value,
  name,
  placeholder,
  options,
  className = null,
  formatOptionLabel = null,
  onChange = null,
  selectedDescription = null,
  disabledAltPlaceholderText = null,
  disabledHideOptionText: _disabledHideOptionText = false,
  disabled = false,
  showSelectAll = false,
  optionOutlined: _optionOutlined = false,
  optionFilled: _optionFilled = false,
  selectedOutlined: _selectedOutlined = false,
  selectedFilled: _selectedFilled = false,
  ...props
}: MultiSelectFieldProps) {
  const select = useRef<SelectInstance & { inputRef: HTMLInputElement }>(null)

  useEffectAfterChange(() => {
    if (typeof selectedDescription === "function") {
      const selectedValues = value?.map(({ value }) => value) ?? []
      if (select.current) {
        const inputParent: null | HTMLElement = select.current.inputRef.parentNode as HTMLElement
        if (inputParent) {
          inputParent.dataset.value = selectedDescription(selectedValues)
        }
      }
    }
  }, [value, selectedDescription])

  function handleChange(selectedOptions: SelectOption[], actionMeta: ActionMeta<SelectOption>) {
    const selectedValues = selectedOptions.map((option) => option.value)
    if (selectedValues.includes(SELECT_ALL)) {
      onChange?.({ value: options.map(({ value }) => value) }, actionMeta)
    } else {
      onChange?.({ value: selectedValues.filter((value) => value !== SELECT_ALL) }, actionMeta)
    }
  }

  function removeItem(option: SelectOption) {
    if (option.value !== SELECT_ALL) {
      const actionMeta: ActionMeta<SelectOption> = { option, action: "deselect-option" }
      onChange?.(
        {
          value: value.map(({ value }) => value).filter((value) => value !== option.value),
        },
        actionMeta
      )
    }
  }

  return (
    <AdvancedSelectField
      className={className}
      isMulti
      name={name}
      value={value}
      forwardRef={select}
      options={[...(showSelectAll ? [{ label: "Select all", value: SELECT_ALL }] : []), ...options]}
      onChange={handleChange as ReactSelectProps["onChange"]}
      formatOptionLabel={(option: SelectOption) => (
        <span className={cn("item", { "select-all": option.value === SELECT_ALL })} onClick={() => removeItem(option)}>
          {option.value === SELECT_ALL || !formatOptionLabel ? option.label : formatOptionLabel(option)}
          <XmarkIcon />
        </span>
      )}
      components={{ ClearIndicator }}
      disabled={disabled}
      placeholder={(disabled && disabledAltPlaceholderText) || placeholder}
      {...props}
    />
  )
})`
  .item {
    display: block;
    width: fit-content;
    padding: 0 12px;
    color: var(--gray-9);
    transition: color 0.2s ease;
    height: 28px;
    border-radius: 14px;
    font-weight: 500;
    font-size: 15px;

    // Set max-width for items that doesn't overlap X icon:
    max-width: calc(100% - 7px);
    overflow: hidden;
    text-overflow: ellipsis;

    .fa-xmark {
      display: none;
    }
  }

  .advanced-select__multi-value,
  .advanced-select__multi-value__label {
    max-width: 100%;
    background: none;
    margin: 0;
    padding: 0;
  }

  .advanced-select__control {
    height: auto;

    .item {
      margin-left: 8px;
      margin-bottom: 8px;
      padding-right: 24px;
      position: relative;

      .fa-xmark {
        display: block;
        position: absolute;
        right: 10px;
        top: 6px;
        color: var(--gray-6);
        transition: color 0.1s ease-in-out;
      }

      /* stylelint-disable-next-line csstools/use-nesting */
      &:hover .fa-xmark {
        color: var(--gray-9);
      }
    }
  }

  .advanced-select__value-container {
    padding: var(--spacing-1) 0 0 0;
  }

  .advanced-select__multi-value__label {
    overflow: visible;
    white-space: nowrap;
  }

  .advanced-select__placeholder {
    padding: 4px 0 var(--spacing-2) var(--spacing-2);
  }

  .advanced-select__input {
    cursor: pointer;
  }

  .advanced-select__input-container {
    padding: 4px 0 var(--spacing-2) var(--spacing-2);
    color: var(--gray-6);
  }

  .advanced-select__multi-value {
    + .advanced-select__input-container::after {
      visibility: visible;
    }
  }

  .advanced-select__indicators {
    height: 44px;
  }

  .advanced-select__indicator-separator {
    display: none;
  }

  .advanced-select__indicator {
    padding-left: 0;
    margin-top: -3px;

    ~ .advanced-select__indicator {
      display: none;
    }
  }

  .advanced-select__multi-value__remove {
    display: none;
  }

  .deselect-all-icon {
    transition: color 0.1s ease-in-out;

    &:hover {
      color: var(--danger-hover);
    }
  }

  /* Item Style Variants */

  ${({ optionOutlined }) =>
    !optionOutlined
      ? ""
      : `
    .advanced-select__menu .item:not(.select-all) {
      border: 3px dashed var(--gray-5);
      height: 30px;
      margin-bottom: 4px;
      transform: translateY(-2px);
    }
  `}

  ${({ optionFilled }) =>
    !optionFilled
      ? ""
      : `
    .advanced-select__menu .item:not(.select-all) {
      background: var(--gray-4);
      transition: background 0.1s ease-in-out;
      &:hover {
        background: var(--gray-5);
      }
    }
  `}

  ${({ selectedOutlined }) =>
    !selectedOutlined
      ? ""
      : `
    .advanced-select__control .item {
      border: 2px dashed var(--gray-5);
      height: 30px;
      margin-bottom: 4px;
      transform: translateY(-2px);
    }
  `}

  ${({ selectedFilled }) =>
    !selectedFilled
      ? ""
      : `
    .advanced-select__control .item {
      background: var(--gray-4);
      transition: background 0.1s ease-in-out;
      &:hover {
        background: var(--gray-5);
      }
    }
  `}

  ${({ disabledHideOptionText, disabledAltPlaceholderText }) =>
    !disabledHideOptionText
      ? ""
      : `
    &.advanced-select--is-disabled {
      .item {
        color: transparent; // hide option text while select is disabled for clarity
      }
      ${
        // show alt placeholder text at bottom of options list while disabled:
        !disabledAltPlaceholderText
          ? ""
          : `
          &::after {
            content: "${disabledAltPlaceholderText ?? ""}";
            pointer-events: none;
            position: absolute;
            bottom: 0.5rem;
            left: 14px;
            z-index: var(--z-menu);
          }
      `
      }
    }
  `}
`

export default MultiSelectField
