import { orderBy, omitBy } from "lodash-es"

import { ArtiSender, LLMType, NO_ONE_MEMBER, VANILLA_LLM_TYPES, CONVERSATION_TYPE } from "./constants"

import { sortUsersByShortName } from "resources/users"
import { uniqueById } from "utils/array"

const getPopularTopics = ({
  team,
  user,
  member,
  conversationTypeSelected,
  sampleOnly = false,
}: {
  team: TeamData
  user: UserData
  member: UserData | null
  conversationTypeSelected: string
  sampleOnly: boolean
}) => {
  if (conversationTypeSelected === CONVERSATION_TYPE.TEAM) {
    return sampleOnly ? sampleTopicsTeam() : getPopularTopicsTeam()
  } else {
    if (!member || member.id === NO_ONE_MEMBER.id) {
      return sampleOnly ? getSampleTopicsNoOne() : getPopularTopicsNoOne()
    } else if (user.id === member.id) {
      return sampleOnly ? getSampleTopicsMyself() : getPopularTopicsMyself()
    } else if (team.team_lead_id === user.id || team.demo) {
      // in demo teams, pretend the user is the team lead
      return sampleOnly ? getSampleTopicsToDirectReport() : getPopularTopicsToDirectReport(member)
    } else if (team.team_lead_id === member.id) {
      return sampleOnly ? getSampleTopicsToManager() : getPopularTopicsToManager(member)
    } else {
      return sampleOnly ? getSampleTopicsToPeer() : getPopularTopicsToPeer(member)
    }
  }
}

const getSampleTopicsNoOne = () => ["Setting goals", "Improving work/life balance", "General problem solving"]

const getPopularTopicsNoOne = () => [
  "How do I prioritize my workload?",
  "How can I increase my influence and impact at work?",
  "Help me stop procrastinating.",
  "How can I improve my work/life balance?",
  "How can I set better boundaries?",
  "How can I stop working on too many things at once?",
  "Help me create a career development plan.",
  "Help me write and set goals.",
  "Help me write my self-review.",
  "I just want to vent.",
  "I'm feeling stressed about…",
  "I'm struggling with…",
  "Help me resolve a conflict.",
  "Help me prepare for a difficult conversation.",
  "What do I do if I feel underappreciated?",
]

const getSampleTopicsMyself = () => ["Setting goals", "Improving work/life balance", "General problem solving"]

const getPopularTopicsMyself = () => [
  "How do I prioritize my workload?",
  "How can I increase my influence and impact at work?",
  "Help me stop procrastinating.",
  "How can I improve my work/life balance?",
  "How can I set better boundaries?",
  "How can I stop working on too many things at once?",
  "Help me create a career development plan.",
  "Help me write and set goals.",
  "Help me write my self-review.",
  "I just want to vent.",
  "I'm feeling stressed about…",
  "I'm struggling with…",
  "Help me resolve a conflict.",
  "Help me prepare for a difficult conversation.",
  "What do I do if I feel under appreciated?",
]

const getSampleTopicsToDirectReport = () => [
  "Giving feedback",
  "Showing appreciation",
  "Preparing for a difficult conversation",
]

const getPopularTopicsToDirectReport = (member: UserData) => {
  const first = member.first_name
  const possessiveAdjectivePronoun = member.pronouns[2]

  return [
    `I'd like to appreciate ${first} for ${possessiveAdjectivePronoun} work on…`,
    `I'd like to give constructive feedback to ${first} on…`,
    `How can I get more visibility into what ${first} is working on?`,
    `I'd like to support ${first} who is struggling with…`,
    "I need help with a difficult conversation about…",
    `What do I do if ${first} isn’t performing well?`,
    `How can I help ${first} grow ${possessiveAdjectivePronoun} career?`,
    `How can I encourage ${first} to give me feedback?`,
    `How can I encourage ${first} to be more flexible?`,
    `How can I help ${first} to be a better collaborator?`,
    `How can I encourage ${first} to accept help from others?`,
    `How can I encourage ${first} to ask for help when needed?`,
    `What can I do to manage ${first} who is not open to feedback?`,
  ]
}

const getSampleTopicsToManager = () => [
  "Discussing your career",
  "Giving feedback",
  "Preparing for a difficult conversation",
]

const getPopularTopicsToManager = (member: UserData) => {
  const first = member.first_name
  const possessiveAdjectivePronoun = member.pronouns[2]

  return [
    `I'd like to appreciate ${first} for ${possessiveAdjectivePronoun} help on…`,
    `I'd like help preparing for a conversation with ${first} about…`,
    `I'd like to discuss my career with ${first}.`,
    `I'd like to ask for feedback from ${first} on…`,
    `I'd like to ask ${first} for help with…`,
  ]
}

const getSampleTopicsToPeer = () => [
  "Giving feedback",
  "Showing appreciation",
  "Preparing for a difficult conversation",
]

const getPopularTopicsToPeer = (member: UserData) => {
  const first = member.first_name
  const subjectPronoun = member.pronouns[0]
  const objectPronoun = member.pronouns[1]
  const possessiveAdjectivePronoun = member.pronouns[2]

  return [
    `I'd like to appreciate ${first} for ${possessiveAdjectivePronoun} work on…`,
    `I'd like to give constructive feedback to ${first} on…`,
    "I need help with a difficult conversation about…",
    `I need help from ${first} with something. What’s the best way to ask ${objectPronoun}?`,
    `I am upset about something ${first} said. What should I do?`,
    `I needed ${first} to complete something by a certain date and ${subjectPronoun} didn't do it. What should I do?`,
    `I just want to vent about ${first}.`,
    `${first} is dealing with a personal issue, is there anything I can do or say?`,
    `${first} is dealing with a work challenge, how can I be helpful?`,
  ]
}

const sampleTopicsTeam = () => ["Completed team kits", "Completed kits desciptions", "Completed kits titles"]

const getPopularTopicsTeam = () => [
  "What kits have I completed on Rising Team",
  "What kits have I completed on Rising Team in the last quarter",
  "What are the details of the kits I've completed",
  "What are the titles of the kits I've completed",
]

function getConversationTypeSelectedMessage({
  llm,
  conversationTypeSelected,
}: {
  llm: LLMType
  conversationTypeSelected: string
}) {
  const text = VANILLA_LLM_TYPES.includes(llm)
    ? "You're using the vanilla version of this LLM, which does not include information about aRTi or about team members."
    : conversationTypeSelected === CONVERSATION_TYPE.TEAM_MEMBER
    ? "I see you want to talk about someone on your team. Who would you like to talk about?"
    : conversationTypeSelected === CONVERSATION_TYPE.TEAM
    ? "I see you want to talk about your team. Which team would you like to discuss?"
    : conversationTypeSelected === CONVERSATION_TYPE.PERF_HELPER_CONVO &&
      "I see you want help writing a performance review for someone on your team. Who is this performance review for?"

  return text
}

function getMemberSelectedMessage({
  user,
  teamMember,
  llm,
  team,
  conversationTypeSelected,
}: {
  user: UserData
  teamMember: UserData
  llm: LLMType
  team: TeamData
  conversationTypeSelected: string
}) {
  const sampleTopics = getPopularTopics({ team, user, member: teamMember, conversationTypeSelected, sampleOnly: true })
  const examplesText = `Sample topics I can help you with include:
${sampleTopics.map((topic) => `- ${topic}`).join("\n")}

Click "Popular topics" below for more examples.
`

  const memberLabel =
    teamMember?.id === user.id
      ? "yourself"
      : teamMember?.id === NO_ONE_MEMBER.id
      ? NO_ONE_MEMBER.full_name.toLowerCase()
      : teamMember?.first_name?.trim() || "your team member"
  const text = VANILLA_LLM_TYPES.includes(llm)
    ? "You're using the vanilla version of this LLM, which does not include information about aRTi or about team members."
    : teamMember
    ? `I see you're interested in chatting about ${memberLabel}.\n\n${examplesText}`
    : "Please select a team member to chat about."

  return { sender: ArtiSender.BOT, text }
}

function getTeamSelectedMessage({
  user,
  llm,
  team,
  conversationTypeSelected,
}: {
  user: UserData
  llm: LLMType
  team: TeamData
  conversationTypeSelected: string
}) {
  const sampleTopics = getPopularTopics({ team, user, member: null, conversationTypeSelected, sampleOnly: true })
  const examplesText = `Sample topics I can help you with include:
${sampleTopics.map((topic) => `- ${topic}`).join("\n")}

Click "Popular topics" below for more examples.
`

  const teamLabel = "your team"
  const text = VANILLA_LLM_TYPES.includes(llm)
    ? "You're using the vanilla version of this LLM, which does not include information about aRTi or about team members."
    : team
    ? `I see you're interested in chatting about ${teamLabel}.\n\n${examplesText}`
    : "Please select a team to chat about."

  return { sender: ArtiSender.BOT, text }
}

function formatPopularTopicAsSelectFieldOption(topic: string) {
  return { value: topic.replace("…", " "), label: topic }
}

const availableLLMsForUser = (user: UserData) =>
  user.is_staff ? LLMType : omitBy(LLMType, (llmType) => VANILLA_LLM_TYPES.includes(llmType))

function createMembersAndTeamMap({
  allNonJumboTeams,
  team,
  user,
}: {
  allNonJumboTeams: TeamData[]
  team: TeamData
  user: UserData
}): { memberIdTeamNameMap: Map<UserID, string>; teamMembers: UserData[] } {
  if (!user || !team || !allNonJumboTeams) {
    return { memberIdTeamNameMap: new Map(), teamMembers: [] }
  }
  const memberIdTeamNameMap = new Map()
  const currentTeamLast = orderBy(allNonJumboTeams, ({ id }) => id === team.id)
  const teamMembers = uniqueById([
    user, // Place current user entry first in list of team members.
    NO_ONE_MEMBER, // Place "No one" option second in list of team members.
    ...currentTeamLast.flatMap(({ members, name: teamName }) =>
      sortUsersByShortName({ users: members }).map((member) => {
        // Associate team names with members for display in member select menu:
        if (!memberIdTeamNameMap.has(member.id)) {
          memberIdTeamNameMap.set(member.id, teamName)
        }
        return member
      })
    ),
  ])
  return { memberIdTeamNameMap, teamMembers }
}

function isValidMessageText(message: string) {
  return !!message && !!message.trim().length
}

export {
  getPopularTopics,
  formatPopularTopicAsSelectFieldOption,
  getMemberSelectedMessage,
  getTeamSelectedMessage,
  availableLLMsForUser,
  createMembersAndTeamMap,
  getConversationTypeSelectedMessage,
  isValidMessageText,
}
