import { Link } from "react-router-dom"

import { useTeams } from "resources/teams"
import { useUser } from "resources/users"
import { buildUrl } from "utils/string"

const PromoFreeKitLink = ({ children, mini, className }) => {
  const { data: user } = useUser({ userId: "me" })
  const { data: teams } = useTeams()

  const tlTeam = (teams ?? []).filter(({ team_lead_id }) => team_lead_id === user?.id)[0]
  const page = mini ? "minis" : "kit"
  const url = buildUrl([page], { urlQueryParams: { team_id: tlTeam?.id } })

  return (
    <Link to={url} className={className}>
      {children}
    </Link>
  )
}

export default PromoFreeKitLink
