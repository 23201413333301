import { Navigate, Route } from "react-router-dom"

import Onboarding from "./Onboarding"
import OnboardingFromDemo from "./OnboardingFromDemo"
import Welcome from "./Welcome"

import { useSetAccountTypeByTeam } from "components/AccountTypeContext"
import { RouteSteps } from "components/Steps/Steps"
import { useTeams } from "resources/teams"
import { useUser } from "resources/users"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"
import View from "ui/View"

const CreateAccount = () => {
  const { data: user } = useUser({ userId: "me" })
  const { data: teams } = useTeams()
  const firstTeam = teams ? teams[0] : null
  useSetAccountTypeByTeam(firstTeam)

  if (!user) {
    return <Loading />
  }

  return (
    <>
      <PageTitle>Create your account</PageTitle>
      <View $flex={1} $flexDirection="column">
        <RouteSteps>
          <Route path="onboarding" element={<Onboarding user={user} />} />
          <Route path="onboarding-from-demo" element={<OnboardingFromDemo user={user} />} />
          <Route path="welcome" element={<Welcome user={user} />} />
          <Route path="*" ignore element={<Navigate to="onboarding" replace />} />
        </RouteSteps>
      </View>
    </>
  )
}

export default CreateAccount
