function adjustRadialChartLabelsToAvoidOverlap(
  chartContainerElement: HTMLElement,
  {
    moveTopLabelsUpPx = 4,
    moveBottomLabelsDownPx = 4,
    numTopLabelsToAdjustUp = 2,
    numBottomLabelsToAdjustDown = 2,
  } = {}
): void {
  const labels = Array.from(chartContainerElement.querySelectorAll("text"))

  // Remove extra adjustment translations from all label elements:
  labels.forEach((label) => {
    const transforms = label.getAttribute("transform")?.split("translate(")
    if (transforms?.length && transforms.length > 2) {
      label.setAttribute("transform", transforms.slice(0, 2).join("translate(").trim())
    }
  })

  const labelsWithYPositions = labels
    .map((label) => {
      const yPosition = parseFloat(label.getAttribute("transform")?.split(",").at(-1) ?? "")
      return [yPosition, label]
    })
    .filter(([yPosition]) => !Number.isNaN(yPosition)) as Array<[number, SVGTextElement]>

  labelsWithYPositions.sort(([aYPosition], [bYPosition]) => aYPosition - bYPosition)

  const topTwoLabelPairs = labelsWithYPositions.slice(0, numTopLabelsToAdjustUp)
  const bottomTwoLabelPairs = labelsWithYPositions.slice(-numBottomLabelsToAdjustDown)

  // Add extra adjustment translations to topmost two label elements:
  topTwoLabelPairs.forEach(([_yPosition, label]) => {
    label.setAttribute("transform", label.getAttribute("transform") + ` translate(0,-${moveTopLabelsUpPx})`)
  })

  // Add extra adjustment translations to bottommost two label elements:
  bottomTwoLabelPairs.forEach(([_yPosition, label]) => {
    label.setAttribute("transform", label.getAttribute("transform") + ` translate(0,${moveBottomLabelsDownPx})`)
  })
}

// eslint-disable-next-line import/prefer-default-export
export { adjustRadialChartLabelsToAvoidOverlap }
