import { styled } from "styled-components"

import View from "ui/View"

const FeedbackPeerFramework = styled(function FeedbackPeerFramework({ className }) {
  return (
    <div className={className}>
      <div className="letter-box-container">
        <View
          $width="100px"
          $height="100px"
          $alignItems="center"
          $justifyContent="center"
          $radius="8px"
          className="letter-box text-orange-2 border-radius-large"
        >
          P
        </View>
        <div>
          <h3 className="text-orange-2">Purpose</h3>
          <h4 className="text-gray-9 my-xs">Why do you want to give this feedback?</h4>
          <p className="text-gray-8">
            What is your motive for giving this feedback? For example, you care about their growth or you’re concerned
            it will derail the team’s focus.
          </p>
        </div>
      </div>
      <div className="letter-box-container">
        <View
          $width="100px"
          $height="100px"
          $alignItems="center"
          $justifyContent="center"
          $radius="8px"
          className="letter-box text-orange-4 border-radius-large"
        >
          E
        </View>
        <div>
          <h3 className="text-orange-4">Experience</h3>
          <h4 className="text-gray-9 my-xs">The observable facts</h4>
          <p className="text-gray-8">
            Describe the facts as you experienced them, without interpretation or emotion. For example; you arrived 15
            minutes late to the meeting.
          </p>
        </div>
      </div>
      <div className="letter-box-container">
        <View
          $width="100px"
          $height="100px"
          $alignItems="center"
          $justifyContent="center"
          $radius="8px"
          className="letter-box text-yellow-2 border-radius-large"
        >
          E
        </View>
        <div>
          <h3 className="text-yellow-2">Effect</h3>
          <h4 className="text-gray-9 my-xs">The impact of these facts</h4>
          <p className="text-gray-8">
            How did this impact you or the team? Here you can include interpretation or emotion. For example; because
            the meeting started late, I felt stressed about finishing the presentation on time.
          </p>
        </div>
      </div>
      <div className="letter-box-container">
        <View
          $width="100px"
          $height="100px"
          $alignItems="center"
          $justifyContent="center"
          $radius="8px"
          className="letter-box text-rising-green border-radius-large"
        >
          R
        </View>
        <div>
          <h3 className="text-rising-green">Request</h3>
          <h4 className="text-gray-9 my-xs">The outcome you are hoping for</h4>
          <p className="text-gray-8">The result or desired outcome you would like to see happen in the future.</p>
        </div>
      </div>
    </div>
  )
})`
  .letter-box-container {
    display: grid;
    width: 100%;
    grid-template-columns: 48px auto;
    grid-gap: 70px;
    margin-bottom: 30px;
  }

  .letter-box {
    font-size: 4.5rem;
    font-weight: 700;
    border: 8px solid var(--gray-4);
    border-radius: 16px;
  }
`

export default FeedbackPeerFramework
