import CopyButton from "ui/CopyButton"

const ArtiCopyButton = ({ className, clipboardText }) => (
  <CopyButton
    icon
    iconCopied
    tooltip="Copy message text"
    tooltipCopied="Copied!"
    className={className}
    clipboardText={clipboardText}
    title=""
    aria-label="Copy message text"
    hoverColor="gray-8"
    onClickColor="gray-8"
  />
)

export default ArtiCopyButton
