import { styled } from "styled-components"

const ResilienceTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div className="pb-xs">
        After you complete the Rising Team session on resilience, we want to give you some tips for how to continue
        building that muscle on your team.
      </div>
      <div className="pt-xs pb-large">Here are a few ideas you can try:</div>
      <h2 className="text-rising-orange pb-medium">Keep the conversation going</h2>
      <div className="pb-large">
        Building the team's resilience muscle is an ongoing process that you can continue developing through individual
        1:1 check-ins, regular team meetings, or dedicated events and offsites. Consider the following strategies to
        keep the process top of mind.
      </div>
      <h3 className="pb-medium">Follow-up on resilience strategies</h3>
      <div className="pb-large">
        Ask team members to share their experience with trying the new resilience strategies they identified in the team
        session. Have they had a chance to put them into practice? If so, what were the circumstances? What worked? What
        would they do differently next time? What support do they need? Make sure to share yours as well!
      </div>
      <h3 className="pb-medium">Encourage story telling</h3>
      <div className="pb-large">
        "To foster participation, trust, and engagement, leaders of resilient teams often encourage team members to map
        out their life's journey, including highs and lows, and share highlights with the rest of the team" says{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2021/01/7-strategies-to-build-a-more-resilient-team"
        >
          HBR
        </a>
        . You can try doing this at your next team meeting or offsite. Sometimes sharing personal stories, especially
        ones that involve resilience in our personal lives, can inspire us to bring the same capabilities to our work
        lives. Creating a safe space to share these kinds of stories builds on the psychological safety concept we
        covered in Kit 1.
      </div>
      <h3 className="pb-medium">Demonstrate vulnerability</h3>
      <div className="pb-xs">
        As a leader you have tremendous influence on the team culture. How you respond to challenging moments will set
        the tone for what the team deems "normal." You can accelerate creating a culture of resilience by showing the
        team that it's okay to be vulnerable and ask for help when you need it. You can do this by:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>Openly discussing your mistakes and failures</li>
          <li>Asking for help when needed</li>
          <li>Sharing personal challenges you've had to overcome</li>
          <li>Highlighting strategies that helped you overcome setbacks</li>
        </ul>
      </div>
      <div className="pt-xs pb-large">
        Rising Team implements an approach called "Festival of Failure," designed to create a safe space for people to
        share failures and what they learned from them. We have a Slack channel dedicated to this as well as include a
        regular section in meetings for people to share failures. Doing this frequently reduces the stigma around
        failure or mistakes and prepares the team to be better at navigating future challenges.
      </div>
      <h2 className="text-rising-orange pb-medium">Manage team energy</h2>
      <div className="pb-xs">
        Teams are at their most vulnerable when drained. The best chance we have to practice our resilience skills is to
        have the energy to address challenges as we encounter them. This means that leaders can be proactive in building
        resilience by paying attention to their team's well-being.
      </div>
      <div className="py-xs">
        Making time for self-care activities on the team can be instrumental in ensuring the team feels ready to take on
        challenges. You can help the team with well-being through practices like flexibility around schedules, setting
        times for grounding/meditation at the beginning of meetings, providing access to mental health resources, or
        giving people time off for necessary personal activities.
      </div>
      <div className="py-xs">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2021/01/7-strategies-to-build-a-more-resilient-team"
        >
          HBR also recommends
        </a>{" "}
        "temperature checks". This is where you pose a quick question at the end of a week, month or even specific
        project to gauge the energy of the team. They recommend that you "ask everyone to state their energy levels on a
        scale of one (low) to five (high)." You can do this in a team meeting or in 1:1 meetings if easier. After
        everyone responds, you can quickly identify those individuals who may need more support. Or if the whole team
        has low scores, you can have a dialogue about why they feel this way and how you can all help each other.
      </div>
      <div className="py-xs">
        There's no magic formula or one-size-fits-all approach to building team resilience. It's about staying plugged
        into the team's state of being and leveraging individual strengths at any given moment. Lastly, remember that
        team resiliency is a continuous journey, not a final destination. It's not only a personal journey but one that
        must be practiced.
      </div>
      <div className="py-xs">
        If you want to keep learning more about this topic, here are some additional resources:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://time.com/collection/guide-to-happiness/3892044/the-science-of-bouncing-back/"
            >
              The Science of Bouncing Back
            </a>{" "}
            (Time Magazine)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2016/06/627-building-resilience-ic-5-ways-to-build-your-personal-resilience-at-work"
            >
              5 Ways to Boost Your Resilience at Work
            </a>{" "}
            (Harvard Business Review)
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/learning/enhancing-resilience">
              Enhancing Resilience
            </a>{" "}
            (LinkedIn Learning)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.gallup.com/workplace/349484/state-of-the-global-workplace.aspx"
            >
              State of the Global Workforce
            </a>{" "}
            (Gallup)
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hbr.org/2021/01/7-strategies-to-build-a-more-resilient-team"
            >
              7 Strategies to Build a More Resilient Team
            </a>{" "}
            (Harvard Business Review)
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default styled(ResilienceTraining2)`
  li {
    color: var(--gray9);
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    .image-container {
      margin: var(--spacing-0);
    }
  }
`
