import { styled } from "styled-components"

const InputRadio = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-7);
  margin-right: 6px;
  position: relative;
  flex-shrink: 0;
  outline: none;

  &:disabled {
    background-color: var(--gray-2);
  }

  &[type="radio"] {
    border-radius: 50%;
    top: -2px;

    &:checked::after {
      content: "";
      border-radius: 50%;
      margin: 3px;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      background-color: var(--rising-green);
    }
  }

  &[type="checkbox"] {
    border-radius: 4px;

    &:checked {
      border: none;

      &::after {
        content: "✓";
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--fg);
        background-color: var(--rising-green);
        font-weight: 900;
        border-radius: 4px;
      }
    }
  }
`

export default InputRadio
