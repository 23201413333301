import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import api, { auth } from "api"
import { authCacheKey } from "domains/Authentication/resource"
import { featuresCacheKey } from "ui/hooks/useFeatures"

const MARKETING_URL = "https://risingteam.com"
const MARKETING_PRICING_URL = MARKETING_URL + "/pricing#prices"

const checkUserEmail = async (email) => {
  const { data } = await auth.post("/register/check_email/", { email })
  return data
}

const register = async (values) => {
  const { data } = await auth.post("/register/", values)
  return data
}

const useRegister = () => {
  const queryClient = useQueryClient()
  return useMutation(register, {
    onSuccess: (data) => {
      queryClient.setQueryData(authCacheKey, data)
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      window.heap.identify(data.id)
    },
  })
}

const registerInvited = async (values) => {
  const { data } = await auth.post("/register_invited/", values)
  return data
}

const useRegisterInvited = () => {
  const queryClient = useQueryClient()
  return useMutation(registerInvited, {
    onSuccess: (data) => {
      queryClient.setQueryData(authCacheKey, data)
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      window.heap.identify(data.id)
    },
  })
}

const verifyToken = async ({ uid, token }) => {
  const { data } = await auth.post("/verify_invite_link/", { uid, token })
  return data
}

const useVerifyToken = ({ uid, token }) => useQuery(["verify_invite_link"], () => verifyToken({ uid, token }))

const getPromo = async (code) => api.get(`/billing/promo/?code=${code}`).then(({ data }) => data)

const usePromo = (code) => useQuery(["billing", "promo", code], () => getPromo(code), { enabled: !!code })

async function createShrmVolunteerSession() {
  const { data } = await api.post("/billing/shrm_volunteer/")
  return data
}

function useCreateShrmVolunteerSession() {
  return useMutation(createShrmVolunteerSession)
}

export {
  MARKETING_URL,
  MARKETING_PRICING_URL,
  checkUserEmail,
  register,
  useRegister,
  registerInvited,
  useRegisterInvited,
  useVerifyToken,
  usePromo,
  useCreateShrmVolunteerSession,
}
