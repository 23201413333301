import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const NumMaxParticipants = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({
    kitInstance,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })

  if (!kitInstance || !participants) {
    return <Loading inline />
  }

  const maxParticipants = kitInstance.max_session_participants.toString()

  return <span className={className}>{maxParticipants}</span>
}

export default NumMaxParticipants
