import cn from "classnames"
import { styled } from "styled-components"

import View from "./View"

import { CircleIcon } from "icons/FontAwesomeIcons"

const UserLegend = ({ keys, colors, onKeyClick, className }) => {
  const handleKeyClick = (key) => () => {
    if (onKeyClick) {
      onKeyClick(key)
    }
  }
  return (
    <View $flexDirection="column" className={className}>
      <h3 className="text-uppercase text-gray-8 mb-xxs">Key</h3>
      <p className="instructions text-small text-gray-8 mb-xs">Click a name to view</p>

      {keys.map((key) => (
        <View
          key={key}
          $alignItems="center"
          className={cn("text-gray-9 text-semi-bold my-xxs", { interactive: onKeyClick })}
          onClick={handleKeyClick(key)}
        >
          <CircleIcon color={colors({ key })} className="mr-small" />
          {key}
        </View>
      ))}
    </View>
  )
}

export default styled(UserLegend)`
  .interactive {
    cursor: pointer;
  }

  @media print {
    .instructions {
      display: none;
    }
  }
`
