import cn from "classnames"
import { styled } from "styled-components"

import ChoicesField, { Choices } from "./ChoicesField"

const FlexibleRowCheckboxGroupField = styled(function FlexibleRowCheckboxGroupField({
  name,
  options,
  className,
  individualChoiceClassName,
  disabled = false,
  ...props
}) {
  const ChoicesComponent = disabled ? ReadOnlyChoicesField : ChoicesField
  return (
    <ChoicesComponent
      type="checkbox"
      name={name}
      options={options}
      disabled={disabled}
      className={className}
      individualChoiceClassName={cn("flex-shrink-0 fit-content", individualChoiceClassName)}
      {...props}
    />
  )
})`
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-3);
`

const ReadOnlyChoicesField = ({ className, ...props }) => (
  <div className={className}>
    <Choices {...props} />
  </div>
)

export default FlexibleRowCheckboxGroupField
