const DEIInclusiveCultureTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-large">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/great-attrition-or-great-attraction-the-choice-is-yours"
        >
          Fifty-one percent
        </a>{" "}
        of people who quit their job reported doing so, at least in part, because they didn't feel a sense of belonging
        in their workplace. Inclusive team cultures that promote the belonging of all members of a team are more
        successful (people on diverse teams are{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2016/11/why-diverse-teams-are-smarter">
          58% more likely
        </a>{" "}
        to price stocks correctly than those on homogenous teams), more innovative (as more women join an organization,
        the likelihood of{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://hbr.org/2016/11/why-diverse-teams-are-smarter">
          radical new innovations
        </a>{" "}
        in the following two years increases), and more engaged (individuals that report a sense of belonging have{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://coqual.org/wp-content/uploads/2021/04/Power-Of-Belonging-1-Press-Release-Updated.pdf"
        >
          43% higher
        </a>{" "}
        engagement levels than those who do not). The good news is that an inclusive culture is something that is
        created by members of an organization, which means that you have the power to contribute to it.
      </p>
      <h2 className="text-rising-orange mb-medium">Creating an inclusive culture</h2>
      <p className="mb-medium">
        There are many aspects that make up an inclusive culture, from individual interactions, to team dynamics, to
        organizational norms. While the correct mix of actions that will create an inclusive culture are unique to you
        and your team, below are some evidence-backed ideas to get your juices flowing:
      </p>
      <ul className="ml-xl mb-large">
        <li>
          Practice{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2023/05/make-inclusive-behaviors-habitual-on-your-team"
          >
            closed loop exchanges
          </a>{" "}
          where you intentionally acknowledge that messages are heard and received, even if you end up going in a
          different direction.
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2023/02/7-small-ways-to-be-a-more-inclusive-colleague"
          >
            Highlight the contributions
          </a>{" "}
          that others on your team make—whether at a meeting or in your Slack channel.
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2021/12/3-small-ways-to-be-a-more-inclusive-colleague"
          >
            Help each other out!
          </a>{" "}
          If you have information about something relevant to a team member, go out of your way to share it. If you know
          someone that would be a good contact for them, make an introduction. If you hear an idea you agree with being
          shared in a meeting, chime in and say so.
        </li>
        <li>
          Socialize the norms that{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2021/06/research-what-inclusive-companies-have-in-common"
          >
            failure is part of life and feedback is a gift
          </a>
          . To do so, consider sharing your own experiences of failure, the lessons you learned from them, and the ways
          feedback from others has changed your perspective.
        </li>
      </ul>
      <h2 className="text-rising-orange mb-medium">Bias as a threat to an inclusive culture</h2>
      <p className="mb-medium">
        One of the most insidious factors that counteracts efforts toward an inclusive culture is bias. For instance,
        across industries,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2022/10/to-build-a-dei-program-that-works-you-need-metrics"
        >
          81% to 88% of white men
        </a>{" "}
        report fair access to career-enhancing assignments while for other racial groups, that percentage is as low as
        50%. Additionally,{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=9pILReNo0-c">
          59% of men's performance reviews
        </a>{" "}
        include critical feedback, whereas 88% of women's do. Women's feedback is also less actionable and personal,
        making it more difficult to make responsive changes.
      </p>
      <p className="mb-medium">
        While you may not be surprised that biases abound as it relates to race and gender, there are some less-commonly
        discussed identities that are also impacted by bias in the workplace. For instance, in just six years, from 2004
        to 2010, anti-fat bias as measured by the{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://implicit.harvard.edu/implicit/iatdetails.html">
          Harvard Implicit Association Test
        </a>{" "}
        increased by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2019/08/research-on-many-issues-americans-biases-are-decreasing"
        >
          40%
        </a>
        . This bias was captured in an{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.frontiersin.org/articles/10.3389/fpsyg.2016.00647/full"
        >
          experiment
        </a>{" "}
        that asked people to rate candidates for employment. Holding all things equal between candidates, those that
        were described as signifcantly overweight were rated as less suitable for the job.
      </p>
      <p className="mb-large">
        All actions that you take to foster an inclusive culture are critical, but they would be incomplete if you did
        not also consider how unconscious bias may play a role in the culture you are creating. What kinds of people are
        considered a "culture fit" on your team? What kinds of social activities does your team do and who attends them?
        How is collaboration fostered on your team and whose voices are typically listened to? These are the kinds of
        questions you will want to ask yourself to move beyond simply a collegial culture, to a truly inclusive one.
      </p>
      <h2 className="text-rising-orange mb-medium">Inclusive culture: using an inclusive mindset</h2>
      <p className="mb-medium">
        In the last DEI module, we talked about the three tenets that make up an Inclusive Mindset: brave, humble, and
        dedicated. Those same tenets apply when trying to create an inclusive culture. We first have to be brave enough
        to acknowledge where we can better contribute to our team culture and how our biases may be preventing us from
        doing so. We then have to be humble by asking others on our team what we can do better and seeking out best
        practices. Finally, we must be dedicated to a lifelong commitment of contributing to an inclusive culture, even
        when we may sometimes mess up.
      </p>
      <p className="mb-medium">
        Practicing building an inclusive culture is not easy—it takes work on a continual basis. As you work through
        this session, we hope you will begin to identify how unconscious biases form and the actions you can take to
        counteract them.
      </p>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2021/08/youve-built-a-racially-diverse-team-but-have-you-built-an-inclusive-culture"
        >
          You've Built a Racially Diverse Team. But Have You Built an Inclusive Culture?
        </a>
      </p>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2021/12/3-small-ways-to-be-a-more-inclusive-colleague"
        >
          3 Small Ways to Be a More Inclusive Colleague
        </a>
      </p>
      <p className="mb-medium">
        <a target="_blank" rel="noopener noreferrer" href="https://asana.com/resources/unconscious-bias-examples">
          19 Unconscious Biases to Overcome and Help Promote Inclusivity
        </a>
      </p>
    </div>
  </div>
)

export default DEIInclusiveCultureTraining1
