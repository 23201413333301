import SelectField from "./SelectField"

const hear_us_through_options = [
  { value: "Searching online", label: "I was searching online" },
  { value: "Social media", label: "I saw it on social media" },
  { value: "Friend", label: "I heard about it from a friend" },
  { value: "News", label: "I read about it in the news" },
  { value: "Other", label: "Other" },
]

const HearUsThroughField = (props) => (
  <SelectField {...props}>
    <option disabled value="" />
    {hear_us_through_options.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ))}
  </SelectField>
)

export default HearUsThroughField
