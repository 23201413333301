import cn from "classnames"
import { styled } from "styled-components"

const Callout = ({ title, children, className, borderColor = "rising-green", borderLocation = "left" }) => (
  <div
    className={cn(
      "text-gray-9 fg blur-2 border-radius-small",
      "px-large py-medium px-xs-mobile-only py-xs-mobile-only",
      `border-${borderLocation}-thick border-${borderColor}`,
      className
    )}
  >
    {!!title && <h4 className="text-bold mb-small mr-xs">{title}</h4>}
    {children}
  </div>
)

export default styled(Callout)`
  li {
    padding-left: var(--spacing-1);
  }
`
