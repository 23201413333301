const ListeningTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        Unlike some of our other kits that focus on talent development or crisis management, this kit focuses on a
        leadership skill that you can practice every day, in any meeting or conversation. Here's how to turn this skill
        into a habit.
      </p>
      <h2 className="text-rising-orange mb-medium">Speak less</h2>
      <p className="mb-medium">
        Leaders are often rewarded for providing advice and direction. So it's not surprising that they can also fall
        into the trap of talking more than listening. As a recent{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.forbes.com/sites/mikemyatt/2012/02/09/why-most-leaders-need-to-shut-up-listen/?sh=d404ace6ef9e"
        >
          Forbes
        </a>{" "}
        article points out: "The big miss for most leaders is that they fail to understand that the purpose of
        communication is not to message, but to engage — THIS REQUIRES LISTENING."
      </p>
      <p className="mb-large">
        Try to get into the habit of asking a clarifying question before stating your opinion or advice. In doing so, we
        give others the space to express themselves and give ourselves a chance to process what's been shared.
      </p>
      <h2 className="text-rising-orange mb-medium">Manage team distractions</h2>
      <p className="mb-medium">
        As we see in this kit, managing distractions helps us stay present, which in turn enables us to be better
        listeners. As a team leader, you can apply this strategy at a team level. Can you think of distractions that may
        get in the way of the team consciously listening to each other? For instance, maybe it's a team norm for people
        to multitask during meetings. Even small shifts can help.
      </p>
      <p className="mb-large">
        So, as a team leader, look for opportunities to help people stay present, thus reducing miscommunication and
        creating team efficiencies.
      </p>
      <h2 className="text-rising-orange mb-medium">Lean into psychological safety</h2>
      <p className="mb-medium">
        As a team leader, you play an important role in encouraging all team members to speak with honesty and openness.
        Teams with high psychological safety perform better, and that's why we offer a kit on how to create it. In our
        Psychological Safety kit, you'll explore team dynamics that can build trust to underpin psychological safety.
      </p>
      <p className="mb-large">
        Fostering a sense of psychological safety—whether through our kit or other work—will only accelerate your team's
        ability to be conscious listeners. If you sense that some team members are holding back or not speaking up, help
        them along by asking them for their opinion or input. If the team is versed in psychological safety, this
        process will become more comfortable over time.
      </p>
      <h2 className="text-rising-orange mb-medium">Create a team listening experiment</h2>
      <p className="mb-medium">
        To aid the team in practicing conscious listening together, identify one behavior that everyone can commit to
        trying over a period of time as an experiment. For example, after every conversation, the listener could
        validate by saying: "What I hear you saying is _____. Is that right?" After a month, the team can debrief on how
        it felt and what impact it had.
      </p>
      <p className="mb-medium">
        When a team commits to trying something together, it can become a fun challenge that creates a shared awareness
        and investment in positive results. With some open dialogue and shared commitments, teams can learn how to
        elevate their conscious listening skills together!
      </p>
      <p className="mb-medium">If you want to learn more about this topic, here are some additional resources:</p>
      <ul className="mb-medium ml-medium">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://newsroom.accenture.com/industries/global-media-industry-analyst-relations/accenture-research-finds-listening-more-difficult-in-todays-digital-workplace.htm"
          >
            Accenture Research Finds Listening More Difficult in Today's Digital Workplace
          </a>{" "}
          (Accenture)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.psychologytoday.com/us/blog/play-your-way-sane/202108/were-worse-listening-we-realize"
          >
            We're Worse at Listening Than We Realize
          </a>{" "}
          (Psychology Today)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.forbes.com/sites/mikemyatt/2012/02/09/why-most-leaders-need-to-shut-up-listen"
          >
            Why Most Leaders Need to Shut Up and Listen
          </a>{" "}
          Forbes
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.amazon.com/Power-Listening-Mastering-Critical-Business/dp/1591844622"
          >
            Power Listening: Mastering the Most Critical Business Skill of All
          </a>{" "}
          (Bernard T. Ferrari)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://risingteam.com/post/how-psychological-safety-drives-successful-teams"
          >
            How psychological safety drives more successful teams
          </a>{" "}
          (Rising Team)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/effective-listening/improve-your-listening-skills"
          >
            Effective Listening
          </a>{" "}
          (LinkedIn)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=CKayQthlwts&feature=youtu.be"
          >
            Conscious Listening TEDx Talk by Julian Treasure
          </a>{" "}
          (TEDx)
        </li>
      </ul>
    </div>
  </div>
)

export default ListeningTraining2
