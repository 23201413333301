const CrisisResponseTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        During your team session, everyone will take a brief assessment to identify each team member's natural responses
        to sudden and negative scenarios. Later you will discuss and practice how to use their natural responses to
        contribute to quick recovery and team success.
      </p>
      <p className="mb-medium">This session has 3 activities</p>
      <h2 className="text-rising-orange mb-medium">Activity 1: A previous crisis</h2>
      <p className="mb-medium">
        The first part of the exercise highlights that all teams experience unexpected situations that can derail them
        if not addressed quickly and competently. While some setbacks are small and easily recoverable, this activity
        asks team members to reflect on a situation that rose to the level of a crisis. Each team member will share an
        example of a sudden and stressful negative event they recently encountered at work and then describe what made
        it so challenging as well as what helped them get through it.
      </p>
      <h2 className="text-rising-orange mb-medium">Activity 2: Responder roles</h2>
      <p className="mb-medium">
        The second part of the exercise presents a series of scenarios where team members will be asked to prioritize
        how they think they would respond. Each person will then get a personalized Responder profile highlighting their
        top preferences. (We’re not going to detail the Responder roles here, so that you can take your own Responder
        exercise without bias. You’ll see the complete details after you try the exercise yourself.)
      </p>
      <h2 className="text-rising-orange mb-medium">Activity 3: Practice scenario</h2>
      <p className="mb-medium">
        After learning about each team member’s responder role preferences, the team will work together to create a
        response plan to a real or potential workplace crisis scenario.
      </p>
      <p className="mb-medium">
        First, you will work as a team to identify a scenario from your own workplace that has some potential
        constructive actions you can take. Then, as a team, you will assign one or more team members to each responder
        role, using the "6 Hats" concept, where people get to practice thinking about a situation from different
        perspectives. Each team member will then build their response plan based on the role(s) they were assigned to
        and will describe what actions they would take to fulfill each role.
      </p>
      <p className="mb-medium">A few key things to keep in mind:</p>
      <h3 className="mb-medium">The Responder exercise is a self-reported reflection tool</h3>
      <p className="mb-medium">
        We built this exercise for it to be taken quickly and represent people’s own sense of their response tendencies.
        It is meant to be a conversation starter, not an exact “label” of a person. For this reason, we also include the
        ability for each person to rate their own results in terms of accuracy. Since we are measuring emotional
        responses here, they should be able to say what feels right to them personally, rather than as observed by
        others. As part of the discussion, make sure to leave room for people to talk about anything that feels off or
        missing to them.
      </p>
      <h3 className="mb-medium">It’s focused on workplace scenarios</h3>
      <p className="mb-medium">
        For this session, we limit the discussion to difficult situations and challenges that most commonly happen in
        the workplace. Although it’s possible that we would have similar responses and behaviors in challenging personal
        situations, the goal of the exercise is to optimize team resilience at work, so we stick to those scenarios. The
        Responder types are intentionally “high level,” and may not capture every possible response an individual might
        have for any given situation. If people find that there are other responses they may have that are relevant to
        work, encourage them to bring those up in the discussion.
      </p>
      <h3 className="mb-medium">Use Responder types to build a common language</h3>
      <p className="mb-medium">
        When everyone has completed the Responder role exercise, they will have a new language they can use to
        articulate how they’re responding when they encounter future challenges or setbacks. For example, when you need
        someone to analyze why a problem occurred, you might ask the team for a “Fire Inspector.” Or when you need
        someone to jump in with fast action, you might ask for some “Firefighters” to be on deck. As we point out in the
        6 Hats exercise, although people do have natural preferences, each of us is capable of playing different kinds
        of roles in a crisis. Understanding this common language also helps each of us switch our mindset to the role
        that needs attention in the moment.
      </p>
      <h3 className="mb-medium">How you react matters</h3>
      <p className="mb-medium">
        And finally, as with all of the Rising Team sessions, remaining open, curious, and positive is key to ensuring
        each of your team members feels understood and valued for what they bring to the table. Knowing that you are
        empathetic to the various responses will give your team members more confidence to look for opportunities to
        leverage their response to behaviors that most help the team. Overall, you want to make sure your team
        understands that the goal of this exercise is to support them and help maximize their success, not to judge
        them.
      </p>
      <p className="mb-medium">
        In the next step of the prep, you’ll be able to see a quick preview of how the team session will run, and test
        out the Responder exercise for yourself. In our last training article we’ll cover how to build team resilience
        on an ongoing basis.
      </p>
    </div>
  </div>
)

export default CrisisResponseTraining2
