import cn from "classnames"
import { useFormikContext } from "formik"
import { groupBy, mapValues } from "lodash-es"

import ExercisePercentageFieldBody from "./ExercisePercentageFieldBody"
import ExercisePercentageGroupFieldCenteredMetric from "./ExercisePercentageGroupFieldCenteredMetric"

import PercentagesChart from "ui/PercentagesChart"
import View from "ui/View"

const ExercisePercentageGroupFieldBody = ({
  saveOnChange,
  name: groupName,
  items,
  exerciseComponentNameMap,
  className,
  percentage_colors,
}) => {
  const { values, setValues } = useFormikContext()
  const pctValues = values[groupName] ?? {}

  for (const identifier of Object.keys(pctValues)) {
    const exercise_item = items.find((item) => item.identifier === identifier)
    if (!exercise_item) {
      delete pctValues[identifier]
    }
  }

  const itemsMap = groupBy(items, "identifier")
  const pctValuesLabels = mapValues(pctValues, (value, key) => ({
    ...value,
    label: itemsMap[key][0].label,
  }))

  const onChangeItem = (fieldName, value) => {
    const itemName = fieldName.split(".")[1]
    const newPctValues = {
      ...pctValues,
      [itemName]: { percentage: value },
    }
    setValues({ ...values, [groupName]: newPctValues })
    saveOnChange(groupName, newPctValues)
  }

  const sum = Object.values(pctValues).reduce((sum, v) => sum + (v.percentage || 0), 0)

  return (
    <View className={className} $flexDirectionTablet="column" $alignItems="center">
      <View $flexDirection="column" $flex={1}>
        {items.map((item) => {
          const id = `${groupName}.${item.identifier}`
          const CustomFieldComponent = exerciseComponentNameMap[item.custom_field?.component]
          return (
            <View key={id} $flexDirection="column" className="my-xs px-small py-xxs border border-radius border-gray-2">
              <ExercisePercentageFieldBody
                id={id}
                identifier={item.identifier}
                sentence={item.sentence}
                custom_field={item.custom_field}
                CustomFieldComponent={CustomFieldComponent}
                saveOnChange={onChangeItem}
                customSaveOnChange={saveOnChange}
              />
            </View>
          )
        })}
      </View>
      <View $flexDirection="column" $flex={1} $justifyContent="center" $alignItems="center">
        <PercentagesChart
          data={pctValuesLabels}
          colors={percentage_colors}
          layers={["arcs", "arcLabels", "arcLinkLabels", "legends", ExercisePercentageGroupFieldCenteredMetric]}
          enableArcLabels={false}
        />
        <p className={cn("mt-small", { "text-danger": 100 - sum < 0, "text-gray-8": 100 - sum >= 0 })}>
          {100 - sum}% remaining
        </p>
      </View>
    </View>
  )
}

export default ExercisePercentageGroupFieldBody
