const DEIInclusiveCultureTraining2 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-large">
        Cultivating an inclusive culture and building an inclusive culture on your team takes long-term dedication and
        reflection. Along with the new practices your team can adopt, like risk chips, "oops" and "ouch", and criteria
        setting, there are also several steps you can take to promote inclusion and decrease bias on your team in your
        day to day.
      </p>
      <h2 className="text-rising-orange mb-medium">Assess the reasons behind your beliefs</h2>
      <p className="mb-medium">
        When someone says or does something, we often have a reflexive reaction to it. We either view it positively or
        negatively, albeit at different degrees. These reflexive reactions are where bias hides out. In order to
        counteract this, practice quickly asking yourself why you are feeling the way that you do. Is it truly because
        of the thing that was said or done? Or are your feelings potentially being colored by your preconceptions about
        that person or people who hold the same identities as them? Many times you will be able to back up your
        perception with objective reasoning, but when you realize you have a flimsy argument, you may want to reconsider
        your judgment.
      </p>
      <p className="mb-large">
        If this feels difficult, try imagining yourself in the same scenario but changing some of the salient identities
        in the other person. If they were a man, would I still be feeling the same way? A Jewish person? A thin person?
        A native English speaker? An attracive person? Though this is not a perfect test by any means, it can help to
        reframe the context and uncover potential biases.
      </p>
      <h2 className="text-rising-orange mb-medium">If you see something, say something</h2>
      <p className="mb-medium">
        A large part of creating an inclusive culture has to do not with your actions, but your reactions. If you hear
        or see something that makes you wonder if bias might be at play, say something. Before you freeze up—we do not
        mean you should call someone out. Instead, replace judgment for curiosity. Ask them what made them come to that
        conclusion or tell them that hearing specific feedback or critiques can be really helpful to your learning
        process. Typically, if someone is not aware of their bias, having to put their reasoning into words can help
        them take a step back and realize what they had not considered.
      </p>
      <p className="mb-medium">
        Sometimes it can be appropriate to make a quick correction. For instance, consider you enter a meeting with your
        manager who identifies as a Latina woman. The person you are meeting with starts directing all of their
        questions to you and dismissing your manager when she jumps in to respond. Rather than letting this become the
        status quo, redirect by saying something like, "actually, I'm not sure but I think my manager, Elizabeth,
        probably has the answer. What do you think, Elizabeth?" thereby turning the conversation back to your manager.
        Small redirections like this can make a world of difference, without ruining the rapport with the other person.
      </p>
      <p className="mb-medium">
        Ultimately, fostering an inclusive culture and helping those around us do the same takes bravery, humility, and
        dedication. It is an ongoing process of uncovering blind spots and making adjustments. Sometimes it will be
        frustrating, but in the long-term it will have compounding benefits as you create a welcoming and equitable
        environment on your team and in your broader work environment.
      </p>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.fastcompany.com/90854949/7-ways-to-be-a-more-inclusive-colleague"
        >
          7 ways to be a more inclusive colleague
        </a>
      </p>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2019/09/to-build-an-inclusive-culture-start-with-inclusive-meetings"
        >
          To Build an Inclusive Culture, Start with Inclusive Meetings
        </a>
      </p>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2021/11/how-sharing-our-stories-builds-inclusion"
        >
          How Sharing Our Stories Builds Inclusion
        </a>
      </p>
    </div>
  </div>
)

export default DEIInclusiveCultureTraining2
