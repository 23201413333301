import { Formik } from "formik"
import { find } from "lodash-es"
import { useState } from "react"

import UpdateResultsButton from "./UpdateResultsButton"

import { useLatestTeamExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import FiveSensesUserResults from "domains/Exercise/ResultsComponents/FiveSensesUserResults"
import SelectField from "forms/fields/SelectField"
import { HeartIcon } from "icons/FontAwesomeIcons"
import { sortUsersByShortName, useUser } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import View from "ui/View"

const FiveSensesResultsReview = ({ kit, selectedTeam }) => {
  const { data: currentUser } = useUser({ userId: "me" })
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (!currentUser || isFetching) {
    return <Loading />
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const initialUserId = parseInt(query.participant)
  const initialUser = find(users, { id: initialUserId })

  return (
    <>
      <View $alignItems="flex-start" className="mb-large">
        <HeartIcon className="text-orange-4 title-icon mr-medium" />
        <h1 className="text-gray-9">5 Senses</h1>
      </View>
      <p className="text-gray-9 mb-medium">
        This summary was created during your team session. You can view each person’s individual results.
      </p>
      <SelectedUserResults
        kit={kit}
        users={users}
        currentUser={currentUser}
        exerciseInstances={exerciseInstances}
        initialUser={initialUser}
        selectedTeam={selectedTeam}
      />
    </>
  )
}

const SelectedUserResults = ({ kit, users, currentUser, exerciseInstances, initialUser, selectedTeam }) => {
  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id || currentUser.id)
  const sortedUsers = sortUsersByShortName({ users })
  const initialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })

  const handleUserChange = ({ target }) => {
    const user = find(users, {
      id: parseInt(target.value),
    })
    setSelectedUserId(user?.id || currentUser.id)
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-xl">
        <h4 className="text-gray-9 mb-small-mobile-only">View:</h4>
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="center">
          <Formik initialValues={initialValues} className="mb-xl">
            <SelectField name="current_team_member" size="medium" value={selectedUserId} onChange={handleUserChange}>
              {sortedUsers.map(({ id, short_name }) => (
                <option key={id} value={id}>
                  {short_name}
                </option>
              ))}
            </SelectField>
          </Formik>
          <View $justifyContent="flex-end" $justifyContentTablet="flex-start" $justifyContentMobile="flex-start">
            <UpdateResultsButton selectedUserId={selectedUserId} kitSlug={kit.slug} selectedTeam={selectedTeam} />
          </View>
        </View>
      </View>

      <FiveSensesUserResults exercise={selectedExercise} selectedUser={selectedUserId} />
    </>
  )
}

export default FiveSensesResultsReview
