import cn from "classnames"
import { useFormikContext } from "formik"
import { get, isEmpty } from "lodash-es"
import type { ElementType } from "react"

interface FormMessageProps {
  className?: string
  as?: ElementType
  path?: string
  [key: string]: any
}

// non_field_errors is default to django. Using message for our own custom message
const FormMessage = ({ className, as: AsComponent = "div", path = "message", ...props }: FormMessageProps) => {
  const { status, errors } = useFormikContext()

  const errorMessage = get(errors, path) || get(errors, "non_field_errors")
  const statusMessage = get(status, path) || get(status, "non_field_errors")
  const message = !isEmpty(errorMessage) ? errorMessage : statusMessage
  const messageType = !isEmpty(errorMessage)
    ? get(errors, "messageType", "danger")
    : get(status, "messageType", "danger")

  return (
    <AsComponent className={cn("form-message", `text-${messageType}`, className)} {...props} hidden={!message}>
      {message}
    </AsComponent>
  )
}

export default FormMessage
export type { FormMessageProps }
