import { Form, Formik } from "formik"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import LineRatingField from "forms/fields/LineRatingField"

const RatingFieldReadOnly = styled(function RatingFieldReadOnly({
  identifier,
  exerciseInstance,
  labels,
  colors,
  aria_labels,
  num_options = 10,
  show_numbers = false,
}) {
  const answer = getExerciseAnswer(exerciseInstance, identifier)
  const initialValues = {
    [identifier]: { value: answer?.toString() || "" },
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form>
        <LineRatingField
          disabled
          numOptions={num_options}
          name={identifier}
          colors={colors}
          axisLabels={labels}
          ariaLabels={aria_labels}
          className="mb-large"
          showNumbers={show_numbers}
        />
      </Form>
    </Formik>
  )
})`
  width: ${({ num_options }) => (num_options < 6 ? "70%" : "100%")};

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    width: 100%;
  }
`

export default RatingFieldReadOnly
