import { ResponsiveScatterPlot } from "@nivo/scatterplot"

import { useCurrentTheme } from "ui/ThemeUpdateContext"

const ScatterPlot = ({ data, theme = {}, axisLeft = {}, axisBottom = {}, ...props }) => {
  const appTheme = useCurrentTheme()
  const defaultTheme = {
    fontSize: "16px",
    grid: {
      line: {
        strokeWidth: "2px",
        stroke: "var(--border-color)",
      },
    },
    axis: {
      legend: {
        text: {
          fontSize: appTheme.normalFontSize,
          fill: "var(--gray-9)",
        },
      },
      ticks: {
        text: {
          fontWeight: 500,
          color: "#000",
        },
      },
    },
    label: {
      text: {
        fill: "#494949",
      },
    },
    fontFamily: appTheme.normalFontFamily,
    tooltip: {
      maxWidth: "230px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
      color: "white",
      background: "var(--gray-9)",
      borderRadius: "4px",
      boxShadow: "0px 0px 33px rgba(0, 0, 0, 0.05)",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "0.8125rem",
      lineHeight: "1.375rem",
    },
  }

  return (
    <ResponsiveScatterPlot
      data={data}
      theme={{ ...defaultTheme, ...theme }}
      margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
      xScale={{ type: "linear", min: 0, max: "auto" }}
      yScale={{ type: "linear", min: 0, max: "auto" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "x-axis",
        legendPosition: "middle",
        legendOffset: 46,
        ...axisBottom,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "y-axis",
        legendPosition: "middle",
        legendOffset: -60,
        ...axisLeft,
      }}
      useMesh={false}
      {...props}
    />
  )
}

export default ScatterPlot
