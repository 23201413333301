import StandaloneNavigation from "./StandaloneNavigation"

const StandaloneOutro = ({ kit, exerciseInstance }) => (
  <div className="text-gray-9">
    <h1 className="mb-large">Wrap-up</h1>
    <div className="space-y-small mb-medium">
      {kit.outro_paragraphs.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
    <StandaloneNavigation kit={kit} exerciseInstance={exerciseInstance} />
  </div>
)

export default StandaloneOutro
