import { styled } from "styled-components"

import InputField, { StyledInput } from "./InputField"

const ImageField = (props) => <InputField type="file" accept="image/*" {...props} />

export default styled(ImageField)`
  ${StyledInput} {
    border: none;
    box-shadow: none;
  }
`
