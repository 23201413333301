import { trim } from "lodash-es"
import { useState, createContext, useContext } from "react"
import { useLocation } from "react-router-dom"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"

const ScrollToTopContext = createContext({
  noScrollToTopForPaths: [],
  setNoScrollToTopForPaths: () => {},
  resetNoScrollToTop: () => {},
})

const useScrollToTopContext = () => useContext(ScrollToTopContext) || {}

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation()
  const [noScrollToTopForPaths, setNoScrollToTopForPaths] = useState([])

  useEffectAfterChange(() => {
    if (
      window.scrollY !== 0 &&
      !noScrollToTopForPaths.some(
        (path) => trim(pathname, "/.").includes(trim(path, "/."))
        // ignore leading+trailing dots and slashes in paths during comparison
      )
    ) {
      window.scrollTo(0, 0)
    }
  }, [pathname, noScrollToTopForPaths])

  return (
    <ScrollToTopContext.Provider
      value={{
        noScrollToTopForPaths,
        setNoScrollToTopForPaths,
        resetNoScrollToTop: () => setNoScrollToTopForPaths([]),
      }}
    >
      {children}
    </ScrollToTopContext.Provider>
  )
}

export { useScrollToTopContext }
