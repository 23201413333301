import cn from "classnames"
import { styled } from "styled-components"

import View from "./View"

import useWindowSize from "ui/hooks/useWindowSize"

const LineChart = styled(function LineChart({ labels, endLabels, showTick, className }) {
  const { isMobileOrSmaller } = useWindowSize()

  return (
    <View $flexDirection="column" className={cn("pt-xl pb-large", className)}>
      <div className="line-chart-ticks">
        {labels.map((label, index) => (
          <TickMark key={label} label={label} index={index} endLabels={endLabels} labels={labels} showTick={showTick} />
        ))}
      </div>

      {!!(endLabels || isMobileOrSmaller) && (
        <View
          $justifyContent="space-between"
          $alignItems="center"
          className={cn(
            "text-small text-thin text-gray-8 pt-xxs",
            { "end-labels": endLabels, "mobile-tick-labels pt-medium": showTick },
            className
          )}
        >
          {!!endLabels && (
            <>
              <div>{endLabels[0]}</div>
              <div>{endLabels[1]}</div>
            </>
          )}
          {!!showTick && (
            <>
              <div className="mobile-label">{labels[0]}</div>
              <div className="mobile-label">{labels[(labels.length - 1) / 2]}</div>
              <div className="mobile-label">{labels[labels.length - 1]}</div>
            </>
          )}
        </View>
      )}

      <div className="average-container">
        <div className="average-container-second">
          <div className="average">
            <p className="average-label text-semi-bold">AVG</p>
            <div className="average-tick"></div>
          </div>
        </div>
      </div>
    </View>
  )
})`
  position: relative;
  max-width: 691px;

  .line-chart-ticks {
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 0.813rem;
    line-height: 1.188rem;
    width: 100%;
  }

  .tick-mark-container {
    flex: 2 2 0;
    width: 1px;
    text-overflow: ellipsis;
    text-align: center;

    &:first-child,
    &:last-child {
      flex: 1 1 0;
    }

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  .end-labels {
    font-size: 0.813rem;
    line-height: 1.5rem;
  }

  .average-container {
    width: 100%;
    position: absolute;
  }

  .average-container-second {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .average {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
    color: var(--rising-orange);
    margin-left: ${({ labels, average }) => `${((average - 1) / (labels.length - 1)) * 100 - 5}%`};
  }

  .average-label {
    margin-top: 10px;
    font-size: 0.813em;
  }

  .average-tick {
    height: 23px;
    width: 2px;
    border-radius: 1px;
    background: var(--rising-orange);
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .mobile-label {
      flex-grow: 1;
      max-width: 65px;
    }

    .mobile-tick-labels {
      font-size: 0.75rem;
      line-height: 1rem;

      div {
        text-align: center;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
`

const TickMark = styled(function TickMark({
  labels, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  label,
  index, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  showTick,
  className,
}) {
  return (
    <div className={cn("tick-mark-container", className)}>
      <div className="top-line"></div>
      {!!showTick && <div className="tick"></div>}
      <p className={cn("tick-label text-gray-8 text-thin mx-xxs", { "pt-small": !showTick })}>{label}</p>
    </div>
  )
})`
  .top-line {
    height: 2px;
    background-color: var(--gray-5);
  }

  .tick {
    width: 2px;
    height: 20px;
    border-radius: 1px;
    background-color: var(--gray-5);
    margin: ${({ labels, index }) => (index === 0 ? "0 0" : index === labels.length - 1 ? "0 0 0 auto" : "0 auto")};
  }

  .tick-label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .tick {
      margin-top: -20px;
    }

    .tick-label {
      padding-top: ${({ showTick }) => (showTick ? "var(--spacing-4)" : "var(--spacing-2)")};
      display: ${({ showTick }) => (showTick ? "none" : "visible")};
    }
  }
`

export default LineChart
