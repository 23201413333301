import * as Sentry from "@sentry/browser"
import axios from "axios"
import Cookies from "js-cookie"
import qs from "qs"

import { getFirebaseAuthRequestHeaderPromise } from "domains/Authentication/firebase"
import { buildUrl } from "utils/string"

const FIREBASE_AUTH_RETRIES = 2

const BASE_API_URL = "/api/v1"
const XSRF_COOKIE_NAME = "csrftoken"
const XSRF_HEADER_NAME = "X-CSRFTOKEN"

const options = {
  withCredentials: true,
  xsrfCookieName: XSRF_COOKIE_NAME,
  xsrfHeaderName: XSRF_HEADER_NAME,
  firebaseAuthRetries: FIREBASE_AUTH_RETRIES, // set initial number of auth retries
}

// NOTE: We also set firebase authentication headers below using interceptors.
const root = axios.create(options)

const auth = axios.create({
  ...options,
  baseURL: "/authentication",
  // NOTE: We also set firebase authentication headers below using interceptors.
})

const api = axios.create({
  ...options,
  baseURL: BASE_API_URL,
  paramsSerializer: {
    serialize(params) {
      return qs.stringify(params, { indices: false })
    },
  },
  // NOTE: We also set firebase authentication headers below using interceptors.
})

// Set up Firebase Auth <> Axios request header integration.
// Interceptors below will do nothing if user is not authenticated via Firebase.
for (const instance of [root, auth, api]) {
  instance.interceptors.request.use(
    async (requestConfig) => {
      const authHeaderPromise = getFirebaseAuthRequestHeaderPromise({
        forceTokenRefresh: !!requestConfig.isFirebaseAuthRetry,
        // during Firebase Auth retry request, always attempt to refresh auth token
      })
      const isLogoutRoute = window.location.pathname.startsWith("/auth/logout")
      if (authHeaderPromise && !isLogoutRoute) {
        requestConfig.headers = {
          ...(requestConfig.headers ?? {}),
          ...(await authHeaderPromise),
          isAuthenticatedWithFirebase: true,
        }
        const willRetryRequest = requestConfig.firebaseAuthRetries > 0
        if (!requestConfig.headers.authorization && !willRetryRequest) {
          // If we failed to get an auth header, and there won't be a retry, alert us of this failure:
          Sentry.captureMessage(
            "Firebase Auth token refresh failed while setting request headers during request auth retry."
          )
        }
      }
      return requestConfig
    },
    // Error handler is necessary to ensure errors above are not hidden:
    (error) => Promise.reject(error)
  )

  // Set up response interceptor to automatically retry auth-failed requests with a
  // refreshed auth token, IF the user is currently authenticated with Firebase Auth:
  instance.interceptors.response.use(null, async (error) => {
    const { response, config: requestConfig } = error
    if (
      response?.status === 401 &&
      requestConfig?.isAuthenticatedWithFirebase &&
      requestConfig?.firebaseAuthRetries > 0
      // If requestConfig missing, or no retries remaining, don't retry.
      // This avoids possibility of a request-retry infinite loop.
    ) {
      return instance.request({
        ...requestConfig,
        isFirebaseAuthRetry: true,
        firebaseAuthRetries: requestConfig?.firebaseAuthRetries - 1,
      })
    } else {
      // Otherwise, use Promise.reject to ensure error is handled normally:
      return Promise.reject(error)
    }
  })
}

const fetchApiPost = async (path, body) => {
  const xcsrfToken = Cookies.get(XSRF_COOKIE_NAME)

  const firebaseAuthHeaderPromise = getFirebaseAuthRequestHeaderPromise()
  const firebaseAuthHeader = firebaseAuthHeaderPromise
    ? {
        ...(await firebaseAuthHeaderPromise),
        isAuthenticatedWithFirebase: true,
      }
    : {}

  return fetch(buildUrl([BASE_API_URL, path]), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [XSRF_HEADER_NAME]: xcsrfToken,
      ...firebaseAuthHeader,
    },
    body: JSON.stringify(body),
  })
}

const fetchS3Post = async (presignedUploadData, file) => {
  const url = presignedUploadData.url
  const fields = presignedUploadData.fields
  const formData = new FormData()
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key])
  })
  formData.append("file", file)
  return fetch(url, {
    method: "POST",
    body: formData,
  })
}

export default api

export { root, auth, fetchApiPost, fetchS3Post }
