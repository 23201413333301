import cn from "classnames"
import { Link } from "react-router-dom"

import { TrafficCone } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"
import { plural, wordForInt } from "utils/string"

const SetupIncomplete = ({ className, steps = null }) => {
  const numIncompleteSteps = steps?.filter(({ completed }) => !completed).length ?? 0
  const wordIncompleteSteps = wordForInt(numIncompleteSteps)
  return (
    <div className={cn(className, "full-width m-xxxl")}>
      <h1 className="text-large text-gray-9 mb-xl">
        <TrafficCone className="title-icon text-rising-orange gradient-icon mr-medium" />
        Are you sure?
      </h1>
      <p className="text-gray-8 mb-large">
        It looks like there {plural(numIncompleteSteps, "is", { pluralForm: "are", showCount: false })}{" "}
        {plural(numIncompleteSteps, wordIncompleteSteps, {
          pluralForm: wordIncompleteSteps,
          zeroForm: "some",
          showCount: false,
        })}{" "}
        {plural(numIncompleteSteps, "step", { showCount: false })} that{" "}
        {plural(numIncompleteSteps, "is", { pluralForm: "are", showCount: false })} still incomplete.
      </p>
      <View className="mt-xxxl mb-medium">
        <Link to="/setup" className="link text-button mr-medium">
          <Button>Go back and finish</Button>
        </Link>
        <Link to="/kit" className="link text-button">
          <Button className="secondary">I'll do this later</Button>
        </Link>
      </View>
    </div>
  )
}

export default SetupIncomplete
