import SSOLogin from "./SSOLogin"

import useQueryParams from "ui/hooks/useQueryParams"
import { asStringsOrNulls } from "utils/string"

const TestSSOLogin = ({ ssoRedirectLogin = false }) => {
  const { provider = null, providerName = null } = asStringsOrNulls(useQueryParams())

  if (!provider || !providerName) {
    return null
  }

  return <SSOLogin provider={provider} providerName={providerName} ssoRedirectLogin={ssoRedirectLogin} />
}

export default TestSSOLogin
