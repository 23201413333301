import { Navigate, useNavigate, useParams } from "react-router"

import { useOrCreateKitInstance } from "resources/monthly_kit"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"
import { PromoKitSlug } from "utils/kit"
import { buildUrl } from "utils/string"

export default function KitSlugRedirect() {
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam()
  const { slug } = useParams()
  const urlQueryParams = useQueryParams()
  const navigate = useNavigate()

  const redirectWithTeamId = !selectedTeamId && redirectTeamId
  const redirectToKitHome = !selectedTeamId && !needsRedirect

  const isGratitudePromo = !!urlQueryParams.gratitude_promo
  const isGratitudeKit = slug === PromoKitSlug.GRATITUDE

  // Ensure we only send create-kit-instance request in cases where we WON'T redirect:
  const { data: kitInstance } = useOrCreateKitInstance({
    slug,
    teamId: selectedTeamId,
    enabled: !redirectWithTeamId && !redirectToKitHome,
    addPromoKit: isGratitudePromo && isGratitudeKit,
    onError: () => navigate(buildUrl(["kit", slug, "unavailable"])),
  })

  if (redirectWithTeamId) {
    const urlParts = slug ? ["kit", slug] : ["kit"]
    const url = buildUrl(urlParts, {
      urlQueryParams: { ...urlQueryParams, team_id: redirectTeamId },
    })
    return <Navigate to={url} replace />
  }

  if (redirectToKitHome) {
    return <Navigate to="/kit" replace />
  }

  if (!kitInstance) {
    return <Loading />
  }

  return <Navigate to={kitInstance.home_url} replace />
}
