import { styled } from "styled-components"

const RT4SPurposeGoalsTraining2 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <h2 className="text-rising-orange pb-xs">Benefits of a team purpose</h2>
      <p className="mb-medium">
        While a school purpose statement is broad and applies to everyone in the school, a team purpose statement is
        more specific and tailored to a particular team. While it aligns with the school’s purpose, a team purpose zooms
        in on the unique goals, objectives, and responsibilities of that team. It helps team members understand their
        collective mission, their roles, and contributions to the school’s overall objectives.
      </p>
      <p className="mb-medium">
        One sign that a team has a compelling shared purpose statement is that it energises, inspires, and motivates the
        team, creating a sense of cohesion and belonging.
      </p>
      <p className="mb-medium">
        A compelling purpose creates a sense of unity and promotes collaboration, as everyone works together towards a
        common objective, leveraging their diverse talents and perspectives. It enhances communication and
        decision-making processes, as decisions are guided by a larger vision, driving continuous improvement and
        success.
      </p>
      <p className="mb-medium">
        Purpose statements work best when they are developed together as a team and have enough detail to be tangible
        and meaningful. The right level of detail helps a team identify shared priorities and clarify what to focus on.
        For example, a statement like our purpose is to “support a lifelong love of learning” or “ to develop
        creativity, collaboration, and critical thinking” makes it easier to prioritise between the many initiatives
        that might compete for the team’s time and attention.
      </p>
      <h2 className="text-rising-orange pb-xs">Purpose is about why, goals address ‘how’</h2>
      <p className="mb-medium">
        Team goals ensure everyone is working towards the same objectives. This alignment helps to coordinate efforts
        and resources effectively, minimising duplication of work and maximising productivity.
      </p>
      <p className="mb-medium">
        Clear and achievable team goals can motivate team members to work together towards a common purpose. They help
        us focus our energy and actions and provide a basis for monitoring progress and evaluating performance. When
        team members are collectively responsible for achieving specific goals, it fosters a sense of accountability
        within the team and addresses how the team will work together to realise their shared purpose.
      </p>
      <p className="mb-medium">
        The value of setting goals is well understood as a useful practice. It is acknowledged that when people set
        goals, they are better able to direct their attention (Hattie, 2012) and are more motivated to persevere
        (Bandura, 1977). Researchers (Bandura, 1997; Locke & Latham, 2013) have found that goal-setting also affected
        people’s motivation levels and belief in their ability.
      </p>
      <p className="mb-medium">
        Goal setting in school teams makes it possible to consider larger goals that are more than any individual could
        do on their own. Here are some examples:
      </p>
      <ol className="ml-xl mb-medium">
        <li>
          <span className="text-bold">Improving Student Learning Outcomes:</span> This could involve setting specific
          targets for student progress and achievement in various subjects or skills, such as enhancing abilities to
          transfer and apply concepts, principles, and methods, improving levels of proficiency, increasing attendance
          rates, or enhancing critical thinking abilities, and then developing strategies and resources to help students
          achieve those targets.
        </li>
        <li>
          <span className="text-bold">Enhancing Student Engagement and Wellbeing:</span> Teams play an important role in
          supporting students’ engagement and well-being. This may include goals focused on a particular domain of
          student engagement (i.e. cognitive, emotional, and/or behavioural engagement) or wellbeing. This may include
          setting targets for student completion of tasks, participation in activities, or attendance at events or
          performances, or setting targets related to student attitude, resilience, satisfaction, and experiences at
          school, and offering strategies and resources that encourage healthy lifestyle habits, that help students
          manage stress effectively and cope with adversity, that build on students’ strengths, the help them build
          healthy relationships, and implementing practices that help students feel valued, respected and accepted.
        </li>
        <li>
          <span className="text-bold">Improving Teacher Collaboration:</span> A goal for a grade-level or subject-area
          team could be to enhance collaboration and communication among teachers. This might involve setting targets
          for the frequency and quality of collaborative planning meetings, implementing shared resources and lesson
          plans, and fostering a culture of peer support and professional learning.
        </li>
      </ol>
      <p className="mb-medium">
        Developing a shared sense of purpose, building consensus on goals, and setting expectations around meeting these
        have a significant impact on multiple factors, including work satisfaction, commitment, empowerment, and
        efficacy.
      </p>
      <h4 className="my-medium text-semi-bold">More resources:</h4>
      <ul className="ml-xl">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://research.acer.edu.au/tll_misc/38/"
            className="text-gray-9"
          >
            "School Improvement Tool Elaborations: Student engagement and wellbeing" by Australian Council for
            Educational Research (ACER) and Queensland Department of Education
          </a>
        </li>

        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://jamesclear.com/habits" className="text-gray-9">
            Habits Guide: How to Build Good Habits and Break Bad Ones (jamesclear.com)
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default styled(RT4SPurposeGoalsTraining2)`
  ol,
  ul {
    margin: 0;
    padding-left: 24px;
  }
  ol > li::marker {
    font-weight: 700;
  }
`
