import cn from "classnames"

import { StarIcon, CircleCheckIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import { colors } from "ui/theme"

const SetupButton = ({ className, children, active, completed }) => (
  <Button
    className={cn(className, { secondary: !active }, "large full-width text-left py-large")}
    color={completed ? colors.risingGreen : colors.orange3}
  >
    {completed ? <CircleCheckIcon className={cn({ "text-rising-green": !active })} /> : <StarIcon />}
    <span className={cn({ "text-gray-9": !active })}> {children}</span>
  </Button>
)

export default SetupButton
