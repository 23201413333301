import { last, range, sortBy } from "lodash-es"

import ResultsBlocks from "domains/Exercise/ResultsBlocks/ResultsBlocks"
import CrisisResponseExerciseReport from "domains/Reports/ExerciseReports/CrisisResponseExerciseReport"
import LearnerAgilityExerciseReport from "domains/Reports/ExerciseReports/LearnerAgilityExerciseReport"
import TalentsExerciseReport from "domains/Reports/ExerciseReports/TalentsExerciseReport"
import { useKitInstanceReportExerciseInstances } from "resources/monthly_kit"
import { getUseGetAggregatedResultsDataForTeamFunction } from "resources/teams"
import Loading from "ui/Loading"

const SLUG_TO_EXERCISE_REPORT_COMPONENT = {
  talents: TalentsExerciseReport,
  "learner-agility": LearnerAgilityExerciseReport,
  "crisis-response": CrisisResponseExerciseReport,
}

const KitExerciseResultsReport = ({ kitInstance, className }) => {
  const { data: reportExerciseInstances, isFetching } = useKitInstanceReportExerciseInstances({
    kitInstanceId: kitInstance.id,
  })

  if (!reportExerciseInstances && isFetching) {
    return <Loading />
  }

  const exerciseInstances = reportExerciseInstances?.user_exercises
  const teamExerciseInstance = reportExerciseInstances?.team_exercise
  if (!teamExerciseInstance && !exerciseInstances?.length) {
    return null
  }

  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at")) ?? teamExerciseInstance
  const latestDefinition = latestExerciseInstance.definition

  if (!latestDefinition) {
    return null
  }

  // Create anonymous users called "Person X"
  const userIds = range(1, exerciseInstances.length + 1)
  const userIdMap = userIds.reduce(
    (acc, userId) => ({
      ...acc,
      [userId]: {
        id: userId,
        first_name: "Person",
        last_name: userId.toString(),
        short_name: `Person ${userId}`,
      },
    }),
    {}
  )
  const sortedUsers = Object.values(userIdMap).sort((a, b) => a.id - b.id)

  // Set user_id to anonymous user ids
  const exerciseInstancesWithUserId = exerciseInstances.map((e, idx) => ({
    ...e,
    user_id: idx + 1,
  }))

  const exerciseReportBlocks = latestDefinition.exercise_report_blocks ?? latestDefinition.team_overview_blocks
  if (exerciseReportBlocks) {
    const useAggregatedResultsData = getUseGetAggregatedResultsDataForTeamFunction({
      teamId: kitInstance.team.id,
      kitSlug: kitInstance.slug,
      apiResource: "team_reports",
    })

    return (
      <ResultsBlocks
        blocks={exerciseReportBlocks}
        exerciseInstances={exerciseInstancesWithUserId}
        teamExerciseInstance={teamExerciseInstance}
        sortedUsers={sortedUsers}
        useAggregatedResultsData={useAggregatedResultsData}
      />
    )
  }

  const ExerciseReportComponent = SLUG_TO_EXERCISE_REPORT_COMPONENT[kitInstance.slug]
  if (!ExerciseReportComponent) {
    return null
  }

  return (
    <div className={className}>
      <h3 className="mb-medium">Exercise Results</h3>
      <ExerciseReportComponent
        exerciseInstances={exerciseInstancesWithUserId}
        userIdMap={userIdMap}
        team={kitInstance.team}
      />
    </div>
  )
}

export default KitExerciseResultsReport
