import DOMPurify from "dompurify"

function initDOMPurify() {
  changeAllDOMPurifyLinkstoOpenInNewTab()
}

function changeAllDOMPurifyLinkstoOpenInNewTab() {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank")
      // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
      node.setAttribute("rel", "noopener noreferrer")
    }
    // set non-HTML/MathML links to xlink:show=new
    if (!node.hasAttribute("target") && (node.hasAttribute("xlink:href") || node.hasAttribute("href"))) {
      node.setAttribute("xlink:show", "new")
    }
  })
}

function walkNodeTree(
  root: Node,
  {
    callback,
    filter = null,
    ignore = null,
    includePreceedingAdjacentNodes = false,
  }: {
    callback: (prevNode: Node, preceedingAdjacentNodes: Node[]) => void
    filter: null | ((node: Node) => boolean)
    ignore: null | ((node: Node) => boolean)
    includePreceedingAdjacentNodes: boolean
  }
) {
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_ALL, {
    acceptNode: (node) => {
      if (ignore && ignore(node)) {
        return NodeFilter.FILTER_REJECT
      } else if (filter && !filter(node)) {
        return NodeFilter.FILTER_SKIP
      } else {
        return NodeFilter.FILTER_ACCEPT
      }
    },
  })
  if (!includePreceedingAdjacentNodes) {
    let node = null
    while (!!(node = walker.nextNode())) {
      callback(node, [])
    }
  } else {
    // includePreceedingAdjacentNodes=true causes groups of adjacent matching nodes
    // to be sent to callback, instead of each and every individual node.
    // This helps because often multiple text nodes may be adjacent, but the caller
    // really just wants the text value from all of them together. If this mode is
    // active, only the last of a group of adjacent nodes will be sent as the main
    // node to callback, and an additional arg will contain the preceeding nodes.
    let node = null
    let prevNode = null
    let preceedingAdjacentNodes = []
    while (!!(node = walker.nextNode())) {
      if (prevNode && prevNode !== node.previousSibling) {
        callback(prevNode, preceedingAdjacentNodes)
        preceedingAdjacentNodes = []
      } else if (prevNode) {
        preceedingAdjacentNodes.push(prevNode)
      }
      prevNode = node
    }
    if (prevNode) {
      callback(prevNode, preceedingAdjacentNodes)
    }
  }
}

export { initDOMPurify, changeAllDOMPurifyLinkstoOpenInNewTab, walkNodeTree }
