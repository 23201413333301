import { mapValues } from "lodash-es"

import { CHART_MAX_VALUE, getCategoryToPointsMap, getChartValues, pointsToBucketIndex } from "./radarchart"

const BUCKETS = ["Low", "Medium", "High", "Very High"]
const TEAMMOJI_BASE_URL = "https://static.risingteam.com/kit_assets/talents/teammojis/"
const TEAMMOJI_DOWNLOAD_URL = `${TEAMMOJI_BASE_URL}TalentsTeamMojis.zip`

const getTalentsData = (exercise) => {
  const talentsData = {
    envision: {
      title: "Envision",
      teammoji: "rising-team-envision.png",
      shortDescription: "Love imagining new ideas that have not existed before. Great in a brainstorm.",
      description:
        "With Envision as a top talent you have the natural ability to imagine things that haven’t existed before. You often enjoy the very first stages of a project where it’s more open-ended and you can contribute new ideas. In addition to creating fresh ideas, you are also often good at coming up with new solutions to problems that haven’t previously been considered or tried. Leverage this talent by helping others think about new approaches and getting involved early in the ideation stages of new projects.",
    },
    clarify: {
      title: "Clarify",
      teammoji: "rising-team-clarify.png",
      shortDescription: "Take complex information and enjoy boiling it down into easy to understand formats.",
      description:
        "With Clarify as a top talent it comes easily to you to take large amounts of complex information and simplify them into easily understandable pieces. You may do this through clear written documents, visual frameworks, summarized data, and/or presentation decks. Whatever the means, you often enjoy making something complicated into something more straightforward, which helps others around you understand and appreciate complex information. Leverage this talent by helping clarify big and/or complex ideas in ways that make them easy to understand, sell, and build.",
    },
    persuade: {
      title: "Persuade",
      teammoji: "rising-team-persuade.png",
      shortDescription:
        "Enjoy getting people excited about ideas, through data, design, storytelling, or persuasive writing or speaking.",
      description:
        "As a Persuader you are naturally good at getting other people excited about an idea or product. You may do this by using your command of language and storytelling, by leveraging data and analytical proof points, or through visual and creative means, but no matter the methods, the outcome is the same—people are influenced by you and find your ideas compelling. Persuaders are also often good at understanding and meeting the needs of the people around them. Use this talent to help pitch or sell new ideas, learn about stakeholder needs and bring people onboard.",
    },
    plan: {
      title: "Plan",
      teammoji: "rising-team-plan.png",
      shortDescription: "Love planning how something will happen and the processes & systems that need to be in place.",
      description:
        "With Plan as a top talent, you are naturally good at putting together processes and systems to help things work more smoothly. As a Planner you are also likely to be organized, detail-oriented and good at keeping teams on track towards reaching goals. Allocating resources across different projects or goals often comes naturally to you. Use this talent to create plans and systems to help teams work effectively together and reach goals. Get involved in projects before the actual work begins so you can set up the process for success.",
    },
    cultivate: {
      title: "Cultivate",
      teammoji: "rising-team-cultivate.png",
      shortDescription:
        "Enjoy bringing groups of people together as a team, helping individuals grow, and often have high empathy for customers.",
      description:
        "With Cultivate as a top talent, you are a natural at understanding and caring for the people around you, including both your colleagues and your customers. You often exhibit a high degree of empathy and are a strong relationship-builder with those around you. This talent can make you effective at building community among teams, coaching others to grow and in understanding customer needs to help define what to provide for them. Take advantage of this talent by getting involved early in projects when user empathy is critical and also by supporting and leading teams to help them bond together and grow as individuals.",
    },
    build: {
      title: "Build",
      teammoji: "rising-team-build.png",
      shortDescription:
        "Love making and creating new things, whether through physical materials, code, writing, design or other means.",
      description:
        "As a Builder you are naturally good at bringing ideas to life. Builders love to get involved in projects at the stage when the creation work is happening, whether that is in code, in hardware, in writing, or other means. Your Builder talent means you are also often good at bringing disparate efforts together into unified ones. You are often pragmatic, and you usually prefer to execute on well established plans than to create new ones. Leverage your talent as a Builder to help teams create new things that didn’t exist before and turn vision into reality.",
    },
    accomplish: {
      title: "Accomplish",
      teammoji: "rising-team-accomplish.png",
      shortDescription: "Enjoy driving results and beating deadlines and expectations.",
      description:
        "With Accomplish as a top talent, you are a natural at getting things done, and love to beat expectations around goals that are set. As an Accomplisher you likely love making and completing to-do lists and you are often willing to work extra hard to complete goals by their deadlines. You may also be good at rallying others around an idea and getting teams to push hard towards achieving goals together. To take advantage of this talent, get involved in projects that have clear goals and deadlines and lead others to meet or exceed those goals, even when people think it may not be possible.",
    },
    analyze: {
      title: "Analyze",
      teammoji: "rising-team-analyze.png",
      shortDescription: "Measure how things are working, solve problems, and love digging into data of all types.",
      description:
        "As an Analyzer, you are naturally good at problem-solving and digging into data or information to understand why things are happening. You are often able to imagine and execute multiple approaches to find solutions and will generally continue working hard until the issue is solved. Your Analyzer talent can also be useful in seeing patterns in the way things work to uncover new potential ideas or directions. Leverage your talent as Analyzer to get involved when projects are stuck in order to unblock them, and to discern patterns in data that may lead to new possibilities.",
    },
    adapt: {
      title: "Adapt",
      teammoji: "rising-team-adapt.png",
      shortDescription: "Love to continuously learn and adapt as things change. Often good in a crisis.",
      description:
        "As an Adapter you are naturally good at staying calm when reacting to change, and at learning new things and applying them to new situations. You often use this talent to achieve better results for teams you work on because you help them understand and react to changing external circumstances. You are also likely known for being the person who stays even keeled in a crisis and can help others stay calm too. Leverage your talent as an Adapter to help guide teams through change or crisis, and to help projects adapt to continually changing environments. Also make sure you stay in roles that allow you to keep fulfilling your talent and desire to continue learning.",
    },
  }

  //TODO: this is a temporary hack until we migrate talents to blocks.
  if (exercise && exercise.version.startsWith("schools_")) {
    talentsData.cultivate.shortDescription =
      "Enjoy bringing groups of people together as a team, helping individuals grow, and often have high empathy for others."
    talentsData.cultivate.description =
      "With Cultivate as a top talent, you are a natural at understanding and caring for the people around you, including both your colleagues and others in your school community. You often exhibit a high degree of empathy and are a strong relationship-builder with those around you. This talent can make you effective at building community among teams, coaching others to grow and in understanding student needs to help define what to provide for them. Take advantage of this talent by getting involved early in projects when user empathy is critical and also by supporting and leading teams to help them bond together and grow as individuals."
  }

  return talentsData
}

const processTalentsData = (exercise) => {
  const maxPointsPerBucket = exercise.definition.max_points_per_bucket
  const talentPoints = getCategoryToPointsMap(exercise)
  const talentBucketIndices = mapValues(talentPoints, (points) => pointsToBucketIndex(points, maxPointsPerBucket))
  const talentBuckets = mapValues(talentBucketIndices, (bucketIndex) => BUCKETS[bucketIndex])
  const sortedTalents = Object.keys(talentPoints).sort((a, b) => talentPoints[b] - talentPoints[a])
  const talentChartValues = getChartValues(talentPoints, maxPointsPerBucket)

  return {
    exercise,
    talentBucketIndices,
    talentBuckets,
    sortedTalents,
    talentChartValues,
  }
}

const getChartConfig = (exercises, userIdMap) => {
  if (!exercises?.length) {
    return { data: [] }
  }

  const processedExercises = exercises.map(processTalentsData)
  const names = exercises.map((exercise) => userIdMap[exercise.user_id]?.short_name).filter(Boolean)
  // The radar chart has one ring per bucket plus an extra ring for aesthetic reasons
  const gridLevels = BUCKETS.length + 1
  const talentsData = getTalentsData(exercises[0])

  return {
    keys: names,
    indexBy: "talent",
    maxValue: CHART_MAX_VALUE,
    gridLevels,
    valueFormat: (value) => Math.round(value),
    data: Object.entries(talentsData).map(([talent, { title }]) =>
      processedExercises.reduce(
        (talentConfig, processedExercise) => {
          const { exercise, talentChartValues } = processedExercise
          const key = userIdMap[exercise.user_id]?.short_name
          if (key) {
            talentConfig[key] = talentChartValues[talent]
          }
          return talentConfig
        },
        { talent: title }
      )
    ),
  }
}

const mashupExercises = (exercises, userIdMap) => {
  if (!exercises?.length) {
    return {
      processedExercises: [],
      numHighTalents: 0,
      peopleWithTalent: [],
    }
  }

  const processedExercises = exercises.map(processTalentsData)
  const talentsData = getTalentsData(exercises[0])
  const talents = Object.keys(talentsData)

  const maxBucketIndexPerTalent = talents.reduce((acc, talent) => {
    const exerciseBucketIndicesForTalent = processedExercises.map(
      ({ talentBucketIndices }) => talentBucketIndices[talent]
    )
    const maxBucketIndex = Math.max(...exerciseBucketIndicesForTalent)
    return { ...acc, [talent]: maxBucketIndex }
  }, {})

  const numHighTalents = talents.filter((talent) => maxBucketIndexPerTalent[talent] >= 2).length

  const peopleWithTalent = talents.reduce((acc, talent) => {
    const processedExercisesWithTalent = processedExercises.filter(
      ({ talentBucketIndices }) => talentBucketIndices[talent] >= 2
    )
    const people = processedExercisesWithTalent.map((x) => userIdMap[x.exercise.user_id])
    return { [talent]: people, ...acc }
  }, {})

  return {
    processedExercises,
    numHighTalents,
    peopleWithTalent,
  }
}

export {
  BUCKETS,
  TEAMMOJI_BASE_URL,
  TEAMMOJI_DOWNLOAD_URL,
  getTalentsData,
  processTalentsData,
  getChartConfig,
  mashupExercises,
}
