import cn from "classnames"
import { styled } from "styled-components"

import View from "./View"

const NpsChart = styled(function NpsChart({ labels, npsToolTip, className }) {
  return (
    <View $flexDirection="column" className={cn("pt-xl pb-large", className)}>
      <div className="line-chart-ticks">
        {labels.map((label, index) => (
          <TickMark key={label} label={label} index={index} labels={labels} />
        ))}
      </div>

      <div className="score-container">
        <div className="score">{npsToolTip}</div>
      </div>
    </View>
  )
})`
  position: relative;
  max-width: 691px;

  .line-chart-ticks {
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 0.813rem;
    line-height: 1.188rem;
    width: 100%;
  }

  .tick-mark-container {
    flex: 2 2 0;
    width: 1px;
    text-overflow: ellipsis;
    text-align: center;

    &:first-child,
    &:last-child {
      flex: 1 1 0;
    }
  }

  .score-container {
    width: 100%;
    position: absolute;
  }

  .score {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
    color: var(--rising-orange);
    margin-left: ${({ npsScore }) => `${0.5 * npsScore + 45}%`};
  }

  .score-label {
    margin-top: 10px;
    font-size: 0.813em;
  }

  .score-tick {
    height: 23px;
    width: 2px;
    border-radius: 1px;
    background: var(--rising-orange);
  }

  @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
    .score {
      width: 20%;
      margin-left: ${({ npsScore }) => `${0.5 * npsScore + 40}%`};
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .score {
      width: 20%;
      margin-left: ${({ npsScore }) => `${0.5 * npsScore + 40}%`};
    }
  }
`

const TickMark = styled(function TickMark({
  labels, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  label,
  index, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  className,
}) {
  return (
    <div className={cn("tick-mark-container", className)}>
      <div className="top-line"></div>
      <div className="tick mb"></div>
      <p className={cn("tick-label text-gray-8 text-thin")}>{label}</p>
    </div>
  )
})`
  .top-line {
    height: 2px;
    background-color: var(--gray-5);
  }

  .tick {
    width: 2px;
    height: 20px;
    border-radius: 1px;
    background-color: var(--gray-5);
    margin: ${({ labels, index }) =>
      index === 0 ? "-10px 0 0 0" : index === labels.length - 1 ? "-10px 0 0 auto" : "-10px auto 0 auto"};
  }

  .tick-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
`

export default NpsChart
