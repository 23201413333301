import { styled } from "styled-components"

const AppreciationsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>This kit's theme is: Appreciation. In these three brief training articles, we’ll cover:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>the definition of appreciation (versus recognition) and why it matters for your team</li>
          <li>how to identify and deliver on each person’s preferences for appreciation</li>
          <li>ideas to cultivate appreciation regularly with your team</li>
        </ul>
      </div>
      <div>To get started, let’s talk about the difference between appreciation and recognition.</div>
      <h3 className="text-rising-orange pt-large pb-xs">
        1. Appreciation is about who you are; recognition is about what you’ve done
      </h3>
      <div className="py-xs">
        You may hear the words recognition and appreciation both used to describe the same things at work, when in fact
        there is a subtle difference. Recognition is one subtype of appreciation—it’s linked to a positive or successful
        outcome—whereas appreciation is broader, inclusive of your effort, values, and behaviors. We use the term
        appreciation throughout this Kit and the team session because it is a more holistic concept.
      </div>
      <div className="py-xs">
        Recognition is inherently scarce, because there are only so many bonuses and awards to go around for specific
        results. Appreciation, meanwhile, is free, unlimited, and can come from anyone, including peers.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2019/11/why-employees-need-both-recognition-and-appreciation"
        >
          As leadership expert Mike Robbins writes
        </a>
        , appreciation “is about acknowledging a person’s inherent value. The point isn’t their accomplishments. It’s
        their worth as a colleague and a human being.” As human beings, we seek constant connection and validation from
        the people around us, and expressing appreciation for one another is one way we do this.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">2. Why is appreciation valuable?</h3>
      <div className="pb-xs">
        Research shows that people feel more engaged, more motivated, and less likely to quit when they feel appreciated
        by their manager. Researchers call this “prosocial” behavior, and{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://psycnet.apa.org/record/2010-09990-007">
          hypothesize
        </a>{" "}
        that it comes about because expressions of gratitude make us feel more confident and more socially valued. The
        stats are pretty mind blowing.
      </div>
      <h4 className="text-gray-9 py-xs">a) Appreciation is a more powerful motivator than fear</h4>
      <div className="py-xs">
        According to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.glassdoor.com/employers/blog/employers-to-retain-half-of-their-employees-longer-if-bosses-showed-more-appreciation-glassdoor-survey/"
        >
          Glassdoor’s Employee Appreciation Survey
        </a>
        , 81% of employees—that’s four out of five people!—say they’re motivated to work harder when their boss shows
        appreciation for their work. In that same survey, only 38% said they were motivated to work harder by a
        demanding boss, and 37% by fear of losing their job. If your goal is a highly engaged, motivated team, then
        focusing on the positive will get you much further than focusing on the negative.
      </div>
      <h4 className="text-gray-9 py-xs">b) Appreciation is a more powerful motivator than cash</h4>
      <div className="pt-small">
        <img
          src="https://static.risingteam.com/kit_assets/appreciations/kit-training-appreciations-1.1.png"
          alt="kit-training-why-appreciation-matters"
        />
      </div>
      <div className="py-xs">
        In a survey across the US, Germany, and India, the Cicero Group, a team performance advisory firm, asked
        employees which type of benefit would most improve their relationship with their manager: cash,recognition, or
        appreciation. As you can see, “ongoing effort” recognition—defined as informal, casual, and more frequent
        appreciation—is what employees want from their managers.
      </div>
      <h4 className="text-gray-9 py-xs">c) Your team needs more appreciation than you might think</h4>
      <div className="py-xs">
        When it comes to appreciation, more is more. Looking again at the Glassdoor survey linked above, 68% of survey
        respondents said they felt their boss showed them enough appreciation. At the same time, 53% said they would
        stay at their jobs longer if they felt more appreciation from their manager. How do we interpret this? Even when
        people feel some level of appreciation, our human desire to be seen and validated by others is a powerful
        motivator and we usually crave more of it. You can acknowledge this by sharing appreciation with your team more
        frequently than you may believe is needed.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">3. Sharing appreciation benefits the giver too </h3>
      <div className="pb-xs">
        It turns out that the act of sharing appreciation has a positive impact on the person giving it, in addition to
        the person receiving it. And this applies to your whole team, too.
      </div>
      <h4 className="text-gray-9 py-xs">a) Expressing gratitude makes us happier and healthier</h4>
      <div className="py-xs">
        In the past decade, research has revealed that the simple act of expressing gratitude can make us feel happier.
        For example, Dr. Martin Seligman, director of the Positive Psychology Center at the University of Pennsylvania,
        tested a variety of different positive psychology interventions{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier"
        >
          in a landmark study
        </a>{" "}
        of over 400 people. He compared the impact of each on people’s self-reported happiness. The factor that drove
        the largest impact was when he asked people to send a letter of gratitude to someone who had never been properly
        thanked for his or her kindness.
      </div>
      <div className="py-xs">
        These benefits have been demonstrated on a physical level, as well. The{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.psychologytoday.com/us/blog/prefrontal-nudity/201211/the-grateful-brain"
        >
          National Institutes of Health
        </a>{" "}
        used fMRI technology to examine brain activity in people experiencing a range of different sentiments, including
        gratitude. They found that feelings of gratitude were linked to higher levels of activity in the hypothalamus
        region of the brain, which influences a variety of essential bodily functions such as appetite, sleeping,
        metabolism, and stress levels.
      </div>
      <h4 className="text-gray-9 py-xs">b) Expressing gratitude lowers uncivil behavior</h4>
      <div className="py-xs">
        Researchers from the University of Central Florida hypothesized that gratitude could be an antidote to rude,
        uncivil, or clique-ish behavior between colleagues in the workplace. Indeed,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://hbr.org/2020/11/building-a-better-workplace-starts-with-saying-thanks"
        >
          their 2020 study
        </a>{" "}
        showed decreased rudeness among work colleagues who were asked to record the people and events at work they were
        grateful for as compared to people who were not. Gratitude makes us feel more open and optimistic to those
        around us, and this creates a positive ripple effect in how we treat our team members.
      </div>
      <h3 className="text-rising-orange pt-large pb-xs">4. Can I appreciate someone who’s not meeting their goals?</h3>
      <div className="py-xs">
        Yes, you can appreciate people while also holding them accountable to their goals. Remember that appreciation is
        different from recognition. It’s all about acknowledging people for their inherent value. When you think about
        it that way, you can see why you can still find ways to share things you appreciate about people even when
        you’re asking them to be accountable for agreed-upon goals. Appreciation should be given independently, and not
        be linked with negative feedback as a way to “soften the blow.”
      </div>
      <div className="py-xs">
        That’s a wrap on defining appreciation and why it matters. Now we’re ready to talk about different appreciation
        preferences.
      </div>
    </div>
  </div>
)

export default styled(AppreciationsTraining1)`
  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    .image-container {
      margin: var(--spacing-0);
    }
  }
`
