import BulletedList from "./BulletedList"
import Button from "./Button"
import HorizontalRule from "./HorizontalRule"
import NumberedList from "./NumberedList"
import TextContainer from "./TextContainer"
import Tooltip from "./Tooltip"
import View from "./View"

const blockUIComponents = {
  BulletedList,
  Button,
  HorizontalRule,
  NumberedList,
  TextContainer,
  Tooltip,
  View,
}

export default blockUIComponents
