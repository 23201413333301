import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const FiveSensesUserResults = ({ exercise, isSession, className }) => {
  const scent_description = getExerciseAnswer(exercise, "scent_description")
  const scent_image = getExerciseAnswer(exercise, "scent_image")
  const touch_description = getExerciseAnswer(exercise, "touch_description")
  const touch_image = getExerciseAnswer(exercise, "touch_image")
  const taste_description = getExerciseAnswer(exercise, "taste_description")
  const taste_image = getExerciseAnswer(exercise, "taste_image")
  const sight_description = getExerciseAnswer(exercise, "sight_description")
  const sight_image = getExerciseAnswer(exercise, "sight_image")
  const sound_description = getExerciseAnswer(exercise, "sound_description")
  const wrap_up_description = getExerciseAnswer(exercise, "wrap_up_description")

  return (
    <View className={cn("text-gray-9", className)}>
      <TextContainer maxWidth={556}>
        <SenseAnswerDisplay
          senseType="Scent"
          senseDescription="This smell brings back strong memories"
          answerDescription={scent_description}
          answerImage={scent_image}
        />
        <SenseAnswerDisplay
          senseType="Touch"
          senseDescription="This texture or tactile experience brings up a specific feeling or memory"
          answerDescription={touch_description}
          answerImage={touch_image}
        />
        <SenseAnswerDisplay
          senseType="Taste"
          senseDescription="This flavor brings back good memories"
          answerDescription={taste_description}
          answerImage={taste_image}
        />
        <SenseAnswerDisplay
          senseType="Sight"
          senseDescription="The sight of this makes me feel hopeful"
          answerDescription={sight_description}
          answerImage={sight_image}
        />
        <h2 className="text-gray-9 mb-small">Sound</h2>
        <p className="text-field-label mb-small">This sound reminds me of a certain part of my life</p>
        {!!isSession && (
          <>
            <p className="text-gray-8 mb-small">Play the sound and describe what it means to you.</p>
            <p className="text-gray-8 mb-small">
              Tip: if you are using Zoom, first share your screen and select the "Share sound" checkbox at the bottom of
              that page.
            </p>
          </>
        )}
        <AnswerDisplayContent answer={sound_description} />
        <h2 className="text-gray-9 mb-small">Wrap-up</h2>
        <p className="text-field-label mb-small">A pattern or insight I recognized based on this exercise</p>
        <AnswerDisplayContent answer={wrap_up_description} />
      </TextContainer>
    </View>
  )
}

const SenseAnswerDisplay = styled(function SenseAnswerDisplay({
  senseType,
  senseDescription,
  answerDescription,
  answerImage,
  className,
}) {
  return (
    <>
      <div className={className}>
        <h2 className="text-gray-9 mb-small">{senseType}</h2>
        <p className="text-field-label">{senseDescription}</p>
        <AnswerDisplayContent className="mb-large" answer={answerDescription} />
        {!!answerImage && (
          <div className="exercise-picture mb-large">
            <img src={answerImage} alt={answerDescription} />
          </div>
        )}
      </div>
    </>
  )
})`
  .exercise-picture {
    max-width: 377px;

    img {
      object-fit: contain;
      border-radius: 8px;
      max-width: 377px;
      max-height: 377px;
    }
  }
`

export default FiveSensesUserResults
