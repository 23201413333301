const BOHOperationalExcellenceTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        Forward Together is the only way truly great things happen. People join together, harness their energies and
        collective hopes and move forward to make life better.
      </p>
      <div className="py-xs ml-xl">
        <ul className="mb-medium">
          <li>For our customers we want to provide assurance that however they need us, they can count on us.</li>
          <li>
            For our employees we want to create a workplace that leads to professional success and personal well-being.
          </li>
          <li>For our community we want to create a more vibrant and sustainable island home.</li>
        </ul>
      </div>
      <p className="mb-medium">
        At Bank of Hawaii, to meet the challenges of today we focus on our Pillars of Success which are Customer
        Experience (CX), Employee Experience (EX) and Operational Excellence (OX).
      </p>
      <p className="mb-medium">
        Operational Excellence (OX) is about optimizing the way we work and investing for the future. How do we reshape
        how we work so we gain productivity and optimize our resources? How do we strengthen and simplify business
        processes and leverage data to drive continuous improvement? Most importantly, how do we drive to change – gain
        commitment and champion continuous improvement efforts?
      </p>
      <p className="mb-medium">
        We believe OX is an enabler of excellence, CX and EX, and we all own OX at all levels of the organization. A
        culture focused on OX delivers benefits now and in the future. So what is our desired outcome? We need to
        transform business operations through enhanced CX/EX/OX. We want to increase efficiency, reduce expense and
        deliver automation and reduce manual processes and increase resilience.
      </p>
      <p className="mb-medium">
        This is the first step in the journey. Moving ahead we must continue to live our value of innovation. We will do
        this by broadening the OX scope and impact to our organization.
      </p>
      <p className="mb-medium">
        We need to deliver meaningful results through aligned priorities and collaborative execution.
      </p>
      <p className="mb-medium">
        The OX Kit is designed to start your thinking about who your customers are and what the operational
        opportunities are to improve their experience. What are the pain points in the process and what needs to happen
        next? Your Operational Excellence team will be available for consultation on your next steps.
      </p>
    </div>
  </div>
)

export default BOHOperationalExcellenceTraining1
