import cn from "classnames"
import { styled } from "styled-components"

import View, { type ViewProps } from "ui/View"

interface FooterProps extends ViewProps {
  className?: string
}

const Footer = ({ className, ...props }: FooterProps = {}) => {
  const currentYear = new Date().getFullYear()

  return (
    <View
      as="main"
      className={cn(className, "mt-xxl footer")}
      $flexDirection="column"
      $justifyContent="center"
      $alignItems="center"
      {...props}
    >
      <div className="mb-xs">© {currentYear} Rising Team</div>
      <ul>
        <li>
          <a href="https://risingteam.com/faq" target="_blank" rel="noopener noreferrer">
            FAQ
          </a>
        </li>
        <li>
          <a href="https://risingteam.com/contact" target="_blank" rel="noopener noreferrer">
            Contact Us
          </a>
        </li>
        <li>
          <a href="https://risingteam.com/terms" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
        </li>
        <li>
          <a href="https://risingteam.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy
          </a>
        </li>
      </ul>
    </View>
  )
}

export default styled(Footer)`
  padding: var(--spacing-3);
  padding-top: 0;
  font-size: 13px;

  ul li {
    display: inline-flex;

    a {
      color: #939da7;

      &:hover,
      &:focus-visible {
        color: #636e79;
      }
    }

    &::after {
      content: "|";
      color: var(--gray-5);
      padding: 0 var(--spacing-2);

      @media (max-width: ${({ theme }) => theme.mobileMax}) {
        padding: 0 var(--spacing-0);
      }
    }

    &:last-child::after {
      display: none;
    }
  }
`

export type { FooterProps }
