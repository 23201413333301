import TextareaField from "forms/fields/TextareaField"

const ExerciseTextareaField = ({
  // Custom props can't be passed to a standard html element
  full_exercise_only: _fullExerciseOnly,
  partial_exercise_only: _partialExerciseOnly,
  initialValue: _initialValue,
  name,
  ...props
}) => <TextareaField name={`${name}.value`} {...props} />

export default ExerciseTextareaField
