import { useMemo } from "react"
import ReactWordcloud, { MinMaxPair, Word } from "react-wordcloud"
import { styled } from "styled-components"

import theme from "ui/theme"
import { plural } from "utils/string"

const WordCloud = styled(function WordCloud({
  wcWords,
  minFontSize,
  maxFontSize,
  height: _height,
  className,
}: {
  wcWords: Word[]
  minFontSize: number
  maxFontSize: number
  height?: string
  className: string
}) {
  const options = useMemo(
    () => ({
      deterministic: true,
      enableTooltip: false,
      rotations: 1,
      rotationAngles: [0, 0] as MinMaxPair,
      fontSizes: [minFontSize, maxFontSize] as MinMaxPair,
      fontFamily: theme.normalFontFamily,
      fontWeight: "bold",
      colors: ["var(--rising-blue)", "var(--green-2)", "var(--orange-2)", "var(--yellow-2)"],
    }),
    [minFontSize, maxFontSize]
  )

  const sortedTopWcWords = wcWords.sort((a, b) => b.value - a.value).slice(0, 8)
  const topWordsText = sortedTopWcWords
    .map(({ text, value }) => `${text}, appeared ${plural(value, "time")}`)
    .join(". ")
  const ariaLabel = `Top phrases: ${topWordsText}`

  return (
    <div className={className} aria-label={ariaLabel}>
      <ReactWordcloud aria-hidden options={options} words={wcWords} />
    </div>
  )
})`
  ${({ height }) => (height ? `height: ${height};` : "")};
`

export default WordCloud
