import { createContext, useContext } from "react"

import { AccountType } from "components/AccountTypeContext"

const BillingDataContext = createContext({
  billingData: {},
  setBillingData: () => {},
})

const useBillingData = () => useContext(BillingDataContext) || {}

const getBillingData = ({ subscription, quantity, queryParams }) => {
  const {
    kitSlug,
    free_trial,
    non_profit,
    select_num_teams,
    gclid,
    fbclid,
    referral_code,
    gratitude_promo,
    account_type,
    pc_id,
    customBilling,
    productTier,
  } = queryParams

  // if the subscription is specified but not the account_type, then the account_type is assumed to be STANDARD
  const accountType = account_type ?? (subscription ? AccountType.STANDARD : null)

  const billingData = {
    subscriptionType: subscription,
    quantity: parseInt(quantity),
    kitSlug,
    accountType,
    gclid,
    fbclid,
    promotionCodeId: pc_id,
    customBilling,
    productTier,
    referralCode: referral_code,
  }

  // The following undefined checks are only for booleans to make it still turn on the flag even if there is no value.
  if (typeof free_trial !== "undefined") {
    billingData["freeTrial"] = true
  }

  if (typeof non_profit !== "undefined") {
    billingData["nonProfit"] = true
  }

  if (typeof select_num_teams !== "undefined") {
    billingData["selectNumTeams"] = true
  }

  if (typeof gratitude_promo !== "undefined") {
    billingData["gratitudePromo"] = true
  }

  return billingData
}

export { BillingDataContext, useBillingData, getBillingData }
