function duration(seconds) {
  const output = []
  let remainder = seconds
  const durations = [
    // Number of seconds in
    60 * 60, // a hour
    1 * 60, // a minute
  ]
  durations.forEach((divisor, index) => {
    const quotient = Math.abs(parseInt(`${remainder / divisor}`))
    remainder = Math.abs(remainder % divisor)
    output.push(quotient)
    if (index === durations.length - 1) {
      output.push(remainder)
    }
  })
  return output.reverse()
}

function pad(value) {
  let s = String(value)
  while (s.length < 2) {
    s = "0" + s
  }
  return s
}

function positiveIntOrZero(val) {
  return isNaN(val) || val < 0 ? 0 : val
}

const Duration = ({ children, showHours = true, showMinutes = true, showSeconds = true, ...props }) => {
  const secs = positiveIntOrZero(parseInt(children))
  const [seconds, minutes, hours] = duration(secs)
  return (
    <span {...props}>
      {!!showHours && <span className="unit">{hours}</span>}
      {!!showHours && !!showMinutes && <span className="separator">:</span>}
      {!!showMinutes && <span className="unit">{showHours ? pad(minutes) : minutes}</span>}
      {!!showMinutes && !!showSeconds && <span className="separator">:</span>}
      {!!showSeconds && <span className="unit">{showMinutes ? pad(seconds) : seconds}</span>}
    </span>
  )
}

export default Duration

export { positiveIntOrZero }
