import cn from "classnames"
import { useRef } from "react"
import { styled } from "styled-components"

import EmailField from "forms/fields/EmailField"
import TextField from "forms/fields/TextField"
import Yup from "forms/yup"
import { CheckIcon, XmarkIcon } from "icons/FontAwesomeIcons"

const emailSchema = Yup.object().shape({
  value: Yup.string().email(),
})

const textSchema = Yup.object().shape({
  value: Yup.string(),
})

const ValidatedField = ({
  className,
  base,
  value,
  onClear,
  onSubmitConfirmedMessage = "Sent",
  onChange = null,
  validator = null,
  placeholder = null,
  width = null, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  disabled = false,
  ...props
}) => {
  if (!onClear) {
    throw new Error(
      "ValidatedField: You should pass an onClear prop which is a function that" +
        " clears appropriate Formik form values when X icon is clicked in the input."
    )
  }

  const BaseField = base ?? TextField // default field type: text
  if (BaseField === EmailField) {
    // defaults for email field type:
    validator = validator ?? ((value) => emailSchema.isValidSync({ value }))
    placeholder = placeholder ?? "name@domain.com"
  } else {
    validator = validator ?? ((value) => textSchema.isValidSync({ value }))
  }

  const valid = validator(value)
  const empty = !value?.length
  const inputRef = useRef()

  return (
    <div className={cn(className, "fit-content", { valid, empty, disabled })}>
      <BaseField
        inputRef={inputRef}
        placeholder={placeholder}
        onChange={!!onChange && (() => onChange(inputRef.current.value))}
        disabled={disabled}
        {...props}
      />
      <div className="icon-container">
        <XmarkIcon
          className="clear-icon text-gray-5 fa-xl"
          onClick={() => {
            inputRef.current.value = null
            inputRef.current.focus()
            onClear()
          }}
        />
        {!!(disabled && value && onSubmitConfirmedMessage) && (
          <div className="submit-confirmed-text text-rising-green text-semi-bold">{onSubmitConfirmedMessage}</div>
        )}
        <CheckIcon className="valid-icon text-rising-green fa-xl" />
      </div>
    </div>
  )
}

export default styled(ValidatedField)`
  position: relative;

  input {
    padding-right: 38px;
    width: ${({ width }) => (width ? `${width}px;` : "100%")};
  }

  .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 44px; // match input height
  }

  .submit-confirmed-text {
    position: absolute;
    top: calc(50% - 13px);
    right: 2rem;
    pointer-events: none;
    background: linear-gradient(to left, var(--gray-2), var(--gray-2) 60%, transparent);
    padding-left: 2rem;
  }

  .valid-icon,
  .clear-icon {
    width: 16px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%); // center horizontal+vertical in container
    opacity: 1;
    transition: opacity 0.1s ease;
  }

  .clear-icon {
    opacity: 0;
    pointer-events: none;
  }

  &:not(.valid),
  &.empty {
    .valid-icon {
      opacity: 0;
      pointer-events: none;
    }
  }

  &:not(.disabled) {
    cursor: pointer;

    .icon-container:hover {
      .clear-icon {
        opacity: 1;
        pointer-events: initial;
      }

      .valid-icon {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
`
