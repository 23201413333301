import cn from "classnames"
import { styled } from "styled-components"

import Tooltip from "./Tooltip"
import View from "./View"

import { TrophyIcon } from "icons/FontAwesomeIcons"

const baseHeight = {
  first: 80,
  second: 44,
  third: 24,
}

const Podium = styled(function Podium({ topScorers, className }) {
  const firstPlace = topScorers[0]?.name
  const secondPlace = topScorers.length > 1 ? topScorers[1]?.name : ""
  const thirdPlace = topScorers.length > 2 ? topScorers[2]?.name : ""
  return (
    <View $alignItems="flex-end" className={cn(className, "my-medium")} $justifyContent="center">
      <div className="podium-item mr-xxs">
        {!!secondPlace && (
          <Tooltip top float title={secondPlace} className="podium-tooltip">
            <p className="podium-user mb-xxs text-white">{secondPlace?.charAt(0)}</p>
          </Tooltip>
        )}
        <div className="second-place bg-green-3" />
      </div>
      <div className="podium-item mr-xxs">
        {!!firstPlace && (
          <Tooltip top float title={firstPlace} className="podium-tooltip">
            <p className="podium-user mb-xxs text-white">{firstPlace?.charAt(0)}</p>
          </Tooltip>
        )}
        <View $alignItems="center" $justifyContent="center" className="first-place bg-green-4 text-white">
          <TrophyIcon className="fa-2xl" color="var(--white)" />
        </View>
      </div>
      <div className="podium-item mr-xxs">
        {!!thirdPlace && (
          <Tooltip top float title={thirdPlace} className="podium-tooltip">
            <p className="podium-user mb-xxs text-white">{thirdPlace?.charAt(0)}</p>
          </Tooltip>
        )}
        <div className="third-place bg-green-2" />
      </div>
    </View>
  )
})`
  .podium-item {
    width: 64px;
  }

  .podium-tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .podium-user {
    background-color: var(--orange-3);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    text-transform: capitalize;
  }

  .first-place {
    min-height: ${baseHeight.first}px;
    max-height: ${baseHeight.first}px;
  }

  .second-place {
    min-height: ${baseHeight.second}px;
    max-height: ${baseHeight.second}px;
  }

  .third-place {
    min-height: ${baseHeight.third}px;
    max-height: ${baseHeight.third}px;
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    .first-place {
      min-height: ${baseHeight.first * 2}px;
      max-height: ${baseHeight.first * 2}px;
    }

    .second-place {
      min-height: ${baseHeight.second * 2}px;
      max-height: ${baseHeight.second * 2}px;
    }

    .third-place {
      min-height: ${baseHeight.third * 2}px;
      max-height: ${baseHeight.third * 2}px;
    }

    .podium-item {
      width: 80px;
    }
  }

  @media (min-width: ${({ theme }) => theme.desktopMin}) {
    .first-place {
      min-height: ${baseHeight.first * 2}px;
      max-height: ${baseHeight.first * 2}px;
    }

    .second-place {
      min-height: ${baseHeight.second * 2}px;
      max-height: ${baseHeight.second * 2}px;
    }

    .third-place {
      min-height: ${baseHeight.third * 2}px;
      max-height: ${baseHeight.third * 2}px;
    }

    .podium-item {
      width: 100px;
    }
  }
`

export default Podium
