import { styled } from "styled-components"

import Button from "./Button"
import useAudio from "./hooks/useAudio"

import { MusicIcon, PauseIcon } from "icons/FontAwesomeIcons"

const SmallAudioPlayer = ({ src, volume = 0.3, className }) => {
  const { audio, paused, togglePlay } = useAudio({ volume })

  return (
    <div className={className}>
      <audio src={src} preload="metadata" ref={audio} hidden />
      {!!audio.current && (
        <Button onClick={togglePlay} className="tertiary">
          {paused ? (
            <>
              <MusicIcon className="fa-xl" />
              Play music
            </>
          ) : (
            <>
              <PauseIcon className="fa-xl" />
              Pause music
            </>
          )}
        </Button>
      )}
    </div>
  )
}

export default styled(SmallAudioPlayer)`
  display: flex;
  justify-content: inherit;
`
