import { ExerciseComponent } from "./ExerciseComponents"

export default function ExerciseContent({
  kit,
  components,
  saveOnChange,
  is_full_exercise,
  is_standalone,
  is_session,
  className,
}) {
  const visibleComponents = components.filter(
    ({ full_exercise_only, partial_exercise_only, session_only, standalone_only }) => {
      if (session_only) {
        return is_session
      }
      if (standalone_only) {
        return is_standalone
      }
      if (full_exercise_only) {
        return is_full_exercise
      }
      if (partial_exercise_only) {
        return !is_full_exercise
      }

      return true
    }
  )
  const visibleComponentCount = visibleComponents.length

  return (
    <div className={className}>
      {visibleComponents.map(({ component, identifier, sentence, containerClassName, ...props }, idx) => {
        const isLast = idx + 1 === visibleComponentCount
        return (
          <ExerciseComponent
            key={identifier}
            component={component}
            identifier={identifier}
            sentence={sentence}
            kit={kit}
            saveOnChange={saveOnChange}
            containerClassName={containerClassName ?? (isLast ? null : "mb-xl")}
            {...props}
          />
        )
      })}
    </div>
  )
}
