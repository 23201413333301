import { useUser } from "resources/users"
import { useSelectedUser } from "ui/SelectedUserContext"

const IfMeIsSelectedUser = ({ children }) => {
  const { data: user } = useUser({ userId: "me" })
  const { selectedUser } = useSelectedUser()

  return <>{!!(user && selectedUser && user.id === selectedUser.id) && children}</>
}

export default IfMeIsSelectedUser
