import { styled } from "styled-components"

import Button from "ui/Button"
import SoundBoardButton from "ui/SoundBoardButton"
import View from "ui/View"

const SoundBoard = styled(function SoundBoard({ className, kit }) {
  return (
    <div className={className}>
      <div className="text-gray-9 mt-xl mb-xs">
        <h2>The Sounds of Appreciation</h2>
      </div>
      <div className="description-wrapper mb-large">
        <div>{kit.bonus_page.sound_header_desc}</div>
      </div>
      <View className="soundboard-grid" $flexDirection="row">
        {kit.sound_list.map(({ title, normalState, pressedState, sound }) => (
          <SoundBoardButton
            key={title}
            title={title}
            normalStateUrl={normalState}
            pressedStateUrl={pressedState}
            soundUrl={sound}
          />
        ))}
      </View>
      <a
        href={`https://static.risingteam.com/kit_assets/${kit.bonus_page.sound_download_slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="tertiary mb-xl mt-large">Download Sounds</Button>
      </a>
    </div>
  )
})`
  .soundboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    gap: var(--spacing-0);
  }
`

export default SoundBoard
