import { useEffect } from "react"

const NOT_PASSED = Symbol() // guaranteed to not match any other value

export default function useEffectOnComponentUnmount(effectFunc, dependencyArray = NOT_PASSED) {
  if (dependencyArray !== NOT_PASSED) {
    throw new Error(
      "useEffectOnComponentUnmount: Never pass a dependency array as the 2nd argument." +
        "\nINCORRECT:  useEffectOnComponentUnmount(() => { ... }, <anything> )" +
        "\n  CORRECT:  useEffectOnComponentUnmount(() => { ... })"
    )
  }
  // eslint-disable-next-line no-restricted-syntax
  useEffect(() => {
    const runOnUnmount = effectFunc
    return runOnUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
