import { getExerciseAnswer } from "../results_utils"
import AnswerDisplayIconBox from "../ResultsComponents/AnswerDisplayIconBox"

import getIconOrError from "icons"

const RadioGroupIconButtons = ({ identifier, exerciseComponent, exerciseInstance }) => {
  const choiceOptions = exerciseComponent.options
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []
  const answers = choiceOptions.map(({ short_label, value, icon }) => {
    const Icon = getIconOrError(icon)
    return { title: short_label, icon: Icon, value: userSelections.includes(value) }
  })

  return <AnswerDisplayIconBox answers={answers} className="mb-xl" />
}

export default RadioGroupIconButtons
