import cn from "classnames"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const KeepLetGoUserResults = ({ exercise, maxWidth, className }) => {
  const keep_habit_or_behavior = getExerciseAnswer(exercise, "keep_habit_or_behavior")
  const keep_habit_is_beneficial = getExerciseAnswer(exercise, "keep_habit_is_beneficial")
  const keep_team_support = getExerciseAnswer(exercise, "keep_team_support")
  const let_go_habit_or_behavior = getExerciseAnswer(exercise, "let_go_habit_or_behavior")
  const let_go_why = getExerciseAnswer(exercise, "let_go_why")
  const let_go_team_support = getExerciseAnswer(exercise, "let_go_team_support")

  return (
    <View className={cn("text-gray-9", className)}>
      <TextContainer maxWidth={maxWidth} className="full-width">
        <h2 className="text-semi-bold mb-large">What to keep</h2>
        <AnswerDisplayContent title="The habit or behavior" answer={keep_habit_or_behavior} />
        <AnswerDisplayContent title="Why this habit is beneficial" answer={keep_habit_is_beneficial} />
        <AnswerDisplayContent title="How the team can support me" answer={keep_team_support} />
        <h2 className="text-semi-bold mt-xxl mb-large">What to let go</h2>
        <AnswerDisplayContent title="The habit or behavior" answer={let_go_habit_or_behavior} />
        <AnswerDisplayContent title="Why this habit isn’t helpful" answer={let_go_why} />
        <AnswerDisplayContent title="How the team can support me" answer={let_go_team_support} />
      </TextContainer>
    </View>
  )
}

export default KeepLetGoUserResults
