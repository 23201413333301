import cn from "classnames"

import { ArtiSender } from "../constants"

const ArtiChatMessage = ({ sender, children, className }) => (
  <div className={className}>
    {sender === ArtiSender.BOT ? (
      <div className="mb-xxs text-semi-bold">
        <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
        <span className="ml-xxs">aRTi</span>
      </div>
    ) : (
      <div className="text-right mb-xxs text-semi-bold">you</div>
    )}
    <div className={cn("mb-medium px-medium py-small message", sender)}>{children}</div>
  </div>
)

export default ArtiChatMessage
