import { styled } from "styled-components"

function TalentBucket({ bucket, className }) {
  return bucket ? <div className={className}>{bucket}</div> : null
}

export default styled(TalentBucket)`
  display: inline-block;
  background-color: var(--gray-1);
  border: 1px solid var(--rising-orange-dark);
  border-radius: 4px;
  color: var(--rising-orange-dark);
  font-size: 0.8125rem;
  line-height: 1rem;
  padding: 4px 8px;
`
