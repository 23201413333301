import { useQueryClient } from "@tanstack/react-query"
import { formatDuration, intervalToDuration, parseISO } from "date-fns"
import { useState } from "react"
import { useInterval } from "use-interval"

import { refetchAccountOffers } from "resources/billing"

const OfferExpiration = ({ accountOffers, className }) => {
  const expirationDate =
    accountOffers.time_limited_offer &&
    accountOffers.offer_expiration_date &&
    parseISO(accountOffers.offer_expiration_date)

  if (!expirationDate) {
    return null
  }

  const duration = intervalToDuration({
    start: new Date(),
    end: expirationDate,
  })

  const days = duration.days + (duration.hours > 12 ? 1 : 0)
  if (days > 1) {
    return (
      <p className={className}>
        This offer will expire <span className="text-bold">in {days} days.</span>
      </p>
    )
  }

  if (duration.days > 0 || duration.hours > 1) {
    const formattedDuration = formatDuration(duration, { format: ["days", "hours"] })
    return <p className={className}>This offer will expire in {formattedDuration}.</p>
  }

  return <OfferExpirationCountdown expirationDate={expirationDate} className={className} />
}

const OfferExpirationCountdown = ({ expirationDate, className }) => {
  const queryClient = useQueryClient()
  const [now, setNow] = useState(Date.now())
  const updateExpiration = () => {
    if (expirationDate < Date.now()) {
      refetchAccountOffers(queryClient)
    } else {
      setNow(Date.now())
    }
  }
  useInterval(updateExpiration, 10000)

  const duration = intervalToDuration({
    start: now,
    end: expirationDate,
  })

  if (duration.hours === 0 && duration.minutes === 0) {
    return <p className={className}>This offer will expire in 0 minutes.</p>
  }

  const formattedDuration = formatDuration(duration, { format: ["hours", "minutes"] })
  return <p className={className}>This offer will expire in {formattedDuration}.</p>
}

export default OfferExpiration
