import { Route, Routes } from "react-router-dom"

import AdminHome from "./AdminHome"
import CancelSubscription from "./CancelSubscription"

import { SimpleRouteLayout } from "domains/Authentication/Routes"

const AdminLayout = () => (
  <div className="main-container full-width">
    <SimpleRouteLayout title="Admin" />
  </div>
)

const AdminIndex = () => (
  <Routes>
    <Route element={<AdminLayout />}>
      <Route path="/:accountId/cancel" element={<CancelSubscription />} />
      <Route path="*" element={<AdminHome />} />
    </Route>
  </Routes>
)

export default AdminIndex
