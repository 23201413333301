import cn from "classnames"
import { styled } from "styled-components"

import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { DumbbellIcon, HeartPulseIcon, LightbulbOnIcon, ListIcon } from "icons/FontAwesomeIcons"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const KeepLetGoIntroKickoffStep = styled(function KeepLetGoIntroKickoffStep({ className }) {
  return (
    <StepContent className={cn(className)}>
      <SidebarContainer>
        <TextContainer className="mr-xl">
          <p className="text-gray-9 mb-medium">
            The purpose of today’s session is to take a little break, connect, and get to know each other a little
            better.
          </p>
          <h3 className="text-gray-9 mb-xxl">Agenda</h3>
          <View className="agenda" $flexDirectionMobile="column">
            <div className="icon-text-container">
              <ListIcon color="var(--orange-3)" className="fa-2x fa-fw pb-medium icon" />
              <p className="text-gray-9">Ground rules</p>
            </div>
            <div className="icon-text-container">
              <DumbbellIcon color="var(--rising-blue)" className="fa-2x fa-fw pb-medium icon" />
              <p className="text-gray-9">Warmup exercise</p>
            </div>
            <div className="icon-text-container">
              <HeartPulseIcon color="var(--rising-green)" className="fa-2x fa-fw pb-medium icon" />
              <p className="text-gray-9">Keep / Let Go activity</p>
            </div>
            <div className="icon-text-container">
              <LightbulbOnIcon color="var(--rising-yellow)" className="fa-2x fa-fw pb-medium icon" />
              <p className="text-gray-9">Session wrap-up</p>
            </div>
          </View>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
    </StepContent>
  )
})`
  .icon-text-container {
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-5);
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: 2px dashed var(--gray-5);
  }

  .agenda div:last-child {
    border-right: none;
    padding: 0 0 0 var(--spacing-5);
    margin-right: -24px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    h3 {
      margin-bottom: var(--spacing-3);
    }

    .icon-text-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-right: none;
      text-align: flex-start;
      padding: 0 0 var(--spacing-3) 0;
    }

    .agenda div:last-child {
      padding: 0 0 var(--spacing-7) 0;
      text-align: left;
    }

    .icon {
      font-size: 1.5rem;
      padding: 0 var(--spacing-2) 0 0;
    }
  }
`

export default KeepLetGoIntroKickoffStep
