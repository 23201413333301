import { Route, Routes } from "react-router"

import DemoHome from "./DemoHome"

import { SimpleRouteLayout } from "domains/Authentication/Routes"

const Demo = () => (
  <Routes>
    <Route element={<SimpleRouteLayout title="Demo" />}>
      <Route path="*" element={<DemoHome />} />
    </Route>
  </Routes>
)

export default Demo
