import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import SessionPreviewMessage from "domains/LeaderKit/SessionPreviewMessage"
import { useStartKitSession } from "resources/monthly_kit"
import Button from "ui/Button"

const PreviewSessionStartButton = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { mutateAsync: startKitSession } = useStartKitSession()
  const navigate = useNavigate()

  const onSessionStartClick = async () => {
    await startKitSession({ kitInstanceId: kitInstance.id })
    navigate(kitInstance.session_url)
  }

  return (
    <div className={className}>
      <SessionPreviewMessage>
        This is Preview Mode, if you want to start a team session,{" "}
        {team.preview ? (
          <span>
            first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
          </span>
        ) : (
          <Button className="link text-button inline-block" onClick={onSessionStartClick}>
            click here
          </Button>
        )}
      </SessionPreviewMessage>
    </div>
  )
}

export default styled(PreviewSessionStartButton)`
  display: flex;
  flex-direction: column;
`
