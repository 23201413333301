function SvgStripesBandIcon(props) {
  return (
    <svg width="5.57em" height="5em" viewBox="0 0 78 70" fill="none" {...props}>
      <path d="M78 0 L15 18 L15 39 L78 21" fill="#5CCF68" opacity="0.8" />
      <path d="M78 19 L0 41 L0 62 L78 40" fill="#FFD257" opacity="0.8" />
      <path d="M78 33 L23 49 L23 70 L78 54" fill="#FF9F0A" opacity="0.8" />
    </svg>
  )
}

export default SvgStripesBandIcon
