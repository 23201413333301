import { shuffle } from "lodash-es"

import SelectField from "./SelectField"

const pronouns = shuffle([
  { value: "he", label: "he/him/his" },
  { value: "she", label: "she/her/hers" },
  { value: "they", label: "they/them/their" },
])

const PreferredPronounsField = (props) => (
  <SelectField {...props}>
    <option value="" />
    {pronouns.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ))}
    <option value="not_disclosed">Prefer not to say</option>
  </SelectField>
)

export default PreferredPronounsField
