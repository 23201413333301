import cn from "classnames"
import { styled } from "styled-components"

import Button from "./Button"
import Duration from "./Duration"
import useAudio from "./hooks/useAudio"
import InputSeekSlider from "./InputSeekSlider"
import Menu from "./Menu"
import View from "./View"

import { PauseIcon, PlayIcon, CogIcon } from "icons/FontAwesomeIcons"
import { colors } from "ui/theme"

const AudioPlayer = ({ src, children, className }) => {
  const {
    audio,
    duration,
    paused,
    togglePlay,
    currentTime,
    setCurrentTime,
    allPlaybackRates,
    playbackRate,
    setPlaybackRate,
  } = useAudio()

  const onSliderInput = (evt) => {
    setCurrentTime(evt.target.value, true)
  }
  const onSliderChange = (evt) => {
    setCurrentTime(evt.target.value)
  }
  return (
    <>
      <audio src={src} preload="metadata" ref={audio} hidden />
      {!!audio.current && (
        <View className={className} $flexDirection="column">
          {children}
          <View className="mb-large" $justifyContent="space-between" $alignItems="center">
            <Button onClick={togglePlay} className="tertiary">
              {paused ? (
                <>
                  <PlayIcon />
                  Listen
                </>
              ) : (
                <>
                  <PauseIcon />
                  Pause
                </>
              )}
            </Button>
            <View $width="auto" $alignItems="center">
              <Duration showHours={false} className="text-gray-8 duration-text">
                {duration - (currentTime || 0)}
              </Duration>
              <Menu className="text-small">
                <Menu.Button as={Button} className="tertiary text-medium ml-medium">
                  <CogIcon color="var(--gray-8)" />
                </Menu.Button>
                <View>
                  <Menu.Items minWidth={100}>
                    <Menu.Item>
                      <div className="text-center">Speed</div>
                    </Menu.Item>
                    {allPlaybackRates.map((speed) => (
                      <Menu.Item key={speed.toString()}>
                        <Button
                          className={cn("full-width", { tertiary: playbackRate !== speed })}
                          color={colors.risingBlue}
                          onClick={() => setPlaybackRate(speed)}
                        >
                          <div
                            className={cn("text-small", {
                              "text-gray-9": playbackRate !== speed,
                            })}
                          >
                            {speed}x
                          </div>
                        </Button>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </View>
              </Menu>
            </View>
          </View>
          <InputSeekSlider
            max={Math.floor(duration)}
            value={currentTime}
            onChange={onSliderChange}
            onInput={onSliderInput}
          />
        </View>
      )}
    </>
  )
}

export default styled(AudioPlayer)`
  .duration-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
