/**
 * A polyfill for `ReadableStream.protototype[Symbol.asyncIterator]`,
 * aligning as closely as possible to the specification.
 *
 * @see https://streams.spec.whatwg.org/#rs-asynciterator
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream#async_iteration
 *
 * Copied from https://gist.github.com/MattiasBuelens/496fc1d37adb50a733edd43853f2f60e
 *
 * Currently Chrome does not support this so we need to polyfill it
 * Chrome issue: https://issues.chromium.org/issues/40612900
 */
function readableStreamValues(this: ReadableStream<any>, { preventCancel = false } = {}) {
  const reader = this.getReader()
  return {
    async next() {
      try {
        const result = await reader.read()
        if (result.done) {
          reader.releaseLock()
        }
        return result
      } catch (e) {
        reader.releaseLock()
        throw e
      }
    },
    async return(value: unknown) {
      if (!preventCancel) {
        const cancelPromise = reader.cancel(value)
        reader.releaseLock()
        await cancelPromise
      } else {
        reader.releaseLock()
      }
      return { done: true, value }
    },
    [Symbol.asyncIterator]() {
      return this
    },
  }
}

;(ReadableStream.prototype as unknown as { values: typeof readableStreamValues }).values ??= readableStreamValues as any
;(ReadableStream.prototype as unknown as { [Symbol.asyncIterator]: typeof readableStreamValues })[
  Symbol.asyncIterator
] ??= readableStreamValues
