import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router"

import { completedCheckoutSession } from "resources/billing"
import useEffectAfterEveryRender from "ui/hooks/useEffectAfterEveryRender"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"

const CompleteCheckoutSession = () => {
  const { isFetching: _, ...features } = useFeatures()
  const queryClient = useQueryClient()
  const query = useQueryParams()
  const { checkout_session_id, free_trial, from_demo } = query
  const freeTrial = typeof free_trial !== "undefined"
  const navigate = useNavigate()
  const freeTrialQueryParamSuffix = freeTrial ? "free_trial" : ""

  const onLoad = async () => {
    if (!checkout_session_id) {
      navigate("/")
      return
    }

    await completedCheckoutSession(checkout_session_id, queryClient)

    if (from_demo) {
      navigate("/get-started/create-account/onboarding-from-demo")
    } else {
      // TODO(onboarding-launch): remove if statements below, leave only "navigate('/')"
      // TODO(onboarding-launch): do we want to do anything special in the free-trial case here?
      if (Object.keys(features).length) {
        // Wait for feature flags to load before any navigate here
        if (features[FLAGS.RTDEV_ONBOARDING]) {
          navigate("/")
        } else {
          navigate(`/get-started/create-account/welcome/?${freeTrialQueryParamSuffix}`)
        }
      }
    }
  }

  useEffectAfterEveryRender(() => {
    onLoad()
  })

  return <Loading />
}

export default CompleteCheckoutSession
