const CareerHorizonsTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-large">
      <p className="mb-large">
        After your Rising Team session on Career Horizons, here's how to continue supporting your team.
      </p>
      <h2 className="text-rising-orange mb-medium">1. Build skills through experiences</h2>
      <p className="mb-medium">
        A popular and time-tested guideline for developing others is{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ccl.org/articles/leading-effectively-articles/70-20-10-rule/"
        >
          the 70-20-10 rule
        </a>{" "}
        research from the Center for Creative Leadership, this model highlights that our professional growth comes from
        3 types of experiences following a ratio of:
      </p>
      <ul className="mb-medium ml-xl">
        <li>70% from challenging experiences and assignments</li>
        <li>20% from developmental relationships</li>
        <li>10% from coursework and training</li>
      </ul>
      <h2 className="text-rising-orange mb-medium">2. Think creatively about how to fill gaps</h2>
      <p className="mb-medium">
        With the 70-20-10 model in mind, think about on-the-job experiences that can best support your team members'
        long-term goals. Consider projects, scope expansion and new initiatives as opportunities to help people acquire
        new skills.
      </p>
      <p className="mb-medium">
        Once we know what a team member is trying to learn, we can also look at non-traditional ways to acquire new
        skills. Some examples include taking on a leadership role outside of work (such as a community project),
        building new relationships through volunteer work, or mentoring others in the discipline.
      </p>
      <h2 className="text-rising-orange mb-medium">3. Listen to what's important</h2>
      <p className="mb-medium">
        Helping a team member discover their career path is a sure-fire way to get them motivated at work. However,
        sometimes we may have other thoughts and opinions about what path might be right for them. Be sure to continue
        discussing the Horizon exercise in a one-on-one meeting to better understand how team members define success.
      </p>
      <p className="mb-medium">
        As people leaders we may see hidden talents or some similar to our own and want to steer someone down a path
        that they haven't yet identified (or have already ruled out). If this happens, it's important to focus on what
        they see as success and not what we see for them. Use this follow-up conversation as an opportunity to better
        connect with team members and understand them.
      </p>
      <h2 className="text-rising-orange mb-medium">4. Be flexible</h2>
      <p className="mb-medium">
        Our wants and needs will change over time. With each new experience we learn and grow and naturally expand our
        vision of what success may look like in our future. This means it may not be useful to make detailed long-term
        plans. Having an overly detailed long-term plan might even cause team members to fixate on just one path—and
        miss a great opportunity that doesn't perfectly fit within it. Instead, focus on the skills behind a path.
      </p>
      <p className="mb-medium">
        Josh Bersin, author of{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://joshbersin.com/ex-definitive-guide-2021/">
          The Definitive Guide: Employee Experience
        </a>
        , concludes "many of our studies have shown that when people leave organizations, the #1 driver is 'lack of
        progress'. And in this economy, everyone has to grow and learn all the time."
      </p>
      <p className="mb-medium">
        As leaders, we have the unique opportunity to tap into long-term career goals not only as an engagement and
        retention strategy, but also to truly help our team members be and feel successful.
      </p>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="mb-xxxxl ml-xl">
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/taking-charge-of-your-career"
          >
            How the workplace has changed
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hbr.org/2020/01/a-better-way-to-develop-and-retain-top-talent"
          >
            A Better Way to Develop and Retain Top Talent
          </a>{" "}
          (Harvard Business Review)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/having-career-conversations-with-your-team"
          >
            Having Career Conversations with Your Team
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.forbes.com/sites/hvmacarthur/2020/10/31/career-conversations-what-do-you-approach-them-when-no-one-knows-whats-happening-next"
          >
            Career Conversations: How To Approach Them When No One Knows What's Happening Next?
          </a>{" "}
          (Forbes)
        </li>
      </ul>
    </div>
  </div>
)

export default CareerHorizonsTraining3
