import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import CrisisResponsePreviousCrisisUserResults from "domains/Exercise/ResultsComponents/CrisisResponsePreviousCrisisUserResults"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SessionSelectedUsersShareView from "domains/KitSession/components/SessionSelectedUsersShareView"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useSelectedUser } from "ui/SelectedUserContext"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const CrisisResponsePreviousCrisisShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer className="text-gray-9 mr-xl">
          <h2 className="mb-medium">Share out</h2>
          <p className="mb-large">
            Now each person will get time to share their experience with the team. When sharing, consider including how
            it feels to share your example with the group. If there’s time, you can ask each other clarifying questions.
          </p>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" tip="Read this screen out loud." />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={2}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances }) => {
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  return (
    <div>
      <CrisisResponsePreviousCrisisUserResults exercise={selectedExercise} hideIfNoAnswers={false} />
    </div>
  )
}

export default CrisisResponsePreviousCrisisShareStep
