import { isEmpty, join } from "lodash-es"
import { styled } from "styled-components"

import { mashupExercises, RESPONDER_TYPES_DATA } from "../results_utils/crisis_response"

import { InfoCircleIcon } from "icons/FontAwesomeIcons"
import Tooltip from "ui/Tooltip"

const CrisisResponseResponderTypeGrid = styled(function CrisisResponseResponderTypeGrid({
  exerciseInstances,
  userIdMap,
  className,
}) {
  const { peopleWithResponderType } = mashupExercises(exerciseInstances, userIdMap)
  const responderTypes = Object.keys(RESPONDER_TYPES_DATA)

  return (
    <div className={className}>
      <h3 className="text-bold text-gray-9 mb-small">Your team by their top responder type:</h3>
      <div className="responder-type-grid">
        {responderTypes.map((responderType) => (
          <Tooltip
            top
            float
            className="responder-type-grid-box px-large py-small"
            key={responderType}
            title={RESPONDER_TYPES_DATA[responderType].description}
          >
            <h4 className="text-gray-9">
              {RESPONDER_TYPES_DATA[responderType].title}
              <InfoCircleIcon className="ml-small text-rising-blue" />
            </h4>
            <div className="text-small mt-xs text-gray-8">
              <PeopleNames people={peopleWithResponderType[responderType]} />
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  )
})`
  .responder-type-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: ${({ theme }) => theme.tabletMax}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
      grid-template-columns: 1fr;
    }
  }

  .responder-type-grid-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--gray-4);
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0 0 0 / 20%);
    border-radius: 4px;
  }
`

const PeopleNames = ({ people }) => {
  if (isEmpty(people)) {
    return "(None)"
  }

  const peopleNames = people.map((p) => p.short_name)
  return join(peopleNames, ", ")
}

export default CrisisResponseResponderTypeGrid
