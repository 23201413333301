import cn from "classnames"
import { styled } from "styled-components"

import View from "./View"

const SegmentChart = styled(function SegmentChart({ labels, average, bounds, className }) {
  return (
    <View $flexDirection="column" className={cn("pt-xl pb-large", className)}>
      <div className="line-chart-ticks">
        {labels.map((label, index) => (
          <TickMark
            className="line-chart-tick"
            key={label}
            label={label}
            index={index}
            labels={labels}
            average={average}
            lowerBound={bounds[index].lower_bound}
            upperBound={bounds[index].upper_bound}
          />
        ))}
      </div>
    </View>
  )
})`
  position: relative;
  max-width: 691px;

  .line-chart-ticks {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 0.813rem;
    line-height: 1.188rem;
  }

  .line-chart-tick {
    &:first-child {
      border-left: 2px solid var(--gray-5);
    }

    &:last-child {
      border-right: 2px solid var(--gray-5);
    }
  }
`

const TickMark = styled(function TickMark({ labels, label, index, average, lowerBound, upperBound, className }) {
  return (
    <View $flexDirection="row" className={className}>
      {index !== 0 && <div className="tick"></div>}
      <View $flexDirection="column" $alignItems="center">
        <div className="top-line"></div>
        <p
          className={cn("label text-gray-8 pt-small", { "pl-xxs": index === 0, "pr-xxs": index === labels.length - 1 })}
        >
          {label}
        </p>
      </View>
      {index !== labels.length - 1 && <div className="tick"></div>}

      {average >= lowerBound && (average < upperBound || (index === labels.length - 1 && average <= upperBound)) && (
        <div className="average">
          <p className="average-label text-semi-bold">AVG</p>
          <div className="average-tick"></div>
        </div>
      )}
    </View>
  )
})`
  position: relative;
  width: 100%;

  .tick {
    height: 100%;
    width: 1px;
    background-color: var(--gray-5);
  }

  .top-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--gray-5);
  }

  .label {
    width: 100%;
    text-align: ${({ labels, index }) => (index === 0 ? "left" : index === labels.length - 1 ? "right" : "center")};
  }

  .average {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: -50px;
    color: var(--rising-orange);
    margin-left: ${({ average, lowerBound, upperBound }) =>
      `${((average - lowerBound) / (upperBound - lowerBound)) * 100 - 5}%`};
  }

  .average-label {
    margin-top: 15px;
  }

  .average-tick {
    margin-top: 5px;
    height: 23px;
    width: 2px;
    border-radius: 1px;
    background: var(--rising-orange);
  }
`

export default SegmentChart
