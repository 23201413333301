import { ResponsiveBar } from "@nivo/bar"
import { memo } from "react"

import { chartTheme } from "./theme"

const axis = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
}

const MyResponsiveBar = memo(
  ({
    data,
    keys,
    layout = "vertical",
    tooltip,
    axisBottom = {},
    axisLeft = {},
    margin = {},
    gridXValues,
    gridYValues,
    innerPadding,
    borderRadius,
    ...props
  }) => (
    <ResponsiveBar
      theme={chartTheme}
      data={data}
      keys={keys}
      indexBy="group"
      margin={{
        top: 0,
        right: 40,
        bottom: 40,
        left: 150,
        ...margin,
      }}
      valueScale={{ type: "linear" }}
      colors={({ id, data }) => data[`${id} color`]}
      innerPadding={innerPadding}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      borderRadius={borderRadius}
      axisTop={null}
      axisRight={null}
      axisBottom={
        !axisBottom
          ? null
          : {
              ...axis,
              ...axisBottom,
            }
      }
      axisLeft={
        !axisLeft
          ? null
          : {
              ...axis,
              ...axisLeft,
            }
      }
      layout={layout}
      groupMode="stacked"
      enableGridY={true}
      gridXValues={gridXValues}
      gridYValues={gridYValues}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={tooltip}
      {...props}
    />
  )
)

const BarChart = memo(({ data, className, ...props }) => (
  <>
    <div className={className}>
      <MyResponsiveBar data={data} layout="horizontal" {...props} />
    </div>
  </>
))

export default BarChart
