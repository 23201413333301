import { Navigate, Route, useParams } from "react-router-dom"

import ReflectionsReview from "./ReflectionsReview"

import NotFound from "components/NotFound"
import { RouteSteps } from "components/Steps/Steps"
import { useUser } from "resources/users"
import Loading from "ui/Loading"
import { useKitInstanceByID } from "ui/SelectedTeamContext"

export default function KitSessionReview() {
  const { data: user } = useUser({ userId: "me" })
  const { id } = useParams()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id)

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <NotFound />
  }

  if (!user) {
    return null
  }

  if (!kitInstance.session_completed_at) {
    return <Navigate to={kitInstance.home_url} replace />
  }

  return (
    <RouteSteps>
      <Route path="/reflections" element={<ReflectionsReview kitInstance={kitInstance} />} />
      <Route path="*" ignore element={<Navigate to={kitInstance.home_url} replace />} />
    </RouteSteps>
  )
}
