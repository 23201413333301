import { map, isEmpty, reduce } from "lodash-es"

import { CHART_MAX_VALUE, getCategoryToPointsMap, getChartValues } from "./radarchart"

// There are 6 responder types and 5 questions.
// One way to understand the distribution is to imagine the point distribution of 5 consecutive dice rolls.
// That is a bell curve that ranges from 5-30 (this ranges from 0-25 which is the same thing).
const MAX_POINTS_PER_BUCKET = [7, 13, 17, 25]

const LEARNER_AGILITY_DATA = {
  visual: {
    title: "Visual",
    description: "Visual learners need to see information in order to process it.",
    descriptionBulletPoints: [
      "They prefer graphic elements over words, and video over audio or text",
      "Like to watch “how-to” videos or observe others doing what they are trying to learn",
      "Often take notes to remember what was discussed, and draw images, doodle, and use colors",
    ],
  },
  auditory: {
    title: "Auditory",
    description: "Auditory learners learn by hearing and talking about new information.",
    descriptionBulletPoints: [
      "Often learn by reading out loud because they need to hear or speak something in order to know it",
      "Enjoy lectures, discussions, podcasts, and audiobooks",
      "Benefit from hearing themself speak, repetition, and mnemonic devices",
    ],
  },
  linguistic: {
    title: "Linguistic",
    description:
      "Linguistic learners have a fascination with words, enjoy language, and learn best through spoken or written materials.",
    descriptionBulletPoints: [
      "They prefer activities based on language reasoning rather than abstract visual information",
      "Like to have thorough, written documents to understand new concepts",
      "Tend to enjoy games involving word play such as Scrabble, Wordle, and crossword puzzles",
    ],
  },
  tactile: {
    title: "Tactile",
    description: "Tactile learners learn by doing and prefer concrete, hands-on experience.",
    descriptionBulletPoints: [
      "Prefer hands-on learning over reading or watching something",
      "Like to learn things through trial and error, tactical practice, and repetition",
      "May like to role play or practice to learn new information",
    ],
  },
  social: {
    title: "Social",
    description: "Social learners like to learn together with and from other people.",
    descriptionBulletPoints: [
      "Often work through ideas by talking them through with a partner who can give them feedback",
      "Like being able to ask questions to other people when they are learning something new",
      "Often seek out mentors and experts for support",
    ],
  },
  conceptual: {
    title: "Conceptual",
    description:
      "When learning something new, conceptual learners start with the bigger picture along with the logic, reasoning and systems behind that concept.",
    descriptionBulletPoints: [
      "Easily recognize patterns and connects concepts easily",
      "Deal well with abstractions and models",
      "Can imagine things well in their minds, without seeing them written on paper",
    ],
  },
}

const processLearningStylesData = (exercise) => {
  const learningStylePoints = getCategoryToPointsMap(exercise)
  const sortedLearningStyleTypes = Object.keys(learningStylePoints).sort(
    (a, b) => learningStylePoints[b] - learningStylePoints[a]
  )
  const chartValues = getChartValues(learningStylePoints, MAX_POINTS_PER_BUCKET)

  return {
    exercise,
    learningStylePoints,
    sortedLearningStyleTypes,
    chartValues,
  }
}

const getChartConfig = (exercises, userIdMap) => {
  if (isEmpty(exercises)) {
    return { data: [] }
  }

  const processedExercises = exercises.map(processLearningStylesData)
  const names = exercises.map((exercise) => userIdMap[exercise.user_id].short_name)
  // The radar chart has one ring per bucket plus an extra ring for aesthetic reasons
  const gridLevels = MAX_POINTS_PER_BUCKET.length + 1

  return {
    keys: names,
    indexBy: "learningStyle",
    maxValue: CHART_MAX_VALUE,
    gridLevels,
    valueFormat: (value) => Math.round(value),
    data: map(LEARNER_AGILITY_DATA, ({ title }, learningStyle) =>
      reduce(
        processedExercises,
        (learningStyleConfig, processedExercise) => {
          const { exercise, chartValues } = processedExercise
          const key = userIdMap[exercise.user_id].short_name
          learningStyleConfig[key] = chartValues[learningStyle] ?? 0
          return learningStyleConfig
        },
        { learningStyle: title }
      )
    ),
  }
}

export { MAX_POINTS_PER_BUCKET, LEARNER_AGILITY_DATA, processLearningStylesData, getChartConfig }
