import cn from "classnames"
import { Form, Formik } from "formik"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import ScheduleForm from "./ScheduleForm"

import { useRTBrandName } from "components/AccountTypeContext"
import renderKitBlocks from "domains/KitSession/blocks"
import { applyTranslationLayer, isTranslatableKit } from "domains/KitSession/translation"
import SessionPreviewMessage from "domains/LeaderKit/SessionPreviewMessage"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { ArrowRightIcon, LockIcon, PaperPlaneIcon, PenAltIcon, PlayCircleIcon } from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"
import BulletedList from "ui/BulletedList"
import Button from "ui/Button"
import FlairContainer from "ui/FlairContainer"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { TEAM_FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import useWindowSize from "ui/hooks/useWindowSize"
import PageBreadcrumbs from "ui/PageBreadcrumbs"
import TextContainer from "ui/TextContainer"
import View from "ui/View"
import { KitGroupSize, formatKitSessionTime } from "utils/kit"
import { buildUrl, plural } from "utils/string"

const SessionPrepHome = ({ kitInstance, user, team, className }) => {
  const rtBrandName = useRTBrandName()
  const { pathname } = useLocation()
  const { isDesktopOrLarger } = useWindowSize()
  const exerciseUrl = getStandaloneExerciseUrl({
    slug: kitInstance.slug,
    teamId: kitInstance.team_id,
    onFinishUrl: pathname,
  })
  const isDemoPreview = team.demo && user.id !== team.team_lead_id
  const previewMode = team.preview || isDemoPreview
  const isJumbo = kitInstance.kit.group_size === KitGroupSize.JUMBO
  const sessionTime = formatKitSessionTime(kitInstance.kit.session_time_minutes, { unit: "minute" })
  const goTime = formatKitSessionTime(kitInstance.kit.session_time_minutes)

  const BrandBlocks = ({ brand_blocks, kitInstance }) => {
    if (!brand_blocks) {
      return null
    }

    return <View>{renderKitBlocks(brand_blocks, { kitInstance })}</View>
  }

  // TODO(kit-translation,evnp) Remove translationEnabled below:
  // (we've temporarily disabled language-switching here for a session in China)
  const translationEnabled = false
  // TRANSLATION LAYER MANAGEMENT
  const { lang } = useQueryParams()
  const kitSlug = kitInstance?.kit?.slug
  useEffectAfterChange(() => {
    if (
      translationEnabled &&
      // FEATURE-FLAG KIT TRANSLATION (code should be no-op for other users):
      team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) &&
      user &&
      // TODO(hackathon,kit-translation,evnp) Only try to apply translations for psych-safety kit for now:
      isTranslatableKit(kitSlug) &&
      // Wait for participants to load before applying
      // translation so we catch the "hang tight" text:
      !user.email.endsWith("@rtunittest.com")
      // don't apply translation layers during end-to-end session tests, otherwise
      // they take forever to run and we don't get clean translation input data
    ) {
      applyTranslationLayer(kitSlug, lang, true)
    }
  }, [lang, user, kitSlug, team, translationEnabled])
  const navigate = useNavigate()
  function changeTranslation(option) {
    if (team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION)) {
      navigate(option.value?.length ? `?lang=${option.value}` : "?")
    }
  }
  // END TRANSLATION LAYER MANAGEMENT

  return (
    <main className={cn("align-left full-width", className)}>
      {/* FEATURE-FLAG KIT TRANSLATION (code should be no-op for other users): */}
      {!!translationEnabled &&
        !!team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) &&
        !!isTranslatableKit(kitSlug) && (
          <View $justifyContent="flex-end" className="language-switcher">
            <Formik initialValues={{ lang: lang ?? "" }}>
              <Form className="fit-content">
                <AdvancedSelectField
                  name="lang"
                  onChange={changeTranslation}
                  options={[
                    { value: "", label: "English" },
                    { value: "zh-CN", label: "简体中文 (Chinese — Simplified)" },
                  ]}
                />
              </Form>
            </Formik>
          </View>
        )}
      {/* END FEATURE-FLAG KIT TRANSLATION */}
      <FlairContainer className="main-container">
        <TextContainer maxWidth={525}>
          <PageBreadcrumbs className="mb-medium">
            <Link to={`/kit?team_id=${team.id}`}>View all kits</Link>
            {kitInstance.kit.title}
          </PageBreadcrumbs>
          <h3 className="text-primary text-bold">{isJumbo ? "Jumbo Development Kit" : "Introducing the theme"}</h3>
          <h1 className="text-gray-9 text-bold py-small pr-medium">{kitInstance.kit.title}</h1>
          <div className="text-gray-9 text-thin">{kitInstance.kit.description}</div>
          {!!isJumbo && (
            <div className="text-gray-9 text-thin mt-medium">
              This version is optimized for groups up to 1000 people.
            </div>
          )}
          <div className="text-gray-9 text-bold mt-medium">Just 3 quick steps: 3, 2, 1, GO</div>
        </TextContainer>
        <div>
          <div className="border-radius-small border-left-thick border-orange-2 box-shadow px-large py-medium mt-medium mb-large">
            <div className="step-header-container pt-xs">
              <h4 className="step-time text-semi-bold text-orange-2 pr-xxs">5 mins</h4>
              <h4 className="step-title text-gray-9 text-bold">
                <span className="text-huge pr-small text-orange-2">3</span>Pick a date and time
              </h4>
            </div>
            <div className="text-gray-9 pt-medium pl-xxs pb-medium">
              Choose a {sessionTime} block for your team to do the {rtBrandName} session together. Once you create the
              calendar invite, add your team and a video link or conference room if needed. The session can be done
              remotely or in-person, as long as everyone has a computer or mobile device and internet access.
            </div>
            {team.preview ? (
              <SessionPreviewMessage className="mb-xxs">
                To schedule a session, first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
              </SessionPreviewMessage>
            ) : (
              !isDemoPreview && (
                <ScheduleForm
                  kitInstance={kitInstance}
                  kitInfo={kitInstance.kit}
                  team={team}
                  className="pl-xxs pb-xxs"
                />
              )
            )}
          </div>

          <div className="border-radius-small border-left-thick border-orange-3 box-shadow px-large py-medium mt-small mb-large">
            <div className="step-header-container pt-xs">
              <h4 className="step-time text-semi-bold text-orange-3 pr-xxs">{kitInstance.kit.prep_time} mins</h4>
              <h4 className="step-title text-gray-9 text-bold">
                <span className="text-huge pr-small text-orange-3">2</span>Learn about the theme
              </h4>
            </div>
            <div className="text-gray-9 pt-medium pl-xxs">
              {plural(kitInstance.kit.prep_links.length, "This", { pluralForm: "These", showCount: false })} quick
              training {plural(kitInstance.kit.prep_links.length, "article", { showCount: false })} will help you get up
              to speed on the topic and make the most of your team workshop. (Read or listen!)
            </div>
            <BulletedList className="mt-medium">
              {kitInstance.kit.prep_links.map((prep_link) => (
                <li key={prep_link.title} className="bullet-point">
                  <div className="text-normal">
                    {prep_link.is_placeholder ? (
                      <p className="text-italic">{prep_link.title}</p>
                    ) : prep_link.slug ? (
                      <Link to={`${kitInstance.prep_url}articles/${prep_link.slug}`}>{prep_link.title}</Link>
                    ) : (
                      <a target="_blank" rel="noopener noreferrer" href={prep_link.external_url}>
                        {prep_link.title}
                      </a>
                    )}
                  </div>
                </li>
              ))}
            </BulletedList>
          </div>
          <div className="border-radius-small border-left-thick border-rising-yellow box-shadow px-large py-medium mt-small mb-large">
            <div className="step-header-container pt-xs">
              <h4 className="step-time text-semi-bold text-yellow-2 pr-xxs">20 mins</h4>
              <h4 className="step-title text-gray-9 text-bold">
                <span className="text-huge pr-small text-yellow-2">1</span>Give it a test run
              </h4>
            </div>
            <div className="text-gray-9 pt-medium pl-xxs pb-medium">
              Use these tools to prepare to lead the team session like a pro.
            </div>
            {(!!kitInstance.kit.screencast_id || !!kitInstance.kit.screencast_placeholder_text) && (
              <div className="icon-list-container text-normal text-gray-9">
                <div className="icon-container">
                  <PlayCircleIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium mb-xs">
                  {kitInstance.kit.screencast_placeholder_text ? (
                    <>
                      <p className="text-italic">{kitInstance.kit.screencast_placeholder_text}</p>
                    </>
                  ) : (
                    <>
                      <Link
                        to={buildUrl([kitInstance.prep_url, "video-walkthrough"], {
                          urlQueryParams: lang ? { lang } : {},
                        })}
                      >
                        Watch a quick video
                      </Link>{" "}
                      on how to lead the session with your team.
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="icon-list-container">
              <div className="icon-container">
                <PaperPlaneIcon color="var(--gray-9)" />
              </div>
              <div className="ml-medium mb-xs">
                <Link to={kitInstance.session_preview_url}>Preview the session </Link>
                {kitInstance.kit.session_preview_custom_text || "to walk through the details on each page."}
              </div>
            </div>
            {!!kitInstance.kit.has_standalone_exercise && (
              <div className="icon-list-container">
                <div className="icon-container">
                  <PenAltIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium">
                  <Link to={exerciseUrl}>Do the exercise in advance</Link> to complete the interactive activity on your
                  own.
                </div>
              </div>
            )}
          </div>
          <div className="border-radius-small border-left-thick border-rising-green box-shadow px-large py-medium mt-small">
            <div className="step-header-container pt-xs">
              <h4 className="step-time text-semi-bold text-rising-green pr-xxs">{goTime}</h4>
              <h4 className="step-title text-gray-9 text-bold">
                <span className="text-huge pr-small text-rising-green">GO</span>And it’s game time!
              </h4>
            </div>
            <div className="text-gray-9 pt-medium pl-xxs">
              We recommend you book {goTime}, and for smaller teams the session will likely be shorter. All you (and
              your team) need to do is show up and bring a laptop or mobile device with internet access.
            </div>
            {!!team.preview && (
              <SessionPreviewMessage className="mt-small">
                To run a session, first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
              </SessionPreviewMessage>
            )}
            <View
              $alignItems="center"
              $alignItemsMobile="flex-start"
              $flexDirectionMobile="column"
              className="ml-xxs mt-small"
            >
              {!previewMode && (
                <Button as={Link} to={kitInstance.session_start_url} className="text-nowrap">
                  {kitInstance.session_started_at ? "Resume Session" : "Start Session"}
                  <ArrowRightIcon />
                </Button>
              )}
              <Link
                to={kitInstance.session_preview_url}
                className={cn("text-semi-bold", {
                  "ml-medium": isDesktopOrLarger && !previewMode,
                  "mt-xs": !isDesktopOrLarger && !previewMode,
                })}
              >
                Preview the session
              </Link>
            </View>
          </div>
          {!!kitInstance.kit.bonus_page && (
            <div className="mt-xl">
              <h4>
                <LockIcon color="var(--rising-yellow)" className="mr-xs fa-2x" />
                Unlock your secret bonus
              </h4>
              <div className="text-gray-9 pt-xs">Finish the session to unlock a special bonus prize.</div>
            </div>
          )}
          <div className="mt-xl">
            <h4>Still have questions?</h4>
            <div className="text-gray-9 pt-xs mb-large">
              <a href="https://app.risingteam.com/slack/invite" target="_blank" rel="noopener noreferrer">
                Ask our community of managers
              </a>{" "}
              and coaches in the Huddle on Slack.
            </div>
          </div>
        </div>
        <BrandBlocks brand_blocks={kitInstance.kit.brand_blocks} />
      </FlairContainer>
    </main>
  )
}

export default styled(SessionPrepHome)`
  .icon-container {
    font-size: 1.25rem;
    padding-top: 2px;
  }

  .icon-list-container {
    display: flex;
    flex-direction: row;
  }

  .step-header-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .step-time {
    margin-top: auto;
  }

  .border-left-thick {
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    position: relative;

    .step-header-container {
      flex-direction: column;
    }

    .step-time {
      text-align: right;
    }

    .border-radius-small {
      border-radius: 2px;
    }

    .border-left-thick {
      border-top: none;
      border-right: none;
      border-bottom: none;
      margin-bottom: 40px;
      border-width: 5px;
    }
  }

  /* TODO(hackathon,kit-translation,evnp): Clean up code below after hackathon
     TRANSLATION LAYER MANAGEMENT */

  .language-switcher {
    position: absolute;
    top: 3rem;
    right: 3rem;
  }

  /* END TRANSLATION LAYER MANAGEMENT */
`
