import { Link } from "react-router-dom"

import { useKitSession } from "../KitSessionContext"

const SessionLink = ({ children, linkKey, className }) => {
  const { kitInstance } = useKitSession()

  return (
    <Link to={kitInstance[linkKey]} className={className}>
      {children}
    </Link>
  )
}

export default SessionLink
