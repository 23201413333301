import { Route, Routes } from "react-router-dom"

import Bonus from "./Bonus"

import { SimpleRouteLayout } from "domains/Authentication/Routes"

const BonusIndex = () => (
  <Routes>
    <Route element={<SimpleRouteLayout title="Bonus" />}>
      <Route path="kit/:slug/:id/bonus" element={<Bonus />} />
    </Route>
  </Routes>
)

export default BonusIndex
