import cn from "classnames"
import { groupBy, mapValues } from "lodash-es"
import { useState, useMemo } from "react"
import { styled } from "styled-components"

import { StyledSelect } from "forms/fields/SelectField"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useWindowSize from "ui/hooks/useWindowSize"
import { intersperse } from "utils/array"
import { KitType } from "utils/kit"

const FILTER_KEYS = {
  ALL_KITS: "all_kits",
  KITS: "kit",
  MINIS: "minis",
}

const ResultFilters = styled(function ResultFilters({ startedKitInstances, setVisibleResults, className }) {
  const [activeFilter, setActiveFilter] = useState(FILTER_KEYS.ALL_KITS)
  const { isMobileOrSmaller } = useWindowSize()
  const visibleFilters = useMemo(
    () =>
      [
        {
          text: "All results",
          key: FILTER_KEYS.ALL_KITS,
          kits: [...startedKitInstances],
        },
        {
          text: "Kits",
          key: FILTER_KEYS.KITS,
          kits: startedKitInstances.filter((kitInstance) => kitInstance.basic_kit.type === KitType.KIT),
        },
        {
          text: "Minis",
          key: FILTER_KEYS.MINIS,
          kits: startedKitInstances.filter((kitInstance) => kitInstance.basic_kit.type === KitType.MINI),
        },
      ].filter(({ kits }) => kits.length > 0),
    [startedKitInstances]
  )
  const visibleFiltersMap = useMemo(
    () => mapValues(groupBy(visibleFilters, "key"), (filters) => filters[0]),
    [visibleFilters]
  )

  useEffectAfterChange(() => {
    const { kits = [] } = visibleFiltersMap[activeFilter] ?? {}
    setVisibleResults(kits)
  }, [activeFilter, setVisibleResults, visibleFiltersMap])

  if (!visibleFiltersMap[FILTER_KEYS.MINIS]?.kits?.length || !visibleFiltersMap[FILTER_KEYS.KITS]?.kits?.length) {
    return null
  }

  return (
    <div className={cn(className, "text-gray-7", { "full-width": isMobileOrSmaller })}>
      {isMobileOrSmaller ? (
        <MobileFilterSelect
          activeFilter={activeFilter}
          visibleFilters={visibleFilters}
          setActiveFilter={setActiveFilter}
          setVisibleResults={setVisibleResults}
        />
      ) : (
        intersperse(
          visibleFilters.map(({ text, key }) => {
            const onClick = () => {
              setActiveFilter(key)
            }
            return (
              <span
                key={key}
                onClick={onClick}
                className={cn("filter-option text-semi-bold cursor-pointer", {
                  "active-filter": key === activeFilter,
                })}
              >
                {text}
              </span>
            )
          }),
          <span className="px-xs">|</span>
        )
      )}
    </div>
  )
})`
  .filter-option:hover {
    color: var(--gray-9);
  }

  .active-filter {
    color: var(--gray-9);
    padding-bottom: 3px;
    border-bottom: 2px solid var(--orange-2);
  }
`

const MobileFilterSelect = ({ activeFilter, visibleFilters, setActiveFilter, setVisibleResults, className }) => {
  if (visibleFilters.length <= 0) {
    return null
  }

  const visibleFiltersMap = mapValues(groupBy(visibleFilters, "key"), (filters) => filters[0])
  const onChange = (evt) => {
    const key = evt.target.value
    const { kits } = visibleFiltersMap[key]
    setActiveFilter(key)
    setVisibleResults(kits)
  }

  return (
    <StyledSelect value={activeFilter} onChange={onChange} className={className}>
      {visibleFilters.map(({ text, key }) => (
        <option key={key} value={key}>
          {text}
        </option>
      ))}
    </StyledSelect>
  )
}

export default ResultFilters
