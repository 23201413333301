const LifelinesIntro = () => (
  <>
    <h2 className="text-gray-9 mb-medium">Intro</h2>
    <p className="mb-medium">
      For this activity, think of 3 events from your life that helped shape who you are that you feel comfortable
      sharing with the group.
    </p>
    <p className="mb-medium">
      When choosing which events to share, it doesn’t matter if the event is small or really big, a moment of hardship
      or a moment of of triumph. What matters is that it was important to you. If you’re not used to sharing personal
      stories at work, it may feel a little awkward. Try to get just a little bit out of your comfort zone.
    </p>
  </>
)

export default LifelinesIntro
