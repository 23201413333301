import qs from "qs"
import { useState } from "react"
import { Navigate, useParams } from "react-router-dom"

import AlreadyLoggedInPurchase from "./AlreadyLoggedInPurchase"
import { BillingDataContext, getBillingData } from "./BillingDataContext"
import RegisterForPurchase from "./RegisterForPurchase"

import { useSetAccountTypeByBillingData } from "components/AccountTypeContext"
import { useAuth } from "domains/Authentication/resource"
import { usePromoCode } from "domains/Promo/utils"
import { SubscriptionType } from "resources/billing"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"

const RegisterIndex = ({ promoCode: promoCodeProp = null }) => {
  const { data: auth, isFetching: authIsFetching } = useAuth()
  const promoCodeParam = usePromoCode()
  const promoCode = promoCodeProp ?? promoCodeParam
  const { subscription: subscriptionParam, quantity: quantityParam } = useParams()
  const subscription = promoCode ? "monthly" : subscriptionParam
  const quantity = promoCode ? 1 : quantityParam

  const queryParams = useQueryParams()
  const [billingData, setBillingData] = useState(getBillingData({ subscription, quantity, queryParams }))
  const queryParamString = qs.stringify({ ...queryParams, subscription, quantity })

  useSetAccountTypeByBillingData(billingData)

  if (isNaN(quantity) || quantity <= 0 || quantity > 100 || !SubscriptionType[subscription]) {
    return <Navigate to="/" replace />
  }

  if (authIsFetching) {
    return <Loading />
  }

  return (
    <BillingDataContext.Provider value={{ billingData, setBillingData }}>
      {auth ? (
        <AlreadyLoggedInPurchase queryParamString={queryParamString} promoCode={promoCode} />
      ) : (
        <RegisterForPurchase promoCode={promoCode} />
      )}
    </BillingDataContext.Provider>
  )
}

export default RegisterIndex
