import { useParams } from "react-router"

import NotFound from "components/NotFound"
import { trackLeaderAction } from "resources/monthly_kit"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"

const ALLOWED_STATUSES = ["done", "snooze", "skip"]

const LeaderActions = () => {
  const { action, status } = useParams()
  const { id, token } = useQueryParams()
  const isAllowedStatus = ALLOWED_STATUSES.includes(status)

  useEffectAfterFirstRender(() => {
    if (isAllowedStatus) {
      trackLeaderAction({ id, token, action, status })
      window.location.replace(`https://risingteam.com/leader-actions/${status}`)
    }
  })

  if (!isAllowedStatus) {
    return <NotFound />
  }

  return <Loading />
}

export default LeaderActions
