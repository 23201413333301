import InviteTeamLeadStepComponent from "./SetupStepComponents/InviteTeamLeadStep"
import ScheduleTeamSessionsStepComponent from "./SetupStepComponents/ScheduleTeamSessionsStep"
import SetupProfileStep from "./SetupStepComponents/SetupProfileStep"
import SetUpTeamStep from "./SetupStepComponents/SetUpTeamStep"

// IMPORTANT:
// Keep SetupStepPath here in sync with SetupStepPath in backend/risingteam/setup.py
const SetupStepPath = {
  INVITE_TEAM_LEADS: "invite-team-leads",
  SET_UP_TEAM: "set-up-team",
  SCHEDULE_TEAM_SESSIONS: "schedule-team-sessions",
  SET_UP_ENGAGEMENT_SURVEY: "set-up-engagement-survey",
  SET_UP_PROFILE: "set-up-profile",
}

// Remove the following once all the steps are implemented
// const TODOPlaceholderStepComponent = ({ title, path }) => (
//   <div style={{ height: '300px' }}>
//     <h3 className="mb-medium">{title}</h3>
//     <p>Other {path} step content goes here.</p>
//     <p>Replace with actual step component when ready.</p>
//   </div>
// )

const TODOPlaceholderMultiStepComponent = ({ title, path, index }) => (
  <div style={{ height: "300px" }}>
    <h3 className="mb-medium">
      {title} {index + 1}
    </h3>
    <p>Other {path} step content goes here.</p>
    <p>Replace with actual step component when ready.</p>
  </div>
)

const setupSteps = [
  {
    title: "Invite team leads",
    path: SetupStepPath.INVITE_TEAM_LEADS,
    components: [InviteTeamLeadStepComponent],
  },
  {
    title: "Set up your team",
    path: SetupStepPath.SET_UP_TEAM,
    components: [SetUpTeamStep],
  },
  {
    title: "Schedule team sessions",
    path: SetupStepPath.SCHEDULE_TEAM_SESSIONS,
    components: [ScheduleTeamSessionsStepComponent],
  },
  {
    title: "Set up engagement survey",
    path: SetupStepPath.SET_UP_ENGAGEMENT_SURVEY,
    subPathSuffixes: ["step-one", "step-two", "step-three"],
    // If you remove subPathSuffixes, default will be 1, 2, 3, eg.
    // set-up-engagement-survey-1 -> set-up-engagement-survey-2 -> set-up-engagement-survey-3
    // or you can customize it with anything, for instance
    // subPathSuffixes: ['A', 'B', 'C'],
    // set-up-engagement-survey-A -> set-up-engagement-survey-B -> set-up-engagement-survey-C
    components: [
      TODOPlaceholderMultiStepComponent,
      TODOPlaceholderMultiStepComponent,
      TODOPlaceholderMultiStepComponent,
    ],
  },
  {
    title: "Set up your profile",
    path: SetupStepPath.SET_UP_PROFILE,
    components: [SetupProfileStep],
  },
]

export default setupSteps
