import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import Button from "ui/Button"

const ResendInviteLink = ({ className, resendInvite, resendInviteArgs, text = "Resend" }) => {
  const [inviteSent, setInviteSent] = useState(false)

  const onClick = async () => {
    setInviteSent(true)
    await resendInvite(...resendInviteArgs)
  }

  if (inviteSent) {
    return (
      <div className={cn("resend-invite-confirmation", className, "text-field-label text-rising-green mr-medium")}>
        Invite Sent!
      </div>
    )
  }

  return (
    <Button className={cn("resend-invite-button", className, "link-blue text-field-label")} onClick={onClick}>
      {text}
    </Button>
  )
}

export default styled(ResendInviteLink)`
  height: auto;
  padding: 6px 0;

  &.resend-invite-confirmation {
    margin-top: 1px; // align text with resend-invite-button
  }
`
