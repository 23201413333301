import { Route, Routes } from "react-router-dom"

import ReportsHome from "./ReportsHome"

import { SimpleRouteLayout } from "domains/Authentication/Routes"
import PageTitle from "ui/PageTitle"

const Reports = () => (
  <>
    <PageTitle>Report</PageTitle>
    <Routes>
      <Route element={<SimpleRouteLayout title="Report" />}>
        <Route path="/" element={<ReportsHome />} />
      </Route>
    </Routes>
  </>
)

export default Reports
