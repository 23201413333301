import { ResponsiveRadar } from "@nivo/radar"
import cn from "classnames"
import { mapValues } from "lodash-es"
import { styled } from "styled-components"

import { chartColors, chartTheme, getColorDefault } from "./theme"
import UserLegend from "./UserLegend"
import View from "./View"

const MyResponsiveRadar = ({
  blendMode = "multiply",
  gridShape = "linear",
  motionConfig = "wobbly",
  theme = chartTheme,
  colors = chartColors,
  fillOpacity = 0.15,
  enableDots = false,
  margin = {
    top: 40,
    right: 95,
    bottom: 40,
    left: 95,
  },
  ...props
}) => (
  <ResponsiveRadar
    blendMode={blendMode}
    fillOpacity={fillOpacity}
    gridShape={gridShape}
    motionConfig={motionConfig}
    theme={theme}
    colors={colors}
    enableDots={enableDots}
    margin={margin}
    {...props}
  />
)

const RadarChart = styled(function RadarChart({
  data,
  keys,
  showLegend,
  getColorFunc = getColorDefault,
  legend: Legend = UserLegend,
  onKeyClick,
  className,
  ...props
}) {
  if (data.length === 0) {
    return null
  }

  const { indexBy, maxValue, valueFormat } = props
  const getColor = getColorFunc(keys)
  const dataForAriaLabel = data.map((item) => {
    const { [indexBy]: indexName, ...strValuesMap } = item
    const valuesMap = mapValues(strValuesMap, (value) => parseFloat(value) || -1)
    const keyOfMaxValue = Object.keys(valuesMap).reduce((a, b) => (item[a] > item[b] ? a : b))
    const formattedMaxValue = (valueFormat ? valueFormat(item[keyOfMaxValue]) : item[keyOfMaxValue]) || 0
    const indexDescription = keys.length > 1 ? `${keyOfMaxValue} - ${formattedMaxValue}` : formattedMaxValue
    return `${indexName}: ${indexDescription}`
  })
  const ariaLabel = `Chart of values, max value is ${maxValue}, with the following data: ${dataForAriaLabel.join(", ")}`

  return (
    <View $flexDirectionMobile="column" aria-label={ariaLabel} className={className}>
      <div
        className={cn("chart-container px-xs", {
          "mb-small border border-gray-5": showLegend,
        })}
      >
        <MyResponsiveRadar data={data} keys={keys} colors={getColor} {...props} />
      </div>
      {!!showLegend && (
        <Legend data={data} keys={keys} colors={getColor} onKeyClick={onKeyClick} className="chart-legend" />
      )}
    </View>
  )
})`
  .chart-container {
    width: 100%;

    @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
      height: 200px;

      text {
        font-size: 0.668rem !important;
      }
    }

    @media (min-width: ${({ theme }) => theme.tabletMin}) {
      height: 400px;
      flex: 2;
    }

    @media print {
      width: 400px;
      height: 400px;
      border: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}), print {
    .chart-legend {
      flex: 1;
      margin-left: var(--spacing-8);
    }
  }
`

export default RadarChart
