import { styled } from "styled-components"

import { LightbulbIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"

const SessionPreviewMessage = styled(function SessionPreviewMessage({ children, className }) {
  return (
    <View className={className}>
      <LightbulbIcon color="var(--yellow-2)" className="preview-icon mr-xs" />
      <div className="text-gray-8">{children}</div>
    </View>
  )
})`
  .preview-icon {
    margin-top: 6px;
    font-size: 1.5em;
  }
`

export default SessionPreviewMessage
