import { getExerciseAnswer, getUserExerciseInstance } from "../results_utils"

import { CareerHorizonsChartingPathNextStepsReadOnly } from "./CareerHorizonsChartingPathNextSteps"
import CareerHorizonsThinkingBigExcitement from "./CareerHorizonsThinkingBigExcitement"

import Accordion from "components/Accordion"
import { sortUsersByShortName } from "resources/users"
import Loading from "ui/Loading"

const CareerHorizonsTeamView = ({ exerciseInstances, userIdMap }) => {
  const sortedUsers = sortUsersByShortName({ users: Object.values(userIdMap) })

  if (!exerciseInstances) {
    return <Loading />
  }

  return (
    <>
      {sortedUsers.map((user) => {
        const selectedExercise = getUserExerciseInstance(exerciseInstances, user)
        const path_field_id = getExerciseAnswer(selectedExercise, "path_to_share")?.split(".")[0]
        const path = getExerciseAnswer(selectedExercise, path_field_id)
        const title = `Potential Path: ${path}`
        return (
          <div key={user.id}>
            {!!path_field_id && (
              <>
                <h2 className="text-gray-9 mb-medium">{user.short_name}</h2>
                <Accordion className="text-gray-9 text-bold mb-large" title={title} isOpen={false}>
                  <div className="text-normal text-gray-9">
                    <CareerHorizonsThinkingBigExcitement exercise={selectedExercise} path_field_id={path_field_id} />
                    <CareerHorizonsChartingPathNextStepsReadOnly exercise={selectedExercise} />
                  </div>
                </Accordion>
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

export default CareerHorizonsTeamView
