const FiveSensesIntro = () => (
  <>
    <h2 className="text-gray-9 mb-medium">Intro</h2>
    <p className="mb-medium">
      For this activity, you will be asked to think of something meaningful for each of the 5 senses. When choosing what
      to share, try to choose experiences that were significant to you. It’s OK if they are small or really big, what
      matters is that they are meaningful to you.
    </p>
    <p className="mb-xxxxl">
      If you’re not used to sharing personal stories at work, it may feel a little awkward. Try to get just a little bit
      out of your comfort zone.
    </p>
  </>
)

export default FiveSensesIntro
