import { useNavigate } from "react-router-dom"

import OnboardingProfilePage from "./OnboardingProfilePage"

import handleErrors from "forms/handleErrors"
import { useUpdateCurrentUser } from "resources/users"

const OnboardingFromDemo = ({ user, className }) => {
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const navigate = useNavigate()

  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser({
      profile: values,
      team_lead_onboarding_complete: true,
    })

    navigate("/")
  })

  return (
    <OnboardingProfilePage
      user={user}
      headerText="Tell us about yourself"
      onSubmit={onSubmit}
      className={className}
      nextButtonText="Finish!"
    />
  )
}

export default OnboardingFromDemo
