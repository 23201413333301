import { format as formatDate } from "date-fns"

import View from "ui/View"

const ArtiIntro = ({ user, showTeamSummary }) => {
  const dayOfWeek = formatDate(new Date(), "EEEE")
  const userLabel = user.first_name?.trim() ? ` ${user.first_name.trim()}` : ""
  return (
    <View className="intro" $flexDirection="column">
      <div className="mb-xxs text-semi-bold">
        <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
        <span className="ml-xxs">aRTi</span>
      </div>
      <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
        <div className="mb-medium">
          Happy {dayOfWeek}, {userLabel}! I'm aRTi, your personalized AI leadership coach who can help you work better
          with your team by applying insights from Rising Team sessions.
        </div>
        <div>
          {showTeamSummary ? "Who or what would you like to discuss?" : "Which team member would you like to discuss?"}
        </div>
      </View>
    </View>
  )
}

export default ArtiIntro
