import { Form as FormikForm } from "formik"
import { styled } from "styled-components"

export default styled(FormikForm)`
  .question {
    font-size: 0.9375rem;
  }
  .drag-drop-question {
    font-size: 1.125rem;
  }
  .question,
  .drag-drop-question {
    font-weight: 600;
    color: var(--gray-9);
  }
  .instructions {
    font-size: 0.9375rem;
    color: #828282;
  }
`
