import { Route, Routes } from "react-router-dom"

import UserAccount from "./UserAccount"

import { SimpleRouteLayout } from "domains/Authentication/Routes"

const UserAccountLayout = () => (
  <div className="full-width">
    <SimpleRouteLayout title="Account" />
  </div>
)

const UserAccountIndex = () => (
  <Routes>
    <Route element={<UserAccountLayout />}>
      <Route path="*" element={<UserAccount />} />
    </Route>
  </Routes>
)

export default UserAccountIndex
