import { Route, Routes, Navigate, useLocation } from "react-router-dom"

import ArtiChat from "./components/ArtiChat"

import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import Loading from "ui/Loading"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"

const Arti = () => {
  const { state } = useLocation()
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam({
    allowJumboTeams: false,
    redirectIfNotSelectableTeam: true,
  })
  const { data: user } = useUser({ userId: "me" })
  const { data: team } = useTeam({ teamId: selectedTeamId })

  if (redirectTeamId) {
    return <Navigate to={`/arti?team_id=${redirectTeamId}${window.location.hash}`} state={state} replace />
  }

  if (!user || !team || needsRedirect) {
    return <Loading className="p-xxxl m-large" />
  }

  if (!user.has_arti_access) {
    return <Navigate to="/" state={state} replace />
  }

  return (
    <Routes>
      <Route path="/:id" element={<ArtiChat user={user} team={team} />} />
      <Route path="*" element={<ArtiChat user={user} team={team} />} />
    </Routes>
  )
}

export default Arti
