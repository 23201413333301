const CrisisResponsePracticeScenarioIntro = () => (
  <>
    <h2 className="text-gray-9 mb-large">Intro</h2>
    <p className="text-gray-9 mb-small">
      During this exercise, the team will work together to create a response plan to a hypothetical crisis scenario.
    </p>
    <p className="text-gray-9 mb-xl">
      First you will work as a team to identify the scenario you want to use. Next you will assign team members to roles
      using the "6 Hats" concept. This means that sometimes we each need to wear different hats (roles), to address a
      new challenge. Sometimes the role we’re assigned aligns with our preferences and sometimes it doesn't. This
      exercise will allow each of us to practice filling different roles, as we might need to do in a crisis.
    </p>
  </>
)

export default CrisisResponsePracticeScenarioIntro
