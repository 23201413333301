import { invert } from "lodash-es"

import { LLMName, LLMType } from "domains/Arti/constants"

const ArtiFooterDescription = ({ llm, isDefaultLLM }) => (
  <>
    {!isDefaultLLM && (
      <div className="text-center text-gray-7 text-small py-medium">Powered by {LLMName[invert(LLMType)[llm]]}</div>
    )}
    <div className="text-center text-gray-6 text-small">
      aRTi is powered by probabilistic large language models that generate responses based on user inputs. This means
      that their responses are generated through predicting the most probable next word or text, based on the data
      they've been trained on. Because of this approach, these models can sometimes behave in ways that are inaccurate,
      incomplete, or unreliable. We encourage you to review and reflect on the responses you get from aRTi before acting
      on them.
    </div>
  </>
)

export default ArtiFooterDescription
