import cn from "classnames"
import { useState } from "react"

import renderBlocks from "components/Blocks/renderBlocks"
import getIconOrError from "icons"
import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import TextContainer from "ui/TextContainer"
import View from "ui/View"

const ExerciseRadioAccordionField = ({ sentence, options, value = null, className }) => {
  const [selectedValue, setSelectedValue] = useState(value)
  const onChange = (event) => {
    const { value } = event.target
    setSelectedValue(value)
  }

  return (
    <View className={className}>
      <TextContainer className="full-width">
        <h4 className="text-semi-bold mb-medium">{renderBlocks(sentence)}</h4>
        {options.map(({ label, value, icon, selected_icon, selected_color, description }) => {
          const Icon = getIconOrError(icon)
          const SelectedIcon = getIconOrError(selected_icon)
          return (
            <div key={label}>
              <label>
                <View $alignItems="center" className="p-medium mt-medium border border-radius-small">
                  {selectedValue === value ? (
                    <SelectedIcon className={cn("fa-2x mr-small", selected_color)} />
                  ) : (
                    <Icon className="text-gray-5 fa-2x mr-small" />
                  )}
                  <input type="radio" name="radio" className="display-none" value={value} onChange={onChange} />
                  {label}
                </View>
              </label>
              <View $justifyContent="center">
                {selectedValue === value && (
                  <View $width="90%" className="bg-gray-3 p-small border-bottom-radius-small text-gray-8">
                    <div>
                      <PurifyInnerHTMLDiv>{description}</PurifyInnerHTMLDiv>
                    </div>
                  </View>
                )}
              </View>
            </div>
          )
        })}
      </TextContainer>
    </View>
  )
}

export default ExerciseRadioAccordionField
