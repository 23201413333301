const CareerHorizonsTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-large">
      <p className="mb-large">
        Now that you're clear on the role that long-term career goals play, how do you help your team articulate their
        professional aspirations? This Rising Team session will help people uncover their long-term objectives and then
        share those goals with the team for continued support.
      </p>
      <h2 className="text-rising-orange mb-medium">The Horizon exercise</h2>
      <p className="mb-medium">
        The Horizon exercise is designed to help team members elevate their thinking about professional goals by
        focusing on future possibilities. This activity involves 2 steps with opportunities to share insights with the
        team along the way.
      </p>
      <h3 className="mb-medium">Part 1: Thinking big</h3>
      <p className="mb-medium">
        The first part of this exercise will challenge the team to get out of the day-to-day and think about what's
        possible for their future. They'll be asked to think about up to 3 possible exciting paths they could take in
        the next 10 to 15 years. For each path they create, they'll list the requirements needed for success. Finally,
        they'll share the path they're most inspired by and get immediate feedback from the team on additional
        requirements they may want to consider.
      </p>
      <h3 className="mb-medium">Part 2: Charting a path</h3>
      <p className="mb-medium">
        In this step, team members will be asked to review the requirements they listed in the previous steps and
        evaluate each by indicating which skills or experiences they don't yet have, which they are in progress on, and
        which areas where they are already strong.
      </p>
      <p className="mb-medium">
        Finally, the team will complete the exercise by identifying requirements they want to focus on next and
        brainstorm how they might go about doing so as part of their near term work. They will then be asked to share
        these thoughts with the team for feedback and support.
      </p>
      <h2 className="text-rising-orange mb-medium">Facilitation tips</h2>
      <p className="mb-medium">A few things to keep in mind:</p>
      <h3 className="mb-medium">1. The Horizon exercise reflects a moment in time</h3>
      <p className="mb-medium">
        This exercise will help team members articulate ideas and goals that are several years in the future. The actual
        paths they brainstorm are not meant to be used as concrete career goals that you will track progress against.
        It's possible that our long-term vision of success will shift over time. What's most important in this activity
        is to focus on how you can align the skills identified for long-term aspirations with opportunities in the short
        term.
      </p>
      <h3 className="mb-medium">2. Focus on development</h3>
      <p className="mb-medium">
        This activity is designed to spark a team conversation and encourage future discussions about career planning
        and ongoing development opportunities. As the team lead, you have an opportunity to connect long-term
        aspirations with developmental activities in the shorter-term. Think about how team members can acquire new
        skills that ultimately support their future success.
      </p>
      <h3 className="mb-medium">3. Address diverse aspirations</h3>
      <p className="mb-medium">
        It's important to keep in mind that the main purpose of this exercise is not to build a plan to make everyone's
        career dreams a reality, but rather to gain insight into how they define and pursue future success. Then, focus
        on how you can support the development of skills that are important to that definition of success. There may be
        instances where someone has diverse aspirations that seem to have little to do with each other. In these cases,
        aim to help identify skills or experiences that contribute to more than one path or goal.
      </p>
      <h3 className="mb-medium">4. Support those who are unclear</h3>
      <p className="mb-medium">
        This activity may be the first time some team members have been asked to think about their future aspirations,
        and they may find it challenging to articulate them. You can help by sharing examples of how others in similar
        roles have progressed, as well as your own career journey and aspirations. You can also ask them to think about
        how their talents and interests might be useful in the future. For example you can ask, "What types of work
        activities feel most rewarding?" and "If you were to do more of these, what might you be able to achieve?"
      </p>
      <h3 className="mb-medium">5. It's OK if their long-term aspirations may be elsewhere</h3>
      <p className="mb-medium">
        It may be that the long-term paths people identify would mean they need to leave your organization in order to
        achieve them. That's OK! Remember these are 10-15 year horizons, so there are likely many things they can do to
        prepare for those paths while still working in their current role or at their current company.
      </p>
      <p className="mb-xxxxl">
        In the next step, you'll be able to see a quick preview of how the team session will run, and try out the
        Horizon exercise for yourself. In our last training article, we'll cover how to support long-term career goals
        on an ongoing basis.
      </p>
    </div>
  </div>
)

export default CareerHorizonsTraining2
