import ReportWithTeamAndKitControls from "domains/Reports/ReportWithTeamAndKitControls"
import SessionSummaryReportDetail from "domains/Reports/SessionSummaryReport/SessionSummaryReportDetail"

const SessionSummaryReport = ({ className, user, accountId, accountTags, multiAccountIds, includeArchivedTeams }) => (
  <div className={className}>
    <ReportWithTeamAndKitControls
      ReportComponent={SessionSummaryReportDetail}
      user={user}
      accountId={accountId}
      accountTags={accountTags}
      multiAccountIds={multiAccountIds}
      includeArchivedTeams={includeArchivedTeams}
      kitInstanceQueryEnabled={true}
    />
  </div>
)

export default SessionSummaryReport
