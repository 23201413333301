import { getExerciseAnswer } from "../results_utils"

import { CareerHorizonsChartingPathNextStepsReadOnly } from "./CareerHorizonsChartingPathNextSteps"
import { CareerHorizonsChartingPathRequirementsReadOnly } from "./CareerHorizonsChartingPathRequirements"
import CareerHorizonsThinkingBigExcitement from "./CareerHorizonsThinkingBigExcitement"
import CareerHorizonsThinkingBigPathName from "./CareerHorizonsThinkingBigPathName"

const CareerHorizonsSelectedUserView = ({ exercise }) => {
  const path_field_id = getExerciseAnswer(exercise, "path_to_share")?.split(".")[0]

  if (!path_field_id) {
    return null
  }

  const path_name = getExerciseAnswer(exercise, path_field_id)
  const all_path_field_ids = ["path_1", "path_2", "path_3"]
  const other_path_field_ids = all_path_field_ids.filter(
    (potential_path_field_id) => potential_path_field_id !== path_field_id
  )

  return (
    <>
      <h2 className="mb-medium">Potential Path: {path_name}</h2>
      <CareerHorizonsThinkingBigExcitement exercise={exercise} path_field_id={path_field_id} />
      <CareerHorizonsChartingPathRequirementsReadOnly exercise={exercise} className="mb-large" />
      <CareerHorizonsChartingPathNextStepsReadOnly exercise={exercise} className="mb-xl" />
      <h2 className="mb-large">Alternate paths</h2>
      {other_path_field_ids.map((other_path_field_id) => (
        <div key={other_path_field_id}>
          <CareerHorizonsThinkingBigPathName
            exercise={exercise}
            path_field_id={other_path_field_id}
            className="mb-xs"
          />
          <CareerHorizonsThinkingBigExcitement
            exercise={exercise}
            path_field_id={other_path_field_id}
            className="mb-large"
          />
        </div>
      ))}
    </>
  )
}

export default CareerHorizonsSelectedUserView
