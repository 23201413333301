import cn from "classnames"
import { Formik, Form } from "formik"
import { useLocation, useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import { forgotPassword } from "../resource"

import EmailField from "forms/fields/EmailField"
import FormMessage from "forms/FormMessage"
import handleErrors from "forms/handleErrors"
import BackButton from "ui/BackButton"
import PageTitle from "ui/PageTitle"
import SubmitButton from "ui/SubmitButton"
import View from "ui/View"

interface InputFormProps {
  className?: string
}

const InputForm = styled(function InputForm({ className }: InputFormProps) {
  return (
    <Form name="forgot-password" className={cn("space-y-large", className)}>
      <p>
        It happens to the best of us. Enter your email address below and we’ll send you a link to reset your password.
      </p>
      <EmailField name="email" label="Email" autoFocus={true} size="medium" />
      <FormMessage />
      <div>
        <SubmitButton>Submit</SubmitButton>
      </div>
      <View className="mt-auto pt-xxxxl">
        <BackButton className="secondary" navigateTo="/auth/login" />
      </View>
    </Form>
  )
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface ForgotPasswordFormValues {
  email: string
}

const ForgotPasswordForm = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const onSubmit = handleErrors(async (values: ForgotPasswordFormValues) => {
    await forgotPassword(values)
    navigate("success", { state: { email: values.email } })
  })
  const initialValues: ForgotPasswordFormValues = {
    email: state?.email || "",
  }
  return (
    <>
      <PageTitle>Forgot password</PageTitle>
      <View $flex={1} $flexDirection="column">
        <h2 className="mb-xs text-bold text-gray-9">Forgot password?</h2>
        <Formik initialValues={initialValues} onSubmit={onSubmit} component={InputForm as unknown as undefined} />
      </View>
    </>
  )
  // TODO(typescript): Change "InputForm as unknown as undefined" above to "InputForm" and debug typing issue.
}

export default ForgotPasswordForm
