import { Menu } from "@headlessui/react"
import cn from "classnames"

import { ZIndex } from "ui/z-index"

const MyMenu = ({ style = {}, className, ...props }) => (
  <div style={{ position: "relative", ...style }} className={className}>
    <Menu {...props} />
  </div>
)

MyMenu.Button = ({ className, style = {}, ...props }) => (
  <Menu.Button
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "var(--fg)",
      cursor: "pointer",
      outline: "none",
      ...style,
    }}
    className={cn("p-xs border-radius", className)}
    {...props}
  />
)

MyMenu.Items = ({ className, minWidth = 304, style = {}, ...props }) => (
  <Menu.Items
    className={cn("mt-xs border-radius", className)}
    style={{
      position: "absolute",
      right: 0,
      backgroundColor: "var(--fg)",
      boxShadow: "var(--lift-8)",
      zIndex: ZIndex.Menu,
      outline: "none",
      minWidth,
      ...style,
    }}
    {...props}
  />
)

MyMenu.Item = ({ as: AsComponent, className, style = {}, to, href, ...props }) => {
  AsComponent = AsComponent || "div"
  return (
    <Menu.Item>
      {({ active }) => (
        <AsComponent
          to={to}
          href={href}
          className={cn("py-small mx-medium", { fg: active && (to || href) }, className)}
          style={{ display: "block", cursor: to || href ? "pointer" : "text", outline: "none", ...style }}
          {...props}
        />
      )}
    </Menu.Item>
  )
}

export default MyMenu
