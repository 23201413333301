import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "domains/Exercise/resource"
import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import CareerHorizonsThinkingBigExcitement from "domains/Exercise/ResultsComponents/CareerHorizonsThinkingBigExcitement"
import CareerHorizonsThinkingBigPathName from "domains/Exercise/ResultsComponents/CareerHorizonsThinkingBigPathName"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SessionSelectedUsersShareView from "domains/KitSession/components/SessionSelectedUsersShareView"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useSelectedUser } from "ui/SelectedUserContext"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const CareerHorizonsThinkingBigShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 10000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer maxWidth={660}>
          <h2 className="text-gray-9 mb-medium">Share out</h2>
          <p className="text-gray-9 mb-medium">Now each person will get time to share with the team.</p>
        </TextContainer>
        <FacilitatorTip className="mb-large sidebar-right" tip="Share your screen here." />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={3}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances }) => {
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  const path_field_ids = ["path_1", "path_2", "path_3"]

  return (
    <>
      <h3 className="text-gray-9 mb-xs">Potential paths</h3>
      {path_field_ids.map((path_field_id) => (
        <TextContainer key={path_field_id}>
          <CareerHorizonsThinkingBigPathName
            exercise={selectedExercise}
            selectedUserId={selectedUser.id}
            path_field_id={path_field_id}
          />
          <CareerHorizonsThinkingBigExcitement
            exercise={selectedExercise}
            selectedUserId={selectedUser.id}
            path_field_id={path_field_id}
          />
        </TextContainer>
      ))}
    </>
  )
}

export default CareerHorizonsThinkingBigShareStep
