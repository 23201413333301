import { Form, Formik } from "formik"
import { useNavigate } from "react-router"

import manuallyAddAccount from "./resource"

import IntegerField from "forms/fields/IntegerField"
import SelectField from "forms/fields/SelectField"
import TextField from "forms/fields/TextField"
import ToggleField from "forms/fields/ToggleField"
import FormMessage from "forms/FormMessage"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import SubmitButton from "ui/SubmitButton"

const ManuallyAddAccount = () => {
  const navigate = useNavigate()

  const initialValues = {
    email: "",
    account_name: "",
    product_type: "LEADER_KIT",
    account_type: "STANDARD",
    stripe_customer_id: "",
    stripe_subscription_id: "",
    num_people: 1,
    create_first_team: true,
    send_email: true,
    remove_demo_team_conversions: true,
  }

  const submit = handleErrors(async (values) => {
    const account = await manuallyAddAccount(values)
    navigate(`/admin?accountId=${account.id}&includeAllAccounts=activeAccounts`)
  })

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => (
        <Form autoComplete="off" className="space-y-large">
          <h1>Manually Add Account</h1>
          <TextField name="email" label="Email" />
          <TextField name="account_name" label="Account Name" />
          <SelectField name="product_type" label="Product Type" size="medium">
            <option value="LEADER_KIT">LEADER_KIT</option>
            <option value="LEADER_KIT_INVOICE">LEADER_KIT_INVOICE</option>
            <option value="LEADER_KIT_FREE">LEADER_KIT_FREE</option>
          </SelectField>
          <SelectField name="account_type" label="Account Type" size="medium">
            <option value="STANDARD">STANDARD</option>
            <option value="MENZIES">MENZIES</option>
          </SelectField>
          {props.values.product_type === "LEADER_KIT" && (
            <>
              <TextField name="stripe_customer_id" label="Stripe Customer ID" />
              <TextField name="stripe_subscription_id" label="Stripe Subscription ID" />
            </>
          )}
          <IntegerField name="num_people" label="Number of People" />
          <ToggleField name="create_first_team">Create First Team</ToggleField>
          <ToggleField name="send_email">Send Invite Email</ToggleField>
          <ToggleField name="remove_demo_team_conversions">Remove Demo Teams</ToggleField>
          <FormMessage />
          <SubmitButton>Create Account</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  account_name: Yup.string().required().label("Account Name"),
  product_type: Yup.string().required().label("Product Type"),
  account_type: Yup.string().required().label("Account Type"),
  stripe_customer_id: Yup.string()
    .label("Stripe Customer ID")
    .when("product_type", {
      is: "LEADER_KIT",
      then: (schema) => schema.required(),
    }),
  stripe_subscription_id: Yup.string()
    .label("Stripe Subscription ID")
    .when("product_type", {
      is: "LEADER_KIT",
      then: (schema) => schema.required(),
    }),
  num_people: Yup.number().required().label("Number of People"),
  create_first_team: Yup.boolean().required(),
  send_email: Yup.boolean().required(),
  remove_demo_team_conversions: Yup.boolean().required(),
})

export default ManuallyAddAccount
