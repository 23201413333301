import { keyBy } from "lodash-es"

import { getExerciseAnswer } from "../results_utils"

const SelectFieldMultiUserList = ({ identifier, exerciseInstances, exerciseComponent, sortedUsers, className }) => {
  const selectOptions = exerciseComponent.options
  const userIdMap = keyBy(sortedUsers, "id")

  return (
    <div className={className}>
      {selectOptions.map((option) => {
        const selectedExerciseInstances = exerciseInstances.filter((instance) =>
          getExerciseAnswer(instance, identifier)?.includes(option.value)
        )
        return (
          <div key={option.value}>
            <span className="text-bold">{`${option.label}: `} </span>
            <ShortNameList selectedExerciseInstances={selectedExerciseInstances} userIdMap={userIdMap} />
          </div>
        )
      })}
    </div>
  )
}

const ShortNameList = ({ selectedExerciseInstances, userIdMap }) =>
  selectedExerciseInstances.map((instance) => userIdMap[instance.user_id].short_name).join(", ")

export default SelectFieldMultiUserList
