import { styled } from "styled-components"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { ShareoutStickyNotes } from "domains/KitSession/components/StickyNotes"
import Grid from "ui/Grid"

const StickyNotesUserFavoritesShare = ({ className, identifier, exerciseInstance, favoriteIdentifier }) => {
  const exerciseAnswer = getExerciseAnswer(exerciseInstance, identifier)
    ?.map((stickyNote) => ({ ...stickyNote, value: stickyNote?.value?.trim() }))
    ?.filter((stickyNote) => stickyNote?.value.length > 0)
  const favoriteUserStickyNotes =
    (favoriteIdentifier ? exerciseAnswer?.filter((stickyNote) => stickyNote[favoriteIdentifier]) : exerciseAnswer) ?? []

  if (!favoriteUserStickyNotes.length) {
    return <p className="text-gray-8 text-italic">Nothing added.</p>
  }

  return (
    <Grid className={className} $columns="3" $columnsMobile="1" $columnsTablet="2" $gap="12px">
      <ShareoutStickyNotes name={identifier} stickyNotes={favoriteUserStickyNotes} />
    </Grid>
  )
}

export default styled(StickyNotesUserFavoritesShare)``
