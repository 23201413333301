export default function SearchHighlight({ text, matches, highlight, minLength }) {
  if (!text?.length || !matches?.length || (minLength && matches.join("").length < minLength)) {
    return text // base case, otherwise recursion occurs below
  }
  const [match, ...rest] = matches
  const parts = text.toLowerCase().split(match)
  // cursor/substring/advance mechanism below is necessary because we want to
  // display text from original item name, NOT lowercased name (and lose casing)
  let cursor = 0
  const advance = (text, numChars) => text.substring(cursor, (cursor += numChars))
  return parts.flatMap((part, idx) => [
    SearchHighlight({
      text: advance(text, part.length),
      matches: rest,
      highlight,
    }),
    cursor < text.length && highlight(advance(text, match.length), idx),
  ])
}
